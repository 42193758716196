import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getStudentsInFirstDegree } from '../../../redux/faculty/actions';

const FacultyStudents = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, currentFaculty, students } = useSelector((state) => state.faculty);

    const {
        _id: facultyId,
        splitAcademicStructureTwo: structTwo,
        splitAcademicStructureThree: structThree,
        academicStructureId,
    } = currentFaculty;

    const acceptedStudents = students?.filter((student) => student?.status === 'Accepted');

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'firstName',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Matric Number',
            accessorKey: 'matricNumber',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: structTwo,
            accessorKey: 'department',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        ...(structThree
            ? [
                  {
                      header: structThree,
                      accessorKey: 'program',
                      cell: (props) => <p>{props?.getValue()}</p>,
                  },
              ]
            : []),
        {
            header: 'Level',
            accessorKey: 'level',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Admission Session',
            accessorKey: 'admissionSession',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
    ];

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        if (facultyId) {
            dispatch(getStudentsInFirstDegree(academicStructureId, facultyId, query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, facultyId, query]);

    const modifiedAcceptedStudents = acceptedStudents?.map((student) => {
        return {
            firstName: (
                <td
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() =>
                        navigate(`/faculties/students/${student?._id}`, {
                            state: {
                                studentId: student?._id,
                            },
                        })
                    }
                >{`${student?.lastName} ${student?.firstName} ${student?.middleName || ''}`}</td>
            ),
            department: <td>{student?.[structTwo?.toLowerCase()] || '-'}</td>,
            program: <td>{student?.[structThree?.toLowerCase()] || '-'}</td>,
            level: <td>{student?.level || '-'}</td>,
            admissionSession: <td>{student?.admissionSession || '-'}</td>,
        };
    });

    return (
        <>
            <PageLayout
                pageTitle={currentFaculty?.facultyName}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                fields={fields}
                data={modifiedAcceptedStudents}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isActionable
            />
        </>
    );
};

export default FacultyStudents;
