import React, { useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../components/list-empty';
import { CountBadge, getStatusStyles, StatusContainer } from '../../../../components/shared';
import { Text } from '../../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getNonAcademicStaff } from '../../../../redux/staff/actions';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Full Name',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Designation',
        accessorKey: 'designation',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Unit',
        accessorKey: 'unit',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Email',
        accessorKey: 'staffEmail',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Emp. Year',
        accessorKey: 'employmentYear',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Admin Status',
        accessorKey: 'staff_status',
    },
];

const NonAcademicStaff = ({ handleSelect, selectedItems }) => {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.staff.isLoading);
    const staff = useSelector((state) => state.staff.nonAcademicStaff);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        } else {
            queryParams.staffEmail = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getNonAcademicStaff(query));
        // dispatch(getStaffInUnit());
    }, [dispatch, query]);

    const modifiedStaff = staff?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox checked={selectedItems?.some((ite) => ite._id === item._id)} />
                </td>
            ),
            name: (
                <td style={{ color: '#2563EB' }}>
                    <Link to={item._id}>{`${item.fullName}`}</Link>
                </td>
            ),
            unit: (
                <td>
                    <div className="flex items-center gap-[4px]">
                        <Text color="#6b728">{item.unitName[0] || '-'}</Text>
                        {item.unitName?.length > 1 ? <CountBadge>+{item.unitName?.length - 1}</CountBadge> : null}
                    </div>
                </td>
            ),
            staff_status: (
                <td>
                    <StatusContainer style={getStatusStyles(item.staffStatus.toLowerCase())}>
                        <span>{item.staffStatus}</span>
                    </StatusContainer>
                </td>
            ),
            employmentYear: <td>{format(new Date(item?.yearOfEmployment), 'PP')}</td>,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedStaff || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title="No Non-Academic Staff "
                            subtitle="Create new Non-Academic Staff"
                        >
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Link to="add-staff">
                                    <Button bgColor="#10B981" color="#fff">
                                        <Circularplus className="mr-[1.15rem]" />
                                        Create New
                                    </Button>
                                </Link>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => {
                    const selectedItem = staff?.find((el) => el?._id === item?._id);
                    handleSelect(selectedItem);
                }}
                isActionable
            />
        </>
    );
};

export default NonAcademicStaff;
