import React from 'react';

import { ReactComponent as BoxCheck } from '../../../assets/icons/box-check.svg';
import { ConfirmActionDialogue } from '../../../components';
import { Button, LoadingButton } from '../../../components/buttons';
import PDFPreview from '../../../components/pdf-preview';
import { PageTitle, Text } from '../../../containers/MesssageContainers';

const Preview = ({
    values,
    isReviewer,
    handleSendComment,
    handleApproveCommunication,
    handleShowComment,
    handleShowApprove,
    handleShowEdit,
    onCommentChange,
    isLoading,
    confirmActions,
    setConfirmActions,
    fileUrl,
}) => {
    return (
        <div>
            <div className="rounded-[8px] bg-white flex flex-col py-[1rem] gap-[1rem] mt-[8px]">
                {values?.format?.toLowerCase() === 'text' && (
                    <div className="bg-white rounded-[0.8rem]">
                        <div className="px-[2.4rem] flex items-center justify-between pb-[0.8rem]">
                            <PageTitle transform="capitalize" align="left">
                                {values?.title}
                            </PageTitle>
                            <div className="">
                                <Text align="left" size="1.2rem" color="#9CA3AF">
                                    sent.{' '}
                                    {new Date(values?.createdAt).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}{' '}
                                    {new Date(values?.createdAt).toLocaleTimeString()}
                                </Text>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="text-[#1F2937] text-[1.4rem] font-normal leading-[2.4rem] py-[1.6rem] px-[2.4rem]"
                            dangerouslySetInnerHTML={{ __html: values?.content }}
                        />
                    </div>
                )}
                {values?.format?.toLowerCase() === 'upload' && <PDFPreview url={fileUrl} />}
                <hr />
                {values?.status !== 'draft' && (
                    <div className="pt-[10px] px-[32px] flex justify-end ">
                        <Button bgColor="#6366F1" color="#fff">
                            <BoxCheck /> Acknowledge
                        </Button>
                    </div>
                )}
            </div>
            {isReviewer && (
                <div className="flex items-center justify-end gap-[24px] mt-[2rem]">
                    <Button onClick={handleShowComment}>Leave a Comment</Button>
                    <LoadingButton onClick={handleShowApprove}>Approve</LoadingButton>
                </div>
            )}
            <ConfirmActionDialogue
                show={confirmActions.showLeaveComment}
                withIcon={false}
                title="Leave a Comment"
                input
                placeholder="Type here"
                onChange={onCommentChange}
                btn2Text="Send"
                isLoading={isLoading}
                confirmAction={handleSendComment}
                bgColor="#6366F1"
                close={() => setConfirmActions((prev) => ({ ...prev, showLeaveComment: false }))}
            />
            <ConfirmActionDialogue
                show={confirmActions.showApprove}
                withIcon={false}
                title="Approve"
                subtitleElement={
                    <Text align="left" color="#374151" size="16px" weight="500">
                        Approving this means it is good and should be sent to the recipients.{' '}
                        <span className="text-[#3B82F6] cursor-pointer" onClick={handleShowEdit}>
                            Edit message
                        </span>
                    </Text>
                }
                bgColor="#6366F1"
                close={() => setConfirmActions((prev) => ({ ...prev, showApprove: false }))}
                confirmAction={handleApproveCommunication}
                isLoading={isLoading}
            />
            <ConfirmActionDialogue
                show={confirmActions.showEdit}
                withIcon={false}
                title="Edit Message"
                input
                placeholder="Type here"
                onChange={onCommentChange}
                btn2Text="Save Changes & Approve"
                confirmAction={handleSendComment}
                bgColor="#6366F1"
                close={() => setConfirmActions((prev) => ({ ...prev, showEdit: false }))}
                isLoading={isLoading}
            />
        </div>
    );
};

export default Preview;
