import { FACULTY } from '../../utils/unitNames';
import {
    AcademicStanding,
    CourseFormMagtDetails,
    CourseFormManagement,
    CreateCurriculum,
    CumulativeCreditUnits,
    CurriculumDetails,
    CurriculumLevels,
    ProbationListSettings,
    ProgrammeStudents,
    Settings,
    WithdrawalListSettings,
    MinimumPromotionCGPA,
    Broadsheet,
    ExamLevels,
    ProbationListSheet,
    WithdrawalListSheet,
    SummarySheet,
    IncompleteResult,
    Access,
    EditRole,
    CreateRole,
    EditAdministrator,
    AssignRole,
    Staff,
    Administrator,
    Roles,
} from '../../views';

const baseUrl = 'faculties/department/programme';

export const programmeRoutes = [
    {
        path: `${baseUrl}/students`,
        element: <ProgrammeStudents />,
    },
    {
        path: `${baseUrl}/curriculum`,
        element: <CurriculumLevels />,
    },
    {
        path: `${baseUrl}/curriculum/:id`,
        element: <CurriculumDetails />,
    },
    {
        path: `${baseUrl}/curriculum/:id/create/:level`,
        element: <CreateCurriculum />,
    },
    {
        path: `${baseUrl}/settings`,
        element: <Settings />,
    },
    {
        path: `${baseUrl}/settings/withdrawal-list`,
        element: <WithdrawalListSettings />,
    },
    {
        path: `${baseUrl}/settings/probation-list`,
        element: <ProbationListSettings />,
    },
    {
        path: `${baseUrl}/settings/academic-standing`,
        element: <AcademicStanding />,
    },
    {
        path: `${baseUrl}/settings/cumulative-credit-units`,
        element: <CumulativeCreditUnits />,
    },
    {
        path: `${baseUrl}/settings/minimum-promotion-cgpa`,
        element: <MinimumPromotionCGPA />,
    },
    {
        path: `${baseUrl}/course-form-management`,
        element: <CourseFormManagement />,
    },
    {
        path: `${baseUrl}/course-form-management/:id`,
        element: <CourseFormMagtDetails />,
    },
    {
        path: `${baseUrl}/exam-management/broadsheet`,
        element: <ExamLevels title="Broadsheet" />,
    },
    {
        path: `${baseUrl}/exam-management/broadsheet/:level`,
        element: <Broadsheet />,
    },
    {
        path: `${baseUrl}/exam-management/summary-sheet`,
        element: <ExamLevels title="Summary Sheet" />,
    },
    {
        path: `${baseUrl}/exam-management/summary-sheet/:level`,
        element: <SummarySheet />,
    },
    {
        path: `${baseUrl}/exam-management/probation-list`,
        element: <ExamLevels title="Probation List" />,
    },
    {
        path: `${baseUrl}/exam-management/probation-list/:level`,
        element: <ProbationListSheet />,
    },
    {
        path: `${baseUrl}/exam-management/withdrawal-sheet`,
        element: <ExamLevels title="Withdrawal Sheet" />,
    },
    {
        path: `${baseUrl}/exam-management/withdrawal-sheet/:level`,
        element: <WithdrawalListSheet />,
    },
    {
        path: `${baseUrl}/exam-management/incomplete-result`,
        element: <ExamLevels title="Incomplete Result" />,
    },
    {
        path: `${baseUrl}/exam-management/incomplete-result/:level`,
        element: <IncompleteResult />,
    },
    {
        path: `${baseUrl}/access`,
        element: (
            <Access
                staffs={<Staff unitName={FACULTY} />}
                administrators={<Administrator unitName={FACULTY} />}
                roles={<Roles unitName={FACULTY} />}
            />
        ),
    },
    {
        path: `${baseUrl}/access/add-administrator`,
        element: <AssignRole unitName={FACULTY} />,
    },
    {
        path: `${baseUrl}/access/edit-administrator`,
        element: <EditAdministrator unitName={FACULTY} />,
    },
    {
        path: `${baseUrl}/access/create-role`,
        element: <CreateRole unitName={FACULTY} />,
    },
    {
        path: `${baseUrl}/access/edit-role`,
        element: <EditRole unitName={FACULTY} />,
    },
];
