import React from 'react';

import AcademicCalendar from '../../../../configuration/academic-calendar';

const AcademicCal = () => {
    return (
        <>
            <AcademicCalendar academic={true} />
        </>
    );
};

export default AcademicCal;
