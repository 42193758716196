import React, { useEffect } from 'react';

import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { GoBack } from '../../../../components/go-back';
import PDFPreview from '../../../../components/pdf-preview';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../../utils';
import { storeUnit } from '../../../../utils/originUnit';

const MainContainer = tw.div`
    mt-8
    mb-[6.4rem]
    rounded-lg
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;
const PdfDetails = tw.div`
    bg-white
    rounded-lg
    mt-[2rem]
`;
const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const StaffHandbookDetails = ({ unitName }) => {
    const navigate = useNavigate();
    const {
        state: { details, session },
    } = useLocation();

    const handleDownload = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(objectUrl);
        } catch (error) {
            toast.error('Error occurred during download:', error);
        }
    };

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <div>
            <div className="mb-[3.2rem]">
                <GoBack
                    title="Handbook"
                    backAction={() => navigate('/staff-arena/handbook', { state: { session } })}
                    subTitle={`${capitalizeFirstLetter(details?.title)}`}
                />
            </div>

            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexCentredRow>
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                    </FlexCentredRow>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Title</GreyText>
                                <DarkText>{capitalizeFirstLetter(details?.title)}</DarkText>
                            </FlexCol>
                        </RowContainer>

                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Description</GreyText>
                                <DarkText>{details?.description}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <DownloadIcon
                                onClick={() => {
                                    const downloadUrl = details?.handbookUpload?.url;
                                    const fileName = details?.handbookUpload?.fileName;

                                    if (downloadUrl && fileName) {
                                        handleDownload(downloadUrl, fileName);
                                    }
                                }}
                            />
                        </RowContainer>
                    </BasicContent>
                </Details>
                <PdfDetails>
                    <PDFPreview
                        url={details?.handbookUpload?.url}
                        pageNumber={1}
                        pdfTitle={capitalizeFirstLetter(details?.title)}
                    />
                </PdfDetails>
            </MainContainer>
        </div>
    );
};

export default StaffHandbookDetails;
