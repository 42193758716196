import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../components/list-empty';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getStudentsInSecondDegree } from '../../../../redux/departments/actions';

const DepartmentStudents = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, students, currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const {
        _id: facultyId,
        academicStructureId,

        splitAcademicStructureThree: structThree,
    } = currentFaculty;
    const { _id: departmentId } = currentDepartment || {};

    const [searchValue, setSearchValue] = useState('');
    const acceptedStudents = students?.filter((student) => student?.status === 'Accepted');

    const fields = [
        {
            accessorKey: 'action',
            cell: (props) => <p>{props.getValue()}</p>,
            header: <Checkbox />,
            size: 5,
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Full Name',
            accessorKey: 'name',
        },
        ...(structThree
            ? [
                  {
                      cell: (props) => <p>{props.getValue()}</p>,
                      header: structThree,
                      accessorKey: 'program',
                  },
              ]
            : []),
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Level',
            accessorKey: 'level',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Admission Year',
            accessorKey: 'admissionSession',
        },
    ];

    useEffect(() => {
        if (departmentId) {
            dispatch(getStudentsInSecondDegree(academicStructureId, facultyId, departmentId));
        }
    }, [dispatch, departmentId, academicStructureId, facultyId]);

    const modifiedAcceptedStudents = acceptedStudents?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            name: (
                <td
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() =>
                        navigate(`/faculties/department/students/${item?._id}`, {
                            state: {
                                studentId: item?._id,
                            },
                        })
                    }
                >{`${item?.lastName || ''} ${item?.firstName || ''} ${item?.middleName || ''}`}</td>
            ),
            program: <td>{item?.[structThree?.toLowerCase()]}</td>,
        };
    });

    return (
        <>
            <PageLayout
                pageTitle={currentDepartment?.departmentName}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedAcceptedStudents || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isActionable
            />
        </>
    );
};

export default DepartmentStudents;
