// export const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/; //habib
export const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=_{|}~-]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/; //student portal

export const containsLettersRegExp = /^((?!([A-Za-z])).)*$/g;

export const containsAllCharsExceptNumbers = /^((?!([\D])).)*$/g;

export const phoneRegExp = /^(?:[0-9] ?){6,14}[0-9]$/;

export const passwordRegExp = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,20}$/;

export const utmeRegExp = /\d{6}[a-zA-Z]{2}/;
