// Pages that can only be visited when user is authenticated

import Dashboard from '../views/dashboard';

import {
    admissionRoutes,
    facultyRoutes,
    registryRoutes,
    examsRecordsRoutes,
    personnelRoutes,
    libraryRoutes,
    bursaryRoutes,
    studentAffairsRoutes,
    ictRoutes,
    medicalsRoutes,
    configurationsRoutes,
    hostelRoutes,
    sportsRoutes,
    programmeRoutes,
    staffArenaRoutes,
    departmentRoutes,
    academicPlanningRoutes,
    auditRoutes,
    qualityAssuranceRoutes,
    securityRoutes,
    worksRoutes,
} from './unit-routes';

export const protectedRoutes = [
    {
        path: '/dashboard',
        element: <Dashboard />,
    },
    ...admissionRoutes,
    ...facultyRoutes,
    ...registryRoutes,
    ...examsRecordsRoutes,
    ...personnelRoutes,
    ...libraryRoutes,
    ...bursaryRoutes,
    ...studentAffairsRoutes,
    ...ictRoutes,
    ...medicalsRoutes,
    ...configurationsRoutes,
    ...hostelRoutes,
    ...sportsRoutes,
    ...programmeRoutes,
    ...staffArenaRoutes,
    ...departmentRoutes,
    ...academicPlanningRoutes,
    ...auditRoutes,
    ...qualityAssuranceRoutes,
    ...securityRoutes,
    ...worksRoutes,
];
