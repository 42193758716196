import React from 'react';

import { Field, ErrorMessage } from 'formik';
import tw, { styled } from 'twin.macro';

const InputField = tw(Field)`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
`;

const StyledInputField = styled(InputField)`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '4rem'};
    // -webkit-appearance: none;
`;

const DateInput = ({
    as,
    children,
    value,
    name,
    errors,
    placeholder,
    className,
    width,
    height,
    onChange,
    ...props
}) => {
    return (
        <div className="w-full relative">
            <StyledInputField
                type={'date'}
                as={as}
                placeholder={placeholder}
                className={`${errors[name] && 'focus:ring-[#EF4444]'} ${className}`}
                value={value}
                name={name}
                height={height}
                width={width}
                onChange={onChange && onChange}
                {...props}
            >
                {children}
            </StyledInputField>

            <ErrorMessage name={name}>
                {(errorMessage) => <span className="text-[#EF4444] text-[1.2rem]">{errorMessage}</span>}
            </ErrorMessage>
        </div>
    );
};

export default DateInput;
