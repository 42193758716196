import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const ConfirmApproval = ({ children, show, close, title, subtitle, confirmAction, bgColor, isLoading }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <FlexCentredRow>
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>
                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>
            <Text align="left" size="1.6rem" color="#374151" top="1.6rem">
                {subtitle} <Text></Text>
            </Text>
            {children}
            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <Button onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={confirmAction}
                    bgColor={bgColor || '#6366F1'}
                    color="#fff"
                >
                    Confirm
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ConfirmApproval;
