import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
// import Checkbox from '../../../components/inputs/checkbox';
import Select from '../../../components/inputs/new-select';
import ConfirmAddAdministrator from '../../../components/popups/confirm-add-administrator';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { addStaffToUnitAsAdminWithIds } from '../../../redux/administrators/actions';
import // assignRoleToStaff,
// getRolesInUnit,
'../../../redux/roles/actions';
import { getStaffInUnit } from '../../../redux/staff/actions';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    w-full
`;

// const Label = tw.label`
//     text-[1.4rem]
//     leading-6
//     font-semibold
//     mb-[0.8rem]
// `;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

// COmponent not currently in use. <AssignRole is used instead
const AddAdministrator = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.roles);
    const { filteredActiveOrgUnits: organisationUnits } = useSelector((state) => state.organisationUnits);

    const [staff, setStaff] = useState([]);
    // const [roles, setRoles] = useState([]);
    // const [selectedRole, setSelectedRole] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [showConfirmAllocation, setShowConfirmAllocation] = useState(false);

    // const handleChangeRole = (item) => {
    //     // const selected = selectedRole?.some((ite) => ite._id === item._id);
    //     const selected = selectedRole?.some((ite) => ite._id === item._id);

    //     if (selected) {
    //         // Needed for multiple selection
    //         // const newItems = selectedRole.filter((ite) => ite._id !== item._id);
    //         // setSelectedRole(newItems);

    //         setSelectedRole([]);
    //     } else {
    //         // Needed for multiple selection
    //         // setSelectedRole((prev) => [...prev, item]);

    //         setSelectedRole([item]);
    //     }
    // };

    const getStaff = async () => {
        const data = await dispatch(getStaffInUnit());
        if (data) {
            const dataToUse = data?.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
            }));
            setStaff(dataToUse);
        } else {
            setStaff([]);
        }
    };

    const handleSubmit = async () => {
        // const payload = {
        //     role: selectedRole[0]?.role,
        // };
        const currentUnit = organisationUnits.find((item) => item.unitName === unitName);

        // const res = await dispatch(assignRoleToStaff(selectedStaff[0]?._id, payload));

        const staffIds = [selectedStaff[0]?._id];
        const res = await dispatch(addStaffToUnitAsAdminWithIds(currentUnit?._id, { staffNames: staffIds }));

        if (res) {
            navigate(-1);
        }
    };

    // const getRoles = async () => {
    //     const data = await dispatch(getRolesInUnit(unitName));
    //     if (data) {
    //         return setRoles(data);
    //     }
    //     setRoles([]);
    // };

    useEffect(() => {
        if (unitName) {
            getStaff();
            // getRoles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitName]);

    return (
        <>
            <div className="mb-12">
                <GoBack title={`Administrators`} subtitle="Add Administrator" />
            </div>

            <Formik
                initialValues={{
                    staffId: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    setShowConfirmAllocation(true);
                }}
            >
                {({ errors, handleChange, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <div className="pl-6">
                                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem] ">
                                    <PeopleIcon />
                                    <Title>Add Administrator(s)</Title>
                                </FlexCentredRow>
                                <FlexCentredCol className="mb-[2.4rem]">
                                    <Select
                                        objProp="fullName"
                                        placeholder="Select Staff"
                                        label="Staff"
                                        // multiSelect
                                        data={staff}
                                        onChange={(selected) => {
                                            setFieldValue('staffId', selected[0]?._id);
                                            setSelectedStaff([selected[0]]);
                                        }}
                                        error={errors?.staffId}
                                    />
                                </FlexCentredCol>

                                {/* <Label align="left">Select Role</Label> */}
                            </div>
                            {/* <div className="grid grid-cols-1 justify-center mt-4 gap-y-[1.6rem] w-3/4">
                                {roles.map((item) => (
                                    <div key={item?._id}>
                                        <Checkbox
                                            value={item.role}
                                            id={item._id}
                                            label={item.role}
                                            labelClassName="text-xl mb-0"
                                            onChange={() => handleChangeRole(item)}
                                            checked={selectedRole?.some((ite) => ite._id === item._id)}
                                        />
                                    </div>
                                ))}
                            </div> */}

                            <FlexRowEnd className="gap-[2.4rem] mt-[3.6rem]">
                                <Button
                                    bgColor="#fff"
                                    color="#D1D5DB"
                                    border="1px solid #D1D5DB"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>

                                <LoadingButton type="submit">Add Administrator</LoadingButton>
                            </FlexRowEnd>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
            <ConfirmAddAdministrator
                show={showConfirmAllocation}
                close={() => setShowConfirmAllocation(false)}
                list={selectedStaff}
                // role={selectedRole}
                role={'Administrator'}
                confirmAction={() => handleSubmit()}
                altLoading={isLoading}
            />
        </>
    );
};

export default AddAdministrator;
