import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';

import { ExportSheetDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import CardPopup from '../../../../components/popups/card-popup';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getUnpaidFull } from '../../../../redux/bursary/actions';
import { getStudentDetails } from '../../../../redux/students/actions';
import currencyFormatter from '../../../../utils/formatCurrency';
import PaymentDetailsModal from '../payment-modal';

const NotPaidStudents = ({ unpaidStudents, loading, currentFee, setUnpaidStudents, id }) => {
    const dispatch = useDispatch();
    const { bioData, basicData } = useSelector((store) => store.student.studentDetails);
    const { unpaidStudents: responseData } = useSelector((store) => store.bursary);
    const { totalPages, totalNoOfStudents } = responseData || {};
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { tableSettings } = useTableContext();
    const { updateInfo } = bioData || {};

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [displayPaymentDetails, setDisplayPaymentDetails] = useState(false);
    const [studentId, setStudentId] = useState('');
    const [studentFee, setStudentFee] = useState([]);
    const [currentPage, setCurrentPage] = useState('1');

    useEffect(() => {
        if (studentId) {
            dispatch(getStudentDetails(studentId));
        }
    }, [dispatch, studentId]);

    const fields = [
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Names',
            accessorKey: 'name',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Matric No',
            accessorKey: 'matricNumber',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: firstDegreeOptionsStr,
            accessorKey: 'faculty',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: secondDegreeOptionsStr,
            accessorKey: 'program',
        },
        {
            cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
            header: 'Amount Payable',
            accessorKey: 'amountPayable',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: '',
            accessorKey: 'view',
        },
    ];

    const handleRowClick = (item) => {
        setStudentId(item._id);
        setStudentFee([item]);
        setDisplayPaymentDetails(true);
    };

    useEffect(() => {
        const queries = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: tableSettings?.numOfPages,
        };
        if (id) {
            const getUnpaidStudents = async () => {
                const res = await dispatch(getUnpaidFull(id, queries));
                setUnpaidStudents(res);
            };
            getUnpaidStudents();
        }
        //eslint-disable-next-line
    }, [id, debouncedSearchValue, currentPage, tableSettings]);

    const modifiedUnpaidStudents = unpaidStudents?.map((item) => {
        return {
            name: (
                <td>
                    <Text align="left">
                        {item?.lastName} {item?.firstName} {item?.middleName || ''}
                    </Text>
                </td>
            ),
            program: (
                <td>
                    <Text align="left">{item?.program || item?.department}</Text>
                </td>
            ),
            faculty: item?.college || item?.faculty || item?.school,
            view: (
                <td>
                    <Button border="1px solid #1F2937" onClick={() => handleRowClick(item)}>
                        View
                    </Button>
                </td>
            ),
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedUnpaidStudents || []}
                fields={fields}
                loading={loading}
                // onRowClick={(item) => {
                //     const selectedItem = unpaidStudents?.find((stud) => stud?._id === item?._id);
                //     handleRowClick(selectedItem);
                // }}
                isPaginated={true}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={totalNoOfStudents}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
            />

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                // chooseAction={} exportAction={}
            />
            <CardPopup show={displayPaymentDetails} close={() => setDisplayPaymentDetails(false)} noClose={true}>
                <PaymentDetailsModal
                    basicData={basicData}
                    updateInfo={updateInfo}
                    studentFee={studentFee}
                    session={currentFee}
                />
            </CardPopup>
        </>
    );
};

export default NotPaidStudents;
