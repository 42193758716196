import { styled } from 'twin.macro';

import { Text } from '../../containers/MesssageContainers';
import { CenteredContainer } from '../../containers/ScreenContainers';

const LoaderDiv = styled.div`
    border: 1.6rem solid #e5e7eb;
    border-top: 1.6rem solid #6366f1;
    border-radius: 50%;
    width: 13.26rem;
    height: 13.26rem;
    animation: spin 2s linear infinite;
    margin-bottom: 1.57rem;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Loader = ({ withText, containerStyle }) => {
    return (
        <CenteredContainer className={containerStyle ? containerStyle : 'flex-column h-screen'}>
            <LoaderDiv />
            {withText && (
                <Text color="#312E81" weight="600" size="2.3rem">
                    Getting Everything Ready
                </Text>
            )}
        </CenteredContainer>
    );
};
