import React from 'react';

import CreateNewCourse from '../../../../../components/forms/create-new-course';
import { GoBack } from '../../../../../components/go-back';

const AddNewCourse = () => {
    return (
        <>
            <div className="mb-12">
                <GoBack title="Courses" subtitle="Add Course" />
            </div>

            <CreateNewCourse />
        </>
    );
};
export default AddNewCourse;
