export const assuranceQualityNavLinks = [
    {
        title: 'Communications',
        to: '/quality-assurance/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/quality-assurance/communications/compose',
            },
            {
                title: 'Received',
                to: '/quality-assurance/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/quality-assurance/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/quality-assurance/communications/settings',
            },
        ],
    },
    { title: 'Access', to: '/quality-assurance/access' },
];

export const assuranceQualityRoutes = assuranceQualityNavLinks.map((item) => item.to);
