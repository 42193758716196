import React from 'react';

import { useLocation } from 'react-router-dom';

import { Card } from '../../../../components';
import { GoBack } from '../../../../components/go-back';
import { Text } from '../../../../containers/MesssageContainers';

import ApplicableStudents from './ApplicableStudent';

const DiscountDetails = () => {
    const { state } = useLocation();

    const { discount } = state || {};

    const overviewItems = [
        { title: 'Session', value: discount?.session },
        { title: 'Discount Name', value: discount?.discountName },
        { title: 'Description', value: discount?.discountDescription || '' },
        { title: 'PercentageOff', value: discount?.discountPercentage },
        { title: 'Number of Student Applied', value: discount?.beneficiaryCount },
        { title: ' Applicable Fee(s)', value: discount?.feeName.map((fee) => fee).join(', ') },
    ];
    return (
        <div>
            <GoBack title="Discount" subTitle={discount?.discountName} />

            <Card className="mt-8 mb-[32px] p-[24px]">
                <Text align="left" size="1.9rem" weight="600" bottom="28px">
                    Overview
                </Text>
                <div className="grid grid-cols-3 gap-y-[34px]">
                    {overviewItems.map((item) => (
                        <div key={item.title}>
                            <Text align="left" lineHeight="17px" color="#9CA3AF" size="1.2rem" weight="500">
                                {item.title || ''}
                            </Text>
                            <Text align="left" weight="500" color="#4B5563">
                                {item.value || ''}
                            </Text>
                        </div>
                    ))}
                </div>
            </Card>
            <ApplicableStudents discountName={discount?.discountName} />
        </div>
    );
};

export default DiscountDetails;
