import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, LoadingButton } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import DatePicker from '../../../../components/inputs/date-picker';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import SuccessPopup from '../../../../components/popups/success';
import { FlexCentredCol, FlexRowEnd } from '../../../../containers/ScreenContainers';
import { createActivityInWeek, getWeeksInSemester } from '../../../../redux/academic-calendar/actions';
import { formatDate } from '../../../../utils/formatDate';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    mt-[2.8rem]
`;

const InputDetails = tw.div`
    grid
    grid-cols-2
`;

const Label = tw.label`
    font-normal
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const CreateActivityInWeek = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const admissionsSessions = useSelector((state) => state.admission.sessions);
    const { isLoading } = useSelector((state) => state.calendar);

    const [weeks, setWeeks] = useState([]);
    const weeksToUse = weeks?.map((el) => ({ name: el.week, id: el._id }));

    const [openSuccess, setOpenSuccess] = useState(false);
    const [session, setSession] = useState('');
    const [semester, setSemester] = useState('');

    const getWeeks = async () => {
        setWeeks([]);
        const toastId = toast.loading('Loading semester calendar...');
        const weeks = await dispatch(getWeeksInSemester(session, semester));
        toast.dismiss(toastId);
        setWeeks(weeks?.data);
    };

    useEffect(() => {
        if (session && semester) {
            getWeeks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, session, semester]);

    return (
        <>
            <GoBack title="Academic Calendar" subtitle="Create Activity in Week" />

            <Formik
                initialValues={{
                    academicSession: '',
                    semester: '',
                    week: '',
                    from: '',
                    to: '',
                    activity: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const payload = {
                        from: formatDate(values.from),
                        to: formatDate(values.to),
                        activity: values.activity,
                    };
                    const res = await dispatch(createActivityInWeek(values.week, payload));
                    if (res) {
                        actions.resetForm();
                        setOpenSuccess(true);
                    }
                }}
            >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                    <>
                        <Form>
                            <MainContainer>
                                <>
                                    <InputDetails className="gap-[3.2rem]">
                                        <Select
                                            name="academicSession"
                                            objProp="admissionYear"
                                            placeholder="Session"
                                            label="Session"
                                            data={admissionsSessions}
                                            onChange={(selected) => {
                                                setFieldValue('academicSession', String(selected[0].admissionYear));
                                                setSession(String(selected[0].admissionYear));
                                            }}
                                            error={errors.academicSession}
                                        />
                                        {values.academicSession && (
                                            <Select
                                                name="semester"
                                                objProp="name"
                                                placeholder="Semester"
                                                label="Semester"
                                                data={['First', 'Second'].map((item) => ({ name: item }))}
                                                onChange={(selected) => {
                                                    setFieldValue('semester', String(selected[0].name?.toLowerCase()));
                                                    setSemester(String(selected[0].name?.toLowerCase()));
                                                }}
                                                error={errors.semester}
                                            />
                                        )}
                                        {values.semester && (
                                            <Select
                                                name="week"
                                                objProp="name"
                                                placeholder="Week"
                                                label="Week"
                                                data={weeksToUse}
                                                onChange={(selected) => {
                                                    setFieldValue('week', String(selected[0].id));
                                                }}
                                                error={errors.week}
                                            />
                                        )}
                                        {values.week && (
                                            <>
                                                <FlexCentredCol>
                                                    <Label>Start Date</Label>
                                                    <DatePicker
                                                        name="from"
                                                        touched={touched}
                                                        minDate={
                                                            weeks?.length
                                                                ? // ? transformDate(weeks[values.week - 1]?.startDate)
                                                                  new Date(
                                                                      weeks.find(
                                                                          (item) =>
                                                                              String(item._id) ===
                                                                              (values.week || weeksToUse[0].id),
                                                                      )?.startDate,
                                                                  )
                                                                : null
                                                        }
                                                        maxDate={
                                                            weeks?.length
                                                                ? new Date(
                                                                      weeks.find(
                                                                          (item) => String(item._id) === values.week,
                                                                      )?.endDate,
                                                                  )
                                                                : null
                                                        }
                                                    />
                                                </FlexCentredCol>
                                                <FlexCentredCol>
                                                    <Label>End Date</Label>
                                                    <DatePicker
                                                        name="to"
                                                        touched={touched}
                                                        minDate={new Date(values.from)}
                                                        maxDate={
                                                            weeks?.length
                                                                ? new Date(
                                                                      weeks.find(
                                                                          (item) => String(item._id) === values.week,
                                                                      )?.endDate,
                                                                  )
                                                                : null
                                                        }
                                                    />
                                                </FlexCentredCol>
                                            </>
                                        )}
                                    </InputDetails>

                                    <FlexCentredCol className="mt-[3.2rem]">
                                        <Label>Description</Label>
                                        <TextInput
                                            name="activity"
                                            type="text"
                                            as="textarea"
                                            placeholder="Enter text here"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.activity}
                                        />
                                    </FlexCentredCol>
                                </>

                                <FlexRowEnd className="gap-[2.4rem] my-[3.6rem]">
                                    <Button
                                        bgColor="#fff"
                                        color="#1F2937"
                                        border="0.5px solid #D1D5DB"
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        disabled={isLoading}
                                        loading={isLoading}
                                        bgColor="#6366F1"
                                        color="#ffffff"
                                        type="submit"
                                    >
                                        Create
                                    </LoadingButton>
                                </FlexRowEnd>
                            </MainContainer>
                        </Form>
                    </>
                )}
            </Formik>
            <SuccessPopup
                show={openSuccess}
                onClose={() => setOpenSuccess(false)}
                btnAction={() => navigate(-1)}
                title="Awesome!"
                subtitle="The event has been added to the academic calendar"
                btnText="Done"
            />
        </>
    );
};

export default CreateActivityInWeek;
