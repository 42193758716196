import React from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Button } from '../../../../../components/buttons';
import { Text } from '../../../../../containers/MesssageContainers';

const Container = tw.div`
p-[2.4rem]
bg-white
rounded-[1.6rem]
flex
flex-col
gap-[1.6rem]
`;

const Details = tw.div`
flex
flex-col
gap-[1.6rem]
border-b-[1px]
py-[1.6rem]
border-b-[#E5E7EB]
last:border-b-[0px]
`;

const ContactInfo = ({ contact }) => {
    const navigate = useNavigate();

    const contactDetails = [
        {
            title: 'Full Name',
            value: contact?.fullName,
        },
        {
            title: 'Company Name',
            value: contact?.companyName,
        },
        {
            title: 'Phone Number',
            value: contact?.phoneNumber,
        },
        {
            title: 'Email Address',
            value: contact?.email,
        },
        {
            title: 'Website Url',
            value: contact?.websiteUrl,
        },
        {
            title: 'Address',
            value: contact?.address,
        },
    ];
    return (
        <Container>
            <div className="flex items-center justify-between">
                <Text align="left" size="1.9rem" weight="600">
                    Contact Information
                </Text>
                <Button type="button" onClick={() => navigate('edit')} bgColor="#6366F1" color="#FFF">
                    Edit
                </Button>
            </div>
            {contactDetails?.map((data, i) => (
                <Details key={i}>
                    <Text align="left" weight="500" size="1.6rem">
                        {data.title}
                    </Text>
                    <Text align="left" weight="400" size="1.4rem">
                        {data.value}
                    </Text>
                </Details>
            ))}
        </Container>
    );
};

export default ContactInfo;
