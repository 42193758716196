import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as AttachmentAsh } from '../../../../../assets/icons/attachment-ash.svg';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';
import { ReactComponent as Download } from '../../../../../assets/icons/download-new.svg';
import { ReactComponent as Picture } from '../../../../../assets/icons/picture.svg';
import { Button } from '../../../../../components/buttons';
import Acknowledge from '../../../../../components/forms/acknowledge';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../../containers/ScreenContainers';

const MainContainer = tw.div`
h-auto
bg-white
mx-auto
py-[2.4rem]
px-[3.2rem]
rounded-lg
`;

const NoticePreview = () => {
    const navigate = useNavigate();
    const [showAcknowledge, setShowAcknowledge] = useState(false);

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.2rem]">
                <BackArrow className="mr-[8px] " />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Received - Communications
                </PageTitle>
            </FlexCentredRow>

            <MainContainer>
                <FlexRowSpaceBetween>
                    <PageTitle>Memo</PageTitle>

                    <FlexCentredRow className="gap-x-[1.6rem]">
                        <Button className="shadow-[0px_1px_2px_rgba(6,25,56,0.05)]">
                            <Download />
                        </Button>

                        <Close onClick={() => navigate('/office/inbox')} />
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween className="mt-[2.8rem]">
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TYPE:
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            Memo
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <AttachmentAsh />
                        <Text color="#9CA3AF">Sent. Jun 7, 2022, 1:45pm</Text>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexCentredCol>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            FROM (OFFICE):
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            Registrar
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            THROUGH:{' '}
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            Head of Department
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TO:{' '}
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            All Staff
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            REF NO:{' '}
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            11156GF
                        </Text>
                    </FlexCentredRow>
                </FlexCentredCol>
                <FlexCentredCol className=" gap-y-[3.2rem] my-[3.2rem]">
                    <Text align="left">Hi guys!</Text>
                    <Text align="left">
                        As you already know, we are developing a new product called Boxboard, and today I present you a
                        new category of panels for mail. Which you will be able to test in the near future. I would also
                        like to get feedback on the shortcomings and various kinds of bugs. All messages about the work
                        you can send me by the same mail from which I sent the same message I wish you good luck and
                        pleasant interaction with the panel.
                    </Text>
                    <Text align="left">Best Regards,Bogdan at Unisense</Text>
                </FlexCentredCol>
                <Picture className="mb-[25.074rem]" />
                <FlexRowEnd>
                    <Button
                        border="1px solid #6366F1"
                        bgColor="#6366F1"
                        color="#FFF"
                        onClick={() => setShowAcknowledge(true)}
                    >
                        Acknowledge
                    </Button>
                </FlexRowEnd>
            </MainContainer>

            <Acknowledge show={showAcknowledge} onClose={() => setShowAcknowledge(false)}></Acknowledge>
        </div>
    );
};

export default NoticePreview;
