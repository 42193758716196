import React, { useEffect } from 'react';

import { PageTitle } from '../../containers/MesssageContainers';

import CommunicationFeed from './CommunicationFeed';
import OrganisationUnits from './OrganisationUnit';

const Dashboard = () => {
    useEffect(() => {
        localStorage.removeItem('activeTab');
        //eslint-disable-next-line
    }, []);
    return (
        <>
            <PageTitle align="left">Dashboard</PageTitle>
            <div className=" mt-5 grid grid-cols-[2fr_1fr] gap-8">
                <OrganisationUnits />
                <CommunicationFeed />
            </div>
        </>
    );
};

export default Dashboard;
