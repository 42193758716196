import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import { GET_ALL_ADMINS, LOADING, STOP_LOADING } from './slice';

export const getAdministratorsInUnit = (unitName) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/${currentUnit?._id}/get_administrator_in_unit/${currentUnit.unitName}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAdministratorsInOrganization = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/${currentUnit?._id}/get_all_staffs_administrator?${queries}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_ALL_ADMINS(data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllAdministratorRoles = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/${currentUnit?._id}/get_all_administrators`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createAdministratorInUnit = (unitName, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}organizationUnit/${currentUnit?._id}/create_role_in_organization_unit/${unitName}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addStaffToUnitAsAdmin = (unitName, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}organizationUnit/${currentUnit?._id}/add_staff_to_unit_as_administrator/${unitName}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// used to add org admins from inside personnel unit or ict unit
export const addStaffToUnitAsAdminWithIds = (unitId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}organizationUnit/${currentUnit?._id}/make_staff_an_administrator_in_unit/${unitId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStaffAsAdmin = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}organizationUnit/${currentUnit?._id}/remove_staff_as_administrator_in_unit/${staffId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addStaffToUnit = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}staff/${currentUnit?._id}/add_staff_to_unit/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStaffFromUnit = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}staff/${currentUnit?._id}/remove_staff_from_unit/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};