import * as Yup from 'yup';

const ratioRegex = /^(\d{1,2})(:\d{1,2}){1,4}$/;

export const ValidationSchema = Yup.object().shape({
    search: Yup.string().nullable().required('Required'),
    session: Yup.string().nullable().required('Required'),
    feeType: Yup.string().nullable().required('Required'),
    amountOwed: Yup.string().nullable().required('Required'),
    span: Yup.string().nullable().required('Required'),
    paymentConfig: Yup.string().nullable().required('Required'),
    debtSchedule: Yup.string().nullable().required('Required'),
    deadline: Yup.string().nullable(),

    numberOfInstallments: Yup.string()
        .nullable()
        .when('debtSchedule', {
            is: 'Installment',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable(),
        }),
    studentDecides: Yup.string()
        .nullable()
        .when('debtSchedule', {
            is: 'Installment',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable(),
        }),
    ratios: Yup.string()
        .nullable()
        .when(['debtSchedule', 'studentDecides'], {
            is: (debtSchedule, studentDecides) => debtSchedule === 'Installment' && studentDecides === 'percent',
            then: Yup.string()
                .required('Required')
                .matches(ratioRegex, 'Must be in ratio format (e.g., 50:50, 60:20:20 etc.)')
                .test('total-100', 'Percentages must total 100', (value) => {
                    if (!value) return true; // skip validation if value is null or empty
                    const parts = value.split(':').map(Number);
                    return parts.reduce((acc, curr) => acc + curr, 0) === 100;
                })
                .test(
                    'match-installments',
                    'Number of Percentages must match the number of instalments',
                    function (value) {
                        const { numberOfInstallments } = this.parent;
                        if (!value) return true;
                        const parts = value.split(':');
                        return parts.length === Number(numberOfInstallments);
                    },
                ),
            otherwise: Yup.string().nullable(),
        }),
    feeBreakdown: Yup.array().of(
        Yup.object().shape({
            amount: Yup.string().test(
                'amount-description-check',
                // 'Either both amount and description must be provided, or neither.',
                'Required',
                function (value) {
                    const { description } = this.parent;
                    return (!value && !description) || (value && description);
                },
            ),
            description: Yup.string().test(
                'description-amount-check',
                // 'Either both amount and description must be provided, or neither.',
                'Required',
                function (value) {
                    const { amount } = this.parent;
                    return (!value && !amount) || (value && amount);
                },
            ),
        }),
    ),
});
