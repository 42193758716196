import React, { useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ChooseColumnsToExport, ExportSheetDialogue, UploadFile } from '../../../components';
import AddNewFacultyForm from '../../../components/forms/add-new-faculty';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, ItemsPerPageCont } from '../../../containers/ScreenContainers';
import { tenantData } from '../../../data/tenantData';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Name',
        key: 'name',
    },
    {
        label: 'Age',
        key: 'age',
    },
    {
        label: 'Department',
        key: 'department',
    },
    {
        label: 'Medical Challenge',
        key: 'medical',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const ViewTenant = () => {
    const navigate = useNavigate();
    const [isLoading /*setIsLoading*/] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(tenantData?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.6rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Hostel Management
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                        /
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" color="#6366F1" lineHeight="2.8rem">
                        Adeniyi Hostel
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                        /
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Room 100
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                        /
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                        Tenants
                    </PageTitle>
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                <CDataTable
                    items={tenantData || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => navigate(`/hostel/hostel-list/${item.id}`)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => (
                            <td style={{ color: '#2563EB' }} className="cursor-pointer">
                                {item.name}
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <AddNewFacultyForm show={showForm} setShow={setShowForm} />
            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default ViewTenant;
