import * as Yup from 'yup';

import { emailRegExp } from '../../../utils/regex';

export const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().required('Required'),
    lastName: Yup.string().nullable().required('Required'),
    otherName: Yup.string().nullable().required('Required'),
    organizationEmail: Yup.string().matches(emailRegExp, 'Enter a valid email').nullable().required('Required'),
    institutionName: Yup.string().nullable().required('Required'),
    phoneNumber: Yup.string().nullable().required('Required'),
    institutionType: Yup.string().nullable().required('Required'),
    institutionCountry: Yup.string().nullable().required('Required'),
    institutionAddress: Yup.string()
        .matches(/^[a-zA-Z0-9\s,.-]+$/, 'Only letters, numbers, spaces, commas, periods, and hyphens are allowed.')
        .required('Required'),
    institutionState: Yup.string().nullable().required('Required'),
});

