export const ADMISSIONS = 'Admissions';
export const REGISTRY = 'Registry';
export const FACULTY = 'Academics';
export const BURSARY = 'Bursary';
export const EXAMS_AND_RECORDS = 'Exams and Records';
export const PERSONNEL = 'Personnel';
export const ICT = 'ICT';
export const STUDENT_AFFAIRS = 'Students Affairs';
export const LIBRARY = 'Library';
export const MEDICAL = 'Clinic';
export const SPORTS = 'Sports';
export const HOSTEL = 'Hostel';
export const STAFF_ARENA = 'Staff Arena';
export const ACADEMIC_PLANNING = 'Academic Planning';
export const AUDIT = 'Audit';
export const QUALITY_ASSURANCE = 'Quality Assurance';
export const SECURITY = 'Security';
export const WORKS = 'Works';
// export const unitNames = [
//     'Admissions',
//     'Registry',
//     'Faculty',
//     'Bursary',
//     'Exam and Records',
//     'Staff Management',
//     'ICT',
//     'Student Affairs',
//     'Library',
//     'Medical',
//     'Sports',
//     'Staff Arena',
// ];
