import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as CirclePlus } from '../../../../../../assets/icons/circlePlus.svg';
import { ReactComponent as Info } from '../../../../../../assets/icons/Info-white.svg';
import { ReactComponent as Mastercard } from '../../../../../../assets/icons/Mastercard.svg';
import { Button } from '../../../../../../components/buttons';
import { Text } from '../../../../../../containers/MesssageContainers';

const Checkout = ({ seats, onMonthlyConfirm, onYearlyConfirm }) => {
    const [activeTab, setActiveTab] = useState(1);
    const { billingPlans } = useSelector((store) => store.ict);
    return (
        <div>
            <div className="bg-[#3B82F6] mx-[13.6rem] rounded-[4px] p-[10px] flex items-center justify-center gap-[1.2rem]">
                <Info />
                <Text color="#fff" size="1.6rem">
                    These changes will be applied and you’ll be charged on renewal (26 Aug 2023)
                </Text>
            </div>
            <div className="grid grid-cols-3 pt-[3.6rem] gap-[2.6rem] items-start">
                <div className="col-span-2">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-[0.8rem]">
                            <Mastercard />
                            <div className="">
                                <Text align="left" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.4rem">
                                    MasterCard
                                </Text>
                                <Text align="left" color="#6B7280" size="1rem" weight="400" lineHeight="1.7rem">
                                    Ending 6916 . Expires On 9/2024
                                </Text>
                            </div>
                        </div>
                        <div className="rounded-[1000px] bg-[#3B82F6] px-[0.8rem] text-[#fff] text-[1.4rem] font-semibold">
                            Primary
                        </div>
                        <Button bgColor="#fff" color="#3B82F6">
                            Edit
                        </Button>
                    </div>
                    <div className="pt-[3.75rem] flex items-center justify-between">
                        <Text align="left" size="1.4rem" weight="400" color="#6B7280" className="w-[33.8rem]">
                            You can add an alternative payment method by adding another
                        </Text>
                        <Button border="1px solid #3B82F6" color="#3B82F6" className="flex items-center gap-[0.8rem]">
                            <CirclePlus fill="#2563EB" />{' '}
                            <Text color="#3B82F6" size="1.4rem" weight="500">
                                Add Another
                            </Text>
                        </Button>
                    </div>
                    <div className="pt-[1.6rem] mt-[2.4rem] pl-[3.2rem] pr-[3.5rem] pb-[4rem] border-[1px] rounded-[24px] border-[#E5E7EB] shadow-[0px_35px_60px_-15px_rgba(6,25,56,0.25)]">
                        <Text align="left" size="1.4rem" color="#6B7280" weight="600" bottom="1.4rem">
                            Current Plan
                        </Text>
                        <div className="h-[0.4px] bg-[#E5E7EB]"></div>
                        <div className="flex items-center justify-between pt-[1.4rem]">
                            <div className="">
                                <Text align="left" size="1.4rem" color="#4B5563" weight="600">
                                    Monthly Plan
                                </Text>
                                <Text align="left" size="1.4rem" color="#4B5563" weight="500">
                                    0 Seats
                                </Text>
                            </div>
                            <div className="">
                                <Text align="right" size="1.9rem" color="#000" weight="500">
                                    NGN 0
                                </Text>
                                {/* <Text align="right" size="1.4rem" color="#6B7280" weight="500">
                                    NGN 850, 000
                                </Text> */}
                            </div>
                        </div>
                    </div>
                    <div className="pt-[1.6rem] mt-[2.4rem] pl-[3.2rem] pr-[3.5rem] pb-[4rem] border-[1px] rounded-[24px] border-[#E5E7EB] shadow-[0px_35px_60px_-15px_rgba(6,25,56,0.25)]">
                        <Text align="left" size="1.4rem" color="#1F2937" weight="600" bottom="1.4rem">
                            New Plan
                        </Text>
                        <div className="h-[0.4px] bg-[#E5E7EB]"></div>
                        <div className="flex items-center justify-between pt-[1.4rem]">
                            <div className="">
                                <Text align="left" size="1.4rem" color="#4B5563" weight="600">
                                    Monthly Plan
                                </Text>
                                <Text align="left" size="1.4rem" color="#4B5563" weight="500">
                                    {seats} Seats
                                </Text>
                            </div>
                            <div className="">
                                <Text align="right" size="1.9rem" color="#000" weight="500">
                                    NGN {seats * billingPlans?.[0]?.displayAmount}
                                </Text>
                                {/* <Text align="right" size="1.4rem" color="#6B7280" weight="500">
                                    NGN 850, 000
                                </Text> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-[3.35rem] pl-[4.2rem] pr-[4rem] pb-[3.6rem] border-[1px] rounded-[24px] border-[#E5E7EB] shadow-[0px_14px_21px_-1px_rgba(6,25,56,0.08)]">
                    <div className="flex items-center justify-center pb-[3.2rem]">
                        <div className={`w-[17.6rem] bg-[#DCF7FE] rounded-[1000px] flex items-center justify-center`}>
                            <div
                                onClick={() => setActiveTab(1)}
                                className={`rounded-[1000px] py-[0.6rem] w-[8.8rem] px-[1.4rem] cursor-pointer ${
                                    activeTab === 1 ? 'bg-[#DCF7FE]' : 'bg-[#F3F4F6]'
                                }`}
                            >
                                <Text align="center" color="#9CA3AF" size="1.4rem" weight="600">
                                    Monthly
                                </Text>
                            </div>
                            <div
                                onClick={() => setActiveTab(2)}
                                className={`${
                                    activeTab === 2 ? 'bg-[#DCF7FE]' : 'bg-[#F3F4F6]'
                                } px-[1.4rem] py-[0.6rem] rounded-[1000px] w-[8.8rem] cursor-pointer`}
                            >
                                <Text align="left" color="#9CA3AF" size="1.4rem" weight="600">
                                    Yearly
                                </Text>
                            </div>
                        </div>
                    </div>
                    {activeTab === 1 && (
                        <div className="">
                            <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                                Bill Summary
                            </Text>
                            <Text align="left" size="1.4rem" color="#1F2937" weight="600" top="1.6rem">
                                {seats} seats
                            </Text>
                            <Text align="left" size="1.4rem" color="#6B7280" weight="600" top="0.4rem" bottom="4rem">
                                NGN {seats * billingPlans?.[0]?.displayAmount}
                            </Text>
                            <div className="h-[1px] bg-[#E5E7EB] w-[19.7rem]"></div>
                            <Text align="left" color="#1F2937" size="1.4rem" weight="600" top="0.8rem">
                                Total per month
                            </Text>
                            <Text align="left" color="#1F2937" size="1.9rem" weight="600">
                                NGN {seats * billingPlans?.[0]?.displayAmount}
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="500"
                                top="1.4rem"
                                lineHeight="1.7rem"
                            >
                                Prorated charge today
                            </Text>
                            <Text align="left" color="#059669" size="1.2rem" weight="500" lineHeight="1.7rem">
                                $0
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0.8rem"
                                bottom="0rem"
                                lineHeight="1.7rem"
                            >
                                Next Renewal is on <span className="text-[1.2rem] font-medium text-[#6B7280]">[]</span>
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0rem"
                                lineHeight="1.7rem"
                            >
                                and you’ll be charged{' '}
                                <span className="text-[1.2rem] font-medium text-[#059669]">[]</span>
                            </Text>
                            <Button
                                onClick={onMonthlyConfirm}
                                bgColor="#6366F1"
                                color="#fff"
                                className="mx-[auto] mt-[3rem]"
                            >
                                Confirm Changes
                            </Button>
                        </div>
                    )}
                    {activeTab === 2 && (
                        <div className="">
                            <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                                Bill Summary
                            </Text>
                            <Text align="left" size="1.4rem" color="#1F2937" weight="600" top="1.6rem">
                                {seats} Seats
                            </Text>
                            <Text align="left" size="1.4rem" color="#6B7280" weight="600" top="0.4rem" bottom="4rem">
                                NGN {seats * billingPlans?.[0]?.displayAmount * 12}
                            </Text>
                            <div className="h-[1px] bg-[#E5E7EB] w-[19.7rem]"></div>
                            <Text align="left" color="#1F2937" size="1.4rem" weight="600" top="0.8rem">
                                Total per year
                            </Text>
                            <Text align="left" color="#1F2937" size="1.9rem" weight="600">
                                NGN {seats * billingPlans?.[0]?.displayAmount * 12}
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="500"
                                top="1.4rem"
                                lineHeight="1.7rem"
                            >
                                Prorated charge today
                            </Text>
                            <Text align="left" color="#059669" size="1.2rem" weight="500" lineHeight="1.7rem">
                                $0
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0.8rem"
                                bottom="0rem"
                                lineHeight="1.7rem"
                            >
                                Next Renewal is on <span className="text-[1.2rem] font-medium text-[#6B7280]">[]</span>
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0rem"
                                lineHeight="1.7rem"
                            >
                                and you’ll be charged{' '}
                                <span className="text-[1.2rem] font-medium text-[#059669]">[]</span>
                            </Text>
                            <Button
                                onClick={onYearlyConfirm}
                                bgColor="#6366F1"
                                color="#fff"
                                className="mx-[auto] mt-[3rem]"
                            >
                                Confirm Changes
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Checkout;
