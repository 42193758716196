import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { GoBack } from '../../../../components/go-back';
import PDFPreview from '../../../../components/pdf-preview';
import { StatusContainer, getStatusStyles } from '../../../../components/shared';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../../utils';

const MainContainer = tw.div`
    mt-8
    mb-[6.4rem]
    rounded-lg
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;
const PdfDetails = tw.div`
    bg-white
    rounded-lg
    mt-[2rem]
`;
const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const HandbookDetails = () => {
    const navigate = useNavigate();
    const {
        state: { session, handbook },
    } = useLocation();

    const { title, description, status, selectType, handbookUpload } = handbook || {};

    return (
        <div>
            <div className="mb-[3.2rem]">
                <GoBack
                    title="Handbook"
                    backAction={() => navigate('/registry/handbook', { state: { session } })}
                    subTitle={`${capitalizeFirstLetter(title)}`}
                />
            </div>
            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Overview
                            </PageTitle>
                        </FlexCentredRow>

                        <FlexDiv className="justify-end gap-[3.2rem]">
                            <StatusContainer
                                className="flex items-center"
                                style={getStatusStyles(status?.toLowerCase())}
                            >
                                <Text
                                    weight="500"
                                    lineHeight="2.4rem"
                                    color={getStatusStyles(status?.toLowerCase())?.color}
                                >
                                    {status}
                                </Text>
                            </StatusContainer>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Title</GreyText>
                                <DarkText>{capitalizeFirstLetter(title)}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Type</GreyText>
                                <DarkText>{selectType}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Description</GreyText>
                                <DarkText>{capitalizeFirstLetter(description)}</DarkText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>
                <PdfDetails>
                    <PDFPreview url={handbookUpload?.url} pageNumber={1} pdfTitle={capitalizeFirstLetter(title)} />
                </PdfDetails>
            </MainContainer>
        </div>
    );
};

export default HandbookDetails;
