import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    facultyProgrammes: [],
    departmentProgrammes: [],
    currentProgramme: {},
    students: [],
};

export const programmesSlice = createSlice({
    name: 'programmes',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_FACULTY_PROGRAMMES: (state, action) => {
            state.facultyProgrammes = action.payload;
        },
        GET_DEPARTMENT_PROGRAMMES: (state, action) => {
            state.departmentProgrammes = action.payload;
        },
        SAVE_CURRENT_PROGRAMME: (state, action) => {
            state.currentProgramme = action.payload;
        },
        GET_PROGRAMME_STUDENTS: (state, action) => {
            state.students = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_FACULTY_PROGRAMMES,
    GET_DEPARTMENT_PROGRAMMES,
    SAVE_CURRENT_PROGRAMME,
    GET_PROGRAMME_STUDENTS,
} = programmesSlice.actions;

export default programmesSlice.reducer;
