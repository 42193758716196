import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allNotices: [],
    allMails: [],
    allInternalMemos: [],
    allExternalMemos: [],
    allBulletins: [],
    allCategories: [],
    sentExternalMemos: [],
    externalMemo: {},
    bulletin: {},
    receivedNotice: [],
    notice: {},
    mail: {},
    sentInternalMemo: [],
    internalMemo: {},
    sentNotice: [],
    sentMail: [],
    sentBulletin: [],
    allReceived: [],
    allSent: [],
    allDraft: [],
    currentDraft: {},
    commsSettings: {},
};

export const communicationsSlice = createSlice({
    name: 'communications',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_NOTICES: (state, action) => {
            state.allNotices = action.payload;
        },
        GET_ALL_MAILS: (state, action) => {
            state.allMails = action.payload;
        },
        GET_ALL_INTERNAL_MEMOS: (state, action) => {
            state.allInternalMemos = action.payload;
        },
        GET_ALL_EXTERNAL_MEMOS: (state, action) => {
            state.allExternalMemos = action.payload;
        },
        GET_ALL_BULLETINS: (state, action) => {
            state.allBulletins = action.payload;
        },
        GET_ALL_CATEGRORIES: (state, action) => {
            state.allCategories = action.payload;
        },
        GET_SENT_EXTERNAL_MEMOS: (state, action) => {
            state.sentExternalMemos = action.payload;
        },
        GET_EXTERNAL_MEMO: (state, action) => {
            state.externalMemo = action.payload;
        },
        GET_BULLETIN: (state, action) => {
            state.bulletin = action.payload;
        },
        GET_RECEIVED_NOTICE: (state, action) => {
            state.receivedNotice = action.payload;
        },
        GET_NOTICE: (state, action) => {
            state.notice = action.payload;
        },
        GET_MAIL: (state, action) => {
            state.mail = action.payload;
        },
        GET_SENT_INTERNAL_MEMO: (state, action) => {
            state.sentInternalMemo = action.payload;
        },
        GET_INTERNAL_MEMO: (state, action) => {
            state.internalMemo = action.payload;
        },
        GET_SENT_NOTICE: (state, action) => {
            state.sentNotice = action.payload;
        },
        GET_SENT_MAIL: (state, action) => {
            state.sentMail = action.payload;
        },
        GET_SENT_BULLETIN: (state, action) => {
            state.sentBulletin = action.payload;
        },
        GET_ALL_SENT: (state, action) => {
            state.allSent = action.payload;
        },
        GET_ALL_RECEIVED: (state, action) => {
            state.allReceived = action.payload;
        },
        GET_ALL_DRAFT: (state, action) => {
            state.allDraft = action.payload;
        },
        SAVE_CURRENT_DRAFT: (state, action) => {
            state.currentDraft = action.payload;
        },
        GET_COMMS_SETTINGS: (state, action) => {
            state.commsSettings = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_ALL_BULLETINS,
    GET_ALL_EXTERNAL_MEMOS,
    GET_ALL_INTERNAL_MEMOS,
    GET_ALL_MAILS,
    GET_ALL_NOTICES,
    GET_ALL_CATEGRORIES,
    GET_SENT_EXTERNAL_MEMOS,
    GET_EXTERNAL_MEMO,
    GET_BULLETIN,
    GET_RECEIVED_NOTICE,
    GET_NOTICE,
    GET_MAIL,
    GET_SENT_INTERNAL_MEMO,
    GET_INTERNAL_MEMO,
    GET_SENT_NOTICE,
    GET_SENT_MAIL,
    GET_SENT_BULLETIN,
    GET_ALL_DRAFT,
    GET_ALL_RECEIVED,
    GET_ALL_SENT,
    SAVE_CURRENT_DRAFT,
    GET_COMMS_SETTINGS,
} = communicationsSlice.actions;

export default communicationsSlice.reducer;
