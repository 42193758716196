import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { CancelButton, SubmitButton } from '../../../components/forms/sharedStyles';
import { Text } from '../../../containers/MesssageContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getCommunicationsSettings, sendCommunicationsSettings } from '../../../redux/communications/actions';
import { getStaffInUnit } from '../../../redux/staff/actions';

import SettingsForm from './settings-form';

const CommsSettings = () => {
    const dispatch = useDispatch();
    const { unitStaffs } = useSelector((store) => store.staff);
    const { currentUnit } = useSelector((store) => store.organisationUnits);

    const [commsSettings, setCommsSettings] = useState(null);
    const [checkedFields, setCheckedFields] = useState([]);
    const [checkedMailFields, setCheckedMailFields] = useState([]);
    const [checkedNoticeFields, setCheckedNoticeFields] = useState([]);
    const [checkedInternalMemoFields, setCheckedInternalMemoFields] = useState([]);
    const [publishers, setPublishers] = useState({ bulletin: [], mail: [], internalMemo: [], notice: [] });
    const [deleters, setDeleters] = useState({ bulletin: [], mail: [], internalMemo: [], notice: [] });
    const [numberValue, setNumberValue] = useState({ bulletin: 0, mail: 0, notice: 0, memo: 0 });

    const modifiedStaffs = unitStaffs?.map((staff) => {
        return {
            name: `${staff?.surname} ${staff?.firstName} ${staff?.otherName}`,
            ...staff,
        };
    });

    const mapFields = (selectedFields, updateFields, criterion) => {
        if (selectedFields?.includes(criterion)) {
            updateFields((prev) => prev?.filter((field) => field !== criterion));
        } else {
            updateFields((prev) => [...prev, criterion]);
        }
    };

    console.log('checked', checkedInternalMemoFields);

    const handleChangePublish = (formName) => {
        if (formName === 'Bulletin') {
            mapFields(checkedFields, setCheckedFields, 'publish');
        }
        if (formName === 'Internal Memo') {
            mapFields(checkedInternalMemoFields, setCheckedInternalMemoFields, 'publish');
        }
        if (formName === 'Mail') {
            mapFields(checkedMailFields, setCheckedMailFields, 'publish');
        }
        if (formName === 'Notice') {
            mapFields(checkedNoticeFields, setCheckedNoticeFields, 'publish');
        }
    };

    const handleChangeRestrict = (formName) => {
        if (formName === 'Bulletin') {
            mapFields(checkedFields, setCheckedFields, 'restrictedPublishers');
        }
        if (formName === 'Internal Memo') {
            mapFields(checkedInternalMemoFields, setCheckedInternalMemoFields, 'restrictedPublishers');
        }
        if (formName === 'Mail') {
            mapFields(checkedMailFields, setCheckedMailFields, 'restrictedPublishers');
        }
        if (formName === 'Notice') {
            mapFields(checkedNoticeFields, setCheckedNoticeFields, 'restrictedPublishers');
        }
    };

    const handleChangeReview = (formName) => {
        if (formName === 'Bulletin') {
            mapFields(checkedFields, setCheckedFields, 'review');
        }
        if (formName === 'Internal Memo') {
            mapFields(checkedInternalMemoFields, setCheckedInternalMemoFields, 'review');
        }
        if (formName === 'Mail') {
            mapFields(checkedMailFields, setCheckedMailFields, 'review');
        }
        if (formName === 'Notice') {
            mapFields(checkedNoticeFields, setCheckedNoticeFields, 'review');
        }
    };

    const handleChangeDelete = (formName) => {
        if (formName === 'Bulletin') {
            mapFields(checkedFields, setCheckedFields, 'restrictedDeleters');
        }
        if (formName === 'Internal Memo') {
            mapFields(checkedInternalMemoFields, setCheckedInternalMemoFields, 'restrictedDeleters');
        }
        if (formName === 'Mail') {
            mapFields(checkedMailFields, setCheckedMailFields, 'restrictedDeleters');
        }
        if (formName === 'Notice') {
            mapFields(checkedNoticeFields, setCheckedNoticeFields, 'restrictedDeleters');
        }
    };

    useEffect(() => {
        const { bulletin } = commsSettings || {};
        if (bulletin) {
            if (bulletin?.allowComposerToPublish) {
                handleChangePublish('Bulletin');
            }
            if (bulletin?.allowReview) {
                handleChangeReview('Bulletin');
            }
            if (bulletin?.deleteStatus) {
                handleChangeDelete('Bulletin');
            }
            if (bulletin?.publishStatus) {
                handleChangeRestrict('Bulletin');
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { internalMemo } = commsSettings || {};
        if (internalMemo) {
            if (internalMemo?.allowComposerToPublish) {
                handleChangePublish('Internal Memo');
            }
            if (internalMemo?.allowReview) {
                handleChangeReview('Internal Memo');
            }
            if (internalMemo?.deleteStatus) {
                handleChangeDelete('Internal Memo');
            }
            if (internalMemo?.publishStatus || internalMemo?.restrictedPublishers) {
                handleChangeRestrict('Internal Memo');
            }
        }
        //eslint-disable-next-line
    }, []);

    const publishingForm = [
        {
            name: 'publish',
            title: 'Allow the composer to publish?',
            subtitle: 'When enabled, the composer would be able to publish also.',
            onChange: (name) => handleChangePublish(name),
        },
        {
            name: 'restrictedPublishers',
            title: 'Restrict who can publish communications',
            subtitle: 'When enabled, only selected persons can publish.',
            showSelect: true,
            onChange: handleChangeRestrict,
            selectData: modifiedStaffs,
        },
        {
            name: 'review',
            title: 'Minimum number of reviewers before publishing',
            subtitle: 'When enabled, the composer would be able to publish also.',
            isNumber: true,
            onChange: handleChangeReview,
        },
        {
            name: 'restrictedDeleters',
            title: 'Restrict who can delete received communication',
            showSelect: true,
            onChange: handleChangeDelete,
            selectData: modifiedStaffs,
        },
    ];

    const onBulletinSelectChange = (selected, form) => {
        if (form === 'restrictedPublishers') {
            setPublishers((prev) => ({ ...prev, bulletin: selected }));
        } else {
            setDeleters((prev) => ({ ...prev, bulletin: selected }));
        }
    };

    useEffect(() => {
        const { bulletin } = commsSettings || {};
        if (bulletin?.restrictedPublishers?.length > 0) {
            setPublishers([...bulletin.restrictedPublishers]);
        }
    }, [commsSettings]);

    useEffect(() => {
        const { internalMemo } = commsSettings || {};
        if (internalMemo?.restrictedPublishers?.length > 0) {
            setPublishers([...internalMemo.restrictedPublishers]);
        }
    }, [commsSettings]);

    const onMemoSelectChange = (selected, form) => {
        if (form === 'restrictedPublishers') {
            setPublishers((prev) => ({ ...prev, internalMemo: selected }));
        } else {
            setDeleters((prev) => ({ ...prev, internalMemo: selected }));
        }
    };

    const onMailSelectChange = (selected, form) => {
        if (form === 'restrictedPublishers') {
            setPublishers((prev) => ({ ...prev, mail: selected }));
        } else {
            setDeleters((prev) => ({ ...prev, mail: selected }));
        }
    };

    const onNoticeSelectChange = (selected, form) => {
        if (form === 'restrictedPublishers') {
            setPublishers((prev) => ({ ...prev, notice: selected }));
        } else {
            setDeleters((prev) => ({ ...prev, notice: selected }));
        }
    };

    useEffect(() => {
        dispatch(getStaffInUnit());
    }, [dispatch]);

    const getCommsSettings = async () => {
        const res = await dispatch(getCommunicationsSettings());
        setCommsSettings(res);
    };

    useEffect(() => {
        getCommsSettings();
        //eslint-disable-next-line
    }, []);

    const submitComposeSettings = () => {
        const payload = {
            unitName: currentUnit?.unitName,
            bulletin: {
                allowComposerToPublish: checkedFields?.includes('publish') ? true : false,
                publishStatus: checkedFields?.includes('restrictedublishers') ? true : false,
                deleteStatus: checkedFields?.includes('restrictedDeleters') ? true : false,
                allowReview: checkedFields?.includes('review') ? true : false,
                restrictedPublishers: publishers.bulletin,
                minReviewersBeforePublishing: numberValue.bulletin,
                restrictedDeleters: deleters.bulletin,
            },
            internalMemo: {
                allowComposerToPublish: checkedInternalMemoFields?.includes('publish') ? true : false,
                publishStatus: checkedInternalMemoFields?.includes('restrictedublishers') ? true : false,
                deleteStatus: checkedInternalMemoFields?.includes('restrictedDeleters') ? true : false,
                allowReview: checkedInternalMemoFields?.includes('review') ? true : false,
                restrictedPublishers: publishers.internalMemo,
                minReviewersBeforePublishing: 1,
                restrictedDeleters: deleters.internalMemo,
            },
        };
        dispatch(sendCommunicationsSettings(payload));
    };

    return (
        <div>
            <PageLayout backTitle="Settings / Configure publishing rights" pageTitle="Configure Publishing Rights" />
            <div className="mt-[3.5rem] px-[3.2rem] pt-[3.6rem] pb-[1.5rem] bg-white rounded-[16px]">
                <div className="flex items-center gap-[3.2rem]">
                    <PeopleIcon />
                    <Text align="left" size="1.6rem" weight="600" color="#1F2937">
                        Configure publishing rights
                    </Text>
                </div>
                <SettingsForm
                    checked={checkedFields}
                    numberValue={numberValue.bulletin}
                    onNumberChange={(e) => setNumberValue((prev) => ({ ...prev, bulletin: e.target.value }))}
                    formName="Bulletin"
                    publishingForm={publishingForm}
                    onSelectChange={(selected, form) => onBulletinSelectChange(selected, form)}
                    selectedItems={commsSettings?.bulletin}
                />
                <SettingsForm
                    checked={checkedInternalMemoFields}
                    numberValue={numberValue.memo}
                    onNumberChange={(e) => setNumberValue((prev) => ({ ...prev, memo: e.target.value }))}
                    formName="Internal Memo"
                    publishingForm={publishingForm}
                    onSelectChange={(selected, form) => onMemoSelectChange(selected, form)}
                    selectedItems={commsSettings?.internalMemo}
                />
                <SettingsForm
                    checked={checkedNoticeFields}
                    numberValue={numberValue.notice}
                    onNumberChange={(e) => setNumberValue((prev) => ({ ...prev, notice: e.target.value }))}
                    formName="Notice"
                    publishingForm={publishingForm}
                    onSelectChange={(selected, form) => onNoticeSelectChange(selected, form)}
                />
                <SettingsForm
                    checked={checkedMailFields}
                    numberValue={numberValue.mail}
                    onNumberChange={(e) => setNumberValue((prev) => ({ ...prev, mail: e.target.value }))}
                    formName="Mail"
                    publishingForm={publishingForm}
                    onSelectChange={(selected, form) => onMailSelectChange(selected, form)}
                />
                <div className="flex items-center gap-[8px] justify-end mt-[1rem]">
                    <CancelButton>Cancel</CancelButton>
                    <SubmitButton onClick={submitComposeSettings}>Submit</SubmitButton>
                </div>
            </div>
        </div>
    );
};

export default CommsSettings;
