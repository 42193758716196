import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { getFacultyDepartments } from '../../../redux/faculty/actions';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddHODs = ({ show, setShow, btnAction, setDetails }) => {
    const dispatch = useDispatch();

    const { allStaff } = useSelector((store) => store.staff);
    const { faculties, departments, isLoading } = useSelector((store) => store.faculty);

    const onClose = () => setShow(false);
    const [facId, setFacId] = useState('');
    const [facName, setFacName] = useState('');

    useEffect(() => {
        if (facName) {
            const selectedFac = faculties.find((item) => item.facultyName === facName);
            setFacId(selectedFac._id);
        }
    }, [facName, faculties]);

    useEffect(() => {
        if (facId) {
            dispatch(getFacultyDepartments(facId));
        }
    }, [facId, dispatch]);

    return (
        <>
            <FormContainer close={onClose} show={show} title={'Add Appointment'}>
                <Formik
                    initialValues={{
                        appointmentType: '',
                        startDate: '',
                        endDate: '',
                        staffNo: '',
                        name: '',
                        facultyName: '',
                        departmentName: '',
                    }}
                    onSubmit={async (values, action) => {
                        values.facultyName = facName;

                        setDetails(values);
                    }}
                >
                    {({ errors, handleChange, touched, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <Select
                                    name="name"
                                    objProp="fullName"
                                    placeholder="Select Name"
                                    label="Select Name"
                                    data={allStaff}
                                    onChange={(selected) => {
                                        setFieldValue('name', String(selected[0].fullName));
                                    }}
                                    error={errors.name}
                                />
                                <Select
                                    name="facultyName"
                                    objProp="facultyName"
                                    placeholder="Select Faculty"
                                    label="Faculty"
                                    data={faculties}
                                    onChange={(selected) => {
                                        setFieldValue('facultyName', String(selected[0].facultyName));
                                        setFacName(String(selected[0].facultyName));
                                    }}
                                    error={errors.facultyName}
                                />
                                <Select
                                    name="departmentName"
                                    objProp="departmentName"
                                    placeholder="Select Department"
                                    label="Department"
                                    isLoading={isLoading}
                                    data={departments}
                                    onChange={(selected) => {
                                        setFieldValue('departmentName', String(selected[0].departmentName));
                                    }}
                                    error={errors.departmentName}
                                />
                                <DatePicker label="Start Date (Optional)" name="startDate" touched={touched} />
                                <DatePicker label="End Date (Optional)" name="endDate" touched={touched} />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <SubmitButton type="submit" onClick={btnAction}>
                                    Appoint Staff
                                </SubmitButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddHODs;
