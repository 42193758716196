import React, { useState } from 'react';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Checkbox from '../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../components/list-empty';
import { CountBadge, getStatusStyles, StatusContainer } from '../../../../components/shared';
import { Text } from '../../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
    },
    {
        header: 'Full Name',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'name',
    },
    {
        header: 'Designation',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'designation',
    },
    {
        header: 'Email',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'staffEmail',
    },
    {
        header: 'Emp. Year',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'employmentYear',
    },
    {
        header: 'Status',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'staff_status',
    },
];

const InactiveStaff = ({ handleSelect, selectedItems }) => {
    const isLoading = useSelector((state) => state.staff.isLoading);
    const staff = useSelector((state) => state.staff.deactivatedStaff);

    const [searchValue, setSearchValue] = useState('');

    const modifiedStaff = staff?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox checked={selectedItems?.some((ite) => ite._id === item._id)} />
                </td>
            ),
            name: (
                <td style={{ color: '#2563EB' }}>
                    <Link to={item._id}>{`${item.fullName}`}</Link>
                </td>
            ),
            unit: (
                <td>
                    <div className="flex items-center gap-[4px]">
                        <Text color="#6b728">{item.unitName?.[0] || '-'}</Text>
                        {item.unitName?.length > 1 ? <CountBadge>+{item.unitName?.length - 1}</CountBadge> : null}
                    </div>
                </td>
            ),
            staff_status: (
                <td>
                    <StatusContainer style={getStatusStyles(item.staffStatus.toLowerCase())}>
                        <span>{item.staffStatus}</span>
                    </StatusContainer>
                </td>
            ),
            employmentYear: <td>{format(new Date(item?.yearOfEmployment), 'PP')}</td>,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedStaff || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Inactive Staff"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => {
                    const selectedItem = staff?.find((el) => el?._id === item?._id);
                    handleSelect(selectedItem);
                }}
                isActionable
            />
        </>
    );
};

export default InactiveStaff;
