import React, { useState } from 'react';

import { useNavigate } from 'react-router';
import tw from 'twin.macro';


import InputContainer from '../../../components/Container/inputcontainer';
import PasswordConfirmation from '../../../components/forms/password-confirm';
import SuccessPopup from '../../../components/popups/success';
import { PoweredBy } from '../../../components/powered-by';

export const AuthContainer = tw.section`
  bg-[#fff]
  flex
  justify-center
  items-center
  min-h-screen
  max-h-screen
  overflow-y-auto
  bg-center
  w-full
  pt-[10vh]
  pb-[10vh]
`;





const ConfirmPassword = () => {
    const navigate = useNavigate();
    const [openSuccess, setOpenSuccess] = useState(false);

    return (
        <AuthContainer>

            <InputContainer>
                <PasswordConfirmation setOpenSuccess={setOpenSuccess} />
                <PoweredBy />

                <SuccessPopup
                    show={openSuccess}
                    title="Awesome"
                    onClose={() => navigate('/sign-in-staff')}
                    subtitle="Your password has been successfully changed. Kindly Sign In to proceed."
                    btnText="Sign In"
                />


            </InputContainer>
        </AuthContainer>
    );
};
export default ConfirmPassword;
