import CreateExamTimetable from '../../components/forms/create-exam-timetable';
import CreateLectureTimeTable from '../../components/forms/create-lecture-timetable';
import { FACULTY } from '../../utils/unitNames';
import {
    ExamTimetable,
    Faculties,
    // FacultyAdmin,
    FacultyCourses,
    FacultyDepartments,
    FacultyPersonnels,
    FacultyStudents,
    LectureTimetable,
    CreateRole,
    EditAdministrator,
    Received,
    Roles,
    // AddAdministrator,
    Administrator,
    AcademicCalendar,
    StudentDetail,
    StaffDetail,
    EditRole,
    FacultySettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    Academics,
    AddFacultyGeneralCourse,
    Access,
    AssignRole,
    ClassOfDegreeSetting,
    GradeSetting,
    WeightedGradePointSetting,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    CourseAllocation,
    ViewCommunications,
} from '../../views';

export const facultyRoutes = [
    {
        path: '/academics',
        element: <Academics unitName={FACULTY} />,
    },
    {
        path: '/academics/faculties',
        element: <Faculties unitName={FACULTY} />,
    },
    {
        path: '/faculties/departments',
        element: <FacultyDepartments />,
    },
    {
        path: '/faculties/access',
        element: (
            <Access
                staffs={<FacultyPersonnels />}
                administrators={<Administrator unitName={FACULTY} />}
                roles={<Roles unitName={FACULTY} />}
            />
        ),
    },
    // {
    //     path: '/faculties/admin',
    //     element: <FacultyAdmin />,
    // },
    {
        path: '/faculties/access/create-role',
        element: <CreateRole unitName={FACULTY} />,
    },
    {
        path: '/faculties/access/edit-role',
        element: <EditRole unitName={FACULTY} />,
    },
    {
        path: '/faculties/access/add-administrator',
        // element: <AddAdministrator unitName={FACULTY} />,
        element: <AssignRole unitName={FACULTY} />,
    },
    {
        path: '/faculties/access/edit-administrator',
        element: <EditAdministrator unitName={FACULTY} />,
    },
    {
        path: '/faculties/courses',
        element: <FacultyCourses />,
    },
    {
        path: '/faculties/courses/add-new',
        element: <AddFacultyGeneralCourse action="Add" />,
    },
    {
        path: '/faculties/courses/edit-course/:id',
        element: <AddFacultyGeneralCourse action="Edit" />,
    },
    {
        path: '/faculties/allocate-general-courses',
        element: <CourseAllocation pageTitle="Allocate General Courses" isFirstDegreeAllocation />,
    },
    {
        path: '/faculties/students',
        element: <FacultyStudents />,
    },
    {
        path: '/faculties/students/:id',
        element: <StudentDetail />,
    },
    {
        path: '/faculties/exam-timetable',
        element: <ExamTimetable />,
    },
    {
        path: '/faculties/lecture-timetable',
        element: <LectureTimetable />,
    },
    {
        path: '/faculties/lecture-timetable/create',
        element: <CreateLectureTimeTable />,
    },
    {
        path: '/faculties/exam-timetable/create',
        element: <CreateExamTimetable />,
    },
    {
        path: '/faculties/communications/received',
        element: <Received unitName={FACULTY} />,
    },
    {
        path: '/faculties/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/faculties/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/faculties/settings',
        element: <FacultySettings />,
    },
    {
        path: '/faculties/settings/class-of-degree',
        element: <ClassOfDegreeSetting />,
    },
    {
        path: '/faculties/settings/grade',
        element: <GradeSetting />,
    },
    {
        path: '/faculties/settings/weighted-grade-point',
        element: <WeightedGradePointSetting />,
    },
    {
        path: '/faculties/communications/distribution-list',
        element: <DistributionList unitName={FACULTY} title="Communications" />,
    },
    {
        path: '/faculties/communications/distribution-list/add',
        element: <AddDistributionList unitName={FACULTY} title="Communications" />,
    },
    {
        path: '/faculties/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={FACULTY} title="Communications" />,
    },
    {
        path: '/faculties/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/faculties/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/faculties/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/faculties/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
