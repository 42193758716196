import React from 'react';

import { ReactComponent as People } from '../../../assets/icons/people2.svg';
import { Text } from '../../../containers/MesssageContainers';
import {
    BasicContent,
    DetailsCardSubText,
    DetailsCardTitleText,
    RowContainer,
} from '../../../containers/RecordContainers';
import { FlexColumn } from '../../../containers/ScreenContainers';

const DetailsBox = ({
    heading,
    dataBadge,
    leftData = [],
    rightData = [],
    badgeClass,
    badgeColor,
    children,
    icon,
    className,
}) => {
    return (
        <div className={`p-[2.4rem] bg-white rounded-[8px] gap-[2.8rem] flex flex-col h-max ${className}`}>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                    {icon ? icon : <People />}
                    <Text weight="600" size="1.8rem" align="left" lineHeight="2.8rem">
                        {heading}
                    </Text>
                </div>
                {dataBadge && (
                    <div className={`py-[2px] px-[4px] rounded-[4px] ${badgeClass}`}>
                        <Text weight="500" size="1.2rem" color={badgeColor}>
                            {dataBadge}
                        </Text>
                    </div>
                )}
            </div>
            {leftData?.length > 0 || rightData?.length > 0 ? (
                <BasicContent>
                    <RowContainer className="lg:text-left text-center">
                        {leftData?.map((item) => (
                            <FlexColumn key={item.title} className="">
                                <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                {Array.isArray(item?.subtitle) ? (
                                    item?.subtitle?.map((subItem, i) => (
                                        <DetailsCardSubText key={i}>{subItem}</DetailsCardSubText>
                                    ))
                                ) : (
                                    <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                )}
                            </FlexColumn>
                        ))}
                    </RowContainer>
                    <RowContainer className="lg:text-right text-center">
                        {rightData?.map((item) => (
                            <FlexColumn key={item.title} className="">
                                <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                {Array.isArray(item?.subtitle) ? (
                                    item?.subtitle?.map((subItem, i) => (
                                        <DetailsCardSubText align="right" key={i}>
                                            {subItem}
                                        </DetailsCardSubText>
                                    ))
                                ) : (
                                    <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                )}
                            </FlexColumn>
                        ))}
                    </RowContainer>
                </BasicContent>
            ) : (
                <div className="">{children}</div>
            )}
        </div>
    );
};

export default DetailsBox;
