import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import {
    GET_PROBATION_SETTINGS,
    LOADING,
    STOP_LOADING,
    GET_ACADEMIC_STANDING_SETTINGS,
    GET_WITHDRAWAL_LIST_SETTINGS,
    GET_CUMULATIVE_UNITS_SETTINGS,
    GET_DEGREE_SETTINGS,
    GET_GRADE_SETTINGS,
    GET_MATRIC_SETTINGS,
    GET_MINIMUM_PROMOTION_CGPA,
    GET_WEIGHTED_GRADE_POINT_SETTINGS
} from './slice';

export const setCumulativeUnits =
    (structureId, departmentId, payload, useLoader = 'true') =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());

        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}setting/${currentUnit?._id}/cumulative_credit_unit/${structureId}/${departmentId}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCumulativeUnits = (academicStructureId, departmentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}setting/${currentUnit?._id}/last_degree_cumulative_credit_unit/${academicStructureId}/${departmentId}`,
        );
        if (response.status === 200) {
            dispatch(GET_CUMULATIVE_UNITS_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_CUMULATIVE_UNITS_SETTINGS({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createProbationListSetting =
    ({ session, academicStructureId, lastDegreeId, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}exam/${currentUnit?._id}/probation-list/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getProbationListSetting = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}exam/${currentUnit?._id}/probation-list/${academicStructureId}/${lastDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_PROBATION_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_PROBATION_SETTINGS({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getMinimumPromotionCGPA = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}exam/${currentUnit?._id}/get-minimum-promotion-settings/${academicStructureId}/${lastDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_MINIMUM_PROMOTION_CGPA(response.data.data));
        }
    } catch (error) {
        dispatch(GET_MINIMUM_PROMOTION_CGPA({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createMinimumPromotionCGPA =
    ({ session, academicStructureId, lastDegreeId, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}exam/${currentUnit?._id}/create-minimum-promotion-settings/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createAcademicStanding =
    ({ academicStructureId, lastDegreeId, session, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}exam/${currentUnit?._id}/academic-standing/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const getAcademicStandingSettings =
    (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}exam/${currentUnit?._id}/academic-standing/${academicStructureId}/${lastDegreeId}/${session}`,
            );
            if (response.status === 200) {
                dispatch(GET_ACADEMIC_STANDING_SETTINGS(response.data.data));
            }
        } catch (error) {
            dispatch(GET_ACADEMIC_STANDING_SETTINGS({}));
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createWithdrawalList =
    ({ academicStructureId, lastDegreeId, session, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}exam/${currentUnit?._id}/withdrawal-list/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getWithdrawalListSettings = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}exam/${currentUnit?._id}/withdrawal-list/${academicStructureId}/${lastDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_WITHDRAWAL_LIST_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_WITHDRAWAL_LIST_SETTINGS({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDegreeSetting = (params) => async (dispatch, getState) => {
    const { structureId, firstDegreeId, session, payload, useLoader = true } = params;

    if (useLoader) dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}exam/${currentUnit?._id}/degree-setting/${structureId}/${firstDegreeId}/${session}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDegreeSetting = (structureId, firstDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}exam/${currentUnit?._id}/degree-setting/${structureId}/${firstDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_DEGREE_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_DEGREE_SETTINGS({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createGradeSetting = (params) => async (dispatch, getState) => {
    const { structureId, firstDegreeId, session, payload, useLoader = true } = params;
    if (useLoader) dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}exam/${currentUnit?._id}/grade-setting/${structureId}/${firstDegreeId}/${session}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getGradeSetting = (structureId, firstDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}exam/${currentUnit?._id}/grade-setting/${structureId}/${firstDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_GRADE_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_GRADE_SETTINGS({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createWeightedGradePointSetting = (params) => async (dispatch, getState) => {
    const { structureId, firstDegreeId, session, payload, useLoader = true } = params;
    if (useLoader) dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}exam/${currentUnit?._id}/weighted-grade-point/${structureId}/${firstDegreeId}/${session}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getWeightedGradePointSetting = (structureId, firstDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}exam/${currentUnit?._id}/get-weighted-grade-point/${structureId}/${firstDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_WEIGHTED_GRADE_POINT_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_WEIGHTED_GRADE_POINT_SETTINGS({}));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const setMatricFormat =
    (payload, useLoader = false) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}setting/${currentUnit?._id}/set_matriculation_number`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getMatricFormat = () => async (dispatch, getState) => {
    dispatch(LOADING());
    dispatch(GET_MATRIC_SETTINGS({}));
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}setting/${currentUnit?._id}/get_matric_format`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const initialSerialNumberKey = Object.keys(data?.serialNumber || {})[0];
            const serialNumberValues = data?.serialNumber[initialSerialNumberKey] || [];

            const {
                _id,
                organizationId,
                sample,
                createdAt,
                updatedAt,
                __v,
                matricOrder,
                continuum,
                ...mainSettings
                // programAbbreviation,
                // "programAbbreviation": {
                //     "facultyCode": "",
                //     "departmentCode": ""
                // },
                // "session": "2023",
                // "serialNumber": 90,
                // "programType": "",
                // "separator": "/",
                // "startpoint": 9,
                // "separatorTwo": "/",
                // "separatorThree": "",
                // "separatorFour": "",
            } = data;
            dispatch(
                GET_MATRIC_SETTINGS({
                    _id,
                    organizationId,
                    sample,
                    createdAt,
                    updatedAt,
                    matricOrder,
                    continuum,
                    serialNumberValues,
                    settings: { ...mainSettings, serialNumber: initialSerialNumberKey },
                }),
            );
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
