import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Checkbox } from '../../../components';
import UploadFile from '../../../components/upload-file';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { capitalizeFirstLetter, formatDate, getStatusBadge, sortData } from '../../../utils';

const Notice = ({ tableHeader, tableData, selectedItem, handleSelect }) => {
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        const sortedData = sortData(tableData);
        setSortedData(sortedData);
    }, [tableData]);

    const NoticeData = sortedData?.map((notice) => {
        const formattedDate = formatDate(notice.createdAt);
        return {
            action: (
                <td>
                    <Checkbox checked={selectedItem?._id === notice?._id} />
                </td>
            ),
            subject: (
                <td
                    className="cursor-pointer text-[#2563EB]"
                    onClick={() => navigate(`view/${notice._id}`, { state: notice?.type })}
                >
                    {notice?.title}
                </td>
            ),
            time: formattedDate,
            commsStatus: getStatusBadge(capitalizeFirstLetter(notice.status === 'sent' ? 'Published' : notice?.status)),

            ...notice,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={NoticeData}
                fields={tableHeader}
                // loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
            />
            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default Notice;
