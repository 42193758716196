import React from 'react';

import EditStaffForm from '../../../../components/forms/edit-staff';
import { GoBack } from '../../../../components/go-back';

const EditStaff = () => {
    return (
        <>
            <GoBack title={`Staff Management`} subtitle="Edit Staff" />

            <EditStaffForm />
        </>
    );
};

export default EditStaff;
