import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import { setAcademicYearDuration } from '../../../redux/academic-calendar/actions';
import { getAcademicSessionDetails } from '../../../redux/admissions/actions';
import { formatDate } from '../../../utils/formatDate';
import { LoadingButton } from '../../buttons';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import SuccessPopup from '../../popups/success';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const BtnsContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const Separator = tw.div`
    flex
    flex-col
    gap-[2.8rem]
`;

const SectionLabel = tw.p`
    text-[#1f2937]
    font-semibold
    text-[1.6rem]
    mb-5
`;

const SetAdmissionYearDuration = ({ show, setShow }) => {
    const dispatch = useDispatch();

    const { sessions: admissionsSessions } = useSelector((state) => state.admission);

    const [openSuccess, setOpenSuccess] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sessionDetails, setSessionDetails] = useState(null);

    const { academicSession: { firstSemester = {}, secondSemester = {} } = {} } = sessionDetails || {};

    const onClose = () => setShow(false);

    useEffect(() => {
        if (!selectedSession) return;

        const getData = async () => {
            setIsLoading(true);
            const data = await dispatch(getAcademicSessionDetails(selectedSession?.admissionYear));
            if (data) {
                setSessionDetails(data);
            } else {
                setSessionDetails(null);
            }
            setIsLoading(false);
        };

        getData();
    }, [dispatch, selectedSession]);

    return (
        <>
            <FormContainer close={onClose} show={show} title={'Session Duration'}>
                <Formik
                    initialValues={{
                        sessionId: selectedSession?.id || '',
                        startDate: firstSemester?.startDate || '',
                        endDate: firstSemester?.endDate || '',
                        startDate2: secondSemester?.startDate2 || '',
                        endDate2: secondSemester?.endDate2 || '',
                    }}
                    enableReinitialize
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        setIsLoading(true);
                        const payload = {
                            first: 1,
                            second: 2,
                            startDate: formatDate(values?.startDate),
                            endDate: formatDate(values?.endDate),
                            startDate2: formatDate(values?.startDate2),
                            endDate2: formatDate(values?.endDate2),
                        };

                        const res = await dispatch(setAcademicYearDuration(selectedSession.id, payload));

                        if (res) {
                            toast.success('Academic year duration was set successfully');
                            actions.resetForm();
                            setSessionDetails(null);
                            setSelectedSession(null);
                            onClose();
                        }
                        setIsLoading(false);
                    }}
                >
                    {({ errors, handleChange, values, touched, setFieldValue }) => (
                        <Form>
                            {!admissionsSessions?.length > 0 && (
                                <Text align="left" color="red" size="1.2rem" bottom="1rem">
                                    You need to create at least one session.
                                </Text>
                            )}
                            <Separator>
                                <Select
                                    name="sessionId"
                                    objProp="admissionYear"
                                    placeholder="Select Session"
                                    data={admissionsSessions}
                                    useComponentState={false}
                                    onChange={(selected) => {
                                        setFieldValue('sessionId', selected[0].id);
                                        setSelectedSession(selected[0]);
                                    }}
                                    passedSelectedItems={
                                        selectedSession
                                            ? [{ admissionYear: selectedSession?.admissionYear || '' }]
                                            : null
                                    }
                                    error={errors.sessionId}
                                />

                                <div>
                                    <SectionLabel>First Semester</SectionLabel>
                                    <div className="gap-12 flex flex-col">
                                        <DatePicker
                                            name="startDate"
                                            label="Start date"
                                            touched={touched}
                                            initialValue={
                                                firstSemester?.startDate ? new Date(firstSemester?.startDate) : null
                                            }
                                        />
                                        <DatePicker
                                            name="endDate"
                                            label="End date"
                                            touched={touched}
                                            minDate={new Date(values?.startDate)}
                                            initialValue={
                                                firstSemester?.endDate ? new Date(firstSemester?.endDate) : null
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <SectionLabel>Second Semester</SectionLabel>
                                    <div className="gap-12 flex flex-col">
                                        <DatePicker
                                            name="startDate2"
                                            touched={touched}
                                            label="Start date"
                                            minDate={new Date(values?.endDate)}
                                            initialValue={
                                                secondSemester?.startDate2 ? new Date(secondSemester?.startDate2) : null
                                            }
                                        />
                                        <DatePicker
                                            name="endDate2"
                                            touched={touched}
                                            label="End date"
                                            minDate={new Date(values?.startDate2)}
                                            initialValue={
                                                secondSemester?.endDate2 ? new Date(secondSemester?.endDate2) : null
                                            }
                                        />
                                    </div>
                                </div>
                            </Separator>
                            <BtnsContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Create
                                </LoadingButton>
                            </BtnsContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>

            <SuccessPopup
                show={openSuccess}
                onClose={() => setOpenSuccess(false)}
                title="Awesome!"
                subtitle="Student has been succesfully added"
                btnText="Done"
            />
        </>
    );
};

export default SetAdmissionYearDuration;
