/* eslint-disable no-unused-vars */
import React from 'react';

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as Pen } from '../../../assets/icons/pen.svg';
import { Button } from '../../../components/buttons';
import SelectInput from '../../../components/inputs/select-input';
import TextInput from '../../../components/inputs/text-input-with-formik';
import UploadFile2 from '../../../components/upload-file2';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexDiv, FlexCentredRow, FlexRowCentered } from '../../../containers/ScreenContainers';

// import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[2.4rem]
    flex
    flex-col
    gap-y-[3.2rem]
`;

const Label = tw.label`
    font-medium
    text-[1.4rem]
    text-[#1F2937]
`;

const NewHostel = () => {
    const navigate = useNavigate();

    // const [openModal, setOpenModal] = useState(false);
    // const closeModal = () => setOpenModal(false);

    return (
        // <Container>
        <Formik
            initialValues={{
                typeOfFamily: '',
            }}
            // validationSchema={ValidationSchema}
            onSubmit={(values, actions) => {
                console.log('first');
                // setOpenModal(true);
            }}
        >
            {({
                errors,
                // handleChange,
                // handleBlur,
                isSubmitting,
            }) => (
                <Form>
                    <PageTitle bottom="3.2rem" align="left">
                        Hostel Management
                    </PageTitle>

                    <Container>
                        <div>
                            <Label>Hostel Name</Label>
                            <TextInput name="name" type="text" placeholder="Akinlabi Hostel" errors={errors} />
                        </div>
                        <div>
                            <Label>Hostel Logo</Label>
                            <UploadFile2 />
                        </div>
                        <div>
                            <Label>Hostel Address</Label>
                            <TextInput
                                name="name"
                                type="text"
                                placeholder="121103, Ilishan-Remo, Babcock Univrsity Illishan Remo"
                                errors={errors}
                            />
                        </div>
                        <div>
                            <Label>Gender</Label>
                            <SelectInput>
                                <option>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                            </SelectInput>
                        </div>
                        <div>
                            <Label>Hostel Administrator's Phone Number</Label>
                            <TextInput name="name" type="number" placeholder="Phone Number" errors={errors} />
                        </div>
                        <div>
                            <Label>Hostel Administrator's Email Address</Label>
                            <TextInput name="name" type="text" placeholder="HAAdeniyi@babcock.edu.ng" errors={errors} />
                        </div>
                        <div>
                            <Label>About Organisation</Label>
                            <TextInput
                                as="textarea"
                                name="name"
                                type="text"
                                placeholder="Write a short information about the hostel"
                                errors={errors}
                                className="py-3"
                                height="6rem"
                            />
                        </div>

                        <div>
                            <Label>Upload Hostel Image(s)</Label>
                            <UploadFile2 />
                        </div>
                        <div>
                            <FlexCentredRow className="gap-x-[1rem]">
                                <Label>Number of Rooms in Block A/1</Label>
                                <Pen />
                            </FlexCentredRow>
                            <SelectInput>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                            </SelectInput>
                        </div>
                        <div>
                            <FlexCentredRow className="gap-x-[1rem]">
                                <Label>Number of Rooms in Block B/1</Label>
                                <Pen />
                            </FlexCentredRow>
                            <SelectInput>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                            </SelectInput>
                        </div>
                        <div>
                            <FlexCentredRow className="gap-x-[1rem]">
                                <Label>Number of Rooms in Block C/1</Label>
                                <Pen />
                            </FlexCentredRow>
                            <SelectInput>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                            </SelectInput>
                        </div>
                        <FlexRowCentered className="mt-[1rem]">
                            <Circularplus className="mr-[1.15rem]" />
                            <Text color="#2563EB" weight="500" lineHeight="2.4rem">
                                Add Another Block
                            </Text>
                        </FlexRowCentered>
                        <div className="flow-root mb-[3rem] mt-12">
                            <FlexDiv className="float-right gap-[1.45rem]">
                                <Button
                                    // disabled={isSubmitting}
                                    // onClick={() => navigate('/')}
                                    bgColor="#F3F4F6"
                                    // color="#D1D5DB"
                                    className="py-2 px-4  hover:bg-[#6366F1] focus:bg-[#6366F1] focus:text-white mb-[1.9rem] cursor-pointer"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    bgColor="#6366F1"
                                    color="#fff"
                                    // type="submit"
                                    // disabled={isSubmitting}
                                    onClick={() => navigate('/hostel/hostel-list')}
                                    className="py-2 px-4  cursor-pointer"
                                >
                                    Save
                                </Button>
                            </FlexDiv>
                        </div>
                    </Container>
                </Form>
            )}
        </Formik>
        // </Container>
    );
};

export default NewHostel;
