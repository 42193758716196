export const examsRecordsNavLinks = [
    { title: 'Exams & Records', to: '/exams-records/records' },
    { title: 'Results Backlog', to: '/exams-records/results-backlog' },
    { title: 'Manual Promotion', to: '/exams-records/manual-promotion' },
    {
        title: 'Academic Calendar',
        to: '/exams-records/academic-calendar',
    },
    {
        title: 'Communications',
        to: '/exams-records/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/exams-records/communications/compose',
            },
            {
                title: 'Received',
                to: '/exams-records/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/exams-records/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/exams-records/communications/settings',
            },
        ],
    },
    { title: 'Access', to: '/exams-records/access' },
    {
        title: 'Settings',
        to: '/exams-records/settings',
    },
];

export const examsRecordsRoutes = examsRecordsNavLinks.map((item) => item.to);
