import React, { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as DocumentIcon } from '../../../assets/icons/book.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { editHandbook } from '../../../redux/handbook/action';
import { Button, LoadingButton } from '../../buttons';
import { GoBack } from '../../go-back';
import Select from '../../inputs/new-select';
import TextAreaInput from '../../inputs/text-area';
import TextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;
const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const Details = tw.div`
flex
flex-row
border
border-dashed
border-[#D1D5DB]
rounded-[8px]
items-center
px-[.5rem]
py-[.5rem]
gap-[1rem]
`;

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const Type = ['Students', 'Academic Staff', 'Non-Academic Staff', 'All Staff'];

const EditHandbook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        state: { id, handbook: handbookDetails },
    } = useLocation();
    const hiddenFileInput = useRef(null);

    const { isLoading } = useSelector((state) => state.handbook);

    const [fileName, setFileName] = useState(handbookDetails?.handbookUpload?.fileName || '');

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {
        setFileName(handbookDetails?.handbookUpload?.fileName || 's');
    }, [handbookDetails]);

    return (
        <>
            <div className="mb-12">
                <GoBack title="Handbook" subtitle="Edit Handbook" />
            </div>
            <MainContainer>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        title: handbookDetails?.title || '',
                        selectType: handbookDetails?.selectType || '',
                        description: handbookDetails?.description || '',
                        handbookUpload: handbookDetails?.handbookUpload?.url || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const formData = new FormData();

                        formData.append('title', values.title);
                        formData.append('selectType', values.selectType);
                        formData.append('description', values.description);

                        formData.append('handbookUpload', values.handbookUpload.url);

                        const headers = {
                            'Content-Type': 'multi-part/form-data',
                        };

                        const res = await dispatch(editHandbook(formData, headers, id));
                        if (res) {
                            navigate(-1);
                            toast.success('Handbook updated successfully!');
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="mb-[3.2rem] mt-[1.2rem] gap-[0.715rem]">
                                <DocumentIcon />
                                <Title> Edit Handbook</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] gap-x-[3.2rem] grid grid-cols-2 ">
                                <TextInput
                                    name="title"
                                    type="text"
                                    label="Title"
                                    placeholder="input title"
                                    value={values.title}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <TextAreaInput
                                    name="description"
                                    label="Description"
                                    type="text"
                                    placeholder="Input Description"
                                    errorMessage={errors.description}
                                    onChange={(e) => {
                                        setFieldValue('description', e.target.value);
                                    }}
                                    value={values.description}
                                    height="6rem"
                                />
                                <Select
                                    name="selectType"
                                    objProp="selectType"
                                    placeholder="Select Type"
                                    label="Select Type"
                                    data={Type.map((item) => ({ selectType: item }))}
                                    onChange={(selected) => {
                                        setFieldValue('selectType', String(selected[0].selectType));
                                    }}
                                    error={errors.selectType}
                                    passedSelectedItems={values?.selectType}
                                />

                                <div>
                                    <Label>Upload Files</Label>
                                    <Details>
                                        <Button
                                            type="button"
                                            onClick={handleClick}
                                            className="px-[1.6rem] py-[0.8rem] border-dotted  border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                        >
                                            Upload Files
                                        </Button>
                                        <Text size="1.6rem">
                                            {handbookDetails?.handbookUpload || values.handbookUpload.url
                                                ? fileName || values.handbookUpload.url.split('/').pop()
                                                : 'Click to upload files'}
                                        </Text>
                                        <input
                                            name="handbookUpload"
                                            type="file"
                                            accept=".pdf"
                                            style={{ display: 'none' }}
                                            ref={hiddenFileInput}
                                            onChange={({ target: { files } }) => {
                                                if (files) {
                                                    setFieldValue('handbookUpload.url', files[0]);
                                                    setFileName(files[0]?.name);
                                                }
                                            }}
                                        />
                                    </Details>
                                </div>
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
                                    Update Handbook
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

export default EditHandbook;
