import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AddNewStudentForm from '../../../components/forms/add-new-student';
import { GoBack } from '../../../components/go-back';
import { getFaculties } from '../../../redux/faculty/actions';

const CreateStudent = () => {
    const location = useLocation();
    const session = location?.state;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFaculties());
    }, [dispatch]);

    return (
        <>
            <div className="mb-12">
                <GoBack title={`Admissions (${session})`} subTitle="Add New Student" />
            </div>

            <AddNewStudentForm session={location?.state} />
        </>
    );
};

export default CreateStudent;
