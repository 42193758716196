import React from 'react';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu2.svg';
import { Card } from '../../components';
import { Text } from '../../containers/MesssageContainers';
import { FlexDiv, FlexRowSpaceBetween } from '../../containers/ScreenContainers'

const CommunicationFeed = () => {
    return (
        <Card>
            <FlexRowSpaceBetween>
                <FlexDiv className="gap-3">
                    <Text size="1.6rem" weight="600">
                        Communication Feeds
                    </Text>
                    {/* // needed later */}
                    {/* <div className="bg-[#EF4444] w-[35px] h-[24px] rounded-full">
                        <Text color="#fff" weight="600">
                            9 +
                        </Text>
                    </div> */}
                </FlexDiv>
                <MenuIcon />
            </FlexRowSpaceBetween>
        </Card>
    );
};

export default CommunicationFeed;
