import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import { GET_ROLES, LOADING, STOP_LOADING } from './slice';

export const getRoles = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}role/${currentUnit?._id}/all_roles`);
        if (response.status === 200) {
            dispatch(GET_ROLES(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getRolesInUnit = (unitName, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/${currentUnit?._id}/get_role_in_unit/${currentUnit.unitName}?${queries}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createRole = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}role/${currentUnit?._id}/creat_role`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createRoleInUnit = (unitName, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}organizationUnit/${currentUnit?._id}/create_role_in_organization_unit/${unitName}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editRoleInUnit = (roleId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}role/${currentUnit?._id}/edit_and_addmore_permissions_to_role/${roleId}`,
            // `${currentAPI}role/${currentUnit?._id}/edit_and_addmore_permissions_to_role/63efea1db92d32499c1f7588`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteRole = (roleId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}role/${currentUnit?._id}/delete_role/${roleId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDepartmentRole = (deptId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}role/${currentUnit?._id}/creat_departmental_role/${deptId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getRolesInDepartment = (departmentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}role/${currentUnit?._id}/get_department_role_by_id/${departmentId}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const assignRoleInDepartment = (departmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}role/${currentUnit?._id}/assign_departmental_role/${departmentId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const assignRoleToStaff = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}role/${currentUnit?._id}/assign_role/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Administrator added successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editAssignedStaffRole = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}role/${currentUnit?._id}/edit_assigned_staffs_role/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Administrator updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const assignPlatformAdminRoleToStaff = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}role/${currentUnit?._id}/assign_platform_role/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStaffAsPlatformAdmin = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}role/${currentUnit?._id}/remove_staff_as_platform_administrator_role/${staffId}`,
            { role: 'Platform Administrator' },
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeAllRolesFromStaff = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}role/${currentUnit?._id}/remove_all_roles_assgned_staff/${staffId}`,
            { role: 'Platform Administrator' },
        );
        if (response.status === 200) {
            toast.success('Staff removed successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffWithRoles = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}role/${currentUnit?._id}/all_staffs?${queries}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
