import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { createCollege } from '../../../redux/college/actions';
import { createFaculty } from '../../../redux/faculty/actions';
import { capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddNewFacultyForm = ({ show, setShow }) => {
    const dispatch = useDispatch();
    const { currentStructure } = useSelector((state) => state.academicStructure);

    const selectData = currentStructure?.items?.map((item) => ({
        ...item,
        name: capitalizeFirstLetter(item.structure),
    }));

    const onClose = () => setShow(false);
    const [loading, setLoading] = useState(false);

    return (
        <>
            <FormContainer close={onClose} show={show} title={`Add New ${currentStructure?.name}`}>
                <Formik
                    initialValues={{
                        academicStructure: '',
                        facultyName: '',
                        facultyCode: '',
                        description: '',
                        facultyNumberingCode: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        setLoading(true);
                        const { facultyNumberingCode, facultyCode, facultyName, ...otherValues } = values;
                        let res;

                        if (currentStructure?.name?.toLowerCase() === 'faculty') {
                            const payload = {
                                facultyCode: facultyCode?.split(','),
                                ...otherValues,
                                facultyName: `${currentStructure?.name} of ${capitalizeFirstLetter(facultyName)}`,
                                ...(facultyNumberingCode ? { facultyNumberingCode } : {}),
                            };
                            res = await dispatch(createFaculty(payload, false));
                        }

                        if (currentStructure?.name?.toLowerCase() === 'college') {
                            const collegePaylod = {
                                academicStructure: otherValues.academicStructure,
                                collegeName: `${currentStructure?.name} of ${capitalizeFirstLetter(facultyName)}`,
                                collegeCode: facultyCode?.split(','),
                                description: otherValues.description,
                                ...(facultyNumberingCode ? { collegeNumberingCode: facultyNumberingCode } : {}),
                            };
                            res = await dispatch(createCollege(collegePaylod, false));
                        }

                        if (res) {
                            actions.resetForm();
                            onClose();
                            toast.success(`${currentStructure?.name} created successfully`);
                        }
                        setLoading(false);
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-5 flex flex-col">
                                <Select
                                    name="academicStructure"
                                    objProp="name"
                                    placeholder="Select Academic Structure"
                                    label="Select Academic Structure"
                                    data={selectData}
                                    onChange={(selected) => {
                                        setFieldValue('academicStructure', String(selected[0].structure));
                                    }}
                                    error={errors.academicStructure}
                                />

                                <>
                                    <TextInput
                                        label={`${currentStructure?.name} Name (${currentStructure?.name} of ${values.facultyName})`}
                                        name="facultyName"
                                        type="text"
                                        placeholder={`${currentStructure?.name} Name`}
                                        value={values.facultyName}
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                    <TextInput
                                        label={`${currentStructure?.name} Abbreviation`}
                                        name="facultyCode"
                                        type="text"
                                        placeholder={`${currentStructure?.name} Abbreviation/Code e.g FAC,SCI,EGL`}
                                        value={values.facultyCode}
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                    <TextInput
                                        label={`${currentStructure?.name} Numbering Code`}
                                        name="facultyNumberingCode"
                                        type="text"
                                        placeholder={`${currentStructure?.name} Numbering Code`}
                                        value={values.facultyNumberingCode}
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                    <TextInput
                                        label="Description"
                                        name="description"
                                        type="text"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                </>
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={loading} type="submit">
                                    Add
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddNewFacultyForm;
