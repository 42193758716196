import React from 'react';

import tw from 'twin.macro';

import { FlexColumn } from '../../../containers/ScreenContainers';

const Input = tw.input`
    border-[1px]
    border-[#E5E7EB]
    h-[4rem]
    rounded
    p-3
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    text-[1.4rem]
    text-[#6B7280]
    leading-6
`;
const TimeInput = ({ name, value, onChange, label, error }) => {
    return (
        <FlexColumn>
            {label && <label className="font-medium text-[1.4rem] text-[#1F2937]">{label}</label>}
            <Input type="time" name={name} value={value} onChange={onChange} />
            {error && <span className="text-[#EF4444] text-[1.2rem]">{error}</span>}
        </FlexColumn>
    );
};

export default TimeInput;
