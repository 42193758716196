export const programmeNavLinks = [
    {
        title: 'Students',
        to: '/faculties/department/programme/students',
    },
    {
        title: 'Curriculum',
        to: '/faculties/department/programme/curriculum',
    },
    {
        title: 'Course Form Management',
        to: '/faculties/department/programme/course-form-management',
    },

    {
        title: 'Examination Management',
        to: '/faculties/department/programme/exam-management',
        subMenu: [
            {
                title: 'Broadsheet',
                to: '/faculties/department/programme/exam-management/broadsheet',
            },
            {
                title: 'Summary Sheet',
                to: '/faculties/department/programme/exam-management/summary-sheet',
            },
            {
                title: 'Probation List',
                to: '/faculties/department/programme/exam-management/probation-list',
            },
            {
                title: 'Withdrawal Sheet',
                to: '/faculties/department/programme/exam-management/withdrawal-sheet',
            },
            {
                title: 'Incomplete Result',
                to: '/faculties/department/programme/exam-management/incomplete-result',
            },
        ],
    },
    {
        title: 'Access',
        to: '/faculties/department/programme/access',
    },
    {
        title: 'Settings',
        to: '/faculties/department/programme/settings',
    },
];

export const programmeRoutes = programmeNavLinks.map((item) => item.to);
