import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    position: Yup.string().nullable().required('Required'),
    email: Yup.string().nullable().required('Required'),
    phoneNumber: Yup.string().nullable().required('Required'),
    securityName: Yup.string().nullable().required('Required'),
    securityEmail: Yup.string().nullable().required('Required'),
    securityPhoneNumber: Yup.string().nullable().required('Required'),
    securityPosition: Yup.string().nullable().required('Required'),
    billingName: Yup.string().nullable().required('Required'),
    billingEmail: Yup.string().nullable().required('Required'),
    billingPhoneNumber: Yup.string().nullable().required('Required'),
    billingPosition: Yup.string().nullable().required('Required'),
});
