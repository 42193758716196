import * as Yup from 'yup';

import { emailRegExp } from '../../../utils/regex';

export const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().required('Required'),
    surname: Yup.string().nullable().required('Required'),
    email: Yup.string().matches(emailRegExp, 'Enter a valid email').nullable().required('Required'),
    phoneNumber: Yup.string().nullable().required('Required'),
    institutionName: Yup.string().nullable().required('Required'),
    institutionType: Yup.string().nullable().required('Required'),
    country: Yup.string().nullable().required('Required'),
    state: Yup.string().nullable().required('Required'),
    designation: Yup.string().nullable().required('Required'),
    totalNoOfStudents: Yup.string().nullable().required('Required'),
    howYouHearAboutUs: Yup.string().nullable().required('Required'),
});
