import React from 'react';

import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-sm.svg';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../containers/ScreenContainers';

const Card = tw.div`
    bg-white
    p-[2.4rem]
    mb-[2.4rem]
    no-underline
`;

const ITEMS = [
    {
        title: 'General Information',
        subtitle: 'Organization Name, Logo, Address, Country, State, Phone Number, Email Address e.t.c',
        link: 'general',
    },
    {
        title: ' Academic Settings',
        subtitle: 'Grading system, Matric No. Settings...',
        link: 'academic',
    },
    {
        title: 'Special Letters Settings',
        subtitle: 'Acceptance letter, Call-Off letter e.t.c',
        link: 'special-letters',
    },
    {
        title: 'Academic Calendar Settings',
        subtitle: 'A schedule of all of the events...',
        link: 'academic-calendar',
    },
    {
        title: 'Distribution List Settings',
        subtitle: 'Add and manage distribution lists for communications',
        link: 'distribution-list',
    },
];

const Configuration = () => {
    return (
        <div>
            <PageTitle bottom="4rem" align="left">
                Settings
            </PageTitle>
            {ITEMS.map((item) => (
                <Link to={item.link} key={item.link}>
                    <Card>
                        <Text weight="600" align="left" size="2.3rem" bottom="2.4rem">
                            {item.title}
                        </Text>
                        <FlexRowSpaceBetween>
                            <Text color="#6B7280" align="left" size="1.9rem">
                                {item.subtitle}
                            </Text>
                            <ArrowIcon />
                        </FlexRowSpaceBetween>
                    </Card>
                </Link>
            ))}
        </div>
    );
};

export default Configuration;
