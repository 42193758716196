import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload3.svg';
import { Text } from '../../containers/MesssageContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 10.9rem 11.2rem 10.3rem;
        width: 59.2rem;
    }
`;

const StyledCloseIcon = tw(CloseIcon)`
    absolute
    top-[3.5rem]
    right-[3.9rem]
`;

const InnerContainer = tw.div`
    flex
    flex-col
    items-center
    border
    border-dashed
    pt-[2.7rem]
    pb-[2.4rem]
    px-[12.1rem]
    rounded-[8px]
`;

const UploadButton = styled.label`
    border: 0.5px solid #d1d5db;
    font-size: 1.6rem;
    width: 12.6rem;
    border-radius: 4px;
    padding: 8px 16px;
    height: 4rem;
    color: #1f2937;
    font-weight: 500;
    cursor: pointer;

    & > input {
        /* height: 0; */
        /* width: 0; */
        overflow: hidden;
        background: transparent;
        opacity: 0;
    }
`;

const UploadFile = ({ show, setShow }) => {
    const close = () => setShow(false);
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledCloseIcon onClick={close} />
            <InnerContainer className="border-[#6B7280]">
                <UploadIcon />
                <Text size="1.6rem" weight="500" top="1.9rem" bottom="1.6rem">
                    Drop Files or
                </Text>
                <UploadButton>
                    Upload Files
                    <input
                        type="file"
                        id="uploadSheet"
                        // accept={accept}
                        accept={'.xlx, .xlsx'}
                        // onChange={(event) => {
                        //     if (event.target.files[0].size >= 100000000) {
                        //         toast.error('File size cannot be more than 10MB');
                        //         return;
                        //     } else {
                        //         handleFile(event);
                        //     }
                        // }}
                    />
                </UploadButton>
                <Text color="#6B7280" top="0.8rem">
                    Supports .xlxs
                </Text>
            </InnerContainer>
            <Text size="1.6rem" color="#8B5CF6" top="4.6rem">
                Download template here
            </Text>
        </StyledModal>
    );
};

export default UploadFile;
