import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Checkmark } from '../../../assets/icons/circular-mark.svg';
// import checkmark from '../../../assets/icons/circular-mark.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { Button } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.5rem 3.9rem;
        min-width: 25rem;
    }
`;

const StyledCloseIcon = tw(CloseIcon)`
    absolute
    top-[3.5rem]
    right-[3.9rem]
    cursor-pointer
`;

const SuccessPopup = ({
    show,
    type = 'success',
    title,
    subtitle,
    withButton = true,
    btnText,
    btnAction,
    onClose,
    svgIcon,
    children,
}) => {
    return (
        <StyledModal show={show} onClose={onClose} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledCloseIcon onClick={onClose} />
            <div>
                <div className="flex flex-col justify-center items-center mt-[40.3px] mb-[31px]">
                    {svgIcon || <Checkmark />}

                    <Text weight="600" top="3.1rem" bottom="1.6rem" size="4rem">
                        {title || 'Done!'}
                    </Text>
                    <Text size="1.6rem" bottom="3.2rem">
                        {subtitle}
                    </Text>

                    {withButton && (
                        <Button type="button" bgColor="#6366F1" color="#ffffff" onClick={btnAction || onClose}>
                            {btnText || 'Continue'}
                        </Button>
                    )}
                    {children}
                </div>
            </div>
        </StyledModal>
    );
};

export default SuccessPopup;
