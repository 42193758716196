import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete2.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit3.svg';
import { AddLectureVenue, Checkbox, ConfirmActionDialogue } from '../../../../components';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { deleteLectureVenues, getSecondDegreeLectureVenues } from '../../../../redux/departments/actions';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Name',
        accessorKey: 'hallName',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Location',
        accessorKey: 'venue',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Capacity',
        accessorKey: 'capacity',
    },
];

const LectureVenue = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { departmentLectureVenues, currentDepartment, isLoading } = useSelector((store) => store.department);
    const {
        departmentName: deptName,
        _id: secondLevelId,
        academicStructureId,
        collegeId,
        schoolId,
        facultyId,
    } = currentDepartment || {};

    const firstLevelId = collegeId || facultyId || schoolId;
    const [selectedId, setSelectedId] = useState('');
    const [checked, setChecked] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [hallName, setHallName] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [createVenue, setCreateVenue] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const getData = () => {
        dispatch(getSecondDegreeLectureVenues(academicStructureId, firstLevelId, secondLevelId));
    };

    const handleDelete = async () => {
        if (hallName !== selectedName) {
            return toast.error('Lecture venue name is wrong');
        } else {
            const res = await dispatch(deleteLectureVenues(selectedId, academicStructureId));
            if (res) {
                getData();
                setConfirmDelete(false);
                setHallName('');
            }
        }
    };

    const handleCheck = (item) => {
        if (!checked) {
            setChecked(true);
            setSelectedId(item._id);
            setSelectedName(item.hallName);
        } else {
            setChecked(false);
            setSelectedId('');
            setSelectedName('');
        }
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, deptName]);

    const modifiedVenueData = departmentLectureVenues?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox checked={item._id === selectedId} onChange={() => handleCheck(item)} />
                </td>
            ),
            ...item,
        };
    });

    return (
        <div>
            {/* <PageLayout
               
            /> */}
            {createVenue ? (
                <AddLectureVenue
                    onClose={() => setCreateVenue(false)}
                    onFinish={getData}
                    secondLevelId={secondLevelId}
                    academicStructureId={academicStructureId}
                />
            ) : (
                <PageLayout
                    pageTitle="Lecture Venue"
                    secondaryButtonText="Add Lecture Venue"
                    onSecondaryButtonClick={() => setCreateVenue(true)}
                    onActionBtnClick={() => {
                        setShowActions(!showActions);
                    }}
                    actionItems={[
                        {
                            name: 'Edit Venue',
                            icon: <EditIcon />,
                            disabled: !selectedId,
                            click: () => {
                                navigate(`edit/${selectedId}`);
                                setShowActions(false);
                            },
                        },
                        {
                            name: 'Delete Venue',
                            icon: <DeleteIcon />,
                            disabled: !selectedId,
                            click: () => {
                                setConfirmDelete(true);
                                setShowActions(false);
                            },
                        },
                    ]}
                    actionOpen={showActions}
                    actionClose={() => setShowActions(false)}
                    showTableUtils
                    searchable
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    data={modifiedVenueData || []}
                    fields={fields}
                    loading={isLoading}
                    onRowClick={(item) => {
                        const selectedItem = departmentLectureVenues?.find((venue) => venue?._id === item?._id);
                        handleCheck(selectedItem);
                    }}
                    isActionable
                />
            )}
            <ConfirmActionDialogue
                title="Delete Lecture Venue?"
                subtitle="This Lecture Venue will be deleted."
                bodyText="To confirm, enter name of the venue in the text field below."
                btn2Text="Yes, Remove"
                show={confirmDelete}
                close={() => setConfirmDelete(false)}
                isLoading={isLoading}
                confirmAction={handleDelete}
                value={hallName}
                onChange={(e) => setHallName(e.target.value)}
                placeholder="Input Name"
                input
                borderLine
            />
        </div>
    );
};

export default LectureVenue;
