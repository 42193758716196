import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: {
        id: '',
        firstName: '',
        lastName: '',
        otherName: '',
        organisationEmail: '',
        institutionName: '',
        institutionType: '',
        institutionCountry: '',
        institutionAddress: '',
        isVerified: false,
        info: {
            logo: '',
            state: '',
            website: '',
            about: '',
            stakeholders: '',
            otherStakeholders: '',
            organizationImages: '',
        },
    },
    staffData: {},
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        SAVE_USER: (state, action) => {
            state.userData = { ...action.payload };
        },
        SAVE_STAFF: (state, action) => {
            state.staffData = action.payload;
        },
    },
});

export const { SAVE_USER, SAVE_STAFF } = userSlice.actions;

export default userSlice.reducer;
