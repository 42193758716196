import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PublishIcon } from '../../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as PlusBlue } from '../../../assets/icons/plus-blue.svg';
import { ReactComponent as SendGreen } from '../../../assets/icons/send-green.svg';
import { ActionsPopup, Checkbox, ConfirmActionDialogue } from '../../../components';
import { Button } from '../../../components/buttons';
import Select from '../../../components/inputs/new-select';
import ListEmptyContent from '../../../components/list-empty';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredCol,
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
} from '../../../containers/ScreenContainers';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { deleteSpecialLetter, getAllSpecialLetter, publishSpecialLetter } from '../../../redux/special-letter/actions';

const Container = tw.div`
    bg-white
    p-[1.6rem]
    mb-[0.8rem]
    rounded-[8px]
`;

const SpecialLetters = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allSpecialLetter, isLoading } = useSelector((store) => store.specialLetter);
    const { sessions, activeSession } = useSelector((store) => store.admission);

    const [showDelete, setShowDelete] = useState(false);
    const [openActions, setOpenActions] = useState(false);
    const [selectedId, setSelectedId] = useState(false);
    const [letterName, setLetterName] = useState('');
    const [confirmPublish, setConfirmPublish] = useState(false);

    useEffect(() => {
        dispatch(getAllSpecialLetter());
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    const deleteLetter = async () => {
        if (letterName !== 'Yes') {
            toast.error('Please type "Yes" in the text field to delete');
        } else {
            const res = await dispatch(deleteSpecialLetter(selectedId));
            if (res) {
                setShowDelete(false);
                setLetterName('');
                dispatch(getAllSpecialLetter());
            }
        }
    };

    const publishLetter = async () => {
        if (letterName !== 'Yes') return toast.error('Please type "Yes" in the text field to delete');

        const res = await dispatch(publishSpecialLetter(selectedId));
        if (res) {
            setConfirmPublish(false);
            setLetterName('');
        }
    };

    if (isLoading) return <Loader />;

    return (
        <>
            <FlexRowSpaceBetween className="mb-[5.8rem] gap-x-[1rem]">
                <PageTitle weight="600">Special Letters</PageTitle>
                <div className="flex gap-[1rem] items-center relative">
                    <Button
                        bgColor="#FFF"
                        className="flex w-max items-center gap-[8px]"
                        color="#6366F1"
                        onClick={() => navigate('add')}
                    >
                        <PlusBlue />
                        Add Special Letter
                    </Button>
                    <Button
                        bgColor="#6366F1"
                        className="flex items-center gap-[0.8rem]"
                        color="#FFF"
                        onClick={() => {
                            setOpenActions(!openActions);
                        }}
                    >
                        Actions
                        <Dropdown />
                    </Button>
                    <ActionsPopup
                        open={openActions}
                        width="31.9rem"
                        close={() => setOpenActions(null)}
                        items={[
                            {
                                name: 'Publish',
                                icon: <PublishIcon />,
                                disabled: !selectedId,
                                click: () => {
                                    setConfirmPublish(true);
                                    setOpenActions(false);
                                },
                            },
                            {
                                name: 'Edit',
                                icon: <EditIcon />,
                                disabled: !selectedId,
                                click: () => {
                                    navigate(`/registry/special-letters/edit/${selectedId}`);
                                    setOpenActions(false);
                                },
                            },
                            {
                                name: 'Remove',
                                icon: <DeleteIcon />,
                                disabled: !selectedId,
                                click: () => {
                                    setShowDelete(true);
                                    setOpenActions(false);
                                },
                            },
                        ]}
                    />
                </div>
            </FlexRowSpaceBetween>

            {!allSpecialLetter?.length > 0 ? (
                <CenteredContainer>
                    <ListEmptyContent
                        title="Create a Special Letter to get Started"
                        subtitle="You have no Special Letter yet!"
                    />
                </CenteredContainer>
            ) : (
                <>
                    <Container>
                        <Select
                            objProp="admissionYear"
                            placeholder="Session"
                            containerClass="w-[15rem]"
                            data={sessions}
                            passedSelectedItems={activeSession}
                        />
                    </Container>
                    <FlexColumn>
                        {allSpecialLetter?.map((letter) => (
                            <Container key={letter._id}>
                                <FlexCentredRow>
                                    <Checkbox
                                        checked={letter._id === selectedId}
                                        onChange={() => {
                                            if (selectedId === letter._id) {
                                                setSelectedId('');
                                            } else {
                                                setSelectedId(letter._id);
                                            }
                                        }}
                                    />
                                    <FlexCentredCol
                                        className="gap-[0.4rem] cursor-pointer"
                                        onClick={() => navigate(`view/${letter._id}`)}
                                    >
                                        <Text align="left" weight="500" color="#1F2937">
                                            {letter.type}
                                        </Text>
                                        <Text align="left" color="#9CA3AF" size="1.2rem" lineHeight="1.7rem">
                                            {letter.shortDescription}
                                        </Text>
                                    </FlexCentredCol>
                                </FlexCentredRow>
                            </Container>
                        ))}
                    </FlexColumn>
                </>
            )}
            <ConfirmActionDialogue
                show={showDelete}
                input
                noStyle
                value={letterName}
                onChange={(e) => setLetterName(e.target.value)}
                close={() => setShowDelete(false)}
                title="Remove Special Letter?"
                subtitle="This Special letter will be completely Removed"
                bodyText="To confirm Removal, type 'Yes' in the text field"
                placeholder="Type Yes to confirm delete"
                btn2Text="Yes, Remove"
                confirmAction={deleteLetter}
            />

            <ConfirmActionDialogue
                svgIcon={<SendGreen />}
                input
                show={confirmPublish}
                noStyle
                value={letterName}
                onChange={(e) => setLetterName(e.target.value)}
                close={() => setConfirmPublish(false)}
                title="Publish Special Letter"
                subtitle="Are you sure you want to publish this special letter?"
                bodyText="To confirm, type 'Yes' in the text field"
                placeholder="Type Yes to confirm publish"
                btn2Text="Yes, Publish"
                bgColor={'#10B981'}
                confirmAction={publishLetter}
            />
        </>
    );
};

export default SpecialLetters;
