export const ictNavLinks = [
    {
        title: 'Communications',
        to: '/ict/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/ict/communications/compose',
            },
            {
                title: 'Received',
                to: '/ict/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/ict/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/ict/communications/settings',
            },
        ],
    },
    {
        title: 'Academic Calendar',
        to: '/ict/academic-calendar',
    },
    {
        title: 'Access',
        to: '/ict/access',
    },
    {
        title: 'Organization Management',
        to: '/ict/org',
        subMenu: [
            {
                title: 'Academic Structure',
                to: '/ict/org/academic-structure',
            },
            {
                title: 'Platform Administrators',
                to: '/ict/org/admins',
            },
            {
                title: 'Organization Units',
                to: '/ict/org/units',
            },
            {
                title: 'Organization Settings',
                to: '/ict/org/settings',
            },
        ],
    },
    {
        title: 'Audit Trail',
        to: '/ict/audit-log',
    },
    {
        title: 'Settings',
        to: '/ict/settings',
    },
];

export const ictRoutes = ictNavLinks.map((item) => item.to);
