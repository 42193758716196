import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import backArrow from '../../../assets/icons/arrow-back-blue.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { forgetPassword } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';



const ForgotPasswordForm = ({ userType, setOpenSuccess }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.auth);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const payload = {
                        userType,
                        email: values.email.toLowerCase(),
                    };
                    const res = await dispatch(forgetPassword(payload));
                    if (res) {
                        actions.resetForm();
                        setOpenSuccess(true);
                    }
                }}
            >
                {({ values, errors, handleChange }) => (
                    <Form>
                        <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <Link to="/sign-in-staff">
                                <img src={backArrow} alt="preset" className="mb-10" />
                            </Link>

                            <h3 className="font-semibold text-[#1F2937] text-[17px] my-3">
                                Forgot Password?
                                {/* {userType === 'staff' ? '(Staff)' : '(Organisation Moderator)'} */}
                            </h3>

                            <p className="font- text-[#9CA3AF] text-[12px] mb-8">
                                Enter the email associated with your account
                            </p>

                            <TextInput
                                name="email"
                                type="text"
                                placeholder="Email Address"
                                errors={errors}
                                onChange={handleChange}
                                value={values.email}
                            />

                            <FlexRowSpaceBetween className="mt-12">
                                <FlexCentredRow>
                                    <p className="text-[#9CA3AF] text-left text-2xl mr-4" style={{ lineHeight: '2.4rem' }}>
                                        Remember Password?
                                    </p>
                                    <Link to="/sign-in-staff">
                                        <Text color="#6366F1" align="left">
                                            Sign In
                                        </Text>
                                    </Link>
                                </FlexCentredRow>

                                <LoadingButton type="submit" loading={isLoading}>
                                    Send Link
                                </LoadingButton>
                            </FlexRowSpaceBetween>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ForgotPasswordForm;
