import { ACADEMIC_PLANNING } from '../../utils/unitNames';
import {
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    Received,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    Access,
    Roles,
    Administrator,
    Staff,
} from '../../views';

export const academicPlanningRoutes = [
    {
        path: '/academic-planning/communications/distribution-list',
        element: <DistributionList title="Communications" />,
    },
    {
        path: '/academic-planning/communications/distribution-list/add',
        element: <AddDistributionList title="Communications" />,
    },
    {
        path: '/academic-planning/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList title="Communications" />,
    },
    {
        path: '/academic-planning/communications/received',
        element: <Received />,
    },
    {
        path: '/academic-planning/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/academic-planning/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/academic-planning/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/academic-planning/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: '/academic-planning/access',
        element: (
            <Access
                staffs={<Staff unitName={ACADEMIC_PLANNING} />}
                administrators={<Administrator unitName={ACADEMIC_PLANNING} />}
                roles={<Roles unitName={ACADEMIC_PLANNING} />}
            />
        ),
    },
];
