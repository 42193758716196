import { format } from 'date-fns';

export const formatToDate = (date) => {
    if (date) {
        const newDate = new Date(Date.parse(date));
        return newDate.toLocaleDateString('en-GB');
    }
    return null;
};

export const formatDate = (date) => {
    if (date) {
        return format(new Date(date), 'yyyy-MM-dd');
    }
    return null;
};

// export const reverseDate = (date) => date?.split('-').reverse().join('-');

// const transformDate = (date) => {
//     console.log('date', date);
//     //mm/dd/yyyy
//     const dte = date && format(new Date(date), 'yyyy-MM-dd');
//     // console.log('date', dte);
//     const dteToUse = dte?.split('/').reverse().join('-');
//     // console.log('dteToUse', dteToUse);
//     //function returns  yyyy-mm-dd
//     return dteToUse;
// };
