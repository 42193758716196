import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    departments: [],
    currentDepartment: {},
    courses: [],
    staff: [],
    students: [],
    admins: [],
    departmentTimetable: [],
    departmentExamTimetable: [],
    lectureVenues: [],
    facultyLectureVenues: [],
    departmentLectureVenues: [],
};

export const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_DEPARTMENTS: (state, action) => {
            state.departments = action.payload;
        },
        GET_DEPARTMENT_COURSES: (state, action) => {
            state.courses = action.payload;
        },
        GET_DEPARTMENT_STAFF: (state, action) => {
            state.staff = action.payload;
        },
        GET_DEPARTMENT_STUDENTS: (state, action) => {
            state.students = action.payload;
        },
        GET_DEPARTMENT_ADMINS: (state, action) => {
            state.admins = action.payload;
        },
        SAVE_CURRENT_DEPARTMENT: (state, action) => {
            state.currentDepartment = { ...action.payload, departmentId: action.payload?._id };
        },
        GET_DEPARTMENT_TIMETABLE: (state, action) => {
            state.departmentTimetable = action.payload;
        },
        GET_DEPARTMENT_EXAM_TIMETABLE: (state, action) => {
            state.departmentExamTimetable = action.payload;
        },
        GET_LECTURE_VENUES: (state, action) => {
            state.lectureVenues = action.payload;
        },
        GET_FACULTY_LECTURE_VENUES: (state, action) => {
            state.facultyLectureVenues = action.payload;
        },
        GET_DEPARTMENT_LECTURE_VENUES: (state, action) => {
            state.departmentLectureVenues = action.payload;
        },
    },
});

export const {
    GET_DEPARTMENTS,
    LOADING,
    GET_DEPARTMENT_COURSES,
    GET_DEPARTMENT_STAFF,
    GET_DEPARTMENT_STUDENTS,
    GET_DEPARTMENT_ADMINS,
    STOP_LOADING,
    SAVE_CURRENT_DEPARTMENT,
    GET_DEPARTMENT_TIMETABLE,
    GET_DEPARTMENT_EXAM_TIMETABLE,
    GET_LECTURE_VENUES,
    GET_FACULTY_LECTURE_VENUES,
    GET_DEPARTMENT_LECTURE_VENUES,
} = departmentSlice.actions;

export default departmentSlice.reducer;
