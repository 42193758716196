import { Text } from '../../containers/MesssageContainers';
import getInitials from '../../utils/getInitials';

const Avatar = ({ imageUrl, fullName }) => {
    const initials = getInitials(fullName);

    return imageUrl ? (
        <img src={imageUrl} data-testid="avatar" className="rounded-full" width={32} height={32} alt={fullName} />
    ) : (
        <div className="rounded-full h-[32px] w-[32px] flex items-center justify-center  bg-[#CDD0FE]">
            <Text color="#030EAA" weight="700" size="1.2rem">
                {initials}
            </Text>
        </div>
    );
};

export default Avatar;
