import AddSpecialLetterForm from '../../components/forms/add-special-letter';
import CreateHandbook from '../../components/forms/create-handbook';
import EditHandbook from '../../components/forms/edit-handbook';
import { REGISTRY } from '../../utils/unitNames';
import {
    Records,
    RecordsDetails,
    AcademicCalendar,
    // AddAdministrator,
    Administrator,
    CreateRole,
    EditAdministrator,
    Received,
    Roles,
    Staff,
    StaffDetail,
    EditRole,
    SpecialLetters,
    GeneralSettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    View,
    Edit,
    SpecialLetterCompose,
    EditSpecialLetterCompose,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';
import Handbook from '../../views/records/handbook';
import HandbookDetails from '../../views/records/handbook/handbookDetails';

export const registryRoutes = [
    {
        path: '/registry/records',
        element: <Records unitName={REGISTRY} />,
    },
    {
        path: '/registry/records/:id',
        element: <RecordsDetails />,
    },
    {
        path: '/registry/access',
        element: (
            <Access
                staffs={<Staff unitName={REGISTRY} />}
                administrators={<Administrator unitName={REGISTRY} />}
                roles={<Roles unitName={REGISTRY} />}
            />
        ),
    },
    {
        path: '/registry/access/create-role',
        element: <CreateRole unitName={REGISTRY} />,
    },
    {
        path: '/registry/access/edit-role',
        element: <EditRole unitName={REGISTRY} />,
    },
    {
        path: '/registry/access/add-administrator',
        // element: <AddAdministrator unitName={REGISTRY} />,
        element: <AssignRole unitName={REGISTRY} />,
    },
    {
        path: '/registry/access/edit-administrator',
        element: <EditAdministrator unitName={REGISTRY} />,
    },
    {
        path: '/registry/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/registry/communications/received',
        element: <Received unitName={REGISTRY} />,
    },
    {
        path: '/registry/academic-calendar',
        element: <AcademicCalendar />,
    },

    {
        path: '/registry/special-letters',
        element: <SpecialLetters />,
    },
    {
        path: '/registry/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/registry/communications/distribution-list',
        element: <DistributionList unitName={REGISTRY} title="Communications" />,
    },
    {
        path: '/registry/communications/distribution-list/add',
        element: <AddDistributionList unitName={REGISTRY} title="Communications" />,
    },
    {
        path: '/registry/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={REGISTRY} title="Communications" />,
    },
    {
        path: '/registry/handbook',
        element: <Handbook unitName={REGISTRY} />,
    },
    {
        path: '/registry/handbook/:id',
        element: <HandbookDetails unitName={REGISTRY} />,
    },
    {
        path: '/registry/handbook/create-handbook',
        element: <CreateHandbook unitName={REGISTRY} />,
    },
    {
        path: '/registry/handbook/edit-handbook',
        element: <EditHandbook unitName={REGISTRY} />,
    },
    {
        path: '/registry/special-letters',
        element: <SpecialLetters />,
    },
    {
        path: '/registry/special-letters/add',
        element: <AddSpecialLetterForm />,
    },
    {
        path: '/registry/special-letters/compose',
        element: <SpecialLetterCompose />,
    },
    {
        path: '/registry/edit-special-letters/compose/:id',
        element: <EditSpecialLetterCompose />,
    },
    {
        path: '/registry/special-letters/edit/:id',
        element: <Edit />,
    },
    {
        path: '/registry/special-letters/view/:id',
        element: <View />,
    },
    {
        path: '/registry/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/registry/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/registry/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/registry/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
