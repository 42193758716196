import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// import Checkbox from '../../../components/inputs/checkbox';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getFaculties } from '../../../redux/faculty/actions';

const fields = [
    // {
    //     accessorKey: 'action',
    //     cell: (props) => <p>{props?.getValue()}</p>,
    //     header: <Checkbox />, size: 5,
    // },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Faculty Name',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Departments',
        accessorKey: 'numberOfDepartments',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Courses',
        accessorKey: 'numberOfCourses',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Students',
        accessorKey: 'numberOfStudents',
    },
];

const Faculty = () => {
    const dispatch = useDispatch();
    const { isLoading, faculties } = useSelector((state) => state.faculty);

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(getFaculties());
    }, [dispatch]);

    const modifiedFcaulties = faculties?.map((item) => {
        return {
            // action: (
            //     <td>
            //         <Checkbox />
            //     </td>
            // ),
            name: <td>{item.facultyName}</td>,
            numberOfDepartments: <td>{item?.departments?.length}</td>,
            numberOfCourses: <td>{item?.courses?.length}</td>,
            numberOfStudents: <td>{item?.students?.length}</td>,
        };
    });

    return (
        <div>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedFcaulties || []}
                fields={fields}
                loading={isLoading}
                noItemsViewSlot={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Faculty"></ListEmptyContent>
                    </CenteredContainer>
                }
                isActionable
            />
        </div>
    );
};

export default Faculty;
