import React, { useState } from 'react';

import TextAreaInput from '../../../components/inputs/text-area';
import { Text } from '../../../containers/MesssageContainers';
import { capitalizeFirstLetter } from '../../../utils';
import ConfirmApproval from '../confirm-approval';

const ConfirmCourseFormApproval = ({
    type = 'text',
    value,
    setInputValue,
    placeholder,
    secondDegreeStruct = '',
    ...props
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const onEditClick = () => {
        setInputValue('');
        setIsEdit(true);
    };

    return (
        <ConfirmApproval {...props}>
            {isEdit ? (
                <TextAreaInput
                    name="reason"
                    placeholder={placeholder || 'Reason'}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    height="6rem"
                />
            ) : (
                <Text size="1.6rem" align="left">
                    Your Course Form is in good shape and you're eligible to continue with your registrations.
                    <span onClick={onEditClick} className="text-[#3B82F6] cursor-pointer font-medium">
                        Edit message
                    </span>
                </Text>
            )}

            <Text fontStyle="italic" align="left" top="3.2rem" lineHeight="2.4rem">
                Upon clicking "Confirm," this course form will be sent to the Head of{' '}
                {capitalizeFirstLetter(secondDegreeStruct)} for approval.
            </Text>
        </ConfirmApproval>
    );
};

export default ConfirmCourseFormApproval;
