import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-red.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { Button, LoadingButton } from '../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
        padding: 1rem 1.6rem 1.4rem 1.4rem;
        /* width: 36.6rem; */
    }
`;

const DeleteItem = ({ show, close, assignAction, loading }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween className="mb-[1.6rem]">
                <FlexCentredRow>
                    <DeleteIcon className="mr-[8.15px]" />
                    <Text as="h3" size="1.9rem" weight="600">
                        Delete Item
                    </Text>
                </FlexCentredRow>
                <CloseIcon onClick={close} />
            </FlexRowSpaceBetween>
            <Text bottom="4rem">Are you sure you want to delete this permanently?</Text>
            <FlexRowEnd className="gap-x-[16px]">
                <Button onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton loading={loading} onClick={assignAction} bgColor="#6366F1" color="#fff">
                    Yes, delete
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default DeleteItem;
