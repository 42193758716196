import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    surname: Yup.string()
        .nullable()
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'Surname must contain only alphabetic characters and no spaces'),
    firstName: Yup.string()
        .nullable()
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'Surname must contain only alphabetic characters and no spaces'),
    otherName: Yup.string()
        .nullable()
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'Surname must contain only alphabetic characters and no spaces'),
    yearOfEmployment: Yup.string().nullable().required('Required'),
    designation: Yup.string().nullable().required('Required'),
});
