import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    handbookDetails: {},
};

const handbookSlice = createSlice({
    name: 'handbook',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_HANDBOOK_BY_ID: (state, action) => {
            state.handbookDetails = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_HANDBOOK, GET_HANDBOOK_BY_ID } = handbookSlice.actions;

export default handbookSlice.reducer;
