import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch } from 'react-redux';

import { getCalendars } from '../../../../redux/academic-calendar/actions';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../redux/admissions/actions';

import AcademicCal from './tabs/AcademicCall';
import ExamTimeTable from './tabs/ExamTimeTable';
import Timetable from './tabs/Timetable';

const developmentFlag = process.env.REACT_APP_ENV;

const TimeTable = () => {
    const [, /*tab*/ setTab] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCalendars());
        dispatch(getActiveSemester());
        dispatch(getActiveSession());
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    return (
        <div>
            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Time-table</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Exam-Time-table</CNavLink>
                    </CNavItem>

                    {developmentFlag !== 'production' ? (
                        <CNavItem>
                            <CNavLink onClick={() => setTab(2)}>Academic Calendar</CNavLink>
                        </CNavItem>
                    ) : null}
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Timetable />
                    </CTabPane>
                    <CTabPane>
                        <ExamTimeTable />
                    </CTabPane>
                    <CTabPane>
                        <AcademicCal />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default TimeTable;
