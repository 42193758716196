import React from 'react';

import { Switch } from '@headlessui/react';

const ToggleSwitch = ({ checked, setChecked, value }) => {
    return (
        <div>
            <Switch
                value={value}
                checked={checked}
                onChange={setChecked}
                className={`${checked ? 'bg-[#6366F1]' : 'bg-[#D1D5DB]'}
          relative inline-flex h-[2.4rem] w-[5.2rem] items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${checked ? 'translate-x-12' : 'translate-x-0'}
            pointer-events-none inline-block h-[1.6rem] w-[1.6rem] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    );
};

export default ToggleSwitch;
