import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getAllMemo, getAllInbox, getInboxLetterComms } from '../../../../redux/staff-arena/actions';

import InboxAll from './tabs/All';
import LetterThrough from './tabs/LetterThrough';
import LetterTo from './tabs/LetterTo';
import MemoThrough from './tabs/MemoThrough';
import MemoTo from './tabs/MemoTo';

const Inbox = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const [toOfficeMemo, setToOfficeMemo] = useState([]);
    const [throughOfficeMemo, setThroughOfficeMemo] = useState([]);
    const [inbox, setInbox] = useState({});
    const [toLetter, setToLetter] = useState([]);
    const [throughLetter, setThroughLetter] = useState([]);

    const ComposeList = [
        {
            name: 'Letters',
            click: () => {
                navigate('/staff-arena/office/letter');
                setDropdownOpen(false);
            },
        },

        {
            name: ' Memo ',
            click: () => {
                navigate('/staff-arena/office/memo');
                setDropdownOpen(false);
            },
        },
        {
            name: 'Notices ',
            click: () => {
                // navigate('/staff-arena/office/notice');
                setDropdownOpen(false);
            },
        },
    ];

    const getToOfficeMemo = async () => {
        const res = await dispatch(getAllMemo('to'));
        setToOfficeMemo(res);
    };

    const getThroughOfficeMemo = async () => {
        const res = await dispatch(getAllMemo('through'));
        setThroughOfficeMemo(res);
    };

    const getAllReceived = async () => {
        const res = await dispatch(getAllInbox());
        setInbox(res);
    };

    const getToLetters = async () => {
        const res = await dispatch(getInboxLetterComms('to'));
        setToLetter(res);
    };

    const getThroughLetters = async () => {
        const res = await dispatch(getInboxLetterComms('through'));
        setThroughLetter(res);
    };

    useEffect(() => {
        getToOfficeMemo();
        getThroughOfficeMemo();
        getToLetters();
        getThroughLetters();
        getAllReceived();
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Office</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown />
                    </Button>
                    <ActionsPopup open={dropdownOpen} close={() => setDropdownOpen(false)} items={ComposeList} />
                </RelativeContainer>
            </FlexRowSpaceBetween>
            <Text align="left" weight="500" size="1.6rem" lineHeight="2.4rem" top="1.9rem" bottom="3.2rem">
                Received - Communications
            </Text>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>All</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Letters (To)</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Memo (To)</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Letters (Through)</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Memo (Through)</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <InboxAll allInbox={inbox} />
                    </CTabPane>
                    <CTabPane>
                        <LetterTo toLetter={toLetter} />
                    </CTabPane>
                    <CTabPane>
                        <MemoTo memo={toOfficeMemo} />
                    </CTabPane>
                    <CTabPane>
                        <LetterThrough throughLetter={throughLetter} />
                    </CTabPane>
                    <CTabPane>
                        <MemoThrough memo={throughOfficeMemo} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Inbox;
