import { QUALITY_ASSURANCE } from '../../utils/unitNames';
import {
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    Received,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    Access,
    Staff,
    Administrator,
    Roles,
} from '../../views';

export const qualityAssuranceRoutes = [
    {
        path: '/quality-assurance/communications/distribution-list',
        element: <DistributionList title="Communications" />,
    },
    {
        path: '/quality-assurance/communications/distribution-list/add',
        element: <AddDistributionList title="Communications" />,
    },
    {
        path: '/quality-assurance/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList title="Communications" />,
    },
    {
        path: '/quality-assurance/communications/received',
        element: <Received />,
    },
    {
        path: '/quality-assurance/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/quality-assurance/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/quality-assurance/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/quality-assurance/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: '/quality-assurance/access',
        element: (
            <Access
                staffs={<Staff unitName={QUALITY_ASSURANCE} />}
                administrators={<Administrator unitName={QUALITY_ASSURANCE} />}
                roles={<Roles unitName={QUALITY_ASSURANCE} />}
            />
        ),
    },
];
