import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import Checkbox from '../../../../../components/inputs/checkbox';
import SearchInput from '../../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../../components/items-per-page';
import ListEmptyContent from '../../../../../components/list-empty';
import UploadFile from '../../../../../components/upload-file';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../../../containers/ScreenContainers';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Subject',
        key: 'subject',
    },
    {
        label: 'Message',
        key: 'message',
    },
    {
        label: 'Time Stamp',
        key: 'time',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const SentNotice = () => {
    const navigate = useNavigate();
    const [isLoading /*setIsLoading*/] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const totalPages = Math.ceil([]?.length / itemsPerPage);

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <>
            {isListEmpty ? (
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent title="No Notice yet"></ListEmptyContent>
                </CenteredContainer>
            ) : (
                <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <FlexCentredRow>
                            <SearchInput
                                withRadius
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                        </FlexCentredRow>
                    </FilterContainer>
                    <CDataTable
                        items={[]}
                        fields={fields}
                        // striped
                        selectable
                        responsive
                        itemsPerPage={itemsPerPage}
                        activePage={currentPage || 1}
                        clickableRows
                        loading={isLoading}
                        onRowClick={(item) => navigate(`notice/${item.id}`)}
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox />
                                </td>
                            ),

                            subject: (item) => <td style={{ fontWeight: '600' }}>{item.subject}</td>,
                        }}
                    />
                    <ItemsPerPageCont>
                        <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                        <CPagination
                            activePage={currentPage || 1}
                            onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                            pages={totalPages || 1}
                            align="end"
                        />
                    </ItemsPerPageCont>
                </div>
            )}
            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default SentNotice;
