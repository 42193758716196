import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../../../../../components/inputs/checkbox';
import UploadFile from '../../../../../components/upload-file';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { addLabelsToData, formatDate } from '../../../../../utils';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Subject',
        accessorKey: 'subject',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Message',
        accessorKey: 'message',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Type',
        accessorKey: 'status',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Time Stamp',
        accessorKey: 'time',
    },
];

const SentStaffAllComms = ({ unitName }) => {
    const navigate = useNavigate();

    const { allSent, isLoading } = useSelector((store) => store.staffArena);

    const combinedArray = addLabelsToData(allSent, unitName);

    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);

    const sortedData = combinedArray?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const AllData = sortedData?.map((item) => {
        const formattedDate = formatDate(item.createdAt);
        return {
            id: item._id,
            subject: item.title,
            message: item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content,
            time: formattedDate,
            label: 'Letter',
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            // status: <td>{getLabelBadge('Letter')}</td>,
            ...item,
        };
    });

    const handleClick = (item) => {
        if (item.label === 'Mail') {
            navigate(`mail-preview/${item.id}`);
        } else if (item.label === 'Letter') {
            navigate(`letters-preview/${item.id}`);
        }
    };

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={AllData || []}
                fields={fields}
                loading={isLoading}
                onRowClick={(item) => handleClick(item)}
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default SentStaffAllComms;
