import {
    AdminCreatePassword,
    StaffSignIn,
    Onboarding,
    ForgotPassword,
    StaffCreatePassword,
    ConfirmPassword,
    AdminSignIn,
} from '../views';
import ScheduleDemo from '../views/auth/schedule-demo';
import VerifyEmailAddress from '../views/auth/verify-email';

//routes the user can only visit directly when they are not authenticated

export const routes = [
    { path: '/sign-in-staff', element: <StaffSignIn /> },
    { path: '/sign-in-admin', element: <AdminSignIn /> },
    { path: '/create-password', element: <AdminCreatePassword /> },
    { path: '/staff/create-password', element: <StaffCreatePassword /> },
    { path: '/sign-up', element: <Onboarding /> },
    { path: '/forgot-password-staff', element: <ForgotPassword userType="staff" /> },
    { path: '/forgot-password-admin', element: <ForgotPassword userType="admin" /> },
    { path: '/reset-password/:token', element: <ConfirmPassword /> },
    { path: '/admin/verify-email', element: <VerifyEmailAddress /> },
    { path: '/schedule-demo', element: <ScheduleDemo /> },
    
];