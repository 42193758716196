import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel-red.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-new.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { ReactComponent as LockIcon } from '../../../../assets/icons/lock.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/send-black.svg';
import { ReactComponent as UnlockIcon } from '../../../../assets/icons/unlock.svg';
import { ActionsPopup, Checkbox, ConfirmActionDialogue, SearchInput } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import ConfirmIncompleteResult from '../../../../components/popups/confirm-incomplete-result';
import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    FilterContainer,
    FlexDiv,
    FlexRowEnd,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import {
    getBroadsheet,
    lockEntriesInProgram,
    sendResultToDepartment,
    unlockEntriesInProgram,
} from '../../../../redux/exam-magt/actions';

const Th = styled.th`
    overflow: auto;
    border-width: 1px;
    padding: 8px;

    & > svg {
        display: inline-block;
    }
`;

const Td = tw.td`
    border
    border-gray-100
`;

const BodyText = ({ session, semester }) => (
    <span className="border-b w-full flex gap-[50px]">
        <span>
            Session: <span className="font-bold">{session}</span>
        </span>
        <span>
            Semester: <span className="font-bold">{semester}</span>
        </span>
    </span>
);

const Broadsheet = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { level } = useParams();

    const { item: { curriculumId } = {}, semester = '', session = '' } = state || {};
    const { isLoading } = useSelector((state) => state.examMagt);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { _id: programId } = currentProgramme || {};

    const [searchValue, setSearchValue] = useState('');
    const [modalInputValue, setModalInputValue] = useState('');
    const [openModal, setOpenModal] = useState('');

    const [broadsheetData, setBroadsheetData] = useState([]);

    const courses = broadsheetData?.curriculum?.map((course) => course) || [];
    const sortedCourses = courses?.sort((a, b) => a?.courseId?.localeCompare(b?.courseId));
    const [cocLength, setCocLength] = useState(1);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const handleLock = async () => {
        if (modalInputValue !== 'LOCK') return toast.error('Enter LOCK in input field');
        const res = await dispatch(lockEntriesInProgram({ programId, session, semester: semester?.toLowerCase() }));
        if (res) {
            toast.success('Entries locked successfully!');
            setModalInputValue('');
            setOpenModal(null);
        }
    };

    const handleUnlock = async () => {
        if (modalInputValue !== 'UNLOCK') return toast.error('Enter UNLOCK in input field');
        const res = await dispatch(unlockEntriesInProgram({ programId, session, semester: semester?.toLowerCase() }));
        if (res) {
            toast.success('Entries unlocked successfully!');
            setModalInputValue('');
            setOpenModal(null);
        }
    };

    const handleSendResults = async () => {
        const res = await dispatch(sendResultToDepartment({ programId, session, semester: semester?.toLowerCase() }));
        if (res) {
            toast.success('These results have been sent successfully!');
            setOpenModal(null);
        }
    };

    const handleChangeCocLength = (items = []) => {
        if (items?.length > cocLength) {
            setCocLength(items?.length);
        }
        return;
    };

    const handleSelectStudent = (student) => {
        if (student?.studentId === selectedStudent?.studentId) return setSelectedStudent(null);
        setSelectedStudent(student);
    };

    const getData = async () => {
        const res = await dispatch(
            getBroadsheet({
                session,
                level,
                curriculumId,
                semester: semester.toLowerCase(),
            }),
        );
        if (res) {
            setBroadsheetData(res);
        }
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curriculumId, dispatch, level, semester, session]);

    return (
        <>
            <FlexRowSpaceBetween>
                <GoBack title="Broadsheet" subTitle={`${level} level`} />

                <FlexRowEnd className="gap-x-4">
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setOpenModal('actions')}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openModal === 'actions'}
                            close={() => setOpenModal(null)}
                            items={[
                                {
                                    icon: <DownloadIcon />,
                                    name: 'Download',
                                    click: () => setOpenModal('download'),
                                },
                                {
                                    icon: <LockIcon />,
                                    name: 'Lock Entries',
                                    click: () => setOpenModal('lock'),
                                },
                                {
                                    icon: <UnlockIcon />,
                                    name: 'Unlock Entries',
                                    click: () => setOpenModal('unlock'),
                                },
                                {
                                    icon: <SendIcon />,
                                    name: 'Send Results to Department',
                                    click: () => setOpenModal('send'),
                                },
                                {
                                    icon: <CancelIcon />,
                                    name: 'Mark as Incomplete',
                                    click: () => setOpenModal('incomplete'),
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <FlexDiv className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle>Broadsheet </PageTitle>
            </FlexDiv>

            <div className="mt-[2.4rem] text-base shadow-lg rounded-t-lg bg-[white] pb-[1.8rem] w-full overflow-x-auto">
                <FilterContainer>
                    <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                </FilterContainer>
                <div className=" text-center text-[#4B5563]">
                    <table className="min-w-full border border-gray-400 overflow-auto">
                        {/* Table Headers */}
                        <thead className="overflow-auto">
                            {/* Header Row 1 */}
                            <tr className="font-semibold overflow-auto">
                                <Th></Th>
                                <Th colSpan={courses?.length} className="border p-2 overflow-auto">
                                    Main Course
                                </Th>
                                <Th colSpan={cocLength}>
                                    COC <InfoIcon />
                                </Th>
                                <Th colSpan="4"></Th>
                                <Th colSpan="4"></Th>
                                <Th></Th>
                                <Th></Th>
                            </tr>

                            {/* Header Row 2 */}
                            <tr>
                                <Th>Course Code</Th>
                                {sortedCourses?.map((course) => (
                                    <Th key={course?.courseCode}>{course?.courseCode}</Th>
                                ))}
                                <Th colSpan={cocLength}></Th>

                                <Th colSpan="4">Previous CGPA</Th>
                                <Th colSpan="4">Current CGPA</Th>
                                <Th>Academic Standing</Th>
                                <Th>Honour List</Th>
                            </tr>

                            {/* Header Row 3*/}
                            <tr>
                                <Th>Course Unit</Th>
                                {sortedCourses?.map((course) => (
                                    <Th key={course?.courseCode}>{course?.courseUnit}</Th>
                                ))}
                                <Th colSpan={cocLength}></Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th></Th>
                                <Th></Th>
                            </tr>
                            {/* Header Row 4 */}
                            <tr>
                                <Th>Matric No.</Th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="">
                            {broadsheetData?.data?.map((student) => {
                                const sortedStudentCourses = student?.courses?.sort((a, b) =>
                                    a?.courseId?.localeCompare(b?.courseId),
                                );
                                const carryOvers = sortedStudentCourses?.filter((course) => course?.carriedOver);
                                handleChangeCocLength(carryOvers);

                                const resultArrayLength = student?.result?.length;
                                const prevResult = student?.result?.[resultArrayLength - 2];
                                const currentResult = student?.result?.[resultArrayLength - 1];

                                return (
                                    <tr key={student.studentId} className="">
                                        <Td className="min-w-[150px] flex items-center">
                                            <Checkbox
                                                checked={selectedStudent?.studentId === student?.studentId}
                                                onChange={() => handleSelectStudent(student)}
                                            />
                                            <p className="m-0">{student.name}</p>
                                        </Td>
                                        {sortedStudentCourses?.map((course) => (
                                            <Td key={course.courseId}>
                                                {course.status === 'Registered' ? course.totalScore : 'NR'}
                                            </Td>
                                        ))}
                                        <Td className="flex gap-2 gap-pt-2 items-center border-0">
                                            {carryOvers?.map((course) => (
                                                <p className="grid" key={course.courseCode}>
                                                    <span>{course.courseCode}</span>
                                                    <span>{course.totalScore}</span>
                                                </p>
                                            ))}
                                        </Td>
                                        <Td>{prevResult?.totalUnitsRegistered}</Td>
                                        <Td>{prevResult?.totalUnitsPassed}</Td>
                                        <Td>{prevResult?.totalWeightPassed}</Td>
                                        <Td>{prevResult?.cumulativeGradePointAverage}</Td>
                                        <Td>{currentResult?.totalUnitsRegistered}</Td>
                                        <Td>{currentResult?.totalUnitsPassed}</Td>
                                        <Td>{currentResult?.totalWeightPassed}</Td>
                                        <Td>{currentResult?.cumulativeGradePointAverage}</Td>
                                        <Td>{student?.academicStanding ? 'GS' : 'NGS'}</Td>
                                        <Td>{student?.honourDivision}</Td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <ConfirmActionDialogueWithInput
                show={openModal === 'lock'}
                title="Lock Results"
                subtitle="Are you sure you want to lock these results?"
                subtitle2="To confirm, enter 'LOCK' in the text field."
                close={() => {
                    setModalInputValue('');
                    setOpenModal(null);
                }}
                confirmAction={handleLock}
                borderLine={false}
                bodyText={<BodyText session={session} semester={semester} />}
                btn2Text="Lock Results"
                setInputValue={setModalInputValue}
                value={modalInputValue}
                withIcon={false}
                isLoading={isLoading}
                placeholder="Enter LOCK"
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'unlock'}
                title="Unlock Results"
                subtitle="Are you sure you want to unlock these results?"
                subtitle2="To confirm, enter 'UNLOCK' in the text field."
                close={() => {
                    setModalInputValue('');
                    setOpenModal(null);
                }}
                confirmAction={handleUnlock}
                borderLine={false}
                bodyText={<BodyText session={session} semester={semester} />}
                btn2Text="Unlock Results"
                setInputValue={setModalInputValue}
                value={modalInputValue}
                withIcon={false}
                isLoading={isLoading}
                placeholder="Enter UNLOCK"
            />

            <ConfirmActionDialogue
                show={openModal === 'send'}
                title="Send Results"
                bgColor="#6366F1"
                subtitle="Are you sure you want to send these results?"
                close={() => setOpenModal(null)}
                confirmAction={handleSendResults}
                bodyText={<BodyText session={session} semester={semester} />}
                btn2Text="Send Results"
                withIcon={false}
                isLoading={isLoading}
            />

            <ConfirmIncompleteResult
                show={openModal === 'incomplete'}
                close={() => setOpenModal(null)}
                level={level}
                semester={semester}
                session={session}
                student={selectedStudent}
            />
        </>
    );
};

export default Broadsheet;
