import React, { useState } from 'react';

import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { ReactComponent as CheckMark } from '../../../../../../assets/icons/CheckCircle.svg';
import { ReactComponent as Minus } from '../../../../../../assets/icons/Minus.svg';
import { ReactComponent as Plus } from '../../../../../../assets/icons/Plus2.svg';
import { ReactComponent as Smiley } from '../../../../../../assets/icons/smiley-yellow.svg';
import { Button } from '../../../../../../components/buttons';
import { Text } from '../../../../../../containers/MesssageContainers';

const Features = ['Administrative Portal', "Lecturer's Portal", 'Student Portal', 'All Features Inclusive', 'Security'];

const Customize = ({ onClick, seats, setSeats }) => {
    const [activeTab, setActiveTab] = useState(1);
    const { billingPlans } = useSelector((store) => store.ict);
    return (
        <div className="">
            <div className="grid grid-cols-3 gap-[2.4rem] items-start">
                <div className="col-span-2 flex flex-1 flex-col gap-[2.4rem]">
                    <div className="flex flex-col pt-[3.35rem] pl-[4.2rem] pr-[4rem] pb-[3.6rem] border-[1px] rounded-[24px] border-[#E5E7EB] shadow-[0px_14px_21px_-1px_rgba(6,25,56,0.08)]">
                        <div className="flex items-center justify-between">
                            <Text align="left" size="2.3rem" weight="600">
                                Yearly Plan
                            </Text>

                            <div className="flex items-center gap-[1.6rem]">
                                <Text align="left" size="4rem" weight="600" color="#1F2937">
                                    NGN {billingPlans?.[0]?.displayAmount}
                                </Text>
                                <div className="">
                                    <Text align="left" size="1.4rem" weight="500" color="#1F2937">
                                        per seat
                                    </Text>
                                    <Text align="left" size="1.4rem" weight="500" color="#1F2937">
                                        per month
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <Text align="right" size="1.4rem" weight="500" right="6rem" color="#6B7280">
                            NGN {billingPlans?.[0]?.displayAmount}
                        </Text>
                        <div className="pt-[2.8rem] flex items-center gap-[2.5rem]">
                            <div className="w-[20.1rem] flex items-center border-[1px] border-[#9CA3AF] rounded-[4px]">
                                <div
                                    onClick={() => {
                                        if (seats - 1 < 0) {
                                            setSeats('0');
                                        } else {
                                            setSeats(seats - 1);
                                        }
                                    }}
                                    className="flex cursor-pointer items-center justify-center border-r-[1px] border-[#9CA3AF] p-[8px]"
                                >
                                    <Minus />
                                </div>
                                <div className="flex-1 flex items-center justify-center">
                                    <input
                                        value={seats}
                                        onChange={(e) => {
                                            if (!isNaN(+e.target.value)) {
                                                setSeats(e.target.value);
                                            } else {
                                                toast.error('Only number input is accepted');
                                            }
                                        }}
                                        type="text"
                                        className="outline-none w-[11rem] text-center focus-none text-[#6B7280] text-[1.4rem] font-normal"
                                    />
                                </div>
                                <div
                                    onClick={() => {
                                        setSeats(seats + 1);
                                    }}
                                    className="flex cursor-pointer items-center justify-center border-l-[1px] border-[#9CA3AF] p-[8px]"
                                >
                                    <Plus />
                                </div>
                            </div>
                            <Text size="1.4rem" weight="500">
                                Please select the desired number of seats
                            </Text>
                        </div>
                    </div>

                    <div className="pt-[3.35rem] flex flex-col gap-[1.6rem] pl-[4.2rem] pr-[4rem] pb-[3.6rem] border-[1px] rounded-[24px] border-[#E5E7EB] shadow-[0px_14px_21px_-1px_rgba(6,25,56,0.08)]">
                        {Features?.map((item, i) => (
                            <div key={i} className="flex items-center gap-[1.6rem]">
                                <CheckMark fill="#34D399" />
                                <Text align="400" size="1.4rem">
                                    {item}
                                </Text>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pt-[3.35rem] pl-[4.2rem] pr-[4rem] pb-[3.6rem] border-[1px] rounded-[24px] border-[#E5E7EB] shadow-[0px_14px_21px_-1px_rgba(6,25,56,0.08)]">
                    <div className="flex items-center justify-center pb-[3.2rem]">
                        <div className={`w-[17.6rem] bg-[#DCF7FE] rounded-[1000px] flex items-center justify-center`}>
                            <div
                                onClick={() => setActiveTab(1)}
                                className={`rounded-[1000px] py-[0.6rem] w-[8.8rem] px-[1.4rem] cursor-pointer ${
                                    activeTab === 1 ? 'bg-[#DCF7FE]' : 'bg-[#F3F4F6]'
                                }`}
                            >
                                <Text align="center" color="#9CA3AF" size="1.4rem" weight="600">
                                    Monthly
                                </Text>
                            </div>
                            <div
                                onClick={() => setActiveTab(2)}
                                className={`${
                                    activeTab === 2 ? 'bg-[#DCF7FE]' : 'bg-[#F3F4F6]'
                                } px-[1.4rem] py-[0.6rem] rounded-[1000px] w-[8.8rem] cursor-pointer`}
                            >
                                <Text align="left" color="#9CA3AF" size="1.4rem" weight="600">
                                    Yearly
                                </Text>
                            </div>
                        </div>
                    </div>
                    {activeTab === 1 && (
                        <div className="">
                            <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                                Bill Summary
                            </Text>
                            <Text align="left" size="1.4rem" color="#1F2937" weight="600" top="1.6rem">
                                {seats} seats
                            </Text>
                            <Text align="left" size="1.4rem" color="#6B7280" weight="600" top="0.4rem" bottom="4rem">
                                NGN {seats * billingPlans?.[0]?.displayAmount}
                            </Text>
                            <div className="h-[1px] bg-[#E5E7EB] w-[19.7rem]"></div>
                            <Text align="left" color="#1F2937" size="1.4rem" weight="600" top="0.8rem">
                                Total per month
                            </Text>
                            <Text align="left" color="#1F2937" size="1.9rem" weight="600">
                                NGN {seats * billingPlans?.[0]?.displayAmount}
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="500"
                                top="1.4rem"
                                lineHeight="1.7rem"
                            >
                                Prorated charge today
                            </Text>
                            <Text align="left" color="#059669" size="1.2rem" weight="500" lineHeight="1.7rem">
                                $0
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0.8rem"
                                bottom="0rem"
                                lineHeight="1.7rem"
                            >
                                Next Renewal is on <span className="text-[1.2rem] font-medium text-[#6B7280]">[]</span>
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0rem"
                                lineHeight="1.7rem"
                            >
                                and you’ll be charged{' '}
                                <span className="text-[1.2rem] font-medium text-[#059669]">[]</span>
                            </Text>
                            <Button onClick={onClick} bgColor="#6366F1" color="#fff" className="mx-[auto] mt-[3rem]">
                                Continue
                            </Button>
                        </div>
                    )}
                    {activeTab === 2 && (
                        <div className="">
                            <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                                Bill Summary
                            </Text>
                            <Text align="left" size="1.4rem" color="#1F2937" weight="600" top="1.6rem">
                                {seats} Seats
                            </Text>
                            <Text align="left" size="1.4rem" color="#6B7280" weight="600" top="0.4rem" bottom="4rem">
                                NGN {seats * billingPlans?.[0]?.displayAmount}
                            </Text>
                            <div className="h-[1px] bg-[#E5E7EB] w-[19.7rem]"></div>
                            <Text align="left" color="#1F2937" size="1.4rem" weight="600" top="0.8rem">
                                Total per year
                            </Text>
                            <Text align="left" color="#1F2937" size="1.9rem" weight="600">
                                NGN {seats * billingPlans?.[0]?.displayAmount * 12}
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="500"
                                top="1.4rem"
                                lineHeight="1.7rem"
                            >
                                Prorated charge today
                            </Text>
                            <Text align="left" color="#059669" size="1.2rem" weight="500" lineHeight="1.7rem">
                                $0
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0.8rem"
                                bottom="0rem"
                                lineHeight="1.7rem"
                            >
                                Next Renewal is on <span className="text-[1.2rem] font-medium text-[#6B7280]">[]</span>
                            </Text>
                            <Text
                                align="left"
                                color="#6B7280"
                                size="1.2rem"
                                weight="400"
                                top="0rem"
                                lineHeight="1.7rem"
                            >
                                and you’ll be charged{' '}
                                <span className="text-[1.2rem] font-medium text-[#059669]">[]</span>
                            </Text>
                            <Button onClick={onClick} bgColor="#6366F1" color="#fff" className="mx-[auto] mt-[3rem]">
                                Continue
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-[7.2rem] w-full py-[2.7rem] px-[3.6rem] flex items-center justify-between rounded-[8px] bg-[#F3F4F6]">
                <div className="flex items-center gap-[0.3rem]">
                    <div className="">
                        <Smiley />
                    </div>
                    <Text color="#1F2937" size="1.6rem" weight="500">
                        <span className="font-semibold text-[1.6rem] text-[#1F2937]">Need help?</span> Talk to an expert
                        and find the best plan for your team
                    </Text>
                </div>
                <Button bgColor="#fff" color="#000">
                    Contact Us
                </Button>
            </div>
        </div>
    );
};

export default Customize;
