import React from 'react';

import tw from 'twin.macro';

import { capitalizeEachWord } from '../../../../utils';
import DetailsBox from '../details-box';

const MainContainer = tw.div`
    grid
    grid-cols-2
    grid-flow-row
    row-auto
    gap-6
    mt-8
`;

const Sports = ({ details }) => {
    const { sportData, counselData } = details || {};
    const { sportInfo } = sportData || {};
    const { counselInfo } = counselData || {};

    const sportInformationLeft = [
        {
            title: 'Name of Major Sport/Game',
            subtitle: capitalizeEachWord(counselInfo?.nameOfSport),
        },
        {
            title: 'Achievement (Prize, Medals)',
            subtitle: capitalizeEachWord(counselInfo?.achievement),
        },
    ];
    const sportInformationRight = [
        {
            title: 'Name of other Sport/Game',
            subtitle: capitalizeEachWord(counselInfo?.nameOfOtherSport),
        },
        {
            title: 'Other Achievement (Prize, Medals)',
            subtitle: capitalizeEachWord(counselInfo?.achievements),
        },
    ];
    const otherInformationLeft = [
        {
            title: 'Health Challenges',
            subtitle: sportInfo?.healthChallenge,
        },
        {
            title: 'Wish to represent the school',
            subtitle: sportInfo?.wishToRep,
        },
    ];
    const otherInformationRight = [
        {
            title: 'Purpose of participating in sports',
            subtitle: sportInfo?.wishToParticipate,
        },
        {
            title: 'Interested in Social Sports',
            subtitle: sportInfo?.sportChoice,
        },
    ];
    return (
        <div>
            <MainContainer>
                <DetailsBox
                    heading="Sports/Games Information"
                    leftData={sportInformationLeft}
                    rightData={sportInformationRight}
                />
                <DetailsBox
                    heading="Other Information"
                    leftData={otherInformationLeft}
                    rightData={otherInformationRight}
                />
            </MainContainer>
        </div>
    );
};

export default Sports;
