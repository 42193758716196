import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/peopleIcon.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { LEVEL, SEMESTER } from '../../../data/timetable';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { getCoursesInFirstDegree } from '../../../redux/courses/actions';
import { getFirstDegreeLectureVenues } from '../../../redux/faculty/actions';
import { createExamTimetable } from '../../../redux/timetable/actions';
import { LoadingButton } from '../../buttons';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import TimeInput from '../../inputs/time-input';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationScema';

const MainContainer = tw.div`
    bg-white
    rounded-[16px]
    px-[3rem]
    pb-[3rem]
`;

const InputContainer = tw.div`
    grid
    grid-cols-2
    gap-[2.5rem]
`;

const CreateExamTimetable = ({ onClose, refetch }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sessions } = useSelector((store) => store.admission);
    const { currentFaculty, firstDegreeLectureVenues } = useSelector((store) => store.faculty);
    const { isLoading } = useSelector((store) => store.timetable);
    const { firstDegreeCourses } = useSelector((store) => store.courses);
    const { _id: firstDegreeId, academicStructureId } = currentFaculty || {};

    const [selectedSemester, setSelectedSemester] = useState('');
    const [secondDegreeId, setSecondDegreeId] = useState('');

    useEffect(() => {
        dispatch(getAdmissionSessions());
        //eslint-disable-next-line
    }, [dispatch]);

    const semesterCourses = useMemo(
        () =>
            firstDegreeCourses?.filter(
                (item) => item.courseSemester?.toLowerCase() === selectedSemester?.toLowerCase(),
            ),
        [firstDegreeCourses, selectedSemester],
    );

    useEffect(() => {
        dispatch(getFirstDegreeLectureVenues(academicStructureId, firstDegreeId));
    }, [dispatch, academicStructureId, firstDegreeId]);

    useEffect(() => {
        dispatch(getCoursesInFirstDegree(academicStructureId, firstDegreeId));
    }, [academicStructureId, firstDegreeId, dispatch]);

    return (
        <Formik
            initialValues={{
                session: '',
                semester: '',
                level: '',
                course: '',
                examDate: '',
                startTime: '',
                endTime: '',
                venue: '',
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values, actions) => {
                const payload = {
                    ...values,
                    examDate: new Date(values.examDate).toISOString().slice(0, 10),
                    level: values.level.toString(),
                };
                const res = dispatch(createExamTimetable(payload, academicStructureId, secondDegreeId));
                if (res) {
                    actions.resetForm();
                    navigate(-1);
                }
            }}
        >
            {({ errors, handleChange, values, setFieldValue, touched }) => (
                <Form className="max-w-[1000px]">
                    <PageTitle align="left" bottom="3.4rem">
                        Exam Time-table
                    </PageTitle>
                    <MainContainer>
                        <div className="flex items-center gap-[1rem] py-[3.5rem]">
                            <PeopleIcon />
                            <Text size="1.6rem" weight="600">
                                Set Exam Time-table
                            </Text>
                        </div>
                        <InputContainer>
                            <Select
                                name="session"
                                objProp="admissionYear"
                                label="Session"
                                data={sessions}
                                placeholder="Session"
                                onChange={(selected) => {
                                    setFieldValue('session', selected[0].admissionYear);
                                }}
                                error={errors.session}
                            />

                            <Select
                                name="semester"
                                objProp="name"
                                label="Semester"
                                data={SEMESTER.map((item) => ({ name: item }))}
                                placeholder="Semester"
                                onChange={(selected) => {
                                    setFieldValue('semester', selected[0].name);
                                    setSelectedSemester(selected[0].name);
                                }}
                                error={errors.semester}
                            />

                            <Select
                                name="course"
                                objProp="courseCode"
                                label="Course"
                                placeholder="Course"
                                searchable
                                data={semesterCourses}
                                onChange={(selected) => {
                                    setFieldValue('course', selected[0].courseCode);
                                    const structure = selected[0].academicStructure;
                                    const secondDegree = structure.split('-')[1];
                                    if (secondDegree === 'program') {
                                        setSecondDegreeId(selected[0]?.subProgramId);
                                    } else {
                                        setSecondDegreeId(selected[0][`${secondDegree}Id`]);
                                    }
                                }}
                                error={errors.course}
                            />

                            <Select
                                name="level"
                                objProp="name"
                                label="Level"
                                data={LEVEL.map((item) => ({ name: item }))}
                                placeholder="Level"
                                onChange={(selected) => {
                                    setFieldValue('level', selected[0].name);
                                }}
                                error={errors.level}
                            />

                            <DatePicker label="Date" name="examDate" touched={touched} />

                            <TimeInput
                                label="Start Time"
                                name="startTime"
                                type="time"
                                onChange={handleChange}
                                value={values.startTime}
                                error={errors.startTime}
                            />

                            <TimeInput
                                label="End Time"
                                name="endTime"
                                type="time"
                                onChange={handleChange}
                                value={values.endTime}
                                error={errors.endTime}
                            />

                            <Select
                                name="venue"
                                objProp="hallName"
                                label="Venue"
                                searchable
                                placeholder="Venue"
                                data={firstDegreeLectureVenues}
                                onChange={(selected) => {
                                    setFieldValue('venue', selected[0].hallName);
                                }}
                                error={errors.venue}
                            />
                        </InputContainer>

                        <FlexRowEnd className="gap-[2.4rem] mt-[3.6rem]">
                            <CancelButton type="button" onClick={() => onClose()} disabled={isLoading}>
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                bgColor="#6366F1"
                                color="#fff"
                                type="submit"
                            >
                                Create
                            </LoadingButton>
                        </FlexRowEnd>
                    </MainContainer>
                </Form>
            )}
        </Formik>
    );
};

export default CreateExamTimetable;
