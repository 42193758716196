import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { editVc } from '../../../redux/appointments/actions';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditVc = ({ show, setShow, btnAction, setDetails, id }) => {
    const onClose = () => setShow(false);
    const { allStaff } = useSelector((store) => store.staff);
    const { appointmentDetails } = useSelector((store) => store.appointment);
    const dispatch = useDispatch();

    return (
        <FormContainer close={onClose} show={show} title={'Edit Vice Chancellor'}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    startDate: appointmentDetails[0]?.startDate?.slice(0, 10) || '',
                    endDate: appointmentDetails[0]?.endDate?.slice(0, 10) || '',
                    name: appointmentDetails[0]?.name || '',
                }}
                onSubmit={async (values, action) => {
                    if (values.startDate) values.startDate = new Date(values.startDate).toISOString().slice(0, 10);
                    if (values.endDate) values.endDate = new Date(values.endDate).toISOString().slice(0, 10);
                    setDetails(values);
                    dispatch(editVc(id, values, onClose));
                }}
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                        <div className="gap-10 flex flex-col">
                            <Select
                                name="name"
                                objProp="fullName"
                                data={allStaff}
                                placeholder="Select Name"
                                label="Select Name"
                                onChange={(selected) => {
                                    setFieldValue('name', selected[0].fullName);
                                }}
                                error={errors.name}
                                passedSelectedItems={values?.name}
                            />

                            <DatePicker
                                label="Start Date (Optional)"
                                initialValue={
                                    appointmentDetails?.[0]?.startDate
                                        ? new Date(appointmentDetails?.[0]?.startDate)
                                        : null
                                }
                                name="startDate"
                                touched={touched}
                            />

                            <DatePicker
                                label="End Date (Optional)"
                                initialValue={
                                    appointmentDetails?.[0]?.endDate ? new Date(appointmentDetails?.[0]?.endDate) : null
                                }
                                name="endDate"
                                touched={touched}
                            />
                        </div>
                        <FlexContainer>
                            <CancelButton type="button" onClick={onClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit" onClick={btnAction}>
                                Appoint Staff
                            </SubmitButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default EditVc;
