import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as TrashImage } from '../../../../assets/icons/delete-org.svg';
import { Button } from '../../../../components/buttons';
import ConfirmActionDialogue from '../../../../components/popups/confirm-action';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { deleteOrganization } from '../../../../redux/ict/actions';
import { logoutUser } from '../../../../redux/user/actions';

const OrganizationDelete = () => {
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const [password, setPassword] = useState('');
    const { isLoading } = useSelector((store) => store.ict);
    const user = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const handleDelete = async () => {
        const payload = { password: password };
        const res = await dispatch(deleteOrganization(payload));
        if (res) {
            dispatch(logoutUser(navigate));
        }
    };
    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[0.8rem]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Organization Settings
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Account Deletion</Text>
            </FlexCentredRow>
            <PageTitle align="left" top="3.4rem" bottom="1.7rem">
                Account Deletion
            </PageTitle>
            <div className="flex justify-between items-start">
                <Text align="left" weight="500" size="1.4rem" className="w-[41rem]">
                    By deleting this organisation, you permanently delete all associated data. There’s no turning back!
                </Text>
                <Button type="button" bgColor="#EF4444" color="#fff" onClick={() => setShowDelete(true)}>
                    Delete Organization
                </Button>
            </div>
            <TrashImage />
            <ConfirmActionDialogue
                show={showDelete}
                close={() => setShowDelete(false)}
                title="Delete Organisation Permanently?"
                input
                placeholder="Input Password"
                btn2Text="Yes, Delete Account"
                bgColor="#1F2937"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                confirmAction={handleDelete}
                isLoading={isLoading}
            >
                <Text align="left" weight="400" size="1.6rem" top="1.6rem">
                    This action cannot be undone. Please be certain about your decision.
                </Text>

                <Text align="left" weight="400" size="1.6rem" top="3.2rem">
                    Deleting {user?.institutionName} will delete all of its content, files, lecturers, students, records
                    and plans.
                </Text>

                <Text align="left" weight="400" size="1.6rem" top="3.2rem">
                    If you have an active subscription you will lose access to it. There are no refunds.
                </Text>

                <Text align="left" weight="400" size="1.6rem" top="3.2rem" bottom="3.2rem">
                    To confirm deletion, enter {user?.institutionName} Organisation password in the text field.
                </Text>
            </ConfirmActionDialogue>
        </div>
    );
};

export default OrganizationDelete;
