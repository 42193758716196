export const libraryNavLinks = [
    {
        title: 'Communications',
        to: '/library/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/library/communications/compose',
            },
            {
                title: 'Received',
                to: '/library/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/library/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/library/communications/settings',
            },
        ],
    },
    { title: 'Library', to: '/library/home' },
    {
        title: 'Academic Calendar',
        to: '/library/academic-calendar',
    },
    { title: 'Access', to: '/library/access' },
    {
        title: 'Settings',
        to: '/library/settings',
    },
];

export const libraryRoutes = libraryNavLinks.map((item) => item.to);
