export const hostelData = [
    {
        name: 'Adeniyi Hostel',
        gender: 'Male',
        room: '50',
        bedspace: '10',
    },
    {
        name: 'Azikwe Hostel',
        gender: 'Male',
        room: '30',
        bedspace: '10',
    },
    {
        name: 'Babangida Hostel',
        gender: 'Male',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Awolowo Hostel',
        gender: 'Male',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Adeniyi Hostel',
        gender: 'Male',
        room: '50',
        bedspace: '10',
    },
    {
        name: 'Azikwe Hostel',
        gender: 'Male',
        room: '30',
        bedspace: '10',
    },
    {
        name: 'Babangida Hostel',
        gender: 'Mixed',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Awolowo Hostel',
        gender: 'Female',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Adeniyi Hostel',
        gender: 'Male',
        room: '50',
        bedspace: '10',
    },
    {
        name: 'Azikwe Hostel',
        gender: 'Male',
        room: '30',
        bedspace: '10',
    },
    {
        name: 'Babangida Hostel',
        gender: 'Mixed',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Awolowo Hostel',
        gender: 'Female',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Adeniyi Hostel',
        gender: 'Male',
        room: '50',
        bedspace: '10',
    },
    {
        name: 'Azikwe Hostel',
        gender: 'Male',
        room: '30',
        bedspace: '10',
    },
    {
        name: 'Babangida Hostel',
        gender: 'Female',
        room: '100',
        bedspace: '10',
    },
    {
        name: 'Awolowo Hostel',
        gender: 'Female',
        room: '100',
        bedspace: '10',
    },
];
