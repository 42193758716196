import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import arrowDownIcon from '../assets/icons/arrow-down.svg';
import bellIcon from '../assets/icons/bell.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/exit.svg';
import { ReactComponent as StaffIcon } from '../assets/icons/staff-arena.svg';
import { ActionsPopup } from '../components';
import Avatar from '../components/avatar/Avatar';
import ConfirmActionDialogue from '../components/popups/confirm-action';
import { logoutUser } from '../redux/user/actions';

const Header = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { organizationProfile } = useSelector((store) => store.ict);
    const { userData, staffData } = useSelector((state) => state.user);

    const { appointmentId = '', surname = '', firstName = '' } = staffData || {};

    const fullName = userData?.firstName ? `${userData?.firstName} ${userData?.lastName}` : `${surname} ${firstName}`;

    const [showConfirmActionDialogue, setShowConfirmActionDialogue] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const optionalActionItem =
        staffData?.userType === 'staff'
            ? [
                  {
                      icon: <StaffIcon />,
                      name: 'Staff Arena',
                      click: () => {
                          if (appointmentId) {
                              navigate('/staff-arena/office/inbox');
                          } else {
                              navigate('/staff-arena/communications/received');
                          }
                          setDropdownOpen(false);
                      },
                  },
              ]
            : [];

    return (
        <>
            <header
                onClick={() => {
                    if (dropdownOpen) setDropdownOpen(!dropdownOpen);
                }}
                className="w-full top-0 right-0 h-[6.5rem] pl-[2.4rem] flex sticky z-10 pr-[3rem] bg-white shadow-sm"
            >
                {/* <div>
                    <img className="cursor-pointer" src={menuIcon} alt="menu" onClick={() => setOpen(!open)} />
                </div> */}
                <Link to="/dashboard" className="flex items-center">
                    <div className="w-[4rem] h-[4rem] flex items-center justify-center">
                        {organizationProfile?.logo?.url ? (
                            <img src={organizationProfile?.logo?.url} alt="logo" />
                        ) : (
                            <h1 className={` text-[#312E81] text-[2rem] text-center ml-[13.32px] font-semibold mb-0`}>
                                {organizationProfile?.institutionName?.charAt(0)}
                            </h1>
                        )}
                    </div>

                    <h1 className={` text-[#312E81] text-[2rem] ml-[13.32px] font-semibold mb-0`}>
                        {organizationProfile?.institutionName}
                    </h1>
                </Link>
                <div className="flex justify-center items-center ml-auto">
                    <img
                        src={bellIcon}
                        alt="notification"
                        className="mr-[1.2rem] rounded-full p-[1rem] bg-light-ash cursor-pointer"
                    />

                    <div className="relative">
                        <button
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="w-max h-[3.9312rem] rounded-[3.2rem] px-3 bg-light-ash flex items-center justify-start"
                        >
                            <Avatar imageUrl={userData?.profilePic || staffData?.profile?.url} fullName={fullName} />

                            <p className="ml-2 mr-[1.367rem] mb-0 leading-[2.1rem] text-[#2E4457] font-medium text-[1.4rem]">
                                {fullName}
                            </p>

                            <img src={arrowDownIcon} alt="dropdown" />
                        </button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                ...optionalActionItem,
                                {
                                    icon: <LogoutIcon />,
                                    name: 'Logout',
                                    click: () => {
                                        setShowConfirmActionDialogue(true);
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </header>
            <ConfirmActionDialogue
                show={showConfirmActionDialogue}
                title="Logging Out"
                subtitle="Are you sure you want to log out?"
                close={() => setShowConfirmActionDialogue(false)}
                confirmAction={() => dispatch(logoutUser(navigate))}
                btn2Text="Logout"
            />
        </>
    );
};

export default Header;
