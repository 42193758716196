import { SECURITY } from '../../utils/unitNames';
import {
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    Received,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    Access,
    Staff,
    Administrator,
    Roles,
} from '../../views';

export const securityRoutes = [
    {
        path: '/security/communications/distribution-list',
        element: <DistributionList title="Communications" />,
    },
    {
        path: '/security/communications/distribution-list/add',
        element: <AddDistributionList title="Communications" />,
    },
    {
        path: '/security/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList title="Communications" />,
    },
    {
        path: '/security/communications/received',
        element: <Received />,
    },
    {
        path: '/security/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/security/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/security/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/security/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: '/security/access',
        element: (
            <Access
                staffs={<Staff unitName={SECURITY} />}
                administrators={<Administrator unitName={SECURITY} />}
                roles={<Roles unitName={SECURITY} />}
            />
        ),
    },
];
