import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    title: Yup.string().nullable().required('Required'),
    selectType: Yup.string().nullable().required('Required'),
    description: Yup.string().nullable().required('Required'),
    handbookUpload: Yup.object().shape({
        url: Yup.string().required('Required'),
    }),
});
