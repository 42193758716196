import React from 'react';

import InputContainer from '../../../components/Container/inputcontainer';
import SignInNewAdminForm from '../../../components/forms/sign-in-new';
import { PoweredBy } from '../../../components/powered-by';
import { AuthContainer } from '../confirm-password';



const AdminCreatePassword = () => {
    return (
        <AuthContainer>

            <InputContainer>
                <SignInNewAdminForm />
                <PoweredBy />
            </InputContainer>

        </AuthContainer>
    );
};

export default AdminCreatePassword;
