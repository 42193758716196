import React from 'react';

import {
    BasicContent,
    DetailsCardSubText,
    DetailsCardTitleText,
    RowContainer,
} from '../../../containers/RecordContainers';
import { FlexColumn } from '../../../containers/ScreenContainers';

const AcademicDetailsBox = ({
    academicSubdata,
    prop1,
    prop2,
    prop3,
    prop4,
    subdataTitle1,
    subdataTitle2,
    subdataTitle3,
    keyProp,
}) => {
    return (
        <div className="flex flex-col gap-[2.4rem]">
            {academicSubdata?.map((item, i) => (
                <BasicContent key={item?.[keyProp]}>
                    <RowContainer className="lg:text-left text-center">
                        <FlexColumn className="">
                            <DetailsCardTitleText>
                                {subdataTitle1} {i + 1}
                            </DetailsCardTitleText>
                            <DetailsCardSubText>{item?.[prop1]}</DetailsCardSubText>
                        </FlexColumn>
                        <FlexColumn>
                            <DetailsCardTitleText>{subdataTitle2}</DetailsCardTitleText>
                            <DetailsCardSubText>{item?.[prop2]}</DetailsCardSubText>
                        </FlexColumn>
                    </RowContainer>
                    <RowContainer className="lg:text-right text-center">
                        <FlexColumn className="">
                            <DetailsCardTitleText align="right">{subdataTitle3}</DetailsCardTitleText>
                            <DetailsCardSubText align="right">
                                {item?.[prop3]}-{item?.[prop4]}
                            </DetailsCardSubText>
                        </FlexColumn>
                    </RowContainer>
                </BasicContent>
            ))}
        </div>
    );
};

export default AcademicDetailsBox;
