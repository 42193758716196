import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as MSWordIcon } from '../../../../assets/icons/ms-wordIcon.svg';
import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdfIcon.svg';
import { ReactComponent as FileIcon } from '../../../../assets/icons/ptx-icon.svg';
import { GoBack } from '../../../../components/go-back';
import { Loader } from '../../../../components/loader';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const ViewFiles = () => {
    // const navigate = useNavigate();
    const { staffFiles } = useSelector((store) => store.staffArena);
    const { id } = useParams();
    const [file, setFile] = useState({});

    useEffect(() => {
        const singleFile = staffFiles?.find((item) => item._id === id);
        setFile(singleFile?.attachment);
    }, [id, staffFiles]);

    if (!file) return <Loader />;

    return (
        <div>
            <Text size="2.3rem" weight="600" align="left" bottom="1.3rem">
                Office
            </Text>
            <GoBack title="Office" subtitle="Files" />

            <div className="w-full bg-white py-[3rem] px-[2.4rem] mt-[2.4rem]">
                <FlexRowSpaceBetween>
                    <div className="flex items-center gap-[1.6rem] px-[1.6rem] pb-[3.2rem]">
                        {file?.fileName?.includes('xls') && <FileIcon />}
                        {file?.fileName?.includes('pdf') && <PDFIcon />}
                        {file?.fileName?.includes('doc') && <MSWordIcon />}
                        <div className="">
                            <Text align="left" size="1.9rem" weight="600">
                                {file?.fileName}
                            </Text>
                        </div>
                    </div>
                </FlexRowSpaceBetween>
                <iframe
                    title={file?.fileName}
                    className={file?.fileType}
                    width="100%"
                    height="600"
                    src={`https://docs.google.com/gview?url=${file?.url}&embedded=true`}
                ></iframe>
            </div>
        </div>
    );
};

export default ViewFiles;

// import FileViewer from 'react-file-viewer';

// const FileDisplay = () => {
//     const files = [
//       { src: 'path/to/document.pdf', type: 'pdf' },
//       { src: 'path/to/document.docx', type: 'docx' },
//       { src: 'path/to/document.xlsx', type: 'xlsx' },
//       { src: 'path/to/document.pptx', type: 'pptx' },
//       // Add more files here as needed
//     ];

//     return (
//       <div>
//         {files.map((file, index) => (
//           <FileViewer
//             key={index}
//             fileType={file.type}
//             filePath={file.src}
//           />
//         ))}
//       </div>
