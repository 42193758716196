import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import { FlexDiv } from '../../../containers/ScreenContainers';
import { DAY, LEVEL, SEMESTER } from '../../../data/timetable';
import { getCoursesInFirstDegree } from '../../../redux/courses/actions';
import { getFirstDegreeLectureVenues } from '../../../redux/faculty/actions';
import { editLectureTimetable, getLectureTimetable } from '../../../redux/timetable/actions';
import { Button, LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import { Loader } from '../../loader';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

const MainContainer = tw.div`
    bg-white
    rounded-lg
`;
const TimeInput = tw.input`
    border-[1px]
    border-[#E5E7EB]
    h-[3rem]
    lg:h-[4rem]
    rounded
    p-3
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    text-[1.4rem]
    text-[#6B7280]
    leading-6
`;
const FlowRoot = tw.div`
    flow-root
`;
const GridRows = tw.div`
    grid
    grid-cols-1
    grid-rows-3
`;
const Time = tw.div`
    grid
    grid-cols-3
    grid-rows-1
    justify-center
    items-center
    my-[1.5rem]
`;

const EditLectureTimeTable = ({ show, setShow, timetableId, refetch }) => {
    const dispatch = useDispatch();

    const { currentFaculty, firstDegreeLectureVenues } = useSelector((store) => store.faculty);
    const { isLoading } = useSelector((store) => store.timetable);
    const { sessions } = useSelector((store) => store.admission);
    const { firstDegreeCourses } = useSelector((store) => store.courses);

    const { _id: firstDegreeId, academicStructureId } = currentFaculty || {};
    const [formattedStart, setFormattedStart] = useState('');
    const [formattedEnd, setFormattedEnd] = useState('');

    const [lectureTimetable, setLectureTimetable] = useState(null);
    const [selectedSemester, setSelectedSemester] = useState('');

    const onClose = () => setShow(false);

    const semesterCourses = useMemo(
        () =>
            firstDegreeCourses?.filter(
                (item) => item.courseSemester?.toLowerCase() === selectedSemester?.toLowerCase(),
            ),
        [firstDegreeCourses, selectedSemester],
    );

    useEffect(() => {
        dispatch(getFirstDegreeLectureVenues(academicStructureId, firstDegreeId));
    }, [dispatch, academicStructureId, firstDegreeId]);

    useEffect(() => {
        dispatch(getCoursesInFirstDegree(academicStructureId, firstDegreeId));
    }, [academicStructureId, firstDegreeId, dispatch]);

    useEffect(() => {
        const getData = async () => {
            const data = await dispatch(getLectureTimetable(timetableId));
            if (data) {
                setSelectedSemester(data?.semester);
                setLectureTimetable(data);
            } else {
                setLectureTimetable(null);
            }
        };

        getData();
    }, [dispatch, timetableId]);

    useEffect(() => {
        const startTime = new Date(lectureTimetable?.startTime);
        // startTime.setUTCHours(13, 53, 0, 0);
        const endDate = new Date(lectureTimetable?.endTime);
        // endDate.setUTCHours(13, 53, 0, 0);

        const startHour = startTime?.getUTCHours();
        const startMins = startTime?.getUTCMinutes();
        const formattedStart = `${startHour?.toString().padStart(2, '0')}:${startMins?.toString().padStart(2, '0')}`;
        setFormattedStart(formattedStart);
        const endHour = endDate?.getUTCHours();
        const endMins = endDate?.getUTCMinutes();
        const formattedEnd = `${endHour?.toString().padStart(2, '0')}:${endMins?.toString().padStart(2, '0')}`;
        setFormattedEnd(formattedEnd);
    }, [lectureTimetable]);

    if (isLoading) return <Loader />;

    return (
        <FormContainer close={onClose} show={show} title={'Update Lecture Timetable'}>
            <Formik
                enableReinitialize
                initialValues={{
                    session: lectureTimetable?.session || '',
                    semester: lectureTimetable?.semester || '',
                    level: lectureTimetable?.level?.toString() || '',
                    course: lectureTimetable?.course || '',
                    weekday: lectureTimetable?.weekday || '',
                    startTime: formattedStart || '',
                    endTime: formattedEnd || '',
                    venue: lectureTimetable?.venue || '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(editLectureTimetable(timetableId, values));
                    if (res) {
                        onClose();
                        refetch();
                    }
                }}
            >
                {({ errors, handleChange, values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <GridRows className="gap-y-6">
                                <Select
                                    name="session"
                                    label="Session"
                                    objProp="admissionYear"
                                    data={sessions}
                                    placeholder="Choose Session"
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                    }}
                                    error={errors.session}
                                    useComponentState={false}
                                    passedSelectedItems={values?.session ? [{ admissionYear: values?.session }] : null}
                                />
                                <Select
                                    name="semester"
                                    label="Semester"
                                    objProp="name"
                                    data={SEMESTER?.map((item) => ({ name: item }))}
                                    placeholder="Select Semester"
                                    onChange={(selected) => {
                                        setSelectedSemester(selected[0].name);
                                        setFieldValue('course', selected[0].courseCode);
                                        setFieldValue('semester', selected[0].name);
                                    }}
                                    error={errors.semester}
                                    useComponentState={false}
                                    passedSelectedItems={values?.semester ? [{ name: values?.semester }] : null}
                                />
                                <Select
                                    name="course"
                                    label="Course"
                                    objProp="courseCode"
                                    data={semesterCourses}
                                    searchable
                                    placeholder="Select Course"
                                    onChange={(selected) => {
                                        setFieldValue('course', selected[0].courseCode);
                                    }}
                                    error={errors.course}
                                    useComponentState={false}
                                    passedSelectedItems={values?.course ? [{ courseCode: values?.course }] : null}
                                />
                                <Select
                                    name="level"
                                    label="Level"
                                    objProp="name"
                                    data={LEVEL?.map((item) => ({ name: item }))}
                                    placeholder="Select Level"
                                    onChange={(selected) => {
                                        setFieldValue('level', selected[0].name);
                                    }}
                                    error={errors.level}
                                    useComponentState={false}
                                    passedSelectedItems={values?.level ? [{ name: values?.level }] : null}
                                />
                                <Select
                                    name="weekday"
                                    label="Weekday"
                                    objProp="name"
                                    data={DAY?.map((item) => ({ name: item }))}
                                    placeholder="Select Weekday"
                                    onChange={(selected) => {
                                        setFieldValue('weekday', selected[0].name);
                                    }}
                                    error={errors.weekday}
                                    useComponentState={false}
                                    passedSelectedItems={values?.weekday ? [{ name: values?.weekday }] : null}
                                />
                            </GridRows>
                            <Time>
                                <TimeInput
                                    name="startTime"
                                    type="time"
                                    onChange={handleChange}
                                    value={values.startTime}
                                />
                                <Text size="1.4rem" weight="600" lineHeight="2.4rem" color="#6B7280">
                                    to
                                </Text>
                                <TimeInput name="endTime" type="time" onChange={handleChange} value={values.endTime} />
                            </Time>
                            <Select
                                name="venue"
                                label="venue"
                                objProp="hallName"
                                data={firstDegreeLectureVenues}
                                searchable
                                placeholder="Select Venue"
                                onChange={(selected) => {
                                    setFieldValue('venue', selected[0].hallName);
                                }}
                                error={errors.venue}
                                useComponentState={false}
                                passedSelectedItems={values?.venue ? [{ hallName: values?.venue }] : null}
                            />

                            <FlowRoot>
                                <FlexDiv className="float-right gap-[2.4rem] my-[3.6rem]">
                                    <CancelButton type="button" onClick={onClose}>
                                        Cancel
                                    </CancelButton>
                                    <Button
                                        bgColor="#fff"
                                        type="button"
                                        color="#D1D5DB"
                                        border="1px solid #E5E7EB"
                                        onClick={() => console.log('values', values)}
                                    >
                                        show values
                                    </Button>
                                    <LoadingButton type="submit" loading={isSubmitting}>
                                        Update
                                    </LoadingButton>
                                </FlexDiv>
                            </FlowRoot>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default EditLectureTimeTable;
