import React from 'react';

import tw, { styled } from 'twin.macro';

import { ReactComponent as EmptyState } from '../../../../../assets/icons/empty-state.svg';
import ListEmptyContent from '../../../../../components/list-empty';
import { Text } from '../../../../../containers/MesssageContainers';
import { CenteredContainer, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../layout/page-layout/PageLayout';

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mt-[1.6rem]
`;
const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const fields = [
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Title',
        accessorKey: 'title',
    },
    {
        header: 'Unit',
        accessorKey: 'unit',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Status',
        accessorKey: 'status',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
];

const CourseHistory = () => {
    const modifiedCourseHistory = []?.map((item) => {
        return {
            status: (
                <td>
                    {item.status === 'Compulsory' ? (
                        <StatusContainer bgColor="#EEF2FF">
                            <Text color="#2563EB" size="1.2rem">
                                {item.status}
                            </Text>
                        </StatusContainer>
                    ) : item.status === 'Elective' ? (
                        <StatusContainer bgColor="#FDF2F8">
                            <Text color="#BE185D" size="1.2rem">
                                {item.status}
                            </Text>
                        </StatusContainer>
                    ) : item.status === 'Required' ? (
                        <StatusContainer bgColor="#EFF6FF">
                            <Text color="#4F46E5" size="1.2rem">
                                {item.status}
                            </Text>
                        </StatusContainer>
                    ) : (
                        <StatusContainer bgColor="#F5F3FF">
                            <Text color="#8B5CF6" size="1.2rem">
                                {item.status}
                            </Text>
                        </StatusContainer>
                    )}
                </td>
            ),
            ...item,
        };
    });

    return (
        <div>
            <Details>
                <FlexRowSpaceBetween className="px-[1rem]">
                    <Text color="#9CA3AF" size="1.6rem">
                        Courses Registered:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Level:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Semester:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Cumulative Units:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                </FlexRowSpaceBetween>
            </Details>
            <PageLayout
                noMargin
                data={modifiedCourseHistory}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title="There's no course History"
                            subtitle="This student has not compelted a semester"
                            src={EmptyState}
                        ></ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </div>
    );
};

export default CourseHistory;
