import React from 'react';

import tw, { styled } from 'twin.macro';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down2.svg';

const Container = styled.div`
    position: relative;
    background-color: ${({ bgColor }) => bgColor || '#f3f4f6'};
    display: flex;
    padding: 11.5px 17.15px 11.5px 12px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border: ${({ border }) => border || '1px solid #e5e7eb'};
    height: 40px;
    border-top-left-radius: ${({ borderRadius }) => (borderRadius ? '8px' : null)};
    border-bottom-left-radius: ${({ borderRadius }) => (borderRadius ? '8px' : null)};
    border-top-right-radius: ${({ withRadius }) => (withRadius ? '8px' : null)};
    border-bottom-right-radius: ${({ withRadius }) => (withRadius ? '8px' : null)};

    &:hover {
        cursor: pointer;
    }
`;

const Name = tw.span`
    leading-[17px]
    text-[1.2rem]
    font-normal
`;

const SingleFilter = ({
    name,
    options,
    close,
    handleReset,
    handleApply,
    selected,
    withRadius,
    borderRadius,
    onClick,
}) => {
    return (
        <Container withRadius={withRadius} borderRadius={borderRadius} onClick={onClick}>
            <Name>{name}</Name>
            <ArrowDownIcon className="ml-[17.15px]" />
        </Container>
    );
};

export default SingleFilter;
