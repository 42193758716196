import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as DomainIcon } from '../../../../assets/icons/domain.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const Domain = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleActionClick = (route) => {
        navigate(route);
        setDropdownOpen(false);
    };

    return (
        <>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem]">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Settings
                    </PageTitle>
                    <Text left="1rem" size="1.6rem">
                        / Custom Domains
                    </Text>
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <FlexCentredRow className="mb-[5.8rem] gap-x-[1rem]">
                <PageTitle weight="600" size="3.3rem">
                    Custom Domains
                </PageTitle>
            </FlexCentredRow>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate('')} className="hover:cursor-pointer mb-[3.6rem]">
                    <Text left="1rem" size="1.6rem">
                        Host your portals with custom domains.
                    </Text>
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem" left="2.5rem">
                        Learn more
                    </PageTitle>
                </FlexCentredRow>

                <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                    Add custom domain
                    <ArrowDown className="ml-[8px]" />
                </Button>
                <ActionsPopup
                    open={dropdownOpen}
                    items={[
                        {
                            name: 'Admin Portal',
                            click: () => handleActionClick('add-admin'),
                        },
                        {
                            name: 'Lecturers Portal',
                            click: () => handleActionClick('add-lecturer'),
                        },
                        {
                            name: 'Student Portal',
                            click: () => handleActionClick('add-students'),
                        },
                    ]}
                />
            </FlexRowSpaceBetween>
            <DomainIcon className="ml-[30.6rem] mt-[3.6rem]" />
        </>
    );
};

export default Domain;
