import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete2.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit3.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
// import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-amber.svg';
import { ActionsPopup } from '../../../components';
import { Button, SecondaryButton } from '../../../components/buttons';
import AddStaffToUnit from '../../../components/forms/add-staff-to-unit';
import EditStaffEmail from '../../../components/forms/edit-staff-email';
import RemoveStaffFromUnit from '../../../components/forms/remove-staff-from-unit';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import SendOfferList from '../../../components/popups/send-offer-list';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexDiv, FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { getRoles } from '../../../redux/roles/actions';
import {
    deactivateStaff,
    deleteStaff,
    getAcademicStaff,
    getDeactivatedStaff,
    getNonAcademicStaff,
    // getStaffInUnit,
    inviteStaffToAdmin,
    inviteStaffToLecturersPortal,
} from '../../../redux/staff/actions';
import { storeUnit } from '../../../utils/originUnit';

import AcademicStaff from './tabs/AcademicStaff';
import InactiveStaff from './tabs/InactiveStaff';
import NonAcademicStaff from './tabs/NonAcademicStaff';

const ACTION_LOOKUP = {
    0: getAcademicStaff,
    1: getNonAcademicStaff,
    2: getDeactivatedStaff,
};

const StaffManagement = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading } = useSelector((state) => state.staff);

    const [tab, setTab] = useState(0);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [openModal, setOpenModal] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const handleSelect = (item) => {
        const selected = selectedItems?.some((selectedItem) => selectedItem._id === item._id);

        if (selected) {
            const newItems = selectedItems.filter((ite) => ite._id !== item._id);
            setSelectedItems(newItems);
        } else {
            setSelectedItems((prev) => [...prev, item]);
        }
    };

    const handleTabChange = (tab) => {
        setSelectedItems([]);
        setTab(tab);
    };

    const handleSendInvite = async () => {
        if (tab === 0 || tab === 1) {
            const names = selectedItems?.map((item) => item.fullName);
            const payload = { staffNames: names };

            const res =
                openModal === 'invite-admin'
                    ? await dispatch(inviteStaffToAdmin(payload))
                    : await dispatch(inviteStaffToLecturersPortal(payload));

            if (res) {
                setOpenModal('');
                setSelectedItems([]);
            }
        }
    };

    const handleCloseConfirmModal = () => {
        setOpenModal('');
        setConfirmInputValue('');
    };

    const handleDeactivate = async () => {
        if (confirmInputValue.toLowerCase() !== 'deactivate') return toast.error("Input doesn't match");

        const res = await dispatch(deactivateStaff(selectedItems?.[0]?._id));
        if (res) {
            setSelectedItems([]);
            handleCloseConfirmModal();
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleDelete = async () => {
        if (confirmInputValue.toLowerCase() !== 'delete') return toast.error("Input doesn't match");

        const res = await dispatch(deleteStaff(selectedItems?.[0]?._id));
        if (res) {
            setSelectedItems([]);
            handleCloseConfirmModal();
            setToggleRefetch((prevState) => !prevState);
        }
    };

    useEffect(() => {
        storeUnit(unitName);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
        dispatch(getRoles());
        // dispatch(getStaffInUnit());
    }, [dispatch]);

    useEffect(() => {
        const tabAction = ACTION_LOOKUP[tab];
        dispatch(tabAction());
    }, [dispatch, tab, toggleRefetch]);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-6">
                <PageTitle>Staff Management</PageTitle>
                <FlexDiv className="gap-8">
                    <Link to="add-staff">
                        <SecondaryButton>
                            <PlusIcon />
                            Add Staff
                        </SecondaryButton>
                    </Link>
                    <RelativeContainer>
                        <Button
                            bgColor="#6366F1"
                            color="#fff"
                            onClick={() => {
                                setOpenDropDown(!openDropDown);
                            }}
                        >
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openDropDown}
                            close={() => setOpenDropDown(false)}
                            items={[
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Staff',
                                    click: () => {
                                        navigate('edit-staff', { state: selectedItems?.[0] });
                                    },
                                    disabled:
                                        tab === 2 ||
                                        !selectedItems?.length ||
                                        selectedItems?.length > 1 ||
                                        selectedItems?.some(
                                            (selectedItem) => selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Staff Email',
                                    click: () => {
                                        setOpenModal('edit-email');
                                        setOpenDropDown(false);
                                    },
                                    disabled:
                                        tab === 2 ||
                                        !selectedItems?.length ||
                                        selectedItems?.length > 1 ||
                                        selectedItems?.some(
                                            (selectedItem) => selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                {
                                    icon: <SendIcon />,
                                    name: 'Invite to Admin Portal',
                                    click: () => {
                                        setOpenModal('invite-admin');
                                        setOpenDropDown(false);
                                    },
                                    notShown: tab === 2,
                                    disabled:
                                        !selectedItems?.length ||
                                        selectedItems?.some(
                                            (selectedItem) =>
                                                selectedItem.staffStatus === 'ACTIVE' ||
                                                selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                {
                                    icon: <SendIcon />,
                                    name: 'Invite to Lecturers Portal',
                                    click: () => {
                                        setOpenModal('invite-lecturer');
                                        setOpenDropDown(false);
                                    },
                                    notShown: tab === 1 || tab === 2,
                                    disabled:
                                        !selectedItems?.length ||
                                        selectedItems?.some(
                                            (selectedItem) =>
                                                selectedItem.lecturerStatus === 'ACTIVE' ||
                                                selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                {
                                    icon: <SettingsIcon />,
                                    name: 'Add Staff to Unit(s)',
                                    click: () => {
                                        setOpenModal('add-unit');
                                        setOpenDropDown(false);
                                    },
                                    notShown: tab === 2,
                                    disabled:
                                        !selectedItems?.length ||
                                        selectedItems?.length > 1 ||
                                        selectedItems?.some(
                                            (selectedItem) => selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                {
                                    icon: <SettingsIcon />,
                                    name: 'Remove Staff from Unit(s)',
                                    click: () => {
                                        setOpenModal('remove-unit');
                                        setOpenDropDown(false);
                                    },
                                    notShown: tab === 2,
                                    disabled:
                                        !selectedItems?.length ||
                                        selectedItems?.length > 1 ||
                                        selectedItems?.some(
                                            (selectedItem) => selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                {
                                    icon: <WarningIcon />,
                                    name: 'Deactivate Staff',
                                    click: () => {
                                        setOpenModal('deactivate');
                                        setOpenDropDown(false);
                                    },
                                    notShown: tab === 2,
                                    disabled:
                                        !selectedItems?.length ||
                                        selectedItems?.length > 1 ||
                                        selectedItems?.some(
                                            (selectedItem) => selectedItem.staffStatus === 'DEACTIVATED',
                                        ),
                                },
                                // {
                                //     icon: <RefreshIcon />,
                                //     name: 'Reinstate Staff',
                                //     click: () => {
                                //         setOpenModal('reinstate');
                                //         setOpenDropDown(false);
                                //     },
                                //     notShown: tab !== 2,
                                //     disabled: !selectedItems?.length,
                                // },
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Staff',
                                    click: () => {
                                        setOpenModal('delete');
                                        setOpenDropDown(false);
                                    },
                                    disabled: !selectedItems?.length || selectedItems?.length > 1,
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexDiv>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => handleTabChange(0)}>Academic Staff</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => handleTabChange(1)}>Non-Academic Staff</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => handleTabChange(2)}>Inactive Staff</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <AcademicStaff handleSelect={handleSelect} selectedItems={selectedItems} />
                    </CTabPane>
                    <CTabPane>
                        <NonAcademicStaff handleSelect={handleSelect} selectedItems={selectedItems} />
                    </CTabPane>
                    <CTabPane>
                        <InactiveStaff handleSelect={handleSelect} selectedItems={selectedItems} />
                    </CTabPane>
                </CTabContent>
            </CTabs>

            <ConfirmActionDialogueWithInput
                show={openModal === 'deactivate'}
                placeholder="Enter deactivate"
                title={`Deactivate ${selectedItems?.[0]?.fullName}`}
                subtitle="The staff's access will be revoked and the profile would be archived."
                subtitle2="To confirm Deactivation, enter 'deactivate' in the text field."
                close={() => handleCloseConfirmModal()}
                confirmAction={handleDeactivate}
                btn2Text="Yes, Deactivate"
                setInputValue={setConfirmInputValue}
                value={confirmInputValue}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'deactivate'}
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'reinstate'}
                placeholder="Enter reinstate"
                title={`Reinstate ${selectedItems?.[0]?.fullName}`}
                subtitle="The staff’s access will be reinstated and their profiles will be restored."
                subtitle2="To confirm re-engagement, enter 'reinstate' in the text field."
                close={() => handleCloseConfirmModal()}
                btn2Text="Yes, Reinstate"
                setInputValue={setConfirmInputValue}
                value={confirmInputValue}
                svgIcon={<WarningIcon />}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'reinstate'}
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                placeholder="Enter delete"
                title={`Delete ${selectedItems?.[0]?.fullName}`}
                subtitle="The staff will be deleted and their profile will be permanently removed from the system."
                subtitle2="To confirm, enter 'delete' in the text field."
                close={() => handleCloseConfirmModal()}
                btn2Text="Yes, Delete"
                setInputValue={setConfirmInputValue}
                confirmAction={handleDelete}
                value={confirmInputValue}
                svgIcon={<WarningIcon />}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'delete'}
            />

            <SendOfferList
                show={openModal === 'invite-admin' || openModal === 'invite-lecturer'}
                list={selectedItems}
                onSend={() => handleSendInvite()}
                title="Send Invite"
                subtitle={`The selected staff member(s) will be invited to the ${
                    openModal === 'invite-admin' ? 'admin' : 'lecturers'
                } portal`}
                close={() => setOpenModal(null)}
                idField="_id"
                isLoading={isLoading}
            />

            <EditStaffEmail
                show={openModal === 'edit-email'}
                setShow={setOpenModal}
                selectedStaff={selectedItems?.[0]}
                tab={tab}
            />
            <AddStaffToUnit
                show={openModal === 'add-unit'}
                setShow={setOpenModal}
                selectedStaff={selectedItems?.[0]}
                tab={tab}
                onFinish={() => {
                    setSelectedItems([]);
                }}
            />
            <RemoveStaffFromUnit
                show={openModal === 'remove-unit'}
                setShow={setOpenModal}
                selectedStaff={selectedItems?.[0]}
                tab={tab}
                onFinish={() => {
                    setSelectedItems([]);
                }}
            />
        </div>
    );
};

export default StaffManagement;
