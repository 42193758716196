import React, { useEffect, useMemo, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { format } from 'date-fns';
import { prettyPrintJson } from 'pretty-print-json';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-sm.svg';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAuditLogs } from '../../../redux/ict/actions';
import { filterData } from '../../../utils/filterData';

const fields = [
    {
        accessorKey: 'event',
        header: 'EVENT',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        accessorKey: 'unit',
        header: 'UNIT',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        accessorKey: 'action',
        header: 'ACTION',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        accessorKey: 'name',
        header: 'STAFF NAME',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        accessorKey: 'ip',
        header: 'IP ADDRESS',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        accessorKey: 'timestamp',
        header: 'TIME STAMP',
        cell: (props) => <p>{props.getValue()}</p>,
    },
];

const AuditLog = () => {
    const dispatch = useDispatch();
    const { auditLogsData, isLoading } = useSelector((store) => store.ict);

    const { data: auditLogs, totalPages, totalLogs } = auditLogsData || {};
    const [selectedRow, setSelectedRow] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();

    const handleCollapse = (row) => {
        if (row?.id === selectedRow?.id) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
    };

    useEffect(() => {
        if (selectedRow?.original?.response) {
            const prettyHtml = prettyPrintJson.toHtml(selectedRow?.original?.response);
            const element = document.getElementById('pretty');
            if (element) {
                element.innerHTML = prettyHtml;
            }
        }
    }, [selectedRow]);

    const LogData = auditLogs?.map((log) => {
        const { fullName, firstName, lastName, surname, otherName } = log?.user || {};

        const date = new Date(log?.timestamp);
        const formattedDate = format(date, 'yyyy-MM-dd, hh:mm:ss a');
        const name = fullName || `${firstName} ${lastName || surname} ${otherName || ''}`;

        return {
            name: name,
            ip: log?.ipAddress,
            unit: log?.originUnit,
            timestamp: formattedDate,
            activity: log?.response?.message,
            action: log?.method,
            event: log?.activity,
            ...log,
        };
    });

    useEffect(() => {
        const queries = {
            page: currentPage,
            limit: tableSettings?.numOfPages,
        };

        dispatch(getAuditLogs(queries));
        //eslint-disable-next-line
    }, [dispatch, currentPage, tableSettings]);

    const auditData = LogData?.map((audit) => {
        return {
            action:
                audit?.method === 'POST'
                    ? 'CREATE'
                    : audit?.method === 'PUT' || audit?.method === 'PATCH'
                    ? 'UPDATE'
                    : audit?.method,
            ...audit,
        };
    });

    const memoizedAuditLogs = useMemo(() => {
        if (debouncedSearchValue) {
            const filteredLogs = filterData(auditData, debouncedSearchValue);
            return filteredLogs;
        } else {
            return auditData;
        }
    }, [debouncedSearchValue, auditData]);

    return (
        <PageLayout
            pageTitle="Audit Trail"
            showTableUtils
            searchable
            searchValue={searchValue}
            onSearchChange={(e) => setSearchValue(e.target.value)}
            onSearchClose={() => setSearchValue('')}
            fields={fields}
            data={memoizedAuditLogs}
            loading={isLoading}
            isCollapsible
            isActionable
            actionType="dropdown"
            actionProp={<ArrowRight />}
            openCollapseId={selectedRow?.id}
            handleAction={(row) => handleCollapse(row)}
            eventData={<pre id="pretty" className="json-container" style={{ fontSize: '1.4rem' }}></pre>}
            onCopyClick={() => {
                navigator.clipboard.writeText(JSON.stringify(selectedRow?.original?.response));
                toast.success('Copied!');
            }}
            isPaginated={true}
            paginationComponent={
                <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                    <PaginationStatus
                        currentPage={currentPage}
                        itemsPerPage={tableSettings?.numOfPages}
                        totalItems={totalLogs}
                    />
                    <Pagination
                        pageRangeDisplayed={3}
                        totalPages={totalPages}
                        onPageChange={(nextPage) => {
                            setCurrentPage(nextPage);
                        }}
                        currentPage={currentPage}
                    />
                </FlexRowSpaceBetween>
            }
            collapseTitle="Event Record"
        />
    );
};

export default AuditLog;
