import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { Button } from '../../buttons';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Name',
        accessorKey: 'fullName',
    },

    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Start Date',
        accessorKey: 'startDate',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'End Date',
        accessorKey: 'endDate',
    },
];
const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 58.6rem;
        max-height: 60rem;
    }
`;

// const ItemsContainer = tw.ul`
// overflow-y-auto
// border-b
// pb-2
// // flex
// // gap-4
// `;

const ViewHistory = ({ show, close, data }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    View History
                </Text>
                <CloseIcon onClick={close} />
            </FlexRowSpaceBetween>
            <Text align="start" size="1.6rem" color="#1F2937" top="1.6rem" lineHeight="2.4rem" bottom="3.2rem">
                This is the history of this office
            </Text>
            <PageLayout fields={fields} data={data} />
            <FlexRowEnd className="gap-x-[16px]">
                <Button onClick={close} bgColor="#6366F1" color="#fff">
                    Close
                </Button>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ViewHistory;
