import React from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
    // flex
    // gap-4
`;

const RevokeOffer = ({
    show,
    close,
    list,
    onSend,
    nameField = 'fullName',
    idField = 'id',
    isLoading,
    value,
    setInputValue,
}) => {
    return (
        <div>
            <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
                <FlexRowSpaceBetween>
                    <Text weight="600" size="1.9rem" color="#EF4444">
                        Revoke Offer
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={close} />
                </FlexRowSpaceBetween>

                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                    Are you sure you want to revoke the invite sent to these person(s)?
                </Text>

                <ItemsContainer>
                    {list?.map((item, index) => (
                        <Text align="left" key={index}>{`${item?.[nameField]}`}</Text>
                    ))}
                </ItemsContainer>
                <hr />
                <Text color="#6B7280" size="1.2rem" align="left">
                    {list?.length} name(s) selected
                </Text>

                <Text align="left" weight="400" size="1.6rem" bottom="1rem">
                    To confirm, enter <strong>revoke</strong> in the text field
                </Text>

                <TextInput
                    type="text"
                    placeholder={'Type Here'}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    required
                />
                <FlexRowEnd className="gap-x-[16px] mt-[1rem]">
                    <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton bgColor="#EF4444" disabled={value !== 'revoke'} loading={isLoading} onClick={onSend}>
                        Send
                    </LoadingButton>
                </FlexRowEnd>
            </StyledModal>
        </div>
    );
};

export default RevokeOffer;
