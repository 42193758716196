import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    designations: [],
    unitDesignations: [],
};

export const designationSlice = createSlice({
    name: 'designations',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_DESIGNATIONS: (state, action) => {
            state.designations = action.payload;
        },
        GET_UNIT_DESIGNATIONS: (state, action) => {
            state.unitDesignations = action.payload;
        },
    },
});

export const { GET_DESIGNATIONS, GET_UNIT_DESIGNATIONS, LOADING, STOP_LOADING } = designationSlice.actions;

export default designationSlice.reducer;
