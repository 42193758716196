import React from 'react';

import { format } from 'date-fns';
import tw from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../sharedStyles';

const Container = tw.div`
    bg-white
    px-6
    pt-12
    pb-5
`;

const GridRows = tw.div`
    grid
    md:grid-cols-2
    grid-rows-4
    gap-6
`;

const Label = tw.p`
    text-[1.2rem]
    text-[#4B5563]
    mb-0
`;

const CText = tw(Text)`
    font-medium
    text-left
`;

const Preview = ({ values, setStep, isLoading, onSubmit, splitStruct, secondarySecondDegree }) => {
    return (
        <Container>
            <Text size="1.9rem" weight="600" align={'start'} bottom="3.6rem">
                Preview
            </Text>
            <GridRows>
                <div>
                    <Label>Surname</Label>
                    <CText>{values?.surname}</CText>
                </div>
                <div>
                    <Label>First Name</Label>
                    <CText>{values?.firstName}</CText>
                </div>
                <div>
                    <Label>Other Names</Label>
                    <CText>{values?.otherName || ''}</CText>
                </div>

                <div>
                    <Label> Organization Unit</Label>
                    <CText>{values?.organizationUnit}</CText>
                </div>
                <div>
                    <Label>Official Email Address</Label>
                    <CText>{values?.staffEmail}</CText>
                </div>
                {values?.academicUnit && (
                    <div>
                        <Label>Academic Unit</Label>
                        <CText>{values?.academicUnit}</CText>
                    </div>
                )}
                {values?.firstDegree && (
                    <div>
                        <Label>{capitalizeFirstLetter(splitStruct?.[0])}</Label>
                        <CText>{values?.firstDegree}</CText>
                    </div>
                )}
                <div>
                    <Label>Employment Type</Label>
                    <CText>{values?.staffType}</CText>
                </div>
                {values?.secondDegree && (
                    <div>
                        <Label>{capitalizeFirstLetter(splitStruct?.[1])}</Label>
                        <CText>{values?.secondDegree}</CText>
                    </div>
                )}
                {secondarySecondDegree?.length > 0 && (
                    <div>
                        <Label>Secondary {capitalizeFirstLetter(splitStruct?.[1])}(s)</Label>
                        <CText>{secondarySecondDegree?.map((item) => item.departmentName).join(', ')}</CText>
                    </div>
                )}
                <div>
                    <Label>Staff Phone Number</Label>
                    <CText>{values?.phoneNumber}</CText>
                </div>
                <div>
                    <Label>Staff Number</Label>
                    <CText>{values?.staffNumber}</CText>
                </div>
                <div>
                    <Label>Designation</Label>
                    <CText>{values?.designation}</CText>
                </div>
                <div>
                    <Label>Employment Date </Label>
                    {values?.yearOfEmployment && (
                        <CText>{format(new Date(values?.yearOfEmployment), 'dd-MM-yyyy')}</CText>
                    )}
                </div>
            </GridRows>
            <FlexRowEnd className="gap-3 mt-4">
                <CancelButton type="button" onClick={() => setStep(1)}>
                    Previous
                </CancelButton>
                <LoadingButton type="submit" onClick={onSubmit} loading={isLoading} disabled={isLoading}>
                    Add Staff
                </LoadingButton>
            </FlexRowEnd>
        </Container>
    );
};

export default Preview;
