import React from 'react';

import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import tw from 'twin.macro';

import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddDirectors = ({ show, setShow, btnAction, setDetails }) => {
    const onClose = () => setShow(false);
    const { allStaff } = useSelector((store) => store.staff);
    const { activeOrgUnits } = useSelector((store) => store.organisationUnits);

    return (
        <>
            <FormContainer close={onClose} show={show} title={'Add Appointment'}>
                <Formik
                    initialValues={{
                        appointmentType: '',
                        unitName: '',
                        startDate: '',
                        endDate: '',
                        staffNo: '',
                        name: '',
                    }}
                    onSubmit={async (values, action) => {
                        setDetails(values);
                    }}
                >
                    {({ errors, handleChange, touched, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <Select
                                    name="name"
                                    objProp="fullName"
                                    placeholder="Select Name"
                                    label="Select Name"
                                    data={allStaff}
                                    onChange={(selected) => {
                                        setFieldValue('name', String(selected[0].fullName));
                                    }}
                                    error={errors.name}
                                />
                                <Select
                                    name="unitName"
                                    objProp="unitName"
                                    placeholder="Select unit"
                                    label="Select unit"
                                    data={activeOrgUnits}
                                    onChange={(selected) => {
                                        setFieldValue('unitName', String(selected[0].unitName));
                                    }}
                                    error={errors.unitName}
                                />
                                <DatePicker label="Start Date (Optional)" name="startDate" touched={touched} />
                                <DatePicker label="End Date (Optional)" name="endDate" touched={touched} />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <SubmitButton type="submit" onClick={btnAction}>
                                    Appoint Staff
                                </SubmitButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddDirectors;
