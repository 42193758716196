import React from 'react';

import { useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { Button } from '../../../../../components/buttons';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexRowEnd } from '../../../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../../../utils';

const MainContainer = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    grid-flow-row
    gap-6
    mt-8
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;

const FlexCol = tw.div`
    gap-6
    
`;
const ColContainer = tw.div`
    gap-6
    flex
    flex-col
`;

const Details = tw.div`
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    font-weight: 500;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #4b5563;
    font-weight: 500;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const Personal = ({ details }) => {
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);
    const firstDegreeStudent = details?.basicData?.academicStructure?.split('-')[0];
    const secondDegreeStudent = details?.basicData?.academicStructure?.split('-')[1];
    const firstDegree = details?.basicData?.[firstDegreeStudent];
    const secondDegree = details?.basicData?.[secondDegreeStudent];

    const leftRow = [
        {
            title: 'Title',
            subtitle: details?.bioData?.updateInfo?.title,
        },
        {
            title: 'Gender',
            subtitle: details?.bioData?.updateInfo?.gender,
        },
        {
            title: 'Personal Email Address',
            subtitle: details?.basicData?.studentEmail,
        },

        {
            title: 'Phone Number',
            subtitle: details?.bioData?.updateInfo?.phoneNumber,
        },
    ];
    const rightRow = [
        {
            title: 'Name',
            subtitle: `${details?.basicData?.lastName} ${details?.basicData?.firstName} ${
                details?.basicData?.middleName || ''
            }`,
        },
        {
            title: 'Date of Birth',
            subtitle: details?.bioData?.updateInfo?.dateOfBirth?.slice(0, 10),
        },

        {
            title: 'Matric Number',
            subtitle: details?.basicData?.matricNumber,
        },
        {
            title: 'Marital Status',
            subtitle: details?.bioData?.updateInfo?.maritalStatus,
        },
    ];
    const otherLeft = [
        {
            title: 'Nationality',
            subtitle: details?.bioData?.updateInfo?.nationality,
        },
        {
            title: 'Home Town',
            subtitle: details?.bioData?.updateInfo?.homeTown,
        },
        {
            title: 'Place of Birth',
            subtitle: details?.bioData?.updateInfo?.placeOfBirth,
        },
        {
            title: 'Permanent Home Address',
            subtitle: details?.bioData?.updateInfo?.permanentAddress,
        },
    ];
    const otherRight = [
        {
            title: 'State of Origin',
            subtitle: details?.bioData?.updateInfo?.stateOfOrigin,
        },
        {
            title: 'LGA',
            subtitle: details?.bioData?.updateInfo?.localGovernment,
        },
        {
            title: 'Religion',
            subtitle: details?.bioData?.updateInfo?.religion,
        },
        {
            title: 'Alternative Home Address',
            subtitle: details?.bioData?.updateInfo?.alternativeHomeAddress,
        },
    ];
    const nextLeft = [
        {
            title: 'Name',
            subtitle: details?.bioData?.updateInfo?.nextOfKin,
        },
        {
            title: 'Phone Number',
            subtitle: details?.bioData?.updateInfo?.nextOfKinPhoneNumber,
        },
    ];
    const nextRight = [
        {
            title: 'Address',
            subtitle: details?.bioData?.updateInfo?.nextOfKinAddress,
        },
        {
            title: 'Relationship',
            subtitle: details?.bioData?.updateInfo?.relationshipWithNextOfKin,
        },
    ];
    const deptLeft = [
        {
            title: secondDegreeOptionsStr,
            subtitle: secondDegree,
        },
        {
            title: 'Level',
            subtitle: details?.basicData?.level,
        },
    ];
    const deptRight = [
        {
            title: firstDegreeOptionsStr,
            subtitle: firstDegree,
        },
        {
            title: 'Academic Degree Award',
            subtitle: '',
        },
    ];
    const parentLeft = [
        {
            title: "Father's Name",
            subtitle: capitalizeFirstLetter(details?.bioData?.updateInfo?.fatherName),
        },
        {
            title: "Father's Address",
            subtitle: details?.bioData?.updateInfo?.fatherAddress,
        },
        {
            title: "Father's Phone Number",
            subtitle: details?.bioData?.updateInfo?.fatherPhoneNumber,
        },
        {
            title: "Father's Email Address",
            subtitle: details?.bioData?.updateInfo?.fatherEmail,
        },
        {
            title: "Father's Occupation",
            subtitle: details?.bioData?.updateInfo?.fatherOccupation,
        },
    ];
    const parentRight = [
        {
            title: "Mother's Name",
            subtitle: capitalizeFirstLetter(details?.bioData?.updateInfo?.motherName),
        },
        {
            title: "Mother's Address",
            subtitle: details?.bioData?.updateInfo?.motherAddress,
        },
        {
            title: "Mother's Phone Number",
            subtitle: details?.bioData?.updateInfo?.motherPhoneNumber,
        },
        {
            title: "Mother's Email Address",
            subtitle: details?.bioData?.updateInfo?.motherEmail,
        },
        {
            title: "Mother's Occupation",
            subtitle: details?.bioData?.updateInfo?.motherOccupation,
        },
    ];
    const guardLeft = [
        {
            title: 'Name',
            subtitle: capitalizeFirstLetter(details?.bioData?.updateInfo?.guardianName),
        },
        {
            title: 'Address',
            subtitle: details?.bioData?.updateInfo?.guardianAddress,
        },

        {
            title: 'Email Address',
            subtitle: details?.bioData?.updateInfo?.guardianEmail,
        },
    ];
    const guardRight = [
        {
            title: 'Phone Number',
            subtitle: details?.bioData?.updateInfo?.guardianPhoneNumber,
        },

        {
            title: 'Occupation',
            subtitle: details?.bioData?.updateInfo?.guardianOccupation,
        },
    ];
    const sponsorLeft = [
        {
            title: 'Name',
            subtitle: capitalizeFirstLetter(details?.bioData?.updateInfo?.sponsorName),
        },
        {
            title: 'Address',
            subtitle: details?.bioData?.updateInfo?.sponsorAddress,
        },

        {
            title: 'Relationship',
            subtitle: details?.bioData?.updateInfo?.sponsorRelationship,
        },
    ];
    const sponsorRight = [
        {
            title: 'Phone Number',
            subtitle: details?.bioData?.updateInfo?.sponsorPhoneNumber,
        },

        {
            title: 'Occupation',
            subtitle: details?.bioData?.updateInfo?.sponsorOccupation,
        },
    ];
    const medicalLeft = [
        {
            title: 'Any Special/ Continuous Medical Treatment',
            subtitle: details?.medicalData?.specialTreatment || 'Nil',
        },
        {
            title: 'Description of Treatment',
            subtitle: details?.medicalData?.disabilityDescription || 'Nil',
        },
    ];
    const medicalRight = [
        {
            title: 'Place of Treatment',
            subtitle: details?.medicalData?.treatmentAddress || 'Nil',
        },
    ];
    const familyLeft = [
        {
            title: 'Type of Family',
            subtitle: details?.counselData?.counselInfo?.typeOfFamily,
        },
        {
            title: 'Position in the Family',
            subtitle: details?.counselData?.counselInfo?.positionInFamily,
        },
    ];
    const familyRight = [
        {
            title: 'Number of Children',
            subtitle: details?.counselData?.counselInfo?.numberInFamily,
        },
    ];
    const sports = [
        {
            title: 'Name of Major Sport/ Game',
            subtitle: details?.sportData?.sportInfo?.wishToParticipate,
        },
        {
            title: 'Achievement (Prize, Medals)',
            subtitle: details?.sportData?.sportInfo?.wishToParticipate,
        },

        {
            title: 'Name of Other Sport/ Game',
            subtitle: details?.sportData?.sportInfo?.wishToParticipate,
        },
        {
            title: 'Other Achievement (Prize, Medals)',
            subtitle: details?.sportData?.sportInfo?.wishToParticipate,
        },
    ];
    const lifeAmbitions = [
        {
            title: 'What is/are your life ambition?',
            subtitle: details?.counselData?.counselInfo?.yourAmbition,
        },
        {
            title: 'What other information about yourself will be useful to this unit?',
            subtitle: details?.counselData?.counselInfo?.otherInformation,
        },

        {
            title: 'What personal effort are you making to achieve your ambition?',
            subtitle: details?.counselData?.counselInfo?.personalEffort,
        },
    ];
    const socialActivities = [
        {
            title: 'Name of club',
            subtitle: details?.counselData?.counselInfo?.nameOfClub,
        },
        {
            title: 'Second social organization',
            subtitle: details?.counselData?.counselInfo?.nameOfSociety,
        },

        {
            title: 'Organization Position',
            subtitle: details?.counselData?.counselInfo?.yourPost,
        },
        {
            title: 'Second organization Position',
            subtitle: details?.counselData?.counselInfo?.yourPosition,
        },
    ];
    return (
        <div>
            <MainContainer>
                <ColContainer>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Personal Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#EFF6FF">
                                <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                    Bio-Data
                                </Text>
                            </Button>
                        </FlexRowEnd>
                        <BasicContent>
                            <RowContainer className="lg:text-left text-center">
                                {leftRow?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {rightRow?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Next Of Kin Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#EFF6FF">
                                <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                    Bio-Data
                                </Text>
                            </Button>
                        </FlexRowEnd>
                        <BasicContent>
                            <RowContainer>
                                {nextLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {nextRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Parent's Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#EFF6FF">
                                <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                    Bio-Data
                                </Text>
                            </Button>
                        </FlexRowEnd>
                        <BasicContent>
                            <RowContainer>
                                {parentLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {parentRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Family Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Counselling
                                </Text>
                            </Button>
                        </FlexRowEnd>
                        <BasicContent>
                            <RowContainer>
                                {familyLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="left">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="left">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {familyRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Sports/ Games Information
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {sports?.slice(0, Math.ceil(sports?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {sports?.slice(Math.ceil(sports?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Life Ambition Information
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {lifeAmbitions?.slice(0, Math.ceil(lifeAmbitions?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {lifeAmbitions?.slice(Math.ceil(lifeAmbitions?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                </ColContainer>
                <ColContainer>
                    <Details>
                        <FlexRowSpaceBetween>
                            <FlexCentredRow>
                                <People className="mr-[0.919rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    Other Information
                                </PageTitle>
                            </FlexCentredRow>
                            <FlexRowEnd>
                                <Button bgColor="#EFF6FF">
                                    <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                        Bio-Data
                                    </Text>
                                </Button>
                            </FlexRowEnd>
                        </FlexRowSpaceBetween>

                        <BasicContent>
                            <RowContainer className="lg:text-left text-center">
                                {otherLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {otherRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>

                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Department Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#EFF6FF">
                                <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                    Bio-Data
                                </Text>
                            </Button>
                        </FlexRowEnd>

                        <BasicContent>
                            <RowContainer>
                                {deptLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {deptRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Guardian Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#EFF6FF">
                                <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                    Bio-Data
                                </Text>
                            </Button>
                        </FlexRowEnd>

                        <BasicContent>
                            <RowContainer>
                                {guardLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {guardRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Sponsor Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#EFF6FF">
                                <Text weight="500" lineHeight="2.4rem" color="#2563EB">
                                    Bio-Data
                                </Text>
                            </Button>
                        </FlexRowEnd>

                        <BasicContent>
                            <RowContainer>
                                {sponsorLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {sponsorRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Service/ Social Activities
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Counselling
                                </Text>
                            </Button>
                        </FlexRowEnd>
                        <BasicContent>
                            <RowContainer>
                                {socialActivities?.slice(Math.ceil(socialActivities?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {socialActivities
                                    ?.slice(0, Math.ceil(socialActivities?.length / 2))
                                    .map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                            <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Medical Information
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexRowEnd>
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Counselling
                                </Text>
                            </Button>
                        </FlexRowEnd>
                        <BasicContent>
                            <RowContainer>
                                {medicalLeft?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {medicalRight?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                </ColContainer>
            </MainContainer>
        </div>
    );
};

export default Personal;
