import React from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ADMISSION_YEARS } from '../../../data/admissionYear';
import { createAdmissionSession } from '../../../redux/admissions/actions';
// import DatePicker from '../../inputs/date-picker';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const NewAdmissionYearForm = ({ show, setShow }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow(false);

    const { isLoading } = useSelector((state) => state.admission);

    return (
        <FormContainer close={onClose} show={show} title={'Session Details'}>
            <Formik
                initialValues={{
                    admissionYear: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(createAdmissionSession(values));
                    if (res) {
                        actions.resetForm();
                        toast.success('Session created successfully!');
                        setShow(false);
                    }
                }}
            >
                {({ errors, setFieldValue, handleChange, touched }) => (
                    <Form>
                        <Select
                            name="admissionYear"
                            objProp="name"
                            placeholder="Session"
                            data={ADMISSION_YEARS.map((item) => ({ name: item }))}
                            onChange={(selected) => {
                                setFieldValue('admissionYear', String(selected[0].name));
                            }}
                            error={errors.admissionYear}
                        />

                        <FlexContainer>
                            <CancelButton type="button" onClick={onClose}>
                                Cancel
                            </CancelButton>
                            <LoadingButton loading={isLoading} type="submit">
                                Create
                            </LoadingButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default NewAdmissionYearForm;
