export const worksNavLinks = [
    {
        title: 'Communications',
        to: '/works/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/works/communications/compose',
            },
            {
                title: 'Received',
                to: '/works/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/works/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/works/communications/settings',
            },
        ],
    },
    { title: 'Access', to: '/works/access' },
];

export const worksRoutes = worksNavLinks.map((item) => item.to);
