import React, { useEffect } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../../assets/icons/people2.svg';
import { GoBack } from '../../../../../../components/go-back';
import { Loader } from '../../../../../../components/loader';
import { StatusContainer, getStatusStyles } from '../../../../../../components/shared';
import { PageTitle, Text } from '../../../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow } from '../../../../../../containers/ScreenContainers';
import { getStudentDetails } from '../../../../../../redux/students/actions';
import { capitalizeFirstLetter } from '../../../../../../utils';

import Form from './tabs/Form';
// import History from './tabs/History';
// import Results from './tabs/Results';

const MainContainer = tw.div`
    grid
    gap-8
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
`;

const CourseFormMagtDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { studentDetails, isLoading } = useSelector((store) => store.student);
    const { basicData, bioData } = studentDetails || {};

    const fullName = `${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`;

    const academicStructure = basicData?.academicStructure || '';
    const splitStruct = academicStructure?.split('-');
    const firstDegreeStruct = splitStruct?.[0] || '';
    const secondDegreeStruct = splitStruct?.[1] || '';
    const firstDegree = basicData?.[firstDegreeStruct];
    const secondDegree = basicData?.[secondDegreeStruct];

    const detailsItems = [
        {
            title: 'Full Name',
            subtitle: fullName,
        },
        {
            title: 'Matric No.',
            subtitle: basicData?.matricNumber,
        },
        {
            title: capitalizeFirstLetter(secondDegreeStruct),
            subtitle: secondDegree || '',
        },
        {
            title: 'Sex',
            subtitle: bioData?.updateInfo?.gender,
        },
        {
            title: 'Year of Admission',
            subtitle: basicData?.admissionSession,
        },
        {
            title: 'Mode of Entry',
            subtitle: basicData?.admissionType,
        },
        {
            title: 'Date of Birth',
            subtitle: bioData?.updateInfo?.dateOfBirth?.slice(0, 10),
        },
        {
            title: capitalizeFirstLetter(firstDegreeStruct),
            subtitle: firstDegree || '',
        },
        {
            title: 'Session',
            subtitle: basicData?.admissionYear?.slice(0, 10),
        },
    ];

    useEffect(() => {
        dispatch(getStudentDetails(id));
    }, [dispatch, id]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <GoBack title="Course Form Management" subTitle={`${fullName}`} />

            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Student Details
                            </PageTitle>
                        </FlexCentredRow>

                        <StatusContainer
                            className="flex items-center"
                            style={getStatusStyles(basicData?.academicStatus?.toLowerCase())}
                        >
                            <Text
                                weight="500"
                                lineHeight="2.4rem"
                                color={getStatusStyles(basicData?.academicStatus?.toLowerCase())?.color}
                            >
                                {basicData?.academicStatus}
                            </Text>
                        </StatusContainer>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        {studentDetails?.uploads?.passport?.[0]?.url ? (
                            <img
                                alt="profile"
                                src={studentDetails?.uploads?.passport?.[0]?.url}
                                className="h-[18rem] w-[15rem]"
                            />
                        ) : (
                            <Avatar name={fullName} size="150" textSizeRatio={1.75} className="rounded-[8px]" />
                        )}
                        <div className=" grid grid-cols-3 lg:text-left text-center">
                            {detailsItems?.map((item) => (
                                <div key={item.title}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </div>
                            ))}
                        </div>
                    </BasicContent>
                </Details>

                <CTabs>
                    <CNav variant="tabs">
                        <CNavItem>
                            <CNavLink>Course Form</CNavLink>
                        </CNavItem>
                        {/* <CNavItem>
                            <CNavLink>History</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink>Results</CNavLink>
                        </CNavItem> */}
                    </CNav>

                    <CTabContent>
                        <CTabPane>
                            <Form />
                        </CTabPane>
                        {/* <CTabPane>
                            <History />
                        </CTabPane>
                        <CTabPane>
                            <Results />
                        </CTabPane> */}
                    </CTabContent>
                </CTabs>
            </MainContainer>
        </div>
    );
};

export default CourseFormMagtDetails;
