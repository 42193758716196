import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import warning from '../../../assets/icons/warning-big-ash.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { Text } from '../../../containers/MesssageContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.5rem 3.9rem;
        min-width: 25rem;
    }
`;

const StyledCloseIcon = tw(CloseIcon)`
    absolute
    top-[3.5rem]
    right-[3.9rem]
    cursor-pointer
`;

const DeleteError = ({ show, type = 'success', title, subtitle, withButton = true, btnText, btnAction, onClose }) => {
    return (
        <StyledModal show={show} onClose={onClose} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledCloseIcon onClick={onClose} />
            <div>
                <div className="flex flex-col justify-center items-center mt-[40.3px] mb-[31px]">
                    <img src={warning} alt="warning" />

                    <Text weight="600" top="3.1rem" bottom="1.6rem" size="4rem">
                        {title || 'Done!'}
                    </Text>
                    <Text size="1.6rem" bottom="3.2rem">
                        {subtitle}
                    </Text>

                    {withButton && (
                        <button
                            onClick={btnAction || onClose}
                            className="text-white rounded w-[9.3rem] h-[4rem] text-[1.4rem] px-4 py-2 bg-[#EF4444] leading-6 font-medium"
                        >
                            {btnText || 'Continue'}
                        </button>
                    )}
                </div>
            </div>
        </StyledModal>
    );
};

export default DeleteError;
