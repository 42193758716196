export const filterData = (data, keyword) => {
    return data?.filter((entry) => {
        return Object?.values(entry)?.some(
            (value) =>
                (typeof value === 'string' && value?.toLowerCase()?.includes(keyword?.toLowerCase())) ||
                (typeof value === 'object' &&
                    value !== null &&
                    JSON.stringify(value)?.toLowerCase()?.includes(keyword?.toLowerCase())),
        );
    });
};
