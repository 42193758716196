import React from 'react';

import tw from 'twin.macro';

import {
    BasicContent,
    DetailsCardSubText,
    DetailsCardTitleText,
    RowContainer,
} from '../../../../containers/RecordContainers';
import { FlexColumn } from '../../../../containers/ScreenContainers';
import AcademicDetailsBox from '../academic-details-box';
import DetailsBox from '../details-box';

const MainContainer = tw.div`
    grid
    grid-cols-2
    gap-[2.4rem]
    mt-8
`;

const Academics = ({ details }) => {
    const { academicData } = details || {};
    const { primaryInfo, secondaryInfo, utmeInfo, tertiaryInfo, resultInfo } = academicData || {};

    const utmeLeftInformation = [
        { title: 'Exam Number', subtitle: utmeInfo?.[0]?.utmeNumber },
        { title: utmeInfo?.[0]?.utmeResults?.[0]?.subject, subtitle: utmeInfo?.[0]?.utmeResults?.[0]?.score },
        { title: utmeInfo?.[0]?.utmeResults?.[1]?.subject, subtitle: utmeInfo?.[0]?.utmeResults?.[1]?.score },
    ];

    const utmeRightInformation = [
        { title: 'Year', subtitle: utmeInfo?.[0]?.utmeYear?.slice(0, 4) },
        { title: utmeInfo?.[0]?.utmeResults?.[2]?.subject, subtitle: utmeInfo?.[0]?.utmeResults?.[2]?.score },
        { title: utmeInfo?.[0]?.utmeResults?.[3]?.subject, subtitle: utmeInfo?.[0]?.utmeResults?.[3]?.score },
    ];

    return (
        <MainContainer>
            <div className="flex flex-col gap-[2.4rem]">
                <DetailsBox heading="Primary School Information">
                    <AcademicDetailsBox
                        academicSubdata={primaryInfo}
                        keyProp="title"
                        subdataTitle1="Primary School Attended"
                        subdataTitle2="Qualification Obtained"
                        subdataTitle3="Duration"
                        prop1="primarySchool"
                        prop2="qualificationObtain"
                        prop3="from"
                        prop4="to"
                    />
                </DetailsBox>
                <DetailsBox heading="Result Selection with Dates">
                    {resultInfo?.map((item) => (
                        <BasicContent
                            key={item?._id}
                            className={resultInfo?.length > 1 ? 'border-b border-[#9CA3AF] py-[1.5rem]' : ''}
                        >
                            <RowContainer className="lg:text-left text-center">
                                <FlexColumn className="">
                                    <DetailsCardTitleText>Number of Sittings</DetailsCardTitleText>
                                    <DetailsCardSubText>{resultInfo?.length}</DetailsCardSubText>
                                </FlexColumn>
                                <FlexColumn>
                                    <DetailsCardTitleText>Exam Number</DetailsCardTitleText>
                                    <DetailsCardSubText>{item?.examNumber}</DetailsCardSubText>
                                </FlexColumn>
                                {item.results?.slice(0, 4).map((result) => (
                                    <FlexColumn key={result?._id}>
                                        <DetailsCardTitleText>{result?.subject}</DetailsCardTitleText>
                                        <DetailsCardSubText>{result?.grade}</DetailsCardSubText>
                                    </FlexColumn>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                <FlexColumn className="">
                                    <DetailsCardTitleText>Exam Body</DetailsCardTitleText>
                                    <DetailsCardSubText>{item?.examType}</DetailsCardSubText>
                                </FlexColumn>
                                <FlexColumn>
                                    <DetailsCardTitleText>Exam Year</DetailsCardTitleText>
                                    <DetailsCardSubText>{item?.examYear?.slice(0, 4)}</DetailsCardSubText>
                                </FlexColumn>
                                {item.results?.slice(4).map((result) => (
                                    <FlexColumn key={result?._id}>
                                        <DetailsCardTitleText>{result?.subject}</DetailsCardTitleText>
                                        <DetailsCardSubText>{result?.grade}</DetailsCardSubText>
                                    </FlexColumn>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    ))}
                </DetailsBox>
            </div>
            <div className="flex flex-col gap-[2.4rem]">
                <DetailsBox heading="Secondary School Information">
                    <AcademicDetailsBox
                        academicSubdata={secondaryInfo}
                        keyProp="title"
                        subdataTitle1="Secondary School Attended"
                        subdataTitle2="Qualification Obtained"
                        subdataTitle3="Duration"
                        prop1="secondarySchool"
                        prop2="qualificationObtain"
                        prop3="from"
                        prop4="to"
                    />
                </DetailsBox>
                <DetailsBox
                    heading="UTME Information"
                    leftData={utmeLeftInformation}
                    rightData={utmeRightInformation}
                />
                <DetailsBox heading="Tertiary School Information">
                    <AcademicDetailsBox
                        academicSubdata={tertiaryInfo}
                        keyProp="title"
                        subdataTitle1="Tertiary School Attended"
                        subdataTitle2="Qualification Obtained"
                        subdataTitle3="Duration"
                        prop1="tertiarySchool"
                        prop2="qualificationObtain"
                        prop3="from"
                        prop4="to"
                    />
                </DetailsBox>
            </div>
        </MainContainer>
    );
};

export default Academics;
