import React, { useEffect, useState } from 'react';

import { Country, State } from 'country-state-city';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { registerUser } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import PhoneNumberInput from '../../inputs/phone-input';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const OnboardingForm = ({ onSubmitSuccess }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.isLoading);
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [states, setStates] = useState([]);

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    useEffect(() => {
        setStates(State.getStatesOfCountry(countryCode));
    }, [countryCode]);




    return (
        <>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    otherName: '',
                    organizationEmail: '',
                    phoneNumber: '',
                    institutionName: '',
                    institutionType: '',
                    institutionCountry: '',
                    institutionState: '',
                    institutionAddress: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const payload = {
                        ...values,
                        organizationEmail: values.organizationEmail.toLowerCase(),
                        phoneNumber: String(values.phoneNumber),
                    };

                    const res = await dispatch(registerUser(payload));
                    if (res) {
                        actions.resetForm();
                        onSubmitSuccess();
                    }
                }}
            >
                {({ values, errors, setFieldValue, handleChange }) => (
                    <Form>
                        <div className="w-[32rem] mt-[32px] md:w-[50rem] max-h-[80vh] overflow-y-auto lg:w-[60.8rem] bg-white p-[3rem] md:p-[4.8rem] rounded-lg shadow-lg">
                            <h3 className="font-semibold text-[#1F2937] text-[23px] mb-2">Request Access</h3>
                            <p className="text-[#9CA3AF] mb-8 text-[14px]">
                                Please take some time to introduce yourself and your organisation
                            </p>

                            <div className="grid gap-6">
                                <div className="grid grid-cols-2 gap-6">
                                    <TextInput
                                        label="Surname"
                                        name="lastName"
                                        type="text"
                                        placeholder="Input Surname"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.lastName}
                                    />
                                    <TextInput
                                        label="First Name"
                                        name="firstName"
                                        type="text"
                                        placeholder="Input First Name"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.firstName}
                                    />

                                    <TextInput
                                        label="Other Name"
                                        name="otherName"
                                        type="text"
                                        placeholder="Input Other Name"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.otherName}
                                    />
                                    <TextInput
                                        label="Email Address"
                                        name="organizationEmail"
                                        type="email"
                                        placeholder="Input Email address"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.organizationEmail}
                                    />
                                </div>

                                <PhoneNumberInput
                                    label="Phone Number"
                                    countryCode={true}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    type="number"
                                    placeholder="Input Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                />

                                <TextInput
                                    label="Institution Name"
                                    name="institutionName"
                                    type="text"
                                    placeholder="Input Institution Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.institutionName}
                                />
                                <Select
                                    label="Institution Type"
                                    name="institutionType"
                                    objProp="name"
                                    placeholder="Select Institution Type"
                                    data={['Private University', 'State University', 'Federal University'].map(
                                        (item) => ({
                                            name: item,
                                        }),
                                    )}
                                    onChange={(selected) => {
                                        setFieldValue('institutionType', String(selected[0].name));
                                    }}
                                    error={errors.institutionType}
                                />
                                <Select
                                    label="Institution Country"
                                    name="institutionCountry"
                                    objProp="name"
                                    placeholder="Select Institution Country"
                                    data={countries?.map((item) => ({ name: item.name, isoCode: item.isoCode }))}
                                    onChange={(selected) => {
                                        setFieldValue('institutionCountry', String(selected[0].name));
                                        setCountryCode(selected[0]?.isoCode);
                                    }}
                                    error={errors.institutionCountry}
                                />
                                <Select
                                    label="Institution State"
                                    name="institutionState"
                                    objProp="name"
                                    data={states?.map((item) => ({ name: item.name }))}
                                    placeholder="Select Institution State"
                                    onChange={(selected) => {
                                        setFieldValue('institutionState', selected[0].name);
                                    }}
                                    error={errors.institutionState}
                                />
                                <TextInput
                                    label="Institution Address"
                                    name="institutionAddress"
                                    type="text"
                                    placeholder="Input Institution Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.institutionAddress}
                                />
                            </div>
                            <FlexRowEnd className="mt-6">
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Submit
                                </LoadingButton>
                            </FlexRowEnd>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OnboardingForm;