import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Checkbox from '../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../components/list-empty';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getDepartmentProgrammes } from '../../../../redux/programmes/actions';
import { SAVE_CURRENT_PROGRAMME } from '../../../../redux/programmes/slice';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Programmes',
        accessorKey: 'subProgram',
    },
    {
        accessorKey: 'none',
        cell: (props) => <p>{props.getValue()}</p>,
        header: '',
    },
];

const ResultProgrammesList = () => {
    const dispatch = useDispatch();
    const { isLoading, departmentProgrammes } = useSelector((state) => state.programmes);
    const { currentDepartment } = useSelector((state) => state.department);

    const { departmentId } = currentDepartment || {};
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (departmentId) {
            dispatch(getDepartmentProgrammes(departmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, departmentId]);

    const modifiedProgResult = departmentProgrammes?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            subProgram: (
                <td style={{ color: '#2563EB' }}>
                    <Link to="levels">{item.subprogram}</Link>
                </td>
            ),
            none: <td></td>,
        };
    });

    return (
        <>
            <PageLayout
                pageTitle={currentDepartment?.departmentName}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedProgResult || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Programme(s) Yet " subtitle="Add new Programme(s)" />
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => {
                    dispatch(SAVE_CURRENT_PROGRAMME(item));
                }}
                isActionable
            />
        </>
    );
};
export default ResultProgrammesList;
