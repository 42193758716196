import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

export const getBroadsheet =
    ({ session, level, curriculumId, semester }) =>
    async (dispatch, getState) => {
        const { department, programmes } = getState();
        const {
            currentDepartment: { departmentId },
        } = department || {};
        const {
            currentProgramme: { _id: programId },
        } = programmes || {};

        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}result/${currentUnit?._id}/students_broadsheet/${departmentId}/${programId}/${session}/${level}/${curriculumId}/${semester}`,
            );
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getDepartmentBroadsheet =
    ({ session, level, curriculumId, semester }) =>
    async (dispatch, getState) => {
        const { department, programmes } = getState();
        const {
            currentDepartment: { departmentId },
        } = department || {};
        const {
            currentProgramme: { _id: programId },
        } = programmes || {};

        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}result/${currentUnit?._id}/department_students_sheet/${departmentId}/${programId}/${session}/${level}/${curriculumId}/${semester}`,
            );
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getStudentLevels =
    ({ academicStructureId, lastDegreeId, session, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}result/${currentUnit?._id}/levels/${academicStructureId}/${lastDegreeId}${session}/${semester}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const lockEntriesInProgram =
    ({ programId, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}result/${currentUnit?._id}/lock_entry_result/${programId}/${session}/${semester}`,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const unlockEntriesInProgram =
    ({ programId, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}result/${currentUnit?._id}/unlock_entry_result/${programId}/${session}/${semester}`,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const sendResultToDepartment =
    ({ programId, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}result/${currentUnit?._id}/send_result_to_department/${programId}/${session}/${semester}`,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const lockEntriesInDepartment =
    ({ departmentId, programId, level, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}result/${currentUnit?._id}/lock_entry_result/${departmentId}/${programId}/${session}/${level}/${semester}`,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const markResultAsIncomplete =
    ({ studentId, programId, level, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}result/${currentUnit?._id}/mark_as_incomplete/${programId}/${studentId}/${session}/${level}/${semester}`,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getProgramIncompleteResults =
    ({ programId, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}result/${currentUnit?._id}/incomplete_list/${programId}/${session}/${semester}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};

                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getProgramSummarySheet =
    ({ programId, semester, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}result/${currentUnit?._id}/result_summary/${programId}/${session}/${semester}`,
            );

            if (response.status === 200) {
                const { data } = response || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getProgramProbationList =
    ({ departmentId, programId, level, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                // `${currentAPI}result/${currentUnit?._id}/probation_list/${departmentId}/${programId}/${session}/${level}`,
                `${currentAPI}result/${currentUnit?._id}/probation_list/${departmentId}/${programId}/${session}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getProgramWithdrawalList =
    ({ departmentId, programId, level, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                // `${currentAPI}result/${currentUnit?._id}/withdrawal_list/${departmentId}/${programId}/${session}/${level}`,
                `${currentAPI}result/${currentUnit?._id}/withdrawal_list/${departmentId}/${programId}/${session}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getResultsBacklog = (params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const { lastDegreeId, level, semester, session, academicStructureId, curriculumId } = params;

        const response = await axiosInstance.get(
            `${currentAPI}result/${currentUnit?._id}/result_backlog/${academicStructureId}/${lastDegreeId}/${session}/${level}/${curriculumId}/${semester}`,
        );

        if (response.status === 200) {
            const { data } = response || {};
            return data;
        }
    } catch (error) {
        const errorMessage =
            error?.response?.data?.message || error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentsInResultsBacklog = (params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const { lastDegreeId, level, semester, session, academicStructureId, curriculumId } = params;

        const response = await axiosInstance.get(
            `${currentAPI}result/${currentUnit?._id}/students_in_curriculum/${academicStructureId}/${lastDegreeId}/${session}/${level}/${curriculumId}/${semester}`,
        );

        if (response.status === 200) {
            const { data } = response || {};
            return data;
        }
    } catch (error) {
        const errorMessage =
            error?.response?.data?.message || error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const recordPreviousResult = (params, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const {
            studentId,
            level,
            semester,
            session,
            academicStructureId,
            firstDegreeId,
            secondDegreeId,
            thirdDegreeId,
        } = params;

        const response = await axiosInstance.post(
            `${currentAPI}result/${currentUnit?._id}/record_previous_result/${academicStructureId}/${firstDegreeId}/${secondDegreeId}/${session}/${level}/${semester}/${studentId}?thirdDegree=${thirdDegreeId}`,
            payload,
        );

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};


export const promoteStudents = (academicStructureId, lastDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}result/${currentUnit?._id}/promote_students/${academicStructureId}/${lastDegreeId}`,
            payload,
        );

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};