import React, { useState, useRef, useEffect, Fragment, useMemo } from 'react';

import { Listbox, Transition } from '@headlessui/react';

import { ReactComponent as SelectIcon } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-sm.svg';
import { ReactComponent as CirclePlus } from '../../../assets/icons/circlePlus.svg';
import { Text } from '../../../containers/MesssageContainers';
import useSearch from '../../../hooks/useSearch';
import { Button } from '../../buttons';
import Checkbox from '../checkbox';
import SearchInput from '../search-input';

const MultiSelect = ({
    placeholder = '',
    name = 'select',
    data,
    objProp = '',
    multiSelect,
    passedSelectedItems,
    onChange,
    useComponentState = true,
    label,
    isLoading = false,
    containerClass = '',
    borderRadius = 'rounded',
    noBorder,
    disabled,
    selectedSecondLevel,
    selectedThirdLevel,
    onButtonClick,
    setSelectedAcademicLevels,
    selectedAcademicLevels,
    secondLevelList,
    thirdLevelList,
    academicLevels,
    structure,
    secondLevelLoading,
    onSecondLevelChange,
    thirdLevelLoading,
    onThirdLevelChange,
    singleSelect,
    showLevels = true,
    showValues = true,
    formType,
    selectedLevels,
}) => {
    const ref = useRef(null);
    const secondLevelRef = useRef(null);
    const thirdLevelRef = useRef(null);
    const academicLevelsRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const results = useSearch(searchValue, data, [objProp]);
    const [showSecondSelect, setShowSecondSelect] = useState(false);
    const [showThirdSelect, setShowThirdSelect] = useState(false);
    const [secondLevelSearch, setSecondLevelSearch] = useState('');
    const [thirdLevelSearch, setThirdLevelSearch] = useState('');
    const [showAcademicLevels, setShowAcademicLevels] = useState(false);
    const initialValue = useMemo(() => {
        const value = passedSelectedItems
            ? Array.isArray(passedSelectedItems)
                ? [...passedSelectedItems]
                : [passedSelectedItems]
            : [];

        return value;
    }, [passedSelectedItems]);

    const [selectedItems, setSelectedItems] = useState(initialValue);
    const itemsToUse = useComponentState ? selectedItems : passedSelectedItems;

    const handleSelect = (clickedItem) => {
        if (multiSelect) {
            let selectedItemsUpdated = [];
            if (getItem(clickedItem) === 'Select All') {
                if (isSelected('Select All')) {
                    selectedItemsUpdated = [];
                } else {
                    selectedItemsUpdated = [...data];
                }
            } else {
                if (!isSelected(clickedItem)) {
                    selectedItemsUpdated = [...itemsToUse, clickedItem];
                } else {
                    selectedItemsUpdated = itemsToUse.filter((item) => getItem(item) !== getItem(clickedItem));
                }
            }
            useComponentState && setSelectedItems(selectedItemsUpdated);
            onChange?.(selectedItemsUpdated, getDisplayValue(selectedItemsUpdated));
            setIsOpen(true);
        } else {
            useComponentState && setSelectedItems([clickedItem]);
            onChange?.([clickedItem], getDisplayValue(itemsToUse));
            setIsOpen(true);
            setShowSecondSelect(true);
        }
    };

    const getItem = (option) => {
        if (typeof option === 'object' && option !== null) {
            return option[objProp];
        }
        return option;
    };
    const getDisplayLabel = (option) => getItem(option);

    const getDisplayValue = (options) => options?.map((option) => getItem(option)).join(', ');

    const isSelected = (option) => !!itemsToUse?.find((selectedItem) => getItem(selectedItem) === getItem(option));

    const secondLevelData = useSearch(secondLevelSearch, secondLevelList, ['name']);
    const thirdLevelData = useSearch(thirdLevelSearch, thirdLevelList, ['name']);

    const onAcademicLevelsSelect = (item) => {
        if (item?.id !== 'Select All') {
            const index = selectedAcademicLevels?.findIndex((level) => level.id === item.id);
            if (index !== -1) {
                const updatedLevels = [
                    ...selectedAcademicLevels.slice(0, index),
                    ...selectedAcademicLevels.slice(index + 1),
                ];
                setSelectedAcademicLevels(updatedLevels);
            } else {
                const updatedLevels = [...selectedAcademicLevels, item];
                setSelectedAcademicLevels(updatedLevels);
            }
        } else {
            setSelectedAcademicLevels(academicLevels);
        }
    };

    const onSelectSecondLevel = (item, structure) => {
        if (structure === 3) {
            setShowThirdSelect(true);
        } else {
            setShowAcademicLevels(true);
        }
        onSecondLevelChange?.(item);
    };

    const onSelectThirdLevel = (item) => {
        setShowAcademicLevels(true);
        onThirdLevelChange?.(item);
    };

    const handleAddSelectionClick = () => {
        onButtonClick();
        setShowSecondSelect(false);
        setShowThirdSelect(false);
        setShowAcademicLevels(false);
    };

    const eventListener = (event) => {
        const target = event.target;

        const selectBox = ref.current;
        const secondLevelBox = secondLevelRef.current;
        const thirdLevelBox = thirdLevelRef.current;
        const academicLevelsBox = academicLevelsRef.current;

        if (
            selectBox &&
            selectBox !== target &&
            !selectBox.contains(target) &&
            (!secondLevelBox || (secondLevelBox !== target && !secondLevelBox.contains(target))) &&
            (!thirdLevelBox || (thirdLevelBox !== target && !thirdLevelBox.contains(target))) &&
            (!academicLevelsBox || (academicLevelsBox !== target && !academicLevelsBox.contains(target)))
        ) {
            setIsOpen(false);
            setShowSecondSelect(false);
            setShowThirdSelect(false);
            setShowAcademicLevels(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', eventListener);
        return () => {
            document.removeEventListener('click', eventListener);
        };
    }, []);

    return (
        <div className="relative">
            <div className={containerClass}>
                {label && <label className="font-medium text-[1.4rem] text-[#1F2937]">{label}</label>}
                <Listbox
                    value={itemsToUse}
                    onChange={(clickedItem) => {
                        const option = clickedItem;
                        handleSelect(option);
                    }}
                    as="div"
                >
                    {() => (
                        <div className="relative mt-1" ref={ref}>
                            <Listbox.Button
                                onClick={!disabled ? () => setIsOpen(!isOpen) : null}
                                className={`relative w-full cursor-default ${
                                    disabled && 'bg-[#D1D5DB]'
                                } ${borderRadius} h-[4rem] ${
                                    noBorder ? '' : 'border'
                                } px-3 text-left focus:outline-none text-[1.4rem] font-normal`}
                            >
                                <div className="flex items-center">
                                    {(!itemsToUse?.length > 0 || !showValues) && (
                                        <div className="text-[1.4rem] font-normal text-[#6B7280]">{placeholder}</div>
                                    )}

                                    <div className="flex items-center flex-wrap">
                                        <span className="truncate">
                                            {showValues ? getDisplayValue(itemsToUse) : ''}
                                        </span>
                                    </div>
                                </div>

                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <SelectIcon />
                                </div>
                            </Listbox.Button>

                            <Transition
                                show={isOpen}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute overflow-y-auto z-10 h-max-[18.4rem] bg-white min-w-[23.7rem] rounded-[4px] border border-[#D1D5DB] shadow-select">
                                    <SearchInput
                                        containerWidth="100%"
                                        width="100%"
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.target.value);
                                        }}
                                    />
                                    {!results?.length || isLoading ? (
                                        <p className="p-2">{isLoading ? 'Loading...' : 'No Options'}</p>
                                    ) : (
                                        results?.map((option) => {
                                            const selected = isSelected(option);

                                            return (
                                                <Listbox.Options
                                                    static
                                                    key={`${getDisplayLabel(option)}`}
                                                    onClick={() => handleSelect(option)}
                                                    className={`${
                                                        selected ? 'bg-[#F3F4F6]' : ''
                                                    } cursor-pointer overflow-y-auto flex items-center pt-[12px] pr-[10px] pb-[8px] pl-[12px] border-b border-b-[#D1D5DB]`}
                                                >
                                                    <>
                                                        {multiSelect ? (
                                                            <Checkbox
                                                                checked={selected}
                                                                className="mr-0"
                                                                aria-hidden="true"
                                                            />
                                                        ) : null}
                                                        <div className="flex items-center justify-between w-full">
                                                            <Text align="left" size="1.6rem" weight="400">
                                                                {getDisplayLabel(option)}
                                                            </Text>
                                                            {!multiSelect && <ArrowIcon />}
                                                        </div>
                                                    </>
                                                </Listbox.Options>
                                            );
                                        })
                                    )}
                                    {!structure && (
                                        <Button
                                            onClick={handleAddSelectionClick}
                                            bgColor="#6366F1"
                                            color="#fff"
                                            className="flex gap-[8px] items-center w-[100%]"
                                        >
                                            <CirclePlus fill="#fff" /> Add Selection
                                        </Button>
                                    )}
                                </div>
                            </Transition>
                        </div>
                    )}
                </Listbox>
            </div>
            {showSecondSelect && secondLevelList && (
                <div
                    ref={secondLevelRef}
                    className="absolute left-[25rem] top-[4rem] h-max-[18.4rem] bg-white w-[18.7rem] rounded-[4px] border border-[#D1D5DB] shadow-select"
                >
                    <SearchInput
                        value={secondLevelSearch}
                        onChange={(e) => {
                            setSecondLevelSearch(e.target.value);
                        }}
                        width={'18.7rem'}
                    />
                    {!secondLevelData?.length || secondLevelLoading ? (
                        <p className="p-2">{secondLevelLoading ? 'Loading...' : 'No Options'}</p>
                    ) : (
                        secondLevelData?.map((item) => (
                            <div
                                onClick={() => onSelectSecondLevel(item, structure)}
                                key={item?.id}
                                className={`${
                                    selectedSecondLevel?.includes(item?.name) ? 'bg-[#F3F4F6]' : ''
                                } cursor-pointer flex items-center pt-[12px] justify-between pr-[10px] pb-[8px] pl-[12px] border-b border-b-[#D1D5DB]`}
                            >
                                <div className="flex items-center gap-[10px]">
                                    {structure === 2 && formType !== 'student' && (
                                        <Checkbox
                                            checked={selectedSecondLevel?.find((secDeg) => secDeg?.id === item?.id)}
                                            className="mr-0"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <Text align="left" size="1.6rem" weight="400">
                                        {item?.name}
                                    </Text>
                                </div>
                                {structure === 2 && formType === 'student' && <ArrowIcon />}
                            </div>
                        ))
                    )}
                    {structure === 2 && formType !== 'student' && (
                        <Button
                            onClick={handleAddSelectionClick}
                            bgColor="#6366F1"
                            color="#fff"
                            className="flex gap-[8px] items-center w-[100%]"
                        >
                            <CirclePlus fill="#fff" /> Add Selection
                        </Button>
                    )}
                </div>
            )}
            {showThirdSelect && thirdLevelData?.length > 0 && (
                <div
                    ref={thirdLevelRef}
                    className={`absolute left-[44rem] top-[6rem] h-max-[18.4rem] bg-white w-[18.7rem] rounded-[4px] border border-[#D1D5DB] shadow-select`}
                >
                    <SearchInput
                        value={thirdLevelSearch}
                        onChange={(e) => {
                            setThirdLevelSearch(e.target.value);
                        }}
                        width={'18.7rem'}
                    />
                    {!thirdLevelData?.length || thirdLevelLoading ? (
                        <p className="p-2">{thirdLevelLoading ? 'Loading...' : 'No Options'}</p>
                    ) : (
                        thirdLevelData?.map((item) => (
                            <div
                                onClick={() => onSelectThirdLevel(item)}
                                key={item?.id}
                                className={`${
                                    selectedThirdLevel?.includes(item?.name) ? 'bg-[#F3F4F6]' : ''
                                } cursor-pointer flex items-center pt-[12px] justify-between pr-[10px] pb-[8px] pl-[12px] border-b border-b-[#D1D5DB]`}
                            >
                                <div className="flex items-center">
                                    {structure === 3 && formType !== 'student' && (
                                        <Checkbox
                                            checked={selectedThirdLevel?.find((thdDeg) => thdDeg?.id === item?.id)}
                                            className="mr-0"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <Text align="left" size="1.6rem" weight="400">
                                        {item?.name}
                                    </Text>
                                </div>
                                {structure === 3 && formType === 'student' && <ArrowIcon />}
                            </div>
                        ))
                    )}
                    {structure === 3 && formType !== 'student' && (
                        <Button
                            onClick={handleAddSelectionClick}
                            bgColor="#6366F1"
                            color="#fff"
                            className="flex gap-[8px] items-center w-[100%]"
                        >
                            <CirclePlus fill="#fff" /> Add Selection
                        </Button>
                    )}
                </div>
            )}
            {showLevels && showAcademicLevels && (
                <div
                    ref={academicLevelsRef}
                    className={`absolute ${
                        showThirdSelect && thirdLevelData?.length > 0 ? 'left-[63rem]' : 'left-[44rem]'
                    } top-[8rem] h-max-[18.4rem] bg-white w-[15.7rem] rounded-[4px] border border-[#D1D5DB] shadow-select`}
                >
                    {academicLevels?.map((item) => (
                        <div
                            onClick={() => onAcademicLevelsSelect(item)}
                            key={item?.id}
                            className={`${
                                selectedAcademicLevels?.name === item?.name ? 'bg-[#F3F4F6]' : ''
                            } cursor-pointer flex items-center pt-[12px] pr-[10px] pb-[8px] pl-[12px] border-b border-b-[#D1D5DB]`}
                        >
                            <Checkbox
                                checked={selectedAcademicLevels?.find((level) => level?.id === item?.id)}
                                onChange={() => onAcademicLevelsSelect(item)}
                                className="mr-0"
                                aria-hidden="true"
                            />
                            <Text align="left" size="1.6rem" weight="400">
                                {item?.name}
                            </Text>
                        </div>
                    ))}
                    <Button
                        onClick={handleAddSelectionClick}
                        bgColor="#6366F1"
                        color="#fff"
                        className="flex gap-[8px] items-center w-[100%]"
                    >
                        <CirclePlus fill="#fff" /> Add Selection
                    </Button>
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
