import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as CheckMark } from '../../../assets/icons/CheckCircle.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { getBillingPlans, getPaymentSuccess } from '../../../redux/ict/actions';

const Features = ['Administrative Portal', "Lecturer's Portal", 'Student Portal', 'All Features Inclusive', 'Security'];

const Billing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { billingPlans, payment } = useSelector((store) => store.ict);
    const currentPlan = payment?.[0];

    const currentPaymentPlan = billingPlans?.find((item) => item.id === currentPlan?.payment_plan);

    const options = { month: 'long', day: '2-digit', year: 'numeric' };

    useEffect(() => {
        dispatch(getBillingPlans());
        dispatch(getPaymentSuccess());
        //eslint-disable-next-line
    }, []);
    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[8px] pb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" weight="400">
                    /
                </Text>
                <Text size="1.6rem" weight="400">
                    Billing
                </Text>
            </FlexCentredRow>

            <div className="bg-white p-[2.4rem]">
                <div className="flex flex-col gap-[8px]">
                    <Text size="2.3rem" align="left" weight="600">
                        Billing
                    </Text>
                    <Text size="1.4rem" align="left" weight="500" color="#6B7280">
                        Manage your Plan and billing history
                    </Text>
                </div>
                <div className="pt-[6.3rem] flex items-center gap-[2.4rem] w-full">
                    <div className="py-[2.4rem] h-[182px] flex-1 pl-[4.2rem] border border-[1px] rounded-[8px] border-[#D1D5DB] bg-[#F9FAFB]">
                        <Text align="left" size="1.4rem" weight="500" color="#6B7280">
                            Current yearly bill
                        </Text>
                        <Text size="2.3rem" align="left" weight="600" top="0.84rem">
                            {currentPlan?.amount ? `NGN ${currentPlan?.amount}` : 'Nil'}
                        </Text>
                        <Text
                            align="left"
                            onClick={() => navigate('add-seat')}
                            size="1.2rem"
                            weight="500"
                            color="#3B82F6"
                            top="0.498rem"
                            className="cursor-pointer"
                        >
                            Add seats
                        </Text>
                        <Text align="left" size="1.2rem" weight="500" color="#3B82F6" top="0.4rem">
                            Switch to Monthly billing
                        </Text>
                    </div>
                    <div className="pt-[2.4rem] h-[182px] pb-[7.5rem] flex-1 pl-[4.2rem] border border-[1px] rounded-[8px] border-[#D1D5DB] bg-[#F9FAFB]">
                        <Text align="left" size="1.4rem" weight="500" color="#6B7280">
                            Next payment due
                        </Text>
                        <Text size="2.3rem" align="left" weight="600" top="0.84rem">
                            {currentPlan?.nextPaymentDate
                                ? `${new Date(currentPlan?.nextPaymentDate).toLocaleDateString('en-US', options)}`
                                : 'Nil'}
                        </Text>
                        <Text
                            onClick={() => navigate('invoice-history')}
                            className="cursor-pointer"
                            align="left"
                            size="1.2rem"
                            weight="500"
                            color="#3B82F6"
                            top="2.4rem"
                        >
                            View invoice history
                        </Text>
                    </div>
                    <div className="pt-[2.4rem] h-[182px] pb-[9rem] flex-1 pl-[4.2rem] border border-[1px] rounded-[8px] border-[#D1D5DB] bg-[#F9FAFB]">
                        <Text align="left" size="1.4rem" weight="500" color="#6B7280">
                            Payment information
                        </Text>
                        <Text
                            onClick={() => navigate('payment-method')}
                            className="cursor-pointer"
                            align="left"
                            size="1.2rem"
                            weight="500"
                            color="#3B82F6"
                            top="1.1rem"
                        >
                            Manage payment method
                        </Text>
                        <Text
                            onClick={() => navigate('payment-history')}
                            className="cursor-pointer"
                            align="left"
                            size="1.2rem"
                            weight="500"
                            color="#3B82F6"
                            top="4px"
                        >
                            View payment history
                        </Text>
                    </div>
                </div>
                <div className="pt-[5rem] pb-[2rem] border-b-[1px] border-b-[#D1D5DB]">
                    <Text align="left" left="4.2rem" size="3.3rem" weight="500" color="#374151">
                        Current Plan
                    </Text>
                </div>
                <div className="mt-[4rem] border-[1px] mb-[5.9rem] border-[#D1D5DB] rounded-t-[8px]">
                    <div className="py-[2.7rem] pl-[4rem] border-b-[1px] border-b-[#D1D5DB] bg-[#F9FAFB]">
                        <Text align="left" size="1.9rem" weight="500" color="#000" transform="capitalize">
                            {currentPaymentPlan?.interval ? `${currentPaymentPlan?.interval}` : 'Nil'}
                        </Text>
                        <Text align="left" size="1.4" color="#6B7280">
                            The university management system all in one place.
                        </Text>
                    </div>
                    <div className="py-[3.6rem] pl-[4rem] flex flex-col gap-[1.6rem]">
                        {Features?.map((item, i) => (
                            <div key={i} className="flex items-center gap-[1.6rem]">
                                <CheckMark fill="#34D399" />
                                <Text align="400" size="1.4rem">
                                    {item}
                                </Text>
                            </div>
                        ))}
                    </div>
                    <div className="border-y-[1px] w-[100%] border-[#D1D5DB] pr-[1.1rem] py-[1.5rem] flex items-center gap-[3.2rem] pl-[4.2rem]">
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <Text align="left" weight="600" size="1.6rem">
                                    Seats
                                </Text>
                                <div className="flex">
                                    <Text align="left" weight="600" size="1.6rem">
                                        {billingPlans?.[0]?.seatUsed || 'Nil'}{' '}
                                        <span className="text-[1.6rem] font-normal">of</span>{' '}
                                        {currentPlan?.seatNumber || 'Nil'}{' '}
                                        <span className="text-[1.6rem] font-normal">used</span>
                                    </Text>
                                </div>
                            </div>
                            <div className="h-[8px] w-full bg-[#E5E7EB] rounded-[8px]">
                                <div className="bg-[#6366F1] h-[8px] rounded-[8px]" style={{ width: `${0}%` }}></div>
                            </div>
                            <Text align="left" color="#6B7280">
                                {billingPlans?.[0]?.members || 'Nil'}
                            </Text>
                        </div>
                        <Text size="2.8rem" weight="600">
                            {currentPlan?.amount ? `NGN ${currentPlan?.amount}` : 'Nil'}
                        </Text>
                    </div>
                    <div
                        onClick={() => navigate('remove-seat')}
                        className="border-t-[1px] cursor-pointer border-[#D1D5DB] py-[1.8rem] pl-[4rem]"
                    >
                        <Text align="left" size="1.6rem" weight="500" color="#3B82F6">
                            Remove unused seats
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;
