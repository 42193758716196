import tw, { styled } from 'twin.macro';

export const CentredBox = styled.div(() => [
    `
    flex-direction: ${({ direction }) => direction || 'row'};
    `,
    tw`
    flex
    items-center
    `,
]);

export const CenteredContainer = tw.div`
    flex
    justify-center
    items-center
`;

export const FlexCentredRow = tw.div`
  flex
  flex-row
  items-center
`;

export const FlexCentredCol = tw.div`
  flex
  flex-col
  justify-center
`;

export const FlexRowSpaceBetween = tw(FlexCentredRow)`
  justify-between
`;

export const FlexRowCentered = tw(FlexCentredRow)`
  justify-center
`;

export const ItemsPerPageCont = styled.div(() => [
    `
  `,
    tw`
  flex
  items-center
  flex-row
  justify-between
  px-[2.4rem]
  mt-[2rem]
  `,
]);

export const FlexDiv = tw.div`
    flex
    flex-row
`;

export const FlexColumn = tw.div`
    flex
    flex-col
`;

export const FlexRowEnd = tw.div`
    flex
    items-center
    justify-end
`;
export const FlexColStart = tw.div`
    flex-col
    justify-start
`;

export const RelativeContainer = tw.div`
  relative
`;

export const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;
