import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { editNew, getAllNew } from '../../../redux/appointments/actions';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditNewCategory = ({ id, show, setShow, btnAction, setDetails }) => {
    const onClose = () => setShow(false);

    const { allStaff } = useSelector((store) => store.staff);
    const [appointeeDetails, setAppointeeDetails] = useState({});
    const { appointmentDetails } = useSelector((store) => store.appointment);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            const appointee = appointmentDetails.find((item) => item._id === id);
            setAppointeeDetails(appointee);
        }
    }, [id, appointmentDetails]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 5000);
        return () => clearTimeout(timeout);
    }, []);

    if (loading) return;

    return (
        <>
            <FormContainer close={onClose} show={show} title={'Edit Appointment'}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        appointmentType: appointeeDetails?.appointmentType || '',
                        post: appointeeDetails?.post || '',
                        startDate: appointeeDetails?.startDate?.slice(0, 10) || '',
                        endDate: appointeeDetails?.endDate?.slice(0, 10) || '',
                        staffNo: appointeeDetails?.staffNo || '',
                        name: appointeeDetails?.name || '',
                    }}
                    onSubmit={async (values, action) => {
                        setDetails(values);
                        if (values.startDate) values.startDate = new Date(values.startDate).toISOString().slice(0, 10);
                        if (values.endDate) values.endDate = new Date(values.endDate).toISOString().slice(0, 10);
                        delete values.staffNo;
                        const res = await dispatch(editNew(id, values));
                        if (res) {
                            onClose();
                            dispatch(getAllNew(values?.appointmentType));
                        }
                    }}
                >
                    {({ errors, handleChange, touched, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <Select
                                    name="name"
                                    objProp="fullName"
                                    data={allStaff}
                                    placeholder="Select Name"
                                    label="Select Name"
                                    onChange={(selected) => {
                                        setFieldValue('name', selected[0].fullName);
                                    }}
                                    error={errors.name}
                                    passedSelectedItems={values?.name}
                                />
                                <TextInput
                                    name="post"
                                    label="Post"
                                    placeholder="Post"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.post}
                                />

                                <DatePicker
                                    label="Start Date (Optional)"
                                    initialValue={
                                        appointeeDetails?.startDate ? new Date(appointeeDetails?.startDate) : null
                                    }
                                    name="startDate"
                                    touched={touched}
                                />

                                <DatePicker
                                    label="End Date (Optional)"
                                    initialValue={
                                        appointeeDetails?.endDate ? new Date(appointeeDetails?.endDate) : null
                                    }
                                    name="endDate"
                                    touched={touched}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <SubmitButton type="submit" onClick={btnAction}>
                                    Appoint Staff
                                </SubmitButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditNewCategory;
