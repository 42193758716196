import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    academicStaff: [],
    nonAcademicStaff: [],
    deactivatedStaff: [],
    unitStaffs: [],
    allStaff: [],
    staffDetails: {},
    currentStaff: {},
};

export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ACADEMIC_STAFF: (state, action) => {
            state.academicStaff = action.payload;
        },
        GET_NON_ACADEMIC_STAFF: (state, action) => {
            state.nonAcademicStaff = action.payload;
        },
        GET_DEACTIVATED_STAFF: (state, action) => {
            state.deactivatedStaff = action.payload;
        },
        GET_UNIT_STAFFS: (state, action) => {
            state.unitStaffs = action.payload;
        },
        GET_ALL_STAFF: (state, action) => {
            state.allStaff = action.payload;
        },

        GET_STAFF_ID: (state, action) => {
            state.staffDetails = action.payload;
        },
        SAVE_CURRENT_STAFF: (state, action) => {
            state.currentStaff = action.payload;
        },
    },
});

export const {
    GET_ACADEMIC_STAFF,
    GET_NON_ACADEMIC_STAFF,
    GET_DEACTIVATED_STAFF,
    GET_UNIT_STAFFS,
    GET_ALL_STAFF,
    LOADING,
    STOP_LOADING,
    GET_STAFF_ID,
    SAVE_CURRENT_STAFF,
} = staffSlice.actions;

export default staffSlice.reducer;
