import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as AlertIcon } from '../../../../assets/icons/alert-grey.svg';
import { ReactComponent as CircularPlus } from '../../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import TextAreaInput from '../../../../components/inputs/text-area';
import TextInput from '../../../../components/inputs/text-input';
import { Loader } from '../../../../components/loader';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../../containers/ScreenContainers';
import { getDepartments } from '../../../../redux/departments/actions';
import { getMatricFormat, setMatricFormat } from '../../../../redux/settings/actions';
import { arrayToObject, objectToArray } from '../../../../utils';

const Box = tw.div`
    min-w-[max-content]
    border
    rounded-l
    h-[4rem]
    px-3
    flex
    items-center
    justify-center
`;

const IconBox = tw.div`
    rounded
    border
    h-[4rem]
    p-4
    ml-3
    cursor-pointer
`;

const payloadKeys = [
    { name: 'Admission Year', key: 'session' },
    { name: 'Organization Abbreviation', key: 'organizationAbbrevation' },
    { name: 'Programme Abbreviation', key: 'programAbbreviation' },
    { name: 'Separator', key: 'separator' },
    { name: 'Separator Two', key: 'separatorTwo' },
    { name: 'Separator Three', key: 'separatorThree' },
    { name: 'Separator Four', key: 'separatorFour' },
    { name: 'Starting Serial Number', key: 'startpoint' },
    { name: 'Serial Number', key: 'serialNumber' },
    { name: 'Programme Type', key: 'programType' },
];

const SELECT_DATA = {
    matricOrder: [
        { name: 'Alphabetical', value: 'alphabetic' },
        { name: 'Admission Date', value: 'admissionDate' },
    ],
    separator: [
        { name: 'Forward Slash (/)', value: '/' },
        { name: 'Hyphen (-)', value: '-' },
    ],
    separatorTwo: [
        { name: 'Forward Slash (/)', value: '/' },
        { name: 'Hyphen (-)', value: '-' },
    ],
    separatorThree: [
        { name: 'Forward Slash (/)', value: '/' },
        { name: 'Hyphen (-)', value: '-' },
    ],
    separatorFour: [
        { name: 'Forward Slash (/)', value: '/' },
        { name: 'Hyphen (-)', value: '-' },
    ],
    session: [
        { name: 'Last Two Digits (e.g 23)', value: 'lastTwoDigit' },
        { name: 'Full Digits (e.g 2023)', value: 'fullDigit' },
    ],
    programAbbreviation: [
        { name: 'Faculty Short Code', value: 'facultyCode' },
        { name: 'Department Short Code', value: 'departmentCode' },
    ],
    serialNumber: [
        { name: 'Institution Based', value: 'institutionBased' },
        { name: 'Department Based', value: 'departmentBased' },
        { name: 'Faculty Based', value: 'facultyBased' },
    ],
    programType: [
        { name: 'Undergraduate (UG)', value: 'UG' },
        { name: 'Postgraduate (PG)', value: 'PG' },
    ],
    continuum: [
        { name: 'True', value: true },
        { name: 'False', value: false },
    ],
};

const PLACEHOLDERS = {
    session: 'e.g 23',
    serialNumber: 'Input Starting Number',
    programAbbreviation: 'Departmental Short Code (AGE)',
    separator: 'e.g / or -',
    programType: 'e.g UG',
};

const MatricNumberSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, matricSettings } = useSelector((state) => state.settings);

    const initialData = objectToArray(matricSettings?.settings || {});
    const { departments } = useSelector((state) => state.department);
    const { faculties } = useSelector((state) => state.faculty);

    const [matricOrder, setMatricOrder] = useState(matricSettings?.matricOrder || '');
    const [formValues, setFormValues] = useState(initialData || [{ key: '', value: '' }]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [altLoading, setAltLoading] = useState(false);
    const [continuum, setContinuum] = useState(matricSettings?.continuum || null);
    const [serialNumberValues, setSerialNumberValues] = useState(matricSettings?.serialNumberValues || []);

    // eslint-disable-next-line no-unused-vars
    const dataToUseForSerialNumber = {
        facultyBased: {
            data: faculties,
            dataKey: 'facultyName',
            payloadKey: 'faculty',
        },
        departmentBased: {
            data: departments,
            dataKey: 'department',
            payloadKey: 'department',
        },
    };

    const updateValues = (index, objProp, newValue) => {
        setFormValues((prevData) => {
            const newData = [...prevData]; // Create a copy of the array
            newData[index] = { ...newData[index], [objProp]: newValue }; // Update the object with the new value
            return newData; // Return the updated array
        });
    };

    const removeValues = (index) => {
        setFormValues((prevData) => {
            const newData = [...prevData]; // Create a copy of the array
            newData.splice(index, 1); // Remove the object at the specified index
            return newData; // Return the updated array
        });
    };

    const updateSerialNumberData = (payloadKey, name, serialNumber) => {
        const newData = [...serialNumberValues];
        const existingIndex = newData.findIndex((item) => item?.[payloadKey] === name);

        if (existingIndex !== -1) {
            newData[existingIndex] = { ...newData[existingIndex], serialNumber };
            setSerialNumberValues(newData);
        } else {
            setSerialNumberValues([...newData, { [payloadKey]: name, serialNumber }]);
        }
    };

    const getSample = () => {
        let str = '';

        formValues.forEach((item) => {
            //TODO //use this instead of returning
            if (item.key === 'serialNumber') return;
            if (item.value === 'lastTwoDigit') return (str += '23');
            if (item.value === 'fullDigit') return (str += '2023');
            if (item.value === 'facultyCode') return (str += 'ENGR');
            if (item.value === 'departmentCode') return (str += 'CIV');
            if (item.value) str += item.value;
        });
        return str;
    };

    const handleSubmit = async () => {
        if (!inputValue || inputValue !== 'SAVE') return toast.error("Input doesn't match");
        setAltLoading(true);
        const payload = arrayToObject(formValues);
        const { serialNumber, startpoint } = payload || {};

        const continuumObj = serialNumber ? { continuum } : {};
        const serialNumberToSend =
            serialNumber === 'institutionBased'
                ? { institutionBased: Number(startpoint) }
                : {
                      [serialNumber]: serialNumberValues,
                  };

        const serialNumberObject = serialNumber ? { serialNumber: serialNumberToSend } : {};

        const res = await dispatch(
            setMatricFormat({ ...payload, matricOrder, sample: getSample(), ...continuumObj, ...serialNumberObject }),
        );
        if (res) {
            setShowConfirm(false);
            dispatch(getMatricFormat());
            toast.success('Matric settings saved successfully');
        }
        setAltLoading(false);
    };

    useEffect(() => {
        dispatch(getMatricFormat());
        dispatch(getDepartments());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings" subTitle="Matric Number Settings" />

            <div className="rounded-lg bg-white min-h-full p-9 mt-5">
                <div className="xl:w-2/3">
                    <PageTitle align="left" bottom="2rem">
                        Matric Number Settings
                    </PageTitle>
                    <TextAreaInput name="sample" placeholder="Sample" value={getSample()} height="8rem" />

                    <FlexCentredRow className="mt-3">
                        <Box>
                            <Text>Matric Number Order</Text>
                        </Box>
                        <Select
                            name="name"
                            containerClass="w-full rounded-r"
                            objProp="name"
                            placeholder="Select"
                            borderRadius="rounded-r"
                            passedSelectedItems={
                                // TODO test getting initialData using toTitleCase when data has been set
                                matricSettings?.matricOrder
                                    ? {
                                          name:
                                              SELECT_DATA.matricOrder.find(
                                                  (item) => item.value === matricSettings?.matricOrder,
                                              )?.name || '',
                                      }
                                    : null
                            }
                            data={SELECT_DATA.matricOrder}
                            onChange={(selected) => setMatricOrder(String(selected[0].value))}
                        />
                    </FlexCentredRow>

                    {formValues?.map((item, idx) => (
                        <>
                            <FlexCentredRow className="mt-3" key={String(item?.key + idx)}>
                                <div className="w-1/3">
                                    <Select
                                        name="name"
                                        containerClass="w-full rounded-l"
                                        objProp="name"
                                        placeholder="Select"
                                        borderRadius="rounded-r"
                                        passedSelectedItems={
                                            item?.key
                                                ? {
                                                      name:
                                                          payloadKeys.find((itm) => itm.key === item?.key)?.name || '',
                                                  }
                                                : null
                                        }
                                        data={payloadKeys}
                                        onChange={(selected) => updateValues(idx, 'key', selected[0].key)}
                                    />
                                </div>
                                {item.key === 'organizationAbbrevation' || item.key === 'startpoint' ? (
                                    <TextInput
                                        name="name"
                                        type={item.key === 'startpoint' ? 'number' : 'text'}
                                        value={item.value}
                                        placeholder={PLACEHOLDERS[item.key] || 'Enter Value'}
                                        onChange={(e) => updateValues(idx, 'value', e.target.value)}
                                    />
                                ) : (
                                    <Select
                                        name="name"
                                        containerClass="w-full rounded-r"
                                        objProp="name"
                                        placeholder={PLACEHOLDERS[item.key] || 'Select Value'}
                                        passedSelectedItems={
                                            item?.key
                                                ? {
                                                      name:
                                                          SELECT_DATA[item?.key]?.find(
                                                              (itm) => itm.value === item?.value,
                                                          )?.name || '',
                                                  }
                                                : null
                                        }
                                        borderRadius="rounded-r"
                                        data={SELECT_DATA[item.key]}
                                        onChange={(selected) => {
                                            if (item.key === 'serialNumber') setSerialNumberValues([]);
                                            updateValues(idx, 'value', selected[0].value);
                                        }}
                                    />
                                )}
                                {item.key === 'serialNumber' && (
                                    <>
                                        <Box className="ml-4">
                                            <Text>Continuum</Text>
                                            <AlertIcon className="ml-2" />
                                        </Box>
                                        <Select
                                            name="name"
                                            containerClass="w-full rounded-r"
                                            objProp="name"
                                            placeholder="Select"
                                            borderRadius="rounded-r"
                                            passedSelectedItems={
                                                matricSettings?.continuum
                                                    ? {
                                                          name:
                                                              SELECT_DATA.continuum.find(
                                                                  (item) => item.value === matricSettings?.continuum,
                                                              )?.name || '',
                                                      }
                                                    : null
                                            }
                                            data={SELECT_DATA.continuum}
                                            onChange={(selected) => setContinuum(selected[0].value)}
                                        />
                                    </>
                                )}
                                <IconBox>
                                    <CloseIcon onClick={() => removeValues(idx)} />
                                </IconBox>
                            </FlexCentredRow>

                            {item.key === 'serialNumber' && (
                                <div className="grid grid-cols-2 mt-3 gap-4 w-full">
                                    {dataToUseForSerialNumber[formValues[idx].value]?.data?.map((item) => {
                                        const { dataKey, payloadKey } =
                                            dataToUseForSerialNumber[formValues[idx].value] || {};

                                        const name = item[dataKey];
                                        const value = serialNumberValues.find(
                                            (itm) => itm[payloadKey] === name,
                                        )?.serialNumber;

                                        return (
                                            <FlexCentredRow key={name}>
                                                <Box>
                                                    <Text>{name}</Text>
                                                </Box>
                                                <TextInput
                                                    name="name"
                                                    type="number"
                                                    value={value}
                                                    placeholder="Input Starting Serial Number"
                                                    onChange={(e) =>
                                                        updateSerialNumberData(payloadKey, name, e.target.value)
                                                    }
                                                />
                                            </FlexCentredRow>
                                        );
                                    })}
                                </div>
                            )}
                        </>
                    ))}

                    <Button
                        onClick={() => setFormValues((prev) => [...prev, { key: '', value: '' }])}
                        top="2.3rem"
                        bottom="3.2rem"
                        color="#6366F1"
                        bgColor="#FFF"
                        border="0.5px solid #6366F1"
                        type="button"
                    >
                        <CircularPlus className="mr-[1.015rem]" />
                        Add Another
                    </Button>

                    <FlexRowEnd className="gap-3">
                        <CancelButton type="button" onClick={() => navigate(-1)}>
                            Cancel
                        </CancelButton>
                        <LoadingButton onClick={() => setShowConfirm(true)} type="submit">
                            Save
                        </LoadingButton>
                    </FlexRowEnd>
                </div>
            </div>

            <ConfirmActionDialogueWithInput
                show={showConfirm}
                isLoading={altLoading}
                withIcon={false}
                bgColor="#6366F1"
                placeholder="Enter SAVE"
                title="Save Matric Number Settings?"
                subtitle="Are you sure you want to save this matric number settings?"
                subtitle2="NOTE: These changes will only affect the next set of students to be assigned matric number. To confirm, enter SAVE in the text field."
                close={() => setShowConfirm(false)}
                confirmAction={handleSubmit}
                btn2Text="Save"
                setInputValue={setInputValue}
                value={inputValue}
            />
        </>
    );
};

export default MatricNumberSettings;
