import React from 'react';

import AddNewStaffForm from '../../../../components/forms/add-staff';
import { GoBack } from '../../../../components/go-back';

const CreateStaff = () => {
    return (
        <>
            <GoBack title={`Staff Management`} subTitle="Add New Staff" />

            <AddNewStaffForm />
        </>
    );
};

export default CreateStaff;
