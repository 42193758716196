import React from 'react';

import CreateNewFacultyCourse from '../../../../components/forms/create-faculty-course';
import { GoBack } from '../../../../components/go-back';
import { PageTitle } from '../../../../containers/MesssageContainers';

const AddFacultyGeneralCourse = ({ action = 'Add' }) => {
    return (
        <>
            <div className="mb-12">
                <GoBack title="Courses" />
                <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                    {action} General Course
                </PageTitle>
            </div>

            <CreateNewFacultyCourse action={action} />
        </>
    );
};
export default AddFacultyGeneralCourse;
