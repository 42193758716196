import React from 'react';

import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import '@coreui/coreui/dist/css/coreui.min.css';
// import './css/table.css';
import './css/tabs.css';
import './css/pills.css';
import { PersistGate } from 'redux-persist/integration/react';
import '@belrald_hq/belrald-ui/dist/style.css';

import App from './App';
import { TableProvider } from './context/tableContext';
import { persistor, store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Toaster
                        toastOptions={{
                            style: {
                                fontSize: '1.4rem',
                            },
                            duration: 5000,
                        }}
                        position="top-center"
                    />
                    <TableProvider>
                        <App />
                    </TableProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
);
