import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export-blue.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
        padding: 5rem 1.6rem 1.4rem 3.4rem;
        /* width: 36.6rem; */
    }
`;

const StyledClose = tw(CloseIcon)`
    absolute
    top-[2.1rem]
    right-[3.73rem]
`;

const ChooseColumnsToExport = ({ show, close, exportAction }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledClose onClick={close} />
            <FlexCentredRow>
                <ExportIcon className="mr-[8.15px]" />
                <Text as="h3" size="1.9rem" weight="600">
                    Export Columns
                </Text>
            </FlexCentredRow>

            <FlexRowEnd className="gap-x-[16px]">
                <Button onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <Button onClick={exportAction} bgColor="#6366F1" color="#fff">
                    Export
                </Button>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ChooseColumnsToExport;
