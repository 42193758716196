import React from 'react';

import { CModal } from '@coreui/react';
import { useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 50.8rem;
        max-height: 60rem;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    // flex
    // gap-4
`;

const ConfirmAddAdministrator = ({
    title,
    subtitle,
    show,
    close,
    list,
    confirmAction,
    role,
    btnText,
    name,
    altLoading,
}) => {
    const { isLoading } = useSelector((state) => state.administrators);

    return (
        <>
            <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        {title || 'Add Administrator'}
                    </Text>
                    <CloseIcon onClick={close} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="3.2rem">
                    {subtitle || 'Are you sure you want to add this person(s) as'}
                    {Array.isArray(role) ? (
                        role?.map((item) => (
                            <Text align="left" weight="600">
                                {item.role}
                            </Text>
                        ))
                    ) : (
                        <Text align="left" weight="600">
                            {role}
                        </Text>
                    )}
                </Text>

                <ItemsContainer>
                    {Array.isArray(list) ? (
                        <>
                            {list?.map((item) => (
                                <Text key={item._id} align="left">{`${item?.fullName}`}</Text>
                            ))}
                        </>
                    ) : (
                        <Text align="left">{name}</Text>
                    )}
                </ItemsContainer>
                <Text color="#6B7280" size="1.2rem" align="left">
                    {list?.length} name(s) selected
                </Text>

                <FlexRowEnd className="gap-x-[16px]">
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton onClick={confirmAction} loading={isLoading || altLoading}>
                        {btnText || 'Add'}
                    </LoadingButton>
                </FlexRowEnd>
            </StyledModal>
        </>
    );
};

export default ConfirmAddAdministrator;
