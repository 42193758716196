import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import { GET_DESIGNATIONS, GET_UNIT_DESIGNATIONS, LOADING, STOP_LOADING } from './slice';

export const getDesignations = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}designation/${currentUnit?._id}/get_all_designation?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_DESIGNATIONS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDesignationsInUnit = (unitId) => async (dispatch, getState) => {
    dispatch(LOADING());
    dispatch(GET_UNIT_DESIGNATIONS([]));
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}designation/${currentUnit?._id}/get_all_designation_by_unit/${unitId || currentUnit?._id}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_UNIT_DESIGNATIONS(data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        dispatch(GET_UNIT_DESIGNATIONS([]));
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDesignation = (payload) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}designation/${currentUnit?._id}/create_designation`,
            payload,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const editDesignation = (unitId, payload, id) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}designation/${currentUnit?._id}/edit_designation/${unitId}/${id}`,
            payload,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const getDesignationDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}designation/${currentUnit?._id}/get_designation_by_id/${id}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDesignation = (id) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}designation/${currentUnit?._id}/delete_designation/${id}`,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const emptyDesignation = (id) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}designation/${currentUnit?._id}/empty_designation/${id}`,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        // dispatch(STOP_LOADING());
    }
};
