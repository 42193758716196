export const COURSE_UNITS = [0, 1, 2, 3, 4, 5, 6];
export const LEVELS = [100, 200, 300, 400, 500, 600, 700];
export const SECONDARY_CERT_TYPES = ['WAEC', 'NECO', 'NABTEC'];
export const TERTIARY_CERT_TYPES = [
    'B.A.',
    'B.Eng.',
    'B.Ed.',
    'B.LIS.',
    'B.MLS.',
    'B.Pharm.',
    'B.NSc.',
    'B.Sc.',
    'B.Tech.',
    'DVM (Doctor of Veterinary Medicine)',
    'HND',
    'LL.B',
    'MBBS (or MBChB)',
    'NCE',
    'ND',
    'BPH',
];
export const PG_CERT_TYPES = [
    'DBA',
    'Ed.D.',
    'LL.M.',
    'M.A',
    'M.B.A.',
    'M.Ed.',
    'M.Eng.',
    'M.Phil.',
    'M.Sc.',
    'M.Tech',
    'MPA',
    'Ph.D.',
    'PGD',
];

export const STAFF_TYPES = {
    Teaching: 'Teaching',
    NonTeaching: 'Non-Teaching',
};