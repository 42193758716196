import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down2.svg';
import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/peopleIcon.svg';
import { ActionsPopup } from '../../../../../components';
import { Button, LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../../components/go-back';
import TextAreaInput from '../../../../../components/inputs/text-area';
import TextInput from '../../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../../components/loader';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn, RelativeContainer } from '../../../../../containers/ScreenContainers';
import {
    createWithdrawalList,
    getDegreeSetting,
    getWithdrawalListSettings,
} from '../../../../../redux/settings/actions';
import { generateLevels } from '../../../../../utils';

import { ValidationSchema } from './ValidationSchema';

const Header = tw.h3`
    font-semibold
    text-[1.4rem]
    text-[#1F2937]
    mb-[2rem]
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[10.3rem]
    justify-end
`;

const MainContainer = tw.div`
    bg-white
    px-[3rem]
    py-[3rem]
    max-w-[100.3rem]
    rounded-2xl
`;

const STEP = 0.01;
const MIN = 0.0;

const WithdrawalListSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { isLoading, withdrawalListSettings, degreeSettings } = useSelector((state) => state.settings);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: firstDegreeId } = currentFaculty;
    const { degreePoint } = degreeSettings || {};
    const { departmentId, studyDuration, academicStructure = '', academicStructureId } = currentDepartment;
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [openDropdown, setOpenDropdown] = useState(null);
    const [session, setSession] = useState(activeSession || '');
    const [altLoading, setAltLoading] = useState(false);

    const levels = generateLevels(studyDuration);

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const createFormikLevelsData = () => {
        const formikLevelsData = {};
        levels?.forEach((num) => {
            formikLevelsData[num] = 0;
        });
        return formikLevelsData;
    };

    useEffect(() => {
        if (!session) return;
        dispatch(getWithdrawalListSettings(academicStructureId, lastDegreeId, session));
    }, [academicStructureId, dispatch, lastDegreeId, session]);

    useEffect(() => {
        if (!session) return;
        dispatch(getDegreeSetting(academicStructureId, firstDegreeId, session));
    }, [academicStructureId, dispatch, firstDegreeId, session]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings" subtitle="Withdrawal List" />

            <PageTitle weight="600" size="2.3rem" lineHeight="3.4rem" top="3.6rem" bottom="3.7rem" align="left">
                Withdrawal List
            </PageTitle>
            <MainContainer>
                {!degreePoint && (
                    <Text color="red" align="left" bottom="2rem">
                        Organisation needs to set the GPA scale in class of degree settings!
                    </Text>
                )}

                <Formik
                    initialValues={{
                        message: withdrawalListSettings?.message || '',
                        minimumCgpa: withdrawalListSettings?.minimumCgpa || {
                            ...createFormikLevelsData(),
                        },
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        setAltLoading(true);

                        const res = await dispatch(
                            createWithdrawalList({
                                academicStructureId,
                                lastDegreeId,
                                session,
                                payload: values,
                                useLoader: false,
                            }),
                        );
                        if (res) {
                            toast.success('Setting saved successfully!');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <RelativeContainer className="max-w-max my-12">
                                <Button
                                    color="#6B7280"
                                    type="button"
                                    border="1px solid #9CA3AF"
                                    onClick={() => handleOpenDropdown('sessions')}
                                >
                                    {session || 'Session'}
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={openDropdown === 'sessions'}
                                    close={() => setOpenDropdown(null)}
                                    items={actionItemSessions}
                                />
                            </RelativeContainer>

                            <div className="flex items-center gap-[1.6rem] mb-5">
                                <PeopleIcon />
                                <Text size="1.6rem" weight="600">
                                    Set Withdrawal List
                                </Text>
                            </div>

                            <TextAreaInput
                                label="Input Message"
                                value={values.message}
                                name="message"
                                height="8rem"
                                errorMessage={errors.message}
                                onChange={(e) => setFieldValue('message', e.target.value)}
                                placeholder={
                                    'e.g. "That the following students who at the end of Second Semester of 200 level are NOT in GOOD STANDING (i.e having CGPA) that is less than 2.50 in both semesters for the session be advised to withdraw from  the degree programme."'
                                }
                            />

                            <FlexColumn className="gap-[0.8rem] mt-5">
                                <Header>Choose Minimum CGPA</Header>
                                {levels?.map((level) => {
                                    const fieldName = `minimumCgpa.${level}`;
                                    return (
                                        <div key={fieldName}>
                                            <TextInput
                                                name={fieldName}
                                                type="number"
                                                label={`${level} Level`}
                                                step={STEP}
                                                min={MIN}
                                                max={degreePoint}
                                                errors={errors}
                                                value={values.minimumCgpa[`${level}`]}
                                                placeholder="Enter minimum CGPA"
                                                onChange={(e) => {
                                                    if (!degreePoint) return;
                                                    const value = e.target.value;
                                                    if (value > degreePoint) {
                                                        toast.error(
                                                            `You can't set a value greater than ${degreePoint}`,
                                                        );
                                                        return;
                                                    }
                                                    setFieldValue(fieldName, value);
                                                }}
                                                width="31rem"
                                            />
                                        </div>
                                    );
                                })}
                            </FlexColumn>

                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={altLoading} disabled={!session || altLoading} type="submit">
                                    Save
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

export default WithdrawalListSettings;
