import React from 'react';

import tw, { styled } from 'twin.macro';

import { ReactComponent as ExternalLink } from '../../../../../assets/icons/external-Link.svg';
import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { Button } from '../../../../../components/buttons';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../../containers/ScreenContainers';
import { handlePdfPreview } from '../../../../../utils';

const RowContainer = tw.div`
    mt-8
    grid
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const TitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    font-weight: 500;
`;

const SubText = styled(TitleText)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
`;

const SubText1 = styled(SubText)`
    color: #6366f1;
    display: inline-block;
`;

const Publication = ({ pub }) => {
    return (
        <div>
            <Details className="mt-[3rem]">
                <FlexCentredRow>
                    <People className="mr-[1.719rem]" />
                    <PageTitle size="1.9rem" lineheight="2.8rem">
                        Publication
                    </PageTitle>
                </FlexCentredRow>
                {pub?.uploadPublication?.map((publication, _id) => (
                    <RowContainer className="lg:text-left text-center">
                        <FlexCol>
                            <TitleText>Publisher</TitleText>
                            <SubText>
                                {publication?.publisher}
                                {publication?.publicationLink && (
                                    <a href={publication?.publicationLink} target="_blank" rel="noopener noreferrer">
                                        <SubText1>{`(${publication?.publicationLink})`}</SubText1>
                                    </a>
                                )}
                            </SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Type</TitleText>
                            <SubText>{publication?.publicationType}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Title</TitleText>
                            <SubText>{publication?.publicationTitle}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Authors</TitleText>
                            <SubText>{publication?.author}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Year Published</TitleText>
                            <SubText>{publication?.year?.slice(0, 4)}</SubText>
                        </FlexCol>
                        <div className="flex justify-start">
                            {publication?.publication && (
                                <Button
                                    border="1px solid #e5e7eb"
                                    onClick={() => handlePdfPreview(publication?.publication?.url)}
                                >
                                    View Publication
                                    <ExternalLink />
                                </Button>
                            )}
                        </div>
                        <hr />
                    </RowContainer>
                ))}
            </Details>
        </div>
    );
};

export default Publication;
