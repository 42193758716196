import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CreditCard } from '../../../../../assets/icons/Credit-Card.svg';
import { ReactComponent as PenTool } from '../../../../../assets/icons/Pen-Tool.svg';
import { Text } from '../../../../../containers/MesssageContainers';
import { createPlan } from '../../../../../redux/ict/actions';

import Checkout from './checkout';
import Customize from './customize';

const BillingTabs = () => {
    const [tab, setTab] = useState(1);
    const [seats, setSeats] = useState(0);
    const dispatch = useDispatch();
    const { billingPlans } = useSelector((store) => store.ict);
    const monthlyiD = billingPlans?.find((plan) => plan.interval === 'monthly');
    const yearlyiD = billingPlans?.find((plan) => plan.interval === 'yearly');
    const navigate = useNavigate();

    const createMonthlyPlan = async () => {
        const payload = { seatNumber: seats, planId: monthlyiD._id };
        const res = await dispatch(createPlan(payload));
        if (res) {
            window.open(res, '_blank', 'noreferrer');
        }
    };

    const createYearlyPlan = async () => {
        const payload = { seatNumber: seats, planId: yearlyiD._id };
        const res = await dispatch(createPlan(payload));
        if (res) {
            navigate(res);
        }
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <div
                    onClick={() => setTab(1)}
                    className={`w-[4rem] h-[4rem] rounded-full ${
                        tab === 1 ? 'bg-[#6366F1]' : 'bg-[#F3F4F6]'
                    } flex items-center justify-center cursor-pointer`}
                >
                    <PenTool />
                </div>
                <div className="h-[1px] w-[17.6rem] bg-[#D1D5DB] mx-[1.6rem]"></div>
                <div
                    onClick={() => setTab(2)}
                    className={`w-[4rem] h-[4rem] rounded-full ${
                        tab === 2 ? 'bg-[#6366F1]' : 'bg-[#F3F4F6]'
                    } flex items-center justify-center cursor-pointer`}
                >
                    <CreditCard />
                </div>
            </div>
            <div className="flex items-center justify-center gap-[17rem] pt-[2.1rem]">
                <Text align="center" size="1.6rem" weight="500" color={tab === 1 ? '#1F2937' : '#9CA3AF'}>
                    Customize
                </Text>

                <Text align="center" size="1.6rem" weight="500" color={tab === 2 ? '#1F2937' : '#9CA3AF'}>
                    Checkout
                </Text>
            </div>
            <div className="pt-[7.8rem]">
                {tab === 1 && <Customize onClick={() => setTab(2)} seats={seats} setSeats={setSeats} />}
                {tab === 2 && (
                    <Checkout seats={seats} onMonthlyConfirm={createMonthlyPlan} onYearlyConfirm={createYearlyPlan} />
                )}
            </div>
        </div>
    );
};

export default BillingTabs;
