import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { editStaffEmail, getAcademicStaff, getNonAcademicStaff } from '../../../redux/staff/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditStaffEmail = ({ show, setShow, selectedStaff, tab }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow(false);

    const { isLoading } = useSelector((store) => store.staff);

    return (
        <>
            <FormContainer close={onClose} show={show} title={`Edit Email of ${selectedStaff?.fullName || ''}`}>
                <Formik
                    initialValues={{
                        staffEmail: selectedStaff?.staffEmail || '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(editStaffEmail(selectedStaff?._id, values));
                        if (res) {
                            tab === 0 ? dispatch(getAcademicStaff()) : dispatch(getNonAcademicStaff());
                            actions.resetForm();
                            onClose();
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="staffEmail"
                                    type="text"
                                    label="Input Staff Email"
                                    placeholder="Staff Email"
                                    value={values.staffEmail}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    disabled={isLoading || !values.staffEmail}
                                    loading={isLoading}
                                    type="submit"
                                >
                                    Edit Staff Email
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditStaffEmail;
