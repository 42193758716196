import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import { toggleOrganisationUnitStatus } from '../../../redux/organisation-unit/actions';
import { LoadingButton } from '../../buttons';
import RadioInput from '../../inputs/radio';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const ManageOrganizationUnitStatus = ({ item, show, setShow, onDone }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow('');

    const { isLoading } = useSelector((state) => state.organisationUnits);

    const initialStatus = item?.unitStatus;

    return (
        <>
            <FormContainer
                headerIcon={<SettingsIcon />}
                close={onClose}
                show={show}
                title={`Manage ${item?.unitName} Unit Status`}
            >
                <Formik
                    initialValues={{
                        status: item?.unitStatus || '',
                    }}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(toggleOrganisationUnitStatus(item._id));
                        if (res) {
                            actions.resetForm();
                            onClose();
                            onDone?.();
                        }
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <RadioInput
                                    name="status"
                                    label="Enable"
                                    checked={values?.status === 'Active'}
                                    onClick={() => setFieldValue('status', 'Active')}
                                />
                                <RadioInput
                                    name="status"
                                    label="Disable"
                                    checked={values?.status === 'Inactive'}
                                    onClick={() => setFieldValue('status', 'Inactive')}
                                />
                            </div>

                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={initialStatus === values?.status || isLoading}
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default ManageOrganizationUnitStatus;
