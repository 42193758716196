import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../components/list-empty';
import { CountBadge, getStatusStyles, StatusContainer } from '../../../../components/shared';
import { Text } from '../../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getAcademicStaff } from '../../../../redux/staff/actions';
import { getDegreeInfo } from '../../../../utils';

const AcademicStaff = ({ handleSelect, selectedItems }) => {
    const { isLoading, academicStaff: staff } = useSelector((state) => state.staff);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAcademicStaff(query));
        // dispatch(getStaffInUnit());
    }, [dispatch, query]);

    const fields = [
        {
            accessorKey: 'action',
            cell: (props) => <p>{props?.getValue()}</p>,
            header: <Checkbox />,
            size: 5,
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Full Name',
            accessorKey: 'name',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: firstDegreeOptionsStr || '',
            accessorKey: 'firstDegree',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: secondDegreeOptionsStr || '',
            accessorKey: 'secondDegree',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Designation',
            accessorKey: 'designation',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Unit',
            accessorKey: 'unit',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Email',
            accessorKey: 'staffEmail',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Admin Status',
            accessorKey: 'staff_status',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Lecturers Status',
            accessorKey: 'lecturer_status',
        },
    ];

    const modifiedStaff = staff?.map((item) => {
        const { firstDegree, secondDegree } = getDegreeInfo(item, 'academicStructure');

        return {
            ...item,
            action: (
                <td>
                    <Checkbox checked={selectedItems?.some((ite) => ite._id === item._id)} />
                </td>
            ),
            name: (
                <td style={{ color: '#2563EB' }}>
                    <Link to={item._id}>{`${item.fullName}`}</Link>
                </td>
            ),
            firstDegree: <td>{firstDegree || '-'}</td>,
            secondDegree: <td>{secondDegree || '-'}</td>,
            unit: (
                <td>
                    <div className="flex items-center gap-[4px]">
                        <Text color="#6b728">{item.unitName[0] || '-'}</Text>
                        {item.unitName?.length > 1 ? <CountBadge>+{item.unitName?.length - 1}</CountBadge> : null}
                    </div>
                </td>
            ),

            staff_status: (
                <td>
                    <StatusContainer style={getStatusStyles(item.staffStatus.toLowerCase())}>
                        <span>{item.staffStatus}</span>
                    </StatusContainer>
                </td>
            ),

            lecturer_status: (
                <td>
                    <StatusContainer style={getStatusStyles(item.lecturerStatus.toLowerCase())}>
                        <span>{item?.lecturerStatus || ''}</span>
                    </StatusContainer>
                </td>
            ),
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedStaff || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Staff " subtitle="Create Staff">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Link to="add-staff">
                                    <Button bgColor="#10B981" color="#fff">
                                        <Circularplus className="mr-[1.15rem]" />
                                        Create New
                                    </Button>
                                </Link>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => {
                    const selectedItem = staff?.find((el) => el?._id === item?._id);
                    handleSelect(selectedItem);
                }}
                isActionable
            />
        </>
    );
};

export default AcademicStaff;
