// Pages that can only be visited when user is authenticated

import { SPORTS } from '../../utils/unitNames';
import {
    CreateRole,
    EditAdministrator,
    Received,
    Roles,
    // AddAdministrator,
    Administrator,
    AcademicCalendar,
    StaffDetail,
    Staff,
    GeneralSettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    EditRole,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';

export const sportsRoutes = [
    {
        path: '/sports/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/sports/access',
        element: (
            <Access
                staffs={<Staff unitName={SPORTS} />}
                administrators={<Administrator unitName={SPORTS} />}
                roles={<Roles unitName={SPORTS} />}
            />
        ),
    },
    {
        path: '/sports/access/add-administrator',
        // element: <AddAdministrator unitName={SPORTS} />,
        element: <AssignRole unitName={SPORTS} />,
    },
    {
        path: '/sports/access/edit-administrator',
        element: <EditAdministrator unitName={SPORTS} />,
    },
    {
        path: '/sports/access/create-role',
        element: <CreateRole unitName={SPORTS} />,
    },
    {
        path: '/sports/access/assign-role',
        element: <AssignRole unitName={SPORTS} />,
    },
    {
        path: '/sports/access/create-role',
        element: <EditRole unitName={SPORTS} />,
    },
    {
        path: '/sports/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: 'sports/communications/received',
        element: <Received unitName={SPORTS} />,
    },
    {
        path: '/sports/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/sports/communications/distribution-list',
        element: <DistributionList unitName={SPORTS} title="Communications" />,
    },
    {
        path: '/sports/communications/distribution-list/add',
        element: <AddDistributionList unitName={SPORTS} title="Communications" />,
    },
    {
        path: '/sports/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={SPORTS} title="Communications" />,
    },
    {
        path: '/sports/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/sports/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/sports/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/sports/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
