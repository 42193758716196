import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as Avatar } from '../../../../assets/icons/avatar.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close-bg-ash.svg';
import { ReactComponent as Paperclip } from '../../../../assets/icons/Paperclip.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/icons/Share.svg';
import { SearchInput, SharedDoc } from '../../../../components';
import CardPopup from '../../../../components/popups/card-popup';
import { Text } from '../../../../containers/MesssageContainers';
//import { Loader } from '../../../../components/loader';
import { getAllDistributionList } from '../../../../redux/distribution-list/actions';
import { getStaffFileById, shareFile } from '../../../../redux/staff-arena/actions';

const ButtonContainer = tw.div`
flex 
items-center 
gap-[1rem] 
border-[0.5px] 
border-[#E5E7EB] 
rounded-[4px] 
mt-[4rem] 
mb-[2.7rem] 
px-[1.6rem] 
py-[0.8rem] 
shadow-[0px_1px_2px_0px rgba(6,25,56,0.05)] 
w-[fit-content]
cursor-pointer
`;

const GroupContainer = tw.div`
shadow-[0px_1px_2px_0px rgba(6,25,56,0.05)] 
text-[1.4rem]
border-[1px] 
w-[fit-content] 
px-[8px] 
py-[6px] 
rounded-[1000px]
`;

const ShareFile = ({ id, show, close }) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const { singleFile } = useSelector((store) => store.staffArena);
    const { staffData: userData } = useSelector((store) => store.user);
    const [dlList, setDlList] = useState([]);
    const [receipients, setReceipients] = useState([]);

    useEffect(() => {
        if (userData.staffType === 'Teaching') {
            const fetchData = async () => {
                const deptData = dispatch(getAllDistributionList(userData.department, 'departmentName'));
                const facData = dispatch(getAllDistributionList(userData.faculty, 'facultyName'));

                const [resDept, resFac] = await Promise.all([deptData, facData]);

                setDlList([...resDept, ...resFac]);
            };
            fetchData();
        } else {
            const fetchUnitData = async () => {
                const res = await dispatch(getAllDistributionList(userData.units?.[0], 'unitName'));
                setDlList(res);
            };
            fetchUnitData();
        }
    }, [dispatch, userData]);

    useEffect(() => {
        if (!id) return;
        dispatch(getStaffFileById(id));
    }, [dispatch, id]);

    const handleEnter = (e) => {
        // const emails = [];
        if (e.key === 'Enter') {
            // emails.push(searchValue);
            if (searchValue.trim() !== '') {
                setReceipients((prevItems) => [...prevItems, searchValue]);
                setSearchValue('');
            }
        }
    };

    const handleShare = async () => {
        const payload = { search: receipients };
        const res = await dispatch(shareFile(id, payload));
        if (res) {
            close();
        }
    };

    if (!singleFile) return;

    return (
        <CardPopup show={show} close={close}>
            <div className="mx-3 mt-[7rem]">
                <div className="border-b-[1px] border-b-[#E5E7EB] pb-[1rem] ">
                    <SharedDoc title={singleFile?.attachment?.fileName} size={singleFile?.attachment?.size} />
                </div>
                <div className="px-[2.5rem]">
                    <div className="flex items-center gap-[8px] pt-[2.7rem] pb-[1.6rem] ">
                        <ShareIcon />
                        <Text size="1.6rem">Share With:</Text>
                    </div>
                    <SearchInput
                        onChange={(e) => setSearchValue(e.target.value)}
                        onClose={() => setSearchValue('')}
                        onSearch={handleShare}
                        value={searchValue}
                        containerWidth="100%"
                        width="100%"
                        withRadius
                        onKeyDown={(e) => handleEnter(e)}
                    />
                    {receipients?.length > 0 && (
                        <div className="flex gap-[4px] items-center flex-wrap pt-[1.4rem]">
                            {receipients?.map((rec, i) => (
                                <div className="flex gap-[5px] items-center">
                                    <GroupContainer key={i}>{rec}</GroupContainer>
                                    <Close
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setReceipients((prevData) => prevData.filter((item, index) => index !== i))
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="pt-[3.6rem] flex flex-col gap-[2rem]">
                        {dlList?.map((dl, i) => (
                            <div key={i} className="flex items-center justify-between">
                                <div className="shadow-[0px_1px_2px_0px rgba(6,25,56,0.05)] border-[1px] w-[fit-content] px-[8px] py-[6px] rounded-[1000px]">
                                    <div className="flex items-center gap-[4px]">
                                        <Avatar />
                                        <Text align="left" size="1.4rem" weight="500">
                                            {dl.name}
                                        </Text>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="border-[0.5px] border-[#6366F1] text-[#6366F1] rounded-[4px] text-[1.4rem] font-medium px-[1.6rem] py-[0.8rem]"
                                >
                                    Send
                                </button>
                            </div>
                        ))}
                    </div>
                    <ButtonContainer>
                        <Paperclip />
                        <Text size="1.4rem" weight="500">
                            Share as attachment
                        </Text>
                    </ButtonContainer>
                </div>
            </div>
        </CardPopup>
    );
};

export default ShareFile;
