import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { editThirdDegree } from '../../../redux/programmes/actions';
import { capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import TextAreaInput from '../../inputs/text-area';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const MainContainer = tw.div`
    bg-white
    rounded-lg
    max-w-[100.5rem]
`;

const EditProgramme = ({ show, onClose, selectedProg, onFinish }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.programmes);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { splitAcademicStructureThree: structThree, academicStructureId } = currentFaculty;

    return (
        <>
            <FormContainer close={onClose} show={show} title={`Edit ${structThree}`}>
                <MainContainer>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            subprogram: selectedProg?.subprogram || '',
                            abbreviation: selectedProg?.abbreviation || '',
                            numberingCode: selectedProg?.numberingCode || '',
                            description: selectedProg?.description || '',
                        }}
                        validationSchema={ValidationSchema}
                        onSubmit={async (values, actions) => {
                            let payloadToUse;

                            const { numberingCode, abbreviation, subprogram, ...otherValues } = values;

                            const splitStructThree = structThree?.toLowerCase();

                            switch (splitStructThree) {
                                case 'program':
                                    payloadToUse = {
                                        subprogram: capitalizeFirstLetter(subprogram),
                                        // abbreviation: abbreviation.toUpperCase(),
                                        subProgramCode: [abbreviation.toUpperCase()],
                                        ...otherValues,
                                        ...(numberingCode ? { subProgramNumberingCode: numberingCode } : {}),
                                    };
                                    break;
                                case 'department':
                                    payloadToUse = {
                                        department: capitalizeFirstLetter(subprogram),
                                        departmentCode: [abbreviation.toUpperCase()],
                                        ...otherValues,
                                        ...(numberingCode ? { departmentNumberingCode: numberingCode } : {}),
                                    };
                                    break;
                                default:
                                    break;
                            }

                            const res = await dispatch(
                                editThirdDegree(academicStructureId, selectedProg?._id, payloadToUse),
                            );
                            if (res) {
                                toast.success(`${structThree} was updated successfully`);
                                actions.resetForm();
                                onFinish?.();
                                onClose();
                            }
                        }}
                    >
                        {({ errors, handleChange, values, setFieldValue }) => (
                            <Form>
                                <div className="gap-5 flex flex-col">
                                    <TextInput
                                        name="subprogram"
                                        label={`${structThree} Name`}
                                        type="text"
                                        placeholder={`${structThree} Name`}
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.subprogram}
                                    />
                                    <TextInput
                                        name="abbreviation"
                                        label={`${structThree} Abbreviation e.g MAT, ENG`}
                                        type="text"
                                        placeholder={`${structThree} Abbreviation`}
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.abbreviation}
                                    />
                                    <TextInput
                                        name="numberingCode"
                                        label={`${structThree} Numbering Code (Optional)`}
                                        type="text"
                                        placeholder={`${structThree} Numbering Code (Optional)`}
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.numberingCode}
                                    />
                                    <TextAreaInput
                                        name="description"
                                        label="Description"
                                        type="text"
                                        placeholder="Description"
                                        errorMessage={errors.description}
                                        onChange={(e) => {
                                            setFieldValue('description', e.target.value);
                                        }}
                                        value={values.description}
                                        height="6rem"
                                    />
                                </div>
                                <FlexContainer>
                                    <CancelButton type="button" onClick={onClose}>
                                        Cancel
                                    </CancelButton>
                                    <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                        Update {structThree}
                                    </LoadingButton>
                                </FlexContainer>
                            </Form>
                        )}
                    </Formik>
                </MainContainer>
            </FormContainer>
        </>
    );
};
export default EditProgramme;
