import { Suspense, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, Routes, Route, useNavigate } from 'react-router-dom';

import { Loader } from './components/loader';
import Layout from './layout';
// import { logoutUser } from './redux/user/actions';
import { routes } from './routes';
import { parseJSON } from './utils/useJSON';

import 'react-datepicker/dist/react-datepicker.css';

function App() {
    const token = parseJSON('token', '');

    const isAuthenticated = token ? true : false;
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            // dispatch(logoutUser(navigate));
            // navigate('/sign-in-staff');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token]);

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                {routes.map((route) => (
                    <Route
                        key={route.path}
                        path={isAuthenticated ? '/' : route.path}
                        element={isAuthenticated ? <Navigate to="/dashboard" replace /> : route.element}
                    />
                ))}

                <Route path="*" element={<Layout />} />
            </Routes>
        </Suspense>
    );
}

export default App;
