import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup, Checkbox, SearchInput } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    FilterContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { getProgramProbationList } from '../../../../redux/exam-magt/actions';

const Th = tw.th`
    overflow-auto
    border p-2
`;

const Td = tw.td`
    border
    border-gray-100
`;

const ProbationListSheet = () => {
    const dispatch = useDispatch();
    const { level } = useParams();

    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { departmentId } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const [session, setSession] = useState(activeSession || '');
    const [searchValue, setSearchValue] = useState('');
    const [probationListData, setProbationListData] = useState([]);
    const [openModal, setOpenModal] = useState(null);
    const [cocLength, setCocLength] = useState(1);

    const handleChangeCocLength = (items = []) => {
        if (items?.length > cocLength) {
            setCocLength(items?.length);
        }
        return;
    };

    const handleOpenDropdown = (dropdown) => {
        if (openModal === dropdown) {
            setOpenModal(null);
        } else {
            setOpenModal(dropdown);
        }
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenModal(null);
        },
    }));

    const getData = async () => {
        const data = await dispatch(
            getProgramProbationList({
                departmentId,
                session,
                programId,
                level,
            }),
        );
        if (data) {
            setProbationListData(data);
        } else {
            setProbationListData([]);
        }
    };

    useEffect(() => {
        if (!session) return;
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, departmentId, programId, session]);

    return (
        <>
            <FlexRowSpaceBetween>
                <GoBack title="Probation List" subTitle={`${level} level`} />
            </FlexRowSpaceBetween>

            <FlexDiv className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle>Probation List </PageTitle>
            </FlexDiv>

            <div className="mt-[2.4rem] min-h-[50vh] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem] w-full overflow-x-auto">
                <FilterContainer>
                    <FlexCentredRow className="mr-[2.065rem] gap-8">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'sessions'}
                                close={() => setOpenModal(null)}
                                items={actionItemSessions}
                            />
                        </RelativeContainer>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>

                <div className=" text-center text-[#4B5563]">
                    <table className="min-w-full border border-gray-400 overflow-auto">
                        {/* Table Headers */}
                        <thead className="overflow-auto">
                            {/* Header Row 1*/}
                            <tr>
                                <Th colSpan="3"></Th>
                                <Th colSpan="4">Previous CGPA</Th>
                                <Th colSpan="4">Current CGPA</Th>
                                <Th colSpan="4">Remark</Th>
                            </tr>
                            {/* Header Row 2 */}
                            <tr>
                                <Th></Th>
                                <Th>Matric No.</Th>
                                <Th>Full Name</Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th colSpan={cocLength}>Carry Over</Th>
                                <Th>Previous Carry Over</Th>
                                <Th>Non Registered</Th>
                                <Th>Total Unit</Th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="">
                            {probationListData?.map((student) => {
                                const carryOvers = student?.courses?.filter((course) => course?.carriedOver);

                                handleChangeCocLength(carryOvers);
                                const resultArrayLength = student?.result?.length;
                                const prevResult = student?.result?.[resultArrayLength - 2];
                                const currentResult = student?.result?.[resultArrayLength - 1];

                                return (
                                    <tr key={student.studentId} className="">
                                        <Td className="">
                                            <Checkbox />{' '}
                                        </Td>
                                        <Td className="min-w-max">{student?.matricNumber}</Td>
                                        <Td className="min-w-[150px]">{student?.name}</Td>
                                        <Td>{prevResult?.totalUnitsRegistered}</Td>
                                        <Td>{prevResult?.totalUnitsPassed}</Td>
                                        <Td>{prevResult?.totalWeightPassed}</Td>
                                        <Td>{prevResult?.cumulativeGradePointAverage}</Td>
                                        <Td>{currentResult?.totalUnitsRegistered}</Td>
                                        <Td>{currentResult?.totalUnitsPassed}</Td>
                                        <Td>{currentResult?.totalWeightPassed}</Td>
                                        <Td>{currentResult?.cumulativeGradePointAverage}</Td>

                                        <Td className="flex gap-2 gap-pt-2 items-center border-0">
                                            {carryOvers?.map((course) => (
                                                <p key={course?.courseCode}>{course.courseCode}</p>
                                            ))}
                                        </Td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ProbationListSheet;
