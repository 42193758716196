import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as Closeicon } from '../../../assets/icons/close.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const FormHeader = tw.div`
    flex
    gap-[0.715rem]
    mb-[3.2rem]
    mt-[4rem]
    items-center
`;
const Container = tw.div`
    bg-white
    w-[47.2rem]
    px-[1.6rem]
    py-[0.8rem]
    rounded-lg
    relative
    
`;

const FormCard = ({ show, close, headerIcon, title, children }) => {
    return (
        <Container show={show} onClose={close}>
            <Closeicon className="absolute top-[1.3rem] right-[2.13rem] cursor-pointer" onClick={close} />
            <FormHeader>
                {headerIcon ? headerIcon : <PeopleIcon />}
                <Title>{title}</Title>
            </FormHeader>
            {children}
        </Container>
    );
};

export default FormCard;
