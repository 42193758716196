import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { createCollegeThirdLevel } from '../../../redux/college/actions';
import { createFacultyThirdLevel } from '../../../redux/faculty/actions';
import { capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import TextAreaInput from '../../inputs/text-area';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const CreateProgramme = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty, isLoading } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);

    const { splitAcademicStructureOne: structOne, splitAcademicStructureThree: structThree } = currentFaculty;

    return (
        <>
            <MainContainer>
                <Formik
                    initialValues={{
                        subprogram: '',
                        abbreviation: '',
                        numberingCode: '',
                        description: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        let res, payloadToUse, actionType;

                        const { numberingCode, subprogram, abbreviation, ...otherValues } = values;
                        const splitStructOne = structOne?.toLowerCase();
                        const splitStructThree = structThree?.toLowerCase();

                        switch (splitStructThree) {
                            case 'program':
                                payloadToUse = {
                                    subprogram: capitalizeFirstLetter(subprogram),
                                    abbreviation: abbreviation.toUpperCase(),
                                    subProgramCode: [abbreviation.toUpperCase()],
                                    studyDuration: currentDepartment.studyDuration,
                                    ...otherValues,
                                    ...(numberingCode ? { subProgramNumberingCode: numberingCode } : {}),
                                };
                                break;
                            case 'department':
                                payloadToUse = {
                                    department: capitalizeFirstLetter(subprogram),
                                    departmentCode: [abbreviation.toUpperCase()],
                                    studyDuration: currentDepartment.studyDuration,
                                    ...otherValues,
                                    ...(numberingCode ? { departmentNumberingCode: numberingCode } : {}),
                                };
                                break;
                            default:
                                break;
                        }

                        switch (splitStructOne) {
                            case 'college':
                                actionType = createCollegeThirdLevel;
                                break;
                            case 'faculty':
                                actionType = createFacultyThirdLevel;
                                break;
                            default:
                                break;
                        }

                        res = await dispatch(actionType(payloadToUse));

                        if (res) {
                            toast.success(`${structThree} was added successfully`);
                            actions.resetForm();
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="mb-[2.4rem] mt-[1.2rem] gap-[0.715rem]">
                                <PeopleIcon />
                                <Title>Add {structThree}</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] gap-x-[3.2rem] grid grid-cols-2">
                                <TextInput
                                    name="subprogram"
                                    label={`${structThree} Name`}
                                    type="text"
                                    placeholder={`${structThree} Name`}
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.subprogram}
                                />
                                <TextInput
                                    name="abbreviation"
                                    label={`${structThree} Abbreviation e.g MAT, ENG`}
                                    type="text"
                                    placeholder={`${structThree} Abbreviation`}
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.abbreviation}
                                />
                                <TextInput
                                    name="numberingCode"
                                    label={`${structThree} Numbering Code (Optional)`}
                                    type="text"
                                    placeholder={`${structThree} Numbering Code (Optional)`}
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.numberingCode}
                                />
                                <TextAreaInput
                                    name="description"
                                    label="Description"
                                    type="text"
                                    placeholder="Description"
                                    errorMessage={errors.description}
                                    onChange={(e) => {
                                        setFieldValue('description', e.target.value);
                                    }}
                                    value={values.description}
                                    height="6rem"
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    disabled={isLoading || collegeLoading}
                                    loading={isLoading || collegeLoading}
                                    type="submit"
                                >
                                    Add {structThree}
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};
export default CreateProgramme;
