import React, { useEffect, useState } from 'react';

import { Country, State } from 'country-state-city';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { scheduleDemo } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import PhoneNumberInput from '../../inputs/phone-input';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const OnboardingScheduleForm = ({ onSubmitSuccess }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.isLoading);
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [states, setStates] = useState([]);

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (countryCode) {
            setStates(State.getStatesOfCountry(countryCode));
        }
    }, [countryCode]);

    return (
        <Formik
            initialValues={{
                firstName: '',
                surname: '',
                email: '',
                phoneNumber: '',
                institutionName: '',
                institutionType: '',
                country: '',
                state: '',
                designation: '',
                totalNoOfStudents: '',
                howYouHearAboutUs: '',
            }}
            validationSchema={ValidationSchema}
            onSubmit={async (values, actions) => {
                const payload = {
                    ...values,
                    phoneNumber: String(values.phoneNumber),
                };

                const res = await dispatch(scheduleDemo(payload));
                if (res) {
                    actions.resetForm();
                    onSubmitSuccess();
                }
            }}
        >
            {({ values, errors, setFieldValue, handleChange }) => (
                <Form>
                    <div className="w-[32rem] mt-[32px] md:w-[50rem] max-h-[80vh] overflow-y-auto scrollbar-hide lg:w-[60.8rem] bg-white p-[3rem] md:p-[4.8rem] rounded-lg shadow-lg">
                        <h3 className="font-semibold text-[#1F2937] text-[23px] mb-2">Schedule a demo</h3>
                        <p className="text-[#9CA3AF] mb-8 text-[14px]">
                            Please take a time to introduce yourself and your institution
                        </p>

                        <div className="grid gap-[24px]">
                            <div className="grid grid-cols-2 gap-6">
                                <TextInput
                                    label="Surname"
                                    name="firstName"
                                    type="text"
                                    placeholder="Enter Surname"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />

                                <TextInput
                                    label="First Name"
                                    name="surname"
                                    type="text"
                                    placeholder="Enter First Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.surname}
                                />
                            </div>

                            <TextInput
                                label="Email Address"
                                name="email"
                                type="email"
                                placeholder="Enter Email Address"
                                errors={errors}
                                onChange={handleChange}
                                value={values.organizationEmail}
                            />

                            <PhoneNumberInput
                                label="Phone Number"
                                countryCode={true}
                                name="phoneNumber"
                                type="number"
                                placeholder="Enter Phone Number"
                                errors={errors}
                                onChange={handleChange}
                                value={values.phoneNumber}
                            />

                            <TextInput
                                label="Institution Name"
                                name="institutionName"
                                type="text"
                                placeholder="Enter Institution Name"
                                errors={errors}
                                onChange={handleChange}
                                value={values.institutionName}
                            />

                            <Select
                                label="Institution Type"
                                name="institutionType"
                                objProp="name"
                                placeholder="Select Institution Type"
                                data={['Private University', 'State University', 'Federal University'].map((item) => ({
                                    name: item,
                                }))}
                                onChange={(selected) => {
                                    setFieldValue('institutionType', String(selected[0].name));
                                }}
                                error={errors.institutionType}
                            />

                            <Select
                                label="Institution Country"
                                name="country"
                                objProp="name"
                                placeholder="Select Institution Country"
                                data={countries?.map((item) => ({ name: item.name, isoCode: item.isoCode }))}
                                onChange={(selected) => {
                                    setFieldValue('country', String(selected[0].name));
                                    setCountryCode(selected[0].isoCode);
                                }}
                                error={errors.country}
                            />

                            <Select
                                label="Institution State"
                                name="state"
                                objProp="name"
                                data={states?.map((item) => ({ name: item.name }))}
                                placeholder="Select Institution State"
                                onChange={(selected) => {
                                    setFieldValue('state', selected[0].name);
                                }}
                                error={errors.state}
                            />

                            <TextInput
                                name="designation"
                                label="Designation"
                                type="text"
                                placeholder="Enter Designation"
                                errors={errors}
                                onChange={handleChange}
                                value={values.designation}
                            />

                            <Select
                                label="Number of Students"
                                name="totalNoOfStudents"
                                objProp="name"
                                data={['1-300', '301-700', '701-1000', '1001-3000', '3001-5000', 'Above 5000'].map(
                                    (item) => ({ name: item }),
                                )}
                                placeholder="Select Range"
                                onChange={(selected) => {
                                    setFieldValue('totalNoOfStudents', selected[0].name);
                                }}
                                error={errors.totalNoOfStudents}
                            />

                            <Select
                                label="How did you hear about us?"
                                name="howYouHearAboutUs"
                                objProp="name"
                                data={['Google Search', 'Email', 'Referral', 'Linkedin', 'Website', 'Others'].map(
                                    (item) => ({ name: item }),
                                )}
                                placeholder="Select an Answer"
                                onChange={(selected) => {
                                    setFieldValue('howYouHearAboutUs', selected[0].name);
                                }}
                                error={errors.howYouHearAboutUs}
                            />
                        </div>
                        <FlexRowEnd className="mt-12">
                            <LoadingButton loading={isLoading} disabled={isLoading} type="submit" className="w-full">
                                Schedule Demo
                            </LoadingButton>
                        </FlexRowEnd>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default OnboardingScheduleForm;
