import React, { useEffect, useState } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import Avatar from 'react-avatar';
import tw from 'twin.macro';

// import { ReactComponent as People } from '../../../../assets/icons/people2.svg';
import { Button } from '../../../../components/buttons';
import EditStudentRecordsForm from '../../../../components/forms/edit-student-records';
import { getStatusStyles, StatusContainer } from '../../../../components/shared';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    BasicContent,
    DetailsCardSubText,
    DetailsCardTitleText,
    RowContainer,
} from '../../../../containers/RecordContainers';
import { FlexCentredRow, FlexColumn } from '../../../../containers/ScreenContainers';
import DetailsBox from '../details-box';

const MainContainer = tw.div`
    grid
    grid-cols-3
    gap-[2.4rem]
    mt-8
`;

const SmallCard = tw.div`
    bg-white
    rounded-lg
    p-6
    flex
    flex-col
    items-center
    justify-center
`;

const DisplayCard = tw.div`
    bg-[#EEF2FF]
    rounded-lg
    pt-[4rem]
    pb-[3.2rem]
    pl-[4.4rem]
    pr-[4.3rem]
    ml-[6rem]
    mr-[6.1rem]
    mb-[1.4rem]
`;

const fields = [
    {
        header: 'S/N',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'serialNumber',
    },
    {
        header: 'Course Title',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'courseTitle',
    },
    {
        header: 'Course Code',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'courseCode',
    },
    {
        header: 'Unit',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'unit',
    },
    {
        header: 'Total',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'total',
    },
];

//TODO is component in use?
const BasicInfo = ({ details }) => {
    const { bioData, medData, libraryData, sportData, basicData, uploads } = details || {};

    const [showForm, setShowForm] = useState(false);
    const [biodataPercent, setBiodataPercent] = useState(0);
    const [sportPercent, setSportPercent] = useState(0);
    const [medPercent, setMedPercent] = useState(0);
    const [libPercent, setLibPercent] = useState(0);
    const [progress, setProgress] = useState(0);
    const courseDetailsData = [];

    useEffect(() => {
        if (!details) return;
        if (bioData?.status === true) {
            setBiodataPercent(25);
        }
        if (medData?.status === true) {
            setMedPercent(25);
        }
        if (libraryData?.status === true) {
            setLibPercent(25);
        }
        if (sportData?.status === true) {
            setSportPercent(25);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);

    useEffect(() => {
        const total = biodataPercent + sportPercent + libPercent + medPercent;
        setProgress(total);
    }, [biodataPercent, sportPercent, medPercent, libPercent]);

    const leftRow = [
        {
            title: 'Full Name',
            subtitle: `${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`,
        },
        {
            title: 'Date of Birth',
            subtitle: bioData?.updateInfo?.dateOfBirth?.slice(0, 10),
        },
        {
            title: 'Email Address',
            subtitle: basicData?.studentEmail,
        },
    ];
    const rightRow = [
        {
            title: 'Gender',
            subtitle: bioData?.updateInfo?.gender,
        },
        {
            title: 'Registration',
            subtitle: basicData?.utmeRegNumber,
        },
        {
            title: 'Admission Mode',
            subtitle: basicData?.admissionType,
        },
    ];

    return (
        <div>
            <MainContainer>
                <DetailsBox
                    className="col-span-2"
                    heading="Students Details"
                    dataBadge={
                        <StatusContainer
                            className="flex items-center"
                            style={getStatusStyles(basicData?.academicStatus?.toLowerCase())}
                        >
                            <Text
                                weight="500"
                                lineHeight="2.4rem"
                                color={getStatusStyles(basicData?.academicStatus?.toLowerCase())?.color}
                            >
                                {basicData?.academicStatus}
                            </Text>
                        </StatusContainer>
                    }
                >
                    <BasicContent>
                        {uploads?.passport?.[0]?.url ? (
                            <div>
                                <img
                                    alt="profile"
                                    src={uploads?.passport?.[0]?.url || ''}
                                    className="h-[18rem] w-[15rem]"
                                />
                            </div>
                        ) : (
                            <Avatar
                                name={`${basicData?.lastName} ${basicData?.firstName}`}
                                size="150"
                                textSizeRatio={1.75}
                                className="rounded-full"
                            />
                        )}
                        <RowContainer className="lg:text-left text-center">
                            {leftRow?.map((item, index) => (
                                <FlexColumn key={index}>
                                    <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                </FlexColumn>
                            ))}
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            {rightRow?.map((item, index) => (
                                <FlexColumn key={index}>
                                    <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                </FlexColumn>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </DetailsBox>

                <SmallCard className="">
                    <PageTitle size="1.9rem" lineheight="2.8rem" bottom="3rem">
                        Registration Status
                    </PageTitle>
                    <div className="progressbar" style={{ '--percentage': `${progress}` }}>
                        <div className="innerProgress"></div>

                        <Text lineHeight="4rem" size="4rem">
                            {progress}%
                        </Text>
                    </div>
                    {progress < 100 ? (
                        <>
                            <Text lineHeight="2.4rem" color="#6B7280">
                                Student registration not complete.
                            </Text>
                            <Text lineHeight="2.4rem" color="#6B7280">
                                Needs Improvement.
                            </Text>
                        </>
                    ) : (
                        <Text lineHeight="2.4rem" color="#6B7280">
                            Student Registration Complete
                        </Text>
                    )}
                </SmallCard>
                <DetailsBox className="col-span-2" heading="Approved Semester Courses">
                    <Table width={'100%'} data={courseDetailsData || []} columns={fields} />
                </DetailsBox>
                <SmallCard>
                    <PageTitle size="1.9rem" lineheight="2.8rem" bottom="1.8rem">
                        CGPA
                    </PageTitle>
                    <DisplayCard>
                        <PageTitle weight="500" size="6.9rem" lineHeight="8.4rem">
                            0.00
                        </PageTitle>
                    </DisplayCard>
                    <Text lineHeight="2.4rem" color="#6B7280">
                        Current Cumulative Grade
                    </Text>
                    <Text lineHeight="2.4rem" color="#6B7280">
                        Point Average
                    </Text>
                    <FlexCentredRow>
                        <Button className="border border-[#6366F1] mx-auto">
                            <Text border="solid" weight="500" lineHeight="2.4rem" color="#6366F1">
                                View Result
                            </Text>
                        </Button>
                    </FlexCentredRow>
                </SmallCard>
            </MainContainer>
            {showForm && <EditStudentRecordsForm show={showForm} setShow={setShowForm} />}
        </div>
    );
};

export default BasicInfo;
