import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    students: [],
    studentDetails: {},
};

export const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_STUDENTS: (state, action) => {
            state.students = action.payload;
        },
        GET_STUDENT_DETAILS: (state, action) => {
            state.studentDetails = action.payload;
        },
    },
});

export const { GET_STUDENTS, LOADING, STOP_LOADING,GET_STUDENT_DETAILS } = studentsSlice.actions;

export default studentsSlice.reducer;
