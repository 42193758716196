import React from 'react';

import InputContainer from '../../../components/Container/inputcontainer';
import SignInNewStaffForm from '../../../components/forms/sign-in-new-staff';
import { PoweredBy } from '../../../components/powered-by';
import { AuthContainer } from '../confirm-password';




const  StaffCreatePassword = () => {
    return (
        <AuthContainer>

            <InputContainer>
                <SignInNewStaffForm />
                <PoweredBy />
            </InputContainer>

        </AuthContainer>
    );
};

export default StaffCreatePassword;
