export const registryNavLinks = [
    { title: 'Records', to: '/registry/records' },
    {
        title: 'Academic Calendar',
        to: '/registry/academic-calendar',
    },
    {
        title: 'Communications',
        to: '/registry/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/registry/communications/compose',
            },
            {
                title: 'Received',
                to: '/registry/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/registry/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/registry/communications/settings',
            },
        ],
    },
    { title: 'Special Letters', to: '/registry/special-letters' },
    {
        title: 'Handbook',
        to: '/registry/handbook',
    },
    { title: 'Access', to: '/registry/access' },

    {
        title: 'Settings',
        to: '/registry/settings',
    },
];

export const registryRoutes = registryNavLinks.map((item) => item.to);
