import * as Yup from 'yup';

import { emailRegExp, passwordRegExp } from '../../../utils/regex';

export const ValidationSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegExp, 'Email address is not valid').nullable().required('Required'),
    password: Yup.string()
        .min(8, 'Password needs to be a minimum of 8 characters')
        .matches(passwordRegExp, 'Password must contain a capital letter, small letter, number and a special character')
        .nullable()
        .required('Required'),
    confirmPassword: Yup.string()
        .nullable()
        .required('Required')
        .oneOf([Yup.ref('password'), null], 'Password does not match'),
});
