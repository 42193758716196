import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    academicSession: Yup.string().nullable().required('Required'),
    semester: Yup.string().nullable().required('Required'),
    studentType: Yup.string().nullable().required('Required'),
    week: Yup.string().nullable().required('Required'),
    description: Yup.string().nullable().required('Required'),
    startDate: Yup.string().nullable().required('Required'),
    endDate: Yup.string().nullable().required('Required'),
});
