import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { editCollege } from '../../../redux/college/actions';
import { editFaculty } from '../../../redux/faculty/actions';
import { capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditFacultyForm = ({ show, setShow, currentFaculty, onFinish }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow(false);
    const { isLoading } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);
    const { currentStructure } = useSelector((state) => state.academicStructure);
    const [selectedAcademicStructure, setSelectedAcademicStructure] = useState(
        capitalizeFirstLetter(currentFaculty?.academicStructure) || '',
    );
    const splitedAcademicStructure = selectedAcademicStructure.split('-');

    const selectData = currentStructure?.items?.map((item) => ({
        ...item,
        name: capitalizeFirstLetter(item.structure),
    }));

    return (
        <>
            <FormContainer close={onClose} show={show} title={`Edit ${currentStructure?.name}`}>
                <Formik
                    initialValues={{
                        academicStructure: selectedAcademicStructure || '',
                        facultyName: currentFaculty?.facultyName || '',
                        facultyCode: currentFaculty?.facultyCode.toString() || '',
                        description: currentFaculty?.description || '',
                        facultyNumberingCode: currentFaculty?.facultyNumberingCode || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const { facultyNumberingCode, facultyCode, facultyName, ...otherValues } = values;
                        let res;

                        if (currentStructure?.name?.toLowerCase() === 'faculty') {
                            const payload = {
                                facultyCode: facultyCode?.split(','),
                                ...otherValues,
                                ...(facultyNumberingCode ? { facultyNumberingCode } : {}),
                                facultyName: capitalizeFirstLetter(facultyName),
                            };

                            res = await dispatch(editFaculty(currentFaculty?._id, payload));
                        }

                        if (currentStructure?.name?.toLowerCase() === 'college') {
                            const collegePaylod = {
                                academicStructure: otherValues.academicStructure,
                                collegeName: capitalizeFirstLetter(facultyName),
                                collegeCode: facultyCode?.split(','),
                                description: otherValues.description,
                                ...(facultyNumberingCode ? { collegeNumberingCode: facultyNumberingCode } : {}),
                            };

                            res = await dispatch(editCollege(currentFaculty?._id, collegePaylod));
                        }

                        if (res) {
                            onFinish();
                            actions.resetForm();
                            onClose();
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-5 flex flex-col">
                                <Select
                                    name="academicStructure"
                                    objProp="name"
                                    placeholder="Select Academic Structure"
                                    label="Select Academic Structure"
                                    data={selectData}
                                    onChange={(selected) => {
                                        setFieldValue('academicStructure', String(selected[0].name));
                                        setSelectedAcademicStructure(selected[0].name);
                                    }}
                                    passedSelectedItems={currentFaculty?.academicStructure}
                                    error={errors.academicStructure}
                                />
                                {selectedAcademicStructure && (
                                    <>
                                        <TextInput
                                            label={`${splitedAcademicStructure[0]} Name (${splitedAcademicStructure[0]} of ${values.facultyName})`}
                                            name="facultyName"
                                            type="text"
                                            placeholder={`${splitedAcademicStructure[0]} Name`}
                                            value={values.facultyName}
                                            onChange={handleChange}
                                            errors={errors}
                                        />
                                        <TextInput
                                            label={`${splitedAcademicStructure[0]} Abbreviation`}
                                            name="facultyCode"
                                            type="text"
                                            placeholder={`${splitedAcademicStructure[0]} Abbreviation`}
                                            value={values.facultyCode}
                                            onChange={handleChange}
                                            errors={errors}
                                        />
                                        <TextInput
                                            label={`${splitedAcademicStructure[0]} Code (Optional)`}
                                            name="facultyNumberingCode"
                                            type="text"
                                            placeholder={`${splitedAcademicStructure[0]} Code`}
                                            value={values.facultyNumberingCode}
                                            onChange={handleChange}
                                            errors={errors}
                                        />
                                        <TextInput
                                            label="Description"
                                            name="description"
                                            type="text"
                                            placeholder="Description"
                                            value={values.description}
                                            onChange={handleChange}
                                            errors={errors}
                                        />
                                    </>
                                )}
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading || collegeLoading} type="submit">
                                    Update
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditFacultyForm;
