import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { getCollegeSecondLevel, getCollegeThirdLevel, getColleges } from '../../../redux/college/actions';
import { getFaculties, getFacultySecondLevel, getFacultyThirdLevel } from '../../../redux/faculty/actions';
import { getSchoolThirdLevel } from '../../../redux/school/actions';
import { createStudent } from '../../../redux/students/actions';
import { capitalizeEachWord, capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import ToggleSwitch from '../../inputs/switch';
import TextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const GridRows = tw.div`
    grid
    md:grid-cols-2
    grid-rows-4
    gap-6
`;

const admissionType = [
    { name: 'UTME', value: 'UTME' },
    { name: 'Direct Entry', value: 'DIRECT ENTRY' },
];
const directEntryType = ['ALevels', 'JUPEB', 'IJMB', 'HND', 'ND', 'Transfer'];

const AddNewStudentForm = ({ session }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading: progLoading, departmentProgrammes } = useSelector((state) => state.programmes);
    const { isLoading } = useSelector((state) => state.student);
    const { isLoading: facLoading, faculties, departments } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);
    const { savedAcademicStructure } = useSelector((state) => state.academicStructure);
    const academicUnits = Object.keys(savedAcademicStructure || {}).map((item) => ({ name: capitalizeEachWord(item) }));

    const [departmentId, setDepartmentId] = useState('');
    const [isAcceptance, setIsAcceptance] = useState(false);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [selectedAcadUnit, setSelectedAcadUnit] = useState(null);
    const [academicLevels, setAcademicLevels] = useState([]);

    const { academicStructure, academicStructureId } = selectedFaculty || {};
    const splitStruct = academicStructure?.split('-');

    useEffect(() => {
        if (splitStruct?.length === 3 && splitStruct?.[0]?.toLowerCase() === 'faculty') {
            dispatch(getFacultyThirdLevel(academicStructureId, selectedFaculty?._id));
        } else if (splitStruct?.length === 3 && splitStruct?.[0]?.toLowerCase() === 'college') {
            dispatch(getCollegeThirdLevel(academicStructureId, selectedFaculty?._id));
        } else if (splitStruct?.length === 3 && splitStruct?.[0]?.toLowerCase() === 'school') {
            dispatch(getSchoolThirdLevel(academicStructureId, selectedFaculty?._id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedFaculty, departmentId]);

    useEffect(() => {
        if (selectedAcadUnit) {
            const currStructName = selectedAcadUnit?.name?.toLowerCase() || '';
            if (currStructName === 'college') {
                dispatch(getColleges());
            }
            if (currStructName === 'faculty') {
                dispatch(getFaculties());
            }
        }
    }, [dispatch, selectedAcadUnit]);

    useEffect(() => {
        if (selectedFaculty) {
            const currStructName = selectedAcadUnit?.name?.toLowerCase() || '';
            const { academicStructure, academicStructureId, _id: firstDegreeId } = selectedFaculty || {};

            const splitStruct = academicStructure?.split('-');
            const structTwo = splitStruct?.[1];
            const params = { academicStructureId, firstDegreeId, structTwo };

            if (currStructName === 'college') {
                dispatch(getCollegeSecondLevel(params));
            }
            if (currStructName === 'faculty') {
                dispatch(getFacultySecondLevel(params));
            }
        }
    }, [dispatch, selectedAcadUnit, selectedFaculty]);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    return (
        <>
            <Container>
                <Formik
                    initialValues={{
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        utmeRegNumber: '',
                        ijmbNumber: '',
                        jupebNumber: '',
                        faculty: '',
                        department: '',
                        level: '',
                        studentEmail: '',
                        subProgram: '',
                        matricNumber: '',
                        isAcceptanceFee: '',
                        nin: '',
                        admissionType: '',
                        directEntryType: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const {
                            matricNumber,
                            faculty,
                            department,
                            utmeRegNumber,
                            studentEmail,
                            firstName,
                            middleName,
                            lastName,
                            subProgram,
                            ...otherValues
                        } = values;

                        const facultyField = splitStruct?.[0];
                        const departmentField = splitStruct?.[1] === 'program' ? 'subProgram' : splitStruct?.[1];
                        const programField = splitStruct?.[2] === 'program' ? 'subProgram' : splitStruct?.[2];
                        const payload = {
                            ...otherValues,
                            ...(matricNumber ? { matricNumber } : {}),
                            // program: values.department,
                            firstName: capitalizeFirstLetter(firstName),
                            middleName: capitalizeFirstLetter(middleName),
                            lastName: capitalizeFirstLetter(lastName),
                            utmeRegNumber: utmeRegNumber?.toUpperCase() || '',
                            isAcceptanceFee: isAcceptance,
                            [facultyField]: faculty,
                            [departmentField]: department,
                            [programField]: subProgram,
                            studentEmail: studentEmail.toLowerCase(),
                        };

                        const res = await dispatch(createStudent(session, academicStructureId, payload));
                        if (res) {
                            toast.success('Student has been successfully created');
                            actions.resetForm();
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="gap-2 mb-8">
                                <PeopleIcon />
                                <Text weight="600" size="1.6rem">
                                    Add Student
                                </Text>
                            </FlexCentredRow>

                            <GridRows>
                                <TextInput
                                    name="lastName"
                                    type="text"
                                    placeholder="Surname"
                                    label="Surname"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                                <TextInput
                                    name="firstName"
                                    type="text"
                                    placeholder="First Name"
                                    label="First Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                                <TextInput
                                    name="middleName"
                                    type="text"
                                    placeholder="Other Name"
                                    label="Other Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.middleName}
                                />

                                <TextInput
                                    name="nin"
                                    type="number"
                                    placeholder="National Identification Number (NIN)"
                                    label="National Identification Number (NIN) - Optional"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.nin}
                                />

                                <Select
                                    name="admissionType"
                                    objProp="name"
                                    label="Admission Type"
                                    data={admissionType}
                                    placeholder="Select Admission Type"
                                    onChange={(selected) => {
                                        setFieldValue('admissionType', selected[0].value);
                                    }}
                                    error={errors.admissionType}
                                />
                                {values?.admissionType?.toLowerCase() === 'direct entry' && (
                                    <Select
                                        name="directEntryType"
                                        objProp="name"
                                        label="Direct Entry Type"
                                        data={directEntryType?.map((item) => ({ name: item, value: item }))}
                                        placeholder="Select Direct Entry"
                                        onChange={(selected) => {
                                            setFieldValue('directEntryType', selected[0].value);
                                        }}
                                        error={errors.directEntryType}
                                    />
                                )}
                                {values?.admissionType?.toLowerCase() !== 'utme' &&
                                    values?.directEntryType?.toLowerCase() !== '' && (
                                        <TextInput
                                            name={`${values?.directEntryType?.toLowerCase()}Number`}
                                            type="text"
                                            placeholder={`Enter ${values?.directEntryType} Number`}
                                            label={`${values?.directEntryType} Number`}
                                            errors={errors}
                                            onChange={handleChange}
                                        />
                                    )}
                                {values?.directEntryType?.toLowerCase() === 'hnd' ||
                                values?.directEntryType?.toLowerCase() === 'nd' ||
                                values?.admissionType?.toLowerCase() === 'utme' ? (
                                    <TextInput
                                        name="utmeRegNumber"
                                        type="text"
                                        placeholder="UTME Registration Number"
                                        label="UTME Registration Number"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.utmeRegNumber}
                                    />
                                ) : null}

                                {values?.admissionType === 'UTME' ||
                                values?.directEntryType?.toLowerCase() === 'hnd' ||
                                values?.directEntryType?.toLowerCase() === 'nd' ? (
                                    <TextInput
                                        name="utmeScore"
                                        type="number"
                                        placeholder="UTME Score"
                                        label="UTME Score"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.utmeScore}
                                    />
                                ) : null}

                                {values?.admissionType === 'UTME' ||
                                values?.directEntryType?.toLowerCase() === 'hnd' ||
                                values?.directEntryType?.toLowerCase() === 'nd' ? (
                                    <TextInput
                                        name="postUtmeScore"
                                        type="number"
                                        placeholder="Post-UTME Score"
                                        label="Post-UTME Score"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.postUtmeScore}
                                    />
                                ) : null}

                                <Select
                                    name="academicUnit"
                                    objProp="name"
                                    label="Academic Unit"
                                    data={academicUnits}
                                    placeholder="Select Academic Unit"
                                    onChange={(selected) => {
                                        setSelectedFaculty(null);
                                        setFieldValue('faculty', '');
                                        setSelectedAcadUnit(selected[0]);
                                        setFieldValue('academicUnit', selected[0].name);
                                    }}
                                    error={errors.academicUnit}
                                />

                                {selectedAcadUnit && (
                                    <Select
                                        name="faculty"
                                        objProp="facultyName"
                                        label={selectedAcadUnit?.name}
                                        placeholder={`Select ${selectedAcadUnit?.name}`}
                                        data={faculties}
                                        onChange={(selected) => {
                                            setSelectedFaculty(selected[0]);
                                            setFieldValue('faculty', selected[0].facultyName);
                                            setFieldValue('department', '');
                                        }}
                                        useComponentState={false}
                                        passedSelectedItems={values.faculty ? [{ facultyName: values.faculty }] : null}
                                        error={errors.faculty}
                                        isLoading={facLoading || collegeLoading}
                                    />
                                )}
                                {values.faculty && (
                                    <Select
                                        name="department"
                                        objProp="departmentName"
                                        placeholder={capitalizeFirstLetter(splitStruct?.[1])}
                                        label={capitalizeFirstLetter(splitStruct?.[1])}
                                        isLoading={facLoading || collegeLoading}
                                        data={departments}
                                        onChange={(selected) => {
                                            const levels = selected?.[0]?.levels;
                                            const sortedLevels = levels?.map(Number)?.sort((a, b) => a - b);
                                            setAcademicLevels(sortedLevels?.map(String));
                                            setFieldValue('department', selected[0].departmentName);
                                            setDepartmentId(selected?.[0]?._id);
                                        }}
                                        useComponentState={false}
                                        passedSelectedItems={
                                            values.department ? [{ departmentName: values.department }] : null
                                        }
                                        error={errors.department}
                                    />
                                )}
                                {splitStruct?.[2] && values.department && (
                                    <Select
                                        name="subProgram"
                                        objProp="subprogram"
                                        placeholder="Programme"
                                        data={departmentProgrammes}
                                        label={capitalizeFirstLetter(splitStruct?.[2])}
                                        isLoading={progLoading}
                                        onChange={(selected) => {
                                            setFieldValue('subProgram', selected[0].subprogram);
                                        }}
                                        error={errors.subProgram}
                                    />
                                )}
                                <Select
                                    name="level"
                                    objProp="name"
                                    placeholder="Level"
                                    label="Level"
                                    data={
                                        values?.admissionType?.toLowerCase() === 'utme'
                                            ? academicLevels?.map((item) => ({ name: item }))?.slice(0, 1)
                                            : academicLevels?.map((item) => ({ name: item }))?.slice(1)
                                    }
                                    onChange={(selected) => {
                                        setFieldValue('level', String(selected[0].name));
                                    }}
                                    error={errors.level}
                                />

                                <TextInput
                                    name="studentEmail"
                                    type="text"
                                    placeholder="Email Address"
                                    label="Email Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.studentEmail}
                                />
                                <TextInput
                                    name="matricNumber"
                                    type="text"
                                    label="Matric Number (Optional - for students with matric number)"
                                    placeholder="Input Matric Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.matricNumber}
                                />
                                <FlexRowSpaceBetween>
                                    <Text>Apply Acceptance Fee to student</Text>

                                    <ToggleSwitch checked={isAcceptance} setChecked={setIsAcceptance} />
                                </FlexRowSpaceBetween>
                            </GridRows>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Add Student
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default AddNewStudentForm;
