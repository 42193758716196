import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down2.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../components/loader';
import ActionsPopup from '../../../../components/popups/actions-popup';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, RelativeContainer } from '../../../../containers/ScreenContainers';
import { createWeightedGradePointSetting, getWeightedGradePointSetting } from '../../../../redux/settings/actions';

// import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    max-w-[101rem]
    p-[2.4rem]
    rounded-lg
    mt-10
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[6rem]
    justify-end
`;

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const GRADES = ['5', '4', '3', '2', '1', '0'];

const STEP = 1;
const TOTAL = 100;

const WeightedGradePointSetting = () => {
    const dispatch = useDispatch();

    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { isLoading, weightedGradePointSettings } = useSelector((state) => state.settings);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: firstDegreeId, academicStructureId } = currentFaculty;
    const { pointRange } = weightedGradePointSettings || {};

    const [altLoading, setAltLoading] = useState(false);
    const [session, setSession] = useState(activeSession || '');
    const [openDropdown, setOpenDropdown] = useState(null);
    const [toggleRefetch, setToggleRefetch] = useState(false);

    // const handleValueChange = (type, value, name, setValue, min, max) => {
    //     let newValue = type === 'decrease' ? Number(value) - STEP : Number(value) + STEP;
    //     if (newValue < min) {
    //         toast.error(`Value can't be less than ${min}`);
    //         newValue = Number(min);
    //     } else if (newValue > max) {
    //         toast.error(`Value can't be greater than ${max}`);
    //         newValue = Number(max);
    //     }
    //     const fixedValue = newValue;
    //     setValue(name, fixedValue);
    // };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };
    useEffect(() => {
        if (!session) return;
        dispatch(getWeightedGradePointSetting(academicStructureId, firstDegreeId, session));
    }, [academicStructureId, dispatch, firstDegreeId, session, toggleRefetch]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings" subtitle="Weighted Grade Point Settings" />

            <Container>
                <FlexCentredRow className="mb-[3.2rem] gap-x-[1rem]">
                    <PageTitle weight="600" size="2.3rem" lineHeight="3.4rem">
                        Weighted Grade Point Setttings
                    </PageTitle>
                </FlexCentredRow>
                <Formik
                    initialValues={{
                        pointRange: {
                            5: pointRange?.[5] || { from: '', to: '' },
                            4: pointRange?.[4] || { from: '', to: '' },
                            3: pointRange?.[3] || { from: '', to: '' },
                            2: pointRange?.[2] || { from: '', to: '' },
                            1: pointRange?.[1] || { from: '', to: '' },
                            0: pointRange?.[0] || { from: '', to: '' },
                        },
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        setAltLoading(true);
                        const params = {
                            structureId: academicStructureId,
                            firstDegreeId,
                            session,
                            payload: values,
                            useLoader: false,
                        };
                        const res = await dispatch(createWeightedGradePointSetting(params));
                        if (res) {
                            setToggleRefetch((prevState) => !prevState);
                            toast.success('Weighted grade point settings saved successfully!');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, values, setFieldValue }) => (
                        <Form>
                            <RelativeContainer className="max-w-max my-12">
                                <Button
                                    color="#6B7280"
                                    type="button"
                                    border="1px solid #9CA3AF"
                                    onClick={() => handleOpenDropdown('sessions')}
                                >
                                    {session || 'Session'}
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={openDropdown === 'sessions'}
                                    close={() => setOpenDropdown(null)}
                                    items={actionItemSessions}
                                />
                            </RelativeContainer>

                            <FlexCentredCol className="gap-[0.6rem]">
                                <Label>Weighted Grade Point Settings</Label>
                                {GRADES.map((grade, idx) => {
                                    let fromMin, fromMax, toMin, toMax;
                                    const field1Name = `pointRange.${grade}.from`;
                                    const field2Name = `pointRange.${grade}.to`;
                                    // TODO can this be better?
                                    fromMin = STEP;

                                    if (idx === 0) {
                                        // Highest "A"
                                        toMin = TOTAL;
                                        toMax = TOTAL;
                                        fromMax = TOTAL - STEP;
                                    } else {
                                        const prevValueName = GRADES[idx - 1];

                                        const value = Number(values.pointRange[prevValueName].from) - STEP;
                                        const minValue = value >= TOTAL ? value - STEP : value;

                                        toMin = minValue;
                                        toMax = minValue;
                                        fromMax = minValue - STEP;
                                    }
                                    if (idx === GRADES.length - 1) {
                                        // Lowest "F"
                                        fromMin = 0;
                                        fromMax = 0;
                                    }

                                    return (
                                        <FlexCentredRow className="gap-[5rem]" key={grade}>
                                            <Text align="left">{grade}</Text>

                                            <FlexCentredRow className="gap-[0.8rem]">
                                                <TextInput
                                                    name="numberRange"
                                                    type="number"
                                                    step="1"
                                                    min={fromMin}
                                                    max={fromMax}
                                                    errors={errors}
                                                    value={values.pointRange[grade].from}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (!value) return setFieldValue(field1Name, '');

                                                        if (value > TOTAL) return setFieldValue(field1Name, TOTAL);
                                                        setFieldValue(field1Name, Number(value));
                                                    }}
                                                    classChange="absolute top-[25%] right-[5px] gap-[1.087rem] z-10"
                                                    className=" [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                    width="8.9rem"
                                                />

                                                <Text left="ml-[0.8rem]">to</Text>
                                                <TextInput
                                                    name="numberRange"
                                                    type="number"
                                                    step="1"
                                                    min={toMin}
                                                    max={toMax}
                                                    errors={errors}
                                                    value={values.pointRange[grade].to}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (!value) return setFieldValue(field2Name, '');

                                                        if (value > TOTAL) return setFieldValue(field2Name, TOTAL);
                                                        setFieldValue(field2Name, Number(value));
                                                    }}
                                                    classChange="absolute top-[25%] right-[5px] gap-[1.087rem] z-10"
                                                    className=" [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                    width="8.9rem"
                                                />
                                            </FlexCentredRow>
                                        </FlexCentredRow>
                                    );
                                })}
                            </FlexCentredCol>

                            <FlexContainer>
                                <CancelButton type="button">Cancel</CancelButton>
                                <LoadingButton disabled={!session || altLoading} loading={altLoading} type="submit">
                                    Save
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default WeightedGradePointSetting;
