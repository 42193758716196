// Pages that can only be visited when user is authenticated
import { STAFF_ARENA } from '../../utils/unitNames';
import {
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    EdtProfile,
    Preference,
    GeneralSettings,
    ChangePassword,
    StaffDirectory,
    StaffReceived,
    StaffSent,
    StaffComposeMail,
    StaffComposeLetter,
    ViewFiles,
    StaffArenaDraft,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';
import StaffProfile from '../../views/stafff-arena';
import Document from '../../views/stafff-arena/document';
import NestedFolders from '../../views/stafff-arena/document/folder-details';
import StaffHandbook from '../../views/stafff-arena/handbook';
import StaffHandbookDetails from '../../views/stafff-arena/handbook/handbookDetails';
import {
    ComposeLetter,
    ComposeMemo,
    ComposeNotice,
    LetterPreview,
    MemoPreview,
    // DraftDetails,
    Outbox,
    NoticePreview,
} from '../../views/stafff-arena/office';
import Drafts from '../../views/stafff-arena/office/draft';
import Inbox from '../../views/stafff-arena/office/inbox';
import FileSharing from '../../views/stafff-arena/preference/file-sharing';

export const staffArenaRoutes = [
    {
        path: 'staff-arena/communications/received',
        element: <StaffReceived />,
    },
    {
        path: 'staff-arena/communications/sent',
        element: <StaffSent />,
    },
    {
        path: 'staff-arena/communications/draft',
        element: <StaffArenaDraft />,
    },
    {
        path: 'staff-arena/communications/compose/mail',
        element: <StaffComposeMail unitName={STAFF_ARENA} />,
    },
    {
        path: 'staff-arena/communications/compose/letter',
        element: <StaffComposeLetter unitName={STAFF_ARENA} />,
    },
    {
        path: 'staff-arena/communications/compose/letter/draft',
        element: <StaffComposeLetter unitName={STAFF_ARENA} />,
    },
    {
        path: '/staff-arena/profile',
        element: <StaffProfile />,
    },
    {
        path: '/staff-arena/profile/edit',
        element: <EdtProfile />,
    },
    {
        path: '/staff-arena/directory',
        element: <StaffDirectory />,
    },
    {
        path: '/staff-arena/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/staff-arena/preference',
        element: <Preference />,
    },
    {
        path: '/staff-arena/preference/file-sharing',
        element: <FileSharing />,
    },
    {
        path: '/staff-arena/preference/change-password',
        element: <ChangePassword />,
    },
    {
        path: '/staff-arena/office/distribution-list',
        element: <DistributionList unitName={STAFF_ARENA} title="Staff Arena" />,
    },
    {
        path: '/staff-arena/office/distribution-list/add',
        element: <AddDistributionList unitName={STAFF_ARENA} title="Staff Arena" />,
    },
    {
        path: '/staff-arena/office/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={STAFF_ARENA} title="Staff Arena" />,
    },
    {
        path: '/staff-arena/office/inbox/letter/:id',
        element: <LetterPreview />,
    },
    {
        path: '/staff-arena/office/inbox/memo/:id',
        element: <MemoPreview />,
    },
    {
        path: '/staff-arena/office/outbox/memo/:id',
        element: <MemoPreview />,
    },
    {
        path: '/staff-arena/office/outbox/notice/:id',
        element: <NoticePreview />,
    },
    {
        path: '/staff-arena/office/outbox/letter/:id',
        element: <LetterPreview />,
    },
    {
        path: '/staff-arena/office/inbox',
        element: <Inbox />,
    },
    {
        path: '/staff-arena/office/outbox',
        element: <Outbox />,
    },
    {
        path: '/staff-arena/office/draft',
        element: <Drafts />,
    },
    {
        path: '/staff-arena/office/notice',
        element: <ComposeNotice />,
    },
    {
        path: '/staff-arena/office/letter',
        element: <ComposeLetter />,
    },
    {
        path: '/staff-arena/office/memo',
        element: <ComposeMemo />,
    },
    {
        path: '/staff-arena/office/notice/draft',
        element: <ComposeNotice />,
    },
    {
        path: '/staff-arena/office/letter/draft',
        element: <ComposeLetter />,
    },
    {
        path: '/staff-arena/office/memo/draft',
        element: <ComposeMemo />,
    },
    {
        path: '/staff-arena/documents',
        element: <Document />,
    },
    {
        path: '/staff-arena/documents/folder/:id',
        element: <NestedFolders />,
    },
    {
        path: '/staff-arena/documents/folder/file/:id',
        element: <Document />,
    },
    {
        path: '/staff-arena/shared-file/view-file/:id',
        element: <ViewFiles />,
    },
    {
        path: '/staff-arena/handbook',
        element: <StaffHandbook unitName={STAFF_ARENA} />,
    },
    {
        path: '/staff-arena/handbook/:id',
        element: <StaffHandbookDetails unitName={STAFF_ARENA} />,
    },
    {
        path: '/staff-arena/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/staff-arena/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/staff-arena/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/staff-arena/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
