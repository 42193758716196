import React, { useState, useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { Button } from '../../../components/buttons';
import { AllFilter } from '../../../components/filter-select';
import FilterPopup from '../../../components/filter-select/filter-popup';
import Checkbox from '../../../components/inputs/checkbox';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import SendOfferList from '../../../components/popups/send-offer-list';
import { getStatusStyles, StatusContainer, StatusText } from '../../../components/shared';
import { CenteredContainer, RelativeContainer } from '../../../containers/ScreenContainers';
import { LEVELS } from '../../../data/constants';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSessionDetails, revokeAdmissionOffer, sendMultipleAdmissionOffer } from '../../../redux/admissions/actions';
import { getColleges } from '../../../redux/college/actions';
import { getFaculties } from '../../../redux/faculty/actions';
import { removeStudent } from '../../../redux/students/actions';

import RevokeOffer from './revoke-offer-modal';

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const OFFER_STATUS_FILTER_OPTIONS = ['Accepted', 'Not Sent', 'Sent'];
const ADMISSION_TYPE_FILTER_OPTIONS = ['UTME', 'Direct Entry'];

const AdmissionDetails = () => {
    const navigate = useNavigate();
    const { id: sessionId } = useParams();
    const dispatch = useDispatch();
    const sessionState = useLocation();
    const { isLoading } = useSelector((state) => state.admission);
    const { isLoading: studentsLoading } = useSelector((state) => state.student);
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { isLoading: facultyLoading, faculties } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);

    const [details, setDetails] = useState({});
    const admissionStatus = details?.status?.toLowerCase();
    const [students, setStudents] = useState([]);
    const [openModal, setOpenModal] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [studentLevelFilter, setStudentLevelFilter] = useState('');
    const [admissionTypeFilter, setAdmissionTypeFilter] = useState('');
    const [firstDegreeFilter, setFirstDegreeFilter] = useState('');
    const [courseFilter, setcourseFilter] = useState('');
    const [dateCreatedFilter, setDateCreatedFilter] = useState('');
    const [offerStatusFilter, setOfferStatusFilter] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(true);

    const DateCreatedList = students?.map((item) => item?.admissionYear);
    const secondDegreeList = students?.map((item) => item?.program);

    const FILTER_OPTIONS = useMemo(() => {
        return [
            'Level',
            'Admission Type',
            ...firstDegreeOptionsArray,
            // ...secondDegreeOptionsArray,
            'Date Created',
            'Offer Status',
        ];
    }, [firstDegreeOptionsArray]);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        if (studentLevelFilter) {
            queryParams.studentLevelFilter = studentLevelFilter;
        }
        if (admissionTypeFilter) {
            queryParams.admissionTypeFilter = admissionTypeFilter;
        }
        if (firstDegreeFilter) {
            queryParams.firstDegreeFilter = firstDegreeFilter;
        }
        if (courseFilter) {
            queryParams.courseFilter = courseFilter;
        }
        if (dateCreatedFilter) {
            queryParams.dateCreatedFilter = dateCreatedFilter;
        }
        if (offerStatusFilter) {
            queryParams.offerStatusFilter = offerStatusFilter;
        }

        return queryParams;
    }, [
        admissionTypeFilter,
        dateCreatedFilter,
        debouncedSearchValue,
        firstDegreeFilter,
        offerStatusFilter,
        courseFilter,
        studentLevelFilter,
    ]);

    const handleSelect = (student) => {
        const selected = selectedStudents?.some((ite) => ite._id === student._id);
        if (selected) {
            const newSelected = selectedStudents?.filter((ite) => ite._id !== student._id);
            setSelectedStudents(newSelected);
        } else {
            setSelectedStudents((prev) => [...prev, student]);
        }
    };

    const handleSendOffer = async () => {
        const studentIds = selectedStudents?.map((itm) => itm._id);
        const payload = {
            selectedStudentIds: [...studentIds],
        };
        const res = await dispatch(sendMultipleAdmissionOffer(payload));
        if (res) {
            setSelectedStudents([]);
            setOpenModal('');
            toast.success('Offer sent to student(s) successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleRevokeOffer = async () => {
        const studentIds = selectedStudents?.map((itm) => itm._id);
        const payload = {
            studentIds: [...studentIds],
        };
        const res = await dispatch(revokeAdmissionOffer(payload));
        if (res) {
            setSelectedStudents([]);
            setOpenModal('');
            setInputValue('');
            toast.success('Offer to student(s) revoked successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleDelete = async () => {
        const res = await dispatch(removeStudent(selectedStudents[0]?._id));
        if (res) {
            setSelectedStudents([]);
            setOpenModal('');
            setInputValue('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const generateFilterOptions = (data = [], setStateAction, closeAction, objaccessorKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objaccessorKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    closeAction(null);
                },
            };
        });

        return filterOptions;
    };

    const fields = [
        {
            header: <Checkbox />,
            size: 5,
            accessorKey: 'action',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Full Name',
            accessorKey: 'name',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Matric No.',
            accessorKey: 'matricNumber',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'UTME Reg. No.',
            accessorKey: 'utmeRegNumber',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Level',
            accessorKey: 'level',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Admission Type',
            accessorKey: 'admissionType',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'firstDegree',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'secondDegree',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Date Created',
            accessorKey: 'admissionDate',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Email Address',
            accessorKey: 'studentEmail',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Offer Status',
            accessorKey: 'admissionStatus',
            cell: (props) => <p>{props.getValue()}</p>,
        },
    ];

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setOfferStatusFilter('');
        setSearchValue('');
        setStudentLevelFilter('');
        setAdmissionTypeFilter('');
        setFirstDegreeFilter('');
        setcourseFilter('');
        setDateCreatedFilter('');
        setOfferStatusFilter('');
    };

    const handleAddStudent = () => {
        navigate('/admissions/sessions/add-student', {
            state: details?.admissionYear || sessionState?.state,
        });
    };

    const FILTER_POPUP_OPTIONS = {
        Level: generateFilterOptions(LEVELS, setStudentLevelFilter, setOpenModal),
        'Admission Type': generateFilterOptions(ADMISSION_TYPE_FILTER_OPTIONS, setAdmissionTypeFilter, setOpenModal),
        College: generateFilterOptions(faculties, setFirstDegreeFilter, setOpenModal, 'facultyName'),
        Faculty: generateFilterOptions(secondDegreeList, setcourseFilter, setOpenModal, 'facultyName'),
        // 'School': generateFilterOptions(LEVELS, '', setOpenModal),
        'Date Created': generateFilterOptions(DateCreatedList, setDateCreatedFilter, setOpenModal),
        'Offer Status': generateFilterOptions(OFFER_STATUS_FILTER_OPTIONS, setOfferStatusFilter, setOpenModal),
    };

    const FILTER_VALUES_LOOKUP = {
        Level: {
            value: studentLevelFilter,
            stateAction: setStudentLevelFilter,
        },
        'Admission Type': {
            value: admissionTypeFilter,
            stateAction: setAdmissionTypeFilter,
        },
        College: {
            value: firstDegreeFilter,
            stateAction: setFirstDegreeFilter,
        },
        Faculty: {
            value: courseFilter,
            stateAction: setcourseFilter,
        },
        // 'School': {
        // value: '',
        // stateAction: },
        'Date Created': {
            value: dateCreatedFilter,
            stateAction: setDateCreatedFilter,
        },
        'Offer Status': {
            value: offerStatusFilter,
            stateAction: setOfferStatusFilter,
        },
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    useEffect(() => {
        const currStructName = currentFilterCategory?.toLowerCase() || '';
        if (currStructName === 'college') {
            dispatch(getColleges());
        }
        if (currStructName === 'faculty') {
            dispatch(getFaculties());
        }
    }, [dispatch, currentFilterCategory]);

    useEffect(() => {
        if (sessionId) {
            const getDetails = async () => {
                const data = await dispatch(getSessionDetails(sessionId, query));
                const studentss = data?.students;
                if (data) {
                    setDetails(data);
                    const modifiedStudents = studentss?.map((item) => ({
                        ...item,
                        fullName: `${item?.lastName} ${item?.firstName} ${item?.middleName || ''}`,
                    }));
                    setStudents(modifiedStudents);
                } else {
                    setStudents([]);
                }
            };

            getDetails();
        }
    }, [dispatch, sessionId, query, toggleRefetch]);

    const modifiedStudentsData = students?.map((item) => {
        const firstDegreeStruct = item?.academicStructure?.split('-')?.[0];
        const secondDegreeStruct = item?.academicStructure?.split('-')?.[1];

        return {
            action: (
                <td>
                    <Checkbox checked={selectedStudents?.some((student) => student?._id === item?._id)} />
                </td>
            ),
            name: (
                <td
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                    onClick={() => navigate(`/admissions/sessions/candidate/${item?._id}`)}
                >{`${item?.fullName}`}</td>
            ),
            admissionStatus: (
                <td>
                    <StatusContainer style={getStatusStyles(item?.status?.toLowerCase())}>
                        <span>{item?.status}</span>
                    </StatusContainer>
                </td>
            ),
            admissionDate: <td>{item?.admissionYear ? format(new Date(item?.admissionYear), 'dd-MM-yyyy') : '-'}</td>,
            firstDegree: <td>{item?.[firstDegreeStruct] || '-'}</td>,
            secondDegree: <td>{item?.[secondDegreeStruct] || item?.subProgram || '-'}</td>,
            ...item,
        };
    });

    return (
        <div>
            <PageLayout
                secondaryButtonText="Add new student"
                backTitle="Admissions"
                backSubtitle={`${sessionState?.state}`}
                onSecondaryButtonClick={handleAddStudent}
                onActionBtnClick={() => {
                    setOpenModal('actions');
                }}
                data={modifiedStudentsData || []}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <SendIcon />,
                        name: 'Send Offer',
                        disabled:
                            !selectedStudents?.length ||
                            selectedStudents?.some(
                                (item) =>
                                    item?.status?.toLowerCase() === 'accepted' ||
                                    item?.status?.toLowerCase() === 'sent',
                            ),
                        click: () => {
                            setOpenModal('send-offer');
                        },
                        notShown: admissionStatus === 'closed',
                    },
                    {
                        icon: <EditIcon />,
                        name: 'Edit Student',
                        click: () => {
                            navigate(`/admissions/sessions/candidate/edit/${selectedStudents[0]?._id}`);
                        },
                        disabled: !selectedStudents.length || selectedStudents.length > 1,
                        notShown: admissionStatus === 'closed',
                    },
                    {
                        icon: <EditIcon />,
                        name: 'Revoke Offer',
                        click: () => {
                            setOpenModal('revoke-offer');
                        },
                        disabled:
                            !selectedStudents.length ||
                            selectedStudents?.find((stu) => stu?.status?.toLowerCase() === 'accepted') ||
                            selectedStudents?.find((stu) => stu?.status?.toLowerCase() === 'not sent') ||
                            selectedStudents?.find((stu) => stu?.status?.toLowerCase() === 'revoked'),
                        notShown: admissionStatus === 'closed',
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Student',
                        disabled: !selectedStudents.length || selectedStudents.length > 1,
                        click: () => {
                            setOpenModal('confirm-delete');
                        },
                    },
                ]}
                pageTitle={details?.admissionYear}
                titleAddendum={
                    <>
                        {admissionStatus && (
                            <StatusContainer className="ml-3" style={getStatusStyles(details?.status?.toLowerCase())}>
                                <StatusText>{details?.status?.toLowerCase()}</StatusText>
                            </StatusContainer>
                        )}
                    </>
                }
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showFilter
                showClear={currentFilterCategory}
                filterItems={FILTER_OPTIONS.map((item) => ({
                    name: item,
                    click: () => {
                        handleSelectCategories(item);
                        setCurrentFilterCategory(item);
                        setOpenModal('options-popup-main');
                    },
                }))}
                handleFilterReset={handleReset}
                openFilterPop={openModal === 'options-popup-main'}
                isFilterPopLoading={facultyLoading || collegeLoading}
                closeFilterPop={() => setOpenModal(null)}
                filterPopItems={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                filterCategories={
                    <div>
                        {filterCategories?.length > 0 && (
                            <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                                {filterCategories.map((category) => (
                                    <div key={category} className="flex gap-[1px]">
                                        <StyledTag withLeftRadius>{category}</StyledTag>

                                        {FILTER_VALUES_LOOKUP[category].value && (
                                            <StyledTag withRightRadius>
                                                {FILTER_VALUES_LOOKUP[category].value}
                                                <CloseIcon
                                                    className="ml-[4px] hover:cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveFilterCategory(
                                                            category,
                                                            FILTER_VALUES_LOOKUP[category].stateAction,
                                                        )
                                                    }
                                                />
                                            </StyledTag>
                                        )}
                                    </div>
                                ))}
                                <RelativeContainer>
                                    <AllFilter
                                        useSecondaryBtn
                                        absolutePosition="left-[0px]"
                                        showClear={currentFilterCategory}
                                        items={FILTER_OPTIONS.map((item) => ({
                                            name: item,
                                            click: () => {
                                                handleSelectCategories(item);
                                                setCurrentFilterCategory(item);
                                                setOpenModal('options-popup-sub');
                                            },
                                        }))}
                                        handleReset={handleReset}
                                    />
                                    <FilterPopup
                                        absolutePosition="left-[0px]"
                                        open={openModal === 'options-popup-sub'}
                                        isLoading={facultyLoading || collegeLoading}
                                        close={() => setOpenModal(null)}
                                        items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                    />
                                </RelativeContainer>
                            </div>
                        )}
                    </div>
                }
                fields={fields}
                loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
                noItemView={
                    <CenteredContainer className="mt-[2rem]">
                        <ListEmptyContent
                            isInTable
                            title={searchValue ? '' : 'You have not added any students!'}
                            subtitle={searchValue ? 'No student matches your search criteria' : 'Add New Student'}
                        >
                            <Button bgColor="#6366F1" color="#fff" onClick={handleAddStudent}>
                                Add New Student
                            </Button>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                isActionable
            />

            <SendOfferList
                show={openModal === 'send-offer'}
                list={selectedStudents}
                subtitle="Are you sure you want to send Admission offer to these people?"
                close={() => setOpenModal('')}
                onSend={handleSendOffer}
                isLoading={isLoading}
            />

            <RevokeOffer
                show={openModal === 'revoke-offer'}
                list={selectedStudents}
                close={() => setOpenModal('')}
                isLoading={isLoading}
                onSend={handleRevokeOffer}
                setInputValue={setInputValue}
                value={inputValue}
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'confirm-delete'}
                title="Delete Student(s)?"
                subtitle="This student(s) will be completely deleted."
                subtitle2="To confirm deletion, enter 'delete' in the text field."
                close={() => setOpenModal('')}
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={studentsLoading}
                btnDisabled={inputValue?.toLowerCase() !== 'delete'}
                placeholder="Input delete"
            />
        </div>
    );
};

export default AdmissionDetails;
