import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../../../components/loader';
import { verifyPayment } from '../../../../redux/ict/actions';

const BillingVerification = () => {
    const { isLoading } = useSelector((store) => store.ict);
    //eslint-disable-next-line
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const url = window.location.href;
    const urlParams = url?.split('=');
    const transactionId = urlParams?.[3];
    const txRef = urlParams?.[2]?.split('&')?.[0];

    const verifyBilling = async () => {
        const res = await dispatch(verifyPayment(transactionId, txRef));
        if (res) {
            navigate('/ict/billing');
        }
    };

    useEffect(() => {
        verifyBilling();
        //eslint-disable-next-line
    }, []);

    if (isLoading) return <Loader />;
    return <div>BillingVerification</div>;
};

export default BillingVerification;
