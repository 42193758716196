import tw, { styled } from 'twin.macro';

import { Text } from '../containers/MesssageContainers';

export const BasicContent = tw.div`
    flex
    justify-between
`;

export const RowContainer = tw.div`
   flex
   flex-col
    gap-[2.4rem]
`;

export const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    font-weight: 500;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    text-transform: 'capitalize';
`;

export const DetailsCardSubText = styled(Text)`
    color: #4b5563;
    font-weight: 500;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-transform: 'capitalize';
`;
