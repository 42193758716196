import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as UploadedFile } from '../../../../assets/icons/uploaded-file.svg';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../../containers/ScreenContainers';
import DetailsBox from '../details-box';

const MainContainer = tw.div`
grid
grid-cols-2
gap-[2.4rem]
mt-8
`;

const Medicals = ({ details }) => {
    const { medicalData } = details || {};

    const medicalInformationLeft = [
        {
            title: 'Height (in feet)',
            subtitle: medicalData?.height,
        },
        {
            title: 'Allergies',
            subtitle: medicalData?.allergic,
        },
        {
            title: 'Genotype',
            subtitle: medicalData?.genotype,
        },
    ];
    const medicalInformationRight = [
        {
            title: 'Blood Group',
            subtitle: medicalData?.bloodGroup,
        },
        {
            title: 'Weight (in Kilogram)',
            subtitle: medicalData?.weight,
        },
        {
            title: 'Rhesus Factor',
            subtitle: medicalData?.rhesus,
        },
    ];
    const disabilitiesLeft = [
        {
            title: 'Learning Disabilities',
            subtitle: medicalData?.disability,
        },
        {
            title: 'Description',
            subtitle: medicalData?.disabilityDescription,
        },
    ];
    const disabilitiesRight = [
        {
            title: 'Special/Long-Term Health Condition',
            subtitle: medicalData?.specialCondition,
        },
    ];
    const specialTreatmentLeft = [
        {
            title: 'Special/Continuous Medical Treatment',
            subtitle: medicalData?.specialTreatment,
        },
        {
            title: 'Description of the Treatment',
            subtitle: 'Nil',
        },
    ];
    const specialTreatmentRight = [
        {
            title: 'Description of the Treatment',
            subtitle: medicalData?.treatmentAddress,
        },
    ];

    return (
        <MainContainer>
            <div className="flex flex-col gap-[2.4rem]">
                <DetailsBox
                    heading="Medical Information"
                    leftData={medicalInformationLeft}
                    rightData={medicalInformationRight}
                />
                <DetailsBox
                    heading="Special/Continuous Medical Treatment"
                    leftData={specialTreatmentLeft}
                    rightData={specialTreatmentRight}
                />
            </div>
            <div className="flex flex-col gap-[2.4rem]">
                <DetailsBox heading="Disabilities" leftData={disabilitiesLeft} rightData={disabilitiesRight} />
                <DetailsBox heading="Test Result Uploads">
                    {Array.isArray(medicalData?.testResult) ? (
                        <>
                            {medicalData?.testResult?.map((result) => (
                                <div
                                    key={result?._id}
                                    className="border-[0.5px] rounded-[8px] border-[#C7D2FE] p-[8px]"
                                >
                                    <FlexCentredRow>
                                        <UploadedFile className="mr-[2.308rem]" />

                                        <FlexCentredCol>
                                            <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                                {result?.fileName}
                                            </Text>
                                            <Text
                                                weight="500"
                                                align="left"
                                                color="#6B7280"
                                                size="1.4rem"
                                                lineHeight="2.4rem"
                                            >
                                                Size - {result?.size}kb
                                            </Text>
                                        </FlexCentredCol>
                                    </FlexCentredRow>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className="border-[0.5px] rounded-[8px] border-[#C7D2FE] p-[8px]">
                            <FlexCentredRow>
                                <UploadedFile className="mr-[2.308rem]" />

                                <FlexCentredCol>
                                    <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                        {medicalData?.testResult}
                                    </Text>
                                </FlexCentredCol>
                            </FlexCentredRow>
                        </div>
                    )}
                </DetailsBox>
            </div>
        </MainContainer>
    );
};

export default Medicals;
