import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    // all courses in organisation
    allCourses: [],
    // all courses in department
    courses: [],
    course: {},
    firstDegreeCourses: [],
};

export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_COURSES: (state, action) => {
            state.allCourses = action.payload;
        },
        GET_COURSES: (state, action) => {
            state.courses = action.payload;
        },
        GET_COURSE: (state, action) => {
            state.course = action.payload;
        },
        GET_COURSES_IN_FIRST_DEGREE: (state, action) => {
            state.firstDegreeCourses = action.payload;
        },
    },
});

export const { GET_COURSES, LOADING, STOP_LOADING, GET_ALL_COURSES, GET_COURSE, GET_COURSES_IN_FIRST_DEGREE } =
    coursesSlice.actions;

export default coursesSlice.reducer;
