import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { getAllotedLecturers, removeLecturerFromCourse } from '../../../redux/courses/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditCourseAllocation = ({ show, setShow, courses, selectedCourse: initialCourse, onFinish }) => {
    const dispatch = useDispatch();

    const onClose = () => setShow(null);
    const { sessions: admissionsSessions } = useSelector((state) => state.admission);
    const { isLoading } = useSelector((state) => state.courses);

    const [allotedLecturers, setAllotedLecturers] = useState([]);
    const [lecturersToRemove, setLecturersToRemove] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [session, setSession] = useState('');

    useEffect(() => {
        const getLecturersData = async () => {
            setAllotedLecturers([]);
            const res = await dispatch(getAllotedLecturers(session, selectedCourse?._id));
            if (res) {
                const lecturers =
                    res?.map((el) => {
                        const fullName = `${el.firstName} ${el.otherName || ''} ${el.surname}`
                            .split(' ')
                            .filter((item) => item)
                            .join(' ');
                        return { ...el, fullName };
                    }) || [];

                setAllotedLecturers([...lecturers]);
            }
        };

        if (session && selectedCourse) {
            getLecturersData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedCourse, session]);

    useEffect(() => {
        setSelectedCourse(initialCourse);
    }, [initialCourse]);

    return (
        <>
            <FormContainer headerIcon={<PlusIcon />} close={onClose} show={show} title={'Edit Course Allocation'}>
                <Formik
                    initialValues={{
                        session: session || '',
                        courseId: selectedCourse?._id || '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        const { session, courseId } = values;
                        const payload = { staffNames: lecturersToRemove.map((item) => item.fullName) };

                        const res = await dispatch(removeLecturerFromCourse(session, courseId, payload));
                        if (res) {
                            toast.success('Course allocation updated successfully!');
                            actions.resetForm();
                            setLecturersToRemove([]);
                            onClose();
                            onFinish?.();
                        }
                    }}
                >
                    {({ errors, setFieldValue }) => (
                        <Form>
                            <div className="gap-5 flex flex-col">
                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    placeholder="Select Session"
                                    label="Session"
                                    data={admissionsSessions}
                                    onChange={(selected) => {
                                        setFieldValue('session', String(selected[0].admissionYear));
                                        setSession(String(selected[0].admissionYear));
                                    }}
                                    error={errors.session}
                                />
                                <Select
                                    searchable
                                    name="courseId"
                                    objProp="title"
                                    placeholder="Select Course"
                                    label="Course"
                                    useComponentState={false}
                                    passedSelectedItems={
                                        selectedCourse
                                            ? [
                                                  {
                                                      ...selectedCourse,
                                                      title: `${selectedCourse.courseTitle} (${selectedCourse.courseCode})`,
                                                  },
                                              ]
                                            : null
                                    }
                                    data={courses?.map((item) => ({
                                        ...item,
                                        title: `${item.courseTitle} (${item.courseCode})`,
                                    }))}
                                    onChange={(selected) => {
                                        setFieldValue('courseId', String(selected[0]._id));
                                        setSelectedCourse(selected[0]);
                                    }}
                                    error={errors.courseId}
                                />
                                <Select
                                    searchable
                                    name="lecturers"
                                    label="Lecturer(s)"
                                    objProp="fullName"
                                    isLoading={isLoading}
                                    useComponentState={false}
                                    data={allotedLecturers || []}
                                    placeholder="Lecturer(s)"
                                    multiSelect
                                    passedSelectedItems={lecturersToRemove}
                                    type="text"
                                    onChange={(selected) => {
                                        setLecturersToRemove(selected);
                                    }}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>

                                <LoadingButton
                                    disabled={!lecturersToRemove.length || isLoading}
                                    loading={isLoading}
                                    type="submit"
                                >
                                    Remove Selected Lecturers
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditCourseAllocation;
