import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    permissions: [],
};

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_PERMISSIONS: (state, action) => {
            state.permissions = action.payload;
        },
    },
});

export const { GET_PERMISSIONS, LOADING, STOP_LOADING } = permissionsSlice.actions;

export default permissionsSlice.reducer;
