import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    category: [],
    appointmentDetails: [],
    staffCount: {},
};

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_APPOINTMENT_CATEGORIES: (state, action) => {
            state.category = action.payload;
        },
        GET_APPOINTMENT_DETAILS: (state, action) => {
            if (typeof action.payload === 'object' && action.payload !== 'undefined') {
                state.appointmentDetails = action.payload;
            } else {
                state.appointmentDetails = [];
            }
        },
        STAFF_COUNT: (state, action) => {
            state.staffCount = action.payload;
        },
        CLEAR_APPOINTMENT_DETAILS: (state, action) => {
            state.appointmentDetails = initialState.appointmentDetails;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_APPOINTMENT_CATEGORIES,
    GET_APPOINTMENT_DETAILS,
    CLEAR_APPOINTMENT_DETAILS,
    STAFF_COUNT,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
