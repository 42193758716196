import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const ExportSheetDialogue = ({ show, close, chooseAction, exportAction }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    Export Sheet
                </Text>
                <CloseIcon onClick={close} />
            </FlexRowSpaceBetween>
            <Text align='start' size="1.6rem" color="#374151" top="1.6rem" bottom="3.2rem">
                Do you want to export all columns?
            </Text>
            <FlexRowEnd className="gap-x-[16px]">
                <Button onClick={chooseAction} color="#6366F1" border="0.5px solid #D1D5DB">
                    Choose Columns
                </Button>
                <Button onClick={exportAction} bgColor="#6366F1" color="#fff">
                    Export
                </Button>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ExportSheetDialogue;
