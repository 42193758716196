import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    distributionList: {},
    allDistributionList: [],
    allColleges: [],
    allFaculties: [],
    allSchools: [],
};

export const distributionListSlice = createSlice({
    name: 'distributionList',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_SINGLE_DISTRIBUTIONLIST: (state, action) => {
            state.distributionList = action.payload;
        },
        GET_ALL_DISTRIBUTION_LIST: (state, action) => {
            state.allDistributionList = action.payload;
        },
        GET_ALL_COLLEGES: (state, action) => {
            state.allColleges = action.payload;
        },
        GET_ALL_FACULTIES: (state, action) => {
            state.allFaculties = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_SINGLE_DISTRIBUTIONLIST,
    GET_ALL_DISTRIBUTION_LIST,
    GET_ALL_COLLEGES,
    GET_ALL_FACULTIES,
} = distributionListSlice.actions;

export default distributionListSlice.reducer;
