import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    sourceSession: Yup.string().nullable().required('Required'),
    sourceSemester: Yup.string().nullable().required('Required'),
    destinationSession: Yup.string()
        .nullable()
        .required('Required')
        .notOneOf([Yup.ref('sourceSession'), null], 'Destination session must not be same as the source session'),
    destinationSemester: Yup.string()
        .nullable()
        .required('Required')
        .oneOf([Yup.ref('sourceSemester'), null], 'Destination semester must match the source semester'),
});
