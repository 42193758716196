import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { createDesignation } from '../../../redux/designations/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddNewDesignationForm = ({ show, setShow }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const { activeOrgUnits } = useSelector((store) => store.organisationUnits);
    const unitsToUse = activeOrgUnits
        ?.filter((item) => item.to)
        ?.sort((a, b) => a?.unitName?.localeCompare(b?.unitName));

    return (
        <>
            <FormContainer close={onClose} show={show} title={'Add Designation'}>
                <Formik
                    initialValues={{
                        designation: '',
                        unitName: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        setLoading(true);
                        const { designation, ...otherValues } = values;

                        const payload = {
                            ...otherValues,
                            designation: designation,
                        };
                        const res = await dispatch(createDesignation(payload));
                        if (res) {
                            actions.resetForm();
                            onClose();
                            toast.success('Designation was created successfully!');
                        }
                        setLoading(false);
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <Select
                                    name="unitName"
                                    objProp="unitName"
                                    placeholder="Select Unit"
                                    label="Select Unit"
                                    data={unitsToUse}
                                    onChange={(selected) => {
                                        setFieldValue('unitName', String(selected[0].unitName));
                                    }}
                                    error={errors.unitName}
                                />
                                <TextInput
                                    name="designation"
                                    type="text"
                                    label="Input Designation Name"
                                    placeholder="Name"
                                    value={values.designation}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={loading} type="submit">
                                    Add
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddNewDesignationForm;
