import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, LoadingButton } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import DatePicker from '../../../../components/inputs/date-picker';
import Select from '../../../../components/inputs/new-select';
import TextAreaInput from '../../../../components/inputs/text-area';
import { FlexCentredCol, FlexRowEnd } from '../../../../containers/ScreenContainers';
import { createCalendarActivity, getWeeksInSemester } from '../../../../redux/academic-calendar/actions';
import { getNumOfWeeksBetweeenDates, range } from '../../../../utils';
import { formatDate } from '../../../../utils/formatDate';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    mt-[2.8rem]
`;

const InputDetails = tw.div`
    grid
    grid-cols-2
`;

const Label = tw.label`
    font-normal
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const CreateAcademicCalendar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const admissionsSessions = useSelector((state) => state.admission.sessions);
    const { isLoading } = useSelector((state) => state.calendar);

    const [semesterData, setSemesterData] = useState({});
    const { startDate, endDate } = semesterData || {};
    const [weeks, setWeeks] = useState([]);

    const [session, setSession] = useState('');
    const [semester, setSemester] = useState('');

    const getWeeks = async () => {
        setWeeks([]);
        const toastId = toast.loading('Loading semester calendar...');
        const data = await dispatch(getWeeksInSemester(session, semester));
        setSemesterData(data);
        toast.dismiss(toastId);
        setWeeks(data?.data);
    };

    const numOfWeeksInSemester = getNumOfWeeksBetweeenDates(startDate, endDate);
    const weeksArray = numOfWeeksInSemester ? range(numOfWeeksInSemester) : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

    const getDate = (date, alternateDate) => {
        if (weeks?.length && date) return new Date(date);
        return new Date(alternateDate);
    };

    useEffect(() => {
        if (session && semester) {
            getWeeks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, session, semester]);

    return (
        <>
            <GoBack title="Academic Calendar" subtitle="Create Calendar" />

            <Formik
                initialValues={{
                    // calendar: [
                    //     {
                    academicSession: '',
                    semester: '',
                    startDate: '',
                    endDate: '',
                    studentType: '',
                    week: '',
                    description: '',
                    //     },
                    // ],
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const payload = {
                        ...values,
                        startDate: formatDate(values.startDate),
                        endDate: formatDate(values.endDate),
                    };
                    const res = await dispatch(createCalendarActivity(payload));
                    if (res) {
                        actions.resetForm();
                        toast.success('The academic calendar has been created');
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                    <>
                        <Form>
                            <MainContainer>
                                <>
                                    <InputDetails className="gap-[3.2rem]">
                                        <Select
                                            name="academicSession"
                                            objProp="admissionYear"
                                            placeholder="Session"
                                            label="Session"
                                            data={admissionsSessions}
                                            onChange={(selected) => {
                                                setFieldValue('academicSession', String(selected[0].admissionYear));
                                                setSession(String(selected[0].admissionYear));
                                            }}
                                            error={errors.academicSession}
                                        />
                                        <Select
                                            name="studentType"
                                            objProp="name"
                                            placeholder="Student Type"
                                            label="Student Type"
                                            data={['Undergraduate', 'Postgraduate'].map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('studentType', String(selected[0].name));
                                            }}
                                            error={errors.studentType}
                                        />
                                        {values.academicSession && (
                                            <Select
                                                name="semester"
                                                objProp="name"
                                                placeholder="Semester"
                                                label="Semester"
                                                data={['First', 'Second'].map((item) => ({ name: item }))}
                                                onChange={(selected) => {
                                                    setFieldValue('semester', String(selected[0].name?.toLowerCase()));
                                                    setSemester(String(selected[0].name?.toLowerCase()));
                                                }}
                                                error={errors.semester}
                                            />
                                        )}
                                        {values.semester && (
                                            <Select
                                                name="week"
                                                objProp="name"
                                                placeholder="Week"
                                                label="Week"
                                                data={weeksArray?.map((item) => ({
                                                    name: item,
                                                }))}
                                                onChange={(selected) => {
                                                    setFieldValue('week', String(selected[0].name));
                                                }}
                                                error={errors.week}
                                            />
                                        )}
                                        {values.week && (
                                            <>
                                                <FlexCentredCol>
                                                    <Label>Start Date</Label>
                                                    <DatePicker
                                                        name="startDate"
                                                        touched={touched}
                                                        minDate={getDate(
                                                            weeks?.[values.week - 1]?.startDate,
                                                            startDate,
                                                        )}
                                                        maxDate={getDate(weeks?.[values.week - 1]?.endDate, endDate)}
                                                    />
                                                </FlexCentredCol>
                                                <FlexCentredCol>
                                                    <Label>End Date</Label>
                                                    <DatePicker
                                                        name="endDate"
                                                        touched={touched}
                                                        minDate={new Date(values.startDate)}
                                                        maxDate={getDate(weeks?.[values.week - 1]?.endDate, endDate)}
                                                    />
                                                </FlexCentredCol>
                                            </>
                                        )}
                                    </InputDetails>

                                    <FlexCentredCol className="mt-[3.2rem]">
                                        <TextAreaInput
                                            name="description"
                                            label="Description"
                                            placeholder="Enter text here"
                                            errorMessage={errors.description}
                                            onChange={(e) => {
                                                setFieldValue('description', e.target.value);
                                            }}
                                            value={values.description}
                                            height="6rem"
                                        />
                                    </FlexCentredCol>
                                </>

                                <FlexRowEnd className="gap-[2.4rem] my-[3.6rem]">
                                    <Button
                                        bgColor="#fff"
                                        color="#1F2937"
                                        border="0.5px solid #D1D5DB"
                                        type="button"
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                        Create
                                    </LoadingButton>
                                </FlexRowEnd>
                            </MainContainer>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateAcademicCalendar;
