import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ChevronGrey } from '../../../../../assets/icons/arrow-down2.svg';
import { ReactComponent as ImportIcon } from '../../../../../assets/icons/download.svg';
import { ReactComponent as Dropdown } from '../../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit3.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus-blue.svg';
import { ActionsPopup } from '../../../../../components';
import { Button } from '../../../../../components/buttons';
import AllocateCourse from '../../../../../components/forms/allocate-course';
import EditCourseAllocation from '../../../../../components/forms/edit-course-allocation';
import ImportAllotedLecturers from '../../../../../components/forms/import-course-allocation';
import { GoBack } from '../../../../../components/go-back';
import Checkbox from '../../../../../components/inputs/checkbox';
import SearchInput from '../../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../../components/items-per-page';
import ListEmptyContent from '../../../../../components/list-empty';
import { PageTitle } from '../../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexColumn,
    FlexDiv,
    FlexRowEnd,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../../../containers/ScreenContainers';
import { getAllotedLecturers } from '../../../../../redux/courses/actions';
import { getCoursesInSecondDegree, getDepartmentStaff } from '../../../../../redux/departments/actions';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Name',
        key: 'surname',
    },
    {
        label: 'Course Code',
        key: 'courseCode',
    },
    {
        label: 'Level',
        key: 'courseLevel',
    },
    {
        label: 'Semester',
        key: 'courseSemester',
    },
];

const FilterContainer = tw(FlexDiv)`
  p-[16px]
  gap-x-4
`;
// NOT IN USE? Delete
const AllotedHistory = () => {
    const dispatch = useDispatch();
    const { id: courseId } = useParams();
    const { state: courseData } = useLocation();
    const { sessions: admissionsSessions, activeSession } = useSelector((state) => state.admission);
    const { currentFaculty } = useSelector((state) => state.faculty);
    const { isLoading, courses, currentDepartment } = useSelector((state) => state.department);
    const { isLoading: coursesLoading } = useSelector((state) => state.courses);

    const { academicStructureId, _id: facultyId } = currentFaculty;
    const { _id: departmentId } = currentDepartment || {};

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(courses?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [session, setSession] = useState(activeSession || '');
    const [openModal, setOpenModal] = useState(null);
    const [lecturers, setLecturers] = useState([]);

    const handleOpenModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const getLecturersData = async () => {
        setLecturers([]);
        const res = await dispatch(getAllotedLecturers(session, courseId));
        if (res) {
            setLecturers(res);
        }
    };

    useEffect(() => {
        if (departmentId) {
            dispatch(getCoursesInSecondDegree(academicStructureId, facultyId, departmentId));
            dispatch(getDepartmentStaff(academicStructureId, departmentId));
        }
    }, [dispatch, departmentId, academicStructureId, facultyId]);

    useEffect(() => {
        if (session && courseId) {
            getLecturersData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, courseId, session]);

    return (
        <>
            <FlexRowSpaceBetween>
                <GoBack title="Course Allocation" subTitle="Allocation History" />
                <FlexRowEnd className="gap-3">
                    <Button color="#6366F1" bgColor="#FFF" onClick={() => setOpenModal('allocate')}>
                        <PlusIcon />
                        Allocate Course
                    </Button>

                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => handleOpenModal('actions')}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openModal === 'actions'}
                            close={() => setOpenModal(null)}
                            items={[
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Course Allocation',
                                    click: () => {
                                        setOpenModal('edit');
                                    },
                                },
                                {
                                    icon: <ImportIcon />,
                                    name: 'Import Allocation',
                                    click: () => setOpenModal('import'),
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <FlexColumn className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle align="start">
                    {courseData?.courseTitle} ({courseData?.courseCode})
                </PageTitle>
            </FlexColumn>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <RelativeContainer>
                        <Button
                            bgColor="#FFF"
                            color="#6B7280"
                            border="0.5px solid #9CA3AF"
                            onClick={() => setOpenModal('sessions')}
                        >
                            {session || 'Select Session'}
                            <ChevronGrey />
                        </Button>
                        <div className="absolute left-[200px] top-0 ">
                            <RelativeContainer>
                                <ActionsPopup
                                    open={openModal === 'sessions'}
                                    close={() => setOpenModal(null)}
                                    items={admissionsSessions?.map((item) => ({
                                        name: item.admissionYear,
                                        click: () => {
                                            setSession(item.admissionYear);
                                            setOpenModal(null);
                                        },
                                    }))}
                                />
                            </RelativeContainer>
                        </div>
                    </RelativeContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} withRadius value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                <CDataTable
                    items={lecturers || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No allocation yet"></ListEmptyContent>
                        </CenteredContainer>
                    }
                    clickableRows
                    loading={isLoading || coursesLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        surname: (item) => (
                            <td
                            // style={{ color: '#2563EB' }}
                            >{`${item.surname} ${item.firstName} ${item.otherName || ''}`}</td>
                        ),

                        courseSemester: (item) => (
                            <td style={{ textTransform: 'capitalize' }} className="capitalize">
                                {item?.courseSemester?.split(' ')[0]?.toLowerCase()}
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <AllocateCourse
                show={openModal === 'allocate'}
                setShow={setOpenModal}
                courses={courses || []}
                selectedCourse={courseData}
                onFinish={() => getLecturersData()}
            />

            <EditCourseAllocation
                show={openModal === 'edit'}
                setShow={setOpenModal}
                courses={courses || []}
                selectedCourse={courseData}
                onFinish={() => getLecturersData()}
            />

            <ImportAllotedLecturers
                show={openModal === 'import'}
                setShow={setOpenModal}
                onFinish={() => getLecturersData()}
            />
        </>
    );
};

export default AllotedHistory;
