import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { parseJSON } from '../utils/useJSON';

import Content from './Content';
import Header from './Header';
import InnerSidebar from './InnerSidebar';
import {
    admissionsNavLinks,
    admissionsRoutes,
    registryNavLinks,
    registryRoutes,
    ictRoutes,
    ictNavLinks,
    departmentNavLinks,
    deptNavlinksFromThirdDegree,
    // departmentRoutes,
    programmeRoutes,
    programmeNavLinks,
    staffArenaRoutes,
    staffArenaNavLinks,
    facultyNavLinks,
    facultyRoutes,
    examsRecordsNavLinks,
    examsRecordsRoutes,
    personnelNavLinks,
    personnelRoutes,
    bursaryNavLinks,
    bursaryRoutes,
    studentAffairsRoutes,
    studentAffairsNavLinks,
    clinicRoutes,
    clinicNavLinks,
    sportsRoutes,
    sportsNavLinks,
    libraryRoutes,
    libraryNavLinks,
    academicPlanningRoutes,
    auditRoutes,
    auditNavLinks,
    assuranceQualityRoutes,
    assuranceQualityNavLinks,
    securityRoutes,
    securityNavLinks,
    worksRoutes,
    worksNavLinks,
    academicPlanningNavLinks,
} from './unit-nav-links';

// import Sidebar from './Sidebar';
const Layout = () => {
    const { currentFaculty } = useSelector((state) => state.faculty);
    const { splitAcademicStructureTwo, splitAcademicStructureThree } = currentFaculty;

    const token = parseJSON('token', '');
    const isAuthenticated = token ? true : false;
    const [filteredStaffArenaNavLinks, setFilteredStaffArenaNavLinks] = useState([]);
    const { staffData } = useSelector((state) => state.user);
    const { appointmentId = '' } = staffData || {};

    const modifiedFacultyNavLinks = useMemo(
        () =>
            facultyNavLinks?.map((item) => {
                const modifiedTitle =
                    item?.title === 'Departments'
                        ? splitAcademicStructureTwo === 'Faculty'
                            ? 'Faculties'
                            : `${splitAcademicStructureTwo}s`
                        : item?.title;
                return { ...item, title: modifiedTitle };
            }),
        [splitAcademicStructureTwo],
    );

    const modifiedDepartmentNavLinks = useMemo(() => {
        if (!splitAcademicStructureThree) {
            const newLinks = departmentNavLinks.slice(1);
            newLinks.splice(1, 0, ...deptNavlinksFromThirdDegree);

            return newLinks;
        } else {
            return departmentNavLinks.map((item) => ({
                ...item,
                title: item?.title === 'Programmes' ? `${splitAcademicStructureThree}s` : item?.title,
            }));
        }
    }, [splitAcademicStructureThree]);

    useEffect(() => {
        if (!appointmentId) {
            const filteredNavLinks = staffArenaNavLinks?.filter((item) => item?.title !== 'Office');
            setFilteredStaffArenaNavLinks(filteredNavLinks);
        } else {
            setFilteredStaffArenaNavLinks(staffArenaNavLinks);
        }
    }, [appointmentId]);

    return isAuthenticated ? (
        <div className="flex flex-col w-full h-screen">
            <Header />
            {/* <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} /> */}
            <div className="flex w-full h-full">
                <InnerSidebar
                    links={facultyRoutes}
                    showBackArrow
                    navLinks={modifiedFacultyNavLinks}
                    backLink="academics/faculties"
                />
                <InnerSidebar
                    // links={departmentRoutes}
                    links={modifiedDepartmentNavLinks.map((item) => item?.to)}
                    showBackArrow
                    navLinks={modifiedDepartmentNavLinks}
                    backLink="faculties/departments"
                />
                <InnerSidebar
                    links={programmeRoutes}
                    navLinks={programmeNavLinks}
                    backLink="faculties/department/programmes"
                    showBackArrow
                />
                <InnerSidebar links={personnelRoutes} navLinks={personnelNavLinks} backLink="faculties/departments" />
                <InnerSidebar links={bursaryRoutes} navLinks={bursaryNavLinks} backLink="bursary/fees" />
                <InnerSidebar links={admissionsRoutes} navLinks={admissionsNavLinks} backLink="admissions/sessions" />
                <InnerSidebar links={examsRecordsRoutes} navLinks={examsRecordsNavLinks} backLink="exams-records" />
                <InnerSidebar
                    links={studentAffairsRoutes}
                    navLinks={studentAffairsNavLinks}
                    backLink="student-affairs"
                />
                <InnerSidebar links={clinicRoutes} navLinks={clinicNavLinks} backLink="clinic/home" />
                <InnerSidebar links={sportsRoutes} navLinks={sportsNavLinks} backLink="sports" />
                <InnerSidebar links={ictRoutes} navLinks={ictNavLinks} backLink="ict" />
                <InnerSidebar links={libraryRoutes} navLinks={libraryNavLinks} backLink="library" />
                <InnerSidebar links={registryRoutes} navLinks={registryNavLinks} backLink="registry/records" />
                <InnerSidebar links={staffArenaRoutes} navLinks={filteredStaffArenaNavLinks} backLink="staff-arena" />
                <InnerSidebar
                    links={academicPlanningRoutes}
                    navLinks={academicPlanningNavLinks}
                    backLink="academic-planning"
                />
                <InnerSidebar links={auditRoutes} navLinks={auditNavLinks} backLink="audit" />
                <InnerSidebar
                    links={assuranceQualityRoutes}
                    navLinks={assuranceQualityNavLinks}
                    backLink="quality-assurance"
                />
                <InnerSidebar links={securityRoutes} navLinks={securityNavLinks} backLink="security" />
                <InnerSidebar links={worksRoutes} navLinks={worksNavLinks} backLink="works" />
                <main className="w-full px-[5rem] pb-[5rem] mt-[5rem] overflow-y-scroll">
                    <Content />
                </main>
            </div>
        </div>
    ) : (
        <Navigate to="/sign-in-staff" replace />
    );
};

export default Layout;
