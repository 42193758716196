import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    fullName: Yup.string().nullable().required('Required'),
    companyName: Yup.string().nullable().required('Required'),
    address: Yup.string().nullable().required('Required'),
    email: Yup.string().nullable().required('Required'),
    phoneNumber: Yup.string().nullable().required('Required'),
    websiteUrl: Yup.string().nullable().required('Required'),
});
