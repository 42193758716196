import React from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexColumn, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

const MainContainer = tw.div`
    h-auto
    bg-white
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
    mb-[0.2rem]
`;
const BlockDetails = tw.div`
    h-auto
    bg-[#F3F4F6]
    lg:p-[2.4rem]
    rounded-lg
    mb-[7rem]
`;
const BlockGrid = tw.div`
    grid
    grid-cols-10
    gap-[1.6rem]
`;
const Room = tw.div`
bg-[#2563EB]
w-[5.6rem]
h-[5.6rem]
rounded
flex
items-center
justify-center
`;
const Span = tw.span`
    ml-[0.2rem]
`;

const HostelDetails = () => {
    const navigate = useNavigate();

    return (
        <>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[2.8rem]">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Hostel Management
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                        /
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                        Adeniyi Hostel
                    </PageTitle>
                </FlexCentredRow>
                <Button onClick={() => navigate('/hostel/hostel-list/room-details/:id')} bgColor="#6366F1" color="#FFF">
                    Edit
                </Button>
            </FlexRowSpaceBetween>
            <MainContainer>
                <PageTitle align="left" bottom="3.2rem">
                    Adeniyi Hostel
                </PageTitle>
                <FlexColumn>
                    <Text weight="500" align="left" bottom="0.8rem">
                        Number of Rooms:
                        <Span>100</Span>
                    </Text>
                    <Text weight="500" align="left" bottom="0.8rem">
                        Average number of spaces:
                        <Span>5</Span>
                    </Text>
                    <Text weight="500" align="left" bottom="3.2rem">
                        Gender:
                        <Span>Male</Span>
                    </Text>
                </FlexColumn>
                <BlockDetails>
                    <FlexCentredRow className="mb-[3.2rem] gap-[4.4rem]">
                        <BlockGrid>
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                                25, 26, 27, 28, 29, 30,
                            ].map((_, idx) => (
                                <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                    <Text color="#FFF">1</Text>
                                </Room>
                            ))}
                        </BlockGrid>
                        <FlexCentredCol className="m-auto">
                            <PageTitle align="left" size="4.8rem" weight="500" lineHeight="5.8rem" color="#4B5563">
                                Block A
                            </PageTitle>
                            <Text align="left" size="1.6rem" weight="500" lineHeight="2.8rem">
                                Room 1-30
                            </Text>
                        </FlexCentredCol>
                    </FlexCentredRow>
                    <FlexCentredRow className="mb-[3.2rem] gap-[4.4rem]">
                        <BlockGrid>
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                                25, 26, 27, 28, 29, 30,
                            ].map((_, idx) => (
                                <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                    <Text color="#FFF">1</Text>
                                </Room>
                            ))}
                        </BlockGrid>
                        <FlexCentredCol className="m-auto">
                            <PageTitle align="left" size="4.8rem" weight="500" lineHeight="5.8rem" color="#4B5563">
                                Block B
                            </PageTitle>
                            <Text align="left" size="1.6rem" weight="500" lineHeight="2.8rem">
                                Room 1-30
                            </Text>
                        </FlexCentredCol>
                    </FlexCentredRow>
                    <FlexCentredRow className=" mb-[3.2rem] gap-[4.4rem]">
                        <BlockGrid>
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                                25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
                            ].map((_, idx) => (
                                <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                    <Text color="#FFF">1</Text>
                                </Room>
                            ))}
                        </BlockGrid>
                        <FlexCentredCol className="m-auto">
                            <PageTitle align="left" size="4.8rem" weight="500" lineHeight="5.8rem" color="#4B5563">
                                Block C
                            </PageTitle>
                            <Text align="left" size="1.6rem" weight="500" lineHeight="2.8rem">
                                Room 1-40
                            </Text>
                        </FlexCentredCol>
                    </FlexCentredRow>
                </BlockDetails>
            </MainContainer>
        </>
    );
};

export default HostelDetails;
