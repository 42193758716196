import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PlusWhite } from '../../assets/icons/plus-white.svg';
import { Button } from '../../components/buttons';
import ListEmptyContent from '../../components/list-empty';
import { CenteredContainer, FlexCentredRow } from '../../containers/ScreenContainers';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../redux/academic-structure/actions';
import { getActiveSession, getAdmissionSessions } from '../../redux/admissions/actions';
import { getAllFees } from '../../redux/bursary/actions';
import { filterData } from '../../utils/filterData';
import { storeUnit } from '../../utils/originUnit';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Fee',
        accessorKey: 'fee',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'No of Fees',
        accessorKey: 'count',
    },
];

const Bursary = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allFees, isLoading } = useSelector((store) => store.bursary);
    const { sessions, activeSession } = useSelector((store) => store.admission);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [session, setSession] = useState(activeSession);
    const [openModal, setOpenModal] = useState(null);

    useEffect(() => {
        dispatch(getActiveSession());
        dispatch(getSavedAcademicStructure());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        storeUnit(unitName);
        //eslint-disable-next-line
    }, []);

    const memoizedBursaryData = useMemo(() => {
        if (session) {
            const filteredBursaryData = filterData(allFees, session);
            return filteredBursaryData;
        } else {
            return allFees;
        }
    }, [session, allFees]);

    const AllFees = memoizedBursaryData?.map((fee) => {
        return {
            id: fee?._id,
            count: fee?.noOfFee,

            fee: (
                <td style={{ color: '#2563EB' }} className="cursor-pointer">
                    {fee?.feeType.replace('-', ' ')}
                </td>
            ),
            ...fee,
        };
    });

    useEffect(() => {
        dispatch(getAdmissionSessions());
        //eslint-disable-next-line
    }, []);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.bursarySearch = debouncedSearchValue;
        }
        if (session) {
            queryParams.session = session;
        }

        return queryParams;
    }, [debouncedSearchValue, session]);

    useEffect(() => {
        dispatch(getAllFees(query));
    }, [dispatch, query]);

    return (
        <div className="">
            <PageLayout
                pageTitle="Fees"
                secondaryButtonText="Add Fee"
                onSecondaryButtonClick={() => navigate('/bursary/fees/add-fee')}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                sessionItems={sessions?.map((session) => ({
                    name: session?.admissionYear,
                    click: () => {
                        setSession(session?.admissionYear);
                        setOpenModal(null);
                    },
                }))}
                sessionValue={session}
                openSessionFilter={openModal === 'session'}
                onSessionFilterClick={() => setOpenModal('session')}
                closeSessionFilter={() => setOpenModal(null)}
                data={AllFees || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title={searchValue ? '' : 'You have not created Fees yet!'}
                            subtitle={searchValue ? 'No fees exist that match your search' : 'Create Fee'}
                        >
                            {!searchValue && (
                                <FlexCentredRow className="gap-x-4">
                                    <Button
                                        bgColor="#6366F1"
                                        color="#fff"
                                        onClick={() => navigate('/bursary/add-fee', { state: session })}
                                    >
                                        <PlusWhite />
                                        Add Fee
                                    </Button>
                                </FlexCentredRow>
                            )}
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => navigate(`${item.feeType}`)}
            />
        </div>
    );
};

export default Bursary;
