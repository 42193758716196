import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { PageTitle, Text } from '../../../containers/MesssageContainers';

import ChangePassword from './change-password';

const Card = tw.div`
    bg-white
    p-[2.4rem]
    mb-[2.4rem]
    no-underline
    cursor-pointer
`;

const ITEMS = [
    // {
    //     title: 'Themes',
    //     subtitle: 'Switch between light or dark themes',
    // },
    {
        title: 'Change Password',
        subtitle: 'Ensure account protection with easy password modification',
    },
    // {
    //     title: 'Font Size',
    //     subtitle: 'Customize readability with adjustable font size preferences',
    // },
    {
        title: 'File Sharing',
        subtitle: 'Customize file sharing preference',
    },
];

const Preference = () => {
    const navigate = useNavigate();
    const [changePassword, setChangePassword] = useState(false);

    const handleClick = (title) => {
        if (title === 'Change Password') {
            setChangePassword(!changePassword);
        } else if (title === 'File Sharing') {
            navigate('/staff-arena/preference/file-sharing');
        }
    };
    return (
        <>
            <div>
                <PageTitle bottom="4rem" align="left">
                    Preference
                </PageTitle>
                {ITEMS.map((item, i) => (
                    <Card key={i} onClick={() => handleClick(item.title)}>
                        <Text weight="500" align="left" size="1.4rem" bottom="0.4rem" lineHeight="2.4rem">
                            {item.title}
                        </Text>

                        <Text color="#9CA3AF" weight="400" align="left" size="1.2rem" lineHeight="1.7rem">
                            {item.subtitle}
                        </Text>
                    </Card>
                ))}
            </div>
            <ChangePassword show={changePassword} close={() => setChangePassword(false)} />
        </>
    );
};

export default Preference;
