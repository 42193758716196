import React from 'react';

import { Form, Formik } from 'formik';

import { ReactComponent as CommentIcon } from '../../../assets/icons/comment.svg';
import { ReactComponent as DropDown } from '../../../assets/icons/dropdown.svg';
import { LoadingButton } from '../../../components/buttons';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn } from '../../../containers/ScreenContainers';

const ActivityFeed = ({ activities, commsType, commsCreatedDate, commsCreatedTime, initiator }) => {
    return (
        <div>
            <div className="bg-white mt-[3rem] rounded-[16px] pb-[3rem]">
                <div className="flex items-center justify-between p-[2.4rem]">
                    <Text align="left" size="1.9rem" weight="500">
                        Activity Feed
                    </Text>
                    <DropDown />
                </div>
                <div className="p-[2.4rem] border-b border-b-[#E5E7EB] mx-[2.4rem]">
                    <FlexCentredRow className="gap-[3.2rem]">
                        <Text align="left" weight="600" size="1.4rem">
                            {commsType} Created by {initiator}
                        </Text>
                        <Text>{commsCreatedDate}</Text>
                        <Text>{commsCreatedTime}</Text>
                    </FlexCentredRow>
                </div>
                {activities?.map((activity, i) => (
                    <div key={i} className="p-[2.4rem] border-b border-b-[#E5E7EB] mx-[2.4rem]">
                        <FlexColumn>
                            {activity?.comments && (
                                <FlexCentredRow className="gap-[8px]">
                                    <CommentIcon />
                                    <Text>{activity?.comments}</Text>
                                </FlexCentredRow>
                            )}
                            <FlexCentredRow className="gap-[3.2rem]">
                                {activity?.status === 'Approved' ? (
                                    <span className="text-[1.4rem] font-normal text-[#1F2937] text-left">
                                        <span className="font-semibold capitalize">
                                            {activity?.reviewerInfo?.[0]?.designation}
                                        </span>
                                        <span className="text-[#059669]"> {activity?.status}</span>
                                        <span> this {commsType}</span>
                                    </span>
                                ) : (
                                    <Text
                                        align="left"
                                        weight="600"
                                        size="1.4rem"
                                        color="#1F2937"
                                        transform="capitalize"
                                    >
                                        {activity?.reviewerInfo?.[0]?.designation}
                                    </Text>
                                )}
                                <Text>{new Date(activity?.createdAt).toLocaleDateString()}</Text>
                                <Text>{new Date(activity?.createdAt).toLocaleTimeString()}</Text>
                            </FlexCentredRow>
                        </FlexColumn>
                    </div>
                ))}
                <div className="mx-[2.4rem] mt-[1.6rem] border-[#4B5563] bg-[#F9FAFB] h-[12.4rem] rounded-[8px] border-[0.3px]">
                    <Formik
                        initialValues={{ comment: '' }}
                        onSubmit={async (values, actions) => {
                            console.log(values);
                        }}
                    >
                        {({ errors, handleChange, values }) => (
                            <Form>
                                <textarea
                                    type="text"
                                    name="comment"
                                    onChange={handleChange}
                                    placeholder="Leave a comment..."
                                    rows={4}
                                    className="bg-inherit focus-none outline-none focus:outline-none w-full h-full p-[1rem]"
                                />
                                <div className="my-[0.5px] px-[1rem]">
                                    <LoadingButton border="0.5px solid #D1D5DB" className="ml-auto">
                                        Comment
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ActivityFeed;
