export const securityNavLinks = [
    {
        title: 'Communications',
        to: '/security/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/security/communications/compose',
            },
            {
                title: 'Received',
                to: '/security/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/security/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/security/communications/settings',
            },
        ],
    },
    { title: 'Access', to: '/security/access' },
];

export const securityRoutes = securityNavLinks.map((item) => item.to);
