import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    session: Yup.string().nullable(),
    discountName: Yup.string().nullable(),
    discountType: Yup.string().nullable(),
    // discountApplicableTo: Yup.array()
    //     .of(Yup.string().required('Invalid fee selected')) // Ensure each fee is a valid string
    //     .min(1, 'At least one applicable fee must be selected'),
    discountApplicableTo: Yup.array().min(1, 'At least one applicable fee must be selected').required('Required'),
    discountPercentage: Yup.string().nullable(),
    discountDescription: Yup.string().nullable(),
});
