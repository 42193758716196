import React from 'react';

import { Form, Formik } from 'formik';

import { LoadingButton } from '../../buttons';
import FormikTextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const AddPaymentDetails = () => {
    return (
        <div>
            <Formik
                initialValues={{
                    cardNumber: '',
                    expDate: '',
                    cvc: '',
                    name: '',
                    zipcode: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, action) => {
                    console.log(values);
                }}
            >
                {({ handleChange, errors }) => (
                    <Form className="pt-[1rem] w-[56rem] flex flex-col gap-[2.4rem]">
                        <FormikTextInput
                            label="Card Number"
                            name="cardNumber"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <div className="flex items-center gap-[2.4rem]">
                            <FormikTextInput
                                label="Expiration Date (mm/yy)"
                                name="expDate"
                                labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                                onChange={handleChange}
                                errors={errors}
                            />
                            <FormikTextInput
                                label="CVC"
                                name="cvc"
                                labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                                onChange={handleChange}
                                errors={errors}
                            />
                        </div>
                        <FormikTextInput
                            label="Name on Card"
                            name="name"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <FormikTextInput
                            label="Zipcode"
                            name="zipcode"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <LoadingButton bgColor="#6366F1" color="#fff" className="ml-[auto] mt-[0.8rem]">
                            Save
                        </LoadingButton>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddPaymentDetails;
