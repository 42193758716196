import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_PERMISSIONS, LOADING, STOP_LOADING } from './slice';

export const getPermissions = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}permission/${currentUnit?._id}/get_all_permission`);
        if (response.status === 200) {
            dispatch(GET_PERMISSIONS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPermissionsInUnit = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}permission/${currentUnit?._id}/all_permissions_in_unit`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
