import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    orgUnits: [],
    filteredActiveOrgUnits: [],
    activeOrgUnits: [],
    currentUnit: {},
};

export const organisationUnitsSlice = createSlice({
    name: 'organisationUnits',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ACTIVE_ORGANISATION_UNITS: (state, action) => {
            state.activeOrgUnits = action.payload;
            state.filteredActiveOrgUnits = action.payload
                ?.filter((item) => item.to)
                ?.sort((a, b) => a?.unitName?.localeCompare(b?.unitName));
        },
        GET_ORGANISATION_UNITS: (state, action) => {
            state.orgUnits = action.payload;
        },
        SAVE_CURRENT_UNIT: (state, action) => {
            state.currentUnit = action.payload;
        },
    },
});

export const { GET_ORGANISATION_UNITS, GET_ACTIVE_ORGANISATION_UNITS, LOADING, STOP_LOADING, SAVE_CURRENT_UNIT } =
    organisationUnitsSlice.actions;

export default organisationUnitsSlice.reducer;
