// const developmentFlag = process.env.REACT_APP_ENV;

export const departmentNavLinks = [
    {
        title: 'Programmes',
        to: '/faculties/department/programmes',
    },
    {
        title: 'Courses',
        to: '/faculties/department/courses',
    },
    {
        title: 'Lecture Venue',
        to: '/faculties/department/lecture-venue',
    },
    {
        title: 'Students',
        to: '/faculties/department/students',
    },
    {
        title: 'Course Allocation',
        to: '/faculties/department/course-allocation',
    },
    {
        title: 'Programme Results',
        to: '/faculties/department/results',
    },

    {
        title: 'Timetable',
        to: '/faculties/department/time-table',
    },

    {
        title: 'Communications',
        to: '/department/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/department/communications/compose',
            },
            {
                title: 'Received',
                to: '/department/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/department/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/department/communications/settings',
            },
        ],
    },
    {
        title: 'Access',
        to: '/faculties/department/access',
    },
    {
        title: 'Settings',
        to: '/faculties/department/settings',
    },
];

export const deptNavlinksFromThirdDegree = [
    {
        title: 'Curriculum',
        to: '/faculties/department/curriculum',
    },
    {
        title: 'Course Form Management',
        to: '/faculties/department/course-form-management',
    },
    // UNCOMMENT WHEN NEEDED 
    // {
    //     title: 'Examination Management',
    //     to: '/faculties/department/exam-management',
    //     subMenu: [
    //         {
    //             title: 'Broadsheet',
    //             to: '/faculties/department/exam-management/broadsheet',
    //         },
    //         {
    //             title: 'Summary Sheet',
    //             to: '/faculties/department/exam-management/summary-sheet',
    //         },
    //         {
    //             title: 'Probation List',
    //             to: '/faculties/department/exam-management/probation-list',
    //         },
    //         {
    //             title: 'Withdrawal Sheet',
    //             to: '/faculties/department/exam-management/withdrawal-sheet',
    //         },
    //         {
    //             title: 'Incomplete Result',
    //             to: '/faculties/department/exam-management/incomplete-result',
    //         },
    //     ],
    // },
];

// export const departmentRoutes = departmentNavLinks.map((item) => item.to);
