import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import backArrow from '../../../assets/icons/arrow-back-blue.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { resetPassword } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './validationSchema';

const PasswordConfirmation = ({ setOpenSuccess }) => {
    const dispatch = useDispatch();
    const { token } = useParams();

    const { isLoading } = useSelector((state) => state.auth);

    const searchParams = new URLSearchParams(window.location.search);
    const userType = searchParams.get('userType');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const res = await dispatch(resetPassword(token, values, userType));
                    res && setOpenSuccess(true);
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                       <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <div className="">

                            <img src={backArrow} alt="" className='mb-12'/>
                                <div className="">
                                    <Text size="2.5rem" weight="600" bottom="1rem" align="left">
                                        Choose New Password
                                    </Text>
                                    <p className="font- text-[#9CA3AF] text-[12px] mb-16">
                                    Choose a new password
                            </p>

                                </div>

                                <div className="grid grid-row-6 gap-6">
                                    <TextInput
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        inputType="password"
                                        placeholder="New Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowPassword((prevState) => !prevState)}
                                    />
                                    <TextInput
                                        name="confirmPassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        inputType="password"
                                        placeholder="Confirm New Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowConfirmPassword((prevState) => !prevState)}
                                    />
                                </div>
                            </div>

                            <FlexRowEnd className="mt-12">
                                <LoadingButton type="submit" loading={isLoading}>
                                   Reset Password
                                </LoadingButton>
                            </FlexRowEnd>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PasswordConfirmation;
