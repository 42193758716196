/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { SecondaryButton } from '../../../../components/buttons';
import { AllFilter } from '../../../../components/filter-select';
import { GoBack } from '../../../../components/go-back';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../../containers/ScreenContainers';
import { facultyPersonnelData } from '../../../../data/facultyPersonnel';
import { getDesignationDetails } from '../../../../redux/designations/actions';
const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Name',
        key: 'fullName',
    },
    {
        label: 'Organization Unit',
        key: 'organizationUnit',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const DesignationDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { isLoading } = useSelector((state) => state.designations);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [details, setDetails] = useState({});

    const { designation, staffs } = details || {};
    const totalPages = Math.ceil(facultyPersonnelData?.length / itemsPerPage);

    const getDetails = async () => {
        const res = await dispatch(getDesignationDetails(id));
        if (res) setDetails(res);
    };

    useEffect(() => {
        if (id) {
            getDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    return (
        <>
            <GoBack title="Designations" subtitle={designation} />

            <PageTitle top="2.8rem" align="start">
                {designation}
            </PageTitle>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>
                </FilterContainer>
                <CDataTable
                    items={staffs || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Organization units "></ListEmptyContent>
                        </CenteredContainer>
                    }
                    clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </>
    );
};

export default DesignationDetails;
