/* eslint-disable no-unused-vars */
import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down2.svg';
import { Text } from '../../../containers/MesssageContainers';
import TextInput from '../text-input-with-formik';

const CodeBox = tw.div`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    w-32
    h-[4rem]
    justify-around
    rounded
    leading-6
    font-normal
    text-[1.4rem]
    flex
    items-center
    border-r-0
`;

const PhoneNumberInput = (props) => {
    return (
        <div className="flex items-start w-full">
            {/* {props.countryCode && <CodeBox>
                <Text>+234</Text>
                <ArrowIcon className='w-4' />
            </CodeBox>} */}
            <TextInput className="rounded-l-none" {...props} />
        </div>
    );
};

export default PhoneNumberInput;
