import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { ReactComponent as Closeicon } from '../../../assets/images/close.svg';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
        padding: 6rem 3.1rem 3.6rem;
    }
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const FormHeader = tw.div`
    flex
    gap-[0.715rem]
    mb-[3.2rem]
    items-center
`;

const FormContainer = ({ show, close, headerIcon, title, children }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <Closeicon className="absolute top-[2.9rem] right-[3.73rem] cursor-pointer" onClick={close} />
            <FormHeader>
                {headerIcon ? headerIcon : <PeopleIcon />}
                <Title>{title}</Title>
            </FormHeader>
            {children}
        </StyledModal>
    );
};

export default FormContainer;
