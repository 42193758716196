/* eslint-disable no-unused-vars */
// import React, { useState, useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { Button } from '../../../components/buttons';
import SelectInput from '../../../components/inputs/select-input';
import TextInput from '../../../components/inputs/text-input-with-formik';
import UploadFile2 from '../../../components/upload-file2';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexDiv, FlexColumn, FlexCentredRow } from '../../../containers/ScreenContainers';

// import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[2.4rem]
    flex
    flex-col
    gap-y-[3.2rem]
`;

const Label = tw.label`
    font-medium
    text-[1.4rem]
    text-[#1F2937]
`;

const GeneralInformation = () => {
    const navigate = useNavigate();

    // const [openModal, setOpenModal] = useState(false);
    // const closeModal = () => setOpenModal(false);

    return (
        // <Container>
        <Formik
            initialValues={{
                typeOfFamily: '',
            }}
            // validationSchema={ValidationSchema}
            onSubmit={(values, actions) => {
                // setOpenModal(true);
            }}
        >
            {({
                errors,
                // handleChange,
                // handleBlur,
                isSubmitting,
            }) => (
                <Form>
                    <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem]">
                        <BackArrow className="mr-[8px]" />
                        <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                            Configuration
                        </PageTitle>
                        <Text left="1rem" size="1.6rem">
                            / General Information
                        </Text>
                    </FlexCentredRow>
                    <Container>
                        <PageTitle align="left">General Information</PageTitle>
                        <div>
                            <Label>Organisation Name</Label>
                            <TextInput name="name" type="text" placeholder="Name" errors={errors} />
                        </div>
                        <div>
                            <Label>Organisation Logo</Label>
                            <UploadFile2 />
                        </div>
                        <div>
                            <Label>Organisation Address</Label>
                            <TextInput name="name" type="text" placeholder="Address" errors={errors} />
                        </div>
                        <div>
                            <Label>Country</Label>
                            <SelectInput>
                                <option>Country</option>
                            </SelectInput>
                        </div>
                        <div>
                            <Label>State</Label>
                            <SelectInput>
                                <option>State</option>
                            </SelectInput>
                        </div>
                        <div>
                            <Label>Phone Number</Label>
                            <TextInput name="name" type="number" placeholder="Phone Number" errors={errors} />
                        </div>
                        <div>
                            <Label>Email Address</Label>
                            <TextInput name="name" type="text" placeholder="Email Address" errors={errors} />
                        </div>
                        <div>
                            <Label>Website</Label>
                            <TextInput name="name" type="text" placeholder="Website" errors={errors} />
                        </div>
                        <div>
                            <Label>About Organisation</Label>
                            <TextInput
                                as="textarea"
                                name="name"
                                type="text"
                                placeholder="Short information about the school"
                                errors={errors}
                                className="py-3"
                                height="6rem"
                            />
                        </div>
                        <div>
                            <Label>Key Stakeholders (Optional)</Label>
                            <TextInput
                                name="name"
                                type="text"
                                placeholder="Specify others (optional)"
                                errors={errors}
                            />
                        </div>

                        <div>
                            <Label>Upload School Image(s)</Label>
                            <UploadFile2 />
                        </div>

                        <div className="flow-root mb-[3rem] mt-12">
                            <FlexDiv className="float-right gap-[1.45rem]">
                                <Button
                                    // disabled={isSubmitting}
                                    // onClick={() => navigate('/')}
                                    bgColor="#F3F4F6"
                                    // color="#D1D5DB"
                                    className="py-2 px-4  hover:bg-[#6366F1] focus:bg-[#6366F1] focus:text-white mb-[1.9rem] cursor-pointer"
                                >
                                    Revert
                                </Button>
                                <Button
                                    type="submit"
                                    bgColor="#6366F1"
                                    color="#fff"
                                    // type="submit"
                                    // disabled={isSubmitting}
                                    // onClick={() => navigate('/')}
                                    className="py-2 px-4  cursor-pointer"
                                >
                                    Save Changes
                                </Button>
                            </FlexDiv>
                        </div>
                    </Container>
                </Form>
            )}
        </Formik>
        // </Container>
    );
};

export default GeneralInformation;
