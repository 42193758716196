import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import Checkbox from '../../inputs/checkbox';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 33.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
    // flex
    // gap-4
`;

const SendOfferList = ({
    show,
    close,
    list,
    onSend,
    subtitle,
    title,
    nameField = 'fullName',
    idField = 'id',
    withInviteToAdmin,
    handleCheckbox,
    checked,
    isLoading,
}) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    {title || 'Send Offer'}
                </Text>
                <CloseIcon className="hover:cursor-pointer" onClick={close} />
            </FlexRowSpaceBetween>
            <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                {subtitle}
            </Text>
            {withInviteToAdmin && (
                <Checkbox
                    id="invite"
                    label="also invite to admin portal"
                    labelClassName="select-none mb-0 text-[12px]"
                    checked={checked}
                    onChange={handleCheckbox}
                />
            )}
            <ItemsContainer>
                {list?.map((item, index) => (
                    <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                ))}
            </ItemsContainer>
            <Text color="#6B7280" size="1.2rem" align="left">
                {list?.length} name(s) selected
            </Text>
            <FlexRowEnd className="gap-x-[16px]">
                <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton loading={isLoading} onClick={onSend}>
                    Send
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default SendOfferList;
