import React from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';

import Files from './files';
import Folders from './folders';

const Tabs = ({ toggleRefetch, setToggleRefetch }) => {
    return (
        <div>
            <CTabs>
                <CNav variant="tabs" className="flex items-center gap-[2.4rem]">
                    <CNavItem>
                        <CNavLink className="text-[1.4rem] font-medium">Files</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink className="text-[1.4rem] font-medium">Folders</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Files toggleRefetch={toggleRefetch} setToggleRefetch={setToggleRefetch} />
                    </CTabPane>
                    <CTabPane>
                        <Folders toggleRefetch={toggleRefetch} setToggleRefetch={setToggleRefetch} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Tabs;
