import { FACULTY } from '../../utils/unitNames';
import {
    EditLectureVenue,
    CourseAllocation,
    DepartmentCourses,
    // DepartmentPersonnel,
    TimeTable,
    Received,
    LectureVenue,
    StudentDetail,
    CourseDetails,
    AddNewCourse,
    EditNewCourse,
    CurriculumLevels,
    CurriculumDetails,
    CreateCurriculum,
    // GeneralSettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    WithdrawalListSheet,
    ProbationListSheet,
    AcademicStanding,
    CumulativeCreditUnits,
    DepartmentStudents,
    DepartmentProgrammes,
    AddNewProgramme,
    // DepartmentRoles,
    // CreateDepartmentRole,
    // EditDepartmentRole,
    // DepartmentAdministrators,
    // AddDepartmentAdministrators,
    AllotedHistory,
    ProgrammeResults,
    ResultProgrammesList,
    ExamLevels,
    // EditDepartmentAdministrator,
    DepartmentSettings,
    WithdrawalListSettings,
    ProbationListSettings,
    MinimumPromotionCGPA,
    Access,
    CourseFormManagement,
    CourseFormMagtDetails,
    Administrator,
    Roles,
    Staff,
    AssignRole,
    EditRole,
    CreateRole,
    EditAdministrator,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    Broadsheet,
    SummarySheet,
    IncompleteResult,
    ViewCommunications,
} from '../../views';

const baseUrl = 'faculties/department';

export const departmentRoutes = [
    {
        path: `${baseUrl}/access`,
        element: (
            // <Access
            //     staffs={<DepartmentPersonnel />}
            //     administrators={<DepartmentAdministrators />}
            //     roles={<DepartmentRoles />}
            // />
            <Access
                staffs={<Staff unitName={FACULTY} />}
                administrators={<Administrator unitName={FACULTY} />}
                roles={<Roles unitName={FACULTY} />}
            />
        ),
    },
    {
        // path: `${baseUrl}/access/admin/add`,
        // element: <AddDepartmentAdministrators />,
        path: `${baseUrl}/access/add-administrator`,
        element: <AssignRole unitName={FACULTY} />,
    },
    {
        // path: `${baseUrl}/access/admin/edit`,
        // element: <EditDepartmentAdministrator />,
        path: `${baseUrl}/access/edit-administrator`,
        element: <EditAdministrator unitName={FACULTY} />,
    },
    {
        path: `${baseUrl}/access/create-role`,
        // element: <CreateDepartmentRole />,
        element: <CreateRole unitName={FACULTY} />,
    },
    {
        path: `${baseUrl}/access/edit-role`,
        // element: <EditDepartmentRole />,
        element: <EditRole unitName={FACULTY} />,
    },
    {
        path: `${baseUrl}/courses`,
        element: <DepartmentCourses />,
    },
    {
        path: `${baseUrl}/courses/:id`,
        element: <CourseDetails />,
    },
    {
        path: `${baseUrl}/courses/add-new`,
        element: <AddNewCourse />,
    },
    {
        path: `${baseUrl}/courses/edit-course/:id`,
        element: <EditNewCourse />,
    },
    {
        path: `${baseUrl}/students`,
        element: <DepartmentStudents />,
    },
    {
        path: `${baseUrl}/students/:id`,
        element: <StudentDetail />,
    },
    {
        path: `${baseUrl}/course-allocation`,
        element: <CourseAllocation />,
    },
    {
        path: `${baseUrl}/course-allocation/:id`,
        element: <AllotedHistory />,
    },
    // {
    //     path: `${baseUrl}/course-form`,
    //     element: <DepartmentCourseFormManagement />,
    // },
    {
        path: `${baseUrl}/time-table`,
        element: <TimeTable />,
    },
    {
        path: `${baseUrl}/lecture-venue`,
        element: <LectureVenue />,
    },
    {
        path: `${baseUrl}/lecture-venue/edit/:id`,
        element: <EditLectureVenue />,
    },
    {
        path: '/department/communications/received',
        element: <Received />,
    },
    {
        path: '/department/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: `${baseUrl}/settings`,
        element: <DepartmentSettings />,
    },
    {
        path: `${baseUrl}/settings/withdrawal-list`,
        element: <WithdrawalListSettings />,
    },
    {
        path: `${baseUrl}/settings/probation-list`,
        element: <ProbationListSettings />,
    },
    {
        path: `${baseUrl}/settings/academic-standing`,
        element: <AcademicStanding />,
    },
    {
        path: `${baseUrl}/settings/cumulative-credit-units`,
        element: <CumulativeCreditUnits />,
    },
    {
        path: `${baseUrl}/settings/minimum-promotion-cgpa`,
        element: <MinimumPromotionCGPA />,
    },
    {
        path: `${baseUrl}/curriculum`,
        element: <CurriculumLevels />,
    },
    {
        path: `${baseUrl}/curriculum/:id`,
        element: <CurriculumDetails />,
    },
    {
        path: `${baseUrl}/curriculum/:id/create/:level`,
        element: <CreateCurriculum />,
    },
    {
        path: `/department/communications/distribution-list`,
        element: <DistributionList title="Communications" />,
    },
    {
        path: `department/communications/distribution-list/add`,
        element: <AddDistributionList title="Communications" />,
    },
    {
        path: `department/communications/distribution-list/edit/:id`,
        element: <UpdateDistributionList title="Communications" />,
    },
    {
        path: `${baseUrl}/programmes`,
        element: <DepartmentProgrammes />,
    },
    {
        path: `${baseUrl}/programmes/add-new`,
        element: <AddNewProgramme />,
    },
    {
        path: `${baseUrl}/results`,
        element: <ResultProgrammesList />,
    },
    {
        path: `${baseUrl}/results/levels`,
        element: <ExamLevels inDepartment />,
    },
    {
        path: `${baseUrl}/results/levels/:level`,
        element: <ProgrammeResults />,
    },
    {
        path: `${baseUrl}/course-form-management`,
        element: <CourseFormManagement />,
    },
    {
        path: `${baseUrl}/course-form-management/:id`,
        element: <CourseFormMagtDetails />,
    },
    {
        path: `/department/communications/compose`,
        element: <ComposeCommunications />,
    },
    {
        path: `${baseUrl}/communications/settings`,
        element: <CommsSettings />,
    },
    {
        path: `${baseUrl}/communications/compose/create`,
        element: <ComposeForm />,
    },
    {
        path: `${baseUrl}/exam-management/broadsheet`,
        element: <ExamLevels title="Broadsheet" />,
    },
    {
        path: `${baseUrl}/exam-management/broadsheet/:level`,
        element: <Broadsheet />,
    },
    {
        path: `${baseUrl}/exam-management/summary-sheet`,
        element: <ExamLevels title="Summary Sheet" />,
    },
    {
        path: `${baseUrl}/exam-management/summary-sheet/:level`,
        element: <SummarySheet />,
    },
    {
        path: `${baseUrl}/exam-management/probation-list`,
        element: <ExamLevels title="Probation List" />,
    },
    {
        path: `${baseUrl}/exam-management/probation-list/:level`,
        element: <ProbationListSheet />,
    },
    {
        path: `${baseUrl}/exam-management/withdrawal-sheet`,
        element: <ExamLevels title="Withdrawal Sheet" />,
    },
    {
        path: `${baseUrl}/exam-management/withdrawal-sheet/:level`,
        element: <WithdrawalListSheet />,
    },
    {
        path: `${baseUrl}/exam-management/incomplete-result`,
        element: <ExamLevels title="Incomplete Result" />,
    },
    {
        path: `${baseUrl}/exam-management/incomplete-result/:level`,
        element: <IncompleteResult />,
    },
];
