import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { LoadingButton } from '../../components/buttons';
import { Text } from '../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../containers/ScreenContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const SummarySheetStats = ({ show, close, data }) => {
    const items = [
        { title: 'Total Number of Registered Students', value: data?.numberOfStudents },
        { title: 'Number of Students in Good Standing', value: data?.numberOfGoodAcademicStanding },
        { title: 'Number of Students not in Good Standing', value: data?.numberOfNotGoodAcademicStanding },
        { title: 'Number of Students on Probation List', value: data?.numberOfProbationList },
        { title: 'Number of Students on Withdrawal List ', value: data?.numberOfWithdrawList },
        { title: 'Number of Students with Incomplete Results ', value: data?.numberOfIncompleteList },
    ];
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    Summary Sheet Statistics
                </Text>

                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>

            <Text as="h4" size="1.6rem" align="left" weight="400" bottom="32px" top="8px" color="#374151">
                This is the statistics for summary sheet
            </Text>
            <div className="grid gap-6 border-b pb-4 mb-[4rem]">
                {items.map((item) => (
                    <div className="flex justify-between items-center">
                        <Text weight="400" color="#374151">
                            {item.title}
                        </Text>
                        <Text weight="500" color="#374151">
                            {item.value}
                        </Text>
                    </div>
                ))}
            </div>

            <FlexRowEnd>
                <LoadingButton onClick={close}>Close</LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default SummarySheetStats;
