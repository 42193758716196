import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down2.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../components/loader';
import ActionsPopup from '../../../../components/popups/actions-popup';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { createDegreeSetting, getDegreeSetting } from '../../../../redux/settings/actions';

// import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    max-w-[101rem]
    p-[2.4rem]
    rounded-lg
    mt-10
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[6rem]
    justify-end
`;

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const CLASSES = [
    { label: 'Fail', name: 'fail' },
    { label: 'Pass', name: 'pass' },
    { label: 'Third Class Honours', name: 'thirdClass' },
    { label: 'Second Class Honours (Lower Division)', name: 'secondClassLower' },
    { label: 'Second Class Honours (Upper Division)', name: 'secondClassUpper' },
    { label: 'First Class', name: 'firstClass' },
];

const STEP = 0.01;
const DEFAULT_POINT = 5;

const ClassOfDegreeSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { isLoading, degreeSettings } = useSelector((state) => state.settings);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: firstDegreeId, academicStructureId } = currentFaculty;

    const { degreePoint, degreeRange } = degreeSettings || {};

    const [altLoading, setAltLoading] = useState(false);
    // const [point, setPoint] = useState(degreePoint || DEFAULT_POINT);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [session, setSession] = useState(activeSession || '');

    // const handleValueChange = (type, value, name, setValue, min, max) => {
    //     let newValue = type === 'decrease' ? Number(value) - STEP : Number(value) + STEP;
    //     if (newValue < min) {
    //         toast.error(`Value can't be less than ${min}`);
    //         newValue = Number(min);
    //     } else if (newValue > max) {
    //         toast.error(`Value can't be greater than ${max}`);
    //         newValue = Number(max);
    //     }
    //     const fixedValue = newValue.toFixed(2);
    //     setValue(name, fixedValue);
    // };

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    useEffect(() => {
        if (!session) return;
        dispatch(getDegreeSetting(academicStructureId, firstDegreeId, session));
    }, [academicStructureId, dispatch, firstDegreeId, session]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings" subTitle="Class of Degree Settings" />

            <Container>
                <FlexCentredRow className="mb-[3.2rem] gap-x-[1rem]">
                    <PageTitle weight="600" size="2.3rem" lineHeight="3.4rem">
                        Class of Degree Settings
                    </PageTitle>
                </FlexCentredRow>
                <Formik
                    initialValues={{
                        degreePoint: degreePoint || DEFAULT_POINT,
                        degreeRange: {
                            firstClass: degreeRange?.firstClass || { from: '', to: '' },
                            secondClassUpper: degreeRange?.secondClassUpper || { from: '', to: '' },
                            secondClassLower: degreeRange?.secondClassLower || { from: '', to: '' },
                            thirdClass: degreeRange?.thirdClass || { from: '', to: '' },
                            pass: degreeRange?.pass || { from: '', to: '' },
                            fail: degreeRange?.fail || { from: '', to: '' },
                        },
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        setAltLoading(true);
                        const params = {
                            structureId: academicStructureId,
                            firstDegreeId,
                            session,
                            payload: values,
                            useLoader: false,
                        };
                        const res = await dispatch(createDegreeSetting(params));

                        if (res) {
                            toast.success('Class of degree settings saved successfully!');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, values, setFieldValue }) => (
                        <Form>
                            <RelativeContainer className="max-w-max my-12">
                                <Button
                                    color="#6B7280"
                                    type="button"
                                    border="1px solid #9CA3AF"
                                    onClick={() => handleOpenDropdown('sessions')}
                                >
                                    {session || 'Session'}
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={openDropdown === 'sessions'}
                                    close={() => setOpenDropdown(null)}
                                    items={actionItemSessions}
                                />
                            </RelativeContainer>

                            <FlexCentredCol className="gap-[3.2rem] max-w-[74.5rem]">
                                <Select
                                    name="degreePoint"
                                    objProp="title"
                                    placeholder="Choose Point System"
                                    label="Grade Point Settings"
                                    data={[4, 5, 6, 7].map((item) => ({
                                        name: item,
                                        title: `${item} Point System`,
                                    }))}
                                    passedSelectedItems={degreePoint ? { title: `${degreePoint} Point System` } : null}
                                    onChange={(selected) => {
                                        setFieldValue('degreePoint', String(selected[0].name));
                                        // setPoint(Number(selected[0].name));
                                    }}
                                    error={errors.degreePoint}
                                />

                                <FlexCentredCol className="gap-[0.6rem]">
                                    <Label>Class of Degree Range</Label>
                                    {CLASSES.map((item, idx) => {
                                        let fromMin, fromMax, toMax;
                                        const field1Name = `degreeRange.${item.name}.from`;
                                        const field2Name = `degreeRange.${item.name}.to`;
                                        let toMin = Number(values.degreeRange[item.name].from) + STEP;
                                        const gradePoint = Number(values.degreePoint);

                                        toMax = gradePoint - STEP;

                                        if (idx === 0) {
                                            //Lowest, "Fail", should always
                                            fromMin = 0.0;
                                            fromMax = 0.0;
                                        } else {
                                            const prevValueName = CLASSES[idx - 1].name;

                                            const value = Number(values.degreeRange[prevValueName].to) + STEP;
                                            const minValue = value >= gradePoint ? value - STEP : value;

                                            fromMin = minValue.toFixed(2);
                                            fromMax = minValue.toFixed(2);
                                        }
                                        if (idx === CLASSES.length - 1) {
                                            // Higghest, "First class", should have an upper bound of the point system
                                            toMin = gradePoint;
                                            toMax = gradePoint;
                                        }

                                        return (
                                            <FlexRowSpaceBetween className="md:w-[70%]" key={item.name}>
                                                <Text align="left">{item.label}</Text>

                                                <FlexCentredRow className="gap-[0.8rem]">
                                                    <TextInput
                                                        name="numberRange"
                                                        type="number"
                                                        step={STEP}
                                                        min={fromMin}
                                                        max={fromMax}
                                                        errors={errors}
                                                        value={values.degreeRange[item.name].from}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // if (value > point) {
                                                            //     return toast.error(
                                                            //         `You can't have a value higher than ${point}`,
                                                            //     );
                                                            // }

                                                            setFieldValue(field1Name, value);
                                                        }}
                                                        classChange="absolute top-[25%] right-[5px] gap-[1.087rem] z-10"
                                                        className=" [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                        width="8.9rem"
                                                    />

                                                    <Text left="ml-[0.8rem]">to</Text>
                                                    <TextInput
                                                        name="numberRange"
                                                        type="number"
                                                        step={STEP}
                                                        min={toMin.toFixed(2)}
                                                        max={toMax.toFixed(2)}
                                                        errors={errors}
                                                        value={values.degreeRange[item.name].to}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // if (value > point) {
                                                            //     return toast.error(
                                                            //         `You can't have a value higher than ${point}`,
                                                            //     );
                                                            // }
                                                            setFieldValue(field2Name, value);
                                                        }}
                                                        classChange="absolute top-[25%] right-[5px] gap-[1.087rem] z-10"
                                                        className=" [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                        width="8.9rem"
                                                    />
                                                </FlexCentredRow>
                                            </FlexRowSpaceBetween>
                                        );
                                    })}
                                </FlexCentredCol>
                            </FlexCentredCol>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>

                                <LoadingButton loading={altLoading} disabled={!session || altLoading} type="submit">
                                    Save
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default ClassOfDegreeSettings;
