import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';


import InputContainer from '../../../components/Container/inputcontainer';
import ForgotPasswordForm from '../../../components/forms/forgot-password';
import SuccessPopup from '../../../components/popups/success';
import { PoweredBy } from '../../../components/powered-by';
import { AuthContainer } from '../confirm-password';




const ForgotPassword = ({ userType }) => {

    const navigate = useNavigate();

    const [openSuccess, setOpenSuccess] = useState(false);

    return (
        <AuthContainer>
          
           <InputContainer>
                <ForgotPasswordForm setOpenSuccess={setOpenSuccess} userType={userType} />
                <PoweredBy />
                <SuccessPopup
                    show={openSuccess}
                    onClose={() => navigate('/sign-in-staff')}
                    subtitle="Your password link has been successfully sent. Kindly Check your mail."
                />
             </InputContainer>
        </AuthContainer>
    );
}
export default ForgotPassword;