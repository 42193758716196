import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { FlexColumn } from '../../../containers/ScreenContainers';
import { deleteCurriculum, getCurriculumByLastDegreeId } from '../../../redux/curriculum/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const COURSE_SEMESTER = [
    { name: 'First Semester', value: 'First Semester' },
    { name: 'Second Semester', value: 'Second Semester' },
];

const DeleteCurriculum = ({ show, setShow, level, onFinish }) => {
    const dispatch = useDispatch();

    const admissionsSessions = useSelector((state) => state.admission.sessions);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { isLoading } = useSelector((state) => state.curriculum);

    const { _id: departmentId, academicStructure, academicStructureId } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const onClose = () => setShow('');

    return (
        <>
            <FormContainer headerIcon={<DeleteIcon />} close={onClose} show={show} title={'Delete Curriculum'}>
                <Formik
                    initialValues={{
                        session: '',
                        semester: '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        const data = await dispatch(
                            getCurriculumByLastDegreeId(academicStructureId, lastDegreeId, values.session, level),
                        );
                        if (data) {
                            const curriculumDat = data.find(
                                (item) => item.semester?.toLowerCase() === values.semester?.toLowerCase(),
                            );
                            if (curriculumDat?._id) {
                                const res = await dispatch(deleteCurriculum(curriculumDat?._id));
                                if (res) {
                                    onFinish?.();
                                    actions.resetForm();
                                    onClose();
                                    return;
                                }
                            }
                            return toast.error('There is no curriculum for the selected parameters');
                        }
                        toast.error('There is no curriculum for the selected parameters');
                    }}
                >
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <FlexColumn className="gap-5">
                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    label="Session"
                                    placeholder="Choose Session"
                                    data={admissionsSessions}
                                    useComponentState={false}
                                    passedSelectedItems={values.session ? [{ admissionYear: values.session }] : null}
                                    onChange={(selected) => {
                                        setFieldValue('session', String(selected[0].admissionYear));
                                    }}
                                    error={errors.session}
                                />

                                <Select
                                    name="semester"
                                    objProp="name"
                                    placeholder="Semester"
                                    label="Semester"
                                    data={COURSE_SEMESTER}
                                    useComponentState={false}
                                    passedSelectedItems={values.semester ? [{ name: values.semester }] : null}
                                    onChange={(selected) => {
                                        setFieldValue('semester', String(selected[0].value));
                                    }}
                                    error={errors.semester}
                                />
                            </FlexColumn>

                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton bgColor="#EF4444" loading={isLoading} type="submit">
                                    Delete
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default DeleteCurriculum;
