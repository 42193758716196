import { useReducer } from 'react';

// const initialState = {
//     session: '',
//     feeType: '',
//     amountPayable: '',
//     paymentSchedule: '',
//     span: '',
//     feeName: '',
//     description: '',
//     deadline: '',
//     firstDeadline: '',
//     secondDeadline: '',
//     thirdDeadline: '',
//     finalDeadline: '',
//     level: '',
//     target: '',
//     installmentOption: '',
//     singleFaculty: [],
//     isFacultyOpen: false,
//     data: [],
//     departmentId: [],
//     targets: [],
//     search: [],
//     selectedSearch: [],
//     isSearchOpen: false,
//     searchValue: null,
//     directStudents: [],
//     selectedFaculty: [],
//     receivingAccount: '',
//     targetOptions: Target,
//     paymentScheduleOption: paymentScheduleData,
//     spanOptions: Span,
//     facId: [],
//     facName: [],
// };

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };

        case 'TOGGLE_OPEN':
            return { ...state, isSearchOpen: !state.isSearchOpen };
        default:
            return state;
    }
};

export const useFeeFormReducer = (initialState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return {
        state,
        dispatch,
    };
};
