import React from 'react';


import InputContainer from '../../../components/Container/inputcontainer';
import StaffSignInForm from '../../../components/forms/sign-in-staff';
import { PoweredBy } from '../../../components/powered-by';
import { AuthContainer } from '../confirm-password';



const StaffSignIn = () => {
    return (
        <AuthContainer>

            <InputContainer>
                <StaffSignInForm />
                <PoweredBy />
            </InputContainer>

        </AuthContainer>
    );
};

export default StaffSignIn;