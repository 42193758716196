import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as Tick } from '../../../assets/icons/mark-black.svg';
import { ConfirmActionDialogue } from '../../../components';
import { Button } from '../../../components/buttons';
import NewAdmissionYearForm from '../../../components/forms/new-admission-year';
import Checkbox from '../../../components/inputs/checkbox';
import ListEmptyContent from '../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../components/shared';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { closeAdmission, getAdmissionSessions } from '../../../redux/admissions/actions';
import { storeUnit } from '../../../utils/originUnit';

const fields = [
    {
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Session',
        accessorKey: 'admissionSession',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Total Students',
        accessorKey: 'totalStudent',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Total Accepted',
        accessorKey: 'accepted',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Total Not Accepted',
        accessorKey: 'pending',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Not Sent',
        accessorKey: 'notSent',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Status',
        accessorKey: 'admissionStatus',
        cell: (props) => <p>{props.getValue()}</p>,
    },
];

const Admissions = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, sessions: admissionsSessions } = useSelector((state) => state.admission);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showConfrimActionDialogue, setShowConfrimActionDialogue] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);

    const handleCheck = (item) => {
        const checked = item.id === selectedSession?.id;
        if (!checked) {
            setSelectedSession(item);
        } else {
            setSelectedSession(null);
        }
    };

    const handleCloseAdmission = async (id) => {
        const res = await dispatch(closeAdmission(selectedSession?.id));
        if (res) {
            setShowConfrimActionDialogue(false);
            dispatch(getAdmissionSessions());
        }
    };

    useEffect(() => {
        storeUnit(unitName);
        dispatch(getSavedAcademicStructure());
        //eslint-disable-next-line
    }, []);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.session = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAdmissionSessions(query));
    }, [dispatch, query]);

    const modifiedAdmissionData = admissionsSessions?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox checked={item.id === selectedSession?.id} onChange={() => handleCheck(item)} />
                </td>
            ),
            admissionSession: (
                <td
                    onClick={() => navigate(`/admissions/sessions/${item.id}`, { state: item?.admissionYear })}
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                >
                    {item.admissionYear}
                </td>
            ),
            admissionStatus: (
                <td>
                    <StatusContainer style={getStatusStyles(item.status?.toLowerCase())}>
                        <span>{item.status}</span>
                    </StatusContainer>
                </td>
            ),
            ...item,
        };
    });

    return (
        <>
            <div>
                <PageLayout
                    pageTitle="Admissions"
                    secondaryButtonText="Create Session"
                    onSecondaryButtonClick={() => setShowForm(true)}
                    onActionBtnClick={() => setDropdownOpen(!dropdownOpen)}
                    actionOpen={dropdownOpen}
                    actionClose={() => setDropdownOpen(false)}
                    actionItems={[
                        {
                            icon: <Tick />,
                            name: 'Close Admission',
                            disabled: !selectedSession,
                            click: () => {
                                setShowConfrimActionDialogue(true);
                                setDropdownOpen(false);
                            },
                        },
                    ]}
                    searchable
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    fields={fields}
                    data={modifiedAdmissionData || []}
                    loading={isLoading}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent
                                isInTable
                                title={searchValue ? '' : 'No Sessions'}
                                subtitle={searchValue ? 'No sessions exist that match your search' : 'Create Session'}
                            >
                                {!searchValue && (
                                    <Button bgColor="#6366F1" color="#fff" onClick={() => setShowForm(true)}>
                                        <Circularplus className="mr-[1.15rem]" />
                                        Create Session
                                    </Button>
                                )}
                            </ListEmptyContent>
                        </CenteredContainer>
                    }
                    onRowClick={(item) => {
                        const selectedItem = admissionsSessions?.find((session) => session?.id === item?.id);
                        handleCheck(selectedItem);
                    }}
                    isActionable
                />
            </div>

            <ConfirmActionDialogue
                show={showConfrimActionDialogue}
                title="Close Admission"
                isLoading={isLoading}
                subtitle="Are you sure you want to close this session?"
                close={() => setShowConfrimActionDialogue(false)}
                confirmAction={() => handleCloseAdmission()}
            />
            <NewAdmissionYearForm show={showForm} setShow={setShowForm} />
        </>
    );
};

export default Admissions;
