import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../assets/icons/people2.svg';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexDiv } from '../../../../containers/ScreenContainers';
import { getStudentDetails } from '../../../../redux/students/actions';

import CourseHistory from './tabs/CourseHistory';
import Result from './tabs/Results';

const MainContainer = tw.div`
    grid
    gap-8
    mt-8
    mb-[4.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mt-[1.6rem]
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
`;

const StudentDetail = () => {
    const {
        state: { studentId },
    } = useLocation();
    const dispatch = useDispatch();
    const [, /*tab*/ setTab] = useState(0);

    const { studentDetails, isLoading } = useSelector((store) => store.student);

    useEffect(() => {
        dispatch(getStudentDetails(studentId));
    }, [dispatch, studentId]);

    if (isLoading) return <Loader />;

    const left = [
        {
            title: 'Name',
            subtitle: `${studentDetails?.basicData?.lastName} ${studentDetails?.basicData?.firstName} ${
                studentDetails?.basicData?.middleName || ''
            }`,
        },
        {
            title: 'Gender',
            subtitle: studentDetails?.bioData?.updateInfo?.gender,
        },
        {
            title: 'Date of Birth',
            subtitle: studentDetails?.bioData?.updateInfo?.dateOfBirth?.slice(0, 10),
        },
        {
            title: 'Email Address',
            subtitle: studentDetails?.basicData?.studentEmail,
        },
        {
            title: 'Phone Number',
            subtitle: studentDetails?.bioData?.updateInfo?.phoneNumber,
        },
    ];

    const center = [
        {
            title: 'Matric No',
            subtitle: studentDetails?.basicData?.matricNumber,
        },
        {
            title: 'Year of Admission',
            subtitle: studentDetails?.basicData?.admissionYear?.slice(0, 10),
        },
        {
            title: 'Faculty',
            subtitle: studentDetails?.basicData?.faculty,
        },
        {
            title: 'Department',
            subtitle: studentDetails?.basicData?.program,
        },
        {
            title: 'Mode of Entry',
            subtitle: studentDetails?.basicData?.admissionType,
        },
    ];
    const right = [
        {
            title: 'State of Origin',
            subtitle: studentDetails?.bioData?.updateInfo?.stateOfOrigin,
        },
        {
            title: 'Local Government',
            subtitle: studentDetails?.bioData?.updateInfo?.localGovernment,
        },
        {
            title: 'Home Town',
            subtitle: studentDetails?.bioData?.updateInfo?.homeTown,
        },
        {
            title: 'Next of Kin',
            subtitle: studentDetails?.bioData?.updateInfo?.nextOfKin,
        },
        {
            title: 'Next of Kin Phone Number',
            subtitle: studentDetails?.bioData?.updateInfo?.nextOfKinPhoneNumber,
        },
    ];

    return (
        <div>
            <GoBack
                title="Course Registration"
                subTitle={`${studentDetails?.basicData?.lastName} ${studentDetails?.basicData?.firstName} ${
                    studentDetails?.basicData?.middleName || ''
                }`}
            />

            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Students Details
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexDiv className="justify-end ">
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Active
                                </Text>
                            </Button>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <img
                            alt="profile"
                            src={studentDetails?.uploads?.passport?.[0]?.url}
                            className="h-[18rem] w-[15rem] col-span-1"
                        />

                        <RowContainer className="">
                            {left?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {center?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {right?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
            </MainContainer>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Course History</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Results</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <CourseHistory />
                    </CTabPane>
                    <CTabPane>
                        <Result />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default StudentDetail;
