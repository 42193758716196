import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as CirclePlus } from '../../../../assets/icons/circlePlus.svg';
import { ReactComponent as Mastercard } from '../../../../assets/icons/Mastercard.svg';
import { Button } from '../../../../components/buttons';
import AddPaymentDetails from '../../../../components/forms/add-payment-details';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';

const PaymentMethod = () => {
    const navigate = useNavigate();
    const [addPayment, setAddPayment] = useState(false);
    return (
        <div>
            <FlexCentredRow
                onClick={() => {
                    setAddPayment(false);
                    navigate(-1);
                }}
                className="hover:cursor-pointer gap-[8px] pb-[3.6rem]"
            >
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem" weight="400">
                    /
                </Text>
                <Text size="1.6rem" weight="400">
                    Manage payment methods
                </Text>
            </FlexCentredRow>
            <div className="bg-white p-[2.4rem] h-screen mb-[7.8rem]">
                {!addPayment ? (
                    <div className="w-[70rem]">
                        <Text align="left" size="2.3rem" weight="600">
                            Manage Payment Methods
                        </Text>
                        <Text align="left" size="1.4rem" weight="500" color="#6B7280" top="0.8rem">
                            Edit your payment details, add a backup, or switch your preferred payment method.
                        </Text>
                        <div className="flex items-center justify-between pt-[3.2rem]">
                            <div className="flex items-center gap-[0.8rem]">
                                <Mastercard />
                                <div className="">
                                    <Text align="left" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.4rem">
                                        MasterCard
                                    </Text>
                                    <Text align="left" color="#6B7280" size="1rem" weight="400" lineHeight="1.7rem">
                                        Ending 6916 . Expires On 9/2024
                                    </Text>
                                </div>
                            </div>
                            <div className="rounded-[1000px] bg-[#3B82F6] px-[0.8rem] text-[#fff] text-[1.4rem] font-semibold">
                                Primary
                            </div>
                            <Button onClick={() => navigate('update')} bgColor="#fff" color="#3B82F6">
                                Edit
                            </Button>
                        </div>
                        <div className="pt-[3.35rem] flex items-center justify-between">
                            <Text align="left" size="1.4rem" weight="400" color="#6B7280">
                                You can add an alternative payment method by adding another
                            </Text>
                            <Button
                                onClick={() => {
                                    navigate('?=add');
                                    setAddPayment(true);
                                }}
                                border="1px solid #3B82F6"
                                color="#3B82F6"
                                className="flex items-center gap-[0.8rem]"
                            >
                                <CirclePlus fill="#2563EB" />{' '}
                                <Text color="#3B82F6" size="1.4rem" weight="500">
                                    Add Another
                                </Text>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Text align="left" size="2.3rem" weight="600">
                            Enter your payment details
                        </Text>
                        <Text align="left" size="1.4rem" weight="500" color="#6B7280" top="0.8rem">
                            Edit your payment details, add a backup, or switch your preferred payment method.
                        </Text>
                        <Text align="left" size="1.4rem" weight="600" color="#6B7280" top="3rem" bottom="2.4rem">
                            Credit card information
                        </Text>
                        <AddPaymentDetails />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentMethod;
