import React, { useRef, useState } from 'react';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as AttachmentIcon } from '../../../../../assets/icons/attachment.svg';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';
import { ReactComponent as Gallery } from '../../../../../assets/icons/gallery.svg';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link.svg';
import { ReactComponent as Smiley } from '../../../../../assets/icons/smiley.svg';
import { Button } from '../../../../../components/buttons';
import AddNewLink from '../../../../../components/forms/add-new-link';
import ScheduleSend from '../../../../../components/forms/schedule-send';
import Select from '../../../../../components/inputs/new-select';
import PDFPreview from '../../../../../components/pdf-preview';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { sendDraftMail, sendMail } from '../../../../../redux/communications/actions';

const StyledButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    px-[1em]
    py-[.4em]
    bg-[#6366F1]
    text-[#FFF]
    text-[1.4rem]
    leading-6
    focus:outline-none
    h-[4rem]
`;
const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
`;
const Header = tw.div`
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    py-[2.4rem]
    px-[3.2rem]
    bg-white
    mx-auto

`;
const SubHeader2 = tw.div`
    border-[#E5E7EB]
    border-b
    bg-white
    mx-auto
    px-[3.2rem]
    py-[1.4rem]
`;
const TextArea = tw.textarea`
    w-full
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    placeholder:font-semibold
    leading-6
    font-normal
    text-[1.4rem]
    mb-5
`;
const NewInput = tw.input`
    pl-3
    rounded
    focus:outline-none
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-full
`;

const StaffComposeMail = ({ unitName }) => {
    const navigate = useNavigate();
    const [showAddLink, setShowAddLink] = useState(false);
    const [showScheduleSend, setShowScheduleSend] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [distributionListName, setDistributionListName] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [linkName, setLinkName] = useState('');
    const [attachment, setAttachment] = useState([]);
    const [image, setImage] = useState([]);
    const hiddenFileInput = useRef(null);
    const hiddenImageInput = useRef(null);
    const dispatch = useDispatch();
    const { allDistributionList } = useSelector((store) => store.communications);
    const [isDraft, setIsDraft] = useState(false);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const facName = currentFaculty?.facultyName;
    const deptName = currentDepartment?.departmentName;

    const url = window.location.href;

    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleImageClick = () => {
        hiddenImageInput.current.click();
    };

    const addAttachment = async (e) => {
        const files = Array.from(e.target.files);
        const allowedTypes = ['application/pdf'];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (allowedTypes.includes(file.type)) {
                setAttachment(files);
                toast.success(`${file.name} selected`);
            } else {
                toast.error(`Upload a PDF file`);
            }
        }
    };

    const handleAddLink = () => {
        setContent(`${content} ${linkName}`);
    };

    const addImage = (e) => {
        const files = Array.from(e.target.files);
        const allowedTypes = ['image/jpeg', 'image/png'];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (allowedTypes.includes(file.type)) {
                setImage(files);
                toast.success(`${file.name} selected`);
            } else {
                toast.error(`${file.name} is not an accepted file type`);
            }
        }
    };

    const onEmojiClick = (e) => {
        const sym = e.unified.split('_');
        const emojiArray = [];
        sym.forEach((el) => emojiArray.push('0x' + el));
        let emoji = String.fromCodePoint(...emojiArray);
        setContent(content + emoji);
    };

    const handleReset = () => {
        if (showPicker) {
            setShowPicker(false);
        } else if (showAddLink) {
            setShowAddLink(false);
        } else {
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        attachment.forEach((file) => {
            formData.append('attachment', file);
        });
        image.forEach((file) => {
            formData.append('image', file);
        });
        formData.append('content', content);
        formData.append('link', link);
        formData.append('title', title);
        formData.append('distributionListName', distributionListName);
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };
        if (isDraft) {
            if (url.includes('department')) {
                const res = dispatch(sendDraftMail({ deptName, formData, headers, param: 'senderDepartmentName' }));
                if (res) navigate(-1);
            } else if (url.includes('faculties')) {
                const res = dispatch(sendDraftMail({ facName, formData, headers, param: 'senderFacultyName' }));
                if (res) navigate(-1);
            } else {
                const res = dispatch(sendDraftMail({ unitName, formData, headers, param: 'senderUnitName' }));
                if (res) navigate(-1);
            }
            setIsDraft(false);
        } else {
            if (url.includes('department')) {
                const res = dispatch(sendMail({ deptName, formData, headers, param: 'senderDepartmentName' }));
                if (res) navigate(-1);
            } else if (url.includes('faculties')) {
                const res = dispatch(sendMail({ facName, formData, headers, param: 'senderFacultyName' }));
                if (res) navigate(-1);
            } else {
                const res = dispatch(sendMail({ unitName, formData, headers, param: 'senderUnitName' }));
                if (res) navigate(-1);
            }
        }
    };

    return (
        <div className="max-w-[1000px]" onClick={handleReset}>
            <FlexCentredRow className="mb-[3.2rem]">
                <BackArrow onClick={() => navigate(-1)} className="mr-[8px] hover:cursor-pointer" />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Communications
                </PageTitle>
            </FlexCentredRow>

            <form onSubmit={handleSubmit}>
                <Header>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow className="gap-[1.015rem]">
                            <PageTitle>Mail</PageTitle>
                            <Dropdown />
                        </FlexCentredRow>
                        <FlexCentredRow className=" cursor-pointer gap-[6.315rem]">
                            <Close onClick={() => navigate(-1)} />
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>
                </Header>

                <SubHeader2 className="relative">
                    <div className="flex gap-[2rem] items-center">
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TO:
                        </Text>
                        <Select
                            name="distributionList"
                            containerClass="w-[100px] md:w-[200px] lg:w-[400px] xl:w-[600px]"
                            objProp="name"
                            data={allDistributionList}
                            placeholder="Select Distribution List"
                            onChange={(selected) => setDistributionListName(selected[0].name)}
                        />
                    </div>
                </SubHeader2>

                <SubHeader2 className="flex flex-row">
                    <Text weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                        TITLE
                    </Text>
                    <NewInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        name="subjects"
                        type="text"
                        placeholder="Title"
                    />
                </SubHeader2>
                <MainContainer>
                    <TextArea
                        value={content}
                        rows="15"
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Enter Message here"
                    />
                    <div className="flex gap-[1rem] items-center">
                        {image.length > 0 && (
                            <>
                                {image?.map((pic, i) => (
                                    <div key={i} className="w-[100px] h-[100px]">
                                        <img src={URL.createObjectURL(pic)} alt="" className="w-full h-[100%]" />
                                    </div>
                                ))}
                            </>
                        )}
                        {attachment.length > 0 && (
                            <>
                                {attachment?.map((file, i) => (
                                    <div key={i} className="max-h-[100px]">
                                        <PDFPreview pageNumber={1} url={URL.createObjectURL(file)} />
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <FlexRowSpaceBetween className="gap-[0.2rem]">
                        <FlexCentredRow className="relative gap-[3.8rem]">
                            {showPicker && (
                                <div className=" absolute left-0  bottom-12 z-[1000] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]">
                                    <Picker data={data} onEmojiSelect={onEmojiClick} />
                                </div>
                            )}
                            <LinkIcon onClick={() => setShowAddLink((previousState) => !previousState)} />
                            {showAddLink ? (
                                <AddNewLink
                                    text={linkName}
                                    link={link}
                                    handleTextChange={(e) => setLinkName(e.target.value)}
                                    handleUrlChange={(e) => setLink(e.target.value)}
                                    handleAdd={handleAddLink}
                                    show={showAddLink}
                                    setShow={setShowAddLink}
                                />
                            ) : null}
                            <Smiley onClick={() => setShowPicker((val) => !val)} />

                            <input
                                type="file"
                                className="hidden"
                                ref={hiddenFileInput}
                                id="fileInput"
                                multiple
                                accept=".pdf"
                                onChange={addAttachment}
                            />
                            <AttachmentIcon onClick={handleClick} />
                            <input
                                type="file"
                                id="imageInput"
                                ref={hiddenImageInput}
                                className="hidden"
                                multiple
                                accept=".jpg,.jpeg,.png"
                                onChange={addImage}
                            />
                            <Gallery onClick={handleImageClick} />
                        </FlexCentredRow>
                        <FlexCentredRow className="gap-[2.4rem]">
                            <Button border="0.5px solid #D1D5DB" onClick={() => setIsDraft(true)}>
                                Save as draft
                            </Button>

                            <FlexCentredRow>
                                <StyledButton className="py-0 rounded-l-md">Send</StyledButton>
                                <StyledButton
                                    type="button"
                                    className="py-0 border-l rounded-r-md border-l-[#A5B4FC]"
                                    onClick={() => setShowScheduleSend((previousState) => !previousState)}
                                >
                                    <Dropdown />
                                </StyledButton>
                            </FlexCentredRow>
                        </FlexCentredRow>
                        {showScheduleSend && <ScheduleSend show={showScheduleSend} setShow={setShowScheduleSend} />}
                    </FlexRowSpaceBetween>
                </MainContainer>
            </form>
        </div>
    );
};

export default StaffComposeMail;
