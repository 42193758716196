const developmentFlag = process.env.REACT_APP_ENV;

export const facultyNavLinks = [
    {
        title: 'Departments',
        to: '/faculties/departments',
    },
    {
        title: 'Students',
        to: '/faculties/students',
    },
    {
        title: 'General Courses',
        to: '/faculties/courses',
    },
    {
        title: 'Allocate General Courses',
        to: '/faculties/allocate-general-courses',
    },
    ...(developmentFlag !== 'production'
        ? [
              {
                  title: 'Academic Calendar',
                  to: '/faculties/academic-calendar',
              },
          ]
        : []),
    {
        title: 'Lecture Time-table',
        to: '/faculties/lecture-timetable',
    },

    {
        title: 'Exam Time-table',
        to: '/faculties/exam-timetable',
    },

    // {
    //     title: 'Course Form Management',
    //     to: '/faculties/course-form',
    // },
    {
        title: 'Communications',
        to: '/faculties/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/faculties/communications/compose',
            },
            {
                title: 'Received',
                to: '/faculties/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/faculties/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/faculties/communications/settings',
            },
        ],
    },
    {
        title: 'Access',
        to: '/faculties/access',
    },
    {
        title: 'Settings',
        to: '/faculties/settings',
    },
];

export const facultyRoutes = facultyNavLinks.map((item) => item?.to);
