import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    currentDepartment: {},
};

export const curriculumSlice = createSlice({
    name: 'curriculum',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        SET_CURRENT_DEPARTMENT: (state, action) => {
            state.department = action.payload;
        },
    },
});

export const { SET_CURRENT_DEPARTMENT, LOADING, STOP_LOADING } = curriculumSlice.actions;

export default curriculumSlice.reducer;
