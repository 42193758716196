import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/circlePlus.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { configurePaymentGateway, getActivePaymentGatewayById } from '../../../redux/bursary/actions';
import { LoadingButton } from '../../buttons';
import ToggleSwitch from '../../inputs/switch';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { Loader } from '../../loader';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';
// import { Loader } from '../../loader';

const FlexContainer = tw.div`
flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const PaymentGatewayForm = ({ show, onClose, gatewayId, gatewayName, refetch }) => {
    const dispatch = useDispatch();

    const [active, setActive] = useState(false);
    const [gateway, setGateway] = useState({});
    const [loading, setLoading] = useState(false);

    const getGateway = async () => {
        setLoading(true);
        if (gatewayId !== null) {
            const data = await dispatch(getActivePaymentGatewayById(gatewayId));
            setActive(data.active);
            setGateway(data);
        } else {
            setActive(false);
            setGateway(null);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        getGateway();
        //eslint-disable-next-line
    }, [gatewayId]);

    return (
        <div>
            <FormContainer
                close={onClose}
                show={show}
                headerIcon={<PlusIcon fill="#6366F1" />}
                title={`Configure ${gatewayName}`}
            >
                {loading ? (
                    <Loader noText={true} containerStyle="h-[100%]" />
                ) : (
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            provider: gateway?.provider || '',
                            publicKey: gateway?.publicKey || '',
                            secretKey: gateway?.secretKey || '',
                            encryptKey: gateway?.encryptKey || '',
                            contractCode: gateway?.contractCode || '',
                            active: gateway?.active || '',
                        }}
                        // validationSchema={ValidationSchema}
                        onSubmit={async (values, actions) => {
                            values.provider = gatewayName?.toLowerCase();
                            values.active = active;
                            if (gateway === 'Paystack') {
                                delete values.encryptKey;
                                delete values.contractCode;
                            } else if (gatewayName === 'Monnify') {
                                delete values.encryptKey;
                            } else if (gatewayName === 'Flutterwave') {
                                delete values.contractCode;
                            }
                            const res = await dispatch(configurePaymentGateway(values));
                            if (res) {
                                refetch();
                                actions.resetForm();
                                onClose();
                            }
                        }}
                    >
                        {({ values, errors, handleChange, setFieldValue, isSubmitting }) => (
                            <Form>
                                <div className="gap-5 flex flex-col">
                                    <FormikTextInput
                                        label="Live Public Key"
                                        name="publicKey"
                                        type="password"
                                        placeholder="Input Public Key"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values?.publicKey}
                                    />
                                    <FormikTextInput
                                        label="Live Secret Key"
                                        name="secretKey"
                                        type="password"
                                        placeholder="Input Secret Key"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values?.secretKey}
                                    />
                                    {gatewayName !== 'Paystack' && gatewayName !== 'Remita' && (
                                        <FormikTextInput
                                            label={`Live ${
                                                gatewayName === 'Monnify'
                                                    ? 'Contract Code'
                                                    : gatewayName === 'Flutterwave' && 'Encrypt Key'
                                            }`}
                                            name="extraKey"
                                            type="password"
                                            placeholder={`Input ${
                                                gatewayName === 'Monnify'
                                                    ? 'Contract Code'
                                                    : gatewayName === 'Flutterwave' && 'Encrypt Key'
                                            }`}
                                            errors={errors}
                                            onChange={(e) => {
                                                if (gatewayName === 'Monnify') {
                                                    setFieldValue('contractCode', e.target.value);
                                                } else {
                                                    setFieldValue('encryptKey', e.target.value);
                                                }
                                            }}
                                            value={
                                                gatewayName === 'Monnify' ? values?.contractCode : values?.encryptKey
                                            }
                                        />
                                    )}
                                    <FlexRowSpaceBetween>
                                        <Text>Enable Gateway</Text>

                                        <ToggleSwitch checked={active} setChecked={setActive} />
                                    </FlexRowSpaceBetween>
                                </div>
                                <FlexContainer>
                                    <CancelButton type="button" onClick={onClose}>
                                        Cancel
                                    </CancelButton>
                                    <LoadingButton loading={isSubmitting} type="submit">
                                        Configure
                                    </LoadingButton>
                                </FlexContainer>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormContainer>
        </div>
    );
};

export default PaymentGatewayForm;
