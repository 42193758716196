import React, { useEffect, useState } from 'react';

import { formatDistanceToNow } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../../../../components/inputs/checkbox';
import UploadFile from '../../../../components/upload-file';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { SAVE_CURRENT_DRAFT } from '../../../../redux/communications/slice';
import { getStaffDraft } from '../../../../redux/staff-arena/actions';
import { addLabelsToData } from '../../../../utils';

const fields = [
    {
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Subject',
        accessorKey: 'subject',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Message',
        accessorKey: 'message',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Type',
        accessorKey: 'status',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Time Stamp',
        accessorKey: 'time',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
];

const StaffArenaDraft = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((store) => store.staffArena);

    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [allDraft, setAllDraft] = useState({});
    const combinedArray = addLabelsToData(allDraft, 'Staff-Arena');
    const sortedData = combinedArray?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const AllData = sortedData?.map((item) => {
        return {
            id: item._id,
            message: item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content,
            label: item.label,
            time: formatDistanceToNow(new Date(item.createdAt), { addSuffix: true }),
            distributionListName: item?.distributionListName,
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            subject: <td style={{ fontWeight: '600' }}>{item.title}</td>,
            // status: (item) => <td>{getLabelBadge(item.label)}</td>,
            ...item,
        };
    });

    const handleClick = (item) => {
        if (item.label === 'Letter') {
            navigate('/staff-arena/communications/compose/letter/draft');
        } else if (item.label === 'Notice') {
            navigate('/staff-arena/communications/compose/notice');
        } else {
            navigate('/staff-arena/communications/compose/memo');
        }
    };

    const getDraft = async () => {
        const res = await dispatch(getStaffDraft());
        setAllDraft(res);
    };

    useEffect(() => {
        getDraft();
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            <PageLayout
                pageTitle="Draft"
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={AllData || []}
                fields={fields}
                loading={isLoading}
                onRowClick={(item) => {
                    dispatch(SAVE_CURRENT_DRAFT(item));
                    handleClick(item);
                }}
                isActionable
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </div>
    );
};

export default StaffArenaDraft;
