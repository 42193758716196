import React from 'react';

import tw from 'twin.macro';

import document from '../../assets/icons/document.svg';

const Container = tw.div`
  lg:w-[59.2rem]
  sm:w-[80%]
  pt-[11.06rem]

  flex
  flex-col
  items-center
  bg-contain
  bg-top
  bg-no-repeat bg-white
`;

const Title = tw.h2`
  mb-[4px]
  text-[2.3rem]
  font-semibold
  leading-[3.4rem]
  text-[#1F2937]
`;

const Image = tw.img`
  mb-[2.5rem]
`;

const ListEmptyContent = ({ title, subtitle, src, children, isInTable }) => {
    return (
        <Container className={`bg-empty-bg ${isInTable ? '' : 'h-[49.4rem]'} `}>
            <Image src={src || document} alt="document" />
            <Title className="">{title}</Title>
            <h4 className="mb-[5.7rem] font-normal leading-6 text-[1.4rem]">{subtitle}</h4>
            {children}
        </Container>
    );
};

export default ListEmptyContent;
