import React from 'react';

import EditOrgProfileForm from '../../../../../components/forms/edit-org-profile';

const EditOrgProfile = () => {
    return (
        <div>
            <EditOrgProfileForm />
        </div>
    );
};

export default EditOrgProfile;
