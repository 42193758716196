import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as AttachmentAsh } from '../../../../../../assets/icons/attachment-ash.svg';
import { ReactComponent as BackArrow } from '../../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';
import { Loader } from '../../../../../../components/loader';
import PDFPreview from '../../../../../../components/pdf-preview';
import { PageTitle, Text } from '../../../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
} from '../../../../../../containers/ScreenContainers';
import { getLetterById } from '../../../../../../redux/staff-arena/actions';

const MainContainer = tw.div`
h-auto
bg-white
mx-auto
py-[2.4rem]
px-[3.2rem]
rounded-lg
`;

const StaffLetterPreview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading, letter } = useSelector((store) => store.staffArena);

    useEffect(() => {
        dispatch(getLetterById(id));
    }, [dispatch, id]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.2rem]">
                <BackArrow className="mr-[8px] " />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Sent
                </PageTitle>
            </FlexCentredRow>

            <MainContainer>
                <FlexRowSpaceBetween>
                    <PageTitle>Letters</PageTitle>

                    <Close onClick={() => navigate(-1)} />
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween className="mt-[2.8rem]">
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TYPE:
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            Letters
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <AttachmentAsh />
                        <Text color="#9CA3AF">Sent. {new Date(letter?.createdAt).toLocaleString()}</Text>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexCentredCol>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            FROM (OFFICE):
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            {letter?.sender}
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TO:{' '}
                        </Text>
                        <FlexDiv className="flex-wrap">
                            <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                                {letter?.recipientOffice}
                            </Text>
                        </FlexDiv>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            REF NO:{' '}
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            11156GF
                        </Text>
                    </FlexCentredRow>
                </FlexCentredCol>
                <FlexCentredCol className=" gap-y-[3.2rem] my-[3.2rem]">
                    <Text align="left">{letter?.title}</Text>
                    <Text align="left">{letter?.content}</Text>
                    <Text align="left">Best Regards, {letter?.sender}</Text>
                </FlexCentredCol>
                <div className="flex flex-wrap gap-[1rem] items-center">
                    {letter?.attachment?.length > 0 ? (
                        <>
                            {letter?.attachment?.map((file) => (
                                <PDFPreview url={file?.url} pageNumber={1} />
                            ))}
                        </>
                    ) : null}
                    {letter?.image?.length > 0 ? (
                        <>
                            {letter?.image?.map((img, i) => (
                                <img key={i} src={img?.url} alt={img?.fileName} className="h-[100px] w-[200px]" />
                            ))}
                        </>
                    ) : null}
                </div>
            </MainContainer>
        </div>
    );
};

export default StaffLetterPreview;
