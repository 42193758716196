import React from 'react'

import { Routes, Route } from "react-router-dom";

import { protectedRoutes } from "../routes";

const Content = () => {
  return (
    <Routes>
      {protectedRoutes.map((route, idx) => {
        return (
          route.element && (
            <Route key={idx} path={route.path} element={route.element} />
          )
        );
      })}
    </Routes>
  );
};

export default Content
