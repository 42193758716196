import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ThreeDots } from '../../../assets/icons/three-dots-horizontal.svg';
import { ActionsPopup } from '../../../components';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

const MainContainer = tw.div`
    h-auto
    bg-white
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
    mb-[0.2rem]
`;
const BlockDetails = tw.div`
    h-auto
    bg-[#F3F4F6]
    lg:p-[2.4rem]
    rounded-lg
    mb-[7rem]
`;
const BlockGrid = tw.div`
    grid
    grid-cols-3
    gap-[1.6rem]
`;
const Room = tw.div`
bg-[#2563EB]
w-[24.1rem]
h-[24.1rem]
rounded
pt-[1.4rem]
px-[2.4rem]
`;
const FlexEnd = tw.div`
    flex
    justify-end
    mt-[16.45rem]
    mb-[2.6rem]
`;
const SmallButton = tw.button`
flex
items-center
justify-center
flex-row
font-medium
px-[.4rem]
py-[.2rem]
bg-[#EFF6FF]
rounded

`;

const RoomEmpty = () => {
    const navigate = useNavigate();
    const [openActions, setOpenActions] = useState(false);

    return (
        <>
            <MainContainer>
                <BlockDetails>
                    <FlexCentredRow>
                        <BlockGrid>
                            <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                <FlexRowSpaceBetween>
                                    <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                        A
                                    </Text>
                                    <SmallButton>
                                        <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                            Empty
                                        </Text>
                                    </SmallButton>
                                </FlexRowSpaceBetween>
                                <FlexEnd>
                                    <div className="relative">
                                        <ThreeDots
                                            className=" relative"
                                            onClick={() => {
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Add New Bedspace',
                                                    click: () => {
                                                        // setShowUploadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Edit Bedspace Name',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Unreserve Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Remove Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </FlexEnd>
                            </Room>
                            <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                <FlexRowSpaceBetween>
                                    <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                        B
                                    </Text>
                                    <SmallButton>
                                        <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                            Empty
                                        </Text>
                                    </SmallButton>
                                </FlexRowSpaceBetween>
                                <FlexEnd>
                                    <div className="relative">
                                        <ThreeDots
                                            className=" relative"
                                            onClick={() => {
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Add New Bedspace',
                                                    click: () => {
                                                        // setShowUploadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Edit Bedspace Name',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Unreserve Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Remove Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </FlexEnd>
                            </Room>
                            <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                <FlexRowSpaceBetween>
                                    <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                        C
                                    </Text>
                                    <SmallButton>
                                        <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                            Empty
                                        </Text>
                                    </SmallButton>
                                </FlexRowSpaceBetween>
                                <FlexEnd>
                                    <div className="relative">
                                        <ThreeDots
                                            className=" relative"
                                            onClick={() => {
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Add New Bedspace',
                                                    click: () => {
                                                        // setShowUploadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Edit Bedspace Name',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Unreserve Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Remove Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </FlexEnd>
                            </Room>
                            <Room
                                onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                className="row-start-2"
                            >
                                <FlexRowSpaceBetween>
                                    <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                        D
                                    </Text>
                                    <SmallButton>
                                        <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                            Empty
                                        </Text>
                                    </SmallButton>
                                </FlexRowSpaceBetween>
                                <FlexEnd>
                                    <div className="relative">
                                        <ThreeDots
                                            className=" relative"
                                            onClick={() => {
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Add New Bedspace',
                                                    click: () => {
                                                        // setShowUploadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Edit Bedspace Name',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Unreserve Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Remove Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </FlexEnd>
                            </Room>
                            <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                <FlexRowSpaceBetween>
                                    <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                        E
                                    </Text>
                                    <SmallButton>
                                        <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                            Empty
                                        </Text>
                                    </SmallButton>
                                </FlexRowSpaceBetween>
                                <FlexEnd>
                                    <div className="relative">
                                        <ThreeDots
                                            className=" relative"
                                            onClick={() => {
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Add New Bedspace',
                                                    click: () => {
                                                        // setShowUploadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Edit Bedspace Name',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Unreserve Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Remove Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </FlexEnd>
                            </Room>
                            <Room onClick={() => navigate('/hostel/hostel-list/room-details/:id')}>
                                <FlexRowSpaceBetween>
                                    <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                        F
                                    </Text>
                                    <SmallButton>
                                        <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                            Empty
                                        </Text>
                                    </SmallButton>
                                </FlexRowSpaceBetween>
                                <FlexEnd>
                                    <div className="relative">
                                        <ThreeDots
                                            className=" relative"
                                            onClick={() => {
                                                setOpenActions(true);
                                            }}
                                        />
                                        <ActionsPopup
                                            open={openActions}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Add New Bedspace',
                                                    click: () => {
                                                        // setShowUploadFile(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Edit Bedspace Name',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Unreserve Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Remove Bedspace',
                                                    click: () => {
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </FlexEnd>
                            </Room>
                        </BlockGrid>
                    </FlexCentredRow>
                </BlockDetails>
            </MainContainer>
        </>
    );
};

export default RoomEmpty;
