import React, { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import RadioInput from '../../../../components/inputs/radio';
import TableWithUtils from '../../../../components/table-with-utils';
import { Text } from '../../../../containers/MesssageContainers';
import { RelativeContainer } from '../../../../containers/ScreenContainers';
import { getDiscountDetails, removeStudentFromDiscount } from '../../../../redux/bursary/actions';
import { getDegreeInfo } from '../../../../utils';

const fields = [
    {
        header: <RadioInput />,
        size: 5,
        accessorKey: 'action',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Student Name',
        accessorKey: 'studentName',
    },
    {
        header: 'Matric Number',
        accessorKey: 'matricNumber',
    },
    {
        header: 'Faculty',
        accessorKey: 'faculty',
    },
    {
        header: 'Department',
        accessorKey: 'department',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Level',
        accessorKey: 'level',
    },
    {
        header: 'Amount Waived (NGN)',
        accessorKey: 'amountWaived',
    },
];

const ApplicableStudents = ({ discountName }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { isLoading, discountDetails } = useSelector((state) => state.bursary);

    const [selectedItem, setSelectedItem] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [searchValue, setSearchValue] = useState('');


    const filteredResults = useMemo(() => {
        if (!searchValue) return discountDetails;

        return discountDetails.filter((item) => {
            const fullName = `${item.studentDetails?.firstName || ''} ${item.studentDetails?.middleName || ''} ${
                item.studentDetails?.lastName || ''
            }`.toLowerCase();
            return fullName.includes(searchValue.toLowerCase().trim());
        });
    }, [discountDetails, searchValue]);

    const handleSelect = (fee) => {
        const selected = selectedItem?._id === fee?._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(fee);
        }
    };

    const handleDelete = async () => {
        const studentId = selectedItem?.studentDetails?._id;
        const feeId = discountDetails?.[0]?.feeId;

        const res = await dispatch(removeStudentFromDiscount(studentId, feeId));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setOpenDropdown(false);
        }
    };

    useEffect(() => {
        dispatch(getDiscountDetails(id));
    }, [dispatch, id, toggleRefetch]);

    const modifiedData = filteredResults?.map((item) => {
        const amountWaived = item?.amountPayable - item?.amountAfterDiscount;
        const studentDetails = item?.studentDetails;
        const fullName = `${studentDetails?.firstName} ${studentDetails?.middleName} ${studentDetails?.lastName}`;

        const { firstDegree, secondDegree } = getDegreeInfo(studentDetails, 'academicStructure');
        return {
            ...item,

            action: (
                <td>
                    <RadioInput checked={item._id === selectedItem?._id} onClick={() => handleSelect(item)} />
                </td>
            ),

            studentName: fullName || '',

            matricNumber: studentDetails?.matricNumber || '-',
            faculty: firstDegree || '',
            department: secondDegree || '',
            level: studentDetails?.level || '',
            amountWaived: amountWaived || '',
        };
    });

    return (
        <>
            <div className="bg-[#fff] rounded-t-[16px] mb-10 mt-4">
                <div className="flex items-center justify-between px-[24px] pt-[18px] pb-[0px] mb-[-20px]">
                    <Text weight="500" size="1.9rem">
                        Applicable Students
                    </Text>

                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setOpenDropdown(true)}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openDropdown}
                            close={() => setOpenDropdown(false)}
                            items={[
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Remove Student',
                                    disabled: !selectedItem,
                                    click: () => setOpenModal('delete'),
                                },
                            ]}
                        />
                    </RelativeContainer>
                </div>
                <TableWithUtils
                    fields={fields}
                    data={modifiedData || []}
                    showTableUtils
                    searchable
                    // showFilter
                    loading={isLoading}
                    onRowClick={(item) => handleSelect(item)}
                    isActionable
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                />
            </div>

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Remove student from discount?"
                subtitle={`Are you sure you want to remove this student from ${discountName}.`}
                confirmAction={handleDelete}
                btn2Text="Remove"
                isLoading={isLoading}
            />
        </>
    );
};

export default ApplicableStudents;
