import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../../../../../components/inputs/checkbox';
import UploadFile from '../../../../../components/upload-file';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { formatDate } from '../../../../../utils';

const fields = [
    {
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Subject',
        accessorKey: 'subject',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Message',
        accessorKey: 'message',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Time Stamp',
        accessorKey: 'time',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
];

const SentStaffLetters = ({ sentLetters }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);
    const { isLoading } = useSelector((store) => store.communications);

    const newArray = [...sentLetters];

    const sortedData = newArray?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

    const LettersData = sortedData?.map((mail) => {
        const formattedDate = formatDate(mail.createdAt);
        return {
            id: mail._id,
            message: mail.content.length > 50 ? mail.content.slice(0, 50) + '...' : mail.content,
            acknowledgement: mail.acknowledgementStatus,
            time: formattedDate,
            action: (
                <td>
                    <Checkbox />
                </td>
            ),

            subject: <td style={{ fontWeight: '600' }}>{mail.title}</td>,
            ...mail,
        };
    });

    return (
        <>
            <PageLayout
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={LettersData || []}
                fields={fields}
                loading={isLoading}
                onRowClick={(item) => navigate(`letters-preview/${item.id}`)}
                isActionable
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default SentStaffLetters;
