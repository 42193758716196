import React, { useState } from 'react';

import tw from 'twin.macro';

import bgLogo from '../../../assets/background.png';
import OnboardingScheduleForm from '../../../components/forms/onboarding-schedule-demo';
import { PoweredBy } from '../../../components/powered-by';

import SuccessPage from './success-page';

const Container = tw.div`
  bg-[#fff]
  flex
  justify-center
  items-center
  min-h-screen
  max-h-screen
  overflow-y-auto
  bg-center
  w-full
  pt-[10vh]
  pb-[10vh]
`;

const ScheduleDemo = () => {
    const [showSuccess, setShowSuccess] = useState(false);

    const handleSubmitSuccess = () => {
        setShowSuccess(true);
    };

    if (showSuccess) return <SuccessPage />;

    return (
        <Container>
            <div
                className="flex justify-center items-center flex-col bg-center bg-no-repeat w-[40%] lg:w-[40%] md:w-[80%]"
                style={{
                    backgroundImage: `url(${bgLogo})`,
                    backgroundSize: 'contain',
                }}
            >
                <PoweredBy withText={false} />
                <OnboardingScheduleForm onSubmitSuccess={handleSubmitSuccess} />
            </div>
        </Container>
    );
};

export default ScheduleDemo;
