import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as Closeicon } from '../../../assets/images/close.svg';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
        // padding: 6rem 3.1rem 3.6rem;
    }
`;

const CardPopup = ({ show, close, children, noClose }) => {
    return (
        <StyledModal show={show} onClose={close} centered size="lg" closeOnBackdrop={true} backdrop={true} color="info">
            {!noClose && <Closeicon className="absolute top-[2.9rem] right-[3.73rem] cursor-pointer" onClick={close} />}
            <div className="">{children}</div>
        </StyledModal>
    );
};

export default CardPopup;
