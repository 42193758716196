import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery, transformThirdDegreeData } from '../../utils';
import { GET_FACULTIES, GET_FACULTY_DEPARTMENTS, GET_FACULTY_COURSES, GET_FACULTY_STUDENTS } from '../faculty/slice';
import { GET_DEPARTMENT_PROGRAMMES } from '../programmes/slice';

import { LOADING, STOP_LOADING } from './slice';

export const getColleges = (queryCollege) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const queries = encodeQuery({ ...queryCollege });

        const response = await axiosInstance.get(
            `${currentAPI}college/${currentUnit?._id}/get_all_colleges?${queries}`,
        );
        if (response.status === 200) {
            const { data = [] } = response?.data || {};

            const transformedData = data?.map((item) => {
                const { collegeAdmin, collegeCode, collegeName, collegeNumberingCode, ...otherFields } = item;

                return {
                    facultyAdmin: collegeAdmin,
                    facultyCode: collegeCode,
                    facultyName: collegeName,
                    facultyNumberingCode: collegeNumberingCode,
                    ...otherFields,
                };
            });
            dispatch(GET_FACULTIES(transformedData));
            return true;
        }
    } catch (error) {
        dispatch(GET_FACULTIES([]));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createCollege =
    (payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}college/${currentUnit?._id}/create_college`,
                payload,
            );
            if (response.status === 200) {
                dispatch(getColleges());
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const deleteCollege = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}college/${currentUnit?._id}/delete_college/${id}`);
        const successMessage = response?.data?.message || 'College deleted Successfully';
        if (response.status === 200) {
            toast.success(successMessage);
            dispatch(getColleges());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editCollege = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}college/${currentUnit?._id}/edit_college/${id}`,
            payload,
        );
        const successMessage = response?.data?.message || 'College Updated Successfully';
        if (response.status === 200) {
            dispatch(getColleges());
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// export const getFacultyDetails = (id) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//         const {
//             organisationUnits: { currentUnit },
//         } = getState();

//         const response = await axiosInstance.get(`${currentAPI}faculty/${currentUnit?._id}/detail/${id}`);
//         if (response.status === 200) {
//             return response.data.data;
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const createCollegeProgram =
    (collegeId, payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}program/${currentUnit?._id}/create_program_in_college/${collegeId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCollegeProgrammes =
    (id, showLoader = true) =>
    async (dispatch, getState) => {
        showLoader && dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}program/${currentUnit?._id}/get_program_in_college/${id}`,
            );
            if (response.status === 200) {
                dispatch(GET_FACULTY_DEPARTMENTS(response.data.data));
            }
        } catch (error) {
            dispatch(GET_FACULTY_DEPARTMENTS([]));
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const collegeCreateCourseInProgram = (departmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}course/${currentUnit?._id}/create_course/${departmentId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCollegeCourses = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}college/${currentUnit?._id}/college_courses/${id}`);
        if (response.status === 200) {
            dispatch(GET_FACULTY_COURSES(response.data.data));
        }
    } catch (error) {
        dispatch(GET_FACULTY_COURSES([]));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
// export const getCollegeStaff = (id) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//         const {
//             organisationUnits: { currentUnit },
//         } = getState();

//         const response = await axiosInstance.get(`${currentAPI}college/${currentUnit?._id}/college_staffs/${id}`);
//         if (response.status === 200) {
//             dispatch(GET_FACULTY_STAFF(response.data.data));
//         }
//     } catch (error) {
//         dispatch(GET_FACULTY_STAFF([]));
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const getCollegeStudents = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}college/${currentUnit?._id}/college_students/${id}`);
        if (response.status === 200) {
            dispatch(GET_FACULTY_STUDENTS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_FACULTY_STUDENTS([]));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createCollegeSecondLevel =
    (payload, facultyId, structureType, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}structure/${currentUnit?._id}/college/create_second_level/${facultyId}?type=${structureType}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCollegeSecondLevel =
    ({ showLoader = true, academicStructureId, firstDegreeId, structTwo }, query) =>
    // structTwo i.e 2nd degree e.g 'department', 'faculty', 'program' e.t.c
    // firstDegreeId: here it's same as college id
    async (dispatch, getState) => {
        showLoader && dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();
            const queries = encodeQuery({ ...query });

            const response = await axiosInstance.get(
                `${currentAPI}structure/${currentUnit?._id}/get_second_degree_college/${academicStructureId}/${firstDegreeId}?${queries}`,
            );
            if (response.status === 200) {
                const { data } = response.data;
                const transformedData = data.map((item) => {
                    let newItem;

                    const structMapping = {
                        department: ['department', 'departmentCode', 'departmentNumberingCode'],
                        program: ['subprogram', 'subProgramCode', 'subProgramNumberingCode'],
                        school: ['schoolName', 'schoolCode', 'schoolNumberingCode'],
                        faculty: ['facultyName', 'facultyCode', 'facultyNumberingCode'],
                        college: ['collegeName', 'collegeCode', 'collegeNumberingCode'],
                    };

                    const fields = structMapping[structTwo?.toLowerCase()];

                    if (fields) {
                        const {
                            [fields[0]]: departmentName,
                            [fields[1]]: departmentCode,
                            [fields[2]]: departmentNumberingCode,
                            ...otherFields
                        } = item;

                        newItem = {
                            departmentName,
                            departmentCode,
                            departmentNumberingCode,
                            ...otherFields,
                        };
                    }

                    return newItem;
                });

                dispatch(GET_FACULTY_DEPARTMENTS(transformedData));
                return transformedData;
            }
        } catch (error) {
            dispatch(GET_FACULTY_DEPARTMENTS([]));
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createCollegeThirdLevel =
    (payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
                department: {
                    currentDepartment: { _id: departmentId },
                },
                faculty: {
                    currentFaculty: { _id: collegeId, academicStructure },
                },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}structure/${currentUnit?._id}/college/create_third_degree/${collegeId}/${departmentId}?type=${academicStructure}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCollegeThirdLevel = (academicStructureId, collegeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}structure/${currentUnit?._id}/get_third_degree_college/${academicStructureId}/${collegeId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};

            const transformedData = transformThirdDegreeData(data);

            dispatch(GET_DEPARTMENT_PROGRAMMES(transformedData));
            return transformedData;
        }
    } catch (error) {
        dispatch(GET_DEPARTMENT_PROGRAMMES([]));
        const errorMessage = error?.response?.data?.message || 'An errorr occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
