import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../components/loader';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { getPermissionsInUnit } from '../../../redux/permissions/actions';
import { createRole } from '../../../redux/roles/actions';
import { capitalizeEachWord } from '../../../utils';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    h-[100%]
    p-[2.4rem]
    max-w-[102.2rem]
`;

const Label = tw.label`
    font-normal
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const Title = tw.h2`
    text-[1.6rem]
    font-semibold
    leading-6
    text-[#1F2937]
    mb-[0]
`;

const CreateRole = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.permissions);
    const { isLoading: altLoading } = useSelector((state) => state.roles);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const handleSelectPermission = (item) => {
        const selected = selectedPermissions?.some((ite) => ite === item);

        if (selected) {
            const newItems = selectedPermissions.filter((ite) => ite !== item);
            setSelectedPermissions(newItems);
        } else {
            setSelectedPermissions((prev) => [...prev, item]);
        }
    };

    const getData = async () => {
        const data = await dispatch(getPermissionsInUnit());
        setPermissions(data);
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <Loader />;

    return (
        <div>
            <div className="mb-12">
                <GoBack title={`Roles`} subTitle="Create Role" />
            </div>

            <Formik
                initialValues={{
                    role: '',
                    description: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    // const res = await dispatch(createRole(unitName, { permissions, ...values }));
                    const payload = {
                        permissions: selectedPermissions,
                        ...values,
                    };

                    const res = await dispatch(createRole(payload));
                    if (res) {
                        toast.success('Role created successfully!');
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <MainContainer>
                            <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                                <PeopleIcon />
                                <Title>Create Role</Title>
                            </FlexCentredRow>
                            <FlexCentredCol className="mb-[2.4rem]">
                                <Label>Role Name</Label>
                                <TextInput
                                    placeholder="Input Name"
                                    name="role"
                                    type="text"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                            </FlexCentredCol>
                            <FlexCentredCol className="mb-[3.2rem]">
                                <Label>Role Description</Label>
                                <TextInput
                                    name="description"
                                    placeholder="Input Description"
                                    type="text"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                            </FlexCentredCol>
                            <Label align="left">Permissions</Label>
                            <Text align="left" bottom="2.4rem">
                                Select the type or types of permissions you want the role you are creating to have
                            </Text>
                            <div className="grid grid-cols-3 justify-center gap-x-[1.6rem] gap-y-[1.6rem] w-3/4">
                                {permissions?.map((item, i) => (
                                    <div
                                        key={i}
                                        className="flex items-center justify-start gap-x-[1.4rem] gap-y-[0.8rem]
"
                                    >
                                        {/* <label className="leading-[2.4rem] text-[1.4rem] font-normal" htmlFor={item}> */}
                                        <Checkbox
                                            value={item}
                                            id={item}
                                            label={capitalizeEachWord(item)}
                                            onClick={() => handleSelectPermission(item)}
                                            checked={selectedPermissions?.some((ite) => ite === item)}
                                            labelClassName="leading-[2.4rem] text-[1.4rem] font-normal"
                                        />
                                        {/* </label> */}
                                    </div>
                                ))}
                            </div>
                            <FlexRowSpaceBetween>
                                <CancelButton type="button" onClick={() => setSelectedPermissions([])}>
                                    Deselect All
                                </CancelButton>
                                <FlexRowEnd className="gap-[2.4rem] mb-[3.6rem] mt-[1.9rem]">
                                    <CancelButton type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </CancelButton>
                                    <LoadingButton type="submit" disabled={altLoading} loading={altLoading}>
                                        Create Role
                                    </LoadingButton>
                                </FlexRowEnd>
                            </FlexRowSpaceBetween>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateRole;
