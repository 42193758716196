import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_APPOINTMENT_CATEGORIES, GET_APPOINTMENT_DETAILS, LOADING, STAFF_COUNT, STOP_LOADING } from './slice';

export const createAppointment = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}`, payload);
        const successMessage = response?.data?.message || 'Appointment Created Successfully!.';
        toast.success(successMessage);
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAppointmentCatgories = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}`);
        dispatch(GET_APPOINTMENT_CATEGORIES(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffCount = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/staff/count`);
        dispatch(STAFF_COUNT(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAppointment = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/${id}`);
        return response;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addVc = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}appointment/${currentUnit?._id}/vice-chancellor`,
            payload,
        );
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Vice Chancellor appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllVc = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/vice-chancellors/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeVc = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}appointment/${currentUnit?._id}/vice-chancellor/${id}`,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDeputyVc = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}appointment/${currentUnit?._id}/deputy-vice-chancellor`,
            payload,
        );
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Deputy Vice Chancellor appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeDVc = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}appointment/${currentUnit?._id}/deputy-vice-chancellor/${id}`,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDVc = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}appointment/${currentUnit?._id}/deputy-vice-chancellors/all`,
        );
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addRegistrar = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/registrar`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Registrar appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeRegistrar = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/registrar/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllRegistrars = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/registrars/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addBursar = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/bursar`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Bursar appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeBursar = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/bursar/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBursars = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/bursars/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addProvost = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/provost`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Provost appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeProvost = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/provost/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllProvosts = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/provosts/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDirector = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/director`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Director appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeDirector = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/director/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDirectors = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/directors/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDean = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/dean`, payload);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || 'Dean appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeDean = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/dean/${id}`);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDeans = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/deans/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addSubDean = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/subdean`, payload);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || 'Sub Dean appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeSubdean = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/subdean/${id}`);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSubDeans = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/subdeans/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addHOD = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}appointment/${currentUnit?._id}/headofdepartment`,
            payload,
        );
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || 'Head of department appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeHOD = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}appointment/${currentUnit?._id}/headofdepartment/${id}`,
        );
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllHODs = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}appointment/${currentUnit?._id}/headofdepartments/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addNew = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}appointment/${currentUnit?._id}/newCategory`, payload);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || `${payload.appointmentType} appointed!.`;
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllNew = (name) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}appointment/${currentUnit?._id}/newcategorys/all?appointmentType=${name}`,
        );
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeNew = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}appointment/${currentUnit?._id}/newcategory/${id}`);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editVc = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}appointment/${currentUnit?._id}/vice-chancellor/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDvc = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}appointment/${currentUnit?._id}/deputy-vice-chancellor/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editRegistrar = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}appointment/${currentUnit?._id}/registrar/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editBursar = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}appointment/${currentUnit?._id}/bursar/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editProvost = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}appointment/${currentUnit?._id}/provost/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDirector = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}appointment/${currentUnit?._id}/director/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDean = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}appointment/${currentUnit?._id}/dean/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editSubdean = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}appointment/${currentUnit?._id}/subdean/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editHod = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}appointment/${currentUnit?._id}/headofdepartment/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editNew = (id, payload, close) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}appointment/${currentUnit?._id}/newcategory/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
