import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { Loader } from '../../../../components/loader';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getAllSent, getSentLetters } from '../../../../redux/staff-arena/actions';

import { SentStaffAllComms, SentStaffLetters } from './tabs';

const StaffSent = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const { sentLetters, isLoading } = useSelector((store) => store.staffArena);

    useEffect(() => {
        dispatch(getAllSent());
        dispatch(getSentLetters());
    }, [dispatch]);

    const ComposeList = [
        {
            name: 'Letter',
            click: () => handleActionClick('/staff-arena/communications/compose/letter'),
        },
        // {
        //     name: 'Mail',
        //     click: () => handleActionClick('/staff-arena/communications/compose/mail'),
        // },
    ];

    const handleActionClick = (route) => {
        navigate(route);
        setDropdownOpen(false);
    };

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Sent</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown />
                    </Button>
                    <ActionsPopup open={dropdownOpen} close={() => setDropdownOpen(false)} items={ComposeList} />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>All</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink>Letter</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <SentStaffAllComms />
                    </CTabPane>

                    <CTabPane>
                        <SentStaffLetters sentLetters={sentLetters} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default StaffSent;
