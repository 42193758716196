import React from 'react';

import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

const Card = tw.div`
    bg-white
    p-[2rem]
    mb-[2.4rem]
    no-underline
    rounded-[2rem]
`;

const ITEMS = [
    {
        title: 'Class of Degree',
        subtitle: 'Set University Class of Degree',
        link: 'class-of-degree',
    },
    {
        title: 'Grades',
        subtitle: 'Set academic grades',
        link: 'grade',
    },
    {
        title: 'Weighted Grade Point',
        subtitle: 'Set weighted grade point',
        link: 'weighted-grade-point',
    },
];

const FacultySettings = () => {
    return (
        <div>
            <PageTitle bottom="4rem" align="left">
                Settings
            </PageTitle>
            {ITEMS.map((item) => (
                <Link to={item.link} key={item.link}>
                    <Card>
                        <Text weight="600" align="left" size="1.8rem">
                            {item.title}
                        </Text>
                        <FlexRowSpaceBetween>
                            <Text color="#6B7280" align="left" size="1.4rem">
                                {item.subtitle}
                            </Text>
                        </FlexRowSpaceBetween>
                    </Card>
                </Link>
            ))}
        </div>
    );
};

export default FacultySettings;
