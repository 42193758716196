import React from 'react';

import { format, getDay, parse, startOfWeek } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';

const Timetable = ({ eventsList }) => {
    const locales = {
        'en-US': enUS,
    };

    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={eventsList}
                defaultDate={new Date()}
                startAccessor="start"
                endAccessor="end"
                style={{
                    height: 500,
                    background: '#ffffff',
                    color: '#000000',
                    padding: '2rem',
                    fontSize: '1.6rem',
                }}
            />
        </div>
    );
};

export default Timetable;
