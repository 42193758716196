import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Delete } from '../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit3.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus-blue.svg';
import { ActionsPopup } from '../../components';
import { Button } from '../../components/buttons';
import RadioInput from '../../components/inputs/radio';
import ListEmptyContent from '../../components/list-empty';
import DeleteRole from '../../components/popups/delete-role';
import { CenteredContainer, FlexCentredRow, RelativeContainer } from '../../containers/ScreenContainers';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getRolesInUnit } from '../../redux/roles/actions';

const fields = [
    {
        accessorKey: 'action',
        header: <RadioInput />,
        size: 5,
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Roles',
        accessorKey: 'role',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Number of Staff',
        accessorKey: 'numberOfStaffs',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Description',
        accessorKey: 'description',
        cell: (props) => <p>{props.getValue()}</p>,
    },
];

const Roles = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.roles);

    const [roles, setRoles] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.roleSearch = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const handleDeleteSuccess = () => {
        setSelectedItem(null);
        setToggleRefetch((prevState) => !prevState);
    };

    useEffect(() => {
        if (unitName) {
            const getDetails = async () => {
                const data = await dispatch(getRolesInUnit(unitName, query));

                if (data) {
                    const nonCustomRoles = data?.filter((item) => !item?.isCustom);
                    setRoles(nonCustomRoles);
                }
            };

            getDetails();
        }
    }, [unitName, query, dispatch, toggleRefetch]);

    const modifiedRolesData = roles?.map((item) => {
        return {
            action: (
                <td>
                    <RadioInput onChange={() => handleSelect(item)} checked={selectedItem?._id === item._id} />
                </td>
            ),

            numberOfStaffs: <td>{item.staffs?.length}</td>,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                fields={fields}
                data={modifiedRolesData || []}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Roles"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
                tableUtilsChildren={
                    <FlexCentredRow className="gap-x-4">
                        <Button border="1px solid #e5e7eb" color="#6366F1" onClick={() => navigate('create-role')}>
                            <Plus />
                            Create Role
                        </Button>

                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                Actions
                                <Dropdown />
                            </Button>

                            <ActionsPopup
                                open={dropdownOpen}
                                close={() => setDropdownOpen(false)}
                                items={[
                                    {
                                        icon: <EditIcon />,
                                        name: 'Edit Role',
                                        disabled: !selectedItem,
                                        click: () => {
                                            navigate('edit-role', { state: selectedItem });
                                        },
                                    },
                                    {
                                        icon: <Delete />,
                                        name: 'Delete Role',
                                        disabled: !selectedItem,
                                        click: () => {
                                            setDropdownOpen(false);
                                            setOpenModal('delete');
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                }
                isActionable
            />

            <DeleteRole
                show={openModal === 'delete'}
                role={selectedItem}
                setShow={setOpenModal}
                onFinish={handleDeleteSuccess}
            />
        </>
    );
};

export default Roles;
