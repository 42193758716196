import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete2.svg';
import Checkbox from '../../../components/inputs/checkbox';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import { CountBadge } from '../../../components/shared';
import { Text } from '../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
// eslint-disable-next-line no-unused-vars
import { getAdministratorsInOrganization, removeStaffAsAdmin } from '../../../redux/administrators/actions';
import { removeStaffAsPlatformAdmin } from '../../../redux/roles/actions';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Name',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Organization Unit',
        accessorKey: 'unit',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Designation',
        accessorKey: 'designation',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Role',
        accessorKey: 'role',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Staff ID',
        accessorKey: 'staffNumber',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Email',
        accessorKey: 'staffEmail',
    },
];

const CONFIRM_TEXT = 'remove';

const IctOrganizationAdmins = ({ withCreate }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading, allAdmins = [] } = useSelector((state) => state.administrators);
    const { isLoading: altLoading } = useSelector((state) => state.roles);

    const [openDropdown, setOpenDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openModal, setOpenModal] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const platformAdmins = useMemo(
        () =>
            allAdmins?.filter((staff) => {
                return staff?.staffRole?.some((role) => role?.role === 'Platform Administrator');
            }),
        [allAdmins],
    );

    const modifiedPlatformAdmins = platformAdmins?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox onChange={() => handleSelect(item)} checked={selectedItem?._id === item._id} />
                </td>
            ),
            name: (
                <td>
                    {item.firstName} {item.otherName || ''} {item.surname}
                </td>
            ),
            unit: (
                <td>
                    <div className="flex items-center gap-[4px]">
                        <Text align="left" color="#6b728">
                            {item.organizationUnit[0] || '-'}
                        </Text>
                        {item.organizationUnit?.length > 1 ? (
                            <CountBadge>+{item.organizationUnit?.length - 1}</CountBadge>
                        ) : null}
                    </div>
                </td>
            ),
            role: (
                <td>
                    <div className="flex items-center gap-[4px]">
                        <Text align="left" color="#6b728">
                            {item?.staffRole[0]?.role || '-'}
                        </Text>
                        {item.staffRole?.length > 1 ? <CountBadge>+{item.staffRole?.length - 1}</CountBadge> : null}
                    </div>
                </td>
            ),
            ...item,
        };
    });

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const confirmRemoveAction = async () => {
        if (inputValue !== CONFIRM_TEXT) return toast.error(`Input ${CONFIRM_TEXT}`);

        // const res = await dispatch(removeStaffAsAdmin(selectedItem._id));
        const res = await dispatch(removeStaffAsPlatformAdmin(selectedItem._id));
        if (res) {
            setOpenModal('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.role = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAdministratorsInOrganization(query));
    }, [dispatch, query, toggleRefetch]);

    return (
        <>
            <PageLayout
                pageTitle="Platform Administrators"
                secondaryButtonText={withCreate ? 'Add Administrators' : ''}
                onSecondaryButtonClick={() => navigate('add')}
                onActionBtnClick={() => setOpenDropdown(!openDropdown)}
                actionOpen={openDropdown}
                actionClose={() => setOpenDropdown(false)}
                actionItems={
                    withCreate
                        ? [
                              {
                                  icon: <DeleteIcon />,
                                  name: 'Remove Administrator',
                                  disabled: !selectedItem,
                                  click: () => {
                                      setOpenModal('confirm-remove');
                                      setOpenDropdown(false);
                                  },
                              },
                          ]
                        : null
                }
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedPlatformAdmins || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No admins "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
                isActionable
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'confirm-remove'}
                isLoading={isLoading || altLoading}
                placeholder={`Input ${CONFIRM_TEXT}`}
                title="Remove Platform Administrator?"
                subtitle="The administrator's access to this organization unit will be terminated."
                subtitle2={`To confirm Removal, enter '${CONFIRM_TEXT}' in the text field.`}
                close={() => setOpenModal('')}
                confirmAction={confirmRemoveAction}
                btn2Text="Yes, Remove"
                setInputValue={setInputValue}
                value={inputValue}
                btnDisabled={!inputValue}
            />
        </>
    );
};

export default IctOrganizationAdmins;
