import React, { useState, useEffect } from 'react';

import Avatar from 'react-avatar';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as Copy } from '../../../../../../assets/icons/copy.svg';
import { ReactComponent as Delete } from '../../../../../../assets/icons/delete-red.svg';
import { ReactComponent as FolderIcon } from '../../../../../../assets/icons/folder.svg';
import { ReactComponent as Move } from '../../../../../../assets/icons/move.svg';
import { ReactComponent as RenameIcon } from '../../../../../../assets/icons/rename-icon.svg';
import { ReactComponent as ShareIcon } from '../../../../../../assets/icons/Share.svg';
import { ReactComponent as MenuIcon } from '../../../../../../assets/icons/three-dot.svg';
import { ReactComponent as Details } from '../../../../../../assets/icons/warning-black.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../../../components';
import ListEmptyContent from '../../../../../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../../../../../components/popups/confirm-action-with-input';
import FileDetails from '../../../../../../components/popups/details';
import MoveFolder from '../../../../../../components/popups/move-folder';
import Share from '../../../../../../components/popups/share';
import { Text } from '../../../../../../containers/MesssageContainers';
import { CenteredContainer, FlexCentredRow } from '../../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../../layout/page-layout/PageLayout';
import { deleteFolder, getNestedFolder, renameFolder } from '../../../../../../redux/staff-arena/actions';
import { capitalizeFirstLetter } from '../../../../../../utils';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Folder Name',
        accessorKey: 'folderName',
    },

    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Date Created',
        accessorKey: 'dateCreated',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Owner',
        accessorKey: 'owner',
    },
];

const InnerFolders = ({ folders, toggleRefetch, setToggleRefetch }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation();
    const { previousFolders } = location.state;

    const { isLoading } = useSelector((state) => state.staffArena);

    const [searchValue, setSearchValue] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [folderName, setFolderName] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState('');
    const [renameFolders, setRenameFolders] = useState(false);
    const [showdetails, setShowDetails] = useState(false);
    const [move, setMove] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [share, setShare] = useState(false);

    const [fullName, setFullName] = useState('');

    useEffect(() => {
        if (selectedId) {
            const file = folders?.find((item) => item._id === selectedId);
            if (file) {
                setFullName(file?.createdBy);
            }
        }
    }, [selectedId, folders]);

    const sizeInBytes = folders?.map((item) => parseInt(item?.content?.[0]?.size, 10));
    const size = isNaN(sizeInBytes) ? '0.00' : (sizeInBytes / (1024 * 1024)).toFixed(2);

    const handleRenameFolder = async () => {
        const selectedFolder = folders?.find((item) => item._id === selectedId);
        const folderId = selectedFolder?._id;
        const payload = {
            folderId: folderId,
            newName: folderName,
        };

        if (selectedId) {
            const res = await dispatch(renameFolder(payload));
            if (res) {
                setDropdownOpen(false);
                setFolderName('');
                toast.success('File renamed successfully!');
                setToggleRefetch((prevState) => !prevState);
            }
        }
    };

    // const handleFolderDownload = async () => {
    //     const selectedFolder = folders?.find((item) => item._id === selectedId);
    //     if (selectedFolder) {
    //         const folderName = selectedFolder.folderName;
    //         if (folderName) {
    //             window.location.href = `/download-folder/${folderName}`;
    //         } else {
    //             toast.error('Folder name is missing.');
    //         }
    //     } else {
    //         toast.error('Folder not found.');
    //     }
    // };

    const handleCopyLink = async () => {
        const selectedFolder = folders?.find((item) => item?._id === selectedId);
        if (selectedFolder) {
            const folderUrl = selectedFolder?.link;
            if (folderUrl) {
                navigator.clipboard.writeText(folderUrl);
                toast.success('Link copied to clipboard!');
            } else {
                toast.error('File URL is missing.');
            }
        } else {
            toast.error('File not found.');
        }
    };

    const handleDeleteFolder = async () => {
        const selectedFolder = folders?.find((item) => item?._id === selectedId);
        const folderId = selectedFolder?._id;
        const payload = {
            folderId: folderId,
        };

        if (selectedId) {
            const res = await dispatch(deleteFolder(payload));
            if (res) setOpenDelete(false);
            setSelectedId('');
            toast.success('File deleted successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    useEffect(() => {
        dispatch(getNestedFolder(id));
    }, [dispatch, toggleRefetch, id]);

    const modifiedFilesData = folders?.map((item) => {
        const dateTimeString = item?.dateCreated || item?.createdAt;
        const [datePart, timePart] = dateTimeString.split('T');
        const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

        return {
            ...item,
            folderName: (
                <td
                    className="cursor-pointer"
                    onClick={() =>
                        navigate(`/staff-arena/documents/folder/${item?._id}`, {
                            state: {
                                folderName: item?.folderName,
                                previousFolders: `${previousFolders ? previousFolders : ''} / ${item?.folderName}`,
                            },
                        })
                    }
                >
                    <div className="flex items-center gap-[1.6rem] px-[1.6rem]">
                        <FolderIcon />

                        <div>{capitalizeFirstLetter(item?.folderName)}</div>
                    </div>
                </td>
            ),
            dateCreated: <td>{formattedDateTime}</td>,
            owner: (
                <td className="flex items-center justify-between">
                    <FlexCentredRow className="gap-[1rem] ">
                        {item?.profilePicture?.url ? (
                            <img
                                alt="profile"
                                src={item?.profilePicture?.url}
                                className="rounded-full w-[2rem] h-[2rem]"
                            />
                        ) : (
                            <Avatar name={`${item?.surname}`} size="20" textSizeRatio={1.75} className="rounded-full" />
                        )}

                        <Text>{item?.owner}</Text>
                    </FlexCentredRow>
                    <MenuIcon
                        className="cursor-pointer"
                        onClick={() => {
                            setSelectedId(item?._id);
                            setDropdownOpen(!dropdownOpen);
                        }}
                    />
                    <ActionsPopup
                        open={dropdownOpen && selectedId === item?._id}
                        close={() => setDropdownOpen(false)}
                        items={[
                            // {
                            //     name: 'Download',
                            //     icon: <Download />,
                            //     click: () => {
                            //         // handleFolderDownload();
                            //         setSelectedId(item?._id);
                            //         setDropdownOpen(false);
                            //     },
                            // },

                            {
                                name: 'Details',
                                icon: <Details />,
                                click: () => {
                                    const fileDetails = modifiedFilesData.find((file) => file._id === item?._id);
                                    setSelectedFolder(fileDetails);
                                    setShowDetails(true);
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Copy Link',
                                icon: <Copy />,
                                click: () => {
                                    handleCopyLink();
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Move to folder',
                                icon: <Move />,
                                click: () => {
                                    const folders = modifiedFilesData.find((folder) => folder._id === item?._id);
                                    setFolderName(folders?.folderName);
                                    setSelectedId(item?._id);
                                    setMove(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Rename',
                                icon: <RenameIcon />,
                                click: () => {
                                    setRenameFolders(true);
                                    setSelectedId(item?._id);
                                    setDropdownOpen('Rename');
                                },
                            },
                            {
                                name: 'Share',
                                icon: <ShareIcon />,
                                click: () => {
                                    const folders = modifiedFilesData.find((folder) => folder._id === item?._id);
                                    setFolderName(folders?.folderName);
                                    setShare(true);
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Delete ',
                                icon: <Delete />,
                                click: () => {
                                    setSelectedId(item?._id);
                                    setOpenDelete(true);
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </td>
            ),
        };
    });

    return (
        <>
            <div className="mb-[1.8rem] ">
                <PageLayout
                    className="relative"
                    searchable
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    fields={fields}
                    data={modifiedFilesData || []}
                    loading={isLoading}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent
                                isInTable
                                title={searchValue ? '' : 'No Folders'}
                                subtitle={
                                    searchValue
                                        ? 'No folder exist that match your search'
                                        : 'Get started by creating folders'
                                }
                            ></ListEmptyContent>
                        </CenteredContainer>
                    }
                    isActionable
                />

                <ConfirmActionDialogue
                    show={openDelete}
                    close={() => setOpenDelete(false)}
                    title="Delete Item"
                    subtitle="Are you sure you want to delete this permanently?"
                    btn2Text="Yes, delete"
                    confirmAction={handleDeleteFolder}
                />

                <ConfirmActionDialogueWithInput
                    show={renameFolders}
                    borderLine={false}
                    svgIcon={<RenameIcon />}
                    title="Rename"
                    isLoading={isLoading}
                    subtitle="Name"
                    setInputValue={setFolderName}
                    value={folderName}
                    close={() => setRenameFolders(false)}
                    confirmAction={() => {
                        handleRenameFolder();
                        setRenameFolders(false);
                    }}
                    btn2Text="Rename"
                    placeholder="Input Name"
                    bgColor="#6366F1"
                />
                <FileDetails
                    show={showdetails}
                    close={() => setShowDetails(false)}
                    // profile={profile}
                    fullName={fullName}
                    surname
                    email
                    size={size}
                    file={selectedFolder}
                />
                <Share
                    id={selectedId}
                    show={share}
                    close={() => setShare(false)}
                    setOpen={() => setShare(false)}
                    fileName={folderName}
                    file={selectedFolder}
                    type="folder"
                />
                <MoveFolder
                    id={selectedId}
                    show={move}
                    close={() => setMove(false)}
                    setOpen={() => setMove(false)}
                    fileName={folderName}
                    type="folder"
                    setToggleRefetch={() => setToggleRefetch((prevState) => !prevState)}
                />
            </div>
        </>
    );
};

export default InnerFolders;
