import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow } from '../../containers/ScreenContainers';

export const GoBack = ({ title, children, subTitle, subtitle, backLink, backAction } = {}) => {
    const navigate = useNavigate();
    const backRoute = backLink ? backLink : -1;
    const subtitleToUse = subtitle || subTitle;

    return (
        <FlexCentredRow
            onClick={backAction ? () => backAction() : () => navigate(backRoute)}
            className="hover:cursor-pointer gap-2 w-max"
        >
            <BackArrow />
            <Text size="1.6rem" color="#6366F1">
                {title}
            </Text>
            <Text size="1.6rem">/</Text>
            {subtitleToUse && <Text size="1.6rem">{subtitleToUse}</Text>}

            {children}
        </FlexCentredRow>
    );
};
