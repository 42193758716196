import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup } from '../../../../../components';
import { Button } from '../../../../../components/buttons';
import Checkbox from '../../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../../../components/shared';
import { CenteredContainer, FlexCentredRow, RelativeContainer } from '../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../../redux/admissions/actions';
import { getInternalCourseForm } from '../../../../../redux/course-form-magt/actions';
import { generateLevels } from '../../../../../utils';
import { getFullName } from '../../../../../utils/getInitials';

const fields = [
    {
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Name',
        accessorKey: 'name',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Matric No.',
        accessorKey: 'matricNumber',
        cell: (props) => <p>{props?.getValue()}</p>,
    },

    {
        header: 'Submission Status',
        accessorKey: 'status',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    // {
    //     header: 'HOD Status',
    //     // accessorKey: 'status',
    // },
    // {
    //     header: 'Dean Status',
    //     // accessorKey: 'status',
    // },
];

const CourseFormManagement = () => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.courseForm);
    const { currentDepartment } = useSelector((state) => state.department);
    const { sessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { departmentId, studyDuration, academicStructureId, academicStructure } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure?.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const levels = generateLevels(studyDuration);

    const [session, setSession] = useState(activeSession || '');
    const [students, setStudents] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [semester, setSemester] = useState(activeSemester?.split(' ')?.[0] || 'First');
    const [selectedLevel, setSelectedLevel] = useState('100');
    const [searchValue, setSearchValue] = useState('');

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const actionItemSemester = ['First', 'Second'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenDropdown(null);
        },
    }));

    const actionItemsLevel = levels.map((item) => ({
        name: `${item}`,
        click: () => {
            setSelectedLevel(item);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    useEffect(() => {
        if (session && lastDegreeId && selectedLevel && semester) {
            const getList = async () => {
                const res = await dispatch(
                    getInternalCourseForm({
                        academicStructureId,
                        lastDegreeId,
                        session,
                        semester,
                        level: selectedLevel,
                    }),
                );
                if (res) return setStudents(res);
                setStudents([]);
            };

            getList();
        }
    }, [session, lastDegreeId, dispatch, selectedLevel, academicStructureId, semester]);

    const modifiedCourseFormData = students?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            name: (
                <td style={{ color: '#2563EB' }}>
                    <Link to={`${item.studentId}`} state={{ session, semester, level: selectedLevel }}>
                        <p>{getFullName(item.lastName, item.firstName, item.middleName || '')}</p>
                    </Link>
                </td>
            ),
            status: (
                <td>
                    <StatusContainer style={getStatusStyles(item?.submissionStatus?.status?.toLowerCase())}>
                        <span>{item?.submissionStatus?.status}</span>
                    </StatusContainer>
                </td>
            ),
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                pageTitle="Course Form Management"
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                tableUtilsChildren={
                    <FlexCentredRow className=" gap-[1.6rem]">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'sessions'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSessions}
                            />
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('semester')}
                            >
                                {`${semester} Semester` || 'Semester'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'semester'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSemester}
                            />
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('level')}
                            >
                                {`${selectedLevel} Level` || 'Level'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'level'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemsLevel}
                            />
                        </RelativeContainer>{' '}
                    </FlexCentredRow>
                }
                data={modifiedCourseFormData || []}
                fields={fields}
                loading={isLoading}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No students yet"></ListEmptyContent>
                    </CenteredContainer>
                }
                isActionable
            />
        </>
    );
};

export default CourseFormManagement;
