import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getColleges } from '../redux/college/actions';
import { getFaculties } from '../redux/faculty/actions';

// selectedAcadUnit => college or faculty or school
const useGetFirstDegrees = (selectedAcadUnit) => {
    const dispatch = useDispatch();

    const { isLoading: facLoading, faculties } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);

    useEffect(() => {
        if (selectedAcadUnit) {
            const currStructName = selectedAcadUnit?.toLowerCase() || '';

            if (currStructName === 'college') {
                dispatch(getColleges());
            }
            if (currStructName === 'faculty') {
                dispatch(getFaculties());
            }
        }
    }, [dispatch, selectedAcadUnit]);

    return {
        isLoading: facLoading || collegeLoading,
        firstDegrees: faculties,
    };
};

export default useGetFirstDegrees;
