import { capitalizeFirstLetter } from '../../utils';

// Function to extract the 2nd word from the 'structure' field
export const extractSecondWord = (structure) => {
    const words = structure.split('-');
    return words.length >= 2 ? words[1] : null;
};

export const shortenToString = (data = []) => {
    const strItems = data.map((item) => {
        if (data.length === 1) {
            return item; // Use the full length version if there's only one item
        } else {
            // Shorten the name to the first three characters
            return item.slice(0, 3);
        }
    });

    return strItems.join('/'); // Join the names with slashes
};

export const getUniqueSecondDegrees = (data) => {
    // Collect unique 2nd words across all categories
    const uniqueSecondWords = new Set();

    for (const category in data) {
        const categoryObjects = data[category];

        for (const obj of categoryObjects) {
            const secondWord = extractSecondWord(obj.structure);
            if (secondWord) {
                uniqueSecondWords.add(capitalizeFirstLetter(secondWord));
            }
        }
    }

    return Array.from(uniqueSecondWords);
};
