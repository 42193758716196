import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    email: Yup.string().nullable().required('Required'),
    address1: Yup.string().nullable().required('Required'),
    address2: Yup.string().nullable().required('Required'),
    city: Yup.string().nullable().required('Required'),
    zipcode: Yup.string().nullable().required('Required'),
    state: Yup.string().nullable().required('Required'),
    country: Yup.string().nullable().required('Required'),
});
