import { PERSONNEL } from '../../utils/unitNames';
import {
    AcademicCalendar,
    // AddAdministrator,
    Administrator,
    CreateRole,
    CreateStaff,
    DesignationDetails,
    Designations,
    EditAdministrator,
    EditStaff,
    OrganizationUnit,
    OrganizationUnitDetails,
    Received,
    Roles,
    Staff,
    StaffDetails,
    StaffManagement,
    StaffDetail,
    EditRole,
    GeneralSettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    Appointment,
    AppointmentDetails,
    OrganizationAdmins,
    AddOrgAdministrator,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';

export const personnelRoutes = [
    {
        path: '/personnel/access',
        element: (
            <Access
                staffs={<Staff unitName={PERSONNEL} />}
                administrators={<Administrator unitName={PERSONNEL} />}
                roles={<Roles unitName={PERSONNEL} />}
            />
        ),
    },
    {
        path: '/personnel/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/personnel/access/create-role',
        element: <CreateRole unitName={PERSONNEL} />,
    },
    {
        path: '/personnel/access/edit-role',
        element: <EditRole unitName={PERSONNEL} />,
    },
    {
        path: '/personnel/access/add-administrator',
        // element: <AddAdministrator unitName={PERSONNEL} />,
        element: <AssignRole unitName={PERSONNEL} />,
    },
    {
        path: '/personnel/access/edit-administrator',
        element: <EditAdministrator unitName={PERSONNEL} />,
    },
    {
        path: '/personnel/staff-management',
        element: <StaffManagement unitName={PERSONNEL} />,
    },
    {
        path: '/personnel/staff-management/add-staff',
        element: <CreateStaff />,
    },
    {
        path: '/personnel/staff-management/edit-staff',
        element: <EditStaff />,
    },
    {
        path: '/personnel/staff-management/:id',
        element: <StaffDetails />,
    },
    {
        path: '/personnel/organisation-units',
        element: <OrganizationUnit />,
    },
    {
        path: '/personnel/organisation-units/:id',
        element: <OrganizationUnitDetails />,
    },
    {
        path: '/personnel/organisation-admins',
        element: <OrganizationAdmins withCreate />,
    },
    {
        path: '/personnel/organisation-admins/add',
        element: <AddOrgAdministrator />,
    },
    {
        path: '/personnel/designations',
        element: <Designations />,
    },
    {
        path: '/personnel/designations/:id',
        element: <DesignationDetails />,
    },
    {
        path: '/personnel/communications/received',
        element: <Received unitName={PERSONNEL} />,
    },
    {
        path: '/personnel/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/personnel/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/personnel/communications/distribution-list',
        element: <DistributionList unitName={PERSONNEL} title="Communications" />,
    },
    {
        path: '/personnel/communications/distribution-list/add',
        element: <AddDistributionList unitName={PERSONNEL} title="Communications" />,
    },
    {
        path: '/personnel/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={PERSONNEL} title="Communications" />,
    },
    {
        path: '/personnel/appointments',
        element: <Appointment />,
    },
    {
        path: '/personnel/appointments/:id',
        element: <AppointmentDetails />,
    },
    {
        path: '/personnel/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/personnel/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/personnel/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/personnel/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
