import React from 'react';

import { Text } from '../../../containers/MesssageContainers';

const Overview = ({ values, isDraft }) => {
    const overviewValues = [
        {
            label: 'Type',
            value: values?.type,
        },
        {
            label: 'Title',
            value: values?.title,
        },
        {
            label: 'Format',
            value: values?.format,
        },
        ...(isDraft
            ? []
            : [
                  {
                      label: 'Acknowledgement Status',
                      value: '',
                  },
              ]),
        {
            label: 'Distribution List',
            value: values?.visibleTo,
        },
    ];
    return (
        <div>
            <div className="bg-white p-[2.4rem] rounded-[0.8rem] flex flex-col gap-[2.8rem]">
                <Text align="left" weight="600" size="1.9rem" color="#1F2937">
                    Overview
                </Text>
                <div className={`grid ${isDraft ? 'grid-cols-3' : 'grid-cols-4'} gap-[2.8rem]`}>
                    {overviewValues?.map((item) => (
                        <div className="flex flex-col gap-[4px] w-max">
                            <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.label}
                            </Text>
                            {typeof item?.value === 'string' ? (
                                <Text
                                    width="max-content"
                                    transform="capitalize"
                                    align="left"
                                    size="1.4rem"
                                    weight="500"
                                    color="#4B5563"
                                >
                                    {item?.value}
                                </Text>
                            ) : (
                                item?.value?.map((val) => (
                                    <div className="py-[2px] px-[12px] bg-[#E1EFFE] rounded-[6px] flex items-center w-max gap-[4px]">
                                        <Text color="#1E429F" weight="500" size="1.4rem">
                                            {val?.distributionName}
                                        </Text>
                                    </div>
                                ))
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Overview;
