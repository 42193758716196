import React, { useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusWhite } from '../../../assets/icons/plus-white.svg';
import { ChooseColumnsToExport, ExportSheetDialogue, UploadFile } from '../../../components';
import { Button } from '../../../components/buttons';
import AddNewFacultyForm from '../../../components/forms/add-new-faculty';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, ItemsPerPageCont } from '../../../containers/ScreenContainers';
import { hostelData } from '../../../data/hostelData';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Hostel Name',
        key: 'name',
    },
    {
        label: 'Gender',
        key: 'gender',
    },
    {
        label: 'No of rooms',
        key: 'rooms',
    },
    {
        label: 'Avg. Bedspace per Room',
        key: 'bedspace',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const HostelList = () => {
    const navigate = useNavigate();
    const [isLoading /*setIsLoading*/] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(hostelData?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);

    return (
        <div>
            <FlexRowSpaceBetween>
                <PageTitle>Hostel Management</PageTitle>
                <Button onClick={() => navigate('/hostel/new-hostel')} bgColor="#6366F1" color="#fff">
                    <PlusWhite className="mr-[1.15rem]" />
                    Add New Hostel
                </Button>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                <CDataTable
                    items={hostelData || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => navigate(`/hostel/hostel-list/${item.id}`)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => (
                            <td style={{ color: '#2563EB' }} className="cursor-pointer">
                                {item.name}
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <AddNewFacultyForm show={showForm} setShow={setShowForm} />
            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default HostelList;
