import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../../../components/inputs/radio';
import TableWithUtils from '../../../../components/table-with-utils';
import useDebounce from '../../../../hooks/useDebounce';
import useSearch from '../../../../hooks/useSearch';
import { getStudentsInResultsBacklog } from '../../../../redux/exam-magt/actions';

const Students = ({
    session,
    semester,
    firstDegree,
    secondDegree,
    level,
    curriculumId,
    selectedStudent,
    handleSelectStudent,
}) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.examMagt);

    const [students, setStudents] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const filteredStudents = useSearch(debouncedSearchValue, students, ['name']);

    useEffect(() => {
        if (semester && session && firstDegree && secondDegree && level && curriculumId) {
            const getData = async () => {
                const params = {
                    level,
                    session,
                    curriculumId,
                    academicStructureId: firstDegree.academicStructureId,
                    lastDegreeId: secondDegree._id,
                    semester: semester.toLowerCase(),
                };

                const res = await dispatch(getStudentsInResultsBacklog(params));
                if (res) {
                    return setStudents(res?.data || []);
                }
                return setStudents([]);
            };

            getData();
        }else{
            return setStudents([]);

        }
    }, [curriculumId, dispatch, firstDegree, level, secondDegree, semester, session]);

    const fields = [
        {
            accessorKey: 'action',
            header: <RadioInput />,
            size: 5,
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Full Name',
            accessorKey: 'name',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Matric Number',
            accessorKey: 'matricNumber',
        },
    ];
    const modifiedStudents = filteredStudents?.map((item) => {
        return {
            ...item,
            action: (
                <td>
                    <RadioInput
                        checked={selectedStudent?.studentId === item?.studentId}
                        onClick={() => handleSelectStudent(item)}
                    />
                </td>
            ),
        };
    });

    return (
        <TableWithUtils
            searchable
            showTableUtils
            pageTitle="Students"
            searchValue={searchValue}
            onSearchChange={(e) => setSearchValue(e.target.value)}
            onSearchClose={() => setSearchValue('')}
            data={modifiedStudents || []}
            fields={fields}
            loading={isLoading}
        />
    );
};

export default Students;
