import React, { useEffect } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { Button } from '../../../../components/buttons';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { getPaymentSuccess } from '../../../../redux/ict/actions';

const fields = [
    {
        header: 'Issued on',
        accessorKey: 'issuedOn',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Item',
        accessorKey: 'item',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Total',
        accessorKey: 'total',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Status',
        accessorKey: 'status',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: '',
        accessorKey: 'button',
        cell: (props) => <p>{props.getValue()}</p>,
    },
];

const InvoiceHistory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPaymentSuccess());
    }, [dispatch]);

    const Invoice = [
        {
            title: 'Invoice',
            subText: 'Check your current invoice status, edit billing information, and download earlier invoices.',
            titleSize: '2.3rem',
            titleWeight: '600',
            subTextSize: '1.4rem',
            subTextWeight: '500',
            titleColor: '#1F2937',
            buttonText: '',
            route: '',
            className: 'pb-[2.4rem]',
        },
        {
            title: '$15.00 will be attempted on 17 Mar 2023',
            subText: 'Payment will be made via mastercard ending 6916',
            titleSize: '1.9rem',
            titleWeight: '500',
            subTextSize: '1.6rem',
            subTextWeight: '500',
            titleColor: '#4B5563',
            route: '',
            buttonText: 'Change Payment Method',
            className: 'flex items-center justify-between py-[3.6rem]',
        },
        {
            title: 'Billing information',
            subText: ['Email: abisoye@belrald.com', 'Address: Akobo', 'Ibadan Oyo 200223', 'NG'],
            titleSize: '1.9rem',
            titleWeight: '500',
            subTextSize: '1.4rem',
            titleColor: '#4B5563',
            subTextWeight: '500',
            route: 'edit-billing',
            buttonText: 'Edit Billing Information',
            className: 'flex items-center justify-between pt-[3.6rem]',
        },
    ];
    return (
        <div className="pb-[3.8rem]">
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[8px] pb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">View Invoice History</Text>
            </FlexCentredRow>
            <div className="p-[2.4rem] bg-white">
                {Invoice?.map((data, i) => (
                    <>
                        <div key={i} className={data.className}>
                            <div className="flex flex-col gap-[8px]">
                                <Text
                                    align="left"
                                    color={data.titleColor}
                                    size={data.titleSize}
                                    weight={data.titleWeight}
                                >
                                    {data.title}
                                </Text>
                                {typeof data.subText === 'object' ? (
                                    <div className="flex flex-col gap-[8px]">
                                        {data.subText?.map((text, i) => (
                                            <Text
                                                key={i}
                                                align="left"
                                                color="#6B7280"
                                                size={data.subTextSize}
                                                weight={data.subTextWeight}
                                            >
                                                {text}
                                            </Text>
                                        ))}
                                    </div>
                                ) : (
                                    <Text
                                        align="left"
                                        color="#6B7280"
                                        size={data.subTextSize}
                                        weight={data.subTextWeight}
                                    >
                                        {data.subText}
                                    </Text>
                                )}
                            </div>
                            {data.buttonText && (
                                <Button onClick={() => navigate(data.route)} color="#3B82F6">
                                    {data.buttonText}
                                </Button>
                            )}
                        </div>
                        <div className="h-[1px] bg-[#D1D5DB] last:hidden"></div>
                    </>
                ))}
            </div>
            <div className="mt-[2.4rem] bg-white p-[2.4rem] rounded-[8px]">
                <Text align="left" size="2.3rem" weight="600" color="#6B7280">
                    Invoice History
                </Text>
                <Table data={[]} columns={fields} />
            </div>
        </div>
    );
};

export default InvoiceHistory;
