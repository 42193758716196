export const LevelList = [];

export const typeOfFee = [
    {
        name: 'Tuition Fee',
        value: 'Tuition-Fee',
    },
    {
        name: 'Acceptance Fee',
        value: 'Acceptance-Fee',
    },
    {
        name: 'Medical Fee',
        value: 'Medical-Fee',
    },
    // {
    //     name: 'Accommodation Fee',
    //     value: 'Accommodation-Fee',
    // },
    // {
    //     name: 'Sport Fee',
    //     value: 'Sport-Fee',
    // },
    {
        name: 'Direct Student Payment',
        value: 'Direct-Student',
    },
    {
        name: 'Other Fee',
        value: 'Other-Fee',
    },
];
export const Target = [
    {
        name: 'Institution Wide',
        value: 'Institution Wide',
    },
    {
        name: 'Academic Structure',
        value: 'Academic-Structure',
    },
    {
        name: 'Newly Admitted Students',
        value: 'Newly-Admitted-Students',
    },
    {
        name: 'Matric Number',
        value: 'MatricNumber',
    },
];
export const installmentOpt = ['2', '3', '4', '5'];

export const FilterLevel = ['Direct-Student', 'Institution Wide', 'Acceptance-Fee'];

export const paymentScheduleData = [{ name: 'One-Off' }, { name: 'Installment' }];

export const Span = [{ name: 'Session' }, { name: 'Semester' }];

export const installmentPlan = [
    { name: 'Percentage Based (Institution sets a ratio percentage)', value: 'percent' },
    { name: "Student's Choice (Student determines the amount to pay in each installment)", value: 'student' },
];
