export const personnelNavLinks = [
    { title: 'Staff Management', to: '/personnel/staff-management' },
    {
        title: 'Communications',
        to: '/personnel/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/personnel/communications/compose',
            },
            {
                title: 'Received',
                to: '/personnel/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/personnel/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/personnel/communications/settings',
            },
        ],
    },
    { title: 'Designations', to: '/personnel/designations' },
    { title: 'Organization Units', to: '/personnel/organisation-units' },
    { title: 'Unit Administrators', to: '/personnel/organisation-admins' },
    {
        title: 'Appointments',
        to: '/personnel/appointments',
    },
    {
        title: 'Access',
        to: '/personnel/access',
    },
    {
        title: 'Settings',
        to: '/personnel/settings',
    },
];
export const personnelRoutes = personnelNavLinks.map((item) => item.to);
