import React, { memo, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info.svg';
import { Checkbox, SearchInput } from '../../../../../components';
import { FilterContainer } from '../../../../../containers/ScreenContainers';
import { getBroadsheet } from '../../../../../redux/exam-magt/actions';

const Th = styled.th`
    overflow: auto;
    border-width: 1px;
    padding: 8px;

    & > svg {
        display: inline-block;
    }
`;

const Td = tw.td`
    border
    border-gray-100
`;

const Broadsheet = memo(() => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { level } = useParams();

    const { item: { curriculumId } = {}, semester = '', session = '' } = state || {};

    const [searchValue, setSearchValue] = useState('');

    const [broadsheetData, setBroadsheetData] = useState([]);

    const courses = broadsheetData?.curriculum?.map((course) => course) || [];
    const sortedCourses = courses.sort((a, b) => a?.courseId?.localeCompare(b?.courseId));
    const [cocLength, setCocLength] = useState(1);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const handleChangeCocLength = (items = []) => {
        if (items?.length > cocLength) {
            setCocLength(items?.length);
        }
        return;
    };

    const handleSelectStudent = (student) => {
        if (student?.studentId === selectedStudent?.studentId) return setSelectedStudent(null);
        setSelectedStudent(student);
    };

    const getData = async () => {
        const res = await dispatch(
            getBroadsheet({
                session,
                level,
                curriculumId,
                semester: semester.toLowerCase(),
            }),
        );
        if (res) {
            setBroadsheetData(res);
        }
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curriculumId, dispatch, level, semester, session]);

    return (
        <>
            <div className="mt-[2.4rem] text-base shadow-lg rounded-t-lg bg-[white] pb-[1.8rem] w-full overflow-x-auto">
                <FilterContainer>
                    <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                </FilterContainer>
                <div className="text-center text-[#4B5563]">
                    <table className="min-w-full border border-gray-400 overflow-auto">
                        {/* Table Headers */}
                        <thead className="overflow-auto">
                            {/* Header Row 1 */}
                            <tr className="font-semibold overflow-auto">
                                <Th></Th>
                                <Th colSpan={courses?.length} className="border p-2 overflow-auto">
                                    Main Course
                                </Th>
                                <Th colSpan={cocLength}>
                                    COC <InfoIcon />
                                </Th>
                                <Th colSpan="4"></Th>
                                <Th colSpan="4"></Th>
                                <Th></Th>
                                <Th></Th>
                            </tr>

                            {/* Header Row 2 */}
                            <tr>
                                <Th>Course Code</Th>
                                {sortedCourses?.map((course) => (
                                    <Th key={course?.courseCode}>{course?.courseCode}</Th>
                                ))}
                                <Th colSpan={cocLength}></Th>

                                <Th colSpan="4">Previous CGPA</Th>
                                <Th colSpan="4">Current CGPA</Th>
                                <Th>Academic Standing</Th>
                                <Th>Honour List</Th>
                            </tr>

                            {/* Header Row 3*/}
                            <tr>
                                <Th>Course Unit</Th>
                                {sortedCourses?.map((course) => (
                                    <Th key={course?.courseCode}>{course?.courseUnit}</Th>
                                ))}
                                <Th colSpan={cocLength}></Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th></Th>
                                <Th></Th>
                            </tr>
                            {/* Header Row 4 */}
                            <tr>
                                <Th>Matric No.</Th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="">
                            {broadsheetData?.data?.map((student) => {
                                const sortedStudentCourses = student?.courses?.sort((a, b) =>
                                    a.courseId.localeCompare(b.courseId),
                                );
                                const carryOvers = sortedStudentCourses?.filter((course) => course?.carriedOver);
                                handleChangeCocLength(carryOvers);

                                const resultArrayLength = student?.result?.length;
                                const prevResult = student?.result?.[resultArrayLength - 2];
                                const currentResult = student?.result?.[resultArrayLength - 1];

                                return (
                                    <tr key={student.studentId} className="">
                                        <Td className="min-w-[150px] flex items-center">
                                            <Checkbox
                                                checked={selectedStudent?.studentId === student?.studentId}
                                                onChange={() => handleSelectStudent(student)}
                                            />
                                            <p className="m-0">{student.name}</p>
                                        </Td>
                                        {sortedStudentCourses?.map((course) => (
                                            <Td key={course.courseId}>
                                                {course.status === 'Registered' ? course.totalScore : 'NR'}
                                            </Td>
                                        ))}
                                        <Td className="flex gap-2 gap-pt-2 items-center border-0">
                                            {carryOvers?.map((course) => (
                                                <p className="grid" key={course.courseCode}>
                                                    <span>{course.courseCode}</span>
                                                    <span>{course.totalScore}</span>
                                                </p>
                                            ))}
                                        </Td>
                                        <Td>{prevResult?.totalUnitsRegistered}</Td>
                                        <Td>{prevResult?.totalUnitsPassed}</Td>
                                        <Td>{prevResult?.totalWeightPassed}</Td>
                                        <Td>{prevResult?.cumulativeGradePointAverage}</Td>
                                        <Td>{currentResult?.totalUnitsRegistered}</Td>
                                        <Td>{currentResult?.totalUnitsPassed}</Td>
                                        <Td>{currentResult?.totalWeightPassed}</Td>
                                        <Td>{currentResult?.cumulativeGradePointAverage}</Td>
                                        <Td>{student?.academicStanding ? 'GS' : 'NGS'}</Td>
                                        <Td>{student?.honourDivision}</Td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
});

export default Broadsheet;
