import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';

import { PageTitle } from '../../containers/MesssageContainers';
import { parseJSON, stringifyJSON } from '../../utils/useJSON';

const Access = ({ staffs, administrators, roles }) => {
    const savedActiveTab = parseJSON('activeTab');
    const [pageTitle, setPageTitle] = useState(savedActiveTab || 'Staff');
    const [activeTab, setActiveTab] = useState(savedActiveTab || '');

    const handleTabClick = (tab) => {
        stringifyJSON('activeTab', tab);
        setPageTitle(tab);
        setActiveTab(tab);
    };

    useEffect(() => {
        if (savedActiveTab) {
            setActiveTab(savedActiveTab);
        } else {
            setActiveTab('Staff');
        }
    }, [activeTab, savedActiveTab, pageTitle]);

    return (
        <div>
            <PageTitle align="left" bottom="2rem">
                {pageTitle}
            </PageTitle>
            <CTabs activeTab={activeTab}>
                <CNav variant="tabs">
                    <CNavItem onClick={() => handleTabClick('Staff')}>
                        <CNavLink data-tab="Staff">Staff</CNavLink>
                    </CNavItem>
                    <CNavItem onClick={() => handleTabClick('Administrators')}>
                        <CNavLink data-tab="Administrators">Administrators</CNavLink>
                    </CNavItem>
                    <CNavItem onClick={() => handleTabClick('Roles')}>
                        <CNavLink data-tab="Roles">Roles</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane data-tab="Staff">{staffs}</CTabPane>
                    <CTabPane data-tab="Administrators">{administrators}</CTabPane>
                    <CTabPane data-tab="Roles">{roles}</CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Access;
