import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '../../components/inputs/checkbox';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getRolesInUnit } from '../../redux/roles/actions';
import { getStaffInUnit } from '../../redux/staff/actions';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Name',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Staff Number',
        accessorKey: 'staffNumber',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Email Address',
        accessorKey: 'staffEmail',
    },
];

const Staff = ({ unitName }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.staff);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [staff, setStaff] = useState([]);
    const [roles, setRoles] = useState([]);
    const [roleQuery, setRoleQuery] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(null);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        }
        if (roleQuery) {
            queryParams.role = roleQuery;
        }

        return queryParams;
    }, [debouncedSearchValue, roleQuery]);

    const rolesActionItems = roles?.map((role) => {
        return {
            name: role?.role,
            click: () => {
                setRoleQuery(role?.role);
                setDropdownOpen(null);
            },
        };
    });

    useEffect(() => {
        const getRoles = async () => {
            const data = await dispatch(getRolesInUnit(unitName));
            data && setRoles(data);
        };

        getRoles();
    }, [dispatch, unitName]);

    useEffect(() => {
        if (unitName) {
            const getDetails = async () => {
                const data = await dispatch(getStaffInUnit({ query }));
                data && setStaff(data);
            };

            getDetails();
        }
    }, [unitName, dispatch, query]);

    // const handleResetClick = () => {
    //     setRoleQuery('');
    //     setSearchValue('');
    // };

    const modifiedStaffData = staff?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            name: (
                <td>
                    {item.surname} {item.firstName}
                </td>
            ),
            staffNumber: <td>{item.staffNumber}</td>,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                filterItems={rolesActionItems}
                data={modifiedStaffData || []}
                fields={fields}
                loading={isLoading}
                openFilter={dropdownOpen}
                isActionable
            />
        </>
    );
};

export default Staff;
