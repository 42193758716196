// Pages that can only be visited when user is authenticated

// import {  } from '../../utils/unitNames';
import { Configuration, GeneralInformation, AcademicConfiguration } from '../../views';

export const configurationsRoutes = [
    {
        path: '/configuration',
        element: <Configuration />,
    },
    {
        path: '/configuration/general',
        element: <GeneralInformation />,
    },
    {
        path: '/configuration/academic',
        element: <AcademicConfiguration />,
    },
];
