import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    academicSession: Yup.string().nullable().required('Required'),
    semester: Yup.string().nullable().required('Required'),
    week: Yup.string().nullable().required('Required'),
    activity: Yup.string().nullable().required('Required'),
    from: Yup.string().nullable().required('Required'),
    to: Yup.string().nullable().required('Required'),
});
