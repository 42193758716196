import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 33.8rem;
        max-height: 60rem;
    }
`;

const ItemsContainer = tw.ul`
overflow-y-auto
border-b
pb-2
// flex
// gap-4
`;

const ReceiversList = ({ show, close, Receivers }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    List of receivers
                </Text>
                <CloseIcon onClick={close} />
            </FlexRowSpaceBetween>
            <Text align="start" size="1.6rem" color="#1F2937" top="1.6rem" lineHeight="2.4rem" bottom="3.2rem">
                Receivers
            </Text>
            <ItemsContainer>
                {Receivers?.map((item, i) => (
                    <Text
                        key={i}
                        align="start"
                        size="1.6rem"
                        color="#374151"
                        top="1.6rem"
                        lineHeight="2.4rem"
                        weight="400"
                    >
                        {item}
                    </Text>
                ))}
            </ItemsContainer>

            <FlexRowEnd className="gap-x-[16px]">
                <Button onClick={close} border="0.5px solid #D1D5DB">
                    Close
                </Button>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ReceiversList;
