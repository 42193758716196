import React from 'react';

import EditSpecialLetterForm from '../../../../components/forms/edit-special-letter';

const Edit = () => {
    return (
        <>
            <EditSpecialLetterForm />
        </>
    );
};

export default Edit;
