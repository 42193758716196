import React from 'react';

import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

const Card = tw.div`
    bg-white
    p-[2rem]
    mb-[2.4rem]
    no-underline
    rounded-[2rem]
`;

const ITEMS = [
    {
        title: 'Matric Number Settings',
        subtitle: 'Add and manage distribution lists for communications',
        link: 'matric-number',
    },
];

const AdmissionSettings = () => {
    return (
        <div>
            <PageTitle bottom="4rem" align="left">
                Settings
            </PageTitle>
            {ITEMS.map((item) => (
                <Link to={item.link} key={item.link}>
                    <Card>
                        <Text weight="600" align="left" size="1.8rem">
                            {item.title}
                        </Text>
                        <FlexRowSpaceBetween>
                            <Text color="#6B7280" align="left" size="1.4rem">
                                {item.subtitle}
                            </Text>
                        </FlexRowSpaceBetween>
                    </Card>
                </Link>
            ))}
        </div>
    );
};

export default AdmissionSettings;
