import React, { useState } from 'react';

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/ChevronRight.svg';
import { ReactComponent as Minus } from '../../../../assets/icons/Minus.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/Plus2.svg';
import { Button } from '../../../../components/buttons';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';

const RemoveSeat = () => {
    const navigate = useNavigate();
    const [seats, setSeats] = useState(0);
    return (
        <div>
            <FlexCentredRow
                onClick={() => {
                    navigate(-1);
                }}
                className="hover:cursor-pointer gap-[8px] pb-[3.6rem]"
            >
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem" weight="400">
                    /
                </Text>
                <Text size="1.6rem" weight="400">
                    Remove Seat
                </Text>
            </FlexCentredRow>
            <div className="bg-white p-[2.4rem]">
                <div className="">
                    <Text align="left" size="2.3rem" weight="600">
                        Remove Seats
                    </Text>
                    <Text align="left" size="1.4rem" weight="500" color="#6B7280" top="0.8rem">
                        $4 for each seat / month
                    </Text>
                    <div className="h-[1px] bg-[#D1D5DB] mt-[2.4rem] mb-[3.6rem]"></div>
                </div>
                <div className="">
                    <Text align="left" size="1.6rem" weight="400" color="#4B5563">
                        There are currently 2,600 students in this organization for whom you will need to purchase
                        seats. You can remove Students if you’d like to pay for fewer seats.
                        <br />
                        <br />
                        Your changes will take effect on <span className="font-semibold">Febuary 01, 2023</span>
                    </Text>
                    <div className="h-[1px] bg-[#D1D5DB] mt-[2.4rem] mb-[2.4rem]"></div>
                </div>
                <div className="">
                    <div className="flex items-center gap-[2.5rem]">
                        <Text align="left" size="1.4rem" weight="500">
                            3000
                        </Text>
                        <ArrowRight />
                        <div className="w-[20.1rem] flex items-center border-[1px] border-[#9CA3AF] rounded-[4px]">
                            <div
                                onClick={() => {
                                    if (seats - 1 < 0) {
                                        setSeats('0');
                                    } else {
                                        setSeats(seats - 1);
                                    }
                                }}
                                className="flex cursor-pointer items-center justify-center border-r-[1px] border-[#9CA3AF] p-[8px]"
                            >
                                <Minus />
                            </div>
                            <div className="flex-1 flex items-center justify-center">
                                <input
                                    value={seats}
                                    onChange={(e) => {
                                        if (!isNaN(+e.target.value)) {
                                            setSeats(e.target.value);
                                        } else {
                                            toast.error('Only number input is accepted');
                                        }
                                    }}
                                    type="text"
                                    className="outline-none w-[11rem] text-center focus-none text-[#6B7280] text-[1.4rem] font-normal"
                                />
                            </div>
                            <div
                                onClick={() => {
                                    setSeats(seats + 1);
                                }}
                                className="flex cursor-pointer items-center justify-center border-l-[1px] border-[#9CA3AF] p-[8px]"
                            >
                                <Plus />
                            </div>
                        </div>
                    </div>
                    <div className="h-[0.4px] bg-[#D1D5DB] mt-[2.4rem] mb-[2.4rem]"></div>
                </div>
                <div className="">
                    <Text
                        align="left"
                        size="1.2rem"
                        color="#6B7280"
                        lineHeight="1.7rem"
                        bottom="2.4rem"
                        className="w-[40.4rem]"
                    >
                        You’re removing 400 seats, bringing you down to a total of 2,600 seats. Your next payment of
                        $1,789 will be due 1 Febuary 2023.
                    </Text>
                    <div className="flex items-center gap-[2.4rem]">
                        <Button bgColor="#EF4444" color="#fff">
                            Remove seats
                        </Button>
                        <Button bgColor="#fff" color="#1F2937" border="1px solid #D1D5DB">
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoveSeat;
