import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { Button } from '../../../components/buttons';
import Checkbox from '../../../components/inputs/checkbox';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Full Name',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Matric No.',
        accessorKey: 'matric',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Level',
        accessorKey: 'level',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Session',
        accessorKey: 'year',
    },
];

const ExamsRecordsStudents = () => {
    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading /*setIsLoading*/] = useState(false);
    const [setIsListEmpty] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const modifiedStudentsRecords = []?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            name: (
                <td style={{ color: '#2563EB' }} className="cursor-pointer">
                    {item.name}
                </td>
            ),
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                backTitle="Exams & Records / Departments / Students"
                onActionBtnClick={() => setDropdownOpen(!dropdownOpen)}
                pageTitle="Department of Computer Science"
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedStudentsRecords || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students " subtitle="Create new Student(s)">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button bgColor="#10B981" color="#fff">
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                onRowClick={(item) => navigate(`/exams-records/records/students/${item.id}`)}
                loading={isLoading}
                isActionable
            />
        </>
    );
};

export default ExamsRecordsStudents;
