import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allAdmins: [],
};

export const administratorsSlice = createSlice({
    name: 'administrators',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_ADMINS: (state, action) => {
            state.allAdmins = action.payload;
        },
    },
});

export const { GET_ALL_ADMINS, LOADING, STOP_LOADING } = administratorsSlice.actions;

export default administratorsSlice.reducer;
