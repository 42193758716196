import React from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Button } from '../../../../../components/buttons';
import { Text } from '../../../../../containers/MesssageContainers';

const Container = tw.div`
p-[2.4rem]
bg-white
rounded-[1.6rem]
flex
flex-col
gap-[1.6rem]
`;

const Details = tw.div`
flex
flex-col
gap-[1.6rem]
border-b-[1px]
py-[1.6rem]
border-b-[#E5E7EB]
last:border-b-[0px]
`;

const AlternateInfo = ({ alternateContact }) => {
    const navigate = useNavigate();

    const contactDetails = [
        {
            title: 'Operations',
            name: alternateContact?.name,
            position: alternateContact?.position,
            email: alternateContact?.email,
            phone: alternateContact?.phoneNumber,
        },
        {
            title: 'Security',
            name: alternateContact?.securityName,
            position: alternateContact?.securityPosition,
            email: alternateContact?.securityEmail,
            phone: alternateContact?.securityPhoneNumber,
        },
        {
            title: 'Billing',
            name: alternateContact?.billingName,
            position: alternateContact?.billingPosition,
            email: alternateContact?.billingEmail,
            phone: alternateContact?.billingPhoneNumber,
        },
    ];
    return (
        <Container>
            <div className="flex items-center justify-between">
                <Text align="left" size="1.9rem" weight="600">
                    Service Contact
                </Text>
                <Button type="button" onClick={() => navigate('edit-alternate-contact')} bgColor="#6366F1" color="#FFF">
                    Edit
                </Button>
            </div>
            {contactDetails?.map((data, i) => (
                <Details key={i}>
                    <Text align="left" weight="500" size="1.6rem">
                        {data.title}
                    </Text>
                    <Text align="left" weight="400" size="1.4rem">
                        {data.name}
                    </Text>
                    <Text align="left" weight="400" size="1.4rem">
                        {data.position}
                    </Text>
                    <Text align="left" weight="400" size="1.4rem">
                        {data.email}
                    </Text>
                    <Text align="left" weight="400" size="1.4rem">
                        {data.phone}
                    </Text>
                </Details>
            ))}
        </Container>
    );
};

export default AlternateInfo;
