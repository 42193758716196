import React /*useState*/ from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { createAdminDomain } from '../../../../redux/ict/actions';

const ValidationSchema = Yup.object().shape({
    subDomainName: Yup.string().nullable().required('Required'),
});

const AddAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.ict);

    return (
        <>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem]">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Settings
                    </PageTitle>
                    <Text left="1rem" size="1.6rem">
                        / Custom Domains
                    </Text>
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <FlexCentredRow className="mb-[5.8rem] gap-x-[1rem]">
                <PageTitle weight="600" size="3.3rem">
                    Custom Domains
                </PageTitle>
            </FlexCentredRow>

            <Text align="none" weight="700" size="1.9rem">
                Lecturer Portal Domain URL
            </Text>
            <Text as="span" size="1.5rem" weight="400" lineHeight="2.8rem">
                Enter the Url of the domain you want to add.
            </Text>

            <Formik
                initialValues={{
                    portalType: 'lecturer',
                    subDomainName: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const res = await dispatch(createAdminDomain(values));
                    if (res) {
                        navigate(`/ict/settings/add-lecturer/lecturer-proceed?name=${values.subDomainName}`);
                    }
                }}
            >
                {({ errors, handleChange, values }) => (
                    <Form>
                        <div className="gap-5 flex flex-col">
                            <TextInput
                                className="bg-[inherit]"
                                name="subDomainName"
                                type="text"
                                placeholder="admin.organisation.com"
                                onChange={handleChange}
                                errors={errors}
                            />
                        </div>
                        <FlexRowEnd className="p-[1.6rem] gap-[2rem]">
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton type="submit" loading={isLoading} bgColor="#6366F1" color="#FFF">
                                Proceed
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddAdmin;
