import React from 'react';

import bgLogo from '.././../assets/background.png'; 

const InputContainer = ({ children }) => {
    return (
        <div
            className="flex justify-center items-center flex-col bg-center bg-no-repeat w-[40%] lg:w-[40%] md:w-[80%] h-screen"
            style={{
                backgroundImage: `url(${bgLogo})`,
                backgroundSize: 'contain',
            }}
        >
            {children}
        </div>
    );
};

export default InputContainer;