import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as EmptyState } from '../../../../../assets/icons/empty-state.svg';
import ListEmptyContent from '../../../../../components/list-empty';
import { Text } from '../../../../../containers/MesssageContainers';
import { CenteredContainer, FlexCentredRow, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../layout/page-layout/PageLayout';

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mt-[1.6rem]
`;

const fields = [
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
        cell: (props) => <p>{props?.getValue()}</p>,
    },

    {
        header: 'Unit',
        key: 'unit',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Cont.Assessment',
        key: 'contAssessment',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Exams',
        key: 'exams',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Total',
        key: 'total',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
];

const Result = () => {
    return (
        <div>
            <Details>
                <FlexRowSpaceBetween className="px-[1rem]">
                    <Text color="#9CA3AF" size="1.6rem">
                        Session:
                        <Text as="span" weight="500" left="1rem"></Text>
                    </Text>
                    <FlexCentredRow className="gap-[2.4rem]">
                        <Text color="#9CA3AF" size="1.6rem">
                            Level:
                            <Text as="span" weight="500" left="1rem"></Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Semester:
                            <Text as="span" weight="500" left="1rem"></Text>
                        </Text>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
            </Details>

            <PageLayout
                noMargin
                data={[]}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title="There's no Result"
                            subtitle="This student has not completed a semester"
                            src={EmptyState}
                        ></ListEmptyContent>
                    </CenteredContainer>
                }
            >
                <FlexRowSpaceBetween className="px-[1rem] pt-[1.7rem]">
                    <Text color="#9CA3AF" size="1.6rem">
                        Total Courses :<Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Total Units:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Previous GPA:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        Current GPA:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                    <Text color="#9CA3AF" size="1.6rem">
                        CGPA:
                        <Text as="span" weight="500" left="2rem"></Text>
                    </Text>
                </FlexRowSpaceBetween>
            </PageLayout>
        </div>
    );
};

export default Result;
