import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    surname: Yup.string()
        .nullable()
        .min(3, 'Must be at least 3 characters')
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'Surname must contain only alphabetic characters and no spaces'),
    firstName: Yup.string()
        .nullable()
        .min(3, 'Must be at least 3 characters')
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'First Name must contain only alphabetic characters and no spaces'),
    otherName: Yup.string()
        .nullable()
        .min(3, 'Must be at least 3 characters')
        .matches(/^[a-zA-Z-]+$/, 'Must contain only alphabetic characters and no spaces')
        .transform((value, originalValue) => (originalValue.trim() === '' ? null : value)),
    organizationUnit: Yup.string().nullable().required('Required'),
    academicUnit: Yup.string().when('organizationUnit', {
        is: 'Academics',
        then: Yup.string().nullable().required('Required'),
        otherwise: Yup.string().nullable(),
    }),
    firstDegree: Yup.string().when('organizationUnit', {
        is: 'Academics',
        then: Yup.string().nullable().required('Required'),
        otherwise: Yup.string().nullable(),
    }),
    secondDegree: Yup.string().when(['isAddToSecondDegree', 'staffType'], {
        is: (isAddToSecondDegree, staffType) => isAddToSecondDegree || staffType === 'Teaching',
        then: Yup.string().nullable().required('Required'),
        otherwise: Yup.string().nullable(),
    }),
    // thirdDegree: Yup.string().when(['isAddToSecondDegree', 'staffType', 'hasThirdDegree'], {
    //     is: (isAddToSecondDegree, staffType, hasThirdDegree) =>
    //         (isAddToSecondDegree || staffType === 'Teaching') && hasThirdDegree,
    //     then: Yup.string().nullable().required('Required'),
    //     otherwise: Yup.string().nullable(),
    // }),
    staffEmail: Yup.string().nullable().required('Required'),
    staffType: Yup.string().nullable().required('Required'),
    yearOfEmployment: Yup.string().nullable().required('Required'),
    designation: Yup.string().nullable().required('Required'),
    phoneNumber: Yup.string().nullable().required('Required'),
    staffNumber: Yup.string().nullable(),
    isAddToSecondDegree: Yup.boolean(),
});
