const initialState = {
    selectedStudentFirstDegree: null,
    selectedTeachingFirstDegree: null,
    selectedAcademicFirstDegree: null,
    selectedNonteachingFirstDegree: null,
    selectedStudentSecondDegree: {},
    selectedAcademicSecondDegree: {},
    selectedTeachingSecondDegree: {},
    selectedNonTeachingSecondDegree: {},
    selectedStudentLevels: [],
    selectedNonTeachingLevels: [],
    selectedTeachingStaffLevels: [],
    selectedAcademicLevels: [],
    studentSelection: [],
    studentsPayload: [],
    studentSecondDegree: {},
    nonTeachingSecondDegree: [],
    teachingSecondDegree: [],
    nonAcademicUnitPayload: [],
    nonAcademicUnitSelection: [],
    academicUnitSelection: [],
    academicUnitPayload: [],
    distributionListName: '',
    distributionListDesc: '',
    studentSecondLevelData: [],
    teachingSecondLevelData: [],
    nonTeachingSecondLevelData: [],
    academicSecondLevelData: [],
    selectedTeachingStructure: null,
    selectedStudentStructure: null,
    selectedNonTeachingStructure: null,
    selectedAcademicStructure: null,
    studentAcademicLevels: [],
    nonTeachingAcademicLevels: [],
    teachingAcademicLevels: [],
    studentThirdLevelData: [],
    nonTeachingThirdLevelData: [],
    teachingThirdLevelData: [],
    academicUnitLevels: [],
    academicThirdLevelData: [],
    teachingStaffSelection: [],
    teachingStaffPayload: [],
    nonTeachingStaffSelection: [],
    nonTeachingStaffPayload: [],
    organizationUnitAcademicsSelection: [],
    academicSecondDegree: [],
    academicThirdDegree: [],
    teachingThirdDegree: [],
    nonTeachingThirdDegree: [],
    studentThirdDegree: {},
    nonTeachingStaffUnitsPayload: [],
    nonTeachingStaffUnitsSelection: [],
};

const distributionListReducer = (state, action) => {
    switch (action.type) {
        case 'SET_VALUE':
            return {
                ...state,
                [action.field]: action.value,
            };
        case 'UPDATE_OR_CREATE_SECOND_DEGREE_KEY':
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    [action.key]: action.value,
                },
            };
        case 'ADD_TO_SELECTION':
            return {
                ...state,
                [action.field]: [
                    ...state[action.field],
                    ...(Array.isArray(action.value) ? action.value : [action.value]),
                ],
            };
        case 'REMOVE_FROM_SELECTION':
            return {
                ...state,
                [action.field]: state[action.field].filter((_, index) => index !== action.index),
            };
        default:
            return state;
    }
};

export { initialState, distributionListReducer };
