import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    session: Yup.string().nullable().required('Required'),
    discountName: Yup.string().nullable().required('Required'),
    discountType: Yup.string().nullable().required('Required'),
    // discountApplicableTo: Yup.array()
    //     .of(Yup.string().required('Invalid fee selected')) // Ensure each fee is a valid string
    //     .min(1, 'At least one applicable fee must be selected')
    //     .required('Required'),
    discountApplicableTo: Yup.array().min(1, 'At least one applicable fee must be selected').required('Required'),
    discountPercentage: Yup.string().nullable().required('Required'),
    discountDescription: Yup.string().nullable(),
});
