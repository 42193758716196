import React from 'react';

import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow } from '../../../../../containers/ScreenContainers';

const MainContainer = tw.div`
    grid
    grid-cols-1
    md:grid-cols-3
    grid-flow-row
    gap-6
    mt-8
    mb-[6.4rem]

`;

const Details = tw.div`
    md:col-span-2
    col-span-1
    bg-white
    p-6
    rounded-lg
    shadow
    shadow-[0_1px_2px_rgba(6,25,56,0.05)]
`;

const SmallDetails = tw.div`
    col-span-1
    bg-white
    p-6
    rounded-lg
    shadow-[0_2px_5px_rgba(6,25,56,0.06)]
    shadow
    row-end-2
    row-span-2
`;
const DataContainer = tw.div`
    grid
    grid-rows-2
    gap-6
    lg:col-span-2
    col-span-1
`;
const FlexCol = tw.div``;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
    font-weight: 500;
`;

const DarkText = styled(Text)`
    color: #4b5563;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: left;
    font-weight: 400;
`;
const TitleText = styled(Text)`
    color: #1f2937;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
    font-weight: 600;
`;
const BodyText = styled(Text)`
    color: #4b5563;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
    font-weight: 400;
`;

const NonTeachingExperience = ({ exp }) => {
    return (
        <div>
            <MainContainer>
                <DataContainer>
                    <Details className="mt-[3rem]">
                        <FlexRowSpaceBetween>
                            <FlexCentredRow className="mb-[1rem]">
                                <People className="mr-[1.719rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    About
                                </PageTitle>
                            </FlexCentredRow>
                        </FlexRowSpaceBetween>

                        <FlexCol>
                            <GreyText>Background Profile</GreyText>
                            <DarkText>{exp?.about?.aboutYourself}</DarkText>
                        </FlexCol>
                        <FlexCol className="mt-[2rem]">
                            <GreyText>Research Interests</GreyText>
                            <DarkText>{exp?.about?.researchInterest}</DarkText>
                        </FlexCol>
                    </Details>

                    <Details>
                        <FlexRowSpaceBetween>
                            <FlexCentredRow className="mb-[1rem]">
                                <People className="mr-[1.719rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    Work Experience
                                </PageTitle>
                            </FlexCentredRow>
                        </FlexRowSpaceBetween>
                        {exp?.workExperience?.map((exp, _id) => (
                            <div className="mb-[1.5rem]" key={_id}>
                                <FlexRowSpaceBetween>
                                    <TitleText>{exp?.jobTitle}</TitleText>
                                    <FlexCentredRow className="gap-[0.5rem]">
                                        <TitleText>{exp?.from?.slice(0, 4)}</TitleText>
                                        <TitleText>-</TitleText>
                                        <TitleText>{exp?.to?.slice(0, 4)}</TitleText>
                                    </FlexCentredRow>
                                </FlexRowSpaceBetween>
                                <BodyText>{exp?.company}</BodyText>
                                <BodyText top="1rem">{exp?.description}</BodyText>
                            </div>
                        ))}
                    </Details>
                </DataContainer>
                <SmallDetails className="mt-[3rem]">
                    <FlexCentredRow className="mb-[1rem]">
                        <People className="mr-[1.719rem]" />
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Education History
                        </PageTitle>
                    </FlexCentredRow>
                    {exp?.secondaryInfo?.map((secondary, _id) => (
                        <div className=" mb-[1rem]" key={_id}>
                            <FlexCentredRow className="gap-[0.5rem]">
                                <TitleText>{secondary?.from?.slice(0, 4)}</TitleText>
                                <TitleText>-</TitleText>
                                <TitleText>{secondary?.to?.slice(0, 4)}</TitleText>
                            </FlexCentredRow>

                            <Text align="left" weight="500" size="1.4rem" lineheight="2.4rem">
                                {secondary?.qualificationObtain}
                            </Text>
                            <BodyText>{secondary?.secondarySchool}</BodyText>
                        </div>
                    ))}
                    {exp?.tertiaryInfo?.map((tertiary, _id) => (
                        <div className=" mb-[1rem]" key={_id}>
                            <FlexCentredRow className="gap-[0.5rem]">
                                <TitleText>{tertiary?.from?.slice(0, 4)}</TitleText>
                                <TitleText>-</TitleText>
                                <TitleText>{tertiary?.to?.slice(0, 4)}</TitleText>
                            </FlexCentredRow>

                            <Text align="left" weight="500" size="1.4rem" lineheight="2.4rem">
                                {tertiary?.qualificationObtain}
                            </Text>
                            <BodyText>{tertiary?.tertiarySchool}</BodyText>
                        </div>
                    ))}
                    {exp?.postGraduateInfo?.map((post, _id) => (
                        <div key={_id}>
                            <FlexCentredRow className="gap-[0.5rem]">
                                <TitleText>{post?.from?.slice(0, 4)}</TitleText>
                                <TitleText>-</TitleText>
                                <TitleText>{post?.to?.slice(0, 4)}</TitleText>
                            </FlexCentredRow>
                            <Text align="left" weight="500" size="1.4rem" lineheight="2.4rem">
                                {post?.qualificationObtain}
                            </Text>
                            <BodyText>{post?.school}</BodyText>
                        </div>
                    ))}
                    <div className=" border-t border-[#6B7280] mt-[2rem]">
                        <FlexCentredRow className="my-[1rem]">
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Awards
                            </PageTitle>
                        </FlexCentredRow>
                        {exp?.awardInfo?.map((awards, _id) => (
                            <div key={_id}>
                                <Text align="left" weight="500" size="1.4rem" lineheight="2.4rem">
                                    {awards?.award}
                                </Text>
                                <TitleText>{awards?.year?.slice(0, 4)}</TitleText>
                            </div>
                        ))}
                    </div>
                </SmallDetails>
            </MainContainer>
        </div>
    );
};

export default NonTeachingExperience;
