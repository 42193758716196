export const tenantData = [
    {
        name: 'Oniya Samuel',
        age: '21',
        department: 'Civil Engineering',
        medical: 'Nil',
    },
    {
        name: 'Innocent Daniel',
        age: '21',
        department: 'Mechanical Engineering',
        medical: 'Asthma',
    },
    {
        name: 'Badairo Kunle',
        age: '23',
        department: 'Electrical Engineering',
        medical: 'Nil',
    },
    {
        name: 'Iyanu Masalo',
        age: '20',
        department: 'Civil Engineering',
        medical: 'Nil',
    },
    {
        name: 'Funke Praise',
        age: '22',
        department: 'Civil Engineering',
        medical: 'Nil',
    },
];
