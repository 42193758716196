import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ChevronGrey } from '../../../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup } from '../../../../../../components';
import { Button } from '../../../../../../components/buttons';
import Checkbox from '../../../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../../../components/list-empty';
import { CenteredContainer, FlexDiv, RelativeContainer } from '../../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../../layout/page-layout/PageLayout';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../../../redux/admissions/actions';
import { getCurriculum } from '../../../../../../redux/curriculum/actions';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Level',
        accessorKey: 'curriculumLevel',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Total no of courses',
        accessorKey: 'courses',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Total no of units',
        accessorKey: 'totalUnit',
    },
];

const FilterContainer = tw(FlexDiv)`
  p-[16px]
`;

const CurriculumLevels = () => {
    const dispatch = useDispatch();
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { isLoading } = useSelector((state) => state.curriculum);
    const { sessions: admissionsSessions, activeSession } = useSelector((state) => state.admission);

    const { departmentId, academicStructureId, academicStructure } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure?.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [data, setData] = useState([]);
    const [session, setSession] = useState(activeSession || '');
    const [showDropdown, setShowDropdown] = useState(false);

    const getData = async () => {
        const res = await dispatch(getCurriculum(academicStructureId, lastDegreeId, session));
        if (res) return setData(res);
        setData([]);
    };

    useEffect(() => {
        if (lastDegreeId && session) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lastDegreeId, session]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    const modifiedData = data?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            curriculumLevel: (
                <td style={{ color: '#2563EB' }}>
                    <Link to={`${item.level}`} state={{ session }}>
                        {item.level}
                    </Link>
                </td>
            ),
            ...item,
        };
    });

    return (
        <div className="h-[100vh]">
            <PageLayout
                pageTitle="Curriculum"
                showTableUtils
                tableUtilsChildren={
                    <FilterContainer className="gap-5">
                        <RelativeContainer>
                            <Button
                                bgColor="#FFF"
                                color="#6B7280"
                                border="0.5px solid #9CA3AF"
                                onClick={() => setShowDropdown(!showDropdown)}
                            >
                                {session || 'Select Session'}
                                <ChevronGrey />
                            </Button>
                            <div className="absolute left-[200px] top-0 ">
                                <div className="relative">
                                    <ActionsPopup
                                        open={showDropdown}
                                        close={() => setShowDropdown(false)}
                                        items={admissionsSessions?.map((item) => ({
                                            name: item.admissionYear,
                                            click: () => {
                                                setSession(item.admissionYear);
                                                setShowDropdown(false);
                                            },
                                        }))}
                                    />
                                </div>
                            </div>
                        </RelativeContainer>
                    </FilterContainer>
                }
                data={modifiedData || []}
                fields={fields}
                loading={isLoading}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No curriculum yet "></ListEmptyContent>
                    </CenteredContainer>
                }
                isActionable
            />
        </div>
    );
};

export default CurriculumLevels;
