import React from 'react';

import Avatar from 'react-avatar';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../assets/icons/people2.svg';
import { StatusContainer, getStatusStyles } from '../../../components/shared';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../utils';

const MainContainer = tw.div`
    grid
    gap-8
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const Overview = ({ details, bio, acad }) => {
    const firstDegreeStaff = details?.academicStructure?.split('-')[0];
    const secondDegreeStaff = details?.academicStructure?.split('-')[1];
    const firstDegree = details[firstDegreeStaff];
    const secondDegree = details[secondDegreeStaff];

    const leftRow = [
        {
            title: 'Full Name',
            subtitle: `${details?.surname} ${details?.firstName} ${details?.otherName || ''}`,
        },
        {
            title: capitalizeFirstLetter(firstDegreeStaff),
            subtitle: firstDegree || '',
        },
        {
            title: 'Designation',
            subtitle: details?.designation || 'Nil',
        },
        {
            title: 'Appointment',
            subtitle: details?.appointmentName || 'Nil',
        },
    ];
    const rightRow = [
        {
            title: capitalizeFirstLetter(secondDegreeStaff),
            subtitle: secondDegree || details?.subProgram || '',
        },
        {
            title: 'Email',
            subtitle: details?.staffEmail,
        },
        {
            title: 'Date of Employment ',
            subtitle: details?.yearOfEmployment?.slice(0, 10),
        },
    ];
    const otherLeft = [
        {
            title: 'Nationality',
            subtitle: bio?.personalInfo?.nationality,
        },
        {
            title: 'LGA',
            subtitle: bio?.personalInfo?.localGovernment,
        },
    ];
    const otherCenter = [
        {
            title: 'Employment Type',
            subtitle: details?.staffType,
        },
        {
            title: 'State of Origin',
            subtitle: bio?.personalInfo?.stateOfOrigin,
        },
        {
            title: 'Residential Address',
            subtitle: bio?.personalInfo?.permanentAddress,
        },
    ];
    const otherRight = [
        {
            title: 'Personal Email Address',
            subtitle: bio?.personalInfo?.staffEmail,
        },
        {
            title: 'Phone Number',
            subtitle: details?.phoneNumber,
        },
    ];

    return (
        <div>
            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Staff Details
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexDiv className="justify-end gap-[3.2rem]">
                            <StatusContainer style={getStatusStyles(details?.staffStatus?.toLowerCase())}>
                                <span>{details?.staffStatus}</span>
                            </StatusContainer>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        {bio?.profilePic ? (
                            <img src={bio?.profilePic?.url} alt="profile" className="w-[15rem] h-[15rem] col-span-1" />
                        ) : bio?.profilePicture ? (
                            <img
                                src={bio?.profilePicture?.url}
                                alt="profile"
                                className="w-[15rem] h-[15rem] col-span-1"
                            />
                        ) : (
                            <Avatar
                                name={`${details?.surname} ${details?.firstName} ${details?.otherName || ''}`}
                                size="150"
                                textSizeRatio={1.75}
                            />
                        )}

                        <RowContainer className="">
                            {leftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {rightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Other Details
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Education</GreyText>
                                {acad ? (
                                    <DarkText>{`${
                                        acad?.tertiarySchoolInfo?.[0]?.certificate ||
                                        acad?.tertiaryInfo?.[0]?.qualificationObtain
                                    } , ${
                                        acad?.postGraduateInfo?.[0]?.certificate ||
                                        acad?.postGraduateInfo?.[0]?.qualificationObtain
                                    }`}</DarkText>
                                ) : null}
                            </FlexCol>
                            {otherLeft?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                            .
                        </RowContainer>
                        <RowContainer className="">
                            {otherCenter?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {otherRight?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
            </MainContainer>
        </div>
    );
};

export default Overview;
