import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Check } from '../../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as CircularPlus } from '../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import RadioInput from '../../../components/inputs/radio';
import ApplyDiscount from '../../../components/popups/apply-discount';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import useSearch from '../../../hooks/useSearch';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { deleteDiscount, getAllDiscounts } from '../../../redux/bursary/actions';

const DiscountList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, allDiscounts } = useSelector((state) => state.bursary);
    const { sessions, activeSession } = useSelector((state) => state.admission);

    const [session, setSession] = useState(activeSession || '');

    const [filteredDiscounts, setFilteredDiscounts] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const filteredResults = useSearch(searchValue, filteredDiscounts, ['discountName']) || [];

    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const sessionItems = sessions?.map((item) => ({
        name: item?.admissionYear,
        click: () => {
            setSession(item?.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleCheck = (item) => {
        const itemObj = filteredResults.find((discount) => discount._id === item._id);

        const checked = item._id === selectedItem?._id;
        if (!checked) {
            setSelectedItem(itemObj);
        } else {
            setSelectedItem(null);
        }
    };

    const fields = [
        {
            accessorKey: 'action',
            cell: (props) => <p>{props?.getValue()}</p>,
            header: <RadioInput />,
            size: 5,
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Discount Name',
            accessorKey: 'discountName',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Discount Type',
            accessorKey: 'discountType',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Percentage Off',
            accessorKey: 'discountPercentage',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Number of Student Applied',
            accessorKey: 'beneficiaryCount',
        },
    ];

    const handleDelete = async () => {
        const res = await dispatch(deleteDiscount(selectedItem?._id));
        if (res) {
            setOpenModal('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        if (session && allDiscounts?.length > 0) {
            const sessionDiscounts = allDiscounts?.filter((discount) => discount?.session === session);
            setFilteredDiscounts(sessionDiscounts);
        } else {
            setFilteredDiscounts(allDiscounts);
        }
    }, [session, allDiscounts]);

    useEffect(() => {
        dispatch(getAllDiscounts());
    }, [dispatch, toggleRefetch]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    const modifiedDiscountsData = filteredResults?.map((item) => {
        return {
            ...item,
            action: (
                <td>
                    <RadioInput checked={item._id === selectedItem?._id} onClick={() => handleCheck(item)} />
                </td>
            ),
            discountName: (
                <td
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                    onClick={() => navigate(item?._id, { state: { discount: item } })}
                >
                    {item?.discountName}
                </td>
            ),
            discountPercentage: item?.discountPercentage || '',
            discountType: item?.discountType?.replace(/-/g, ' '),
            noOfStudent: item?.beneficiaryCount,
        };
    });

    return (
        <>
            <PageLayout
                pageTitle="Discounts"
                secondaryButtonText="Apply Discount"
                secondaryButtonIcon={<Check />}
                disableSecondaryBtn={!selectedItem}
                onSecondaryButtonClick={() => setOpenModal('apply')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <CircularPlus />,
                        name: 'Create Discount',
                        click: () => navigate('/bursary/discounts/create'),
                    },
                    {
                        icon: <EditIcon />,
                        name: 'Edit Discount',
                        disabled: !selectedItem,
                        click: () =>
                            navigate('/bursary/discounts/edit', { state: { selectedDiscount: selectedItem || {} } }),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Discount',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSessionFilterClick={() => handleOpenDropdown('sessions')}
                sessionPlaceHolder={session}
                openSessionFilter={openDropdown === 'sessions'}
                closeSessionFilter={() => setOpenDropdown(null)}
                sessionItems={sessionItems}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                data={modifiedDiscountsData || []}
                fields={fields}
                loading={isLoading}
                width={'100%'}
                onRowClick={(item) => handleCheck(item)}
                isActionable
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this discount?"
                bodyText="Do you want to continue?"
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />

            <ApplyDiscount
                show={openModal === 'apply'}
                close={() => setOpenModal('')}
                discount={selectedItem}
                onFinish={() => {
                    setToggleRefetch((prev) => !prev);
                }}
            />
        </>
    );
};

export default DiscountList;
