export const auditNavLinks = [
    {
        title: 'Communications',
        to: '/audit/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/audit/communications/compose',
            },
            {
                title: 'Received',
                to: '/audit/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/audit/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/audit/communications/settings',
            },
        ],
    },
    { title: 'Access', to: '/audit/access' },
];

export const auditRoutes = auditNavLinks.map((item) => item.to);
