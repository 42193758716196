import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    result: Yup.array().of(
        Yup.object().shape({
            courseCode: Yup.string().test(
                'courseCode-score-check',
                // 'Either both courseCode and score must be provided, or neither.',
                'Required',
                function (value) {
                    const { score } = this.parent;
                    return (!value && !score) || (value && score);
                },
            ),
            score: Yup.string().test(
                'score-courseCode-check',
                // 'Either both courseCode and score must be provided, or neither.',
                'Required',
                function (value) {
                    const { courseCode } = this.parent;
                    return (!value && !courseCode) || (value && courseCode);
                },
            ),
        }),
    ),
});
