import React from 'react';

import tw, { styled } from 'twin.macro';

const Input = tw.select`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
`;
const StyledInput = styled(Input)`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '4rem'};
    border: ${({ border }) => border || '1px'};
    &:placeholder {
        font-size: 2rem;
    }
`;
const SelectInput = ({ options, children, width, border }) => {
    return (
        <StyledInput width={width} border={border}>
            {/* <optgroup className="placeholder:text-[1.4rem]"> */}
            {/* {options.map(opt => (
                    <option>{opt}</option>
                ))} */}
            {children}
            {/* </optgroup> */}
        </StyledInput>
    );
};

export default SelectInput;
