import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { LEVELS } from '../../../data/constants';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { getCollegeSecondLevel, getColleges } from '../../../redux/college/actions';
import { getFaculties, getFacultySecondLevel } from '../../../redux/faculty/actions';
import { editStudentDetails, getStudentDetails } from '../../../redux/students/actions';
import { capitalizeEachWord, capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import ToggleSwitch from '../../inputs/switch';
import TextInput from '../../inputs/text-input-with-formik';
import { Loader } from '../../loader';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const GridRows = tw.div`
    grid
    md:grid-cols-2
    grid-rows-4
    gap-6
`;

const EditStudentDetailsAdmisionsForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id: studentId } = useParams();

    const { isLoading } = useSelector((state) => state.student);
    const { isLoading: facLoading, faculties, departments } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);
    const { isLoading: structureLoading, savedAcademicStructure } = useSelector((state) => state.academicStructure);

    const academicUnits = Object.keys(savedAcademicStructure || {}).map((item) => ({ name: capitalizeEachWord(item) }));

    const [basicData, setBasicData] = useState(null);
    const { academicStructureId: currentAcademicStructureId, academicStructure } = basicData || {};

    const splitStruct = academicStructure?.split('-');

    const initialStructOne = splitStruct?.[0];
    const initialStructTwo = splitStruct?.[1];
    const firstDegree = basicData?.[initialStructOne] || '';
    const secondDegree = basicData?.[initialStructTwo] || '';

    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [isAcceptance, setIsAcceptance] = useState(false);
    const [selectedAcadUnit, setSelectedAcadUnit] = useState(null);

    const { academicStructure: newAcademicStructure } = selectedFaculty || {};
    const newSplitStruct = newAcademicStructure?.split('-');
    const newStructOne = newSplitStruct?.[0];
    const newStructTwo = newSplitStruct?.[1];

    useEffect(() => {
        const getData = async () => {
            const { basicData } = await dispatch(getStudentDetails(studentId));

            if (basicData) {
                const { academicStructureId, academicStructure } = basicData || {};
                const splitStruct = academicStructure?.split('-');

                const structOne = splitStruct?.[0];

                const initialFaculty = {
                    academicStructureId,
                    academicStructure,
                    _id: basicData?.[`${structOne}Id`],
                };
                setIsAcceptance(basicData.isAcceptanceFee);
                setSelectedFaculty(initialFaculty);
                setSelectedAcadUnit({ name: capitalizeFirstLetter(structOne) });
                setBasicData(basicData);
                return;
            }
        };

        getData();
    }, [dispatch, studentId]);

    useEffect(() => {
        if (selectedAcadUnit) {
            const currStructName = selectedAcadUnit?.name?.toLowerCase() || '';
            if (currStructName === 'college') {
                dispatch(getColleges());
            }
            if (currStructName === 'faculty') {
                dispatch(getFaculties());
            }
        }
    }, [dispatch, selectedAcadUnit]);

    useEffect(() => {
        if (selectedFaculty) {
            const currStructName = selectedAcadUnit?.name?.toLowerCase() || '';
            const { academicStructure, academicStructureId, _id: firstDegreeId } = selectedFaculty || {};

            const splitStruct = academicStructure?.split('-');
            const structTwo = splitStruct?.[1];
            const params = { academicStructureId, firstDegreeId, structTwo };

            if (currStructName === 'college') {
                dispatch(getCollegeSecondLevel(params));
            }
            if (currStructName === 'faculty') {
                dispatch(getFacultySecondLevel(params));
            }
        }
    }, [dispatch, selectedAcadUnit, selectedFaculty]);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    if (!basicData || isLoading) return <Loader />;

    return (
        <>
            <Container>
                <Formik
                    initialValues={{
                        firstName: basicData?.firstName || '',
                        middleName: basicData?.middleName || '',
                        lastName: basicData?.lastName || '',
                        utmeRegNumber: basicData?.utmeRegNumber || '',
                        utmeScore: basicData?.utmeScore || '',
                        postUtmeScore: basicData?.postUtmeScore || '',
                        faculty: firstDegree || '',
                        department: secondDegree || '',
                        level: basicData?.level || '',
                        studentEmail: basicData?.studentEmail || '',
                        isAcceptanceFee: basicData?.isAcceptanceFee || '',
                        matricNumber: basicData?.matricNumber || '',
                        academicStructureId: selectedFaculty?.academicStructureId || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const { studentEmail, firstName, middleName, lastName, faculty, department, ...otherValues } =
                            values;

                        const facultyField = newStructOne;
                        const departmentField = newStructTwo === 'program' ? 'subProgram' : newStructTwo;

                        const payload = {
                            ...otherValues,
                            firstName: capitalizeFirstLetter(firstName),
                            middleName: capitalizeFirstLetter(middleName),
                            lastName: capitalizeFirstLetter(lastName),
                            studentEmail: studentEmail.toLowerCase(),
                            isAcceptanceFee: isAcceptance,
                            [facultyField]: faculty,
                            [departmentField]: department,
                            academicStructureId: selectedFaculty?.academicStructureId || '',
                        };

                        const res = await dispatch(editStudentDetails(currentAcademicStructureId, studentId, payload));

                        if (res) {
                            toast.success('Student details updated successfully!');
                            actions.resetForm();
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <FlexCentredRow className="gap-2 mb-8">
                                <PeopleIcon />
                                <Text weight="600" size="1.6rem">
                                    Edit Student
                                </Text>
                            </FlexCentredRow>

                            <GridRows>
                                <TextInput
                                    name="lastName"
                                    type="text"
                                    placeholder="Surname"
                                    label="Surname"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                                <TextInput
                                    name="firstName"
                                    type="text"
                                    placeholder="First Name"
                                    label="First Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                                <TextInput
                                    name="middleName"
                                    type="text"
                                    placeholder="Other Name"
                                    label="Other Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.middleName}
                                />

                                <TextInput
                                    name="utmeRegNumber"
                                    type="text"
                                    placeholder="UTME Registration Number"
                                    label="UTME Registration Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.utmeRegNumber}
                                />
                                <TextInput
                                    name="utmeScore"
                                    type="number"
                                    placeholder="UTME Score"
                                    label="UTME Score"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.utmeScore}
                                />
                                <TextInput
                                    name="postUtmeScore"
                                    type="number"
                                    placeholder="Post-UTME Score"
                                    label="Post-UTME Score"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.postUtmeScore}
                                />
                                <Select
                                    name="academicUnit"
                                    objProp="name"
                                    label="Academic Unit"
                                    data={academicUnits}
                                    isLoading={structureLoading}
                                    placeholder="Select Academic Unit"
                                    onChange={(selected) => {
                                        setSelectedFaculty(null);
                                        setFieldValue('faculty', '');
                                        setSelectedAcadUnit(selected[0]);
                                        // setFieldValue('academicUnit', selected[0].name);
                                    }}
                                    error={errors.academicUnit}
                                    useComponentState={false}
                                    passedSelectedItems={
                                        selectedAcadUnit ? [selectedAcadUnit] : null
                                        // initialStructOne ? [{ name: capitalizeFirstLetter(initialStructOne) }] : null
                                    }
                                />

                                {selectedAcadUnit && (
                                    <Select
                                        name="faculty"
                                        objProp="facultyName"
                                        label={selectedAcadUnit?.name}
                                        placeholder={`Select ${selectedAcadUnit?.name}`}
                                        data={faculties}
                                        onChange={(selected) => {
                                            setSelectedFaculty(selected[0]);
                                            setFieldValue('faculty', selected[0].facultyName);
                                            setFieldValue('department', '');
                                        }}
                                        useComponentState={false}
                                        passedSelectedItems={values.faculty ? [{ facultyName: values.faculty }] : null}
                                        error={errors.faculty}
                                        isLoading={facLoading || collegeLoading}
                                    />
                                )}
                                {values.faculty && (
                                    <Select
                                        name="department"
                                        objProp="departmentName"
                                        placeholder={capitalizeFirstLetter(newStructTwo)}
                                        label={capitalizeFirstLetter(newStructTwo)}
                                        isLoading={facLoading || collegeLoading}
                                        data={departments}
                                        onChange={(selected) => {
                                            setFieldValue('department', selected[0].departmentName);
                                        }}
                                        useComponentState={false}
                                        passedSelectedItems={
                                            values.department ? [{ departmentName: values.department }] : null
                                        }
                                        error={errors.department}
                                    />
                                )}
                                <Select
                                    name="level"
                                    objProp="name"
                                    placeholder="Level"
                                    label="Level"
                                    data={LEVELS.map((item) => ({ name: item }))}
                                    useComponentState={false}
                                    passedSelectedItems={values.level ? [{ name: values.level }] : null}
                                    onChange={(selected) => {
                                        setFieldValue('level', String(selected[0].name));
                                    }}
                                    error={errors.level}
                                />

                                <TextInput
                                    name="studentEmail"
                                    type="text"
                                    placeholder="Email Address"
                                    label="Email Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.studentEmail}
                                />
                                <TextInput
                                    name="matricNumber"
                                    type="text"
                                    label="Matric Number (Optional - for students with matric number)"
                                    placeholder="Input Matric Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.matricNumber}
                                />
                                <FlexRowSpaceBetween>
                                    <Text>Apply Acceptance Fee to student</Text>

                                    <ToggleSwitch checked={isAcceptance} setChecked={setIsAcceptance} />
                                </FlexRowSpaceBetween>
                            </GridRows>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isSubmitting} bgColor="#6366F1" color="#ffffff" type="submit">
                                    Update
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default EditStudentDetailsAdmisionsForm;
