import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { allotCourse, allotGeneralCourse, getAllotedLecturers } from '../../../redux/courses/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AllocateCourse = ({
    show,
    setShow,
    courses,
    selectedCourse: initialCourse,
    onFinish,
    isFirstDegreeAllocation,
}) => {
    const dispatch = useDispatch();

    const onClose = () => setShow(null);
    const { sessions: admissionsSessions } = useSelector((state) => state.admission);
    const { staff: secondDegreeStaff } = useSelector((state) => state.department);
    const { staff: firstDegreeStaff, currentFaculty } = useSelector((state) => state.faculty);
    const { isLoading } = useSelector((state) => state.courses);

    const { academicStructureId } = currentFaculty || {};

    const [selectedLecturers, setSelectedLecturers] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [session, setSession] = useState('');

    const teachingStaff = useMemo(() => {
        const staff = isFirstDegreeAllocation ? firstDegreeStaff : secondDegreeStaff;

        return staff?.filter((staff) => staff.staffType === 'Teaching');
    }, [firstDegreeStaff, isFirstDegreeAllocation, secondDegreeStaff]);

    useEffect(() => {
        const getLecturersData = async () => {
            setSelectedLecturers([]);
            const res = await dispatch(getAllotedLecturers(session, selectedCourse._id));
            if (res) {
                const lecturers =
                    res?.map((el) => {
                        const fullName = `${el.firstName} ${el.otherName || ''} ${el.surname}`
                            .split(' ')
                            .filter((item) => item)
                            .join(' ');
                        return { ...el, fullName };
                    }) || [];

                setSelectedLecturers([...lecturers]);
            }
        };

        if (session && selectedCourse) {
            getLecturersData();
        }
    }, [dispatch, selectedCourse, session]);

    useEffect(() => {
        setSelectedCourse(initialCourse);
    }, [initialCourse]);

    return (
        <>
            <FormContainer headerIcon={<PlusIcon />} close={onClose} show={show} title={'Allocate Course'}>
                <Formik
                    initialValues={{
                        session: session || '',
                        courseId: selectedCourse?._id || '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        const { session, courseId } = values;
                        const payload = { staffNames: selectedLecturers?.map((item) => item.fullName) };

                        const res = isFirstDegreeAllocation
                            ? await dispatch(allotGeneralCourse(academicStructureId, session, courseId, payload))
                            : await dispatch(allotCourse(academicStructureId, session, courseId, payload));
                        if (res) {
                            toast.success('Course has been assigned successfully!');
                            // actions.resetForm();
                            onClose();
                            onFinish?.();
                        }
                    }}
                >
                    {({ errors, setFieldValue }) => (
                        <Form>
                            <div className="gap-5 flex flex-col">
                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    placeholder="Select Session"
                                    label="Session"
                                    data={admissionsSessions}
                                    onChange={(selected) => {
                                        setFieldValue('session', String(selected[0].admissionYear));
                                        setSession(String(selected[0].admissionYear));
                                    }}
                                    error={errors.session}
                                />
                                <Select
                                    searchable
                                    label="Course"
                                    name="courseId"
                                    objProp="title"
                                    placeholder="Select Course"
                                    useComponentState={false}
                                    passedSelectedItems={
                                        selectedCourse
                                            ? [
                                                  {
                                                      ...selectedCourse,
                                                      title: `${selectedCourse.courseTitle} (${selectedCourse.courseCode})`,
                                                  },
                                              ]
                                            : null
                                    }
                                    data={courses?.map((item) => ({
                                        ...item,
                                        title: `${item.courseTitle} (${item.courseCode})`,
                                    }))}
                                    onChange={(selected) => {
                                        setFieldValue('courseId', String(selected[0]._id));
                                        setSelectedCourse(selected[0]);
                                    }}
                                    error={errors.courseId}
                                />
                                <Select
                                    searchable
                                    name="lecturers"
                                    label="Lecturer(s)"
                                    objProp="fullName"
                                    useComponentState={false}
                                    data={teachingStaff || []}
                                    placeholder="Lecturer(s)"
                                    isLoading={isLoading}
                                    multiSelect
                                    passedSelectedItems={selectedLecturers}
                                    type="text"
                                    onChange={(selected) => {
                                        setSelectedLecturers(selected);
                                    }}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>

                                <LoadingButton
                                    disabled={!selectedLecturers.length || isLoading}
                                    loading={isLoading}
                                    type="submit"
                                >
                                    Allocate Course
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AllocateCourse;
