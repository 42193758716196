import React from 'react';

import tw from 'twin.macro';

import TextAreaInput from '../../../components/inputs/text-area';
import ConfirmApproval from '../confirm-approval';

const Input = tw.input`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-full
    h-[4rem]
`;

const ConfirmApprovalWithInput = ({ type = 'text', value, setInputValue, placeholder, ...props }) => {
    return (
        <ConfirmApproval {...props}>
            {type === 'textarea' ? (
                <TextAreaInput
                    name="reason"
                    placeholder={placeholder || 'Reason'}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    height="6rem"
                />
            ) : (
                <Input
                    type="text"
                    placeholder={placeholder || 'Reason'}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                />
            )}
        </ConfirmApproval>
    );
};

export default ConfirmApprovalWithInput;
