import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import EditBillingInfoForm from '../../../../components/forms/edit-billing-info';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';

const EditBillingInfo = () => {
    const navigate = useNavigate();
    return (
        <div className="pb-[7.5rem]">
            {' '}
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[8px] pb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    View Invoice History
                </Text>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Edit Billing Information</Text>
            </FlexCentredRow>
            <div className="p-[2.4rem] bg-white">
                <Text align="left" size="2.3rem" color="#1F2937" weight="600">
                    Billing Information
                </Text>
                <Text align="left" size="1.4rem" color="#6B7280" weight="500" top="0.8rem" className="w-[44.4rem]">
                    Your billing information is displayed on all your invoices and billing communication
                </Text>
                <EditBillingInfoForm />
            </div>
        </div>
    );
};

export default EditBillingInfo;
