import React, { useEffect, useState } from 'react';

import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../assets/icons/people2.svg';
import { Button } from '../../../components/buttons';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { getStaffById } from '../../../redux/staff/actions';

const MainContainer = tw.div`
    grid
    gap-8
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
`;

const StaffDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [staffDetails, setStaffdetails] = useState({});
    const { isLoading } = useSelector((store) => store.staff);
    const { staff, bioData } = staffDetails || {};

    const getDetails = async () => {
        const res = await dispatch(getStaffById(id));
        if (res) setStaffdetails(res);
    };
    useEffect(() => {
        if (id) {
            getDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    const leftRow = [
        {
            title: 'Full Name',
            subtitle: `${staff?.surname} ${staff?.firstName} ${staff?.otherName || ''}`,
        },
        {
            title: 'Faculty',
            subtitle: bioData?.personalInfo?.faculty,
        },
        {
            title: 'Designation',
            subtitle: staff?.designation,
        },
        {
            title: 'Appointment',
            subtitle: staff?.appointmentName,
        },
    ];
    const rightRow = [
        {
            title: 'Department',
            subtitle: bioData?.personalInfo?.department,
        },
        {
            title: 'Email',
            subtitle: bioData?.personalInfo?.staffEmail,
        },
        {
            title: 'Date of Employment ',
            subtitle: staff?.yearOfEmployment?.slice(0, 10),
        },
    ];
    const otherLeft = [
        {
            title: 'Nationality',
            subtitle: bioData?.personalInfo?.nationality,
        },
        {
            title: 'LGA',
            subtitle: bioData?.personalInfo?.localGovernment,
        },
    ];
    const otherCenter = [
        {
            title: 'Employment Type',
            subtitle: staff?.staffType,
        },
        {
            title: 'State of Origin',
            subtitle: bioData?.personalInfo?.stateOfOrigin,
        },
        {
            title: 'Residential Address',
            subtitle: bioData?.personalInfo?.permanentAddress,
        },
    ];
    const otherRight = [
        {
            title: 'Personal Email Address',
            subtitle: bioData?.personalInfo?.staffEmail,
        },
        {
            title: 'Phone Number',
            subtitle: bioData?.personalInfo?.phoneNumber,
        },
    ];

    if (isLoading) return <Loader />;
    return (
        <div>
            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Staff Details
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexDiv className="justify-end gap-[3.2rem]">
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Active
                                </Text>
                            </Button>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        {bioData?.profilePic?.url ? (
                            <img
                                src={bioData?.profilePic?.url}
                                alt="profile"
                                className="w-[15rem] h-[15rem] col-span-1"
                            />
                        ) : (
                            <Avatar
                                name={`${staff?.surname} ${staff?.firstName} ${staff?.otherName || ''}`}
                                size="150"
                                textSizeRatio={1.75}
                            />
                        )}

                        <RowContainer className="">
                            {leftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {rightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Other Details
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Education</GreyText>
                                {/* <DarkText>{`${staffAcad?.tertiaryInfo?.[0]?.qualificationObtain} ${staffAcad?.postGraduateInfo?.[0]?.qualificationObtain}`}</DarkText> */}
                            </FlexCol>
                            {otherLeft?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                            .
                        </RowContainer>
                        <RowContainer className="">
                            {otherCenter?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {otherRight?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
            </MainContainer>
        </div>
    );
};

export default StaffDetail;
