import React, { useEffect } from 'react';

import { CModal } from '@coreui/react';
import Avatar from 'react-avatar';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as MarkIcon } from '../../../assets/icons/circular-mark-white-sm.svg';
import { ReactComponent as Closeicon } from '../../../assets/icons/close-grey.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-sm.svg';
import { LoadingButton } from '../../../components/buttons';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { getExeatById, approveExeat, rejectExeat } from '../../../redux/exeat/actions';

const MainContainer = tw.div`
w-auto
md:w-[60rem] 
lg:w-full 
h-auto 
mx-auto
lg:p-[2.4rem] 
md:p-[5.5rem] 
rounded-lg
`;

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        width: 59.2rem;
    }
`;

const InputDetails = tw.div`
grid 
grid-cols-2 
items-center 
justify-center 
gap-x-[3.2rem] 
gap-y-6

`;
const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;
const DetailsCardTitleText = styled(Text)`
    color: #1f2937;
    line-height: 2.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #6b7280;
    line-height: 2.4rem;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: ${({ align }) => align || 'left'};
`;
const FlexCol = tw.div`
    gap-6
`;

const ExeatDetails = ({ onClose, show, exeatId }) => {
    const dispatch = useDispatch();
    const { exeatDetails: exeat, isLoading } = useSelector((store) => store.exeat);
    const { organizationProfile } = useSelector((store) => store.ict);

    useEffect(() => {
        if (!exeatId) return;
        dispatch(getExeatById(exeatId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exeatId]);

    const handleApprove = async () => {
        const res = await dispatch(approveExeat(exeatId));
        if (res) {
            toast.success('exeat request approved successfully');
        }
    };

    const handleReject = async () => {
        const res = await dispatch(rejectExeat(exeatId));
        if (res) {
            toast.success('exeat request rejected successfully');
        }
    };
    const leftRow = [
        {
            title: 'Location',
            subtitle: exeat?.targetLocation,
        },
        {
            title: 'Name of Host',
            subtitle: exeat?.nameOfHost,
        },
        {
            title: 'Relationship with Host',
            subtitle: exeat?.relationshipWithHost,
        },
        {
            title: "Host's Phone Number",
            subtitle: exeat?.hostPhoneNumber,
        },
        {
            title: 'State',
            subtitle: exeat?.state,
        },
        {
            title: 'Town/City',
            subtitle: exeat?.townCity,
        },
        {
            title: 'Address',
            subtitle: exeat?.address,
        },
        {
            title: 'Start Date',
            subtitle: exeat?.startDate?.slice(0, 10),
        },
        {
            title: 'End Date',
            subtitle: exeat?.endDate?.slice(0, 10),
        },
        {
            title: 'Subject',
            subtitle: exeat?.subject,
        },
        {
            title: 'Purpose of Visit',
            subtitle: exeat?.purposeOfVisit,
        },
    ];
    return (
        <StyledModal show={show} onClose={onClose} centered closeOnBackdrop={true} backdrop={true} color="info">
            <div className="bg-white">
                <MainContainer>
                    <FlexRowSpaceBetween className="lg:gap-[31.8rem] md:gap-[10rem] mb-[2.5rem]">
                        <FlexCentredRow>
                            {organizationProfile?.logo?.url ? (
                                <img src={organizationProfile?.logo?.url} alt="logo" className=" mr-[13.32px] " />
                            ) : (
                                <Avatar
                                    name={`${organizationProfile?.institutionName?.slice(0, 1)}`}
                                    size="150"
                                    textSizeRatio={1.75}
                                />
                            )}

                            <Text color="#6366F1" size="2rem" lineHeight="1.8rem" weight="600">
                                {organizationProfile?.institutionName}
                            </Text>
                        </FlexCentredRow>

                        <Closeicon className="float-right cursor-pointer" onClick={onClose} />
                    </FlexRowSpaceBetween>
                    <InputDetails>
                        <RowContainer className="lg:text-left text-center">
                            {leftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </InputDetails>
                    <FlexCentredRow className="gap-4 mr-4 mt-[2rem] justify-end">
                        <LoadingButton
                            loading={isLoading}
                            onClick={() => handleApprove()}
                            className="shadow-[0px_1px_2px_0px_rgba(6,25,56,0.05)] rounded"
                            color="#FFFFFF"
                            bgColor="#6366F1"
                        >
                            <MarkIcon className="mr-2" />
                            Approve
                        </LoadingButton>
                        <LoadingButton
                            loading={isLoading}
                            onClick={() => handleReject()}
                            className="shadow-[0px_1px_2px_0px_rgba(6,25,56,0.05)] rounded"
                            color="#FFFFFF"
                            bgColor="#EF4444"
                        >
                            <CloseIcon className="mr-2" />
                            Reject
                        </LoadingButton>
                    </FlexCentredRow>
                </MainContainer>
            </div>
        </StyledModal>
    );
};

export default ExeatDetails;
