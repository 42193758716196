import React from 'react';

import { useNavigate } from 'react-router';
import tw from 'twin.macro';

import { Text, PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexColumn } from '../../../containers/ScreenContainers';

const MainContainer = tw.div`
    bg-white
    px-[2rem]
    py-[2rem]
    mb-[2rem]
    rounded-[2rem]
    hover:cursor-pointer
`;

const ListItems = [
    {
        title: 'Contact Information',
        subtitle: 'Set up contact information',
        link: 'contact-information',
    },
    {
        title: 'Organization Profile',
        subtitle: 'Customize your logo, about and other information',
        link: 'profile',
    },
    {
        title: 'Account Deletion',
        subtitle: 'Delete your account irreversibly',
        link: 'account-deletion',
    },
];

const OrganizationSettings = () => {
    const navigate = useNavigate();
    return (
        <>
            <FlexColumn className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle align="start" size="3.3rem">
                    Organisation Settings
                </PageTitle>
            </FlexColumn>
            {ListItems?.map((item, i) => (
                <MainContainer onClick={() => navigate(item.link)} key={i}>
                    <Text align="left" weight="700" size="1.8rem">
                        {item.title}
                    </Text>
                    <FlexRowSpaceBetween className="">
                        <Text as="span" align="left" size="1.4rem" weight="400">
                            {item.subtitle}
                        </Text>
                    </FlexRowSpaceBetween>
                </MainContainer>
            ))}
        </>
    );
};

export default OrganizationSettings;
