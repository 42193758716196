import React from 'react';

import tw from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import { Button } from '../../buttons';
import Checkbox from '../../inputs/checkbox';

const Container = tw.div`
    bg-white
    px-[8px]
    w-[100%]
    pt-[12px]
    shadow-[0px 25px 50px -12px rgba(6, 25, 56, 0.25)]
    absolute
    min-w-max
    left-0
    z-[1000]
`;

const ItemBox = tw.div`
    flex
    gap-[10px]
    hover:bg-[#F3F4F6]
    py-3
    rounded-lg
    items-center
`;

const BtnBox = tw.div`
    py-[2rem]
    flex
    justify-center
`;

const ListWithCheckBox = ({
    nameField = 'fullName',
    idField = 'id',
    list,
    selectedItems,
    onDone,
    setSelectedItems,
    onChange,
    open,
    onClose,
    isLoading,
}) => {
    const handleSelect = (item) => {
        const selected = selectedItems?.some((ite) => ite[idField] === item[idField]);
        if (selected) {
            const newItems = selectedItems.filter((ite) => ite[idField] !== item[idField]);
            setSelectedItems(newItems);
        } else {
            setSelectedItems((prev) => [...prev, item]);
        }
        onChange?.(item);
    };

    return (
        <div>
            {open && <div className="fixed z-10 inset-0" onClick={onClose}></div>}
            <Container>
                {isLoading ? (
                    <Text as="label" align="left" color="#6B7280" size="1.6rem">
                        Loading...
                    </Text>
                ) : (
                    <>
                        {!list?.length > 0 ? (
                            <Text as="label" align="left" color="#6B7280" size="1.6rem">
                                No options
                            </Text>
                        ) : (
                            <>
                                <div className="overflow-y-auto max-h-[40rem] pb-4">
                                    {list?.map((item) => (
                                        <ItemBox key={item[idField]} onClick={() => handleSelect(item)}>
                                            <Checkbox
                                                className="mt-0"
                                                checked={selectedItems?.some((ite) => ite[idField] === item[idField])}
                                                data-full-name={item[nameField]}
                                            />
                                            <Text as="label" align="left" color="#6B7280" size="1.6rem">
                                                {item[nameField]}
                                            </Text>
                                        </ItemBox>
                                    ))}
                                </div>
                                {onDone && (
                                    <BtnBox>
                                        <Button color="white" bgColor="#6366F1" onClick={onDone}>
                                            Done
                                        </Button>
                                    </BtnBox>
                                )}
                            </>
                        )}
                    </>
                )}
            </Container>
        </div>
    );
};

export default ListWithCheckBox;
