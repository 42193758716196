import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { Button } from '../../../components/buttons';
import RadioInput from '../../../components/inputs/radio';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useSearch from '../../../hooks/useSearch';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteDebtor, getAllDebtors } from '../../../redux/bursary/actions';
import { getDegreeInfo } from '../../../utils';
import currencyFormatter from '../../../utils/formatCurrency';

export const getSessionsOwed = (debts = []) => new Set(debts.map((debt) => debt.session)).size;

const DebtorsList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, allDebtors } = useSelector((state) => state.bursary);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const [searchValue, setSearchValue] = useState('');
    const filteredResults = useSearch(searchValue, allDebtors, ['studentFullName']);

    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleCheck = (item) => {
        const itemObj = filteredResults.find((debtor) => debtor._id === item._id);

        const checked = item._id === selectedItem?._id;
        if (!checked) {
            setSelectedItem(itemObj);
        } else {
            setSelectedItem(null);
        }
    };

    const fields = [
        {
            accessorKey: 'action',
            cell: (props) => <p>{props?.getValue()}</p>,
            header: <RadioInput />,
            size: 5,
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Name of Debtor',
            accessorKey: 'nameOfDebtor',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Matric Number',
            accessorKey: 'studentMatricNumber',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Number of sessions owed',
            accessorKey: 'noOfSessionOwed',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Total amount owed',
            accessorKey: 'amountOwed',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Date of last payment',
            accessorKey: 'dateOfLastPayment',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: firstDegreeOptionsStr,
            accessorKey: 'firstDegree',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: secondDegreeOptionsStr,
            accessorKey: 'secondDegree',
        },
    ];

    const handleDelete = async () => {
        const res = await dispatch(deleteDebtor(selectedItem._id));
        if (res) {
            setOpenModal('');
        }
    };

    useEffect(() => {
        dispatch(getAllDebtors());
    }, [dispatch]);

    const modifiedDebtorsData = filteredResults?.map((item) => {
        const studentDetails = item;

        const { firstDegree, secondDegree } = getDegreeInfo(studentDetails, 'structureType');
        const totalAmountOwed = item?.debts?.reduce((acc, debt) => acc + debt.amountOwed, 0);
        const sessionsOwed = getSessionsOwed(item.debts);

        return {
            ...item,
            action: (
                <td>
                    <RadioInput checked={item._id === selectedItem?._id} onClick={() => handleCheck(item)} />
                </td>
            ),
            nameOfDebtor: (
                <td
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                    onClick={() => navigate(item.studentId, { state: { debtor: item } })}
                >
                    {studentDetails.studentFullName}
                </td>
            ),
            studentMatricNumber: studentDetails?.studentMatricNumber || '',
            dateOfLastPayment: item.installmentDetails?.deadlines?.[0]
                ? format(new Date(item.installmentDetails.deadlines[0]), 'dd/MM/yyyy')
                : 'N/A',
            firstDegree: firstDegree,
            secondDegree: secondDegree,
            noOfSessionOwed: sessionsOwed || 0,
            amountOwed: currencyFormatter.format(totalAmountOwed || 0),
        };
    });

    return (
        <>
            <PageLayout
                pageTitle="Debt Management"
                secondaryButtonText="Add Debtor"
                onSecondaryButtonClick={() => navigate('/bursary/debtors-list/add')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                // actionItems={[
                //     {
                //         icon: <DeleteIcon />,
                //         name: 'Delete Debtor',
                //         disabled: !selectedItem,
                //         click: () => setOpenModal('delete'),
                //     },
                // ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                data={modifiedDebtorsData || []}
                fields={fields}
                loading={isLoading}
                width={'100%'}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Debtors Found" subtitle="Add a Debtor">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button
                                    onClick={() => navigate('/bursary/debtors-list/add')}
                                    bgColor="#10B981"
                                    color="#fff"
                                >
                                    Add Debtor
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                onRowClick={(item) => handleCheck(item)}
                isActionable
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this debtor?"
                subtitle="This debtor alongside their history will be deleted totally."
                bodyText="Do you want to continue?"
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />
        </>
    );
};

export default DebtorsList;
