import React from 'react';

import { AddFeeForm } from '../../../../components';

const AddFee = () => {
    return (
        <div>
            <AddFeeForm />
        </div>
    );
};

export default AddFee;
