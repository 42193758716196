import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import RadioInput from '../../../../components/inputs/radio';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';

import BillingTabs from './tabs';

const AddSeat = () => {
    const navigate = useNavigate();
    return (
        <div className="pb-[3.6rem]">
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[8px] pb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Add Seats</Text>
            </FlexCentredRow>

            <div className="bg-white p-[2.4rem]">
                <Text size="2.3rem" align="left" weight="600">
                    Add Seats
                </Text>
                <div className="flex items-center gap-[2.4rem] pt-[3.2rem]">
                    <Text size="1.6rem" color="#6B7280">
                        Apply Changes
                    </Text>
                    <RadioInput checked={true} label="Immediately" />
                    <RadioInput label="On Renewal (26 August 2023)" />
                </div>
                <div className="pt-[4.8rem] pb-[6.9rem]">
                    <BillingTabs />
                </div>
            </div>
        </div>
    );
};

export default AddSeat;
