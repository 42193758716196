import React, { useEffect } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../../components/loader';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import {
    getAllBulletinComms,
    getAllInternalMemoComms,
    getAllNoticeComms,
    getAllReceivedComms,
} from '../../../../redux/staff-arena/actions';
import { All, Bulletin, InternalMemo, Notice } from '../../../communication/components';

// import { StaffBulletin, StaffInternalMemo, StaffNotice } from './tabs';

const StaffReceived = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.staffArena);

    useEffect(() => {
        dispatch(getAllReceivedComms());
        dispatch(getAllBulletinComms());
        dispatch(getAllNoticeComms());
        dispatch(getAllInternalMemoComms());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Received</PageTitle>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>All</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Bulletin</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink>Internal Memo</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink>Notice</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <All />
                    </CTabPane>
                    <CTabPane>
                        <Bulletin />
                    </CTabPane>
                    <CTabPane>
                        <InternalMemo />
                    </CTabPane>
                    <CTabPane>
                        <Notice />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default StaffReceived;
