import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as People } from '../../../../assets/icons/people.svg';
import { Checkbox, SearchInput } from '../../../../components';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import { getSearchStaff, patchSetttings } from '../../../../redux/staff-arena/actions';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
`;

const FileSharing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');

    const { searchStaff, isLoading } = useSelector((state) => state.staffArena);
    const [staffList, setStaffList] = useState([]); // Dropdown options
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchValue(query);
    };

    const handleAddStaff = (staff, setFieldValue) => {
        if (!selectedStaff.some((s) => s?._id === staff?._id)) {
            const newSelectedStaff = [...selectedStaff, staff];
            setSelectedStaff(newSelectedStaff);
            setFieldValue('selectedStaff', newSelectedStaff);
        }
        setSearchValue('');
        setStaffList([]);
    };

    const handleRemoveStaff = (staffId, setFieldValue) => {
        const updatedSelectedStaff = selectedStaff.filter((s) => s?._id !== staffId);
        setSelectedStaff(updatedSelectedStaff);
        setFieldValue('selectedStaff', updatedSelectedStaff);
    };

    useEffect(() => {
        if (!debouncedSearchValue) {
            setStaffList([]);
            return;
        }

        dispatch(getSearchStaff(debouncedSearchValue));
        setDropdown(true);
    }, [debouncedSearchValue, dispatch]);

    useEffect(() => {
        if (searchStaff?.data?.staffs) {
            const filteredStaff = searchStaff?.data?.staffs?.filter(
                (staff) =>
                    staff?.firstName.toLowerCase().includes(debouncedSearchValue) ||
                    staff?.surname.toLowerCase().includes(debouncedSearchValue) ||
                    staff?.staffEmail.includes(debouncedSearchValue),
            );
            setStaffList(filteredStaff);
        }
    }, [searchStaff, debouncedSearchValue]);

    return (
        <>
            <Container>
                <div className="mb-[3.2rem]">
                    <GoBack title="Preference" backAction={() => navigate(-1)} subTitle="File Sharing" />
                </div>
                <PageTitle align="left" bottom="3.2rem">
                    File Sharing
                </PageTitle>
                <Formik
                    initialValues={{
                        disableDocumentSharing: false,
                        restrictSharedBy: true,
                        search: [],
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const payload = {
                            disableDocumentSharing: values.disableDocumentSharing,
                            restrictSharedBy: values.restrictSharedBy,
                            search: values?.selectedStaff?.map((staff) => staff?.staffEmail),
                        };
                        const res = await dispatch(patchSetttings(payload));
                        if (res) {
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="gap-[1rem] mb-[1.5rem]">
                                <People />
                                <Text weight="500">Configure File Sharing Settings</Text>
                            </FlexCentredRow>

                            <div className="mb-4 flex">
                                <Checkbox
                                    type="checkbox"
                                    name="disableDocumentSharing"
                                    checked={values.disableDocumentSharing}
                                    onChange={handleChange}
                                    className="mr-2 pt-[0.8rem]"
                                />
                                <FlexCentredCol>
                                    <Text align="left" weight="500">
                                        Disable document sharing with me
                                    </Text>
                                    <Text color="#9CA3AF" weight="400" align="left" size="1.2rem">
                                        When enabled, no one will be able to share documents with you
                                    </Text>
                                </FlexCentredCol>
                            </div>
                            <div className="mb-4 flex ml-[4rem]">
                                <Checkbox
                                    type="checkbox"
                                    name="restrictSharedBy"
                                    checked={values.restrictSharedBy}
                                    onChange={handleChange}
                                    className="mr-2 pt-[0.8rem]"
                                />
                                <FlexCentredCol>
                                    <Text align="left" weight="500">
                                        Restrict who can share documents with me
                                    </Text>
                                    <Text color="#9CA3AF" weight="400" align="left" size="1.2rem">
                                        When enabled, only selected staff can share documents with you
                                    </Text>
                                </FlexCentredCol>
                            </div>

                            {values.restrictSharedBy && (
                                <div className="mb-[3rem] relative ml-[8rem]">
                                    <Text className="mb-[2rem]" weight="500" align="left" size="1.4rem">
                                        Staff Name or Staff Number
                                    </Text>
                                    <SearchInput
                                        width="45rem"
                                        onKeyDown={setDropdown(true)}
                                        onClose={() => setSearchValue('')}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                        placeholder="Search for Staff here"
                                        className="border mt-[1rem] p-2 w-full rounded"
                                    />
                                    {staffList?.length > 0 && dropdown && (
                                        <div className="absolute w-[45rem]  border mt-2 bg-white rounded shadow-lg">
                                            {staffList?.map((staff) => (
                                                <FlexRowSpaceBetween
                                                    key={staff?._id}
                                                    onClick={() => handleAddStaff(staff, setFieldValue)}
                                                    className="hover:bg-gray-200 hover:rounded-lg hover:px-[1rem] m-[1rem]"
                                                >
                                                    <FlexCentredRow className="gap-[1rem]">
                                                        {staff?.profile?.url ? (
                                                            <img
                                                                alt="profile"
                                                                src={staff?.profile?.url}
                                                                className="rounded-full w-[3rem] h-[3rem]"
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                name={`${staff?.firstName} ${staff?.surname}`}
                                                                size="30"
                                                                textSizeRatio={1.75}
                                                                className="rounded-full"
                                                            />
                                                        )}
                                                        <FlexCentredCol className="gap-[0.1rem]">
                                                            <Text
                                                                align="left"
                                                                weight="500"
                                                                size="1.4rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.firstName} {staff?.surname}
                                                            </Text>
                                                            <Text
                                                                align="left"
                                                                color="#6B7280"
                                                                weight="400"
                                                                size="1.2rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.staffEmail}
                                                            </Text>
                                                        </FlexCentredCol>
                                                    </FlexCentredRow>
                                                    <Text align="left" weight="400" size="1.2rem" lineHeight="2.4rem">
                                                        {staff?.staffId}
                                                    </Text>
                                                </FlexRowSpaceBetween>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                            {values.restrictSharedBy && (
                                <div className="bg-[#F3F4F6] border border-[#9CA3AF] rounded-2xl ml-[8rem]">
                                    <Text
                                        className="border-b border-[#9CA3AF] p-[2.4rem]"
                                        align="left"
                                        weight="600"
                                        size="1.6rem"
                                        lineHeight="2.4rem"
                                    >
                                        Selected persons that can share document with me
                                    </Text>

                                    <div className=" flex p-[2.4rem]">
                                        {selectedStaff?.length === 0 ? (
                                            <Text color="#6B7280" weight="400" size="1.4rem" lineHeight="2.4rem">
                                                Staff added will appear here
                                            </Text>
                                        ) : (
                                            selectedStaff?.map((staff, _id) => (
                                                <FlexRowSpaceBetween
                                                    key={_id}
                                                    className="p-2 border-b w-full last:border-0"
                                                >
                                                    <FlexCentredRow className="gap-[1rem]">
                                                        {staff?.profilePicture ? (
                                                            <img
                                                                alt="profile"
                                                                src={staff?.profilePicture}
                                                                className="rounded-full w-[5rem] h-[5rem]"
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                name={`${staff?.surname}`}
                                                                size="60"
                                                                textSizeRatio={1.75}
                                                                className="rounded-full"
                                                            />
                                                        )}
                                                        <FlexCentredCol className="gap-[0.4rem]">
                                                            <Text
                                                                align="left"
                                                                weight="500"
                                                                size="1.4rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.firstName} {staff?.surname}
                                                            </Text>
                                                            <Text
                                                                align="left"
                                                                color="#6B7280"
                                                                weight="400"
                                                                size="1.2rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.staffEmail}
                                                            </Text>
                                                        </FlexCentredCol>
                                                    </FlexCentredRow>

                                                    <FlexRowEnd>
                                                        <CloseIcon
                                                            className="cursor-pointer"
                                                            onClick={() => handleRemoveStaff(staff?._id, setFieldValue)}
                                                        />
                                                    </FlexRowEnd>
                                                </FlexRowSpaceBetween>
                                            ))
                                        )}
                                    </div>
                                </div>
                            )}

                            <FlexRowEnd className="gap-[2rem] mt-[2.5rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Save Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default FileSharing;
