import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editContactInfo } from '../../../redux/ict/actions';
import { LoadingButton } from '../../buttons';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
p-[2.4rem]
bg-white
rounded-[0.8rem]
`;

const EditOrgContact = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { contactInfo, isLoading } = useSelector((store) => store.ict);
    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[0.8rem]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Contact Information
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Update Contact Information</Text>
            </FlexCentredRow>
            <PageTitle align="left" top="4.5rem" bottom="2.4rem">
                Contact Information
            </PageTitle>
            <Container>
                <PageTitle align="left" size="1.9rem" bottom="3.2rem">
                    Contact Information
                </PageTitle>
                <Formik
                    initialValues={
                        contactInfo || {
                            fullName: '',
                            companyName: '',
                            email: '',
                            address: '',
                            phoneNumber: '',
                            websiteUrl: '',
                        }
                    }
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        const res = await dispatch(editContactInfo({ ...values, email: values.email.toLowerCase() }));

                        if (res) {
                            navigate(-1);
                        }
                    }}
                >
                    {({ handleChange, values, errors }) => (
                        <Form>
                            <div className="flex flex-col gap-[3.2rem] max-w-[100.05rem]">
                                <FormikTextInput
                                    label="Full Name"
                                    name="fullName"
                                    type="text"
                                    placeholder="Full Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.fullName}
                                />
                                <FormikTextInput
                                    label="Company Name"
                                    name="companyName"
                                    type="text"
                                    placeholder="Company Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.companyName}
                                />
                                <FormikTextInput
                                    label="Phone Number"
                                    name="phoneNumber"
                                    type="number"
                                    placeholder="Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                />
                                <FormikTextInput
                                    label="Email Address"
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <FormikTextInput
                                    label="Website Url"
                                    name="websiteUrl"
                                    type="text"
                                    placeholder="Website"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.websiteUrl}
                                />
                                <FormikTextInput
                                    label="Address"
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.address}
                                />
                            </div>
                            <FlexRowEnd className="gap-[2rem] mt-[3.2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    bgColor="#6366F1"
                                    color="#fff"
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </Container>
        </div>
    );
};

export default EditOrgContact;
