import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['blockquote', 'code-block'],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['clean'],
    ],
};

const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
];

const Editor = ({ value, handleChange, className }) => {
    return (
        <div>
            <ReactQuill
                style={{ backgroundColor: '#FFFFFF', outline: 'none', border: 'none', color: '#000', height: '10rem' }}
                theme="snow"
                bounds={'.editor'}
                modules={modules}
                placeholder="Type in here..."
                formats={formats}
                value={value}
                onChange={handleChange}
                className={className}
            />
        </div>
    );
};

export default Editor;
