export const facultyDepartmentData = [
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
    {
        departments: 'Computing and Engineering Science',
        personnels: '12',
        admin: 'Admin',
        courses: '5',
        students: '4003',
        description: 'The School of computing and Engineer',
    },
];
