import React, { useRef } from 'react';

import tw from 'twin.macro';

import { ReactComponent as UploadIcon } from '../../assets/icons/upload5.svg';
import { Text } from '../../containers/MesssageContainers';
// import { FlexDiv } from '../../containers/ScreenContainers';

const Container = tw.div`
border
border-dashed
border-gray-200
rounded-lg
flex
items-center
p-2
gap-4
`;

const Button = tw.button`
rounded
border
border-gray-200
px-[1.6rem]
py-[.8rem]
flex
items-center
font-medium
text-[1.4rem]
gap-[1.15rem]
text-[#1F2937]
`;

const UploadFile2 = () => {
    const fileInput = useRef();

    const onSelectFile = () => {
        fileInput.current.click();
    };

    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
    };

    return (
        <Container onClick={onSelectFile}>
            <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*"
                ref={fileInput}
            />
            <Button>
                <UploadIcon />
                Upload Files
            </Button>
            <Text color="#6B7280" weight="500">
                Or Drop Files
            </Text>
        </Container>
    );
};

export default UploadFile2;
