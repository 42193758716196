import { combineReducers } from '@reduxjs/toolkit';

import calendarReducer from './academic-calendar/slice';
import academicStructureReducer from './academic-structure/slice';
import administratorsReducer from './administrators/slice';
import admissionsReducer from './admissions/slice';
import appointmentReducer from './appointments/slice';
import authReducer from './auth/slice';
import bursaryReducer from './bursary/slice';
import collegeReducer from './college/slice';
import communicationsReducer from './communications/slice';
import courseFormReducer from './course-form-magt/slice';
import coursesReducer from './courses/slice';
import curriculumReducer from './curriculum/slice';
import departmentsReducer from './departments/slice';
import designationsReducer from './designations/slice';
import distributionListReducer from './distribution-list/slice';
import examMagtReducer from './exam-magt/slice';
import exeatReducer from './exeat/slice';
import facultyReducer from './faculty/slice';
import handbookReducer from './handbook/slice';
import ictReducer from './ict/slice';
import organisationUnitsReducer from './organisation-unit/slice';
import permissionsReducer from './permissions/slice';
import programmesReducer from './programmes/slice';
import rolesReducer from './roles/slice';
import schoolReducer from './school/slice';
import settingsReducer from './settings/slice';
import specialLetterReducer from './special-letter/slice';
import staffReducer from './staff/slice';
import staffArenaReducer from './staff-arena/slice';
import studentsReducer from './students/slice';
import timetableReducer from './timetable/slice';
import userReducer from './user/slice';

const combinedReducer = combineReducers({
    academicStructure: academicStructureReducer,
    administrators: administratorsReducer,
    admission: admissionsReducer,
    appointment: appointmentReducer,
    auth: authReducer,
    bursary: bursaryReducer,
    calendar: calendarReducer,
    college: collegeReducer,
    communications: communicationsReducer,
    courseForm: courseFormReducer,
    courses: coursesReducer,
    curriculum: curriculumReducer,
    department: departmentsReducer,
    designations: designationsReducer,
    distributionList: distributionListReducer,
    examMagt: examMagtReducer,
    exeat: exeatReducer,
    faculty: facultyReducer,
    ict: ictReducer,
    handbook: handbookReducer,
    organisationUnits: organisationUnitsReducer,
    permissions: permissionsReducer,
    programmes: programmesReducer,
    roles: rolesReducer,
    school: schoolReducer,
    settings: settingsReducer,
    specialLetter: specialLetterReducer,
    staff: staffReducer,
    staffArena: staffArenaReducer,
    student: studentsReducer,
    timetable: timetableReducer,
    user: userReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return combinedReducer(state, action);
};
