export const clinicNavLinks = [
    {
        title: 'Communications',
        to: '/clinic/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/clinic/communications/compose',
            },
            {
                title: 'Received',
                to: '/clinic/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/clinic/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/clinic/communications/settings',
            },
        ],
    },
    {
        title: 'Clinic',
        to: '/clinic/home',
    },
    {
        title: 'Academic Calendar',
        to: '/clinic/academic-calendar',
    },
    {
        title: 'Access',
        to: '/clinic/access',
    },
    {
        title: 'Settings',
        to: '/clinic/settings',
    },
];

export const clinicRoutes = clinicNavLinks.map((item) => item.to);
