import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import Avatar from '../../avatar/Avatar';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const FileDetails = ({ show, close, profile, fullName, surname, email, file, size }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween className="border-b pb-[1rem]">
                <FlexCentredRow>
                    <Text as="h3" size="1.9rem" weight="600">
                        Details
                    </Text>
                </FlexCentredRow>

                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>
            <Text align="left" size="1.4rem" lineHeight="2.4rem" weight="500" top="1.6rem">
                People with access
            </Text>
            <FlexRowSpaceBetween>
                <FlexCentredRow className="gap-[1rem]">
                    {<img alt="profile" src={profile} className="rounded-full w-[2rem] h-[2rem]" /> || (
                        <Avatar name={`${surname}`} size="40" textSizeRatio={1.75} className="rounded-full" />
                    )}
                    <FlexCentredCol>
                        <Text>{fullName}</Text>
                        <Text size="1.2rem" lineHeight="1.7rem" color="#374151" weight="500">
                            {email}
                        </Text>
                    </FlexCentredCol>
                </FlexCentredRow>
                <Text size="1.2rem" lineHeight="1.7rem" weight="200">
                    {file?.owner === 'me' && 'owner'}
                </Text>
            </FlexRowSpaceBetween>
            <FlexCentredCol className="mt-[1.5rem]">
                <Text align="left" weight="500">
                    Date Created
                </Text>
                <Text align="left" size="1.2rem" lineHeight="1.7rem" color="#374151" weight="400">
                    {file?.dateCreated}
                </Text>
            </FlexCentredCol>
            <FlexCentredCol className="mt-[1.5rem]">
                <Text align="left" weight="500">
                    Size
                </Text>
                <Text align="left" size="1.2rem" lineHeight="1.7rem" color="#374151" weight="400">
                    {size} MB
                </Text>
            </FlexCentredCol>
        </StyledModal>
    );
};

export default FileDetails;
