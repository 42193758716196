import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const examSlice = createSlice({
    name: 'designations',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const { LOADING, STOP_LOADING } = examSlice.actions;

export default examSlice.reducer;
