import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { createCollegeSecondLevel, getCollegeSecondLevel } from '../../../redux/college/actions';
import { createFacultySecondLevel, getFacultySecondLevel } from '../../../redux/faculty/actions';
import { capitalizeFirstLetter } from '../../../utils';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddNewDepartmentForm = ({ show, setShow, onFinish }) => {
    const dispatch = useDispatch();

    const { currentFaculty } = useSelector((state) => state.faculty);
    const { currentStructure } = useSelector((state) => state.academicStructure);

    const { _id: facultyId, splitAcademicStructureTwo, academicStructure, academicStructureId } = currentFaculty;

    const onClose = () => setShow(false);
    const [loading, setLoading] = useState(false);

    return (
        <FormContainer close={onClose} show={show} title={`Add New ${splitAcademicStructureTwo}`}>
            <Formik
                initialValues={{
                    department: '',
                    departmentCode: '',
                    description: '',
                    studyDuration: '',
                    departmentNumberingCode: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    let payloadToUse, res;
                    const { department, departmentCode, description, studyDuration, departmentNumberingCode } = values;
                    const splitStructTwo = splitAcademicStructureTwo?.toLowerCase();
                    const currentStruct = currentStructure?.name?.toLowerCase();

                    const commonPayload = {
                        departmentCode: departmentCode.split(','),
                        department: capitalizeFirstLetter(department),
                        ...(departmentNumberingCode ? { departmentNumberingCode } : {}),
                    };

                    switch (splitStructTwo) {
                        case 'school':
                            payloadToUse = {
                                schoolName: commonPayload.department,
                                schoolCode: commonPayload.departmentCode,
                                ...(commonPayload.departmentNumberingCode
                                    ? { schoolNumberingCode: commonPayload.departmentNumberingCode }
                                    : {}),
                            };
                            break;
                        case 'program':
                            payloadToUse = {
                                subprogram: commonPayload.department,
                                subProgramCode: commonPayload.departmentCode,
                                ...(commonPayload.departmentNumberingCode
                                    ? { subProgramNumberingCode: commonPayload.departmentNumberingCode }
                                    : {}),
                            };
                            break;
                        case 'department':
                            payloadToUse = {
                                ...commonPayload,
                            };
                            break;
                        case 'faculty':
                            payloadToUse = {
                                facultyName: commonPayload.department,
                                facultyCode: commonPayload.departmentCode,
                                ...(commonPayload.departmentNumberingCode
                                    ? { facultyNumberingCode: commonPayload.departmentNumberingCode }
                                    : {}),
                            };
                            break;
                        case 'college':
                            payloadToUse = {
                                collegeName: commonPayload.department,
                                collegeCode: commonPayload.departmentCode,
                                ...(commonPayload.departmentNumberingCode
                                    ? { collegeNumberingCode: commonPayload.departmentNumberingCode }
                                    : {}),
                            };
                            break;
                        default:
                            break;
                    }

                    if (currentStruct === 'faculty' || currentStruct === 'college') {
                        const payload = { ...payloadToUse, description, studyDuration };
                        const actionType =
                            currentStruct === 'faculty' ? createFacultySecondLevel : createCollegeSecondLevel;
                        res = await dispatch(actionType(payload, facultyId, academicStructure));
                    }

                    if (res) {
                        toast.success(`${splitAcademicStructureTwo} added successfully`);
                        const params = { academicStructureId, firstDegreeId: facultyId, structTwo: splitStructTwo };

                        if (currentStruct === 'faculty') {
                            dispatch(getFacultySecondLevel(params));
                        } else if (currentStruct === 'college') {
                            dispatch(getCollegeSecondLevel(params));
                        }
                        onClose();
                        onFinish?.();
                    }
                    setLoading(false);
                }}
            >
                {({ values, errors, handleChange, setFieldValue }) => (
                    <Form>
                        <div className="gap-5 flex flex-col">
                            <TextInput
                                label={`${splitAcademicStructureTwo} Name`}
                                name="department"
                                type="text"
                                placeholder={`${splitAcademicStructureTwo} Name`}
                                errors={errors}
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Description"
                                name="description"
                                type="text"
                                placeholder="Short Description"
                                errors={errors}
                                onChange={handleChange}
                            />
                            <TextInput
                                label={`${splitAcademicStructureTwo} code (Separate with comma if more than one)`}
                                name="departmentCode"
                                type="text"
                                placeholder="e.g. MAT, MATH"
                                errors={errors}
                                onChange={handleChange}
                            />
                            <TextInput
                                label={`${splitAcademicStructureTwo} Numbering Code`}
                                name="departmentNumberingCode"
                                type="text"
                                placeholder="e.g. MAT1234"
                                errors={errors}
                                onChange={handleChange}
                            />
                            <Select
                                name="studyDuration"
                                objProp="name"
                                label="Study Duration"
                                data={[3, 4, 5, 6, 7].map((item) => ({ name: item }))}
                                placeholder="Study duration"
                                onChange={(selected) => {
                                    setFieldValue('studyDuration', selected[0].name);
                                }}
                                error={errors.studyDuration}
                            />
                        </div>
                        <FlexContainer>
                            <CancelButton type="button" onClick={onClose}>
                                Cancel
                            </CancelButton>
                            <LoadingButton loading={loading} type="submit">
                                Add
                            </LoadingButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default AddNewDepartmentForm;
