import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { LoadingButton } from '../../../../components/buttons';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { changePassword } from '../../../../redux/staff-arena/actions';

import { ValidationSchema } from './validationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 0.8rem;
        padding-bottom: 4.4rem;
    }
`;

const ChangePassword = ({ close, show }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.staffArena);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    oldpassword: '',
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const res = await dispatch(changePassword(values));
                    if (res) {
                        toast.success('Password changed successfully!');
                        close();
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <StyledModal show={show} centered closeOnBackdrop={true} backdrop={true} color="info">
                        <Form>
                            <div className="w-[32rem] md:w-[45rem] h-auto bg-white mx-auto p-[2rem] md:p-[2.4rem] relative">
                                <FlexRowSpaceBetween>
                                    <Text size="1.9rem" weight="600" bottom="1rem" align="left">
                                        PREFERENCE
                                    </Text>
                                    <CloseIcon className="cursor-pointer" onClick={close} />
                                </FlexRowSpaceBetween>
                                <hr />

                                <div className="grid grid-row-6 gap-6 mt-[3rem]">
                                    <TextInput
                                        name="oldpassword"
                                        type={showOldPassword ? 'text' : 'password'}
                                        inputType="password"
                                        placeholder="Old Password"
                                        label="Old Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowOldPassword((prevState) => !prevState)}
                                    />
                                    <TextInput
                                        name="password"
                                        type={showNewPassword ? 'text' : 'password'}
                                        inputType="password"
                                        label="New password"
                                        placeholder="Enter New Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowNewPassword((prevState) => !prevState)}
                                    />
                                    <TextInput
                                        name="confirmPassword"
                                        type={showRepeatPassword ? 'text' : 'password'}
                                        inputType="password"
                                        placeholder="Repeat New Password"
                                        label="Repeat Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowRepeatPassword((prevState) => !prevState)}
                                    />

                                    <Text align="left" color="#6B7280" size="1.4rem" weight="400" lineHeight="2.4rem">
                                        Use a password at least 8 characters long with both letters and numbers.
                                    </Text>
                                </div>

                                <FlexRowEnd className="mt-6">
                                    <LoadingButton
                                        loading={isLoading}
                                        disabled={isLoading}
                                        bgColor="#6366F1"
                                        color="#fff"
                                        type="submit"
                                    >
                                        Change Password
                                    </LoadingButton>
                                </FlexRowEnd>
                            </div>
                        </Form>
                    </StyledModal>
                )}
            </Formik>
        </>
    );
};

export default ChangePassword;
