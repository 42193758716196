import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as ChevronGrey } from '../../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup, Timetable as DepartmentTimetable } from '../../../../../components';
import { Button } from '../../../../../components/buttons';
import { FlexCentredRow, RelativeContainer } from '../../../../../containers/ScreenContainers';
import { getSecondDegreeTimetable } from '../../../../../redux/departments/actions';
import { generateWeeklyEvents } from '../../../../../utils';

const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    mt-[2.4rem]
    rounded-lg
`;

const Timetable = () => {
    const { currentDepartment } = useSelector((state) => state.department);
    const { departmentTimetable } = useSelector((store) => store.department);
    const { activeSession, activeSemester, sessions } = useSelector((store) => store.admission);
    const dispatch = useDispatch();
    const { academicStructureId, academicStructure, _id } = currentDepartment || {};

    const [showDropdown, setShowDropdown] = useState(null);
    const [semester, setSemester] = useState(activeSemester);
    const [session, setSession] = useState(activeSession);
    let allEvents = [];

    const firstDegree = academicStructure?.split('-')[0];
    const firstDegreeId = currentDepartment[`${firstDegree}Id`];

    const handleDropdown = (action) => {
        if (action === showDropdown) {
            return setShowDropdown(null);
        }
        setShowDropdown(action);
    };

    useEffect(() => {
        dispatch(getSecondDegreeTimetable(academicStructureId, firstDegreeId, _id, session, semester));
    }, [dispatch, academicStructureId, firstDegreeId, _id, session, semester]);

    departmentTimetable?.forEach((event) => {
        const events = generateWeeklyEvents(event);
        allEvents = [...allEvents, ...events];
    });

    return (
        <>
            <MainContainer>
                <FlexCentredRow className="gap-[2.5rem]">
                    <RelativeContainer>
                        <Button
                            bgColor="#FFF"
                            color="#6B7280"
                            border="0.5px solid #9CA3AF"
                            onClick={() => handleDropdown('session')}
                        >
                            {session || 'Select Session'}
                            <ChevronGrey />
                        </Button>
                        <ActionsPopup
                            open={showDropdown === 'session'}
                            close={() => setShowDropdown(null)}
                            items={sessions?.map((item) => ({
                                name: item.admissionYear,
                                click: () => {
                                    setSession(item.admissionYear);
                                    setShowDropdown(null);
                                },
                            }))}
                        />
                    </RelativeContainer>
                    <RelativeContainer>
                        <Button
                            bgColor="#FFF"
                            color="#6B7280"
                            border="0.5px solid #9CA3AF"
                            onClick={() => handleDropdown('semester')}
                        >
                            {semester || 'Select Semester'}
                            <ChevronGrey />
                        </Button>
                        <ActionsPopup
                            open={showDropdown === 'semester'}
                            close={() => setShowDropdown(null)}
                            items={[
                                {
                                    name: 'First Semester',
                                    click: () => {
                                        setShowDropdown(null);
                                        setSemester('First Semester');
                                    },
                                },
                                {
                                    name: 'Second Semester',
                                    click: () => {
                                        setShowDropdown(null);
                                        setSemester('Second Semester');
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexCentredRow>

                <DepartmentTimetable eventsList={allEvents || []} />
            </MainContainer>
        </>
    );
};

export default Timetable;
