import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Checkbox } from '../../../components';
import UploadFile from '../../../components/upload-file';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { capitalizeFirstLetter, formatDate, getStatusBadge, sortData } from '../../../utils';

const InternalMemo = ({ tableHeader, tableData, selectedItem, handleSelect }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        const sortedData = sortData(tableData);
        setSortedData(sortedData);
    }, [tableData]);

    const InternalMemoData = sortedData?.map((intMemo) => {
        const formattedDate = formatDate(intMemo.createdAt);
        return {
            action: (
                <td>
                    <Checkbox checked={selectedItem?._id === intMemo?._id} />
                </td>
            ),
            time: formattedDate,
            subject: (
                <td
                    className="cursor-pointer text-[#2563EB]"
                    onClick={() => navigate(`view/${intMemo._id}`, { state: intMemo?.type })}
                >
                    {intMemo?.title}
                </td>
            ),
            commsStatus: getStatusBadge(
                capitalizeFirstLetter(intMemo.status === 'sent' ? 'Published' : intMemo?.status),
            ),

            ...intMemo,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={InternalMemoData}
                fields={tableHeader}
                onRowClick={(item) => handleSelect(item)}
            />
            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default InternalMemo;
