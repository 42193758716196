import React from 'react';

import { Form, Formik } from 'formik';

import { Text } from '../../../containers/MesssageContainers';
import { LoadingButton } from '../../buttons';
import FormikTextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const EditBillingInfoForm = () => {
    return (
        <div className="pt-[3.1rem] pb-[7.5rem]">
            <Text align="left" size="1.6rem" weight="600" color="#6B7280">
                Billing address
            </Text>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    address1: '',
                    address2: '',
                    city: '',
                    zipcode: '',
                    state: '',
                    country: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, action) => {
                    console.log(values);
                }}
            >
                {({ handleChange, errors }) => (
                    <Form className="pt-[1rem] w-[52.8rem] flex flex-col gap-[2.4rem]">
                        <FormikTextInput
                            label="Name"
                            name="name"
                            placeholder="Name"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <FormikTextInput
                            label="Email"
                            name="email"
                            placeholder="Email Address"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <FormikTextInput
                            label="Address 1"
                            name="address1"
                            placeholder="Address 1"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <FormikTextInput
                            label="Address 2 (Optional)"
                            name="address2"
                            placeholder="Address 2"
                            labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                            onChange={handleChange}
                            errors={errors}
                        />
                        <div className="flex items-center gap-[2.4rem]">
                            <FormikTextInput
                                label="City"
                                name="city"
                                placeholder="City"
                                labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                                onChange={handleChange}
                                errors={errors}
                            />
                            <FormikTextInput
                                label="Zip Code"
                                name="zipcode"
                                placeholder="Zip Code"
                                labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                                onChange={handleChange}
                                errors={errors}
                            />
                        </div>
                        <div className="flex items-center gap-[2.4rem]">
                            <FormikTextInput
                                label="State"
                                name="state"
                                placeholder="state"
                                labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                                onChange={handleChange}
                                errors={errors}
                            />
                            <FormikTextInput
                                label="Country"
                                name="country"
                                placeholder="country"
                                labelClass="text-[1.4rem] font-medium text-[#6B7280] pb-[0.8rem]"
                                onChange={handleChange}
                                errors={errors}
                            />
                        </div>
                        <LoadingButton bgColor="#6366F1" color="#fff" className="ml-[auto] mt-[0.8rem]">
                            Save
                        </LoadingButton>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditBillingInfoForm;
