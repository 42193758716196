import React, { useEffect, useState /*useRef */ } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GoBack } from '../../../components/go-back';
import { Loader } from '../../../components/loader';
//import useClickOutside from '../../../hooks/useClickOutside';
import { getStudentDetails } from '../../../redux/students/actions';

import Academics from './tabs/Academics';
import BasicInfo from './tabs/BasicInfo';
import Library from './tabs/Library';
import Medicals from './tabs/Medicals';
import Personal from './tabs/Personal';
import Results from './tabs/Results';
import Sports from './tabs/Sports';
import Uploads from './tabs/Uploads';

const RecordDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [, /*tab*/ setTab] = useState(0);

    const { studentDetails, isLoading } = useSelector((store) => store.student);

    useEffect(() => {
        dispatch(getStudentDetails(id));
    }, [dispatch, id]);

    if (isLoading || !studentDetails) return <Loader />;
    return (
        <div>
            <GoBack className="mb-[3.2rem]" title="Records" />

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Overview</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Personal</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(2)}>Academics</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(3)}>Medicals</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(4)}>Sports</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(5)}>Library</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(6)}>Results</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(7)}>Uploads</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <BasicInfo details={studentDetails} />
                    </CTabPane>
                    <CTabPane>
                        <Personal details={studentDetails} />
                    </CTabPane>
                    <CTabPane>
                        <Academics details={studentDetails} />
                    </CTabPane>
                    <CTabPane>
                        <Medicals details={studentDetails} />
                    </CTabPane>
                    <CTabPane>
                        <Sports details={studentDetails} />
                    </CTabPane>
                    <CTabPane>
                        <Library details={studentDetails} />
                    </CTabPane>
                    <CTabPane>
                        <Results />
                    </CTabPane>
                    <CTabPane>
                        <Uploads details={studentDetails} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default RecordDetails;
