import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { CancelButton } from '../../../components/forms/sharedStyles';
import Select from '../../../components/inputs/new-select';
import { addStaffToUnitAsAdminWithIds } from '../../../redux/administrators/actions';
import { getActiveOrganisationUnits } from '../../../redux/organisation-unit/actions';
import { getStaffInUnit } from '../../../redux/staff/actions';
import { ReactComponent as CloseIcon } from '../../.../../../assets/icons/close-sm.svg';
import { ReactComponent as PeopleIcon } from '../../.../../../assets/icons/people.svg';
import { LoadingButton } from '../../.../../../components/buttons';
import { GoBack } from '../../.../../../components/go-back';
import ConfirmAddAdministrator from '../../.../../../components/popups/confirm-add-administrator';
import ListWithCheckBox from '../../.../../../components/popups/list-with-checkbox';
import { Text } from '../../.../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../.../../../containers/ScreenContainers';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    w-full
`;

const Label = tw.label`
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const Title = tw.h2`
    text-[1.6rem]
    font-semibold
    leading-6
    text-[#1F2937]
    mb-[0]
`;

const TagInputContainer = tw.div`
    border
    border-[#E5E7EB]
    px-4
    py-4
    rounded-md
    flex
    flex-wrap
    gap-[1rem]
`;

const TagItems = tw.div`
    bg-[#6366F1]
    flex
    justify-center
    items-center
    text-[1.2rem]
    text-white
    rounded
    px-2
    py-1
    gap-4
    w-max
`;

const NewInput = tw.input`
    pl-3
    rounded
    outline-none
    focus:ring-0
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-full
    mr-3
    `;

const AddOrgAdministrator = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { filteredActiveOrgUnits: organisationUnits } = useSelector((state) => state.organisationUnits);
    const { isLoading } = useSelector((state) => state.administrators);
    const { isLoading: staffLoading } = useSelector((state) => state.staff);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState('');
    const [showConfirmAllocation, setShowConfirmAllocation] = useState(false);
    const [staffNames, setStaffNames] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [staff, setStaff] = useState([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleRemove = (item) => {
        setStaffNames(staffNames.filter((ite) => ite.id !== item.id));
    };

    useEffect(() => {
        dispatch(getActiveOrganisationUnits());
    }, [dispatch]);

    useEffect(() => {
        if (selectedUnit) {
            setStaffNames([]);
            const getDetails = async () => {
                const data = await dispatch(getStaffInUnit({ unitName: selectedUnit?.unitName }));

                if (data?.length > 0) {
                    const newData = data?.map((item) => ({
                        ...item,
                        fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
                        id: item._id,
                    }));
                    setStaff(newData);
                } else {
                    setStaff([]);
                }
            };

            getDetails();
        }
    }, [dispatch, selectedUnit]);

    const handleSubmit = async () => {
        const staffIds = staffNames?.map((item) => item._id);
        const res = await dispatch(addStaffToUnitAsAdminWithIds(selectedUnit?._id, { staffNames: staffIds }));

        if (res) {
            toast.success('Administrator added!');
            navigate(-1);
        }
    };

    return (
        <div className="h-[100vh]">
            <div className="mb-12">
                <GoBack title={`Administrators`} subtitle="Add Administrator" />
            </div>

            <Formik
                initialValues={{
                    organizationUnit: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async () => {
                    setShowConfirmAllocation(true);
                }}
            >
                {({ errors, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <div className="pl-6">
                                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem] ">
                                    <PeopleIcon />
                                    <Title>Add Administrator(s)</Title>
                                </FlexCentredRow>
                                <FlexCentredCol className=" gap-5 mb-[2.4rem]">
                                    <Select
                                        name="organizationUnit"
                                        objProp="unitName"
                                        placeholder="Select Unit"
                                        label="Organization Unit"
                                        data={organisationUnits}
                                        onChange={(selected) => {
                                            setFieldValue('organizationUnit', String(selected[0].unitName));
                                            setSelectedUnit(selected[0]);
                                        }}
                                        error={errors.organizationUnit}
                                    />

                                    {selectedUnit && (
                                        <div className="relative">
                                            <Label>Staff</Label>
                                            <TagInputContainer onClick={toggleDropdown}>
                                                {staffNames.length ? (
                                                    <>
                                                        {staffNames?.map((item) => (
                                                            <TagItems key={item.id}>
                                                                <Text color="#FFF">{item.fullName} </Text>
                                                                <CloseIcon
                                                                    className="hover:cursor-pointer"
                                                                    onClick={() => handleRemove(item)}
                                                                />
                                                            </TagItems>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <NewInput
                                                        type="text"
                                                        placeholder={isOpen ? '' : 'Select a staff'}
                                                        name="name"
                                                        errors={errors}
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </TagInputContainer>

                                            {isOpen && (
                                                <div className="absolute z-[5]">
                                                    <ListWithCheckBox
                                                        list={staff}
                                                        setSelectedItems={setStaffNames}
                                                        selectedItems={staffNames}
                                                        open={isOpen}
                                                        onClose={() => {
                                                            setIsOpen(false);
                                                        }}
                                                        idField="id"
                                                        isLoading={staffLoading}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </FlexCentredCol>
                            </div>

                            <FlexRowEnd className="gap-[2.4rem] mb-[3.6rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    type="submit"
                                    disabled={!staffNames.length || isLoading}
                                >
                                    Add Administrator
                                </LoadingButton>
                            </FlexRowEnd>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
            <ConfirmAddAdministrator
                show={showConfirmAllocation}
                close={() => setShowConfirmAllocation(false)}
                list={staffNames}
                role={'Admin'}
                confirmAction={handleSubmit}
            />
        </div>
    );
};

export default AddOrgAdministrator;
