import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_EXEAT, GET_EXEAT_BY_ID, LOADING, STOP_LOADING } from './slice';

export const approveExeat = (exeatId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const response = await axiosInstance.put(`${currentAPI}exeat/${currentUnit?._id}/approve/${exeatId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const rejectExeat = (exeatId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const response = await axiosInstance.put(`${currentAPI}exeat/${currentUnit?._id}/reject/${exeatId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllExeatRequest = (session, level, program) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const response = await axiosInstance.get(
            `${currentAPI}exeat/${currentUnit?._id}?session=${session}&level=${level}&program=${program}&unitName=${currentUnit?.unitName}`,
        );
        dispatch(GET_EXEAT(response.data.data));
        return true;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExeatById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const response = await axiosInstance.get(
            `${currentAPI}exeat/${currentUnit?._id}/${id}?unitName=${currentUnit?.unitName}`,
        );
        dispatch(GET_EXEAT_BY_ID(response.data.data));
        return true;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
