// Pages that can only be visited when user is authenticated

import { HOSTEL } from '../../utils/unitNames';
import {
    HostelManagement,
    NewHostel,
    HostelList,
    HostelDetails,
    RoomDetails,
    ViewTenant,
    GeneralSettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
} from '../../views';

export const hostelRoutes = [
    {
        path: '/hostel',
        element: <HostelManagement />,
    },
    {
        path: '/hostel/new-hostel',
        element: <NewHostel />,
    },
    {
        path: '/hostel/hostel-list',
        element: <HostelList />,
    },
    {
        path: '/hostel/hostel-list/:id',
        element: <HostelDetails />,
    },
    {
        path: '/hostel/hostel-list/room-details/:id',
        element: <RoomDetails />,
    },
    {
        path: '/hostel/hostel-list/room-details/view-tenant',
        element: <ViewTenant />,
    },
    {
        path: '/hostel/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/hostel/communications/distribution-list',
        element: <DistributionList unitName={HOSTEL} title="Communications" />,
    },
    {
        path: '/hostel/communications/distribution-list/add',
        element: <AddDistributionList unitName={HOSTEL} title="Communications" />,
    },
    {
        path: '/hostel/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={HOSTEL} title="Communications" />,
    },
];
