import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import {
    LOADING,
    STOP_LOADING,
    GET_ALL_LECTURE_TIMETABLE,
    GET_MONDAY_TIMETABLE,
    GET_TUESDAY_TIMETABLE,
    GET_WEDNESDAY_TIMETABLE,
    GET_THURSDAY_TIMETABLE,
    GET_FRIDAY_TIMETABLE,
    GET_ALL_EXAM_TIMETABLE,
    GET_MONDAY_EXAM_TIMETABLE,
    GET_TUESDAY_EXAM_TIMETABLE,
    GET_WEDNESDAY_EXAM_TIMETABLE,
    GET_THURSDAY_EXAM_TIMETABLE,
    GET_FRIDAY_EXAM_TIMETABLE,
    GET_EXAM_TIMETABLE,
    GET_FIRST_DEGREE_TIMETABLE,
    GET_SATURDAY_TIMETABLE,
    GET_SUNDAY_TIMETABLE,
    GET_SATURDAY_EXAM_TIMETABLE,
    GET_SUNDAY_EXAM_TIMETABLE,
} from './slice';

export const createLectureTimetable = (payload, structureId, secondDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}timetable/${currentUnit?._id}/create_timetable/${structureId}/${secondDegreeId}`,
            payload,
        );
        // dispatch(CREATE_TIMETABLE(payload));
        const successMessage = response?.data?.data.message || 'Lecture timetable created Successfully';
        toast.success(successMessage);
        return true;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createLectureTimetableInFirstDegree =
    (payload, structureId, firstDegreeId) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}timetable/${currentUnit?._id}/create_timetable_in_first_degree/${structureId}/${firstDegreeId}`,
                payload,
            );
            const successMessage = response?.data?.data.message || 'Lecture timetable created Successfully';
            toast.success(successMessage);
            return true;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getAllLectureTimetable = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/all_timetable`);
        dispatch(GET_ALL_LECTURE_TIMETABLE(response.data.data));
    } catch (error) {
        return error;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getMondayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/monday`,
        );
        dispatch(GET_MONDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getTuesdayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/tuesday`,
        );
        dispatch(GET_TUESDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getWednesdayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/wednesday`,
        );
        dispatch(GET_WEDNESDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getThursdayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/thursday`,
        );
        dispatch(GET_THURSDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFridayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/friday`,
        );
        dispatch(GET_FRIDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSaturdayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/saturday`,
        );
        dispatch(GET_SATURDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSundayTimetable = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/weekday/${structureId}/${firstDegreeId}/sunday`,
        );
        dispatch(GET_SUNDAY_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createExamTimetable = (payload, structureId, secondDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}timetable/${currentUnit?._id}/create_exam_timetable/${structureId}/${secondDegreeId}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Exam timetable created Successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllExamTimetable = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/all_exam_timetable`);
        dispatch(GET_ALL_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getMondayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/monday`);
        dispatch(GET_MONDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getTuesdayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/tuesday`);
        dispatch(GET_TUESDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getWednesdayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/wednesday`,
        );
        dispatch(GET_WEDNESDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getThursdayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/thursday`);
        dispatch(GET_THURSDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFridayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/friday`);
        dispatch(GET_FRIDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getSaturdayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/saturday`);
        dispatch(GET_SATURDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getSundayExam = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}timetable/${currentUnit?._id}/exam_by_weekday/sunday`);
        dispatch(GET_SUNDAY_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteLectureTimetable = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}timetable/${currentUnit?._id}/delete_timetable/${id}`,
        );
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Lecture Timetable Deleted Successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getLectureTimetable = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/get_timetable_by_id/${id}`,
        );
        return response.data.data;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getExamTimetableById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}timetable/${currentUnit?._id}/get_exam_timetable_by_id/${id}`,
        );
        dispatch(GET_EXAM_TIMETABLE(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editLectureTimetable = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}timetable/${currentUnit?._id}/update_timetable/${id}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editExamTimetable = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}timetable/${currentUnit?._id}/edit_exam_timetable/${id}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getLectureTimetableInFirstDegree =
    (structureId, firstDegreeId, session, semester) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}timetable/${currentUnit?._id}/get_lecture_timetable_in_first_degree/${structureId}/${firstDegreeId}?session=${session}&semester=${semester}`,
            );
            dispatch(GET_FIRST_DEGREE_TIMETABLE(response.data.data));
        } catch (error) {
            return error;
        } finally {
            dispatch(STOP_LOADING());
        }
    };
