import React from 'react';

import bgLogo from '../../../../assets/background.png';
import { Button } from '../../../../components/buttons/index';

const SuccessPage = () => {
    return (
        <div className="bg-white flex justify-center items-center min-h-screen overflow-y-auto w-full pt-[10vh] pb-[10vh]">
            <div
                className="flex flex-col items-center justify-center w-[90%] md:w-[70%] lg:w-[50%] bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${bgLogo})`, height: '60vh' }}
            >
                <div className="flex flex-col items-start justify-center p-10 bg-white shadow-lg rounded-lg max-w-3xl mx-auto">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-12 text-gray-800">Sent!</h1>
                    <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-4">
                        Our business development executive will contact you via the
                    </p>
                    <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-16">
                        provided email or phone number to arrange a demo.
                    </p>
                    <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-12">
                        Expect a response in 1 business day.
                    </p>
                    <div className="w-full flex justify-end mt-6">
                        <a href="https://belrald.com/" rel="noopener noreferrer">
                            <Button type="button" bgColor="#6366F1" color="#ffffff">
                                Return to Home Page
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;
