/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/people.svg';
import { Button } from '../../../../../components/buttons';
import { GoBack } from '../../../../../components/go-back';
import Select from '../../../../../components/inputs/new-select';
import { Loader } from '../../../../../components/loader';
import ConfirmAddAdministrator from '../../../../../components/popups/confirm-add-administrator';
import { FlexCentredRow, FlexDiv } from '../../../../../containers/ScreenContainers';
import { addStaffToUnitAsAdmin } from '../../../../../redux/administrators/actions';
import { getDepartmentStaff } from '../../../../../redux/departments/actions';
import { getDepartmentProgrammes } from '../../../../../redux/programmes/actions';
import { assignRoleInDepartment, getRolesInDepartment } from '../../../../../redux/roles/actions';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    w-full
`;

const FlowRoot = tw.div`
    flow-root
    pr-6
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

// NOT IN USE?
const AddDepartmentAdministrators = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading: staffLoading, staff, currentDepartment } = useSelector((state) => state.department);
    const { isLoading, departmentProgrammes } = useSelector((state) => state.programmes);

    const { _id: departmentId, academicStructure, academicStructureId } = currentDepartment || {};

    const [showConfirmAllocation, setShowConfirmAllocation] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [roles, setRoles] = useState([]);

    const structure = academicStructure?.split('-');

    const handleSubmit = async () => {
        const names = selectedStaff?.map((item) => item.fullName);

        const res = await dispatch(addStaffToUnitAsAdmin(unitName, { staffNames: names }));
        if (res) {
        navigate(-1);
        }
    };

    useEffect(() => {
        const getRoles = async () => {
            const data = await dispatch(getRolesInDepartment(departmentId));
            data && setRoles(data);
        };

        if (departmentId) {
            dispatch(getDepartmentStaff(academicStructureId, departmentId));
            dispatch(getDepartmentProgrammes(departmentId));
            getRoles();
        }
    }, [dispatch, departmentId, academicStructureId]);

    if (staffLoading || isLoading) return <Loader />;

    return (
        <>
            <div className="mb-12">
                <GoBack title={`Administrators`} subTitle="Add Administrator" />
            </div>

            <Formik
                initialValues={{
                    name: '',
                    role: '',
                    subProgram: '',
                    staffId: '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const { role, subProgram } = values;
                    const res = await dispatch(assignRoleInDepartment(values.staffId, { role, subProgram }));

                    if (res) {
                        toast.success('Role has been assigned successfully');
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <FlexCentredRow className="gap-[7.15px] mb-[3.8rem] ">
                                <PeopleIcon />
                                <Title>Add Administrator(s)</Title>
                            </FlexCentredRow>

                            <div className="grid gap-6">
                                <Select
                                    name="name"
                                    objProp="fullName"
                                    placeholder="Name"
                                    label="Name"
                                    data={staff}
                                    onChange={(selected) => {
                                        setFieldValue('name', String(selected[0].facultyName));
                                        setFieldValue('staffId', String(selected[0].id));
                                    }}
                                    error={''}
                                />
                                <Select
                                    name="role"
                                    objProp="role"
                                    placeholder="Select Role(s)"
                                    label="Select Role(s)"
                                    data={roles}
                                    onChange={(selected) => {
                                        setFieldValue('role', String(selected[0].role));
                                    }}
                                    error={''}
                                />
                                {structure?.length === 3 && (
                                    <Select
                                        name="subprogram"
                                        objProp="subprogram"
                                        placeholder={`Select ${structure[2]}`}
                                        label={`Select ${structure[2]}`}
                                        data={departmentProgrammes}
                                        onChange={(selected) => {
                                            setFieldValue('subProgram', String(selected[0].subprogram));
                                        }}
                                        error={''}
                                    />
                                )}
                            </div>

                            <FlowRoot>
                                <FlexDiv className="float-right gap-[2.4rem] mb-[3.6rem]">
                                    <Button
                                        bgColor="#fff"
                                        color="#D1D5DB"
                                        border="1px solid #D1D5DB"
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        className="py-2 px-4 mt-[1.9rem] mb-[1.9rem] cursor-pointer border-[#E5E7EB] "
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        bgColor="#6366F1"
                                        color="#fff"
                                        className="py-2 px-4 hover:bg-[#6366F1] focus:bg-[#6366F1] focus:text-white mt-[1.9rem] cursor-pointer"
                                    >
                                        Add Administrator
                                    </Button>
                                </FlexDiv>
                            </FlowRoot>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
            <ConfirmAddAdministrator
                show={showConfirmAllocation}
                close={() => setShowConfirmAllocation(false)}
                list={selectedStaff}
                role={'Administrator'}
                confirmAction={() => handleSubmit()}
            />
        </>
    );
};

export default AddDepartmentAdministrators;
