import React, { useState, useEffect } from 'react';

import Avatar from 'react-avatar';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Copy } from '../../../../../assets/icons/copy.svg';
import { ReactComponent as Delete } from '../../../../../assets/icons/delete-red.svg';
import { ReactComponent as MSWordIcon } from '../../../../../assets/icons/doc-icon.svg';
import { ReactComponent as Download } from '../../../../../assets/icons/download-new.svg';
import { ReactComponent as Preview } from '../../../../../assets/icons/external-Link.svg';
import { ReactComponent as Move } from '../../../../../assets/icons/move.svg';
import { ReactComponent as PDFIcon } from '../../../../../assets/icons/pdfIcon.svg';
import { ReactComponent as FileIcon } from '../../../../../assets/icons/ptx-icon.svg';
import { ReactComponent as RenameIcon } from '../../../../../assets/icons/rename-icon.svg';
import { ReactComponent as ShareIcon } from '../../../../../assets/icons/Share.svg';
import { ReactComponent as MenuIcon } from '../../../../../assets/icons/three-dot.svg';
import { ReactComponent as Details } from '../../../../../assets/icons/warning-black.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../../components';
import ListEmptyContent from '../../../../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../../../../components/popups/confirm-action-with-input';
import FileDetails from '../../../../../components/popups/details';
import MoveFolder from '../../../../../components/popups/move-folder';
import Share from '../../../../../components/popups/share';
import { Text } from '../../../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../../../containers/ScreenContainers';
// import useDebounce from '../../../../../hooks/useDebounce';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { deleteFile, getUploadedFile, renameFile } from '../../../../../redux/staff-arena/actions';
import { capitalizeFirstLetter, handleDownload, handleImagePreview, handlePdfPreview } from '../../../../../utils';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'File Name',
        accessorKey: 'fileName',
    },

    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Date Created',
        accessorKey: 'dateCreated',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Owner',
        accessorKey: 'ownedBy',
    },
];

const Files = ({ toggleRefetch, setToggleRefetch }) => {
    const dispatch = useDispatch();
    const { isLoading, uploadedFiles } = useSelector((state) => state.staffArena);

    const [searchValue, setSearchValue] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [fileName, setFileName] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState('');
    const [renameFiles, setRenameFiles] = useState(false);
    const [showdetails, setShowDetails] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [share, setShare] = useState(false);
    const [move, setMove] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [fullName, setFullName] = useState('');
    const [profile, setProfile] = useState(uploadedFiles?.map((item) => item?.profilePicture?.url));

    useEffect(() => {
        if (selectedId) {
            const file = uploadedFiles.find((item) => item._id === selectedId);
            if (file) {
                setFullName(file?.createdBy);
                setProfile(file?.profilePicture?.url);
            }
        }
    }, [selectedId, uploadedFiles]);

    const sizeInBytes = uploadedFiles?.map((item) => parseInt(item?.content?.[0]?.size, 10));
    const size = isNaN(sizeInBytes) ? '0.00' : (sizeInBytes / (1024 * 1024)).toFixed(2);

    const handleRenameFile = async () => {
        const selectedFile = uploadedFiles?.find((item) => item._id === selectedId);
        const fileId = selectedFile?._id;
        const payload = {
            fileId: fileId,
            newName: fileName,
        };

        if (selectedId) {
            const res = await dispatch(renameFile(payload));
            if (res) {
                setDropdownOpen(false);
                setFileName('');
                toast.success('File renamed successfully!');
                setToggleRefetch((prevState) => !prevState);
            }
        }
    };
    const handleFileDownload = async () => {
        const selectedFile = uploadedFiles?.find((item) => item._id === selectedId);
        if (selectedFile && selectedFile.content && selectedFile.content[0]) {
            const downloadUrl = selectedFile.content[0].url;
            const fileName = selectedFile.content[0].fileName;

            if (downloadUrl && fileName) {
                handleDownload(downloadUrl, fileName);
            } else {
                toast.error('Download URL or file name is missing.');
            }
        } else {
            toast.error('File not found.');
        }
    };

    const handleFilePreview = async () => {
        const selectedFile = uploadedFiles.find((item) => item?._id === selectedId);
        if (selectedFile && selectedFile?.content && selectedFile?.content[0]) {
            const fileUrl = selectedFile?.content[0]?.url;
            const fileName = selectedFile?.content[0]?.fileName;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            if (fileExtension === 'pdf') {
                handlePdfPreview(fileUrl);
            } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
                handleImagePreview(fileUrl);
            } else {
                toast.error('Unsupported file type for preview.');
            }
        } else {
            toast.error('File not found.');
        }
    };

    const handleCopyLink = async () => {
        const selectedFile = uploadedFiles.find((item) => item?._id === selectedId);
        if (selectedFile && selectedFile?.content && selectedFile?.content[0]) {
            const fileUrl = selectedFile?.content[0].url;
            if (fileUrl) {
                navigator.clipboard.writeText(fileUrl);
                toast.success('Link copied to clipboard!');
            } else {
                toast.error('File URL is missing.');
            }
        } else {
            toast.error('File not found.');
        }
    };

    const handleDeleteFile = async () => {
        const selectedFile = uploadedFiles?.find((item) => item._id === selectedId);
        const fileId = selectedFile?._id;
        const payload = {
            fileId: fileId,
        };
        if (selectedId) {
            const res = await dispatch(deleteFile(payload));
            if (res) setOpenDelete(false);
            setSelectedId('');
            toast.success('File deleted successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const getFileIcon = (fileName) => {
        if (fileName.includes('xls')) return <FileIcon />;
        if (fileName.includes('ppt')) return <FileIcon />;
        if (fileName.includes('pdf')) return <PDFIcon />;
        if (fileName.includes('doc') || fileName.includes('docx')) return <MSWordIcon />;
        return null;
    };

    useEffect(() => {
        dispatch(getUploadedFile());
    }, [dispatch, toggleRefetch]);

    const modifiedFilesData = uploadedFiles?.map((item) => {
        const dateTimeString = item?.createdAt;
        const [datePart, timePart] = dateTimeString.split('T');
        const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

        const fileSuffix = item?.content?.[0]?.fileName;
        return {
            fileName: (
                <td className="cursor-pointer">
                    <div className="flex items-center gap-[1.6rem] ">
                        <div className="w-[4rem] h-[4rem] mt-[1.3rem]">{fileSuffix && getFileIcon(fileSuffix)}</div>

                        <div className="">{capitalizeFirstLetter(item?.content?.[0]?.fileName)}</div>
                    </div>
                </td>
            ),
            dateCreated: <td>{formattedDateTime}</td>,
            ownedBy: (
                <td className="flex items-center justify-between">
                    <div className="flex items-center gap-[1rem]">
                        {item?.profilePicture?.url ? (
                            <img
                                alt="profile"
                                src={item?.profilePicture?.url}
                                className="rounded-full w-[3rem] h-[3rem]"
                            />
                        ) : (
                            <Avatar name={`${item?.surname}`} size="30" textSizeRatio={1.75} className="rounded-full" />
                        )}
                        <Text>{item?.owner}</Text>
                    </div>

                    {/* <RelativeContainer className="ml-auto mr-0"> */}
                    <MenuIcon
                        className="cursor-pointer"
                        onClick={() => {
                            setSelectedId(item?._id);
                            setDropdownOpen(!dropdownOpen);
                        }}
                    />
                    <ActionsPopup
                        open={dropdownOpen && selectedId === item?._id}
                        close={() => setDropdownOpen(false)}
                        items={[
                            {
                                name: 'Download',
                                icon: <Download />,
                                click: () => {
                                    handleFileDownload();
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Preview',
                                icon: <Preview />,
                                click: () => {
                                    handleFilePreview();
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Details',
                                icon: <Details />,
                                click: () => {
                                    const fileDetails = modifiedFilesData.find((file) => file._id === item?._id);
                                    setSelectedFile(fileDetails);
                                    setShowDetails(true);
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Copy Link',
                                icon: <Copy />,
                                click: () => {
                                    handleCopyLink();
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Move to folder',
                                icon: <Move />,
                                click: () => {
                                    const fileDetails = modifiedFilesData.find((file) => file._id === item?._id);
                                    setFileName(fileDetails?.content[0]?.fileName);
                                    setSelectedId(item?._id);
                                    setMove(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Rename',
                                icon: <RenameIcon />,
                                click: () => {
                                    setRenameFiles(true);
                                    setSelectedId(item?._id);
                                    setDropdownOpen('Rename');
                                },
                            },
                            {
                                name: 'Share',
                                icon: <ShareIcon />,
                                click: () => {
                                    const fileDetails = modifiedFilesData.find((file) => file._id === item?._id);
                                    setFileName(fileDetails?.content[0]?.fileName);
                                    setSelectedId(fileDetails?._id);
                                    setShare(true);
                                    setSelectedId(item?._id);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                name: 'Delete ',
                                icon: <Delete />,
                                click: () => {
                                    setSelectedId(item?._id);
                                    setOpenDelete(true);
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                    {/* </RelativeContainer> */}
                </td>
            ),
            action: <td></td>,
            ...item,
        };
    });

    return (
        <>
            <div className="mb-[3rem] ">
                <PageLayout
                    className="relative"
                    searchable
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    fields={fields}
                    data={modifiedFilesData || []}
                    loading={isLoading}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent
                                isInTable
                                title={searchValue ? '' : 'No Files'}
                                subtitle={
                                    searchValue
                                        ? 'No file exist that match your search'
                                        : 'Get started by uploading files'
                                }
                            ></ListEmptyContent>
                        </CenteredContainer>
                    }
                    isActionable
                />

                <ConfirmActionDialogue
                    show={openDelete}
                    close={() => setOpenDelete(false)}
                    title="Delete Item"
                    subtitle="Are you sure you want to delete this permanently?"
                    btn2Text="Yes, delete"
                    confirmAction={handleDeleteFile}
                />

                <ConfirmActionDialogueWithInput
                    show={renameFiles}
                    borderLine={false}
                    svgIcon={<RenameIcon />}
                    title="Rename"
                    isLoading={isLoading}
                    subtitle="Name"
                    setInputValue={setFileName}
                    value={fileName}
                    close={() => setRenameFiles(false)}
                    confirmAction={() => {
                        handleRenameFile();
                        setRenameFiles(false);
                    }}
                    btn2Text="Rename"
                    placeholder="Input Name"
                    bgColor="#6366F1"
                />
                <FileDetails
                    show={showdetails}
                    close={() => setShowDetails(false)}
                    profile={profile}
                    fullName={fullName}
                    surname
                    email
                    size={size}
                    file={selectedFile}
                />
                <Share
                    id={selectedId}
                    show={share}
                    close={() => setShare(false)}
                    setOpen={() => setShare(false)}
                    fileName={fileName}
                    file={selectedFile}
                    type="file"
                />
                <MoveFolder
                    id={selectedId}
                    show={move}
                    close={() => setMove(false)}
                    setOpen={() => setMove(false)}
                    fileName={fileName}
                    type="file"
                    setToggleRefetch={() => setToggleRefetch((prevState) => !prevState)}
                />
            </div>
        </>
    );
};

export default Files;
