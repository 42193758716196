export const admissionsNavLinks = [
    { title: 'Admissions', to: '/admissions/sessions' },
    {
        title: 'Students',
        to: '/admissions/students',
    },
    {
        title: 'Academic Calendar',
        to: '/admissions/academic-calendar',
    },
    {
        title: 'Communications',
        to: '/admissions/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/admissions/communications/compose',
            },
            {
                title: 'Received',
                to: '/admissions/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/admissions/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/admissions/communications/settings',
            },
        ],
    },
    { title: 'Access', to: '/admissions/access' },
    {
        title: 'Settings',
        to: '/admissions/settings',
    },
];

export const admissionsRoutes = admissionsNavLinks.map((item) => item.to);
