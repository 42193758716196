import React from 'react';

import EditCourse from '../../../../../components/forms/edt-course';
import { GoBack } from '../../../../../components/go-back';

const EditNewCourse = () => {
    return (
        <>
            <div className="mb-12">
                <GoBack title="Courses" subtitle="Edit Course" />
            </div>
            <EditCourse />
        </>
    );
};

export default EditNewCourse;
