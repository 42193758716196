import { EXAMS_AND_RECORDS } from '../../utils/unitNames';
import {
    AcademicCalendar,
    Access,
    // AddAdministrator,
    AddDistributionList,
    Administrator,
    AssignRole,
    CreateRole,
    CreateStudent,
    DistributionList,
    EditAdministrator,
    EditRole,
    ExamsAndRecords,
    GeneralSettings,
    ExamsRecordsDepartments,
    ExamsRecordsStudentDetails,
    ExamsRecordsStudents,
    Received,
    Roles,
    Staff,
    StaffDetail,
    UpdateDistributionList,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ResultsBacklog,
    UploadResultsBacklog,
    ManualPromotion,
} from '../../views';

export const examsRecordsRoutes = [
    {
        path: '/exams-records/records',
        element: <ExamsAndRecords unitName={EXAMS_AND_RECORDS} />,
    },
    {
        path: '/exams-records/records/faculties/:id',
        element: <ExamsRecordsDepartments />,
    },
    {
        path: '/exams-records/records/departments/:id',
        element: <ExamsRecordsStudents />,
    },
    {
        path: '/exams-records/records/students/:id',
        element: <ExamsRecordsStudentDetails />,
    },
    {
        path: '/exams-records/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/exams-records/access',
        element: (
            <Access
                staffs={<Staff unitName={EXAMS_AND_RECORDS} />}
                administrators={<Administrator unitName={EXAMS_AND_RECORDS} />}
                roles={<Roles unitName={EXAMS_AND_RECORDS} />}
            />
        ),
    },
    {
        path: '/exams-records/access/staff/:id',
        element: <StaffDetail />,
    },

    {
        path: '/exams-records/sessions/add-student',
        element: <CreateStudent />,
    },
    {
        path: '/exams-records/access/create-role',
        element: <CreateRole unitName={EXAMS_AND_RECORDS} />,
    },
    {
        path: '/exams-records/access/edit-role',
        element: <EditRole unitName={EXAMS_AND_RECORDS} />,
    },
    {
        path: '/exams-records/access/add-administrator',
        // element: <AddAdministrator unitName={EXAMS_AND_RECORDS} />,
        element: <AssignRole unitName={EXAMS_AND_RECORDS} />,
    },
    {
        path: '/exams-records/access/edit-administrator',
        element: <EditAdministrator unitName={EXAMS_AND_RECORDS} />,
    },
    {
        path: '/exams-records/communications/received',
        element: <Received unitName={EXAMS_AND_RECORDS} />,
    },
    {
        path: '/exams-records/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/exams-records/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/exams-records/communications/distribution-list',
        element: <DistributionList unitName={EXAMS_AND_RECORDS} title="Communications" />,
    },
    {
        path: '/exams-records/communications/distribution-list/add',
        element: <AddDistributionList unitName={EXAMS_AND_RECORDS} title="Communications" />,
    },
    {
        path: '/exams-records/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={EXAMS_AND_RECORDS} title="Communications" />,
    },
    {
        path: '/exams-records/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/exams-records/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/exams-records/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/exams-records/results-backlog',
        element: <ResultsBacklog />,
    },
    {
        path: '/exams-records/manual-promotion',
        element: <ManualPromotion />,
    },
    {
        path: '/exams-records/results-backlog/upload',
        element: <UploadResultsBacklog />,
    },
];
