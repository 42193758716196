import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import Checkbox from '../../../components/inputs/checkbox';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { getAllAvailableStructure, setAcademicStructure } from '../../../redux/academic-structure/actions';

// import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[102.2rem]
`;

const Title = tw.h2`
    text-[1.6rem]
    font-semibold
    leading-6
    text-[#1F2937]
    mb-[0]
`;

const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const AcademicStructure = ({ unitName }) => {
    const dispatch = useDispatch();

    const { isLoading, availableAcademicStructure } = useSelector((state) => state.academicStructure);
    const [altLoading, setAltLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(
        availableAcademicStructure?.filter((item) => item?.isSet) || [],
    );

    const handleSelectOption = (item) => {
        const selected = selectedOptions?.some((ite) => ite?._id === item?._id);

        if (selected) {
            const newItems = selectedOptions.filter((ite) => ite?._id !== item?._id);
            setSelectedOptions(newItems);
        } else {
            setSelectedOptions((prev) => [...prev, item]);
        }
    };

    useEffect(() => {
        dispatch(getAllAvailableStructure());
    }, [dispatch]);
    if (isLoading) return <Loader />;

    return (
        <>
            <PageTitle align="left" bottom="4rem">
                Choose Academic Structure
            </PageTitle>

            <Formik
                initialValues={{
                    structure: '',
                }}
                onSubmit={async (values, actions) => {
                    setAltLoading(true);
                    const structure = selectedOptions.map((item) => item.structure);

                    const res = await dispatch(setAcademicStructure({ structure }));
                    if (res) {
                        toast.success('Academic Structure saved successfully!');
                    }
                    setAltLoading(false);
                }}
            >
                {({ errors }) => (
                    <Form>
                        <MainContainer>
                            <Title>Select Academic Structure</Title>

                            <Text align="left" bottom="2.4rem">
                                Select the structures you want the your organization to have
                            </Text>
                            <div className="grid grid-cols-3 justify-center gap-x-[1.6rem] gap-y-[1.6rem]">
                                {availableAcademicStructure.map((item) => (
                                    <div
                                        key={item?._id}
                                        className="flex items-center justify-start gap-x-[1.4rem] gap-y-[0.8rem]
"
                                    >
                                        <label
                                            className={`
                                                ${item?.isSet && 'opacity-40'}
                                                    leading-[2.4rem] text-[1.4rem] font-normal`}
                                            htmlFor={item}
                                        >
                                            <Checkbox
                                                value={item}
                                                id={item?._id}
                                                label={capitalizeFirstLetter(item.structure)}
                                                onChange={() => handleSelectOption(item)}
                                                checked={selectedOptions?.some((ite) => ite?._id === item?._id)}
                                                disabled={item?.isSet}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <FlexRowEnd className="gap-[2.4rem] mb-[3.6rem] mt-[4rem]">
                                <CancelButton type="button" onClick={() => setSelectedOptions([])}>
                                    Deselect All
                                </CancelButton>
                                <LoadingButton
                                    type="submit"
                                    disabled={isLoading || altLoading || !selectedOptions.length > 0}
                                    loading={altLoading}
                                >
                                    Confirm Academic Structure
                                </LoadingButton>
                            </FlexRowEnd>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AcademicStructure;
