import React from 'react';

import tw from 'twin.macro';

import DetailsBox from '../details-box';

const MainContainer = tw.div`
    grid
    grid-cols-2
    grid-flow-row
    row-auto
    gap-6
    mt-8
`;

const Library = ({ details }) => {
    const { libraryInfo } = details?.libraryData || {};
    const libraryInformation = [{ title: 'Purpose of using the library', subtitle: libraryInfo?.usage }];
    return (
        <MainContainer>
            <DetailsBox heading="Library Information" leftData={libraryInformation} />
        </MainContainer>
    );
};

export default Library;
