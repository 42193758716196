import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';

import { Checkbox } from '../../../components';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { All, Bulletin, InternalMemo, Mail, Notice } from '../components';

const Received = ({ unitName }) => {
    const url = window.location.href;
    const [activeTab, setActiveTab] = useState('all');

    const TableHeaders = [
        {
            header: <Checkbox />,
            size: 5,
            accessorKey: 'action',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'To',
            accessorKey: 'to',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Subject',
            accessorKey: 'subject',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Ref No.',
            accessorKey: 'ref',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Session',
            accessorKey: 'session',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Time Stamp',
            accessorKey: 'time',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        ...(activeTab === 'all'
            ? [
                  {
                      header: 'Type',
                      accessorKey: 'type',
                      cell: (props) => <p>{props?.getValue()}</p>,
                  },
              ]
            : []),
    ];

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Received</PageTitle>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem onClick={() => setActiveTab('all')}>
                        <CNavLink>
                            All{' '}
                            <span className="bg-[#FEE2E2] px-[6px] py-[2px] rounded-[1000px] text-[#EF4444] font-semibold text-[1.2rem]">
                                0
                            </span>
                        </CNavLink>
                    </CNavItem>
                    <CNavItem onClick={() => setActiveTab('bulletin')}>
                        <CNavLink>
                            Bulletin{' '}
                            <span className="bg-[#E5E7EB] px-[6px] py-[2px] rounded-[1000px] text-[#1F2937] font-semibold text-[1.3rem]">
                                0
                            </span>
                        </CNavLink>
                    </CNavItem>

                    <CNavItem onClick={() => setActiveTab('memo')}>
                        <CNavLink>
                            Internal Memo{' '}
                            <span className="bg-[#E5E7EB] px-[6px] py-[2px] rounded-[1000px] text-[#1F2937] font-semibold text-[1.3rem]">
                                0
                            </span>
                        </CNavLink>
                    </CNavItem>

                    <CNavItem onClick={() => setActiveTab('notice')}>
                        <CNavLink>
                            Notice{' '}
                            <span className="bg-[#E5E7EB] px-[6px] py-[2px] rounded-[1000px] text-[#1F2937] font-semibold text-[1.3rem]">
                                0
                            </span>
                        </CNavLink>
                    </CNavItem>

                    <CNavItem onClick={() => setActiveTab('mail')}>
                        <CNavLink>
                            Mail{' '}
                            <span className="bg-[#E5E7EB] px-[6px] py-[2px] rounded-[1000px] text-[#1F2937] font-semibold text-[1.3rem]">
                                0
                            </span>
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <All tableHeader={TableHeaders} />
                    </CTabPane>
                    <CTabPane>
                        <Bulletin tableHeader={TableHeaders} />
                    </CTabPane>

                    {url.includes('staff-arena') && (
                        <CTabPane>
                            <InternalMemo tableHeader={TableHeaders} />
                        </CTabPane>
                    )}
                    <CTabPane>
                        <Notice tableHeader={TableHeaders} />
                    </CTabPane>
                    {!url.includes('staff-arena') && (
                        <CTabPane>
                            <Mail tableHeader={TableHeaders} />
                        </CTabPane>
                    )}
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Received;
