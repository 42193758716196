import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { Button } from '../../../../components/buttons';
import Editor from '../../../../components/editor';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const Container = tw.div`
    bg-white
    p-[2.4rem]
`;

const Delete = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState('');

    const handleTextChange = (content, delta, source, editor) => {
        setValue(editor.getHTML());
    };

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Settings
                </PageTitle>
                <Text left="1rem" size="1.6rem">
                    / Special Letters
                </Text>
            </FlexCentredRow>

            <FlexCentredRow className="mt-[4rem] mb-[5.8rem] gap-x-[1rem]">
                <PageTitle weight="600" size="3.3rem">
                    Special Letters
                </PageTitle>
            </FlexCentredRow>

            <div className="bg-white p-[1.6rem]">
                <Text align="left" weight="500" color="#4B5563">
                    Acceptance Letter
                </Text>
            </div>

            <Editor value={value} onChange={handleTextChange} />
            <Container>
            <FlexRowSpaceBetween>
            <Button onClick={() => navigate('/configuration/special-letters/view')} color="#6366F1">
                    Previous
                </Button>
            <FlexRowEnd className="bg-white p-[1.6rem] gap-[2rem]">
                <CancelButton type="button">Cancel</CancelButton>
                <Button onClick={() => navigate('/configuration/special-letters/view')} bgColor="#6366F1" color="#FFF">
                    Upload
                </Button>
            </FlexRowEnd>
            </FlexRowSpaceBetween>
            </Container>
        </>
    );
};

export default Delete;
