import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '../../../../../components/inputs/checkbox';
import ListEmptyContent from '../../../../../components/list-empty';
import { CenteredContainer } from '../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { getProgrammeStudents } from '../../../../../redux/programmes/actions';

const fields = [
    {
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Full Name',
        accessorKey: 'name',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Level',
        accessorKey: 'Level',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Admission Year',
        accessorKey: 'admissionSession',
        cell: (props) => <p>{props.getValue()}</p>,
    },
];

const ProgrammeStudents = () => {
    const dispatch = useDispatch();
    const { isLoading, students } = useSelector((state) => state.programmes);

    const { currentProgramme } = useSelector((state) => state.programmes);
    const { _id: programId, academicStructureId } = currentProgramme || {};
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(getProgrammeStudents(academicStructureId, programId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, academicStructureId, programId]);

    const modifiedStudents = students?.map((item) => {
        return {
            action: (
                <td>
                    <Checkbox />
                </td>
            ),
            name: <td>{`${item?.lastName} ${item?.firstName} ${item?.middleName || ''}`}</td>,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                pageTitle={currentProgramme?.subprogram}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                data={modifiedStudents || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isActionable
            />
        </>
    );
};

export default ProgrammeStudents;
