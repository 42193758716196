import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit3.svg';
import { editOrganisationUnit } from '../../../redux/organisation-unit/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditOrganizationUnitForm = ({ details, show, setShow, onDone }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow(false);

    const { isLoading } = useSelector((state) => state.organisationUnits);

    return (
        <>
            <FormContainer headerIcon={<EditIcon />} close={onClose} show={show} title={'Edit Organization Unit'}>
                <Formik
                    initialValues={{
                        unitName: details?.unitName || '',
                        description: details?.description || '',
                    }}
                    enableReinitialize
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(editOrganisationUnit(details._id, values));
                        if (res) {
                            actions.resetForm();
                            onClose();
                            onDone?.();
                        }
                    }}
                >
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="name"
                                    label="Previous Unit Name"
                                    type="text"
                                    placeholder="Name"
                                    value={details?.unitName || ''}
                                    errors={errors}
                                    disabled
                                />
                                <TextInput
                                    name="unitName"
                                    type="text"
                                    label="New Unit Name"
                                    placeholder="Name"
                                    value={values?.unitName || ''}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <TextInput
                                    name="description"
                                    type="text"
                                    label="Description"
                                    placeholder="Short Description"
                                    value={values.description}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Save Changes
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditOrganizationUnitForm;
