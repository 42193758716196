import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/people.svg';
import { LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../../components/go-back';
import Checkbox from '../../../../../components/inputs/checkbox';
import TextInput from '../../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../../components/loader';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../../../containers/ScreenContainers';
import { getPermissionsInUnit } from '../../../../../redux/permissions/actions';
import { createDepartmentRole } from '../../../../../redux/roles/actions';
import { capitalizeEachWord } from '../../../../../utils';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[102.2rem]
`;

const Label = tw.label`
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const Title = tw.h2`
    text-[1.6rem]
    font-semibold
    leading-6
    text-[#1F2937]
    mb-[0]
`;

const CreateDepartmentRole = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.permissions);
    const { isLoading: altLoading } = useSelector((state) => state.roles);

    const { currentDepartment } = useSelector((state) => state.department);
    const { _id: departmentId, academicStructure = '' } = currentDepartment;
    const splitStruct = academicStructure?.split('-');

    const [unitPermissions, setUnitPermissions] = useState([]);
    const [departmentPermissions, setDepartmentPermissions] = useState([]);
    const [programPermissions, setProgrammePermissions] = useState([]);

    const handleChangeDepartmentRole = (item) => {
        const selected = departmentPermissions?.some((ite) => ite === item);

        if (selected) {
            const newItems = departmentPermissions.filter((ite) => ite !== item);
            setDepartmentPermissions(newItems);
        } else {
            setDepartmentPermissions((prev) => [...prev, item]);
        }
    };

    const handleChangeProgramRole = (item) => {
        const selected = programPermissions?.some((ite) => ite === item);

        if (selected) {
            const newItems = programPermissions.filter((ite) => ite !== item);
            setProgrammePermissions(newItems);
        } else {
            setProgrammePermissions((prev) => [...prev, item]);
        }
    };

    const handleDeselectAll = () => {
        setDepartmentPermissions([]);
        setProgrammePermissions([]);
    };

    useEffect(() => {
        const getData = async () => {
            const data = await dispatch(getPermissionsInUnit());
            setUnitPermissions(data);
        };
        getData();
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <>
            <div className="mb-12">
                <GoBack title="Roles" subTitle="Create Role" />
            </div>

            <Formik
                initialValues={{
                    role: '',
                    description: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const permissions = departmentPermissions?.map((item) => item);

                    const res = await dispatch(
                        createDepartmentRole(departmentId, { permissions, programPermissions, ...values }),
                    );
                    if (res) {
                        toast.success('Role created successfully!');
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <MainContainer>
                            <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                                <PeopleIcon />
                                <Title>Create Role</Title>
                            </FlexCentredRow>
                            <div className="flex flex-col gap-10">
                                <TextInput
                                    label="Role Name"
                                    placeholder="Input Name"
                                    name="role"
                                    type="text"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="Role Description"
                                    name="description"
                                    placeholder="Input Description"
                                    type="text"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                                <div>
                                    <Label align="left">Permissions ({capitalizeEachWord(splitStruct?.[1])})</Label>
                                    <Text align="left" bottom="2.4rem" color="#6B7280">
                                        Select the type or types of permissions you want the role you are creating to
                                        have
                                    </Text>
                                    <div className="grid grid-cols-3 justify-center gap-x-[1.6rem] gap-y-[1.6rem] w-3/4">
                                        {unitPermissions?.map((item) => (
                                            <div
                                                key={item}
                                                className="flex items-center justify-start gap-x-[1.4rem] gap-y-[0.8rem]
"
                                            >
                                                <label
                                                    className="leading-[2.4rem] text-[1.4rem] font-normal"
                                                    htmlFor={item}
                                                >
                                                    <Checkbox
                                                        value={item.value}
                                                        id={item}
                                                        label={capitalizeEachWord(item)}
                                                        onChange={() => handleChangeDepartmentRole(item)}
                                                        checked={departmentPermissions?.some((ite) => ite === item)}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {splitStruct?.[2] && (
                                    <div>
                                        <Label align="left">Permissions ({capitalizeEachWord(splitStruct?.[2])})</Label>
                                        <Text align="left" bottom="2.4rem" color="#6B7280">
                                            Select the type or types of permissions you want the role you are creating
                                            to have
                                        </Text>
                                        <div className="grid grid-cols-3 justify-center gap-x-[1.6rem] gap-y-[1.6rem] w-3/4">
                                            {unitPermissions?.map((item) => (
                                                <div
                                                    key={item}
                                                    className="flex items-center justify-start gap-x-[1.4rem] gap-y-[0.8rem]
"
                                                >
                                                    <label
                                                        className="leading-[2.4rem] text-[1.4rem] font-normal"
                                                        htmlFor={item}
                                                    >
                                                        <Checkbox
                                                            value={item}
                                                            id={item}
                                                            label={capitalizeEachWord(item)}
                                                            onChange={() => handleChangeProgramRole(item)}
                                                            checked={programPermissions?.some((ite) => ite === item)}
                                                        />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <FlexRowEnd className="gap-[2.4rem] mb-[3.6rem] mt-[1.9rem]">
                                <CancelButton
                                    disabled={altLoading || !departmentPermissions.length || !programPermissions.length}
                                    type="button"
                                    onClick={handleDeselectAll}
                                >
                                    Deselect All
                                </CancelButton>
                                <LoadingButton loading={altLoading} type="submit" disabled={altLoading}>
                                    Create Role
                                </LoadingButton>
                            </FlexRowEnd>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateDepartmentRole;
