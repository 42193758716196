import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as ThreeDots } from '../../../assets/icons/three-dots-horizontal.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import RoomEmpty from '../room-empty';

const MainContainer = tw.div`
    h-auto
    bg-white
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
    mb-[0.2rem]
`;
const BlockDetails = tw.div`
    h-auto
    bg-[#F3F4F6]
    lg:p-[2.4rem]
    rounded-lg
    mb-[7rem]
`;
const BlockGrid = tw.div`
    grid
    grid-cols-3
    gap-[1.6rem]
`;
const Room = tw.div`
w-[24.1rem]
h-[24.1rem]
rounded
pt-[1.4rem]
px-[2.4rem]
`;
const Span = tw.span`
    ml-[0.2rem]
`;
const FlexEnd = tw.div`
    flex
    justify-end
    mt-[16.45rem]
    mb-[2.6rem]
`;
const SmallButton = tw.button`
flex
items-center
justify-center
flex-row
font-medium
px-[.4rem]
py-[.2rem]
bg-[#EFF6FF]
rounded

`;

const RoomDetails = () => {
    const navigate = useNavigate();
    const [openActions, setOpenActions] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(true);
    // const [deleteBedspace, setDeleteBedspace] = useState(false);
    useEffect(() => {
        const id = setTimeout(() => {
            setIsListEmpty(false);
        }, 1500);

        return () => clearTimeout(id);
    }, []);

    return (
        <>
            <FlexRowSpaceBetween className="mb-[3.6rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Hostel Management
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                        /
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" color="#6366F1" lineHeight="2.8rem">
                        Adeniyi Hostel
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                        /
                    </PageTitle>
                    <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                        Room 100
                    </PageTitle>
                </FlexCentredRow>
                <FlexCentredRow className="gap-[1.6rem]">
                    <Button
                        onClick={() => navigate('/hostel/hostel-list/room-details/view-tenant')}
                        bgColor="#F3F4F6"
                        color="#D1D5DB"
                    >
                        View Tenants
                    </Button>
                    <Button
                        onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                        bgColor="#F3F4F6"
                        color="#D1D5DB"
                    >
                        Edit
                    </Button>
                </FlexCentredRow>
            </FlexRowSpaceBetween>

            <MainContainer>
                <PageTitle align="left" bottom="3.2rem">
                    Adeniyi Hostel
                </PageTitle>
                <FlexColumn>
                    <Text weight="500" align="left" bottom="0.8rem">
                        Number of Rooms:
                        <Span>100</Span>
                    </Text>
                    <Text weight="500" align="left" bottom="0.8rem">
                        Average number of spaces:
                        <Span>5</Span>
                    </Text>
                    <Text weight="500" align="left" bottom="3.2rem">
                        Gender:
                        <Span>Male</Span>
                    </Text>
                </FlexColumn>
                {isListEmpty ? (
                    <RoomEmpty />
                ) : (
                    <BlockDetails>
                        <FlexCentredRow>
                            <BlockGrid>
                                <Room
                                    className="bg-[#1F2937]"
                                    onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                >
                                    <FlexRowSpaceBetween>
                                        <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                            A
                                        </Text>
                                        <SmallButton>
                                            <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                                Occupied
                                            </Text>
                                        </SmallButton>
                                    </FlexRowSpaceBetween>
                                    <FlexEnd>
                                        <div className="relative">
                                            <ThreeDots
                                                className=" relative"
                                                onClick={() => {
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    {
                                                        name: 'Add New Bedspace',
                                                        click: () => {
                                                            // setShowUploadFile(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Edit Bedspace Name',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Unreserve Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Remove Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                            // setDeleteBedspace(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </FlexEnd>
                                </Room>
                                <Room
                                    className="bg-[#1F2937]"
                                    onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                >
                                    <FlexRowSpaceBetween>
                                        <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                            B
                                        </Text>
                                        <SmallButton>
                                            <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                                Occupied
                                            </Text>
                                        </SmallButton>
                                    </FlexRowSpaceBetween>
                                    <FlexEnd>
                                        <div className="relative">
                                            <ThreeDots
                                                className=" relative"
                                                onClick={() => {
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    {
                                                        name: 'Add New Bedspace',
                                                        click: () => {
                                                            // setShowUploadFile(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Edit Bedspace Name',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Unreserve Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Remove Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                            // setDeleteBedspace(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </FlexEnd>
                                </Room>
                                <Room
                                    className="bg-[#E5E7EB]"
                                    onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                >
                                    <FlexRowSpaceBetween>
                                        <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                            C
                                        </Text>
                                        <SmallButton>
                                            <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                                Reserved
                                            </Text>
                                        </SmallButton>
                                    </FlexRowSpaceBetween>
                                    <FlexEnd>
                                        <div className="relative">
                                            <ThreeDots
                                                className=" relative"
                                                onClick={() => {
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    {
                                                        name: 'Add New Bedspace',
                                                        click: () => {
                                                            // setShowUploadFile(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Edit Bedspace Name',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Unreserve Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Remove Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                            // setDeleteBedspace(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </FlexEnd>
                                </Room>
                                <Room
                                    onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                    className="bg-[#E5E7EB]"
                                >
                                    <FlexRowSpaceBetween>
                                        <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                            D
                                        </Text>
                                        <SmallButton>
                                            <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                                Reserved
                                            </Text>
                                        </SmallButton>
                                    </FlexRowSpaceBetween>
                                    <FlexEnd>
                                        <div className="relative">
                                            <ThreeDots
                                                className=" relative"
                                                onClick={() => {
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    {
                                                        name: 'Add New Bedspace',
                                                        click: () => {
                                                            // setShowUploadFile(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Edit Bedspace Name',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Unreserve Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Remove Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                            // setDeleteBedspace(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </FlexEnd>
                                </Room>
                                <Room
                                    className="bg-[#1F2937]"
                                    onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                >
                                    <FlexRowSpaceBetween>
                                        <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                            E
                                        </Text>
                                        <SmallButton>
                                            <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                                Occupied
                                            </Text>
                                        </SmallButton>
                                    </FlexRowSpaceBetween>
                                    <FlexEnd>
                                        <div className="relative">
                                            <ThreeDots
                                                className=" relative"
                                                onClick={() => {
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    {
                                                        name: 'Add New Bedspace',
                                                        click: () => {
                                                            // setShowUploadFile(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Edit Bedspace Name',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Unreserve Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Remove Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                            // setDeleteBedspace(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </FlexEnd>
                                </Room>
                                <Room
                                    className="bg-[#2563EB]"
                                    onClick={() => navigate('/hostel/hostel-list/room-details/:id')}
                                >
                                    <FlexRowSpaceBetween>
                                        <Text color="#FFF" size="3.3rem" lineHeight="4rem">
                                            F
                                        </Text>
                                        <SmallButton>
                                            <Text color="#2563EB" size="1.2" lineHeight="1.7rem">
                                                Empty
                                            </Text>
                                        </SmallButton>
                                    </FlexRowSpaceBetween>
                                    <FlexEnd>
                                        <div className="relative">
                                            <ThreeDots
                                                className=" relative"
                                                onClick={() => {
                                                    setOpenActions(true);
                                                }}
                                            />
                                            <ActionsPopup
                                                open={openActions}
                                                close={() => setOpenActions(false)}
                                                items={[
                                                    {
                                                        name: 'Add New Bedspace',
                                                        click: () => {
                                                            // setShowUploadFile(true);
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Edit Bedspace Name',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Unreserve Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                        },
                                                    },
                                                    {
                                                        name: 'Remove Bedspace',
                                                        click: () => {
                                                            setOpenActions(false);
                                                            // setDeleteBedspace(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </FlexEnd>
                                </Room>
                            </BlockGrid>
                        </FlexCentredRow>
                    </BlockDetails>
                )}
            </MainContainer>
        </>
    );
};

export default RoomDetails;
