export const staffArenaNavLinks = [
    {
        title: 'Office',
        to: '/staff-arena/office',
        subMenu: [
            {
                title: 'Inbox',
                to: '/staff-arena/office/inbox',
            },
            {
                title: 'Outbox',
                to: '/staff-arena/office/outbox',
            },
            {
                title: 'Draft',
                to: '/staff-arena/office/draft',
            },
            {
                title: 'Files',
                to: '/staff-arena/office/files',
            },
            {
                title: 'Course Form Approval',
                to: '/staff-arena/office/courseform-approval',
            },
        ],
    },
    {
        title: 'Communications',
        to: '/staff-arena/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/staff-arena/communications/compose',
            },
            {
                title: 'Received',
                to: '/staff-arena/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/staff-arena/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/staff-arena/communications/settings',
            },
        ],
    },
    {
        title: 'Documents',
        to: '/staff-arena/documents',
    },
    {
        title: 'Directory',
        to: '/staff-arena/directory',
    },
    {
        title: 'Handbook',
        to: '/staff-arena/handbook',
    },
    {
        title: 'Profile',
        to: '/staff-arena/profile',
    },
    {
        title: 'Preference',
        to: '/staff-arena/preference',
    },
];

export const staffArenaRoutes = staffArenaNavLinks.map((item) => item.to);
