import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getFacultyStaff } from '../../../redux/faculty/actions';

const FacultyPersonnels = () => {
    const dispatch = useDispatch();

    const { isLoading, currentFaculty, staff } = useSelector((state) => state.faculty);

    const { splitAcademicStructureTwo: structTwo } = currentFaculty;

    const [searchValue, setSearchValue] = useState('');

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'name',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: structTwo,
            accessorKey: 'department',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Employment Type',
            accessorKey: 'staffType',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Email Address',
            accessorKey: 'email',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Employment Date',
            accessorKey: 'employmentDate',
            cell: (props) => <p>{props.getValue()}</p>,
        },
    ];

    useEffect(() => {
        const { _id: facultyId, academicStructureId } = currentFaculty;

        if (facultyId) {
            dispatch(getFacultyStaff(academicStructureId, facultyId));
        }
    }, [currentFaculty, dispatch]);

    const modifiedStaffData = staff?.map((item) => {
        return {
            name: <td>{item.fullName}</td>,
            employmentDate: <td>{format(new Date(item.yearOfEmployment), 'PP')}</td>,
            department: item.program || item?.faculty || item?.department,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                fields={fields}
                data={modifiedStaffData}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Staff "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
            />
        </>
    );
};

export default FacultyPersonnels;
