import React from 'react';

import { useNavigate } from 'react-router-dom';

// import tw, { styled } from 'twin.macro';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { Button } from '../../../components/buttons';
import ListEmptyContent from '../../../components/list-empty';
import { PageTitle } from '../../../containers/MesssageContainers';
import { CenteredContainer, FlexCentredRow } from '../../../containers/ScreenContainers';

const HostelManagement = () => {
    const navigate = useNavigate();

    return (
        <div>
            <FlexCentredRow className="gap-[0.8rem]">
                <PageTitle>Hostel Management</PageTitle>
            </FlexCentredRow>

            <CenteredContainer className="mt-[5rem]">
                <ListEmptyContent title="No Hostel " subtitle="Add new to manage your hostels">
                    <Button onClick={() => navigate('/hostel/new-hostel')} bgColor="#6366F1" color="#fff">
                        <Circularplus className="mr-[1.15rem]" />
                        Add New Hostel
                    </Button>
                </ListEmptyContent>
            </CenteredContainer>
        </div>
    );
};

export default HostelManagement;
