import React from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/CheckCircle.svg';
import { ReactComponent as OrangeDot } from '../../../assets/icons/orange-dot.svg';
import { Text } from '../../../containers/MesssageContainers';

const Reviewers = ({ reviewerData, isApproved }) => {
    return (
        <div className="bg-white p-[2.4rem] rounded-[0.8rem] flex flex-col gap-[2.8rem]">
            <Text weight="600" size="1.6rem" align="left" color="#000">
                Reviewer(s)
            </Text>
            <div className="flex flex-col gap-[24px]">
                {reviewerData?.map(({ _id, title, surname, firstName, otherName }) => (
                    <div key={_id} className="flex items-center justify-between">
                        <Text>
                            {title}. {surname} {firstName} {otherName}
                        </Text>
                        {isApproved ? <CheckIcon width={14} height={14} color="#10B981" /> : <OrangeDot />}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Reviewers;
