import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import Select from '../../../components/inputs/new-select';
import ConfirmAddAdministrator from '../../../components/popups/confirm-add-administrator';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editAssignedStaffRole, getRolesInUnit } from '../../../redux/roles/actions';
// import { getStaffInUnit } from '../../../redux/staff/actions';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    w-full
`;

const Label = tw.label`
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const EditAdministrator = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { selectedAdmin } = state || {};
    const { isLoading } = useSelector((store) => store.roles);

    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(selectedAdmin?.staffRole || []);
    const [showConfirmAllocation, setShowConfirmAllocation] = useState(false);
    const fullName = `${selectedAdmin?.firstName} ${selectedAdmin?.otherName || ''} ${selectedAdmin?.surname}`;

    const handleChangeRole = (item) => {
        const selected = selectedRole?.some((ite) => (ite?.roleId || ite._id) === item._id);
        if (selected) {
            const newItems = selectedRole.filter((ite) => (ite?.roleId || ite._id) !== item._id);
            setSelectedRole(newItems);

            setSelectedRole([]);
        } else {
            setSelectedRole((prev) => [...prev, item]);
        }
    };

    const handleSubmit = async () => {
        const roles = selectedRole.map((item) => item.role);
        const payload = {
            roles,
        };
        const res = await dispatch(editAssignedStaffRole(selectedAdmin?._id, payload));
        if (res) {
            navigate(-1);
        }
    };

    useEffect(() => {
        if (!unitName) return;

        const getRoles = async () => {
            const data = await dispatch(getRolesInUnit(unitName));
            if (data) {
                return setRoles(data);
            }
            setRoles([]);
        };
        getRoles();
    }, [dispatch, unitName]);

    return (
        <>
            <div className="mb-12">
                <GoBack title={`Staff`} subTitle="Edit Administrator" />
            </div>

            <Formik
                initialValues={{
                    staffId: selectedAdmin?._id,
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    setShowConfirmAllocation(true);
                }}
            >
                {({ errors, handleChange, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <div className="pl-6">
                                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem] ">
                                    <PeopleIcon />
                                    <Title>Edit Administrator</Title>
                                </FlexCentredRow>
                                <FlexCentredCol className="mb-[2.4rem]">
                                    <Select
                                        objProp="fullName"
                                        placeholder="Select Staff"
                                        label="Staff"
                                        passedSelectedItems={[{ fullName }]}
                                        disabled
                                        onChange={() => {}}
                                        error={errors?.staffId}
                                    />
                                </FlexCentredCol>

                                <Label align="left">Select Role</Label>
                            </div>
                            <div className="grid grid-cols-1 justify-center mt-4 gap-y-[1.6rem] w-3/4">
                                {roles
                                    //custom roles can't be assigned with the endpoint here
                                    .filter((item) => !item.isCustom)
                                    .map((item) => (
                                        <div key={item?._id}>
                                            <Checkbox
                                                value={item.role}
                                                id={item._id}
                                                label={item.role}
                                                labelClassName="text-xl mb-0"
                                                onClick={() => handleChangeRole(item)}
                                                checked={selectedRole?.some(
                                                    (ite) => (ite?.roleId || ite._id) === item._id,
                                                )}
                                            />
                                        </div>
                                    ))}
                            </div>

                            <FlexRowEnd className="gap-[2.4rem] mt-[3.6rem]">
                                <Button
                                    bgColor="#fff"
                                    color="#D1D5DB"
                                    border="1px solid #D1D5DB"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton type="submit">Edit Administrator</LoadingButton>
                            </FlexRowEnd>
                        </MainContainer>
                    </Form>
                )}
            </Formik>

            <ConfirmAddAdministrator
                title="Edit Administrator"
                subtitle="Are you sure you want to assign the administrator:"
                show={showConfirmAllocation}
                close={() => setShowConfirmAllocation(false)}
                list={[{ fullName }]}
                role={selectedRole}
                confirmAction={() => handleSubmit()}
                altLoading={isLoading}
                btnText="Edit Administrator"
            />
        </>
    );
};

export default EditAdministrator;
