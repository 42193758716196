import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    mondayTimetable: [],
    tuesdayTimetable: [],
    wednesdayTimetable: [],
    thursdayTimetable: [],
    fridayTimetable: [],
    saturdayTimetable: [],
    sundayTimetable: [],
    allExamTimetable: [],
    allLectureTimetable: [],
    mondayExamTimetable: [],
    tuesdayExamTimetable: [],
    wednesdayExamTimetable: [],
    thursdayExamTimetable: [],
    fridayExamTimetable: [],
    saturdayExamTimetable: [],
    sundayExamTimetable: [],
    examTimetable: {},
    firstDegreeTimetable: [],
};

export const timetableSlice = createSlice({
    name: 'timetable',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_LECTURE_TIMETABLE: (state, action) => {
            state.allLectureTimetable = action.payload;
        },
        GET_MONDAY_TIMETABLE: (state, action) => {
            state.mondayTimetable = action.payload;
        },
        GET_TUESDAY_TIMETABLE: (state, action) => {
            state.tuesdayTimetable = action.payload;
        },
        GET_WEDNESDAY_TIMETABLE: (state, action) => {
            state.wednesdayTimetable = action.payload;
        },
        GET_THURSDAY_TIMETABLE: (state, action) => {
            state.thursdayTimetable = action.payload;
        },
        GET_FRIDAY_TIMETABLE: (state, action) => {
            state.fridayTimetable = action.payload;
        },
        GET_SATURDAY_TIMETABLE: (state, action) => {
            state.saturdayTimetable = action.payload;
        },
        GET_SUNDAY_TIMETABLE: (state, action) => {
            state.sundayTimetable = action.payload;
        },
        GET_ALL_EXAM_TIMETABLE: (state, action) => {
            state.allExamTimetable = action.payload;
        },
        GET_MONDAY_EXAM_TIMETABLE: (state, action) => {
            state.mondayExamTimetable = action.payload;
        },
        GET_TUESDAY_EXAM_TIMETABLE: (state, action) => {
            state.tuesdayExamTimetable = action.payload;
        },
        GET_WEDNESDAY_EXAM_TIMETABLE: (state, action) => {
            state.wednesdayExamTimetable = action.payload;
        },
        GET_THURSDAY_EXAM_TIMETABLE: (state, action) => {
            state.thursdayExamTimetable = action.payload;
        },
        GET_FRIDAY_EXAM_TIMETABLE: (state, action) => {
            state.fridayExamTimetable = action.payload;
        },
        GET_SATURDAY_EXAM_TIMETABLE: (state, action) => {
            state.saturdayExamTimetable = action.payload;
        },
        GET_SUNDAY_EXAM_TIMETABLE: (state, action) => {
            state.sundayExamTimetable = action.payload;
        },
        GET_EXAM_TIMETABLE: (state, action) => {
            state.examTimetable = action.payload;
        },
        GET_FIRST_DEGREE_TIMETABLE: (state, action) => {
            state.firstDegreeTimetable = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    // CREATE_TIMETABLE,
    GET_ALL_LECTURE_TIMETABLE,
    GET_MONDAY_TIMETABLE,
    GET_TUESDAY_TIMETABLE,
    GET_WEDNESDAY_TIMETABLE,
    GET_THURSDAY_TIMETABLE,
    GET_FRIDAY_TIMETABLE,
    GET_ALL_EXAM_TIMETABLE,
    GET_MONDAY_EXAM_TIMETABLE,
    GET_TUESDAY_EXAM_TIMETABLE,
    GET_WEDNESDAY_EXAM_TIMETABLE,
    GET_THURSDAY_EXAM_TIMETABLE,
    GET_FRIDAY_EXAM_TIMETABLE,
    GET_EXAM_TIMETABLE,
    GET_FIRST_DEGREE_TIMETABLE,
    GET_SATURDAY_TIMETABLE,
    GET_SUNDAY_TIMETABLE,
    GET_SATURDAY_EXAM_TIMETABLE,
    GET_SUNDAY_EXAM_TIMETABLE,
} = timetableSlice.actions;

export default timetableSlice.reducer;
