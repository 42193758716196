import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editAlternateContact } from '../../../redux/ict/actions';
import { LoadingButton } from '../../buttons';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
p-[2.4rem]
bg-white
rounded-[0.8rem]
`;

const EditAlternateContact = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { alternateContact, isLoading } = useSelector((store) => store.ict);

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[0.8rem]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Contact Information
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Update Service Contact Information</Text>
            </FlexCentredRow>
            <PageTitle align="left" top="4.5rem" bottom="2.4rem">
                Contact Information
            </PageTitle>
            <Container>
                <PageTitle align="left" size="1.9rem" className="border-b pb-[2rem]">
                    Service Contact
                </PageTitle>
                <Formik
                    initialValues={
                        alternateContact || {
                            name: '',
                            position: '',
                            email: '',
                            phoneNumber: '',
                            securityName: '',
                            securityEmail: '',
                            securityPhoneNumber: '',
                            securityPosition: '',
                            billingName: '',
                            billingEmail: '',
                            billingPhoneNumber: '',
                            billingPosition: '',
                        }
                    }
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        const { email, securityEmail, billingEmail, ...otherValues } = values;

                        const payload = {
                            ...otherValues,
                            email: email.toLowerCase(),
                            securityEmail: securityEmail.toLowerCase(),
                            billingEmail: billingEmail.toLowerCase(),
                        };
                        const res = await dispatch(editAlternateContact(payload));
                        if (res) {
                            navigate(-1);
                        }
                    }}
                >
                    {({ handleChange, values, errors }) => (
                        <Form>
                            <div className="flex flex-col gap-[3.2rem] max-w-[100.05rem] border-b-[1px] border-b-[#E5E7EB] py-[3.2rem]">
                                <FormikTextInput
                                    label="Operations Contact Name"
                                    name="name"
                                    type="text"
                                    placeholder="Operations Contact Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.name}
                                />
                                <FormikTextInput
                                    label="Operations Contact Position"
                                    name="position"
                                    type="text"
                                    placeholder="Position"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.position}
                                />
                                <FormikTextInput
                                    label="Operations Contact Email"
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <FormikTextInput
                                    label="Operations Contact Phone"
                                    name="phoneNumber"
                                    type="number"
                                    placeholder="Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                />
                            </div>
                            <div className="flex flex-col gap-[3.2rem] max-w-[100.05rem] border-b-[1px] border-b-[#E5E7EB] py-[3.2rem]">
                                <FormikTextInput
                                    label="Security Contact Name"
                                    name="securityName"
                                    type="text"
                                    placeholder="Security Contact Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.securityName}
                                />
                                <FormikTextInput
                                    label="Security Contact Position"
                                    name="securityPosition"
                                    type="text"
                                    placeholder="Position"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.securityPosition}
                                />
                                <FormikTextInput
                                    label="Security Contact Email"
                                    name="securityEmail"
                                    type="text"
                                    placeholder="Email"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.securityEmail}
                                />
                                <FormikTextInput
                                    label="Security Contact Phone"
                                    name="securityPhoneNumber"
                                    type="number"
                                    placeholder="Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.securityPhoneNumber}
                                />
                            </div>
                            <div className="flex flex-col gap-[3.2rem] max-w-[100.05rem] border-b-[1px] border-b-[#E5E7EB] py-[3.2rem]">
                                <FormikTextInput
                                    label="Billing Contact Name"
                                    name="billingName"
                                    type="text"
                                    placeholder="Billing Contact Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.billingName}
                                />
                                <FormikTextInput
                                    label="Billing Contact Position"
                                    name="billingPosition"
                                    type="text"
                                    placeholder="Position"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.billingPosition}
                                />
                                <FormikTextInput
                                    label="Billing Contact Email"
                                    name="billingEmail"
                                    type="text"
                                    placeholder="Email"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.billingEmail}
                                />
                                <FormikTextInput
                                    label="Billing Contact Phone"
                                    name="billingPhoneNumber"
                                    type="number"
                                    placeholder="Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.billingPhoneNumber}
                                />
                            </div>
                            <FlexRowEnd className="gap-[2rem] mt-[3.2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    bgColor="#6366F1"
                                    color="#fff"
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </Container>
        </div>
    );
};

export default EditAlternateContact;
