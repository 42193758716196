import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import ScheduleCard from '../../../../components/popups/schedule-card';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { addSpecialLetter } from '../../../../redux/special-letter/actions';
import { SAVE_ADD } from '../../../../redux/special-letter/slice';

const StyledButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    px-[1em]
    py-[.4em]
    bg-[#6366F1]
    text-[#FFF]
    text-[1.4rem]
    leading-6
    focus:outline-none
    h-[4rem]
`;
const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    p-[2.4rem]
    // md:p-[2rem]
    rounded-lg
`;
const Header = tw.div`
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    py-[2.4rem]
    px-[3.2rem]
    bg-white
    mx-auto

`;
const SubHeader2 = tw.div`
    border-[#E5E7EB]
    border-b
    bg-white
    mx-auto
    px-[3.2rem]
    py-[1.4rem]
`;
const TextArea = tw.textarea`
    w-full
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    placeholder:font-semibold
    leading-6
    font-normal
    text-[1.4rem]
    mb-5
`;

const SpecialLetterCompose = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { addLetter, isLoading } = useSelector((store) => store.specialLetter);

    const [showScheduleCard, setShowScheduleCard] = useState(false);
    const [content, setContent] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = { ...addLetter, content: content };

        const res = await dispatch(addSpecialLetter(payload));
        if (res) {
            dispatch(SAVE_ADD({}));
            navigate('/registry/special-letters');
        }
    };

    return (
        <div className="">
            <FlexCentredRow className="mb-[3.2rem] gap-[8px]">
                <BackArrow onClick={() => navigate(-1)} className="hover:cursor-pointer mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Special Letters
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text>{addLetter.type}</Text>
            </FlexCentredRow>
            <form onSubmit={handleSubmit}>
                <Header>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow className="gap-[1.015rem]">
                            <PageTitle>{addLetter.type}</PageTitle>
                            <Dropdown />
                        </FlexCentredRow>
                        <FlexCentredRow className=" cursor-pointer gap-[6.315rem]">
                            <Close onClick={() => navigate(-1)} />
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>
                </Header>

                <SubHeader2 className="relative flex items-center">
                    <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                        TO:
                    </Text>
                    <div className="flex gap-[2rem] items-center">
                        <Text weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            {addLetter.target}
                        </Text>
                    </div>
                </SubHeader2>
                <MainContainer>
                    <TextArea
                        value={content}
                        rows="30"
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Enter Message here"
                    />
                    <FlexRowSpaceBetween>
                        <CancelButton onClick={() => navigate(-1)}>Back</CancelButton>
                        <FlexCentredRow className="justify-end">
                            <LoadingButton loading={isLoading} className="py-0 rounded-l-md">
                                Send
                            </LoadingButton>
                            <StyledButton
                                type="button"
                                className="py-0 border-l rounded-r-md border-l-[#A5B4FC]"
                                onClick={() => setShowScheduleCard((previousState) => !previousState)}
                            >
                                <Dropdown />
                            </StyledButton>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    {showScheduleCard && <ScheduleCard show={showScheduleCard} setShow={setShowScheduleCard} />}
                </MainContainer>
            </form>
        </div>
    );
};

export default SpecialLetterCompose;
