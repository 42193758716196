import React, { useState } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import tw from 'twin.macro';

import SearchInput from '../../../../components/inputs/search-input';
import ListEmptyContent from '../../../../components/list-empty';
import { Text } from '../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Course Title',
        accessorKey: 'courseTitle',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Unit',
        accessorKey: 'unit',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Percentage(%)',
        accessorKey: 'percentage',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Grade',
        accessorKey: 'grade',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const FacultyAdmin = () => {
    const courseDetailsData = [];
    const [isLoading /*setIsLoading*/] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    return (
        <>
            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                <FlexCentredRow className="ml-[1.6rem] mb-[2.3rem] gap-[4rem]">
                    <FlexColumn>
                        <Text as="h4" align="start" weight="500" color="#6B7280" size="1.2rem">
                            Semester
                        </Text>
                        <Text weight="600" align="start" size="2.8rem">
                            {/* 2nd */}
                        </Text>
                    </FlexColumn>
                    <FlexColumn>
                        <Text as="h4" weight="500" align="start" color="#6B7280" size="1.2rem">
                            Session
                        </Text>
                        <Text weight="600" align="start" size="2.8rem">
                            {/* 2014/2015 */}
                        </Text>
                    </FlexColumn>
                    <FlexColumn>
                        <Text align="start" as="h4" weight="500" color="#6B7280" size="1.2rem">
                            GPA
                        </Text>
                        <Text align="start" weight="600" size="2.8rem">
                            {/* 2.75 */}
                        </Text>
                    </FlexColumn>
                    <FlexColumn>
                        <Text as="h4" align="start" weight="500" color="#6B7280" size="1.2rem">
                            CGPA
                        </Text>
                        <Text weight="600" align="start" size="2.8rem">
                            {/* 3.78 */}
                        </Text>
                    </FlexColumn>
                </FlexCentredRow>
                <Table
                    data={courseDetailsData || []}
                    columns={fields}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Results yet "></ListEmptyContent>
                        </CenteredContainer>
                    }
                    loading={isLoading}
                    width={'100%'}
                />
            </div>
        </>
    );
};

export default FacultyAdmin;
