import React, { useEffect, useMemo, useState } from 'react';

import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../assets/icons/people2.svg';
import { GoBack } from '../../../../components/go-back';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getStudentPaymentHistory } from '../../../../redux/bursary/actions';
import { getStudentDetails } from '../../../../redux/students/actions';
import { capitalizeFirstLetter } from '../../../../utils';

const fields = [
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Fee',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Level',
        accessorKey: 'level',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Amount Paid',
        accessorKey: 'paid',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Amount Outstanding',
        accessorKey: 'outstanding',
    },
];

const MainContainer = tw.div`

    mt-8
    bg-white
    p-6
    rounded-lg
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-4
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-3
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const TitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const SubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const BursaryStudentById = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        state: { academicStruct, studentDet, isLoading },
    } = useLocation();

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const { studentPaymentHistory } = useSelector((store) => store.bursary);
    const studentPaymentdetails = studentPaymentHistory[0];
    const { studentDetails: details } = useSelector((store) => store.student);

    const firstLevel = academicStruct?.split('-')[0];
    const secondLevel = academicStruct?.split('-')[1];

    const firstDegree = studentDet[firstLevel];
    const secondDegree = studentDet[secondLevel];

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

    const FeeData = useMemo(() => {
        return studentPaymentHistory?.map((item) => {
            let amountExpected = '';
            let amount = '';

            if (!item.amountExpected) {
                amountExpected = item?.installment?.[0]?.amountExpected;
                amount = item?.installment?.[0]?.amount;
            } else {
                amountExpected = item?.amountExpected;
                amount = item?.amount;
            }
            const outstanding = amountExpected - amount;
            return {
                id: item?._id,
                level: item?.level,
                paid: amount,
                outstanding: outstanding,
                name: (
                    <td style={{ color: '#2563EB' }} className="cursor-pointer">
                        {capitalizeFirstLetter(item?.feeName)}
                    </td>
                ),
            };
        });
    }, [studentPaymentHistory]);

    useEffect(() => {
        if (!id) return;
        dispatch(getStudentDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!id) return;
        dispatch(getStudentPaymentHistory(id, query));
    }, [dispatch, id, query]);

    return (
        <div>
            <GoBack title="Students" subTitle={`${studentDet?.firstName} ${studentDet?.lastName}`} />
            <MainContainer>
                <div className="mt-[3rem]">
                    <FlexCentredRow>
                        <People className="mr-[1.719rem]" />
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Student Details
                        </PageTitle>
                    </FlexCentredRow>

                    <BasicContent>
                        {studentPaymentdetails?.logo?.url ? (
                            <img
                                src={studentPaymentdetails?.logo?.url}
                                alt="profile"
                                className="w-[15rem] h-[15rem] col-span-1"
                            />
                        ) : (
                            <Avatar
                                name={`${studentDet?.firstName} ${studentDet?.lastName} `}
                                size="150"
                                textSizeRatio={1.75}
                            />
                        )}
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <TitleText>Full Name</TitleText>
                                <SubText>
                                    {studentDet?.firstName} {studentDet?.lastName}
                                </SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Sex</TitleText>
                                <SubText>
                                    {studentPaymentdetails?.gender || details?.bioData?.updateInfo?.gender}
                                </SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Date Of Birth</TitleText>
                                <SubText>
                                    {new Date(details?.bioData?.updateInfo?.dateOfBirth).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                </SubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <TitleText>Matric No</TitleText>
                                <SubText>{studentPaymentdetails?.matricNumber}</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Year of Admission</TitleText>
                                <SubText>
                                    {studentPaymentdetails?.admissionSession || studentDet?.admissionSession}
                                </SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>{capitalizeFirstLetter(firstLevel)}</TitleText>
                                <SubText>{capitalizeFirstLetter(firstDegree)}</SubText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <TitleText align="right">{capitalizeFirstLetter(secondLevel)}</TitleText>
                                <SubText align="right">{capitalizeFirstLetter(secondDegree)}</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText align="right">Mode of Entry</TitleText>
                                <SubText align="right">
                                    {studentPaymentdetails?.admissionType || studentDet?.admissionType}
                                </SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText align="right">Admission Year </TitleText>
                                <SubText align="right">
                                    {new Date(details?.basicData?.admissionYear).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                </SubText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </div>
            </MainContainer>

            <PageLayout
                noMargin
                searchable
                showTableUtils
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={FeeData || []}
                fields={fields}
                loading={isLoading}
                onRowClick={(item) =>
                    navigate(`/bursary/students/payment-history/receipt/${item?.id}`, {
                        state: { id: item?.id, secondDegree },
                    })
                }
            />
        </div>
    );
};

export default BursaryStudentById;
