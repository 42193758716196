import React, { useMemo } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { removeStaffFromUnit } from '../../../redux/administrators/actions';
import { getAcademicStaff, getNonAcademicStaff } from '../../../redux/staff/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const RemoveStaffFromUnit = ({ show, setShow, selectedStaff, tab, onFinish }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow('');

    const { isLoading } = useSelector((state) => state.administrators);

    const unitsToUse = useMemo(
        () =>
            selectedStaff?.unitName
                .map((item) => ({ unitName: item }))
                ?.sort((a, b) => a?.unitName?.localeCompare(b?.unitName)),
        [selectedStaff],
    );

    return (
        <StyledModal show={show} onClose={onClose} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    Remove Staff from Unit(s)
                </Text>
                <CloseIcon onClick={onClose} className="cursor-pointer" />
            </FlexRowSpaceBetween>

            <Text align="left" size="1.6rem" bottom="1.6rem" color="#374151" top="1.6rem">
                Remove staff from the lists of units
            </Text>

            <Formik
                initialValues={{
                    organizationUnit: [],
                }}
                validationSchema={ValidationSchema}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    const payload = { organizationUnit: values.organizationUnit.map((item) => item.unitName) };

                    const res = await dispatch(removeStaffFromUnit(selectedStaff._id, payload));
                    if (res) {
                        tab === 0 ? dispatch(getAcademicStaff()) : dispatch(getNonAcademicStaff());
                        onFinish?.();
                        actions.resetForm();
                        toast.success('Staff added to unit(s) successfully!');
                        onClose();
                    }
                }}
            >
                {({ errors, setFieldValue, values }) => (
                    <Form>
                        <Select
                            multiSelect
                            name="organizationUnit"
                            objProp="unitName"
                            label="Organization Units"
                            placeholder="Select Unit(s)"
                            data={unitsToUse}
                            useComponentState={false}
                            passedSelectedItems={values.organizationUnit ? values.organizationUnit : null}
                            onChange={(selected) => {
                                setFieldValue('organizationUnit', selected);
                            }}
                            error={errors.organizationUnit}
                        />

                        <FlexContainer>
                            <CancelButton type="button" onClick={onClose}>
                                Cancel
                            </CancelButton>
                            <CancelButton type="button" onClick={() => console.log('values', values)}>
                                values
                            </CancelButton>
                            <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                Save
                            </LoadingButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </StyledModal>
    );
};

export default RemoveStaffFromUnit;
