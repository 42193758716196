import React, { useState } from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-sm.svg';
import { Checkbox, Select } from '../../../components';
import { Text } from '../../../containers/MesssageContainers';

const SettingsForm = ({
    formName,
    publishingForm,
    selectFormName,
    inputFormName,
    checkValue,
    checked,
    onSelectChange,
    numberValue,
    onNumberChange,
    selectedItems,
}) => {
    const [viewForms, setViewForms] = useState([]);

    const handleViewForm = () => {
        if (viewForms?.includes(formName)) {
            setViewForms((prev) => prev?.filter((form) => form !== formName));
        } else {
            setViewForms((prev) => [...prev, formName]);
        }
    };

    return (
        <div className="flex flex-col gap-[1.6rem] w-[69.5rem]">
            <div className="flex items-center justify-between py-[2.4rem]">
                <Text align="left" size="1.8rem" weight="500" color="#1F2937">
                    {formName}
                </Text>
                {viewForms?.includes(formName) ? (
                    <ArrowDown onClick={() => handleViewForm(formName)} />
                ) : (
                    <ArrowRight onClick={() => handleViewForm(formName)} />
                )}
            </div>
            {viewForms?.includes(formName) && (
                <div className="flex flex-col gap-[1.6rem]">
                    {publishingForm?.map((item, i) => (
                        <div key={i} className="flex items-start gap-[1.2rem]">
                            <Checkbox
                                checked={checked?.includes(item?.name)}
                                value={checkValue}
                                onChange={() => item?.onChange(formName)}
                            />
                            <div className="">
                                <Text align="left" weight="500" size="1.4rem" color="#172B4D">
                                    {item?.title}
                                </Text>
                                <Text align="left" weight="400" size="1.2rem" color="#6B7280">
                                    {item?.subtitle}
                                </Text>
                                {item?.showSelect && (
                                    <Select
                                        disabled={!checked?.includes(item?.name)}
                                        name={selectFormName}
                                        placeholder={`Select ${item?.name}`}
                                        data={item?.selectData}
                                        multiSelect
                                        objProp="name"
                                        onChange={(selected) => {
                                            onSelectChange?.(selected, item?.name);
                                        }}
                                        passedSelectedItems={selectedItems?.[item?.name] || []}
                                    />
                                )}
                                {item?.isNumber && (
                                    <TextInput
                                        disabled={!checked?.includes(item?.name)}
                                        name={inputFormName}
                                        type="number"
                                        showSpinBtns
                                        placeholder="Select Number"
                                        value={numberValue}
                                        onChange={onNumberChange}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <hr />
        </div>
    );
};

export default SettingsForm;
