import React from 'react';

import tw from 'twin.macro';

import DetailsBox from '../details-box';

const MainContainer = tw.div`
    grid
    grid-cols-2
    gap-[2.4rem]
    mt-8
`;

const Personal = ({ details }) => {
    const firstLevel = details?.basicData?.academicStructure?.split('-')[0];
    const secondLevel = details?.basicData?.academicStructure?.split('-')[1];
    const firstDegree = details?.basicData?.[firstLevel];
    const secondDegree = details?.basicData?.[secondLevel];
    const { bioData, basicData, counselData } = details || {};
    const { updateInfo } = bioData || {};
    const { counselInfo } = counselData || {};

    const personalInformationLeft = [
        {
            title: 'Title',
            subtitle: updateInfo?.title,
        },
        {
            title: 'Gender',
            subtitle: updateInfo?.gender,
        },
        {
            title: 'Personal Email Address',
            subtitle: basicData?.studentEmail,
        },
        {
            title: 'Phone Number',
            subtitle: updateInfo?.phoneNumber,
        },
    ];
    const personalInformationRight = [
        {
            title: 'Name',
            subtitle: `${basicData?.lastName || ''} ${basicData?.firstName || ''} ${basicData?.middleName || ''}`,
        },
        {
            title: 'Date of Birth',
            subtitle: updateInfo?.dateOfBirth?.slice(0, 10),
        },
        {
            title: 'Matric Number',
            subtitle: basicData?.matricNumber,
        },
        {
            title: 'Marital Status',
            subtitle: updateInfo?.maritalStatus,
        },
    ];
    const otherPersonalInfoLeft = [
        {
            title: 'Nationality',
            subtitle: updateInfo?.nationality,
        },
        {
            title: 'Home Town',
            subtitle: updateInfo?.homeTown,
        },
        {
            title: 'Place of Birth',
            subtitle: updateInfo?.placeOfBirth,
        },
        {
            title: 'Personal Home Address',
            subtitle: updateInfo?.permanentAddress,
        },
    ];
    const otherPersonalInfoRight = [
        {
            title: 'State of Origin',
            subtitle: updateInfo?.stateOfOrigin,
        },
        {
            title: 'LGA',
            subtitle: updateInfo?.localGovernment,
        },
        {
            title: 'Religion',
            subtitle: updateInfo?.religion,
        },
        {
            title: 'Alternative Home Address',
            subtitle: updateInfo?.alternativeHomeAddress,
        },
    ];
    const nextOfKinLeft = [
        {
            title: 'Name',
            subtitle: updateInfo?.nextOfKin,
        },
        {
            title: 'Phone Number',
            subtitle: updateInfo?.nextOfKinPhoneNumber,
        },
    ];
    const nextOfKinRight = [
        {
            title: 'Address',
            subtitle: updateInfo?.nextOfKinAddress,
        },
        {
            title: 'Relationship',
            subtitle: updateInfo?.relationshipWithNextOfKin,
        },
    ];
    const departmentInfoLeft = [
        {
            title: secondLevel,
            subtitle: secondDegree,
        },
        {
            title: 'Level',
            subtitle: basicData?.level,
        },
    ];
    const departmentInfoRight = [
        {
            title: firstLevel,
            subtitle: firstDegree,
        },
        {
            title: 'Academic Degree Award',
            subtitle: 'Nil',
        },
    ];
    const parentsInformationLeft = [
        { title: "Father's Name", subtitle: updateInfo?.fatherName },
        { title: "Father's Address", subtitle: updateInfo?.fatherAddress },
        { title: "Father's Phone Number", subtitle: updateInfo?.fatherPhoneNumber },
        { title: "Father's Email", subtitle: updateInfo?.fatherEmail },
        { title: "Father's Occupation", subtitle: updateInfo?.fatherOccupation },
    ];
    const parentsInformationRight = [
        { title: "Mother's Name", subtitle: updateInfo?.motherName },
        { title: "Mother's Address", subtitle: updateInfo?.motherAddress },
        { title: "Mother's Phone Number", subtitle: updateInfo?.motherPhoneNumber },
        { title: "Mother's Email", subtitle: updateInfo?.motherEmail },
        { title: "Mother's Occupation", subtitle: updateInfo?.motherOccupation },
    ];
    const guardianInformationLeft = [
        { title: 'Name', subtitle: updateInfo?.guardianName },
        { title: 'Address', subtitle: updateInfo?.guardianAddress },
        { title: 'Email Address', subtitle: updateInfo?.guardianEmail },
    ];
    const guardianInformationRight = [
        { title: 'Phone Number', subtitle: updateInfo?.guardianPhoneNumber },
        { title: 'Occupation', subtitle: updateInfo?.guardianOccupation },
    ];
    const familyInformationLeft = [
        { title: 'Type of Family', subtitle: counselInfo?.typeOfFamily },
        { title: 'Position in Family', subtitle: counselInfo?.positionInFamily },
    ];
    const familyInformationRight = [{ title: 'Number of Children', subtitle: counselInfo?.numberInFamily }];
    const sponsorInformationLeft = [
        { title: 'Name', subtitle: updateInfo?.sponsorName },
        { title: 'Address', subtitle: updateInfo?.sponsorAddress },
        { title: 'Relationship', subtitle: updateInfo?.sponsorRelationship },
    ];
    const sponsorInformationRight = [
        { title: 'Phone Number', subtitle: updateInfo?.sponsorPhoneNumber },
        { title: 'Occupation', subtitle: updateInfo?.sponsorOccupation },
    ];
    const sportsInformationLeft = [
        { title: 'Name of Major Sport/Game', subtitle: counselInfo?.nameOfSport },
        { title: 'Achievement (Prize, Medals)', subtitle: counselInfo?.achievement },
    ];
    const sportsInformationRight = [
        { title: 'Name of other Sport/Game', subtitle: counselInfo?.nameOfOtherSport },
        { title: 'Other Achievement (Prize, Medals)', subtitle: counselInfo?.achievements },
    ];
    const socialActivitiesInformationLeft = [
        { title: 'Name of Club', subtitle: counselInfo?.nameOfClub },
        { title: 'Second Social organisation', subtitle: counselInfo?.nameOfSociety },
    ];
    const socialActivitiesInformationRight = [
        { title: 'Organisation Position', subtitle: counselInfo?.yourPosition },
        { title: 'Second Organisation Position', subtitle: counselInfo?.yourPost },
    ];
    const ambitionInformationLeft = [
        { title: 'What is/are your life ambitions', subtitle: counselInfo?.yourAmbition },
        {
            title: 'What other information about yourself would be useful to this unit',
            subtitle: counselInfo?.otherInformation,
        },
    ];
    const ambitionInformationRight = [
        {
            title: 'What personal efforts are you making to achieve your ambition',
            subtitle: counselInfo?.personalEffort,
        },
    ];
    const medicalInformationLeft = [
        { title: 'Any Special/ Continuous Medical Treatment', subtitle: counselInfo?.anyMedicalCondition },
        { title: 'Description of treatment', subtitle: counselInfo?.treatment },
    ];
    const medicalInformationRight = [{ title: 'Place of Treatment', subtitle: counselInfo?.treatmentAddress }];

    return (
        <MainContainer>
            <div className="flex flex-col gap-[2.4rem]">
                <DetailsBox
                    heading="Personal Information"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={personalInformationLeft}
                    rightData={personalInformationRight}
                />

                <DetailsBox
                    heading="Next of Kin"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={nextOfKinLeft}
                    rightData={nextOfKinRight}
                />

                <DetailsBox
                    heading="Parents Information"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={parentsInformationLeft}
                    rightData={parentsInformationRight}
                />

                <DetailsBox
                    heading="Family Information"
                    dataBadge="Counselling"
                    badgeClass="bg-[#ECFDF5]"
                    badgeColor="#059669"
                    leftData={familyInformationLeft}
                    rightData={familyInformationRight}
                />

                <DetailsBox
                    heading="Sports/Games Information"
                    dataBadge="Counselling"
                    badgeClass="bg-[#ECFDF5]"
                    badgeColor="#059669"
                    leftData={sportsInformationLeft}
                    rightData={sportsInformationRight}
                />

                <DetailsBox
                    heading="Life Ambition Information"
                    dataBadge="Counselling"
                    badgeClass="bg-[#ECFDF5]"
                    badgeColor="#059669"
                    leftData={ambitionInformationLeft}
                    rightData={ambitionInformationRight}
                />
            </div>
            <div className="flex flex-col gap-[2.4rem]">
                <DetailsBox
                    heading="Other Personal Information"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={otherPersonalInfoLeft}
                    rightData={otherPersonalInfoRight}
                />
                <DetailsBox
                    heading="Department Information"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={departmentInfoLeft}
                    rightData={departmentInfoRight}
                />
                <DetailsBox
                    heading="Guardian Information"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={guardianInformationLeft}
                    rightData={guardianInformationRight}
                />
                <DetailsBox
                    heading="Sponsor's Information"
                    dataBadge="Bio-Data"
                    badgeClass="bg-[#EFF6FF]"
                    badgeColor="#2563EB"
                    leftData={sponsorInformationLeft}
                    rightData={sponsorInformationRight}
                />
                <DetailsBox
                    heading="Services or Social Activities"
                    dataBadge="Counselling"
                    badgeClass="bg-[#ECFDF5]"
                    badgeColor="#059669"
                    leftData={socialActivitiesInformationLeft}
                    rightData={socialActivitiesInformationRight}
                />
                <DetailsBox
                    heading="Medical Information"
                    dataBadge="Counselling"
                    badgeClass="bg-[#ECFDF5]"
                    badgeColor="#059669"
                    leftData={medicalInformationLeft}
                    rightData={medicalInformationRight}
                />
            </div>
        </MainContainer>
    );
};

export default Personal;
