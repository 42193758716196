import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
    },
});

export const { LOADING, STOP_LOADING } = schoolSlice.actions;

export default schoolSlice.reducer;
