import React from 'react';

import EditStudentDetailsAdmisionsForm from '../../../components/forms/edit-student-details-admissions';
import { GoBack } from '../../../components/go-back';

const EditStudent = () => {
    return (
        <>
            <div className="mb-12">
                <GoBack title={`Admissions`} subTitle="Edit Student" />
            </div>
            <EditStudentDetailsAdmisionsForm />
        </>
    );
};

export default EditStudent;
