import React, { useState } from 'react';

import tw from 'twin.macro';

import { ReactComponent as Download } from '../../../../../assets/icons/download.svg';
import { ReactComponent as ExternalLink } from '../../../../../assets/icons/external-Link.svg';
// import { ReactComponent as Retry } from '../../../../../assets/icons/retry.svg';
import { ReactComponent as ThreeDots } from '../../../../../assets/icons/three-dot.svg';
import { ReactComponent as UploadedFile } from '../../../../../assets/icons/uploaded-file.svg';
import ActionsPopup from '../../../../../components/popups/actions-popup';
import { Text } from '../../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../../containers/ScreenContainers';
import { handleDownload, handleImagePreview } from '../../../../../utils';

const GridContainer = tw.div`
grid
grid-cols-2
mt-[1.7rem]
`;
const ImageDetails = tw.div`
mr-[2.8rem]
mb-[2.8rem]

`;

const Uploads = (details) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const upload = [
        {
            title: 'Recent Passport*',
            subtitle: details?.details?.uploads?.passport?.[0]?.fileName,
            size: details?.details?.uploads?.passport?.[0]?.size,
            url: details?.details?.uploads?.passport?.[0]?.url,
        },
        {
            title: 'Attestation Letter*',
            subtitle: details?.details?.uploads?.attestation?.[0]?.fileName,
            size: details?.details?.uploads?.attestation?.[0]?.size,
            url: details?.details?.uploads?.attestation?.[0]?.url,
        },
        {
            title: 'Certificate of Origin*',
            subtitle: details?.details?.uploads?.origin?.[0]?.fileName,
            size: details?.details?.uploads?.origin?.[0]?.size,
            url: details?.details?.uploads?.origin?.[0]?.url,
        },
        {
            title: 'Court Affidavit*',
            subtitle: details?.details?.uploads?.affidavit?.[0]?.fileName,
            size: details?.details?.uploads?.affidavit?.[0]?.size,
            url: details?.details?.uploads?.affidavit?.[0]?.url,
        },
        {
            title: 'Birth Certificate*',
            subtitle: details?.details?.uploads?.birthday?.[0]?.fileName,
            size: details?.details?.uploads?.birthday?.[0]?.size,
            url: details?.details?.uploads?.birthday?.[0]?.url,
        },
        {
            title: 'Jamb Admission Letter*',
            subtitle: details?.details?.uploads?.jamb?.[0]?.fileName,
            size: details?.details?.uploads?.jamb?.[0]?.size,
            url: details?.details?.uploads?.jamb?.[0]?.url,
        },
        {
            title: "O'Level Result*",
            subtitle: details?.details?.uploads?.olevel?.[0]?.fileName,
            size: details?.details?.uploads?.olevel?.[0]?.size,
            url: details?.details?.uploads?.olevel?.[0]?.url,
        },
        {
            title: 'Medical Certificate*',
            subtitle: details?.details?.uploads?.medical?.[0]?.fileName,
            size: details?.details?.uploads?.medical?.[0]?.size,
            url: details?.details?.uploads?.medical?.[0]?.url,
        },
    ];

    return (
        <>
            <GridContainer>
                {upload?.map((item) => (
                    <ImageDetails>
                        <>
                            <FlexCentredRow className="bg-white py-10 pl-10 text-center border-b-2 rounded-t">
                                <Text weight="600" size="1.6rem" lineHeight="2.8rem">
                                    {item?.title}
                                </Text>
                            </FlexCentredRow>
                            <FlexRowSpaceBetween className="bg-white p-10 items-center rounded-b">
                                <FlexCentredRow>
                                    <UploadedFile className="mr-[2.308rem]" />

                                    <FlexCentredCol>
                                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                            {item?.subtitle}
                                        </Text>
                                        <Text
                                            weight="500"
                                            align="left"
                                            color="#6B7280"
                                            size="1.4rem"
                                            lineHeight="2.4rem"
                                        >
                                            Size - {item?.size}kb
                                        </Text>
                                    </FlexCentredCol>
                                </FlexCentredRow>
                                <RelativeContainer>
                                    <ThreeDots onClick={() => setOpenDropdown(item.title)} />
                                    <ActionsPopup
                                        open={openDropdown === item.title}
                                        close={() => setOpenDropdown(null)}
                                        items={[
                                            {
                                                icon: <Download fill="#1F2937" />,
                                                name: 'Download',
                                                click: () => {
                                                    const downloadUrl = item?.url;
                                                    const fileName = item.subtitle;

                                                    if (downloadUrl && fileName) {
                                                        handleDownload(downloadUrl, fileName);
                                                    }
                                                },
                                            },

                                            {
                                                icon: <ExternalLink fill="#1F2937" />,
                                                name: 'Preview',
                                                click: () => handleImagePreview(item?.url),
                                            },
                                            // { icon: <Retry />, name: 'Request for Re-Upload' },
                                        ]}
                                    />
                                </RelativeContainer>
                            </FlexRowSpaceBetween>
                        </>
                    </ImageDetails>
                ))}
            </GridContainer>
        </>
    );
};

export default Uploads;
