import { MEDICAL } from '../../utils/unitNames';
import {
    CreateRole,
    EditAdministrator,
    Received,
    Roles,
    // AddAdministrator,
    Administrator,
    AcademicCalendar,
    StaffDetail,
    Staff,
    GeneralSettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    EditRole,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';

export const medicalsRoutes = [
    {
        path: '/clinic/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/clinic/access',
        element: (
            <Access
                staffs={<Staff unitName={MEDICAL} />}
                administrators={<Administrator unitName={MEDICAL} />}
                roles={<Roles unitName={MEDICAL} />}
            />
        ),
    },
    {
        path: '/clinic/access/create-role',
        element: <CreateRole unitName={MEDICAL} />,
    },
    {
        path: '/clinic/access/edit-role',
        element: <EditRole unitName={MEDICAL} />,
    },
    {
        path: '/clinic/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/clinic/access/add-administrator',
        // element: <AddAdministrator unitName={MEDICAL} />,
        element: <AssignRole unitName={MEDICAL} />,
    },
    {
        path: '/clinic/access/edit-administrator',
        element: <EditAdministrator unitName={MEDICAL} />,
    },
    {
        path: 'clinic/communications/received',
        element: <Received unitName={MEDICAL} />,
    },
    {
        path: '/clinic/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/clinic/communications/distribution-list',
        element: <DistributionList unitName={MEDICAL} title="Communications" />,
    },
    {
        path: '/clinic/communications/distribution-list/add',
        element: <AddDistributionList unitName={MEDICAL} title="Communications" />,
    },
    {
        path: '/clinic/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={MEDICAL} title="Communications" />,
    },
    {
        path: '/clinic/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/clinic/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/clinic/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/clinic/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
