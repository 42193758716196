import React, { useEffect, useState /*useRef */ } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { GoBack } from '../../../../components/go-back';
import { Loader } from '../../../../components/loader';
import { getStudentDetails } from '../../../../redux/students/actions';

import Medical from './tabs/medical.js';
import Overview from './tabs/overview.js';
import Personal from './tabs/personalInfo.js';
import Sports from './tabs/sports.js';
import Uploads from './tabs/uploads.js';

const StudentById = () => {
    const {
        state: { academicStruct, studentDet, currentUnit },
    } = useLocation();
    const dispatch = useDispatch();
    const [, /*tab*/ setTab] = useState(0);

    const { studentDetails, isLoading } = useSelector((store) => store.student);
    const { _id: studentId } = studentDet;

    useEffect(() => {
        dispatch(getStudentDetails(studentId));
    }, [dispatch, studentId]);

    if (isLoading) return <Loader />;
    return (
        <div>
            <GoBack title="Students" subTitle={`${studentDet?.firstName} ${studentDet?.lastName}`} />

            <div className="mt-[3.2rem]">
                <CTabs>
                    <CNav variant="tabs">
                        <CNavItem>
                            <CNavLink onClick={() => setTab(0)}>Overview</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(1)}>Personal</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(2)}>Medical</CNavLink>
                        </CNavItem>
                        {currentUnit === 'Admissions' && (
                            <CNavItem>
                                <CNavLink onClick={() => setTab(3)}>Uploads</CNavLink>
                            </CNavItem>
                        )}
                        {currentUnit === 'Students Affairs' && (
                            <CNavItem>
                                <CNavLink onClick={() => setTab(3)}>Sports</CNavLink>
                            </CNavItem>
                        )}
                    </CNav>
                    <CTabContent>
                        <CTabPane>
                            <Overview
                                details={studentDetails}
                                academicStruct={academicStruct}
                                studentDet={studentDet}
                                currentUnit={currentUnit}
                            />
                        </CTabPane>
                        <CTabPane>
                            <Personal details={studentDetails} />
                        </CTabPane>
                        <CTabPane>
                            <Medical details={studentDetails} />
                        </CTabPane>
                        {currentUnit === 'Admissions' && (
                            <CTabPane>
                                <Uploads details={studentDetails} />
                            </CTabPane>
                        )}
                        {currentUnit === 'Students Affairs' && (
                            <CTabPane>
                                <Sports details={studentDetails} />
                            </CTabPane>
                        )}
                    </CTabContent>
                </CTabs>
            </div>
        </div>
    );
};

export default StudentById;
