import React from 'react';

import tw, { styled } from 'twin.macro';

const Container = styled.div`
    width: ${({ width }) => width || '100%'};
`;

const InputField = tw.textarea`
    border-[1px]
    border-[#E5E7EB]
    p-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    text-[#1F2937]
`;

const StyledInputField = styled(InputField)`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '4rem'};
`;

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const TextAreaInput = ({
    value,
    name,
    errorMessage,
    placeholder,
    className,
    width,
    height,
    onChange,
    label = '',
    disabled,
    // rows,
    // cols,
}) => {
    return (
        <Container width={width}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <StyledInputField
                id={name}
                placeholder={placeholder}
                className={`${errorMessage && 'focus:ring-[#EF4444]'} ${className} ${disabled && 'text-[#D1D5DB]'}`}
                value={value}
                height={height}
                width={width}
                onChange={onChange && onChange}
                disabled={disabled}
                type="text"
                // rows={rows}
                // cols={cols}
            />

            {errorMessage && <span className="text-[#EF4444] text-[1.2rem]">{errorMessage}</span>}
        </Container>
    );
};

export default TextAreaInput;
