import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    session: Yup.string().nullable().required('Required'),
    feeType: Yup.string().nullable().required('Required'),
    amountPayable: Yup.string().nullable().required('Required'),
    paymentSchedule: Yup.string().nullable().required('Required'),
    span: Yup.string().nullable().required('Required'),
    feeName: Yup.string().nullable().required('Required'),
    description: Yup.string().nullable().required('Required'),
    deadline: Yup.string(),
    target: Yup.string().nullable().required('Required'),
    paymentConfig: Yup.string().nullable().required('Required'),
});
