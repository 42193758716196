import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import EditPaymentDetails from '../../../../components/forms/edit-payment-details';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';

const EditPaymentMethod = () => {
    const navigate = useNavigate();
    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[8px] pb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem" weight="400">
                    /
                </Text>
                <Text size="1.6rem" weight="400">
                    Manage payment methods
                </Text>
            </FlexCentredRow>
            <div className="bg-white p-[2.4rem]">
                <Text align="left" size="2.3rem" weight="600">
                    Update your credit or debit card details.
                </Text>
                <Text align="left" size="1.4rem" weight="500" color="#6B7280" top="0.8rem">
                    Edit your payment details, add a backup, or switch your preferred payment method.
                </Text>
                <Text align="left" size="1.4rem" weight="600" color="#6B7280" top="3rem" bottom="2.4rem">
                    Credit card information
                </Text>
                <EditPaymentDetails />
            </div>
        </div>
    );
};

export default EditPaymentMethod;
