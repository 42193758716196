import { createSlice } from '@reduxjs/toolkit';

import { capitalizeFirstLetter } from '../../utils';

const initialState = {
    isLoading: false,
    faculties: [],
    departments: [],
    courses: [],
    generalCourses: [],
    staff: [],
    students: [],
    admins: [],
    currentFaculty: {},
    mondayTimetable: [],
    tuesdayTimetable: [],
    wednesdayTimetable: [],
    thursdayTimetable: [],
    fridayTimetable: [],
    allExamTimetable: [],
    allLectureTimetable: [],
    mondayExamTimetable: [],
    tuesdayExamTimetable: [],
    wednesdayExamTimetable: [],
    thursdayExamTimetable: [],
    fridayExamTimetable: [],
    lectureTimetable: {},
    firstDegreeLectureVenues: [],
    examTimetable: {},
};

export const facultySlice = createSlice({
    name: 'faculty',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_FACULTIES: (state, action) => {
            state.faculties = action.payload;
        },
        GET_FACULTY_DEPARTMENTS: (state, action) => {
            state.departments = action.payload;
        },
        GET_FACULTY_COURSES: (state, action) => {
            state.courses = action.payload;
        },
        GET_GENERAL_COURSES_FIRST_DEGREE: (state, action) => {
            state.generalCourses = action.payload;
        },
        GET_FACULTY_STAFF: (state, action) => {
            state.staff = action.payload;
        },
        GET_FACULTY_STUDENTS: (state, action) => {
            state.students = action.payload;
        },
        GET_FACULTY_ADMINS: (state, action) => {
            state.admins = action.payload;
        },
        SAVE_CURRENT_FACULTY: (state, action) => {
            const { academicStructure = '' } = action.payload;
            const splitedAcademicStructure = academicStructure?.split('-') || '';

            state.currentFaculty = {
                ...action.payload,
                splitAcademicStructureOne: capitalizeFirstLetter(splitedAcademicStructure[0]),
                splitAcademicStructureTwo: capitalizeFirstLetter(splitedAcademicStructure[1]) || '',
                splitAcademicStructureThree: capitalizeFirstLetter(splitedAcademicStructure[2]) || '',
            };
        },
        GET_ALL_LECTURE_TIMETABLE: (state, action) => {
            state.allLectureTimetable = action.payload;
        },
        GET_MONDAY_TIMETABLE: (state, action) => {
            state.mondayTimetable = action.payload;
        },
        GET_TUESDAY_TIMETABLE: (state, action) => {
            state.tuesdayTimetable = action.payload;
        },
        GET_WEDNESDAY_TIMETABLE: (state, action) => {
            state.wednesdayTimetable = action.payload;
        },
        GET_THURSDAY_TIMETABLE: (state, action) => {
            state.thursdayTimetable = action.payload;
        },
        GET_FRIDAY_TIMETABLE: (state, action) => {
            state.fridayTimetable = action.payload;
        },
        GET_ALL_EXAM_TIMETABLE: (state, action) => {
            state.allExamTimetable = action.payload;
        },
        GET_MONDAY_EXAM_TIMETABLE: (state, action) => {
            state.mondayExamTimetable = action.payload;
        },
        GET_TUESDAY_EXAM_TIMETABLE: (state, action) => {
            state.tuesdayExamTimetable = action.payload;
        },
        GET_WEDNESDAY_EXAM_TIMETABLE: (state, action) => {
            state.wednesdayExamTimetable = action.payload;
        },
        GET_THURSDAY_EXAM_TIMETABLE: (state, action) => {
            state.thursdayExamTimetable = action.payload;
        },
        GET_FRIDAY_EXAM_TIMETABLE: (state, action) => {
            state.fridayExamTimetable = action.payload;
        },
        GET_SINGLE_TIMETABLE: (state, action) => {
            state.lectureTimetable = action.payload;
        },
        GET_FIRST_DEGREE_LECTURE_VENUES: (state, action) => {
            state.firstDegreeLectureVenues = action.payload;
        },
        GET_EXAM_TIMETABLE: (state, action) => {
            state.examTimetable = action.payload;
        },
    },
});

export const {
    GET_FACULTIES,
    LOADING,
    STOP_LOADING,
    GET_FACULTY_DEPARTMENTS,
    GET_FACULTY_COURSES,
    GET_GENERAL_COURSES_FIRST_DEGREE,
    GET_FACULTY_STAFF,
    GET_FACULTY_STUDENTS,
    GET_FACULTY_ADMINS,
    SAVE_CURRENT_FACULTY,
    // CREATE_TIMETABLE,
    GET_ALL_LECTURE_TIMETABLE,
    GET_MONDAY_TIMETABLE,
    GET_TUESDAY_TIMETABLE,
    GET_WEDNESDAY_TIMETABLE,
    GET_THURSDAY_TIMETABLE,
    GET_FRIDAY_TIMETABLE,
    GET_ALL_EXAM_TIMETABLE,
    GET_MONDAY_EXAM_TIMETABLE,
    GET_TUESDAY_EXAM_TIMETABLE,
    GET_WEDNESDAY_EXAM_TIMETABLE,
    GET_THURSDAY_EXAM_TIMETABLE,
    GET_FRIDAY_EXAM_TIMETABLE,
    GET_SINGLE_TIMETABLE,
    GET_FIRST_DEGREE_LECTURE_VENUES,
    GET_EXAM_TIMETABLE,
} = facultySlice.actions;

export default facultySlice.reducer;
