export const sportsNavLinks = [
    {
        title: 'Communications',
        to: '/sports/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/sports/communications/compose',
            },
            {
                title: 'Received',
                to: '/sports/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/sports/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/sports/communications/settings',
            },
        ],
    },
    {
        title: 'Sports',
        to: '/sports/home',
    },
    {
        title: 'Academic Calendar',
        to: '/sports/academic-calendar',
    },
    {
        title: 'Access',
        to: '/sports/access',
    },
    {
        title: 'Settings',
        to: '/sports/settings',
    },
];

export const sportsRoutes = sportsNavLinks.map((item) => item.to);
