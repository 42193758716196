import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/peopleIcon.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { addLectureVenues } from '../../../redux/departments/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const AddLectureVenue = ({ onClose, onFinish, secondLevelId, academicStructureId }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.department);

    return (
        <div className="bg-white rounded-[1.6rem] py-[3.6rem] px-[3.2rem] mt-[3.2rem]">
            <div className="flex items-center gap-[1.6rem] mb-5">
                <PeopleIcon />
                <Text size="1.6rem" weight="600">
                    Add Lecture Theatre
                </Text>
            </div>
            <Formik
                initialValues={{
                    hallName: '',
                    venue: '',
                    capacity: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    if (!secondLevelId) return toast.error('Second Level Id is missing');
                    const res = await dispatch(addLectureVenues(values, secondLevelId, academicStructureId));
                    if (res) {
                        onFinish?.();
                        toast.success('Lecture venue added successfully!');
                        actions.resetForm();
                        onClose();
                    }
                }}
            >
                {({ errors, values, handleChange }) => (
                    <Form>
                        <div className="grid grid-cols-2 gap-4">
                            <TextInput
                                label="Name of Lecture Hall"
                                name="hallName"
                                type="text"
                                placeholder="Hall Name"
                                errors={errors}
                                onChange={handleChange}
                                value={values.hallName}
                            />
                            <TextInput
                                label="Venue"
                                name="venue"
                                type="text"
                                placeholder="Location"
                                errors={errors}
                                onChange={handleChange}
                                value={values.venue}
                            />

                            <TextInput
                                label="Capacity"
                                name="capacity"
                                type="text"
                                placeholder="Capacity"
                                errors={errors}
                                onChange={handleChange}
                                value={values.capacity}
                            />
                        </div>
                        <FlexRowEnd className="gap-[2rem] mt-5">
                            <CancelButton type="button" disabled={isLoading} onClick={onClose}>
                                Cancel
                            </CancelButton>
                            <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                Add
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddLectureVenue;
