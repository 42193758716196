import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getCollegeSecondLevel } from '../redux/college/actions';
import { getFacultySecondLevel } from '../redux/faculty/actions';

const useGetSecondDegrees = (selectedAcadUnit, selectedFirstDegree) => {
    const dispatch = useDispatch();

    const { isLoading: facLoading, departments } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);

    useEffect(() => {
        if (selectedFirstDegree) {
            const currStructName = selectedAcadUnit?.toLowerCase() || '';
            const { academicStructure, academicStructureId, _id: firstDegreeId } = selectedFirstDegree || {};

            const splitStruct = academicStructure?.split('-');
            const structTwo = splitStruct?.[1];
            const params = { academicStructureId, firstDegreeId, structTwo };

            if (currStructName === 'college') {
                dispatch(getCollegeSecondLevel(params));
            }
            if (currStructName === 'faculty') {
                dispatch(getFacultySecondLevel(params));
            }
        }
    }, [dispatch, selectedAcadUnit, selectedFirstDegree]);

    return {
        isLoading: facLoading || collegeLoading,
        secondDegrees: departments,
    };
};

export default useGetSecondDegrees;
