import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as ImportIcon } from '../../../assets/icons/download-blue2.svg';
import { FlexColumn } from '../../../containers/ScreenContainers';
import { importCurriculum } from '../../../redux/curriculum/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const COURSE_SEMESTER = [
    { name: 'First Semester', value: 'First Semester' },
    { name: 'Second Semester', value: 'Second Semester' },
];

const ImportCurriculum = ({ show, setShow, level, onFinish }) => {
    const dispatch = useDispatch();
    const admissionsSessions = useSelector((state) => state.admission.sessions);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { _id: departmentId, academicStructure, academicStructureId } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [altLoading, setAltLoading] = useState(false);
    const onClose = () => setShow(false);

    return (
        <>
            <FormContainer headerIcon={<ImportIcon />} close={onClose} show={show} title={'Import Curriculum'}>
                <Formik
                    initialValues={{
                        sourceSession: '',
                        sourceSemester: '',
                        destinationSession: '',
                        destinationSemester: '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        setAltLoading(true);
                        const res = await dispatch(importCurriculum(academicStructureId, lastDegreeId, level, values));
                        if (res) {
                            toast.success('Curriculum was imported successfully');
                            actions.resetForm();
                            onFinish?.();
                            onClose();
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <FlexColumn className="gap-5">
                                <Select
                                    name="sourceSession"
                                    objProp="admissionYear"
                                    label="Source Session"
                                    placeholder="Choose Session"
                                    data={admissionsSessions}
                                    useComponentState={false}
                                    passedSelectedItems={
                                        values.sourceSession ? [{ admissionYear: values.sourceSession }] : null
                                    }
                                    onChange={(selected) => {
                                        setFieldValue('sourceSession', String(selected[0].admissionYear));
                                    }}
                                    error={errors.sourceSession}
                                />

                                <Select
                                    name="sourceSemester"
                                    objProp="name"
                                    placeholder="Source Semester"
                                    label="Source Semester"
                                    data={COURSE_SEMESTER}
                                    useComponentState={false}
                                    passedSelectedItems={
                                        values.sourceSemester ? [{ name: values.sourceSemester }] : null
                                    }
                                    onChange={(selected) => {
                                        setFieldValue('sourceSemester', String(selected[0].value));
                                    }}
                                    error={errors.sourceSemester}
                                />

                                <FlexColumn className="mt-6  gap-5">
                                    <Select
                                        name="destinationSession"
                                        objProp="admissionYear"
                                        label="Destination Session"
                                        placeholder="Choose Session"
                                        data={admissionsSessions}
                                        useComponentState={false}
                                        passedSelectedItems={
                                            values.destinationSession
                                                ? [{ admissionYear: values.destinationSession }]
                                                : null
                                        }
                                        onChange={(selected) => {
                                            setFieldValue('destinationSession', String(selected[0].admissionYear));
                                        }}
                                        error={errors.destinationSession}
                                    />

                                    <Select
                                        name="destinationSemester"
                                        objProp="name"
                                        placeholder="Destination Semester"
                                        label="Destination Semester"
                                        data={COURSE_SEMESTER}
                                        useComponentState={false}
                                        passedSelectedItems={
                                            values.destinationSemester ? [{ name: values.destinationSemester }] : null
                                        }
                                        onChange={(selected) => {
                                            setFieldValue('destinationSemester', String(selected[0].value));
                                        }}
                                        error={errors.destinationSemester}
                                    />
                                </FlexColumn>
                            </FlexColumn>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={altLoading} type="submit">
                                    Import
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default ImportCurriculum;
