import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { CancelButton } from '../../../components/forms/sharedStyles';
import Select from '../../../components/inputs/new-select';
import { assignPlatformAdminRoleToStaff } from '../../../redux/roles/actions';
import { getAllStaff } from '../../../redux/staff/actions';
import { ReactComponent as PeopleIcon } from '../../.../../../assets/icons/people.svg';
import { LoadingButton } from '../../.../../../components/buttons';
import { GoBack } from '../../.../../../components/go-back';
import ConfirmAddAdministrator from '../../.../../../components/popups/confirm-add-administrator';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../.../../../containers/ScreenContainers';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    w-full
`;

const Title = tw.h2`
    text-[1.6rem]
    font-semibold
    leading-6
    text-[#1F2937]
    mb-[0]
`;

const ROLE = 'Platform Administrator';

const IctAddOrgAdministrator = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { allStaff, isLoading: staffLoading } = useSelector((store) => store.staff);
    const { isLoading } = useSelector((state) => state.administrators);
    const { isLoading: rolesLoading } = useSelector((state) => state.roles);

    const [showConfirmAllocation, setShowConfirmAllocation] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);

    const handleSubmit = async () => {
        const payload = {
            role: ROLE,
        };
        const res = await dispatch(assignPlatformAdminRoleToStaff(selectedStaff[0]?._id, payload));
        if (res) {
            navigate(-1);
        }
    };

    useEffect(() => {
        dispatch(getAllStaff());
    }, [dispatch]);

    return (
        <div className="h-[100vh]">
            <div className="mb-12">
                <GoBack title={`Administrators`} subtitle="Add Administrator" />
            </div>

            <Formik
                initialValues={{
                    staffId: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    setShowConfirmAllocation(true);
                }}
            >
                {({ errors, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <div className="pl-6">
                                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem] ">
                                    <PeopleIcon />
                                    <Title>Add Administrator(s)</Title>
                                </FlexCentredRow>
                                <FlexCentredCol className=" gap-5 mb-[2.4rem]">
                                    <Select
                                        objProp="fullName"
                                        searchable
                                        placeholder="Select Staff"
                                        label="Staff"
                                        // multiSelect
                                        data={allStaff}
                                        isLoading={staffLoading}
                                        onChange={(selected) => {
                                            setFieldValue('staffId', selected[0]?._id);
                                            setSelectedStaff([selected[0]]);
                                        }}
                                        error={errors?.staffId}
                                    />
                                </FlexCentredCol>
                            </div>

                            <FlexRowEnd className="gap-[2.4rem] mb-[3.6rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading || rolesLoading}
                                    type="submit"
                                    disabled={!selectedStaff.length || isLoading}
                                >
                                    Add Administrator
                                </LoadingButton>
                            </FlexRowEnd>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
            <ConfirmAddAdministrator
                show={showConfirmAllocation}
                close={() => setShowConfirmAllocation(false)}
                list={selectedStaff}
                role={ROLE}
                confirmAction={handleSubmit}
                altLoading={rolesLoading}
            />
        </div>
    );
};

export default IctAddOrgAdministrator;
