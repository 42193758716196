import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-sm.svg';
import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/people.svg';
import { Button } from '../../../../../components/buttons';
import { GoBack } from '../../../../../components/go-back';
import Checkbox from '../../../../../components/inputs/checkbox';
import ConfirmAddAdministrator from '../../../../../components/popups/confirm-add-administrator';
import ListWithCheckBox from '../../../../../components/popups/list-with-checkbox';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexDiv } from '../../../../../containers/ScreenContainers';

const MainContainer = tw.div`
    bg-white
    rounded-lg
    py-[2.4rem]
    max-w-[100.5rem]
`;

const FlowRoot = tw.div`
    flow-root
    pr-6
`;

const Label = tw.label`
    text-[1.4rem]
    leading-6
    font-semibold
    mb-[0.8rem]
`;

const Title = tw.h2`
    text-[1.6rem]
    font-semibold
    leading-6
    text-[#1F2937]
    mb-[0]
`;

const TagInputContainer = tw.div`
    border
    border-[#E5E7EB]
    px-4
    py-4
    rounded-md
    grid
    grid-cols-6
    gap-[1rem]
`;

const TagItems = tw.div`
    bg-[#6366F1]
    flex
    justify-center
    items-center
    text-[1.2rem]
    text-white
    rounded
    px-2
    py-1
    gap-4
    w-max
`;

const NewInput = tw.input`
    pl-3
    rounded
    outline-none
    focus:ring-0
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
`;

const rolesSelection = [
    {
        id: 1,
        value: 'Admission Organization Unit Administrator',
        // check: false,
    },
    {
        id: 2,
        value: 'Records Organization Unit Administrator',
        // check: false,
    },
    {
        id: 3,
        value: 'Faculty Organization Unit Administrator',
        // check: false,
    },
    {
        id: 4,
        value: 'Staff Management Organization Unit Administrator',
        // check: false,
    },
    {
        id: 5,
        value: 'Bursary Organization Unit Administrator',
        // check: false,
    },
    {
        id: 6,
        value: 'Library Organization Unit Administrator',
        // check: false,
    },
    {
        id: 7,
        value: 'Hostel Management Organization Unit Administrator',
        // check: false,
    },
    {
        id: 8,
        value: 'Communications Organization Unit Administrator',
        // check: false,
    },
];

const EditDepartmentAdministrator = ({ show, setShow, facultyId, checked }) => {
    const onClose = () => setShow(false);
    const [selectedRole, setSelectedRole] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmAllocation, setShowConfirmAllocation] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const options = [
        { id: 1, fullName: 'Adeoye Bola' },
        { id: 2, fullName: 'Adeojo Banke' },
        { id: 3, fullName: 'Pierce Adetola' },
        { id: 4, fullName: 'Adeiye Oni' },
        { id: 5, fullName: 'Adeola Abeni' },
    ];

    const filteredOptions = options.filter((item) => item.fullName.toLowerCase().includes(inputValue.toLowerCase()));

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleRemove = (index) => {
        setSelectedOptions(selectedOptions.filter((item, i) => i !== index));
    };

    // const handleChangeRole = (event) => {
    //     const { value, checked } = event.target;
    //     if (checked) {
    //         setSelectedRole([...selectedRole, value]);
    //     } else {
    //         setSelectedRole(selectedRole.filter((item) => item !== value));
    //     }
    // };
    const handleChangeRole = (item) => {
        const selected = selectedRole?.some((ite) => ite.value === item.value);

        if (selected) {
            const newItems = selectedRole.filter((ite) => ite.value !== item.value);
            setSelectedRole(newItems);
        } else {
            setSelectedRole((prev) => [...prev, item]);
        }
    };

    return (
        <>
            <div className="mb-12">
                <GoBack title={`Administrators`} subtitle="Edit Administrator" />
            </div>

            <Formik
                initialValues={{
                    name: '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                    console.log('first');
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <MainContainer>
                            <div className="pl-6">
                                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                                    <PeopleIcon />
                                    <Title>Edit Administrator(s)</Title>
                                </FlexCentredRow>
                                <FlexCentredCol className="mb-[2.4rem]">
                                    <Label> Name</Label>

                                    <div className="relative">
                                        <TagInputContainer onClick={toggleDropdown}>
                                            {selectedOptions?.map((item, index) => (
                                                <TagItems key={item.id}>
                                                    <Text color="#FFF">{item.fullName} </Text>
                                                    <CloseIcon onClick={() => handleRemove(index)} />
                                                </TagItems>
                                            ))}
                                            <NewInput
                                                type="text"
                                                placeholder={isOpen ? '' : 'Search for a name'}
                                                name="name"
                                                errors={errors}
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />
                                        </TagInputContainer>
                                        {isOpen && (
                                            <div className="absolute z-[5]">
                                                <ListWithCheckBox
                                                    list={filteredOptions}
                                                    setSelectedItems={setSelectedOptions}
                                                    selectedItems={selectedOptions}
                                                    onClose={() => {
                                                        setIsOpen(false);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </FlexCentredCol>

                                <Label align="left">Select Role</Label>
                            </div>
                            <div className="grid grid-cols-1 justify-center gap-y-[1.6rem] w-3/4">
                                {rolesSelection?.map((item) => (
                                    <div>
                                        <div
                                            className="flex items-center justify-start gap-x-[1.4rem] gap-y-[0.8rem]
"
                                        >
                                            <div className="rounded-lg relative">
                                                <Checkbox
                                                    value={item.value}
                                                    id={item.id}
                                                    onChange={() => handleChangeRole(item)}
                                                    checked={selectedRole?.some((ite) => ite.value === item.value)}
                                                />
                                            </div>
                                            <label
                                                className="leading-[2.4rem] text-[1.4rem] font-normal"
                                                htmlFor={item.value}
                                            >
                                                {item.value}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <FlowRoot>
                                <FlexDiv className="float-right gap-[2.4rem] mb-[3.6rem]">
                                    <Button
                                        bgColor="#fff"
                                        color="#D1D5DB"
                                        border="1px solid #D1D5DB"
                                        // type="submit"
                                        // disabled={isSubmitting}
                                        onClick={() => onClose}
                                        className="py-2 px-4 mt-[1.9rem] mb-[1.9rem] cursor-pointer border-[#E5E7EB] "
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => setShowConfirmAllocation(true)}
                                        type="submit"
                                        // disabled={isSubmitting}
                                        bgColor="#6366F1"
                                        color="#fff"
                                        className="py-2 px-4 hover:bg-[#6366F1] focus:bg-[#6366F1] focus:text-white mt-[1.9rem] cursor-pointer"
                                    >
                                        Update
                                    </Button>
                                </FlexDiv>
                            </FlowRoot>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
            <ConfirmAddAdministrator
                show={showConfirmAllocation}
                close={() => setShowConfirmAllocation(false)}
                list={selectedOptions}
                role={selectedRole}
                // onSend={() => allotCourses()}
            />
        </>
    );
};

export default EditDepartmentAdministrator;
