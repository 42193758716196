import React from 'react';

import { Text } from '../../../containers/MesssageContainers';
import TextInput from '../../inputs/text-input';
import ConfirmActionDialogue from '../confirm-action';

const ConfirmActionDialogueWithInput = ({
    value,
    setInputValue,
    subtitle2,
    placeholder,
    borderLine = true,
    svgIcon,
    ...props
}) => {
    return (
        <ConfirmActionDialogue svgIcon={svgIcon} borderLine={borderLine} {...props}>
            {subtitle2 && (
                <Text size="1.6rem" color="#374151" bottom="3rem" align="start">
                    {subtitle2}
                </Text>
            )}
            <TextInput
                type="text"
                placeholder={placeholder || 'Input name'}
                onChange={(e) => setInputValue(e.target.value)}
                value={value}
                required
            />
        </ConfirmActionDialogue>
    );
};

export default ConfirmActionDialogueWithInput;
