import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    sessions: [],
    activeSession: '',
    activeSemester: '',
};

export const admissionsSlice = createSlice({
    name: 'admissions',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ADMISSIONS_SESSIONS: (state, action) => {
            state.sessions = action.payload;
        },
        GET_ACTIVE_SESSION: (state, action) => {
            state.activeSession = action.payload;
        },
        GET_ACTIVE_SEMESTER: (state, action) => {
            state.activeSemester = action.payload;
        },
    },
});

export const { GET_ADMISSIONS_SESSIONS, LOADING, STOP_LOADING, GET_ACTIVE_SESSION, GET_ACTIVE_SEMESTER } =
    admissionsSlice.actions;

export default admissionsSlice.reducer;
