import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import tw from 'twin.macro';

import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-sm.svg';
import { Text, PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexColumn, FlexCentredCol } from '../../../../containers/ScreenContainers';
import { getAdmissionSessions } from '../../../../redux/admissions/actions';

const MainContainer = tw.div`
    bg-white
    p-[2rem]
    mb-[2rem]
    rounded-[2rem]
`;

const ListItems = [
    {
        title: 'Academic Standing',
        subtitle: 'Set the conditions for "good standing" or "not good standing"',
        link: 'academic-standing',
    },
    {
        title: 'Withdrawal List',
        subtitle: 'Set the Conditions for withdrawal list',
        link: 'withdrawal-list',
    },
    {
        title: 'Probation List',
        subtitle: 'Set the Conditions for probation list',
        link: 'probation-list',
    },
    {
        title: 'Cumulative credit Units',
        subtitle: 'Set the minimum and maximum cumulative units students can register',
        link: 'cumulative-credit-units',
    },
    {
        title: 'Minimum Promotion CGPA',
        subtitle: 'Grading system, Matric No. Settings...',
        link: 'minimum-promotion-cgpa',
    },
];

const Settings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    return (
        <>
            <FlexColumn className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle align="start">Settings</PageTitle>
            </FlexColumn>
            {ListItems.map((item, i) => (
                <MainContainer key={i} className="hover:cursor-pointer" onClick={() => navigate(item.link)}>
                    <FlexRowSpaceBetween>
                        <FlexCentredCol>
                            <Text align="left" weight="700" size="1.8rem">
                                {item.title}
                            </Text>
                            <Text as="span" align="left" size="1.4rem" weight="400">
                                {item.subtitle}
                            </Text>
                        </FlexCentredCol>

                        <ArrowRight className="mr-[8px]" />
                    </FlexRowSpaceBetween>
                </MainContainer>
            ))}
        </>
    );
};

export default Settings;
