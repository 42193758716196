import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as Link } from '../../../assets/icons/link-blue.svg';
import FormCard from '../form-card';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;
const TextInput = tw.input`
border-[1px]
border-[#E5E7EB]
pl-3
rounded
outline-none
focus:ring-[#6366F1]
focus:ring-2
placeholder:text-[#6B7280]
placeholder:text-[1.4rem]
leading-6
font-normal
text-[1.4rem]
h-[4rem]
`;

const AddNewLink = ({ show, setShow, text, url, handleUrlChange, handleTextChange, handleAdd }) => {
    const onClose = () => setShow(false);

    return (
        <div className="absolute bottom-[5rem] left-0 z-[1000] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]">
            <FormCard headerIcon={<Link />} close={onClose} show={show} title={'Add New Link'}>
                <form>
                    <div className="gap-[3.2rem] flex flex-col">
                        <TextInput
                            name="display text"
                            type="text"
                            placeholder="Text to display"
                            value={text}
                            onChange={handleTextChange}
                        />
                        <TextInput
                            name="link"
                            type="text"
                            placeholder="Enter link URL"
                            value={url}
                            onChange={handleUrlChange}
                        />
                    </div>
                    <FlexContainer>
                        <CancelButton type="button" onClick={onClose}>
                            Cancel
                        </CancelButton>
                        <SubmitButton type="button" onClick={handleAdd}>
                            Ok
                        </SubmitButton>
                    </FlexContainer>
                </form>
            </FormCard>
        </div>
    );
};

export default AddNewLink;
