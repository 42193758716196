import React from 'react';

import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import SelectInput from '../../inputs/select-input';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const EditStudentRecordsForm = ({ show, setShow, details }) => {
    const onClose = () => setShow(false);

    return (
        <FormContainer close={onClose} show={show} title={'Edit Student Details'}>
            <Formik
                initialValues={{
                    email: 'red',
                    text: '',
                    confirmtext: '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {}}
            >
                {({
                    errors,
                    // handleChange,
                    // handleBlur,
                    isSubmitting,
                }) => (
                    <Form>
                        <div className="gap-5 flex flex-col">
                            <TextInput name="firstName" type="text" placeholder="Full Name" errors={errors} />
                            <TextInput name="middleName" type="text" placeholder="Couurse Code" errors={errors} />

                            <SelectInput>
                                <option value="">Faculty</option>
                                {/* {sessions.map((items) => {
                                return <option key={items.session}>{items.session}</option>;
                            })} */}
                            </SelectInput>
                            <SelectInput>
                                <option value="">Department</option>
                                {/* {sessions.map((items) => {
                                return <option key={items.session}>{items.session}</option>;
                            })} */}
                            </SelectInput>
                            <SelectInput>
                                <option value="">Level</option>
                                {/* {sessions.map((items) => {
                                return <option key={items.session}>{items.session}</option>;
                            })} */}
                            </SelectInput>
                            <TextInput name="email" type="text" placeholder="Session" errors={errors} />
                        </div>
                        <FlexContainer>
                            <CancelButton type="button" onClick={onClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit">Update</SubmitButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default EditStudentRecordsForm;
