import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_DEPARTMENTS,
    LOADING,
    GET_DEPARTMENT_COURSES,
    GET_DEPARTMENT_STAFF,
    GET_DEPARTMENT_STUDENTS,
    // GET_DEPARTMENT_ADMINS,
    STOP_LOADING,
    GET_DEPARTMENT_TIMETABLE,
    GET_LECTURE_VENUES,
    GET_FACULTY_LECTURE_VENUES,
    GET_DEPARTMENT_LECTURE_VENUES,
} from './slice';

export const getDepartments = () => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}department/${currentUnit?._id}/all_departments`);
        if (response.status === 200) {
            dispatch(GET_DEPARTMENTS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// export const getDepartmentDetails = (id) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
// const {
//     organisationUnits: { currentUnit },
// } = getState();

//         const response = await axiosInstance.get(`${currentAPI}department/${currentUnit?._id}/all_departments`);
//         if (response.status === 200) {
//             dispatch(GET_DEPARTMENTS(response.data.data));
//             return true;
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const createDepartment =
    (facultyId, payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}department/${currentUnit?._id}/create_department/${facultyId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

// export const getDepartmentCourses = (id) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//         const {
//             organisationUnits: { currentUnit },
//         } = getState();

//         const response = await axiosInstance.get(
//             // `${currentAPI}department/${currentUnit?._id}/courses_in_department/${id}`,
//         );
//         if (response.status === 200) {
//             dispatch(GET_DEPARTMENT_COURSES(response.data.data));
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const getCoursesInSecondDegree =
    (structureId, firstDegreeId, secondDegreeId, query) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const queries = encodeQuery({ ...query });

            const response = await axiosInstance.get(
                `${currentAPI}course/${currentUnit?._id}/fetch_all_courses_second_degree_without_lecturer/${structureId}/${firstDegreeId}/${secondDegreeId}?${queries}`,
            );
            if (response.status === 200) {
                dispatch(GET_DEPARTMENT_COURSES(response.data.data));
            }
        } catch (error) {
            dispatch(GET_DEPARTMENT_COURSES([]));
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCoursesInSecondDegreeWithLecturers =
    ({ academicStructureId, firstDegreeId, secondDegreeId, session }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}course/${currentUnit?._id}/get_all_courses_second_degree/${academicStructureId}/${firstDegreeId}/${secondDegreeId}/${session}`,
            );
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getDepartmentStaff = (structureId, secondDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}department/${currentUnit?._id}/get_staff_in_second_degree/${structureId}/${secondDegreeId}`,
        );
        if (response.status === 200) {
            dispatch(GET_DEPARTMENT_STAFF(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDepartmentStudents = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}department/${currentUnit?._id}/student_in_department/${id}`,
        );
        if (response.status === 200) {
            dispatch(GET_DEPARTMENT_STUDENTS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        dispatch(GET_DEPARTMENT_STUDENTS([]));
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentsInSecondDegree =
    (structureId, firstDegreeId, secondDegreeId, admissionSession) => async (dispatch, getState) => {
        dispatch(LOADING());
        const queries = encodeQuery({ admissionSession });
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}students/${currentUnit?._id}/get_all_students_second_degree/${structureId}/${firstDegreeId}/${secondDegreeId}?${queries}`,
            );
            if (response.status === 200) {
                dispatch(GET_DEPARTMENT_STUDENTS(response.data.data));
                return response.data.data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            dispatch(GET_DEPARTMENT_STUDENTS([]));
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

// export const getDepartmentAdmin = (id) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//         const {
//             organisationUnits: { currentUnit },
//         } = getState();

//         const response = await axiosInstance.get(
//             `${currentAPI}department/${currentUnit?._id}/admin_in_department/${id}`,
//         );
//         if (response.status === 200) {
//             dispatch(GET_DEPARTMENT_ADMINS(response.data.data));
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const getSecondDegreeTimetable =
    (structureId, firstDegreeId, secondDegreeId, session, semester) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}timetable/${currentUnit?._id}/get_lecture_timetable_in_second_degree/${structureId}/${firstDegreeId}/${secondDegreeId}?session=${session}&semester=${semester}`,
            );
            if (response.status === 200) {
                dispatch(GET_DEPARTMENT_TIMETABLE(response.data.data));
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const getDepartmentExamTimetable =
    (structureId, firstDegreeId, secondDegreeId) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}timetable/${currentUnit?._id}/get_exam_timetable_in_second_degree/${structureId}/${firstDegreeId}/${secondDegreeId}`,
            );
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const addLectureVenues = (payload, id, structureId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}venue/${currentUnit?._id}/create_venue/${structureId}/${id}`,
            payload,
        );
        if (response.status === 200) {
            dispatch(GET_LECTURE_VENUES(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editLectureVenues = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}venue/${currentUnit?._id}/edit_venue/${id}`, payload);
        if (response.status === 200) {
            dispatch(GET_LECTURE_VENUES(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteLectureVenues = (id, structureId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}venue/${currentUnit?._id}/delete_venue/${structureId}/${id}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFacultyLectureVenues = (faculty) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}venue/${currentUnit?._id}/venue_by_faculty/${faculty}`);
        if (response.status === 200) {
            dispatch(GET_FACULTY_LECTURE_VENUES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getSecondDegreeLectureVenues =
    (structureId, firstDegreeId, secondDegreeId) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}venue/${currentUnit?._id}/get_venue_in_second_degree/${structureId}/${firstDegreeId}/${secondDegreeId}`,
            );
            if (response.status === 200) {
                dispatch(GET_DEPARTMENT_LECTURE_VENUES(response.data.data));
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const editDepartment = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}department/${currentUnit?._id}/edit_department/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = 'Your changes has been made successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editSecondDegree = (structureId, secondDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}structure/${currentUnit?._id}/edit_second_degree/${structureId}/${secondDegreeId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDepartment = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}department/${currentUnit?._id}/delete_department/${id}`,
        );
        const successMessage = response?.data?.message || 'Department Deleted successfully!';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
