import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as LeftImage } from '../../../assets/icons/oblique.svg';
import { Button } from '../../../components/buttons';
import { Text } from '../../../containers/MesssageContainers';
import { AuthContainer } from '../confirm-password';

const Card = tw.div`
    bg-white
    w-[80vw]
    p-[5rem]
    lg:p-[10rem]
    rounded-2xl
    z-10
    relative
`;

export default function VerifyEmailAddress() {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    // const token = params.get('token');
    const institution = params.get('institution');
    const username = window.location.search?.split('=')[3];

    return (
        <AuthContainer>
            <LeftImage className="absolute left-0 z-0" />
            <Card>
                <Text weight="600" align="left" lineHeight="3.4rem" bottom="8.1rem" color="#3730A3" size="2.3rem">
                    You have been added to {institution}!
                </Text>
                <Text weight="600" align="left" size="3.3rem" bottom="2.4rem">
                    Hi {username} 👋🏼
                </Text>
                <Text size="2.3rem" align="left" bottom="4.8rem">
                    {institution} has added you on their admin dashboard. Click the button below to create a new
                    password.
                </Text>
                <Button
                    onClick={() => navigate(`/create-password?email=${username}`)}
                    bgColor="#6366F1"
                    color="white"
                    className="py-2 px-4 cursor-pointer"
                >
                    Create Password
                </Button>
            </Card>
        </AuthContainer>
    );
}
