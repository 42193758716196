import { LIBRARY } from '../../utils/unitNames';
import {
    AcademicCalendar,
    // AddAdministrator,
    AddDistributionList,
    Administrator,
    CreateRole,
    DistributionList,
    EditAdministrator,
    EditRole,
    Received,
    Roles,
    GeneralSettings,
    Staff,
    StaffDetail,
    UpdateDistributionList,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';

export const libraryRoutes = [
    {
        path: '/library/access',
        element: (
            <Access
                staffs={<Staff unitName={LIBRARY} />}
                administrators={<Administrator unitName={LIBRARY} />}
                roles={<Roles unitName={LIBRARY} />}
            />
        ),
    },
    {
        path: '/library/access/create-role',
        element: <CreateRole unitName={LIBRARY} />,
    },
    {
        path: '/library/access/edit-role',
        element: <EditRole unitName={LIBRARY} />,
    },
    {
        path: '/library/access/add-administrator',
        // element: <AddAdministrator unitName={LIBRARY} />,
        element: <AssignRole unitName={LIBRARY} />,
    },
    {
        path: '/library/access/edit-administrator',
        element: <EditAdministrator unitName={LIBRARY} />,
    },
    {
        path: '/library/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/library/communications/received',
        element: <Received unitName={LIBRARY} />,
    },
    {
        path: '/library/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/library/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/library/communications/distribution-list',
        element: <DistributionList unitName={LIBRARY} title="Communications" />,
    },
    {
        path: '/library/communications/distribution-list/add',
        element: <AddDistributionList unitName={LIBRARY} title="Communications" />,
    },
    {
        path: '/library/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={LIBRARY} title="Communications" />,
    },
    {
        path: '/library/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/library/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/library/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/library/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
