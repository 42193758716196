import React from 'react';

import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../../containers/ScreenContainers';

const MainContainer = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    grid-flow-row
    gap-6
    mt-8
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;

const FlexCol = tw.div`
    gap-6
    
`;

const Details = tw.div`
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    font-weight: 500;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #4b5563;
    font-weight: 500;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const Sports = ({ details }) => {
    const sports = [
        {
            title: 'Name of Major Sport/ Game',
            subtitle: details?.sportData?.sportInfo?.sportChoice[0] || 'Nil',
        },
        {
            title: 'Achievement (Prize, Medals)',
            subtitle: '',
        },

        {
            title: 'Name of Other Sport/ Game',
            subtitle: details?.sportData?.sportInfo?.sportChoice[1] || 'Nil',
        },
        {
            title: 'Other Achievement (Prize, Medals)',
            subtitle: '',
        },
    ];
    const OtherData = [
        {
            title: 'Health Challenges',
            subtitle: details?.sportData?.sportInfo?.healthChallenge || 'Nil',
        },
        {
            title: 'Wish to represent the school',
            subtitle: details?.sportData?.sportInfo?.wishToRep || 'Nil',
        },

        {
            title: 'Purpose of participating in sports',
            subtitle: details?.sportData?.sportInfo?.wishToParticipate || 'Nil',
        },
    ];

    return (
        <div>
            <MainContainer>
                <Details>
                    <FlexCentredRow>
                        <People className="mr-[0.919rem]" />
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Sports/ Games Information
                        </PageTitle>
                    </FlexCentredRow>

                    <BasicContent>
                        <RowContainer>
                            {sports?.slice(0, Math.ceil(sports?.length / 2)).map((item, index) => (
                                <FlexCol key={index}>
                                    <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            {sports?.slice(Math.ceil(sports?.length / 2)).map((item, index) => (
                                <FlexCol key={index}>
                                    <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details>
                    <FlexCentredRow>
                        <People className="mr-[0.919rem]" />
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Other Information
                        </PageTitle>
                    </FlexCentredRow>

                    <BasicContent>
                        <RowContainer>
                            {OtherData?.slice(0, Math.ceil(OtherData?.length / 2)).map((item, index) => (
                                <FlexCol key={index}>
                                    <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            {OtherData?.slice(Math.ceil(OtherData?.length / 2)).map((item, index) => (
                                <FlexCol key={index}>
                                    <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                    <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
            </MainContainer>
        </div>
    );
};

export default Sports;
