/* eslint-disable no-unused-vars */
import React from 'react';

import { useNavigate } from 'react-router-dom';
// import { styled } from 'twin.macro';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People2} from '../../../../assets/icons/people2.svg';
import {ReactComponent as StaffIcon} from '../../../../assets/icons/staff.svg'
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween,} from '../../../../containers/ScreenContainers';


const StatusBox = styled.div`
    background-color: ${({ color }) => color || '#ECFDF5'};
    border-radius: '4px';
    height: '32px';
    padding: 4px 8px;
`;

const MainContainer = tw.div`

    mt-8
    bg-white
    p-6
    rounded-lg
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-4
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-3
    gap-8
`;

const FlexCol = tw.div`
    gap-6

`;

const Details = tw.div`


`;

const TitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const SubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;


const Overview = () => {
    const navigate = useNavigate();
        return (
        <div>
                 <MainContainer>
              <FlexRowSpaceBetween className="mb-[3.2rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                <People2 className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" weight="700"
                    lineHeight="2.8rem">
                        Staff Details
                    </PageTitle>
                                    </FlexCentredRow>
                <StatusBox>
                    <Text weight="500" color="#059669">
                    Active
                    </Text>
                </StatusBox>
            </FlexRowSpaceBetween>


            <FlexRowSpaceBetween>
            <FlexCentredRow>
                        <StaffIcon />
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <TitleText>Name</TitleText>
                                <SubText>Joy-Jude Aderibigbe</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Phone</TitleText>
                                <SubText>+234 (0) 814 984 7469</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Designation</TitleText>
                                <SubText>Secretary</SubText>
                            </FlexCol>
                        </RowContainer>
                        </FlexCentredRow>

                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <TitleText>Department</TitleText>
                                <SubText>Mechanical Engineering</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Email Address</TitleText>
                                <SubText>Joyjudeaderibigbe@babcock.com</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Date of Employment </TitleText>
                                <SubText>24 March 1995</SubText>
                            </FlexCol>
                        </RowContainer>
              </FlexRowSpaceBetween>
                </MainContainer>

                <MainContainer>
                <FlexRowSpaceBetween className="mb-[3.2rem]">
                  <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                  <People2 className="mr-[8px]" />
                      <PageTitle as="span" size="1.6rem" weight="700"
                      lineHeight="2.8rem">
                          Other Details
                      </PageTitle>
                                      </FlexCentredRow>
                                      </FlexRowSpaceBetween>

                        <FlexRowSpaceBetween>
                        <RowContainer className="lg:text-left text-center">
                            <FlexCol>
                                <TitleText>Education</TitleText>
                                <SubText>ND (Owo) B.Eng, M.Tech(England), & Ph.D (Akure)</SubText>
                            </FlexCol>

                            <FlexCol>
                                <TitleText>Nationality</TitleText>
                                <SubText>Nigerian</SubText>
                            </FlexCol>

                            <FlexCol>
                                <TitleText>LGA</TitleText>
                                <SubText>Akoko North</SubText>
                            </FlexCol>
                        </RowContainer>

                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <TitleText>Employment Type</TitleText>
                                <SubText>Non-Academic Staff</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>State of Origin</TitleText>
                                <SubText>Ondo</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText>Residential Address </TitleText>
                                <SubText>12, Chevron Drive Alagbaka Akure, Ondo State</SubText>
                            </FlexCol>
                        </RowContainer>

                        <RowContainer className="lg:text-right text-center">
                            <FlexCol>
                                <TitleText align="right">Personal Email Address</TitleText>
                                <SubText align="right">sdrfunke@yahoo.com</SubText>
                            </FlexCol>
                            <FlexCol>
                                <TitleText align="right">Phone Number</TitleText>
                                <SubText align="right">+234 (0) 814 984 7469</SubText>
                            </FlexCol>
                        </RowContainer>
                    </FlexRowSpaceBetween>
                     </MainContainer>
        </div>
        );
    };

    export default Overview;


