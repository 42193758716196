import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    addLetter: {},
    allSpecialLetter: [],
    singleSpecialLetter: {},
};

export const specialLetterSlice = createSlice({
    name: 'specialLetter',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        SAVE_ADD: (state, action) => {
            state.addLetter = action.payload;
        },
        GET_ALL_SPECIAL_LETTER: (state, action) => {
            state.allSpecialLetter = action.payload;
        },
        GET_SPECIAL_LETTER: (state, action) => {
            state.singleSpecialLetter = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, SAVE_ADD, GET_ALL_SPECIAL_LETTER, GET_SPECIAL_LETTER } =
    specialLetterSlice.actions;

export default specialLetterSlice.reducer;
