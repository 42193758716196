import React from 'react';

import InputContainer from '../../../components/Container/inputcontainer';
import AdminSignInForm from '../../../components/forms/sign-in-admin';
import { PoweredBy } from '../../../components/powered-by';
import { AuthContainer } from '../confirm-password';




const AdminSignIn = () => {
    return (
        <AuthContainer>

            <InputContainer>
                <AdminSignInForm />
                <PoweredBy />
                </InputContainer>

        </AuthContainer>
    );
};

export default AdminSignIn;
