import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    session: Yup.string().required('Required'),
    semester: Yup.string().required('Required'),
    level: Yup.string().required('Required'),
    course: Yup.string().required('Required'),
    weekday: Yup.string().required('Required'),
    startTime: Yup.string().required('Required'),
    endTime: Yup.string().required('Required'),
    venue: Yup.string().required('Required'),
});
