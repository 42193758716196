import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as ImportIcon } from '../../../assets/icons/download-blue2.svg';
import { FlexColumn } from '../../../containers/ScreenContainers';
import { importAllotedLecturers, importGeneralAllotedLecturers } from '../../../redux/courses/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const ImportAllotedLecturers = ({ isFirstDegreeAllocation, show, setShow, onFinish }) => {
    const dispatch = useDispatch();
    const admissionsSessions = useSelector((state) => state.admission.sessions);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: firstDegreeId, academicStructureId } = currentFaculty;
    const { _id: secondDegreeId } = currentDepartment || {};

    const [altLoading, setAltLoading] = useState(false);

    const onClose = () => setShow(false);

    return (
        <>
            <FormContainer headerIcon={<ImportIcon />} close={onClose} show={show} title={'Import Course Allocation'}>
                <Formik
                    initialValues={{
                        sourceSession: '',
                        destinationSession: '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        setAltLoading(true);
                        const degreeIdToUse = isFirstDegreeAllocation ? firstDegreeId : secondDegreeId;
                        const actionToUse = isFirstDegreeAllocation
                            ? importGeneralAllotedLecturers
                            : importAllotedLecturers;

                        const res = await dispatch(actionToUse(academicStructureId, degreeIdToUse, values));
                        if (res) {
                            toast.success('Course lecturer allocation imported successfully!');
                            actions.resetForm();
                            onClose();
                            onFinish?.();
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, setFieldValue }) => (
                        <Form>
                            <FlexColumn className="gap-5">
                                <Select
                                    name="sourceSession"
                                    objProp="admissionYear"
                                    label="Source Session"
                                    placeholder="Choose Session"
                                    data={admissionsSessions}
                                    onChange={(selected) => {
                                        setFieldValue('sourceSession', String(selected[0].admissionYear));
                                    }}
                                    error={errors.sourceSession}
                                />

                                <Select
                                    name="destinationSession"
                                    objProp="admissionYear"
                                    label="Destination Session"
                                    placeholder="Choose Session"
                                    data={admissionsSessions}
                                    onChange={(selected) => {
                                        setFieldValue('destinationSession', String(selected[0].admissionYear));
                                    }}
                                    error={errors.destinationSession}
                                />
                            </FlexColumn>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={altLoading} type="submit">
                                    Import
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default ImportAllotedLecturers;
