import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

export const getInternalCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}courseForm/${
                    currentUnit?._id
                }/course_form/${academicStructureId}/${lastDegreeId}/${session}/${level}?semester=${semester?.toLowerCase()}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getStudentCourseForm = (programId, studentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}courseForm/${currentUnit?._id}/get_student_form/${programId}/${studentId}`,
        );

        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentRegisteredCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester, studentId }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.get(
                `${currentAPI}courseForm/${
                    currentUnit?._id
                }/student_registered_courses/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester?.toLowerCase()}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const advisorApproveCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester, studentId, payload }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}courseForm/${
                    currentUnit?._id
                }/advisor_approval_of_course_form/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester?.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const advisorRejectCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester, studentId, payload }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}courseForm/${
                    currentUnit?._id
                }/advisor_rejection_of_course_form/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester?.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const approveCourse =
    ({ academicStructureId, lastDegreeId, session, studentId, payload, level, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}courseForm/${
                    currentUnit?._id
                }/approval_of_each_course/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const rejectCourse =
    ({ academicStructureId, lastDegreeId, session, studentId, payload, level, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const {
                organisationUnits: { currentUnit },
            } = getState();

            const response = await axiosInstance.put(
                `${currentAPI}courseForm/${
                    currentUnit?._id
                }/rejection_of_course/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
