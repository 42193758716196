import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { getCoursesInSecondDegreeWithLecturers } from '../redux/departments/actions';
import { getGeneralCoursesInFirstDegreeWithLecturers } from '../redux/faculty/actions';

const useFetchCoursesForAllocation = ({
    academicStructureId,
    firstDegreeId,
    secondDegreeId,
    isFirstDegreeAllocation,
    session,
    refetchCourses,
}) => {
    const dispatch = useDispatch();

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        if (!session || !firstDegreeId) return;

        const getCourses = async () => {
            let data;

            if (isFirstDegreeAllocation) {
                data = await dispatch(
                    getGeneralCoursesInFirstDegreeWithLecturers(academicStructureId, firstDegreeId, session),
                );
            } else if (!isFirstDegreeAllocation && secondDegreeId) {
                const params = {
                    academicStructureId,
                    firstDegreeId,
                    secondDegreeId,
                    session,
                };
                data = await dispatch(getCoursesInSecondDegreeWithLecturers(params));
            }
            setCourses(data || []);
        };

        getCourses();
    }, [
        dispatch,
        secondDegreeId,
        academicStructureId,
        firstDegreeId,
        isFirstDegreeAllocation,
        session,
        refetchCourses,
    ]);

    return courses;
};

export default useFetchCoursesForAllocation;
