import React from 'react';

import { useSelector } from 'react-redux';

import CreateProgramme from '../../../../../components/forms/create-programme';
import { GoBack } from '../../../../../components/go-back';
import { PageTitle } from '../../../../../containers/MesssageContainers';

const AddNewProgramme = () => {
    const { currentFaculty } = useSelector((state) => state.faculty);
    const { splitAcademicStructureThree } = currentFaculty;

    return (
        <>
            <div className="mb-12">
                <GoBack title={`${splitAcademicStructureThree}s`} />
                <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                    Add {splitAcademicStructureThree}
                </PageTitle>
            </div>

            <CreateProgramme />
        </>
    );
};
export default AddNewProgramme;
