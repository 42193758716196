import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    courseTitle: Yup.string().nullable().required('Required'),
    // courseCode: Yup.string()
    //     .matches(/^[A-Za-z]+\d+$/, 'Invalid course code. Format must be text followed by numbers (e.g., MAT123).')
    //     .required('Required'),
    courseCode: Yup.string().nullable().required('Required'),
    courseUnit: Yup.string().nullable().required('Required'),
    courseLevel: Yup.string().nullable().required('Required'),
    courseSemester: Yup.string().nullable().required('Required'),
    minimumPassScore: Yup.string().nullable().required('Required'),
    departmentCode: Yup.string().nullable().required('Required'),
});
