import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as Closeicon } from '../../assets/images/close.svg';
import { Text } from '../../containers/MesssageContainers';
import { LoadingButton } from '../buttons';
import { CancelButton } from '../forms/sharedStyles';
import Checkbox from '../inputs/checkbox';
import RadioInput from '../inputs/radio';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
    }
`;

const radioGroup = [
    { label: '10 Resources', name: '10_resources', id: 10 },
    { label: '25 Resources', name: '25_resources', id: 25 },
    { label: '50 Resources', name: '50_resources', id: 50 },
];

const tableSettings = [
    {
        title: 'Alternate row colours',
        description:
            'Enable to show table rows with alternate colours for better visibility, disable to show table rows with same colour',
        id: 'striped',
    },
    {
        title: 'Freeze First Column',
        description: 'Enable to fix first Column ',
        id: 0,
    },
    {
        title: 'Freeze First & Second Column',
        description: 'Enable to fix first & Second Column ',
        id: 1,
    },
];

const TableSettings = ({ show, close, onApplySettings }) => {
    const savedSettings = JSON.parse(localStorage.getItem('tableSettings'));
    const [numOfPages, setNumOfPages] = useState(savedSettings?.numOfPages || 10);
    const [stripedRow, setStripedRow] = useState(savedSettings?.stripedRow || false);
    const [stickyColumns, setStickyColumns] = useState(null);
    const [settingsToApply, setSettingsToApply] = useState({
        stripedRow: false,
        stickyColumns: null,
        numOfPages: numOfPages,
    });

    const onRadioChange = (value) => {
        setNumOfPages(value);
        setSettingsToApply((prev) => ({ ...prev, numOfPages: value }));
    };

    const handleStripedRows = () => {
        setStripedRow(!stripedRow);
        setSettingsToApply((prev) => ({ ...prev, stripedRow: !stripedRow }));
    };

    const handleStickyColumns = (item) => {
        if (settingsToApply.stickyColumns === item?.id) {
            setSettingsToApply((prev) => ({ ...prev, stickyColumns: null }));
            setStickyColumns(null);
        } else {
            setSettingsToApply((prev) => ({ ...prev, stickyColumns: item?.id }));
            setStickyColumns(item?.id);
        }
    };

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <div className="w-[695px] bg-white rounded-[8px]">
                <div className="flex items-center rounded-[8px] justify-between bg-[#F3F4F6] p-[20px]">
                    <Text size="1.6rem" weight="500" color="#111928" align="left">
                        Preferences
                    </Text>
                    <Closeicon className="cursor-pointer" onClick={close} />
                </div>
                <div className="grid grid-cols-3 gap-[58px] p-[20px]">
                    <div className="flex flex-col gap-[16px] items-center">
                        <Text weight="500" size="16px" align="center">
                            Page Size
                        </Text>
                        <div className="flex flex-col gap-[12px]">
                            {radioGroup?.map((radio) => (
                                <RadioInput
                                    key={radio?.name}
                                    label={radio?.label}
                                    name={radio?.name}
                                    onClick={() => onRadioChange(radio?.id)}
                                    checked={numOfPages === radio?.id}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="col-span-2 flex flex-col gap-[24px]">
                        {tableSettings?.map((item, i) => (
                            <div key={item.id} className="flex items-start gap-[12px]">
                                <Checkbox
                                    checked={
                                        i === 0
                                            ? stripedRow
                                            : i === 1
                                            ? stickyColumns === 0
                                            : i === 2
                                            ? stickyColumns === 1
                                            : false
                                    }
                                    className="mt-[0.5rem]"
                                    onClick={() => {
                                        if (item?.id === 'striped') {
                                            handleStripedRows(item);
                                        } else {
                                            handleStickyColumns(item);
                                        }
                                    }}
                                />
                                <div className="flex flex-col gap-[10px]">
                                    <Text align="left" size="1.6rem" weight="500">
                                        {item?.title}
                                    </Text>
                                    <Text align="left" size="1.4rem" weight="400" lineHeight="2.4rem" color="#374151">
                                        {item?.description}
                                    </Text>
                                </div>
                            </div>
                        ))}
                        <div className="flex items-center pt-[24px] gap-[16px]">
                            <CancelButton type="button" onClick={close} className="ml-auto">
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                bgColor="#6366F1"
                                color="#fff"
                                type="button"
                                onClick={() => onApplySettings(settingsToApply)}
                            >
                                Apply Changes
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </StyledModal>
    );
};

export default TableSettings;
