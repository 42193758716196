import EditAlternateContact from '../../components/forms/edit-org-alternate-contact';
import EditOrgContact from '../../components/forms/edit-org-contact';
import { ICT } from '../../utils/unitNames';
import {
    CreateRole,
    EditAdministrator,
    Received,
    Roles,
    // AddAdministrator,
    Domain,
    AddAdmin,
    AddStudent,
    AddLecturer,
    StudentProceed,
    AdminProceed,
    LecturersProceed,
    Administrator,
    AcademicCalendar,
    StaffDetail,
    OrganizationUnit,
    Staff,
    ICTSettings,
    OrganizationSettings,
    EditRole,
    DistributionList,
    // GeneralSettings,
    AddDistributionList,
    UpdateDistributionList,
    AuditLog,
    Home,
    ContactInformation,
    OrganizationProfile,
    OrganizationDelete,
    EditOrgProfile,
    CustomDomain,
    Access,
    AssignRole,
    IctOrganizationAdmins,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
} from '../../views';
import AcademicStructure from '../../views/academics/academic-structure';
import IctAddOrgAdministrator from '../../views/organization-admins/ict-add-administrator';

export const ictRoutes = [
    {
        path: '/ict/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/ict/access',
        element: (
            <Access
                staffs={<Staff unitName={ICT} />}
                administrators={<Administrator unitName={ICT} />}
                roles={<Roles unitName={ICT} />}
            />
        ),
    },
    {
        path: '/ict/access/add-administrator',
        element: <AssignRole unitName={ICT} />,
    },
    {
        path: '/ict/access/edit-administrator',
        element: <EditAdministrator unitName={ICT} />,
    },
    {
        path: '/ict/access/create-role',
        element: <CreateRole unitName={ICT} />,
    },
    {
        path: '/ict/access/edit-role',
        element: <EditRole unitName={ICT} />,
    },
    {
        path: '/ict/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/ict/audit-log',
        element: <AuditLog />,
    },
    {
        path: '/ict/communications/received',
        element: <Received unitName={ICT} />,
    },
    {
        path: 'ict/org/academic-structure',
        element: <AcademicStructure />,
    },
    {
        path: '/ict/org/units',
        element: <OrganizationUnit withActions />,
    },
    {
        path: '/ict/org/admins',
        element: <IctOrganizationAdmins withCreate />,
    },
    {
        path: '/ict/org/admins/add',
        element: <IctAddOrgAdministrator />,
    },
    {
        path: '/ict/org/settings',
        element: <OrganizationSettings />,
    },
    {
        path: '/ict/org/settings/contact-information',
        element: <ContactInformation />,
    },
    {
        path: '/ict/org/settings/contact-information/edit',
        element: <EditOrgContact />,
    },
    {
        path: '/ict/org/settings/contact-information/edit-alternate-contact',
        element: <EditAlternateContact />,
    },
    {
        path: '/ict/org/settings/profile',
        element: <OrganizationProfile />,
    },
    {
        path: '/ict/org/settings/profile/edit',
        element: <EditOrgProfile />,
    },
    {
        path: '/ict/org/settings/account-deletion',
        element: <OrganizationDelete />,
    },

    {
        path: '/ict/settings/domain',
        element: <Domain />,
    },
    {
        path: '/ict/settings/custom-domain',
        element: <CustomDomain />,
    },
    {
        path: '/ict/settings/custom-domain/add-admin',
        element: <AddAdmin />,
    },
    {
        path: '/ict/settings/domain/add-admin',
        element: <AddAdmin />,
    },
    {
        path: '/ict/settings/custom-domain/add-students',
        element: <AddStudent />,
    },
    {
        path: '/ict/settings/domain/add-students',
        element: <AddStudent />,
    },
    {
        path: '/ict/settings/add-student/student-proceed',
        element: <StudentProceed />,
    },
    {
        path: '/ict/settings/add-lecturer/lecturer-proceed',
        element: <LecturersProceed />,
    },
    {
        path: '/ict/settings/add-admin/admin-proceed',
        element: <AdminProceed />,
    },
    {
        path: '/ict/settings/custom-domain/add-lecturer',
        element: <AddLecturer />,
    },
    {
        path: '/ict/settings/domain/add-lecturer',
        element: <AddLecturer />,
    },
    { path: 'ict/home', element: <Home unitName={ICT} /> },
    {
        path: '/ict/settings',
        element: <ICTSettings />,
    },
    {
        path: '/ict/communications/distribution-list',
        element: <DistributionList unitName={ICT} title="Communications" />,
    },
    {
        path: '/ict/communications/distribution-list/add',
        element: <AddDistributionList unitName={ICT} title="Communications" />,
    },
    {
        path: '/ict/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={ICT} title="Communications" />,
    },
    {
        path: '/ict/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/ict/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/ict/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/ict/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
];
