import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';

import { createAppointment } from '../../../redux/appointments/actions';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton, SubmitButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const CreateCategory = ({ show, setShow, setOpenSuccess }) => {
    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
    const onClose = () => setShow(false);

    return (
        <>
            <FormContainer close={onClose} show={show} title={'Create Category'}>
                <Formik
                    initialValues={{
                        appointmentName: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        await dispatch(createAppointment(values));
                        actions.resetForm();
                        onClose();
                        setOpenSuccess(true);
                    }}
                >
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="appointmentName"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <SubmitButton type="submit">Create</SubmitButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default CreateCategory;
