import React, { useEffect } from 'react';

import { storeUnit } from '../../../utils/originUnit';

const Home = ({ unitName }) => {
    useEffect(() => {
        storeUnit(unitName);
        //eslint-disable-next-line
    }, []);
    return <div>Home</div>;
};

export default Home;
