import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { FlexDiv } from '../../../containers/ScreenContainers';
import { LEVEL, SEMESTER } from '../../../data/timetable';
import { getCoursesInFirstDegree } from '../../../redux/courses/actions';
import { getFirstDegreeLectureVenues } from '../../../redux/faculty/actions';
import { editExamTimetable, getExamTimetableById } from '../../../redux/timetable/actions';
import { LoadingButton } from '../../buttons';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import TimeInput from '../../inputs/time-input';
import { Loader } from '../../loader';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const MainContainer = tw.div`
    bg-white
    rounded-[16px]
    px-[3rem]
`;
const InputContainer = tw.div`
    grid
    grid-cols-2
    gap-[2.5rem]
`;
const FlowRoot = tw.div`
flow-root
`;

const EditExamTimeTable = ({ show, setShow, timetableId }) => {
    const onClose = () => setShow(false);
    const dispatch = useDispatch();

    const { sessions } = useSelector((store) => store.admission);
    const { currentFaculty, firstDegreeLectureVenues } = useSelector((store) => store.faculty);
    const { isLoading, examTimetable } = useSelector((store) => store.timetable);
    const { firstDegreeCourses } = useSelector((store) => store.courses);

    const { _id: firstDegreeId, academicStructureId } = currentFaculty || {};

    const [selectedSemester, setSelectedSemester] = useState('');

    const semesterCourses = useMemo(
        () =>
            firstDegreeCourses?.filter(
                (item) => item.courseSemester?.toLowerCase() === selectedSemester?.toLowerCase(),
            ),
        [firstDegreeCourses, selectedSemester],
    );

    useEffect(() => {
        dispatch(getExamTimetableById(timetableId));
    }, [dispatch, timetableId]);

    useEffect(() => {
        dispatch(getFirstDegreeLectureVenues(academicStructureId, firstDegreeId));
    }, [dispatch, academicStructureId, firstDegreeId]);

    useEffect(() => {
        dispatch(getCoursesInFirstDegree(academicStructureId, firstDegreeId));
    }, [academicStructureId, firstDegreeId, dispatch]);

    const startTime = new Date(examTimetable?.startTime);
    const startHour = startTime?.getHours();
    const startMins = startTime?.getMinutes();
    const formattedStart = `${startHour?.toString().padStart(2, '0')}:${startMins?.toString().padStart(2, '0')}`;

    const endTime = new Date(examTimetable?.endTime);
    const endHour = endTime?.getHours();
    const endMins = endTime?.getMinutes();
    const formattedEnd = `${endHour?.toString().padStart(2, '0')}:${endMins?.toString().padStart(2, '0')}`;

    return (
        <FormContainer close={onClose} show={show} title={'Update Exam Timetable'}>
            {isLoading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={{
                    session: examTimetable?.session || '',
                    semester: examTimetable?.semester || '',
                    level: examTimetable?.level || '',
                    course: examTimetable?.course || '',
                    examDate: examTimetable?.examDate || '',
                    startTime: formattedStart || '',
                    endTime: formattedEnd || '',
                    venue: examTimetable?.venue || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                    const payload = {
                        ...values,
                        examDate: new Date(values.examDate).toISOString().slice(0, 10),
                        level: values.level.toString(),
                    };
                    const res = dispatch(editExamTimetable(timetableId, payload));
                    if (res) {
                        actions.resetForm();
                        onClose();
                    }
                }}
            >
                {({ errors, handleChange, setFieldValue, values, touched, isSubmitting }) => (
                    <Form>
                        <MainContainer>
                            <InputContainer>
                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    label="Session"
                                    data={sessions}
                                    placeholder="Session"
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                    }}
                                    error={errors.session}
                                    passedSelectedItems={values.session}
                                />

                                <Select
                                    name="semester"
                                    objProp="name"
                                    label="Semester"
                                    data={SEMESTER.map((item) => ({ name: item }))}
                                    placeholder="Semester"
                                    onChange={(selected) => {
                                        setFieldValue('semester', selected[0].name);
                                        setSelectedSemester(selected[0].name);
                                    }}
                                    error={errors.semester}
                                    passedSelectedItems={values.semester}
                                />

                                <Select
                                    name="course"
                                    objProp="courseCode"
                                    label="Course"
                                    searchable
                                    placeholder="Course"
                                    data={semesterCourses}
                                    onChange={(selected) => {
                                        setFieldValue('course', selected[0].courseCode);
                                    }}
                                    error={errors.course}
                                    passedSelectedItems={values.course}
                                />

                                <Select
                                    name="level"
                                    objProp="name"
                                    label="Level"
                                    data={LEVEL.map((item) => ({ name: item }))}
                                    placeholder="Level"
                                    onChange={(selected) => {
                                        setFieldValue('level', selected[0].name);
                                    }}
                                    error={errors.level}
                                    passedSelectedItems={values.level}
                                />

                                <DatePicker
                                    initialValue={values.examDate ? new Date(values.examDate) : null}
                                    label="Date"
                                    name="examDate"
                                    touched={touched}
                                />

                                <TimeInput
                                    label="Start Time"
                                    name="startTime"
                                    type="time"
                                    onChange={handleChange}
                                    value={values.startTime}
                                    error={errors.startTime}
                                />

                                <TimeInput
                                    label="End Time"
                                    name="endTime"
                                    type="time"
                                    onChange={handleChange}
                                    value={values.endTime}
                                    error={errors.endTime}
                                />

                                <Select
                                    name="venue"
                                    objProp="hallName"
                                    label="Venue"
                                    searchable
                                    placeholder="Venue"
                                    data={firstDegreeLectureVenues}
                                    onChange={(selected) => {
                                        setFieldValue('venue', selected[0].hallName);
                                    }}
                                    error={errors.venue}
                                    passedSelectedItems={values.venue}
                                />
                            </InputContainer>

                            <FlowRoot>
                                <FlexDiv className="float-right gap-[2.4rem] mt-[2rem]">
                                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                                    <LoadingButton
                                        type="submit"
                                        loading={isSubmitting}
                                        bgColor="#6366F1"
                                        color="#fff"
                                        className="py-2 px-4"
                                    >
                                        Update
                                    </LoadingButton>
                                </FlexDiv>
                            </FlowRoot>
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default EditExamTimeTable;
